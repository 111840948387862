import { Injectable } from '@angular/core';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenresService {
  constructor(private http: HttpClient) { }

  updateGenre(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateGenreUrl, postObj,httpOptions);
  }
        
  insertGenre(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertGenreUrl, postObj,httpOptions);
  }    
  
  getGenre( id: any) : Observable<any> {
      
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getGenreUrl+"/" + id);
  }

  getAllGenre(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getGenreUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllCast)
  }

  deleteGenre(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteGenreUrl, postObj,httpOptions);
  }

  
}
