import { Directive, OnDestroy, Input,  HostListener, 
  EventEmitter,
  SimpleChanges,
  Renderer2, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[appAppDisableAfterClick]'
})

export class AppDisableAfterClickDirective   {

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  @Input('appAppDisableAfterClick') reenableButton: EventEmitter<boolean>;

  ngOnChanges(changes: SimpleChanges) {
    this.reenableButton = changes.reenableButton.currentValue;

    this.reenableButton.subscribe(_ => {
      (<HTMLButtonElement>this.el.nativeElement).disabled = false;
    });
  }

  @HostListener('click')
  onClick() {
    (<HTMLButtonElement>this.el.nativeElement).disabled = true;
  }


}





