import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchQuery:any={};
  // start=0;
  // limit=100;

  constructor(private http: HttpClient) { }


  // getAllSearchResult(searchFilter,start,limit){
  //     let urlGetAllSearchResult = environment.apiURL + environment.adminApiUrl + environment.getSearchUrl +'?filter='+encodeURIComponent(JSON.stringify(searchFilter))+'&start='+start+'&limit='+limit;
  //     return this.http.get<any>(urlGetAllSearchResult);
  // }


  



  private approvalStageMessage = new BehaviorSubject('');
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
  updateSearchMessage(message: string) {
    this.approvalStageMessage.next(message)
  }

  getSearchMessage(){
    return(this.currentApprovalStageMessage);
  }

  
  getAllSearchResult(searchQuery){
    // console.log("Find result for -> ",searchQuery);
    let urlGetAllSearchResult = environment.apiURL + environment.adminApiUrl + environment.getSearchUrl +'?filter='+encodeURIComponent(JSON.stringify(searchQuery));
    return this.http.get<any>(urlGetAllSearchResult);
  }

  deleteSearchRecord(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteAssetUrl, postObj,httpOptions);
  }






}
