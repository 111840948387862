import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {

  constructor(private http: HttpClient) { }

  updateClassification(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateClassificationUrl, postObj,httpOptions);
  }
        
  insertClassification(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertClassificationUrl, postObj,httpOptions);
  } 

  getClassification( id: any) : Observable<any> {
    
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getClassificationUrl+"/" + id);
  }

  getAllClassification(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');
    filterObj.languageId = undefined;

      let urlGetAllClassification = environment.apiURL + environment.adminApiUrl + environment.getClassificationUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllClassification)
  }

  deleteClassification(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteClassificationUrl, postObj,httpOptions);
  }

}
