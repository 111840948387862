import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {UserService} from '../../../../../services/user/user.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {awsPath} from '../../../../../configurations/configuration';
import {status } from '../../../../../configurations/configuration';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {MrssService} from '../../../../../services/mrss/mrss.service';
import {AbstractControl} from '@angular/forms';
import { errorMessages } from '../../../../../configurations/errorMessages';

import { DynamoDBService } from "../../../../../services/cognito/ddb.service";
//import { UserLoginService } from "../../../../../services/cognito/user-login.service";
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../../../../../services/cognito/cognito.service";
import {UserRegistrationService} from "../../../../../services/cognito/user-registration.service";

@Component({
  selector: 'app-addMrss',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddMrssComponent implements CognitoCallback, OnInit {
  
  isCreateNewShow:boolean = true;
  createUserSubmitted=false;

  contentReadyToReview:boolean= false;
  gender=false;
  errorImage:any='';
  status = status;
  presentUser:boolean = false;
  globalStateList:any=[];
  globalCountryList:any=[];
  globalCityList:any=[];
  globalRoleList:any=[];
  globalCitySettings:any=[];
  staticDeviceType:any=[];
  staticloginSource:any=[];
  staticRoleId:any=[];
  FileClosedCaptionURL:any;
  errorMessages = errorMessages;
  id:any;
  globalDeviceTypeList:any=[];
  globalLoginSourceList:any=[];
  update=false;
  loader = false;
  
  isContinueEditing:any;
  shareMassage:any;
  uploadImgFileclosedCaption:any=[];
  currentEmailId:any;
  createMrssQForm:FormGroup;
  createMrssQFormSubmit:boolean = false;
  
  isDeleteImageAction:boolean=false;
  errorMessage: string;

  applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    
    registerUser= {
        name: '',
        email: '',
        phone_number: '',
        password: ''
    }
    
  
    constructor( private mrssService:MrssService,private userRegistration:UserRegistrationService, public ddb: DynamoDBService, private activatedRoute:ActivatedRoute, private toastrService:ToastrService,private userService:UserService,private productConfigurationService:ProductConfigurationService , private router: Router,private commonService:CommonService,private formBuilder:FormBuilder) {
    
    }

    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

    //New Code start
      @Input() set contentId(value: any) {

        console.log("value>>>",value);

        this.createMrssQForm = this.formBuilder.group({

          "partnerName":['',Validators.required],
          "partnerId":[''],
          "mrssFeedUrl":[''],
          "videoFound":[''],
          "videoIngested":[''],
          "created_by":[localStorage.getItem('ud')]


      },)
  
          if(value == 'reset'){
  
              this.resetForm();
  
          }else {
  
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
              this.onLoad(value);
          }
          
      }
      //New Code end
      ngOnInit(){
        // theme update code start

        
        this.commonService.notify.subscribe((result) => {
            this.loader = false;
        })
                
        this.commonService.currentThemelight.subscribe((result:any)=>{
                  
          this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          
        })

        // theme update code start
         }
         
    onLoad(id) {

      // console.log("phone_number",phone_number)

      let headerTitle = {
        title:'MRSS Ingestion',
        subTitle:'Mrss Queue'
    }
        this.commonService.changeHeaderTitle(headerTitle);

      this.staticRoleId = [{

        'id':'1',
        'itemName':'Authenticated User'
      }]

    

        this.globalCitySettings = {
          singleSelection: true, 
          text:"Select",
          // filterSelectAllText:'',
          // filterUnSelectAllText:'',
          // selectAllText:'Select All',
          // unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          // classes:"myclass custom-class"
        }

      // this.activatedRoute.params.subscribe(params => {
        this.id = id; // (+) converts string 'id' to a number
        if(this.id!='null'){

        this.update = true;


        }else{

          this.update=false;
          
          
        }

    // In a real app: dispatch action to load the details here.
      // });

    
    }


    resetForm(){


      
      this.createMrssQForm = this.formBuilder.group({

        "partnerName":['',Validators.required],
        "partnerId":[''],
        "mrssFeedUrl":[''],
        "videoFound":[''],
        "videoIngested":[''],
        "created_by":[localStorage.getItem('ud')]

    },)

        this.commonService.changeloader(false);
        //code for global loader end

        this.update = false;
        this.createMrssQFormSubmit = false;

        this.staticRoleId = [{

          'id':'1',
          'itemName':'Authenticated User'
        }]


        this.globalCitySettings = {

          singleSelection: true, 
          text:"Select",
          enableSearchFilter: true,
      
        }

        this.updateSend.emit();

        this.FileClosedCaptionURL = '';

        this.showCreateNewButton('show');
        
    }


    onDeSelectAll(event, info){
        // console.log(items);
        
    }

  get l() { return this.createMrssQForm.controls; }

  openCalendar(){
   
  }

      //New Code for right side for from
      onContentReadyToReview(){

        if(!this.createMrssQForm.valid) {
           console.log("2")
           this.loader=false;
           this.createMrssQFormSubmit = true;
           return
        }

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

      }

        // is Content Ready To Review Received  

      isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            // this.createUserForm.controls['contentState'].setValue(['Completed']);
            this.onMrssSave();
           
        }
        if(event == 'Cancel'){

            // this.createUserForm.controls['contentState'].setValue(['In-Progress']);
         //   this.onMrsssave();
           

        }
       
      }

  

      onMrssSave(){

      if(this.update == true){
        return;
      }

      console.log("createMrssQFormSubmit>>",this.createMrssQForm.value);

        this.loader=true;
        this.presentUser=false;
        this.createMrssQFormSubmit = true;

        if(this.errorImage != ''){
          console.log("1")
          this.loader = false;
        
          return
        }


          if(!this.createMrssQForm.valid) {
              console.log("2")
              this.loader=false;
              return
          }

            this.mrssService.insertMrssQueue(this.createMrssQForm.value)
            .subscribe((result:any)=>{

              this.loader = false;
              this.update = false;
              
              this.resetForm();
              this.createUserSubmitted = false;
                console.log("save========================================",result);
                this.toastrService.success('Mrss Created Successfully.');
                // this.router.navigate(['users/list']);

            });

          
          
      }

    errorClear(){
      this.presentUser = false ; 
    }

    
    cognitoCallback(message: string, result: any) {
        
        if (message != null) { //error
            this.loader = false;
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { //success
            this.ddb.writeLogEntry("login");
            //this.router.navigate(['/securehome']);
             console.log("Cognito Callback===>",result);
        }
    }

        
    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
}   
