import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class MenuCategoryService {

  
    constructor(private http: HttpClient) { }

    updateMenuCategory(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateMenuCategoryUrl, postObj,httpOptions);
    }
    
    insertMenuCategory(postObj:any) : Observable<any> {

        if(postObj.langId== null){
            postObj.langId = '1';
        }

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertMenuCategoryUrl, postObj,httpOptions);
    }    

    getMenuCategory( id: any, langId:any ) : Observable<any> {
        
        langId = localStorage.getItem('languageId');

        if(langId== null){
            langId = '1';
        }

        if(id){
            return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getMenuCategoryUrl+"/" + id +"/" + langId);
        }
        
    }

    getAllMenuCategory(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');

        let urlGetAllMenuCategory = environment.apiURL + environment.adminApiUrl + environment.getMenuCategoryUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllMenuCategory)
    }
    
    deleteMenuCategory(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteMenuCategoryUrl, postObj,httpOptions);
    }

}
