import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  insertUser(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertUserUrl, postObj,httpOptions);
  }

  getUser( id: any) : Observable<any> {
        
      if(id){

      } else {
          id = 1;
      }
      
      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getUserUrl+"/" + id);
  }

  getUserCheck( filterObj: any) : Observable<any> {
      
    return this.http.post(environment.apiURL + environment.adminApiUrl + environment.getUserUrl + environment.checkUrl, filterObj,httpOptions);
  
   }


  getAllUser(filterObj,start,limit){
      let urlGetAllUser = environment.apiURL + environment.adminApiUrl + environment.getUserUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllUser)
  }

  deleteUser(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteUserUrl, postObj,httpOptions);
  }

  updateUser(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateUserUrl, postObj,httpOptions);
  }



  


}
