import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {UserService} from '../../../../../services/user/user.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {awsPath} from '../../../../../configurations/configuration';
import {status } from '../../../../../configurations/configuration';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {MrssService} from '../../../../../services/mrss/mrss.service';
import {AbstractControl} from '@angular/forms';
import { errorMessages } from '../../../../../configurations/errorMessages';
import {UplynkAdServerService} from '../../../../../services/uplynk_adServer/uplynk-ad-service.service';
import { DynamoDBService } from "../../../../../services/cognito/ddb.service";
//import { UserLoginService } from "../../../../../services/cognito/user-login.service";
import {UserRegistrationService} from "../../../../../services/cognito/user-registration.service";

@Component({
  selector: 'app-addMrssPartner',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddMrssPartnerComponent implements  OnInit {
  
  isCreateNewShow:boolean = true;
  createUserSubmitted=false;

  uplynkadserverList = [];
  trailerVideoList = [];

  contentReadyToReview:boolean= false;
  gender=false;
  errorImage:any='';
  status = status;
  presentUser:boolean = false;
  globalStateList:any=[];
  globalCountryList:any=[];
  globalCityList:any=[];
  globalRoleList:any=[];
  globalSettings:any=[];
  staticDeviceType:any=[];
  staticloginSource:any=[];
  staticRoleId:any=[];
  FileClosedCaptionURL:any;
  errorMessages = errorMessages;
  id:any;
  globalDeviceTypeList:any=[];
  globalLoginSourceList:any=[];
  update=false;
  loader = false;

  geoZoneList:any =[];
  producTypeList:any =[];
  primaryNavigationList:any =[];
  menuCategoryList:any =[];
  assetGroupList:any =[];
  
  isContinueEditing:any;
  shareMassage:any;
  uploadImgFileclosedCaption:any=[];
  currentEmailId:any;
  createMrssPartnerForm:FormGroup;
  createMrssPartnerFormSubmit:boolean = false;
  
  isDeleteImageAction:boolean=false;
  errorMessage: string;
  dropdownSettings={};

  assetCategoryList:any=[];
  applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    
    registerUser= {
        name: '',
        email: '',
        phone_number: '',
        password: ''
    }
    
  
    constructor(private uplynkAdServerService:UplynkAdServerService, private mrssService:MrssService,private userRegistration:UserRegistrationService, public ddb: DynamoDBService, private activatedRoute:ActivatedRoute, private toastrService:ToastrService,private userService:UserService,private productConfigurationService:ProductConfigurationService , private router: Router,private commonService:CommonService,private formBuilder:FormBuilder) {
    
    }

    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

    //New Code start
      @Input() set contentId(value: any) {

        this.getGeoZoneInfo();
        this.getProductTypeInfo();
        this.getNavigationInfo();
        this.getGlobalMenuCategory();
        this.getGlobaltrailervideo();
        this.getGlobalAssetGroup();
        this.getGlobalAssetCategory();
        this.getAllUplynkAdServer();
        this.createMrssPartnerForm = this.formBuilder.group({

          "channelName": ['', Validators.required],
          "mediaCredit": [''],
          "mapGeoZone":  [[]],
          "mapProductType": [[]],
          "mapPrimaryNavigation": [[]],
          "mapMenuCategory":[[]],
          "mapAssetCategory":[[]],
          "mapAssetGroup":[[]],
          "mapAsset":[[]],
          "mapUplynkAdServer":[[]],
          "isPublish":[''],
          "id":[this.id],
          "created_by":[localStorage.getItem('ud')]


      },)
  
          if(value == 'reset'){
  
              this.resetForm();
  
          }else {
  
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
              this.onLoad(value);
          }
          
      }
      //New Code end
    
        ngOnInit() {

          this.dropdownSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }
          // theme update code start
          
          this.commonService.currentThemelight.subscribe((result:any)=>{
            
              this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
              
         })
  
         // theme update code start
      }

      onDeSelectAll(e, info ,i){
     
            if(info =='geoZone'){
              this.createMrssPartnerForm.controls['mapGeoZone'].setValue([]);

            }          

            if(info =='primaryNavigation'){
              this.createMrssPartnerForm.controls['mapPrimaryNavigation'].setValue([]);

            }
            if(info =='assetCategory'){
              this.createMrssPartnerForm.controls['mapAssetCategory'].setValue([]);

            }

            if(info =='uplynkadserver'){
              this.createMrssPartnerForm.controls['mapUplynkAdServer'].setValue([]);

            }     

            if(info =='producType'){
              this.createMrssPartnerForm.controls['mapProductType'].setValue([]);

            }
            if(info == 'assetGroup'){
              this.createMrssPartnerForm.controls['mapAssetGroup'].setValue([]);

            }
            if(info == 'menuCategory'){
              this.createMrssPartnerForm.controls['mapMenuCategory'].setValue([]);
  
          }

      }
        
         
    onLoad(id) {

      this.id = id;

      console.log("id>>>>",this.id);

      // console.log("phone_number",phone_number)

      let headerTitle = {
        title:'MRSS Ingestion',
        subTitle:'Mrss Partner'
      }

      this.commonService.changeHeaderTitle(headerTitle);

      this.staticRoleId = [{
          'id':'1',
          'itemName':'Authenticated User'
      }]

    this.globalSettings = {

      singleSelection: false, 
      text:"Select",
      enableSearchFilter: true,

    }

      // this.activatedRoute.params.subscribe(params => {
      this.id = id; // (+) converts string 'id' to a number

      if(this.id!='null'){
      
        console.log('this.id===>>>',this.id);
          this.update=true;
         this.getMrssPartner(this.id);

      }else{

        this.update=false;
        
      }

    }


    resetForm(){

        this.commonService.changeloader(false);
        //code for global loader end

        this.update = false;
        this.createMrssPartnerFormSubmit = false;

        this.staticRoleId = [{

          'id':'1',
          'itemName':'Authenticated User'
        }]

        this.createMrssPartnerForm = this.formBuilder.group({

            "channelName": ['', Validators.required],
            "mediaCredit": [''],
            "mapGeoZone":  [[]],
            "mapProductType": [[]],
            "mapPrimaryNavigation": [[]],
            "mapMenuCategory":[[]],
            "mapAssetCategory":[[]],
            "mapAssetGroup":[[]],
            "mapAsset":[[]],
            "adConfig":[''],
            "id":[''],
            "isPublish":[''],
            "created_by":[localStorage.getItem('ud')]


        },)

        this.globalSettings = {

          singleSelection: false, 
          text:"Select",
          enableSearchFilter: true,
      
        }

        this.FileClosedCaptionURL = '';
        this.updateSend.emit();

        this.showCreateNewButton('show');
        
    }

    showCreateNewButton(isShow){
      if(isShow == 'show'){
          this.isCreateNewShow = true;
      }
      else{
          this.isCreateNewShow = false;
      }
   }

    

  get l() { return this.createMrssPartnerForm.controls; }

      //New Code for right side for from
      onContentReadyToReview(){

        if(!this.createMrssPartnerForm.valid) {
           
           this.loader=false;
           this.createMrssPartnerFormSubmit = true;
           return
        }

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

      }

      // is Content Ready To Review Received  

      isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.onSaveMrssPartner();
           
        }
        if(event == 'Cancel'){

      //    this.onSaveMrssPartner();
           
        }
       
      }

  onSaveMrssPartner(){

    console.log("this.createMrssPartnerForm.value",this.createMrssPartnerForm.value.mapGeoZone);

      if(this.update == true){
        this.createMrssPartnerForm.controls['id'].setValue(this.id);

        console.log("save>>>>",this.createMrssPartnerForm.value);
  
          this.mrssService.updateMrssPartner(this.createMrssPartnerForm.value).subscribe(()=>{

          this.loader=false;
            //New Code for right side for from
            this.update = false;
        
            this.createMrssPartnerFormSubmit = false;
            this.toastrService.success('Mrss Partner updated Successfully.');
            this.resetForm();

        })
      }else {

        delete this.createMrssPartnerForm.value.id;

          this.mrssService.insertMrssPartner(this.createMrssPartnerForm.value).subscribe(()=>{

            this.loader=false;
            //New Code for right side for from
            this.update = false;
        
            this.createMrssPartnerFormSubmit = false;
            this.toastrService.success('Mrss Partner Created Successfully.');
            this.resetForm();
        })
      }

      
  }

  getMrssPartner(id){
    this.mrssService.getMrssPartner(id)
    .subscribe((result:any)=>{

      this.createMrssPartnerForm.controls['channelName'].setValue(result.data[0].channelName);

      this.createMrssPartnerForm.controls['mediaCredit'].setValue(result.data[0].mediaCredit);

        if(result.data[0].isPublish!= null){

          this.createMrssPartnerForm.controls['isPublish'].setValue(result.data[0].isPublish);

        }
            

  
    if(result.data[0].results.geoZoneMapping.length>0){
      let array =[];

      result.data[0].results.geoZoneMapping.forEach(element => {

        this.geoZoneList.forEach(obj=>{

          if(element.geoZoneId == obj.id){

            array.push(obj);
            this.createMrssPartnerForm.controls['mapGeoZone'].setValue(array);
          }
        })
        
      });

    }

    
    if(result.data[0].results.uplynkAdServerMapping.length>0){
        
        let array =[];

        result.data[0].results.uplynkAdServerMapping.forEach(element => {
          console.log("element",element);

          this.uplynkadserverList.forEach(obj=>{

            if(element.uplynkAdserverId == obj.id){

              array.push(obj);
              this.createMrssPartnerForm.controls['mapUplynkAdServer'].setValue(array);
            }
          })
          
        });

    }

    
    if(result.data[0].results.productTypeMapping.length>0){
      let array =[];

      result.data[0].results.productTypeMapping.forEach(element => {

        this.producTypeList.forEach(obj=>{

          if(element.productTypeId == obj.id){

            array.push(obj);
            this.createMrssPartnerForm.controls['mapProductType'].setValue(array);
          }
        })
        
      });
    }
 
    if(result.data[0].results.primaryNavigationMapping.length>0){
      let array =[];

      result.data[0].results.primaryNavigationMapping.forEach(element => {

        this.primaryNavigationList.forEach(obj=>{

          if(element.primaryNavigationId == obj.id){

            array.push(obj);
            this.createMrssPartnerForm.controls['mapPrimaryNavigation'].setValue(array);
          }
        })
        
      });
    


    if(result.data[0].results.menuCategoryMapping.length>0){
        
      let array =[];

      result.data[0].results.menuCategoryMapping.forEach(element => {

        this.menuCategoryList.forEach(obj=>{

          if(element.menuCategoryId == obj.id){

            array.push(obj);
            this.createMrssPartnerForm.controls['mapMenuCategory'].setValue(array);
          }
        })

      })
    }

    
    if(result.data[0].results.assetGroupMapping.length>0){
        
      let array =[];

      result.data[0].results.assetGroupMapping.forEach(element => {

        this.assetGroupList.forEach(obj=>{

          if(element.assetGroupId == obj.id){

            array.push(obj);
            this.createMrssPartnerForm.controls['mapAssetGroup'].setValue(array);
          }
        })

      })
    }

    
    if(result.data[0].results.assetMapping.length>0){
        let array =[];

          result.data[0].results.assetMapping.forEach(element => {

            this.trailerVideoList.forEach(obj=>{

              if(element.assetId == obj.id){
                array.push(obj);
                this.createMrssPartnerForm.controls['mapAsset'].setValue(array);
              }
            })

          })
      }

  }

      if(result.data[0].results.assetCategoryMapping.length>0){

        let array =[];

        result.data[0].results.assetCategoryMapping.forEach(element => {

          this.assetCategoryList.forEach(obj=>{

            if(element.assetCategoryId == obj.id){

              array.push(obj);
              this.createMrssPartnerForm.controls['mapAssetCategory'].setValue(array);
            }
          })
          
        });

      }

     })
  }


      //pravin code start
 getGeoZoneInfo(){

      let obj={
      }
      
      this.productConfigurationService.getAllGeoZone(obj,0,1000000)
      .subscribe((result:any)=>{
          this.geoZoneList = [];

          if(result.data.data.length > 0){


              for (let geoZoneObj of result.data.data) {

                  this.geoZoneList.push(
                  {
                      'id': geoZoneObj.geoZoneId,
                      'itemName': geoZoneObj.geoZoneName
                  });

              }
          }
  
      }) 
}

getGlobalAssetCategory(){

  this.commonService.getGlobalAssetCategory({},0,1000).subscribe((result:any)=>{  
      let mapAssetCategoryArrayTemp = [];
    this.assetCategoryList =[];
      if(result.data.data.length > 0){

            result.data.data.forEach(element => {

                  if(element.assetCategoryName!=''){
                
                    this.assetCategoryList.push(
                    {
                        'id': element.assetCategoryId,
                        'itemName': element.assetCategoryName
                    });
                  } 
                
              });   
          
      }
      
  });
}

getGlobaltrailervideo(){
        
  this.commonService.getGlobalTrailerVideo({},0,1000000).subscribe((result:any)=>{
      
      this.trailerVideoList = [];
      
      if(result.data.data.length > 0){       
          for (let trailerVideoLists of result.data.data) {

              if(trailerVideoLists.assetName!=''){
              
                  this.trailerVideoList.push(
                  {
                      'id': trailerVideoLists.assetId,
                      'itemName': trailerVideoLists.assetName
                  });
              } 
          }
      } 

  });
  
}

getProductTypeInfo(){

  this.commonService.getGlobalProductType({},0,10000).subscribe((result:any)=>{

      this.producTypeList = [];

      if(result.data.data.length > 0){

          for (let ProducTypeLists of result.data.data) {


              if(ProducTypeLists.productTypeName!=''){
              this.producTypeList.push(
                  {
                      'id': ProducTypeLists.productTypeId,
                      'itemName': ProducTypeLists.productTypeName
                  });
              }
          }

        }       
  });
}

getNavigationInfo(){

  this.commonService.getGlobalPrimaryNavigation({},0,10000).subscribe((result:any)=>{
      
      this.primaryNavigationList = [];
     
      if(result.data.data.length > 0){  

          for (let primaryNavigationLists of result.data.data) {

      
              if(primaryNavigationLists .primanyNavigationName!=''){

              this.primaryNavigationList.push(
                  {
                      'id': primaryNavigationLists.primaryNavigationId,
                      'itemName': primaryNavigationLists.primanyNavigationName
                  });
                  
              } 
          }
         
      }
  
  })
}

getGlobalMenuCategory(){

  this.commonService.getGlobalMenuCategory({},0,10000).subscribe((result:any)=>{
   
      this.menuCategoryList = [];
      
      if(result.data.data.length > 0){   

          for (let menuCategoryList of result.data.data) {


              if(menuCategoryList.menuCategoryName!=''){
              
                  this.menuCategoryList.push(
                  {
                      'id': menuCategoryList.menuCategoryId,
                      'itemName': menuCategoryList.menuCategoryName
                  });
              } 
          }
      } 

  });
}

getGlobalAssetGroup(){

this.commonService.getGlobalAssetGroup({},0,1000).subscribe((result:any)=>{

    this.assetGroupList = [];

    if(result.data.data.length > 0){       
        for (let assetGroupList of result.data.data) {

            if(assetGroupList.assetGroupName!=''){
            
                this.assetGroupList.push(
                {
                    'id': assetGroupList.assetGroupId,
                    'itemName': assetGroupList.assetGroupName
                });
            } 
        }
    } 

});
}

getAllUplynkAdServer(){

  this.loader=true;
  
  this.uplynkAdServerService.getAllUplynkadserver({},1,100000)
  .subscribe((result:any)=>{
      
      this.loader=false;
      
      console.log('getAllUplynkAdServer===>',result.data.data);
      
      this.uplynkadserverList = [];

      if(result.data.data.length > 0){

          for (let uplynkadserver of result.data.data) {

            if(uplynkadserver.uplynkAdServerId!=''){
            
                this.uplynkadserverList.push(
                {
                    'id': uplynkadserver.uplynkAdServerId,
                    'itemName': uplynkadserver.uplynkAdServerTitle
                });
            } 
          }
      }

  }) 
  
}
}   
