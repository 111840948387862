import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { GeoZoneService } from '../../../../../services/geo_zone/geo_zone.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Loginshared } from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { awsPath } from '../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../../../services/common_Service/common.service';
import { from } from 'rxjs';
import {SessionHelper} from '../../../../../services/localstorage';


@Component({
  selector: 'app-addGeoZone',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentGeoZone implements OnInit {

    isCreateNewShow:boolean = true;
    contentReadyToReview:boolean= false;
    shareMassage :any;
    isUserLoggedIn:boolean;
    createGeoZoneForm:FormGroup;
    isGeoZoneSubmitted:boolean=false;
    geoMapping:any={};
    mapping:any=[];
    globalContinentSettings={};
    globalCountrySettings={};
    globalStateSettings={};
    globalCitySettings={}

    globalContinentList=[];
    globalCountryList=[];
    globalStateList=[];
    globalCityList=[];

    errorMessages = errorMessages;
    loader:boolean=false;

    update:boolean=false;
    id:any;

    //Dynaic fromArray
    variableForm:FormGroup;
    items: FormArray;
    countryFilterObj:any;
    geoMappingFilter:any={};
    // cityFilter:any={};

    selectedItem = [];

    isDeleteAction: boolean=false;
    deleteGeoMappingIndex:any;


    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
    @Input() set contentId(value: any) {

        this.isGeoZoneSubmitted = false;

        if(value == 'reset'){

            this.resetForm();

        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
    applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    constructor(private commonService: CommonService, private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private geoZoneService: GeoZoneService, private formBuilder:FormBuilder) {
        loginSharedService.sendUserLoggedIn$.subscribe(mission => {
            this.isUserLoggedIn = mission;
            let uid = localStorage.getItem('ud');
            if (uid !== undefined && uid != null) {
                this.isUserLoggedIn = true;
            }
        });
    }

    roleAsses:any={};
    roleConfiguration:any=[];
    ngOnInit(){

        this.roleAsses.geoZoneEdit = false;
        this.roleAsses.geoZone = false;
        this.roleAsses.geoZoneCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

           this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Geo Zone') ){
                  
                  this.roleAsses.geoZone = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Geo Zone') ){
                  
                  this.roleAsses.geoZoneEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Geo Zone')){

                  this.roleAsses.geoZoneCreate = true;
              
              }
            })
        // theme update code start
       this.commonService.currentThemelight.subscribe((result:any)=>{
          
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
     })
    // theme update code start
    }

    editCreate:boolean = false;
    onCreateNew(){
  
          if(this.roleAsses.geoZoneCreate == true){
  
              this.editCreate = true;
          }
          
          this.isCreateNewShow=false
  
    }

    onLoad(id) {

        
        if(this.id != 'null' && this.roleAsses.geoZoneEdit == true){

            this.editCreate = true;

        }else{

            this.editCreate = false;

        }
        
        // this.geoMapping.mapping[0].globalContinentList=[];
        // this.geoMapping.mapping[0].globalCountryList=[];
        // this.geoMapping.mapping.globalStateList=[];
        // this.geoMapping.mapping.globalCityList=[];

        this.geoMappingFilter={};
  
        
        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        let headerTitle = {
            title:'Master',
            subTitle:'Geo Zone'
        }

        this.commonService.changeHeaderTitle(headerTitle);

        this.createGeoZoneForm = this.formBuilder.group({
            geoZoneName:['', Validators.compose([Validators.required])],
            // latitude:['',Validators.pattern('[1-9]+(\.[0-9]+)?')],
            latitude:[''],
            // mapAssetGroup:[[]],
            longitude:[''],
            // longitude:['',Validators.pattern('[1-9]+(\.[0-9]+)?')],
            description:[''],
            isActive:['1'],
            mapGeoMapping:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

            // geoMappingArray: this.formBuilder.array([ this.createGeoMappingItem() ])
        });

        //add dynamic field
        this.variableForm = this.formBuilder.group({
            items: this.formBuilder.array([ this.createItem() ])
        });

        this.globalContinentSettings = {
            singleSelection: true, 
            text:"Select",
            position:'bottom',
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }
        this.globalCountrySettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }
        this.globalStateSettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }
        this.globalCitySettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }


        // this.activatedRoute.params.subscribe(params => {

            // this.id = +params['id']; // (+) converts string 'id' to a number
            this.id = id; // (+) converts string 'id' to a number
                if(this.id!='null'){

                    console.log('this.id===>>>',this.id);
                    this.update = true;
                    this.getGeoZoneInfo(this.id)
                    this.getGlobalContinentList(this.geoMappingFilter);
                    this.getGlobalCountryList(this.geoMappingFilter);
                    this.getGlobalStateList(this.geoMappingFilter);
                    this.getGlobalCityList(this.geoMappingFilter);
                   
                }else{
                    this.getGlobalContinentList(this.geoMappingFilter);
                    this.getGlobalContinentListNew(this.geoMappingFilter,0);
                    this.getGlobalCountryListNew(this.geoMappingFilter,0);
                    this.getGlobalStateListNew(this.geoMappingFilter,0);
                    this.getGlobalCityListNew(this.geoMappingFilter,0);
                 
                    this.update = false;
                }

        // });

    }

    onDeSelectAll(event, info, i){
       
       console.log("ranjeet------onDeSelectAll");
       
       
        if(info =='continentId'){
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('continentId').setValue([]);
            objItems.controls[i].get('countryId').setValue([]);
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            
            //this.createGeoZoneForm.controls['continentId'].setValue([]);
        }
        else if(info =='countryId'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('countryId').setValue([]);
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            //this.createGeoZoneForm.controls['countryId'].setValue([]);
        }
        else if(info =='stateId'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            //this.createGeoZoneForm.controls['stateId'].setValue([]);
        }
        else if(info =='cityId'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('cityId').setValue([]);
            
            //this.variableForm.controls['items'].controls[i].controls['cityId'].setValue([]);
            //this.createGeoZoneForm.controls['cityId'].setValue([]);
        }
    }


    get l() { return this.createGeoZoneForm.controls; }

    getGeoZoneInfo(id){
            
        // this.loader=true;
        this.commonService.changeloader(true);

        this.geoZoneService.getGeoZone(id)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);

            console.log("result==========>",result);
            this.createGeoZoneForm.controls['geoZoneName'].setValue(result.data[0].geoZoneName);
            this.createGeoZoneForm.controls['latitude'].setValue(result.data[0].latitude);
            this.createGeoZoneForm.controls['longitude'].setValue(result.data[0].longitude);
            this.createGeoZoneForm.controls['description'].setValue(result.data[0].description);
            this.createGeoZoneForm.controls['isActive'].setValue(result.data[0].isActive.toString());

            // console.log("result.data[0].results.geoMapping[0]=========>>>>>>>>... ",result.data[0].geoMapping[0]);

            this.createGeoZoneForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createGeoZoneForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
         
            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createGeoZoneForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createGeoZoneForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            if(result.data[0].geoMapping.length > 0){

                this.variableForm = this.formBuilder.group({
                    items: this.formBuilder.array([])
                });

                this.items = this.variableForm.get('items') as FormArray;
                
                for(let i=0;i<result.data[0].geoMapping.length;i++){

                    setTimeout (() => {

                        console.log("123",result.data[0].geoMapping[i]);

                                this.items.push(this.updateItem(result.data[0].geoMapping[i],i));
                          
                     }, 1000);

                   
                    console.log("===============================================");
                }
                
            }
                
        });
    }

    onItemSelect(item:any, geoMappingName,i){
    
        console.log("ranjeet------ON Item Select");
        /*console.log("Sanjeet",this.variableForm);
        console.log("Items--->",this.variableForm.controls['items']);
        console.log("============",this.variableForm.controls['items'].controls[0]);
        console.log("=======xxx=====",this.variableForm.controls['items'].controls[0].controls['countryId'].setValue([]));
        */
        console.log(item);
        if(geoMappingName == 'continent'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('countryId').setValue([]);
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            
            this.geoMappingFilter.continentId=item.id;
            // this.getGlobalCountryList(this.geoMappingFilter);
            this.getGlobalCountryListNew(this.geoMappingFilter,i);
        }
        if(geoMappingName == 'country'){
        
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            
            this.geoMappingFilter.countryId=item.id;
            // this.getGlobalStateList(this.geoMappingFilter);
            this.getGlobalStateListNew(this.geoMappingFilter , i);
        }
        else if(geoMappingName == 'state'){
        
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('cityId').setValue([]);
        
            //this.variableForm.controls['items'].controls[i].controls['cityId'].setValue([]);
            
            this.geoMappingFilter.stateId=item.id;
            // this.getGlobalCityList(this.geoMappingFilter);
            this.getGlobalCityListNew(this.geoMappingFilter , i);
        }
        else if(geoMappingName == 'city'){
            console.log("selected city",item);
        }    
    }

    onItemDeSelect(item:any, geoMappingName, i){
        
        console.log("ranjeet------onItemDeSelect");
        
        if(geoMappingName == 'continent'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('continentId').setValue([]);
            objItems.controls[i].get('countryId').setValue([]);
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
            
            this.geoMappingFilter.continentId = [];
            // this.getGlobalCountryList({});
        }
        if(geoMappingName == 'country'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('countryId').setValue([]);
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);

            this.geoMappingFilter.countryId = [];
            this.createGeoZoneForm.controls['stateId'].setValue([]);
           
            // this.getGlobalStateList(this.geoMappingFilter);
        }
        else if(geoMappingName == 'state'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('stateId').setValue([]);
            objItems.controls[i].get('cityId').setValue([]);
    
            this.geoMappingFilter.stateId = [];
            this.createGeoZoneForm.controls['cityId'].setValue([]);

            // this.getGlobalCityList(this.geoMappingFilter);
        }
        else if(geoMappingName == 'city'){
            
            let objItems = this.variableForm.get('items') as FormArray;
            objItems.controls[i].get('cityId').setValue([]);

            this.geoMappingFilter.cityId = [];
            // console.log("selected city",item);
        }    
    }

    getGlobalContinentList(ContinentFilter){

        this.commonService.getGlobalContinentList(ContinentFilter).subscribe((result:any)=>{
            
            this.globalContinentList = [];
            
            if(result.data.data.length > 0){    
                
                this.globalContinentList.push({id: 1, itemName: "All"})

                for (let globalContinentList of result.data.data) {

                    // if(globalContinentList.continentId!='' && globalContinentList.continentId != 1){
                        if(globalContinentList.continentId!='' && globalContinentList.continentId!=1){

                    
                        this.globalContinentList.push(
                        {
                            'id': globalContinentList.continentId,
                            'itemName': globalContinentList.continentName
                        });
                    } 
                }

                console.log("globalContinentList>>",this.globalContinentList);
            } else{
                
                this.globalContinentList.push({id: 1, itemName: "All"})

            }

        });
    }

    getGlobalCountryList(countryFilter){

        this.commonService.getGlobalCountryList(countryFilter).subscribe((result:any)=>{
            
            this.globalCountryList = [];
            
            if(result.data.data.length > 0){  
                
                this.globalCountryList.push({id: 1, itemName: "All"})

                for (let globalCountryList of result.data.data) {

                    // if(globalCountryList.countryId!='' && globalCountryList.countryId != 1){
                        if(globalCountryList.countryId!='' && globalCountryList.continentId!=1){

                    
                        this.globalCountryList.push(
                        {
                            'id': globalCountryList.countryId,
                            'itemName': globalCountryList.countryName
                        });
                    } 
                }
            } else{
                
                this.globalCountryList.push({id: 1, itemName: "All"})

            }

        });
    }

    getGlobalStateList(stateFilter){
       
        this.commonService.getGlobalStateList(stateFilter).subscribe((result:any)=>{
            
            this.globalStateList = [];
            
            if(result.data.data.length > 0){
                
                this.globalStateList.push({id: 1, itemName: "All"})
                for (let globalStateList of result.data.data) {

                    // if(globalStateList.stateId!=''  && globalStateList.stateId != 1){
                        if(globalStateList.stateId!='' && globalStateList.continentId!=1){

                            this.globalStateList.push(
                            {
                                'id': globalStateList.stateId,
                                'itemName': globalStateList.stateName
                            });

                    } 
                }
            } else{
                
                this.globalStateList.push({id: 1, itemName: "All"})

            }

        });
    }

    getGlobalCityList(cityFilter){

        this.commonService.getGlobalCityList(cityFilter).subscribe((result:any)=>{
            
            this.globalCityList = [];
            
            if(result.data.data.length > 0){  

                this.globalCityList.push({id: 1, itemName: "All"})

                for (let globalCityList of result.data.data) {

                    // if(globalCityList.cityId!='' && globalCityList.cityId != 1){
                        if(globalCityList.cityId!=''&& globalCityList.continentId!=1){
                    
                            this.globalCityList.push(
                            {
                                'id': globalCityList.cityId,
                                'itemName': globalCityList.cityName
                            });
                    } 
                }
                    
            }  else{
                
                this.globalCityList.push({id: 1, itemName: "All"})

            }

        });
    }

    getGlobalContinentListNew(ContinentFilter,i){
     
        this.mapping[i].globalContinentList=[];
             
        this.commonService.getGlobalContinentList(ContinentFilter).subscribe((result:any)=>{
            
            if(result.data.data.length > 0){  

                this.mapping[i].globalContinentList.push({id: 1, itemName: "All"})
                console.log('this.mapping[i]123',this.mapping[i].globalContinentList)

                for (let globalContinentList of result.data.data) {

                    if(globalContinentList.continentId!='' && globalContinentList.continentId != 1){
                    
                        this.mapping[i].globalContinentList.push(
                        {
                            'id': globalContinentList.continentId,
                            'itemName': globalContinentList.continentName
                        });
                    } 
                }
            }  else{
                
                this.mapping[i].globalContinentList.push({id: 1, itemName: "All"});

            }

        });
    }

    getGlobalCountryListNew(countryFilter,i){

        this.commonService.getGlobalCountryList(countryFilter).subscribe((result:any)=>{
            
            this.mapping[i].globalCountryList=[];
            
            if(result.data.data.length > 0){ 
                
                this.mapping[i].globalCountryList.push({id: 1, itemName: "All"})

                console.log('this.mapping[i]123',this.mapping[i].globalCountryList)

                for (let globalCountryList of result.data.data) {

                    // if(globalCountryList.countryId!='' && globalCountryList.countryId != 1){
                        if(globalCountryList.countryId!='' && globalCountryList.continentId!=1){
                    
                        this.mapping[i].globalCountryList.push(
                        {
                            'id': globalCountryList.countryId,
                            'itemName': globalCountryList.countryName
                        });
                    } 
                }
            } else{
                
                this.mapping[i].globalCountryList.push({id: 1, itemName: "All"})

            }


        });
    }

    getGlobalStateListNew(stateFilter,i){

        this.mapping[i].globalStateList=[];
        this.commonService.getGlobalStateList(stateFilter).subscribe((result:any)=>{
         
            if(result.data.data.length > 0){ 
                
                this.mapping[i].globalStateList.push({id: 1, itemName: "All"})
                console.log('this.mapping[i]123',this.mapping[i].globalStateList)

                for (let globalStateList of result.data.data) {

                    // if(globalStateList.stateId!=''  && globalStateList.stateId != 1){
                        if(globalStateList.stateId!=''&& globalStateList.continentId!=1){

                        this.mapping[i].globalStateList.push(
                        {
                            'id': globalStateList.stateId,
                            'itemName': globalStateList.stateName
                        });
                    } 
                }
            }  else{
                
                this.mapping[i].globalStateList.push({id: 1, itemName: "All"})

            }


        });
    }

    getGlobalCityListNew(cityFilter,i){
    
        this.mapping[i].globalCityList=[]; 
        this.commonService.getGlobalCityList(cityFilter).subscribe((result:any)=>{
           
            if(result.data.data.length > 0){ 
                
                this.mapping[i].globalCityList.push({id: 1, itemName: "All"})
                console.log('this.mapping[i]123',this.mapping[i].globalCityList)

                for (let globalCityList of result.data.data) {

                    // if(globalCityList.cityId!='' && globalCityList.cityId != 1){
                        if(globalCityList.cityId!=''&& globalCityList.continentId!=1){

                            this.mapping[i].globalCityList.push(
                            {
                                'id': globalCityList.cityId,
                                'itemName': globalCityList.cityName
                            });
                    } 
                }

            }  else{
                
                this.mapping[i].globalCityList.push({id: 1, itemName: "All"})

            }

        });
    }

    savecreateGeoZone(){ 


        console.log("this.createGeoZoneForm=true;",this.createGeoZoneForm.value);
        
        // this.loader = true;
        this.commonService.changeloader(true);

        this.isGeoZoneSubmitted=true;
        this.createGeoZoneForm.controls['mapGeoMapping'].setValue(this.variableForm.value.items);

        if(!this.createGeoZoneForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }


        if(this.update == true){

            if(this.roleAsses.getGeoEdit == false){
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }


            this.createGeoZoneForm.controls['id'].setValue(this.id);
            this.geoZoneService.updateGeoZone(this.createGeoZoneForm.value)
            .subscribe((result:any)=>{

                // this.loader=false;
                this.commonService.changeloader(false);

                // this.router.navigate(['master/geo_zone/list']);

                 //New Code for right side for from
                 this.update = false;
              //   this.updateSend.emit();
                 this.resetForm();
                 this.isGeoZoneSubmitted = false;

                this.toastrService.success('Geo Zone  Updated Successfully.');
                console.log('this.result===>>>',result);

            })
        }

        if(this.update == false){

            if(this.roleAsses.geoZoneCreate == false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

            this.createGeoZoneForm.value.id = undefined ;

            this.geoZoneService.insertGeoZone(this.createGeoZoneForm.value)
            .subscribe((result:any)=>{

                // this.loader=false;
                this.commonService.changeloader(false);
                
                // this.router.navigate(['master/geo_zone/list']);

                 //New Code for right side for from
                 this.update = false;
               //  this.updateSend.emit();
                 this.resetForm();
                 this.isGeoZoneSubmitted = false;

                this.toastrService.success('Geo Zone Created Successfully.');
                console.log('this.result===>>>',result);
    
            })

        }
    }

    /*start dynamic add form fields */
    updateItem(task , i): FormGroup {


        console.log("task>>>",task );
        console.log("i>>>",i );


        this.mapping.push({
                globalContinentList:[],
                globalCountryList:[],
                globalStateList:[],
                globalCityList:[]  
            })
         
            // || (task.continentId== [] && task.countryId== [] && task.stateId== [] && task.cityId==[])
        

        if((task.continentId== "" && task.countryId== "" && task.stateId== "" && task.cityId=="")){
          
            task.continentId=[]; 
            task.countryId=[];
            task.stateId=[];
            task.cityId=[];

            this.getGlobalContinentListNew({},0);
            this.getGlobalCountryListNew({},i);
            this.getGlobalStateListNew({},i);
            this.getGlobalCityListNew({},i);

        }else{

            this.getGlobalContinentListNew({},0);
        
            this.geoMappingFilter.continentId=task.continentId;
            this.getGlobalCountryListNew(this.geoMappingFilter,i);
        
            this.geoMappingFilter.countryId=task.countryId;
            this.getGlobalStateListNew(this.geoMappingFilter,i);
    
            this.geoMappingFilter.stateId=task.stateId;
            this.getGlobalCityListNew(this.geoMappingFilter,i);

            if(task.continentId == null){

                task.continentId=[];
            }
    
            if(task.countryId == null){
    
                task.countryId=[];
            }
    
            if(task.stateId == null){
    
                task.stateId=[];
            }

            if(task.cityId == null){
    
                task.cityId=[];
            }

            console.log("task>>",task);
            console.log("task>>",task.continentId);
            console.log("task>>",task.countryId);
            console.log("task>>",task.stateId);
            console.log("task>>",task.cityId);

            if(task.continentId!= 0 && this.globalContinentList.length>0){

                this.globalContinentList.forEach(element => {
                    if(element.id == task.continentId){
                        task.continentId=element;
                       
                    }      
                });
            }
            
            if(task.countryId!= 0 && this.globalCountryList.length>0){
    
                this.globalCountryList.forEach(element => {
                    if(element.id == task.countryId){
                        task.countryId=element;
                       
                    }      
                });
            }
            
            if(task.stateId!= 0 && this.globalStateList.length>0){

            
                this.globalStateList.forEach(element => {
                    if(element.id == task.stateId){
                        task.stateId=element;
                       
                    }      
                });
            }
            
            if(task.cityId!= 0 && this.globalCityList.length>0){

                
                this.globalCityList.forEach(element => {
                    if(element.id == task.cityId){
                        task.cityId=element;
                    
                    }      
                });
            }

        }

        console.log("task>>",task);
        console.log("task>>",Array.isArray(task.stateId));

        return this.formBuilder.group({
            continentId: [[task.continentId],Validators.required],
            countryId: [[task.countryId],Validators.required],
            stateId: [[task.stateId],Validators.required],
            cityId: [[task.cityId],Validators.required],
            
        });
    }

    createItem(): FormGroup {

        console.log("createItem 0");

        this.mapping.push({
            globalContinentList:[],
            globalCountryList:[],
            globalStateList:[],
            globalCityList:[]  
        })

        var obj = {
            id: 1,
            itemName: "All"
        }

        return this.formBuilder.group({
            continentId: [[obj],Validators.required],
            countryId: [[obj],Validators.required],
            stateId:  [[obj],Validators.required],
            cityId:  [[obj],Validators.required],
        });
    }

    createItemAfter(): FormGroup {

        var obj = {
            id: 1,
            itemName: "All"
        }

        console.log("createItemAfter 1");
        return this.formBuilder.group({
            continentId: [[obj],Validators.required],
            countryId: [[obj],Validators.required],
            stateId:  [[obj],Validators.required],
            cityId:  [[obj],Validators.required],
        });
    }

    addItem(): void {

        console.log("addItem 1");
             
        this.mapping.push({
            globalContinentList:[],
            globalCountryList:[],
            globalStateList:[],
            globalCityList:[]  
        })

       
        this.items = this.variableForm.get('items') as FormArray;
        this.items.push(this.createItemAfter());
    }

    delInput(index){
        const arrayControl = this.variableForm.get('items') as FormArray;
        arrayControl.removeAt(index);
        arrayControl.length == 0
    }
    /* end dynamic add form fields */

    isDeleteGeoMapping(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteGeoMappingIndex);
        }

    }

    isDeleteGeoMappingYes(i){
        this.deleteGeoMappingIndex = i;
    }


    //New Code for right side for from
    onContentReadyToReview(){

        console.log("variableForm>>",this.variableForm.value.items.length);

        if(this.variableForm.value.items.length == 0){
                       
            this.isGeoZoneSubmitted=true;
            this.commonService.changeloader(false);  
            return;
        }
        
        if(!this.variableForm.valid){
           
            this.isGeoZoneSubmitted=true;
            this.commonService.changeloader(false);  
            return;
        }

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        if(this.createGeoZoneForm.invalid){

            this.contentReadyToReview = false;
            this.isGeoZoneSubmitted = true;
            
            return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createGeoZoneForm.controls['contentState'].setValue(['Completed']);
            this.savecreateGeoZone();
           
        }
        if(event == 'Cancel'){

            this.createGeoZoneForm.controls['contentState'].setValue(['In-Progress']);
            this.savecreateGeoZone();
            
        }
       
    }

    resetForm(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
        // this.img = [];
        // this.spinnerService.hide();
        // this.uploadImgFilePath=[];
        this.update = false;
        this.isGeoZoneSubmitted = false;

        this.updateSend.emit();
    
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {

        //     arrayControl.removeAt(0);
        // }

        this.createGeoZoneForm = this.formBuilder.group({
            geoZoneName:['', Validators.compose([Validators.required])],
            // latitude:['',Validators.pattern('[1-9]+(\.[0-9]+)?')],
            latitude:[''],
            // mapAssetGroup:[[]],
            longitude:[''],
            // longitude:['',Validators.pattern('[1-9]+(\.[0-9]+)?')],
            description:[''],
            isActive:['1'],
            mapGeoMapping:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

            // geoMappingArray: this.formBuilder.array([ this.createGeoMappingItem() ])
        });
        
        //reset multiselect list
        // this.selectedGeoZone=[];
        // this.selectedProductlist=[];

        //reset device type image
        // let obj = {
        //     'index':'',
        //     'deviceType':'',
        //     'selectedFiles':'',
        //     'fileName': '',
        //     'filePath': "",
        //     'fileType':''
        // }

       

        //     this.img.push({
        //         imgUrl:''
        //     })

        this.update = false;

        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });
        
        this.showCreateNewButton('show');

    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
