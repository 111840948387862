import { Component, OnInit,Input,EventEmitter,Output, ɵConsole} from '@angular/core';
import { ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common_Service/common.service';


@Component({
  selector: 'app-paginationlist',
  templateUrl: './paginationlist.component.html',
  styleUrls: ['./paginationlist.component.scss']
})
export class PaginationlistComponent implements OnInit {

  boolShowQuickSearch:boolean=false;
  page :any = {};
  @Input() rows:any=[];
  @Input() columns:any=[];
  // @Input() infoObj:any;
 
  selected:any=[];
  infoObjs:any={};
  listview:boolean=true;
  toggleFilter:boolean=false;

  selectAll:boolean = false;

  selectedValue:any;
  search:boolean = false;
  activecurrentPage : any = 1;
  config: any = {};
  isShowSearch = false;

  queueProgress:boolean = false;

  activeContentType:any;
  activeStatusType:any;
  activeStatusId:any;
  activeContentIds:any=[];


  loader:boolean=false;
  applyBlackTheme:any;

  //New Code start
  @Input() set infoObj(value: any) {

    this.infoObjs = value;
 
    // this.config = {
    //   itemsPerPage: 10,
    //   currentPage: this.activecurrentPage,
    //   totalItems: value.totalCount
    // };

  }

  isLangugePopUp:boolean = false;
  item:any = [];

  onLanguage(item){

    this.isLangugePopUp = true;
    this.item = item;
    localStorage.setItem('langItem',JSON.stringify(this.item));
    localStorage.setItem('activePage',this.infoObjs.page);

  }

  isLanguageActionReceive(event){

    this.isLangugePopUp = false;
    this.item.langId = localStorage.getItem('languageId');
    console.log("languageId>>>",this.item);
    this.commonService.changeThemelight(this.item);

  }

  
  //New Code end
 // for select multiple status management

  @Output() rowsDataSend = new EventEmitter<any>();
  @Output() actionObjSend = new EventEmitter<any>();
  @Output() actionApiSend = new EventEmitter<any>();
  @Output() quicksearchApiSend = new EventEmitter<any>();

  columnWidth:any;

  isPageChanged:boolean = false;
  activeId:any=null;

  constructor( private commonService:CommonService) { }

    multiLanguage:boolean = false;
     collection = { count: 60, data: [] };

      ngOnInit() {

       if(localStorage.getItem('languages')== 'true'){

            this.multiLanguage = true;
       } else {

           this.multiLanguage = false;

       }

        
        if(this.infoObjs.page == 'tracks'){

          this.listview = false;
       }
            
        this.commonService.currentThemelight.subscribe((result:any)=>{
                  
          this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          
        })
        
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@list information data ==========>>>>>>>>>> ",this.infoObjs.page);
      
        console.log("this.infoObj>>",this.infoObjs.totalCount);
        this.columnWidth = 'calc((100% - 130px) / '+this.columns.length+');';

      
        if(this.infoObjs.page == 'tracks'){

              this.listview = false;
        }
      
        this.selectedValue = 'reviewer_state';

          //Create dummy data
          for (var i = 0; i < this.collection.count; i++) {

            this.collection.data.push(
              {
                id: i + 1,
                value: "items number " + (i + 1)
              }
            );

          }
          
          this.config = {
            itemsPerPage: 10,
            currentPage: this.activecurrentPage,
            totalItems: this.infoObjs.totalCount
          };

      }

      addToQueue(index){
        if(index==0)
        this.queueProgress = true;
      }

      ngOnChanges(){
        
       if(localStorage.getItem('languages')== 'true'){

        this.multiLanguage = true;

        } else {

            this.multiLanguage = false;

        }

        
        if(this.infoObjs.page == 'tracks'){

          this.listview = false;
       }

        console.log("this.infoObjs",this.infoObjs);
        console.log("this.activecurrentPage>>>",this.activecurrentPage);        

        if(this.rows.length == 10 && this.infoObjs.totalCount == 10){

            this.config = {
              itemsPerPage: 10,
              currentPage: 1,
              totalItems: this.infoObjs.totalCount
            };
            
        }

       else{

            this.config = {
              itemsPerPage: 10,
              currentPage: this.activecurrentPage,
              totalItems: this.infoObjs.totalCount
            };

        }

     

        
        if(this.rows.length>0){


            if(this.rows[0].statusmanagement != undefined){

              this.activeContentType = this.rows[0].statusmanagement.contentType;

              console.log("this.activeContentType",this.activeContentType);

            }

        }
        
          if(this.search == true){

            this.search = false;

            this.config = {
              itemsPerPage: 10,
              currentPage: 1,
              totalItems: this.infoObjs.totalCount
            };


          }else{

            this.config.totalItems = this.infoObjs.totalCount;
            
          }

          this.activeId = null;

      }

  /* ============================
      Start Quick Search Code */
      onQuicksearchStateChange(state, searchType){

        this.config.currentPage = 1;

        let stateObj = {}; 
        
        if(searchType == 'reviewer'){
            stateObj = {
                'quickSearch':{
                    'reviewerState':state,
                    //'publisherState':'',
  
                }
            }
        }
        else  if(searchType == 'publisher'){
            stateObj = {
                'quickSearch':{
                    //'reviewerState':'',
                    'publisherState':state,
  
                }
            }
        }
        
  
        console.log("QUICK SEARCH OBJECT statusObj ------------ ",stateObj);
        this.quicksearchApiSend.emit(stateObj);
  
      }
      
      onQuicksearchClose(){

        this.boolShowQuickSearch=false;
        let stateObj = null;
        this.quicksearchApiSend.emit(stateObj);
      }

      onParticularSelectAll(item){

        this.onChange(item);


      }
      

      // selece all for reviewer and publisher code start
      onSelectAll(){

          //  this.selectAll != this.selectAll;
          if(this.selectAll == true){

            this.selectAll = false;

            this.activeContentIds = [];

            this.rows.forEach(element => {
             
              element.selected = false;

              
            });

            this.loader = false;

          }else{

            this.selectAll = true;

            this.activeContentIds = [];

            this.loader = false;

            this.rows.forEach(element => {
             
              element.selected = true;
              this.activeContentIds.push(element.statusmanagement.contentId);
              
            });

          }
      }
      // selece all for reviewer and publisher code end
     
      onCheckBOx(){

        console.log("selectAll>>>",this.selectAll);

        this.selectAll != this.selectAll;

      }


      onChange(item){

        console.log("item>>",item);

          if(item.selected == true){

            console.log("rows>> true",this.rows);

            item.selected = false;
            this.activeContentIds = [];


            this.selectAll = false;
  
            this.rows.forEach(element => {
  
  
              if(element.selected == true){
  
                console.log("item>>",element);
  
                this.activeContentIds.push(element.statusmanagement.contentId);
  
                console.log("item>>",this.activeContentIds);
  
              }
          
            });

          }else {

            item.selected = true;

            this.activeContentIds = [];


            this.selectAll = false;

            console.log("rows>> false",this.rows);
  
            this.rows.forEach(element => {
  
  
              if(element.selected == true){
  
                console.log("item>>",element);
  
                this.activeContentIds.push(element.statusmanagement.contentId);
  
                console.log("item>>",this.activeContentIds);
  
              }
          
            });

          }
      }

  /* ============================
      End Quick Search Code */


    //on status type update on seelce all or multiselect.
      onStatus(statusId){

        console.log("status>>>",this.activeContentIds);

        let  contentStatusId;
        let  reviewerStatusId;
        let statusObj;

        if(this.selectedValue == "reviewer_state"){
  
          let contentStatusId = '';

          console.log("statusId",statusId);
            
          if(statusId == "Un-approved"){

              contentStatusId = 'In-Progress';

          } else if(statusId == "Approved"){
              
              contentStatusId = 'Completed';
              
          } else if(statusId == "Rejected"){
              
              contentStatusId = 'In-Progress';
          }
        
          let statusObj = [
              {
                "contentType": this.activeContentType,
                "contentIds": this.activeContentIds,
                "statusType": "reviewer_state",
                "statusId":statusId
              },
              {
                "contentType": this.activeContentType,
                "contentIds": this.activeContentIds,
                "statusType": "content_state",
                "statusId": contentStatusId
              }
          ]
        
            if(this.activeContentIds.length>0){
              
              this.activeContentIds = [];

              this.selectAll = false;

              this.actionApiSend.emit(statusObj);
              
            }

        }

        if(this.selectedValue == "publisher_state"){

          if(statusId == "Published"){
            
            contentStatusId = 'Completed';
            reviewerStatusId = 'Approved';
            
            statusObj = [
                {
                    "contentType": this.activeContentType,
                    "contentIds":  this.activeContentIds,
                    "statusType": "publisher_state",
                    "statusId":statusId,
                    
                },
                {
                  "contentType":this.activeContentType,
                  "contentIds": this.activeContentIds,
                  "statusType": "reviewer_state",
                  "statusId":reviewerStatusId
                },
                {
                  "contentType": this.activeContentType,
                  "contentIds": this.activeContentIds,
                  "statusType": "content_state",
                  "statusId":contentStatusId
                },
            ]


              if(this.activeContentIds.length>0){

                this.activeContentIds = [];
                
                this.selectAll = false;
                
                this.actionApiSend.emit(statusObj);
                
              }
            
            } else if(statusId == "Rejected"){
            
                contentStatusId = 'In-Progress';
                reviewerStatusId = 'Rejected';
                
                statusObj = [
                  {
                      "contentType": this.activeContentType,
                      "contentIds":  this.activeContentIds,
                      "statusType": "publisher_state",
                      "statusId":statusId,
                      
                  },
                  {
                    "contentType":this.activeContentType,
                    "contentIds": this.activeContentIds,
                    "statusType": "reviewer_state",
                    "statusId":reviewerStatusId
                  },
                  {
                    "contentType": this.activeContentType,
                    "contentIds": this.activeContentIds,
                    "statusType": "content_state",
                    "statusId":contentStatusId
                  },
              ]


                if(this.activeContentIds.length>0){
                  
                  this.activeContentIds = [];

                  this.selectAll = false;
                
                  this.actionApiSend.emit(statusObj);

                }
                
            } else if(statusId == "Un-published"){
                
                contentStatusId = 'In-Progress';
                reviewerStatusId = 'Un-approved';
                statusObj = [
                  {
                      "contentType": this.activeContentType,
                      "contentIds":  this.activeContentIds,
                      "statusType": "publisher_state",
                      "statusId":statusId,
                      
                  },
                  {
                    "contentType":this.activeContentType,
                    "contentIds": this.activeContentIds,
                    "statusType": "reviewer_state",
                    "statusId":reviewerStatusId
                  },
                  {
                    "contentType": this.activeContentType,
                    "contentIds": this.activeContentIds,
                    "statusType": "content_state",
                    "statusId":contentStatusId
                  },
              ]


            if(this.activeContentIds.length>0){

                this.activeContentIds = [];

              this.selectAll = false;
              
              this.actionApiSend.emit(statusObj);
          

            }
                
            }

        }

      }
    //on status type update on seelce all

      onDelete(event) {

        console.log("123event>>",event);
          
            let infoObj = {
              'id':event.id,
              'languageId':event.langaugeId,
              'action':'delete',
              'uplynk_id':event.uplynk_id
              }

            this.actionObjSend.emit(infoObj);
        
      }

      onState(){


      }

      onDetails(event){

        this.activeId = event.id;

          let infoObj = {
            'id':event.id,
            'languageId':event.langaugeId,
            'action':'edit',
            'uplynk_id':event.uplynk_id,
             'obj':event
          }

          console.log("infoObj1234567890>>>",infoObj);
          this.actionObjSend.emit(infoObj);

      }

    //below function to maintain state of reviewer
      onReviewerStateChange(item,reviewerStateActive){
        
        console.log("item.statusmanagement",item);
            
            let contentStatusId = '';
            
            if(reviewerStateActive == "Un-approved"){

                contentStatusId = 'In-Progress';

            } else if(reviewerStateActive == "Approved"){
                
                contentStatusId = 'Completed';
                
            } else if(reviewerStateActive == "Rejected"){
                
                contentStatusId = 'In-Progress';
            }
          
            let statusObj = [
                {
                  "contentType": item.statusmanagement.contentType,
                  "contentIds": [ item.statusmanagement.contentId],
                  "statusType": "reviewer_state",
                  "statusId":reviewerStateActive
                },
                {
                  "contentType": item.statusmanagement.contentType,
                  "contentIds": [ item.statusmanagement.contentId],
                  "statusType": "content_state",
                  "statusId": contentStatusId
                }
            ]
          
            this.actionApiSend.emit(statusObj)

      }

      redirectTofile(item){

        console.log("item.rawFilePath",item.rawFilePath);

          window.open( 
            item.rawFilePath
            ); 

      }

      //below function to maintain state of publisher
      onPublisherStateChange(item,publisherStateActive){

            let statusObj = []; 
            
            let contentStatusId = '';
            let reviewerStatusId = '';
            
            if( publisherStateActive == "Published"){
            
                contentStatusId = 'Completed';
                reviewerStatusId = 'Approved';
                
                statusObj = [
                    {
                        "contentType": item.statusmanagement.contentType,
                        "contentIds":  [item.statusmanagement.contentId],
                        "statusType": "publisher_state",
                        "statusId":publisherStateActive,
                        'id':1
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "reviewer_state",
                      "statusId":reviewerStatusId
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "content_state",
                      "statusId":contentStatusId
                    },
                ]
                
            } else if( publisherStateActive == "Rejected"){
            
                contentStatusId = 'In-Progress';
                reviewerStatusId = 'Rejected';
                
                statusObj = [
                    {
                        "contentType": item.statusmanagement.contentType,
                        "contentIds":  [item.statusmanagement.contentId],
                        "statusType": "publisher_state",
                        "statusId":publisherStateActive,
                        'id':1
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "reviewer_state",
                      "statusId":reviewerStatusId
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "content_state",
                      "statusId":contentStatusId
                    },
                ]
                
            } else if(publisherStateActive == "Un-published"){
                
                contentStatusId = 'In-Progress';
                reviewerStatusId = 'Un-approved';
                
                statusObj = [
                    {
                        "contentType": item.statusmanagement.contentType,
                        "contentIds":  [item.statusmanagement.contentId],
                        "statusType": "publisher_state",
                        "statusId":publisherStateActive,
                        'id':1
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "reviewer_state",
                      "statusId":reviewerStatusId
                    },
                    {
                      "contentType": item.statusmanagement.contentType,
                      "contentIds": [ item.statusmanagement.contentId],
                      "statusType": "content_state",
                      "statusId":contentStatusId
                    },
                ]
                
            }
            
          this.actionApiSend.emit(statusObj)

      }

      pageChanged(event){

        this.isPageChanged = true;
        console.log("event>>",event);
        this.activecurrentPage = event;
        // this.config.currentPage = event;

        this.selectAll = false;
        if(this.infoObjs.page == 'genres'){

          // this.config.itemsPerPage = 10;
          // this.config.totalItems = this.infoObjs.totalCount;
            // setTimeout(()=>{

            //     this.config = {
            //       itemsPerPage: 10,
            //       currentPage: event,
            //       totalItems: this.infoObjs.totalCount
            //     };
            
            // },3000)
  
        }

        this.activeContentIds = [];

        let pageInfo = [{

          // 'count': 13,
          'limit': 10,
          'offset': event-1,
          'pageSize': 10,
          'id':1

        }] 

        console.log("pageInfo",pageInfo);

        this.actionApiSend.emit(pageInfo)

      }

      updateFilter(event) {

        this.search = true;

        this.activeContentIds = [];
        
        //for select all contwntIds creation
        if(this.selectAll == true){

            this.rows.forEach(element => {

                element.selected = true;

                this.activeContentIds.push(element.statusmanagement.contentId);

            });
        }
       //for select all contwntIds creation

        console.log("rowsDataSend>>",event);
        this.rowsDataSend.emit(event);
        

      }

}
