import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class AssetService {


  constructor(private http: HttpClient) { }

    updateAsset(postObj:any) : Observable<any> {

        console.log(">>updateAssetUrl>>",environment.updateAssetUrl);
        postObj.langId = localStorage.getItem('languageId');
        if(postObj.langId== null){
            postObj.langId = '1';
 
        }

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateAssetUrl, postObj,httpOptions);
    }
        
    insertAsset(postObj:any) : Observable<any> {

        postObj.langId = localStorage.getItem('languageId');

        if(postObj.langId== null){
            postObj.langId = '1';
 
        }
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertAssetUrl, postObj,httpOptions);
    }    
    
    getAsset( id: any, langId:any) : Observable<any> {
        
        langId = localStorage.getItem('languageId');

        if(langId == null){
            langId = '1';
        }

        if(id){
            return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getAssetUrl+"/" + id +"/" + langId);
        }
         
    }


    getRefreshUplynk( ) : Observable<any> {
        
            return this.http.get(environment.apiURL + environment.adminApiUrl + environment.refreshUplynkUrl)
    }
   
    getAllAsset(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');
        if(filterObj.languageId == null){       
            filterObj.languageId = '1';
        }
        

        let urlGetAllAsset = environment.apiURL + environment.adminApiUrl + environment.getAssetUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllAsset)
    }

    /*getGlobalAsset(filterObj,start,limit){
        let urlGetAllAsset = environment.apiURL + environment.adminApiUrl + environment.getGlobalAssetUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllAsset)
    }*/
              
    deleteAsset(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteAssetUrl, postObj,httpOptions);
    }

    insertAssetGroup(postObj) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertAssetGroupUrl, postObj,httpOptions);
    }

    updateAssetGroup(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateAssetGroupUrl, postObj,httpOptions);
    }

    getAssetGroup( id: any, langId:any) : Observable<any> {

        langId = localStorage.getItem('languageId');

        
        if(id){
            return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getAssetGroupUrl+"/" + id +"/" + langId);
        }
        
    }

}
