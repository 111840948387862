import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SessionHelper} from '../../../../../services/localstorage';
import { errorMessages } from './../../../../../configurations/errorMessages';
import { Loginshared } from './../../../../../services/sharedservices/loginshared.service';
import { CommonService } from '../../../../../services/common_Service/common.service';
import { ReviewService} from './../../../../../services/review/review.service';
import { ProductConfigurationService } from '../../../../../services/product_configuration/product_configuration.service';
import { awsPath, usernamePattern } from './../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addReviews',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentReviews implements OnInit {

    isCreateNewShow:boolean = true;

  contentReadyToReview:boolean= false;
  shareMassage:any;

  id:any;
  update=false;

  loader:boolean=false;

  createReviewForm: FormGroup;
  errorMessages = errorMessages;
  isUserLoggedIn:boolean;
  createReviewSubmitted:boolean=false;

  uploadImageFileUrl:any;
  uploadImgFilePath:any=[];
  img:any=[];

  isDeleteImageAction:boolean=false;

  // globalProductTypeSettings={};
  // getProductTypeList=[];
  // globalGeoZoneSettings={};
  // getGeoZoneList=[];

  //New Code for right side for from
  @Output() updateSend = new EventEmitter<any>();
  @Input() set contentId(value: any) {
      // this.onLoad(value);
      if(value == 'reset'){
          this.resetForm();
      }else {
        //start code for hide create new button
        if(value != 'null'){
            this.showCreateNewButton('hide');
        }
        //end code for hide create new button
          this.onLoad(value);
      }
  }

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

  constructor(private toastrService: ToastrService, private productConfigurationService: ProductConfigurationService, private activatedRoute: ActivatedRoute, private reviewService: ReviewService, private commonService: CommonService, private formBuilder:FormBuilder, private router: Router, private loginSharedService: Loginshared) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  applyBlackTheme = localStorage.getItem('boolThemeFlag');


  roleAsses:any={};
  roleConfiguration:any=[];

  ngOnInit() {

    
    this.roleAsses.reviewsEdit = false;
    this.roleAsses.reviews = false;
    this.roleAsses.reviewsCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {
      console.log('element',element);

          if((element.isView == 1 && element.pageTitle == 'Reviews') ){
              
              this.roleAsses.reviews = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Reviews') ){
              
              this.roleAsses.reviewsEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Reviews')){

              this.roleAsses.reviewsCreate = true;
          
          }
    })
    // theme update code start
    this.commonService.currentThemelight.subscribe((result:any)=>{
                
      this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      
  })
  // theme update code start
  }

  onLoad(id) {

    let headerTitle = {
        title:'Master',
        subTitle:'Reviews'

    }

    this.commonService.changeHeaderTitle(headerTitle);
    
    let uid = localStorage.getItem('ud');
    if (uid !== undefined && uid != null) {

    }else {
        this.router.navigate(['/login']);
    }


    this.createReviewForm = this.formBuilder.group({
        // geoZone: [[], Validators.compose([Validators.required])],
        // productType: [[]],
        reviewedBy: [''],
        // firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
        lastName:['', Validators.compose([Validators.pattern('[A-Za-z ]+')])],
        firstName:['', Validators.compose([Validators.pattern('[A-Za-z ]+'), Validators.required])],
        description: [''],
        isActive: ['1'],
        imageDimension: [''],
        fileName:[''],
        filePath:[''],
        fileType:[''],
        id: [''],
        contentState:['In-Progress'],
        publisherState:['Un-published'],
        reviewerState:['Un-approved'],
        created_by:[localStorage.getItem('ud')]

        

    });
    // this.getGlobalProductType();
    // this.getGlobalGeoZone();

    // this.activatedRoute.params.subscribe(params => {
        // this.id = +params['id']; // (+) converts string 'id' to a number
        this.id = id;
        if(this.id != 'null' ){
        
          console.log('this.id===>>>',this.id);
        
            this.update=true;
            this.getReviewInfo(this.id)
        }else{
          this.update=false;      
        }

    // });

  }

  get l() { return this.createReviewForm.controls; }


  getReviewInfo(id){
        
    // this.loader=true;
    this.commonService.changeloader(true);

    this.reviewService.getReview(id)
    .subscribe((result:any)=>{

          // this.loader=false;
          this.commonService.changeloader(false);

          console.log("get result==========>",result);
          
          this.createReviewForm.controls['reviewedBy'].setValue(result.data[0].reviewedBy);
          this.createReviewForm.controls['firstName'].setValue(result.data[0].firstName);
          this.createReviewForm.controls['lastName'].setValue(result.data[0].lastName);
          this.createReviewForm.controls['description'].setValue(result.data[0].description);
          this.createReviewForm.controls['isActive'].setValue(result.data[0].isActive.toString());


          this.createReviewForm.controls['imageDimension'].setValue(result.data[0].imageDimension);

          this.createReviewForm.controls['fileName'].setValue(result.data[0].fileName);
          this.createReviewForm.controls['filePath'].setValue(result.data[0].filePath);
          this.createReviewForm.controls['fileType'].setValue(result.data[0].fileType);

          this.createReviewForm.controls['publisherState'].setValue(result.data[0].publisherState);
          this.createReviewForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

          this.uploadImageFileUrl=result.data[0].filePath;



          if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createReviewForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createReviewForm.controls['contentState'].setValue(result.data[0].contentState);
            }
          console.log("result==========>",result.data[0].isActive.toString());
    
    });
  }


  selectUploadImageFile(e ) {

        this.loader = true;

        this.uploadImgFilePath=[];

        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }

        var mimeType = e.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {

            console.log("Only images are supported.");
            //this.errorImage = 'Only images are supported.';
            this.uploadImageFileUrl = '';
          
            return;
        }


        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            
            this.uploadImageFileUrl=reader.result;
            
        }

        this.uploadImgFilePath.push(obj);  

        if(this.uploadImgFilePath.length>0){

            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                    if (res && res.Location) {

                        this.loader = false;
                        
                        this.createReviewForm.controls['fileName'].setValue(this.uploadImgFilePath[i].fileName);
                        this.createReviewForm.controls['filePath'].setValue(res.Location);
                        this.createReviewForm.controls['fileType'].setValue(this.uploadImgFilePath[i].fileType);

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }

        }

        e.target.value = '';

  }


    savecreateNewReview(){


      console.log('update',this.id);
      console.log("Record received",this.createReviewForm.value);

      // this.loader = true;
      this.commonService.changeloader(true);

      this.createReviewSubmitted = true;

      if(!this.createReviewForm.valid){

      // this.loader = false;
      this.commonService.changeloader(false);
      return;
     }

      
      if(this.update){

        if(this.roleAsses.reviewsEdit == false ){
          this.toastrService.success('no access to edit.');
          this.commonService.changeloader(false);
          return;
      }

        if(this.uploadImgFilePath.length>0){
          
          let async = this.uploadImgFilePath.length;

            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
      
              this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                  if (res && res.Location) {

                    // this.loader = false;
                    this.commonService.changeloader(false);

                      this.uploadImgFilePath[i].FilePath=res.Location;  
                      this.createReviewForm.controls['fileName'].setValue(this.uploadImgFilePath[i].fileName);
                      this.createReviewForm.controls['filePath'].setValue(res.Location);
                      this.createReviewForm.controls['fileType'].setValue(this.uploadImgFilePath[i].fileType);
                      console.log("createReviewForm",this.createReviewForm);
                      this.postCallReview();
                  }
                
              })
          }

        }

          if(this.uploadImgFilePath.length == 0){

              this.postCallReview();

          }
     }

      if(!this.update){

              if(this.roleAsses.reviewsCreate == false ){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

              if(this.uploadImgFilePath.length>0){
              
                let async = this.uploadImgFilePath.length;
      
                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
      
                    if (res && res.Location) {
      
                        // this.loader = false;
                        this.commonService.changeloader(false);

                        this.uploadImgFilePath[i].FilePath=res.Location;  
                        this.createReviewForm.controls['fileName'].setValue(this.uploadImgFilePath[i].fileName);
                        this.createReviewForm.controls['filePath'].setValue(res.Location);
                        this.createReviewForm.controls['fileType'].setValue(this.uploadImgFilePath[i].fileType);
                        console.log("createReviewForm",this.createReviewForm);
                        this.postCallReview();
                    }
                  
                })
              }
      
            }

            if(this.uploadImgFilePath.length == 0){

              this.postCallReview();
                
            }
      }

   }

  postCallReview(){

    if(!this.update){
      delete this.createReviewForm.value.id;
      this.reviewService.insertReview(this.createReviewForm.value)
      .subscribe((result:any)=>{
          // this.loader=false;
          this.commonService.changeloader(false);

          // this.spinnerService.hide();

          //New Code for right side for from
          this.update = false;
        //  this.updateSend.emit();
          this.resetForm();
          this.createReviewSubmitted = false;

          // this.router.navigate(['master/reviews/list']);
          this.toastrService.success('Review Created Successfully.');
      });
    }

    if(this.update){
      this.createReviewForm.controls['id'].setValue(this.id);
      this.reviewService.updateReview(this.createReviewForm.value)
      .subscribe((result:any)=>{
          // this.loader=false;
          this.commonService.changeloader(false);
          // this.router.navigate(['master/reviews/list']);

          //New Code for right side for from
          this.update = false;
         // this.updateSend.emit();
          this.resetForm();
          this.createReviewSubmitted = false;

          this.toastrService.success('Review Updated Successfully.');
          // this.spinnerService.hide();
          
      });
    }
  }




    isDeleteImage(event){

        console.log('isDeleteImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteImageAction=false;
            
            // delete this.uploadImgFilePath;
            this.uploadImageFileUrl ='';
            
            this.createReviewForm.controls['fileName'].setValue(['']);
            this.createReviewForm.controls['filePath'].setValue(['']);
            this.createReviewForm.controls['fileType'].setValue(['']);
        }

    }



    //New Code for right side for from
    onContentReadyToReview(){

        // this.loader = false;
        this.commonService.changeloader(false);

        if(this.createReviewForm.invalid){
         
          this.createReviewSubmitted = true;

          return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }
    //is Content Ready To Review Received  



    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

      //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
          
        }
        //New Code end

        if(event == 'Yes'){

            this.createReviewForm.controls['contentState'].setValue(['Completed']);
           
            this.savecreateNewReview();
          
        }
        if(event == 'Cancel'){

            this.createReviewForm.controls['contentState'].setValue(['In-Progress']);
           
            this.savecreateNewReview();
          

      }
     
    }


    
    resetForm(){

    
      // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
      // while (arrayControl.length !== 0) {
      //     arrayControl.removeAt(0);
      // }

      // console.log("this.formTabs >>>>",this.formTabs);

      
      this.updateSend.emit();
      this.createReviewForm = this.formBuilder.group({
        // geoZone: [[], Validators.compose([Validators.required])],
        // productType: [[]],
        reviewedBy: [''],
        // firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
        lastName:['', Validators.compose([Validators.pattern('[A-Za-z ]+')])],
        firstName:['', Validators.compose([Validators.pattern('[A-Za-z ]+'), Validators.required])],
        description: [''],
        isActive: ['1'],
        imageDimension: [''],
        fileName:[''],
        filePath:[''],
        fileType:[''],
        id: [''],
        contentState:['In-Progress'],
        publisherState:['Un-published'],
        reviewerState:['Un-approved'],
        created_by:[localStorage.getItem('ud')]

      });
      

      this.update = false;

      //////////////////////

      this.uploadImageFileUrl = '';

      this.uploadImgFilePath = [];
     
      // this.multipleDeviceForm = this.formBuilder.group({

      //     devices: this.formBuilder.array([this.createItem()])

      // });
  
      this.showCreateNewButton('show');
    }


  showCreateNewButton(isShow){
    if(isShow == 'show'){
        this.isCreateNewShow = true;
    }
    else{
        this.isCreateNewShow = false;
    }
}

}
