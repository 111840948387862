import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../../services/common_Service/common.service';
import {LoginService} from '../../../../services/login/login.service';
import { Router,ActivatedRoute } from '@angular/router';
import {Loginshared} from '../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {awsPath, usernamePattern} from './../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import {ChangeDetectorRef } from '@angular/core';
import {Observable}  from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {ClientService} from '../../../../services/client/client.service';
import {ProductConfigurationService} from '../../../../services/product_configuration/product_configuration.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  isUserLoggedIn: boolean = false;

  activeEmail:any;
  loginForm: FormGroup;
  trailerVideoList:any=[];
  update:boolean = false;

  id:any = null;

  menuCategorySettings:any={};

  constructor(private cdref: ChangeDetectorRef, private activatedRoute:ActivatedRoute,private toastrService:ToastrService, private productConfigurationService: ProductConfigurationService, private clientService:ClientService,private formBuilder: FormBuilder, private router:Router,private commonService:CommonService  ,private loginSharedService: Loginshared, private loginService: LoginService) {
    
    this.commonService.notify.subscribe((result) => {
        // this.loader = false;
        this.commonService.changeloader(false);
        this.loader = false;
    })

  }

  ngOnInit() {

    this.id = this.activatedRoute.snapshot.paramMap.get("id");

    console.log("id>>>",this.id);


    this.activatedRoute.params.subscribe(params => {
        

            this.id = +params['id']; // (+) converts string 'id' to a number
            console.log("this.id>>>",this.id);
            this.getClientDetails();
       
      // In a real app: dispatch action to load the details here.
   });
    
    this.menuCategorySettings = {

        singleSelection: false, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        searchBy: ['name'], 
        maxHeight:140,
        minHeight:30,
        lazyLoading: true,
        classes:"myclass custom-class"
    }

    this.getGlobaltrailervideo();
    this.setupSearchDebouncer();


    this.activeEmail = localStorage.getItem('email');
    var headerTitle = {
        title:'Client Management',
        subTitle:''
    }

    console.log(">>>>>>>activeEMail",this.activeEmail);
    
    if(this.activeEmail == 'product@dcafe.io'){
        headerTitle = {
            title:'Product',
            subTitle:''
        }
    }else if(this.activeEmail == 'partner@dcafe.io'){
        headerTitle = {
            title:'Partner',
            subTitle:''
        }
    }

     this.commonService.changeHeaderTitle(headerTitle);
    this.activeEmail = localStorage.getItem('email');

    this.loginForm = this.formBuilder.group({

        clientName: ['', Validators.compose(
          [ Validators.required])],
          
        clientContactName: ['', Validators.compose(
            [ Validators.required, Validators.pattern('0-9')])],
            
        clientAdminUsername: ['', Validators.compose(
            [ Validators.required])],
            
        clientAdminPassword: ['', Validators.compose(
            [ Validators.required])],
        
        customizeURL: ['', Validators.compose([Validators.required])],


        whiteFileName:"",
        whiteFileType :"",
        whiteFilePath:"",
        blackFileName:"",
        blackFileType:"",
        blackFilePath:"",
        client_secret:"",
        id:"",
        uuid:"",

    });

    // mapTrailerVideo:[[]], 


    this.activeEmail = localStorage.getItem('email');
  }

  clientInfo:any={};
  getClientDetails(){

    console.log("result>>>>123456789");
    this.loader = true;

      this.clientService.getClient(this.id).subscribe((result)=>{

        console.log("id..result>>>>id",result);
        this.loader = false;
        if(result.data.length>0){

            this.update = true;
            this.clientInfo = result.data[0];

             this.UploadImageForBlackBackground = result.data[0].blackFilePath;
            this.UploadImageForWhiteBackground = result.data[0].whiteFilePath;

            this.loginForm.controls['uuid'].setValue(result.data[0].uuid)
            this.loginForm.controls['client_secret'].setValue(result.data[0].client_secret)
            this.loginForm.controls['blackFilePath'].setValue(result.data[0].blackFilePath)
            this.loginForm.controls['whiteFilePath'].setValue(result.data[0].whiteFilePath)
            this.loginForm.controls['clientName'].setValue(result.data[0].clientName)
            this.loginForm.controls['clientContactName'].setValue(result.data[0].clientContactName) 
    
            this.loginForm.controls['clientAdminUsername'].setValue(result.data[0].clientAdminUsername) 
    
            this.loginForm.controls['customizeURL'].setValue(result.data[0].customizeURL) 
    
            this.loginForm.controls['id'].setValue(result.data[0].id)
        }else {

            console.log("id..result>>>>id");

            this.update = false;
        }

        
      });

  }

  UploadImageForWhiteBackground:any='';
  UploadImageForBlackBackground:any='';
  loader:boolean= false;

    uploadImgFilePath = [];
    selectFileWhiteBackground(e ) {

    //set obj for file upload of vertical section
        if(e.target.files[0].name==undefined)
        return;

        this.loader = true;


        this.uploadImgFilePath=[];
  
        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

             this.UploadImageForWhiteBackground=reader.result;
            }

            e.target.value = '';

            
        this.uploadImgFilePath.push(obj);  
  
        if(this.uploadImgFilePath.length>0){
  
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
  
                    if (res && res.Location) {

                        this.loader = false;
                        e.target.value = '';
                        this.loginForm.controls['whiteFileName'].setValue(this.uploadImgFilePath[i].fileName);
                        this.loginForm.controls['whiteFilePath'].setValue(res.Location);
                        this.UploadImageForWhiteBackground = res.Location;
                        this.loginForm.controls['whiteFileType'].setValue(this.uploadImgFilePath[i].fileType); 

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }
  
        }

        e.target.value = '';

        
    }

    selectFileBlackBackground(e){

        this.loader = true;
      //set obj for file upload of vertical section
      if(e.target.files[0].name==undefined)
      return;

    
      this.uploadImgFilePath=[];

      let obj ={
        
          'selectedFiles':e.target.files.item(0),
          "fileName": e.target.files[0].name,
          "filePath": "",
          "fileType": e.target.files[0].type, 
      }

      var reader = new FileReader();
      
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (_event) => {

              this.UploadImageForBlackBackground=reader.result;
          }

          e.target.value = '';
          
      this.uploadImgFilePath.push(obj);  

      if(this.uploadImgFilePath.length>0){

          for (let i = 0; i < this.uploadImgFilePath.length; i++) {
      
              this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                  if (res && res.Location) {

                    this.loader = false;

                    e.target.value = '';

                      this.loginForm.controls['blackFileName'].setValue(this.uploadImgFilePath[i].fileName);
                      this.loginForm.controls['blackFilePath'].setValue(res.Location);

                      this.UploadImageForBlackBackground = res.Location;
                      this.loginForm.controls['blackFileType'].setValue(this.uploadImgFilePath[i].fileType); 

                      this.uploadImgFilePath = [];
                      
                  }          
              })
          }

      }



    }

    onClickWhite(){

      document.getElementById("uploadWhite").click();

    }

    onClickBlack(){

        document.getElementById("uploadBlack").click();
 
     }


    onSave(){

    
        this.loader = true;

        if(this.update == false){

            if(this.loginForm.value.customizeURL == ''){

                this.toastrService.error('Customized URL cannot be empty');
                this.loader = false;

                return;
            }

           var filter = {

              'customizeURL' : this.loginForm.value.customizeURL

            }

            this.clientService.getAllClient(filter,0,1000).subscribe((result)=>{

                console.log("<<<123result123>>>",result)

               if(result.data.length == 0) {
                            
                    delete this.loginForm.value.uuid;
                    delete this.loginForm.value.client_secret;

                    delete this.loginForm.value.id;

                    this.loader = false;

                    this.clientService.insertClient(this.loginForm.value).subscribe((result:any)=>{
            
                        this.toastrService.success('Client added successfully')
                        this.router.navigate(['client']);

                    })

               }else {

                   this.loader = false;
                   this.toastrService.error('Customized URL is already taken');
                //    this.toastrService.error('Please change customize url.');

               }

            })



        }else {


            if(this.loginForm.value.clientAdminPassword==''){
                
                delete this.loginForm.value.clientAdminPassword;

            }
            delete this.loginForm.value.customizeURL;
            delete this.loginForm.value.uuid;
            delete this.loginForm.value.client_secret;

            if(this.loginForm.value.blackFilePath==null){


                this.loginForm.value.blackFilePath = '';
            //   delete  this.loginForm.value.blackFilePath
            //   delete  this.loginForm.value.blackFileName
            //   delete  this.loginForm.value.blackFileType
              delete this.loginForm.value.customizeURL;
              delete this.loginForm.value.clientAdminPassword;

            }

            
            if(this.loginForm.value.whiteFilePath==null){

                this.loginForm.value.whiteFilePath = '';

                // delete  this.loginForm.value.whiteFilePath
                // delete  this.loginForm.value.whiteFileName
                // delete  this.loginForm.value.whiteFileType 
                delete this.loginForm.value.customizeURL;
                delete this.loginForm.value.clientAdminPassword;
            }

            setTimeout(()=>{

                this.clientService.updateClient(this.loginForm.value).subscribe((result:any)=>{
               
                    this.loader = false;
                    this.toastrService.success('Client updated successfully')
                    this.router.navigate(['client']);
        
                })
            },1000)
        }

    }


    onDelete(){

        this.isClientDeactivate = true;
        this.isDeleteAction = true;

    }

    isClientDeactivate:boolean;
    isDeleteAction:boolean = false;
    isDeleteActionId:any = 0;
    deleteAction:any= false;
    contentId:any='';
    isDeleteBlackImageAction:boolean;
    isDeleteWhiteImageAction:boolean;

    isDeleteActionReceive(event){

        if(event=='Cancel'){

            this.isDeleteAction=false;

        }
        
        if(event=='Yes'){
 
            if(this.isDeleteBlackImageAction == true){
                
                this.isDeleteBlackImageAction = false;
                this.loginForm.controls['blackFileName'].setValue(['']);
                this.loginForm.controls['blackFilePath'].setValue(['']);
                this.isDeleteAction=false;
                this.loginForm.controls['blackFileType'].setValue(['']); 
                this.UploadImageForBlackBackground ='';

            }else if(this.isDeleteWhiteImageAction == true){
                
                this.isDeleteWhiteImageAction = false;

                this.loginForm.controls['whiteFileName'].setValue(['']);
                this.loginForm.controls['whiteFileType'].setValue(['']);
                this.isDeleteAction=false;
                this.loginForm.controls['whiteFilePath'].setValue(['']); 
                this.UploadImageForWhiteBackground ='';

                    
            }else if(this.isClientDeactivate == true){

                this.isClientDeactivate = false;
                this.isDeleteActionId = this.id;

                let obj={
                    'id': this.isDeleteActionId
                }
    
                this.loader = true;
                this.clientService.deleteClient(obj)
                .subscribe((result:any)=>{
    
                    this.loader = false;
                    this.deleteAction = true;
                    //New Code start
                    this.isDeleteAction=false;
                    this.toastrService.success('client disable Successfully.'); 
    
                })
            }


            
        }

    }
       //New Code start

       deleteWhiteYes(){
            this.isDeleteAction = true;
       } 
       
       deleteBlackYes(){
        this.isDeleteAction = true;
       }
  


    resetForm(){
    
            this.loginForm = this.formBuilder.group({

                clientName: ['', Validators.compose(
                [ Validators.required])],
                
                clientContactName: ['', Validators.compose(
                    [ Validators.required, Validators.pattern('0-9')])],
                    
                clientAdminUsername: ['', Validators.compose(
                    [ Validators.required])],
                    
                clientAdminPassword: ['', Validators.compose(
                    [ Validators.required])],
                
                customizeURL: ['', Validators.compose([Validators.required])],


                whiteFileName:"",
                id:"",
                whiteFileType :"",
                whiteFilePath:"",
                blackFileName:"",
                blackFileType:"",
                blackFilePath:""

            });
    }


    get l() { return this.loginForm.controls; }


    getGlobaltrailervideo(){
        
        this.commonService.getGlobalTrailerVideo(this.filterTrailer,this.startTrailer,10).subscribe((result:any)=>{
            
            // this.trailerVideoList = [];
            
            if(result.data.data.length > 0){ 
                
                this.searchDecouncer$.next(null);


                if(this.isSearch){

                    this.trailerVideoList = [];   
                    this.isSearch = false;
                        
                }  

                for (let trailerVideoLists of result.data.data) {

                    console.log("trailerVideoLists>>>",trailerVideoLists);
   
                    if((trailerVideoLists.assetName != null) && (trailerVideoLists.assetName != '')){
                    
                        this.trailerVideoList.push(
                        {
                            'id': trailerVideoLists.assetId,
                            'itemName': trailerVideoLists.assetName
                        });


                    } 
                }

            } 

        });
        
    }

    
    eventValue:any='df';

   
    isSearch: any = '';

    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
            return;

        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;
        this.eventValue=event.target.value;

        
        if(info == 'trailer'){
            
            if(event.target.value!=''){

                this.filterTrailer =  obj = { 'assetName': event.target.value };
                // this.trailerVideoList = [];

            }else {
                // this.mapClassificationList=[];
                this.filterTrailer =   obj = { } 
            }

            this.startTrailer = 0;

            this.searchDecouncer$.next(event.target.value);

        }
            
    }

    onOpen(info){

        this.activeSearchBox = info;

        if(info == 'trailer'){

            this.trailerVideoList=[];
            this.startTrailer = 0;
            this.filterTrailer = {};
            this.getGlobaltrailervideo();

        }

    }

    onItemSelect(event){

    }

    activeSearchBox= '';

    private setupSearchDebouncer(): void {
        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            if(this.activeSearchBox == 'trailer'){

               this.getGlobaltrailervideo();

            }  
            
        });
    }

  private searchDecouncer$: Subject<string> = new Subject();

    startTrailer = 0;
    filterTrailer = {};
    fetchMoreGetTrailer(event){
    
        if ( ((event.endIndex == this.trailerVideoList.length - 1)) && (this.trailerVideoList.length >= 7)) {

                this.startTrailer = this.startTrailer + 10;

                this.getGlobaltrailervideo();
                 
   
        }

    }

       
    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }
       

  onAdd(){

   this.router.navigate(['clientAdd']);
  
  }

}
