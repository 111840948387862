import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {awsPath, deviceType} from '../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import { errorMessages } from '../../../../../configurations/errorMessages';
import { Router,ActivatedRoute } from '@angular/router';
import {SessionHelper} from '../../../../../services/localstorage';
import {GenresService} from '../../../../../services/genres/genres.service';
@Component({
  selector: 'app-addLan',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentLan implements OnInit {

    isCreateNewShow:boolean = true;
    contentReadyToReview:boolean= false;
    shareMassage:any;

    deviceType = deviceType;
    errorMessages = errorMessages;
    ProducTypeDropdownList = [];
    producTypeList = [];
    dropdownList = [];
    geoZoneList = [];
    loader:boolean=false;
    poster:boolean=false;

    menuCategorySettings = {};
    ProducTypeSettings = {};
    devices: FormArray;
    createGenreFormSubmitted:boolean=false;
    multipleDeviceForm:FormGroup;

    update=false;
    id:any;
    img:any=[];

    All:boolean;
    Web:boolean;
    all:boolean = false;
    android:boolean;
    iOS:boolean;
    roku:boolean;
    

    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;

    //start mapping filter
    productTypeMappingFilter:any={};
    //end mapping filter

  deviceTypeFull:boolean = false;
  deviceTypeDublicate:boolean = false;

  uploadImgFilePathVertical:any=[];
  uploadImgFilePathHorizontal:any=[];
  uploadImgFilePath:any=[];

  isDeleteHorizontalImageAction:boolean=false;
  isDeleteVerticalImageAction:boolean=false;


  formTabs:any = {

    genresInfo:{

        genres:true,
        mapGeoZone:true,
        seo:false,

    },
    deviceType:{
        
        configurationType:false,
        configuration:false,
       
    }
}


  createGenreForm:FormGroup;


  

   //New Code for right side for from
    @Output() updateSend = new EventEmitter<any>();
    @Input() set contentId(value: any) {
        // this.onLoad(value);
        if(value == 'reset'){
            this.resetForm();
        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
    }

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');

    constructor(private activatedRoute:ActivatedRoute ,private toastrService:ToastrService ,private router:Router,private genresService:GenresService,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private formBuilder:FormBuilder) {
        this.commonService.notify.subscribe((result) => {
            // this.loader = false;
            this.commonService.changeloader(false);
        })
    }


    roleAsses:any={};
    roleConfiguration:any=[];
  ngOnInit(){

        this.roleAsses.genresEdit = false;
        this.roleAsses.genres = false;
        this.roleAsses.genresCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Genres') ){
                  
                  this.roleAsses.genres = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Genres') ){
                  
                  this.roleAsses.genresEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Genres')){

                  this.roleAsses.genresCreate = true;
              
              }

        })

      // theme update code start
    this.commonService.currentThemelight.subscribe((result:any)=>{
          
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
     })
    // theme update code start
  }

  editCreate:boolean = false;
  onCreateNew(){

        if(this.roleAsses.genresCreate == true){

            this.editCreate = true;
        }
        
        this.isCreateNewShow=false

  }

  onLoad(id) {

    if(this.id != 'null' && this.roleAsses.genresEdit == true){
        this.editCreate = true;
    }else{
        this.editCreate = false;
    }

    let headerTitle = {
        title:'Master',
        subTitle:'Language'
    }
    this.commonService.changeHeaderTitle(headerTitle);

    // console.log("-----------------qqqqqqqqqqqqqqqqqqqqqqqqqq");
    this.onGenreTabClick();

    this.createGenreForm = this.formBuilder.group({
        isActive : ["1"],
        langId :["1"],
        companyId : [""],
        title : ["",Validators.required],
        sameForAllZone : [""],
        sameForAllProductType :[""],
        exclusionText : [""],
        description : [""],
        seoTitle : [""],
        seoKeyword : [""],
        seoDescription : [""],
        mapGeoZone:[[],Validators.required],
        productDeviceTypeConfigure:[[]],
        mapProductType:[[],Validators.required],
        id:[''],
        displayIndexing:[''],
        contentState:['In-Progress'],
        publisherState:['Un-published'],
        reviewerState:['Un-approved'],
        created_by:[localStorage.getItem('ud')]


    })


    // this.activatedRoute.params.subscribe(params => {

        // this.id = +params['id']; // (+) converts string 'id' to a number
        this.id = id; // (+) converts string 'id' to a number

        if(this.id != 'null'){
        
        console.log('this.id===>>>',this.id);
        
            this.update=true;
            this.getGenresInfo(this.id)
        }else{
            this.update=false;
            this.img.push({
                horizontalURL:'',
                verticalURL:''
            })
            
            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
    
        }

    // });

        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });

        this.menuCategorySettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
            }

        this.ProducTypeSettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
            }


        this.productTypeMappingFilter.geoZoneId = [];
  
        this.getGeoZoneInfo();

        // this.getProductTypeInfo(this.productTypeMappingFilter);
  }

    onGenreTabClick(){
        this.formTabs = {

            genresInfo:{
                genres:true,
                mapGeoZone:true,
                information:false,
                seo:false,
            },
            deviceType:{
                configurationType:false,
                configuration:false,
            }
        }
    }


    onSeoTabClick(){

        this.formTabs = {

            genresInfo:{
                genres:true,
                mapGeoZone:false,
                information:false,
                seo:true,
            },
            deviceType:{
                configurationType:false,
                configuration:false,
            }
        }

    }

    onInfoTabClick(){

        this.formTabs = {

            genresInfo:{
                genres:true,
                mapGeoZone:false,
                information:true,
                seo:false,
            },
            deviceType:{
                configurationType:false,
                configuration:false,
            }
        }

    }

    onDeviceTabClick(){

            this.formTabs = {

                genresInfo:{
            
                    genres:false,
                    mapGeoZone:false,
                    information:false,
                    seo:false,
            
                },
                deviceType:{
                    
                    configurationType:true,
                    configuration:true,
                
                }
            }

    }




    get l() { return this.createGenreForm.controls; }

    get m(): FormArray {

     return this.multipleDeviceForm.get('devices') as FormArray;

    }

    onItemSelect(item:any, mappingName){
        
        console.log("select from = ",mappingName);

        console.log("this.createGenreForm.controls['mapProductType'",this.createGenreForm.value.mapProductType);

        if(mappingName == 'mapGeoZone'){
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.getProductTypeInfo(this.productTypeMappingFilter);
        }

    }

    OnItemDeSelect(item:any, mappingName){
        console.log('item',item.id);
        console.log("this.createGenreForm.controls['mapProductType'",this.createGenreForm.value.mapProductType);

        if(mappingName == 'mapGeoZone'){
            for (let index=0; index<this.productTypeMappingFilter.geoZoneId.length;index++){
                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                    console.log("mapGeoZone deleted = ",item.id);

                    if(this.productTypeMappingFilter.geoZoneId.length == 0){
                        this.producTypeList = [];
                        this.createGenreForm.controls['mapProductType'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }
                    else{
                        this.getProductTypeInfo(this.productTypeMappingFilter);
                    }

                    return;
                }
            }
        }
    }

    onSelectAll(item:any, mappingName){
        console.log("select All from = ",mappingName);

        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter = filterObj;
            this.getProductTypeInfo(filterObj);
        }
    }

    onDeSelectAll(event, info){
        // console.log(items);
        if(info =='mapGeoZone'){
            this.createGenreForm.controls['mapGeoZone'].setValue([]);
            this.producTypeList = [];
            this.createGenreForm.controls['mapProductType'].setValue([]);
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapProductType'){
            this.createGenreForm.controls['mapProductType'].setValue([]);
            this.producTypeList = [];
            console.log("DeSelect All from = ",info);
        }
    }

    //pravin code start
    getGeoZoneInfo(){

            let obj={
            } 
            this.productConfigurationService.getAllGeoZone(obj,0,1000000)
            .subscribe((result:any)=>{

                this.dropdownList = [];
                this.geoZoneList = [];

                if(result.data.data.length > 0){
                        
                    this.dropdownList = result.data.data;

                    for (let geoZoneObj of this.dropdownList) {

                        this.geoZoneList.push(
                        {
                            'id': geoZoneObj.geoZoneId,
                            'itemName': geoZoneObj.geoZoneName
                        });

                    }
                }
        
            }) 
    }

    createItem(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.required],
            description: [''], 
            dtypeId:['',Validators.required], 
            isVisible:'', 
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""
        });
    }     //Pravin code mulitple from
 
    
   //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup {
        
    //device type validation code start
    if(objProductDeviceTypeConfigure.dtypeId === 4){
        this.all = true;
    }
    //device type validation code end
        return this.formBuilder.group({
            label: objProductDeviceTypeConfigure.label,
            description: objProductDeviceTypeConfigure.description,
            dtypeId:objProductDeviceTypeConfigure.dtypeId,
            isVisible:objProductDeviceTypeConfigure.isVisible,
            verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
            verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
            verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
            horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
            horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
            horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType

        });
       
    }
    
    onDtypeId(){
        
        // this.Android=false;
        // this.iOS=false;
    }
    
    //pravin code end
    getProductTypeInfo(filterObj){


        this.commonService.getGlobalProductType({},0,10000).subscribe((result:any)=>{
            
            this.ProducTypeDropdownList = [];
            this.producTypeList = [];
            let mapProductTypeArrayTemp = [];
            let mapProductTypeArray = this.createGenreForm.value.mapProductType;
          
            if(result.data.data.length > 0){       

                this.ProducTypeDropdownList = result.data.data;
                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                    mapProductTypeArray.forEach(element => {

                        if(element.id == ProducTypeLists.productTypeId) {
                            mapProductTypeArrayTemp.push(element);
                        }
                        this.createGenreForm.controls['mapProductType'].setValue(mapProductTypeArrayTemp);
                    });

                    if(ProducTypeLists.productTypeName!=''){

                    this.producTypeList.push(
                        {
                            'id': ProducTypeLists.productTypeId,
                            'itemName': ProducTypeLists.productTypeName
                        });
                    }    
                }
            } 
        });

    }

    onSaveGenres(){

        console.log("!createGenreForm>>",this.createGenreForm);
        console.log("multipleDeviceForm>>",this.multipleDeviceForm);


        let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath=[];

        //remove empty file upload img obj from array.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });

        // this.loader = true;
        this.commonService.changeloader(true);

        this.createGenreFormSubmitted = true;
        let async = this.uploadImgFilePath.length;
        let uploadEmptyCount = 1;
        let varialbeArray=[];

        if(!this.createGenreForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
  
        if(this.multipleDeviceForm.value.devices == 0){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.deviceTypeDublicate){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
        

        if(!this.update){

            if(this.roleAsses.genresCreate == false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

            // uploadImgFilePosterImage upload code finish pravin  'tcs-assets/'
            
            console.log("uploadImgFilePath>>",this.uploadImgFilePath)
            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    if(this.uploadImgFilePath[i].selectedFiles!=''){
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles,awsPath.asset).then(res => {

                            if (res && res.Location) {
                                    async--;
                                      if(this.uploadImgFilePath[i].horizontalFile == true)
                                    this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                    
                                    if(this.uploadImgFilePath[i].verticalFile == true)
                                    this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                    console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                                    if (async == 0) {

                                        let asycn = this.multipleDeviceForm.value.devices.length;
                                        for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                            asycn -- ;
                                                    varialbeArray.push(
                                                    {
                                                        "label": this.multipleDeviceForm.value.devices[i].label,
                                                        "description":this.multipleDeviceForm.value.devices[i].description,
                                                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                                                        "verticalFileName": "",
                                                        "verticalFilePath": "",
                                                        "verticalFileType": "",  
                                                        "horizontalFileName": "",
                                                        "horizontalFilePath": "",
                                                        "horizontalFileType": ""
                                                
                                                    });   

                                        }

                                        if(asycn == 0){
                                            
                                            let asyncvariables = varialbeArray.length;
                                            for(let i=0;i< varialbeArray.length;i++){
                                                    asyncvariables--;
                                                    for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
                
                                                        if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
                
                                                            varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                            varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                            varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
                
                                                        }
    
                                                        if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
                
                                                            varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                            varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                            varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
                
                                                        }
                
                                                    }
                                            }
    
                                            if(asyncvariables == 0){
    
                                                this.createGenreForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                                console.log("createNewProductForm123",this.createGenreForm);
                                                
                                                if(!this.createGenreForm.valid){
                                                    // this.loader = false;
                                                    this.commonService.changeloader(false);
                                                    return;
                                                }

                                                if(!this.multipleDeviceForm.valid){
                                                    // this.loader = false;
                                                    this.commonService.changeloader(false);
                                                    return;
                                                }

                                                this.createGenreFormSubmitted=true;
                                                this.postCallGenres();

                                                //New Code for right side for from
                                                //this.onContentReadyToReview()
                                            }
   
                                    }     
                                }
                            }

                        });
                    }else{

                        async--;
                        uploadEmptyCount ++;
                        if(uploadEmptyCount == this.uploadImgFilePath.length){
                            
                            this.uploadImgFilePath.length=[];

                        }
                        
                    }
                   
                }
            }

            if(this.uploadImgFilePath.length==0){
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                    
                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
                
                console.log("varialbeArray",varialbeArray);
                this.createGenreFormSubmitted = true;
                this.createGenreForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }
        
                if(!this.createGenreForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                
                console.log("createNewProductForm",this.createGenreForm);
                this.postCallGenres();
                //New Code for right side for from
               // this.onContentReadyToReview()
            }

        }
    
        if(this.update){

            if(this.roleAsses.genresEdit == false){
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }
            let asyncvariables = this.multipleDeviceForm.value.devices.length;
            if(this.uploadImgFilePath.length == 0){

                this.createGenreForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createGenreFormSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                if(!this.createGenreForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return
                }
            
                console.log("createNewProductForm",this.createGenreForm);
                
                 this.postCallGenres();
                //New Code for right side for from
                //this.onContentReadyToReview();

            }

            // if(this.uploadImgFilePath.length > 0 && this.createNewAssetForm.value.vodClosedCaptionFilePath!=''){
            // }
            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){

                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }


                                }
                            }

                            if(asyncvariables == 0){
                            
                                this.createGenreFormSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return;
                                }

                                if(!this.createGenreForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return;
                                }
                                this.createGenreForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                                
                                console.log("createNewProductForm",this.createGenreForm);
                                console.log("this.multipleDeviceForm.value.devices",this.multipleDeviceForm.value.devices);
                                
                                this.postCallGenres();
                                //New Code for right side for from
                                //this.onContentReadyToReview()

                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    })
                }
            }

        }


    }

    postCallGenres(){

        // this.loader=false;
        this.commonService.changeloader(false);
       
        if(!this.createGenreForm.valid || !this.multipleDeviceForm.valid)
        {
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

            // this.loader=true;
            this.commonService.changeloader(true);
            if( this.update == false ) {

                delete this.createGenreForm.value.id;
                this.genresService.insertGenre(this.createGenreForm.value)
                .subscribe((result:any)=>{
                    // this.loader=false;
                    this.commonService.changeloader(false);

                    this.uploadImgFilePath = [];
                    //New Code for right side for from
                    this.update = false;
                  //  this.updateSend.emit();
                    this.resetForm();
                    this.createGenreFormSubmitted = false;
                    // this.router.navigate(['master/genres/list']);
                    this.toastrService.success('Genres Created Successfully.');
                });

            }

            if(this.update){

                this.createGenreForm.controls['id'].setValue(this.id);
                this.genresService.updateGenre(this.createGenreForm.value)
                .subscribe((result:any)=>{
                    // this.loader=false;
                    this.commonService.changeloader(false);

                    this.uploadImgFilePath = [];
                    //New Code for right side for from
                    this.update = false;
                  //  this.updateSend.emit();
                    this.resetForm();
                    this.createGenreFormSubmitted = false;
                    this.toastrService.success('Genres Updated Successfully.');
                    // this.router.navigate(['master/genres/list']);
                    console.log('<====result result result============>',result);
                ;
                
                });
            }

    }


    getGenresInfo(id){

        // this.loader=true;
        this.commonService.changeloader(true);

        this.genresService.getGenre(id)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);

            //(start) set dropdown by default empty
            if(result.data[0].sameForAllProductType == null){
                result.data[0].sameForAllProductType = "";
            }
            if(result.data[0].sameForAllZone == null){
                result.data[0].sameForAllZone = "";
            }
            console.log("setValue(result.data[0].sameForAllZone",result.data[0].sameForAllZone);
            //(end) set dropdown by default empty

            this.createGenreForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
            this.createGenreForm.controls['description'].setValue(result.data[0].description);
            this.createGenreForm.controls['title'].setValue(result.data[0].title);

            if(result.data[0].displayIndexing != undefined)
              this.createGenreForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);

            this.createGenreForm.controls['isActive'].setValue(result.data[0].isActive);
            this.createGenreForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
            this.createGenreForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
            this.createGenreForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
            this.createGenreForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
            this.createGenreForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);
            this.createGenreForm.controls['seoTitle'].setValue(result.data[0].seoTitle);

            this.createGenreForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createGenreForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createGenreForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createGenreForm.controls['contentState'].setValue(result.data[0].contentState);
            }
            
            let assetObj=[];
            if(result.data[0].results.productType.length>0){

                for(let i = 0 ;i < result.data[0].results.productType.length;i++){
                    let obj={
                    'id':result.data[0].results.productType[i].productTypeId,
                    'itemName':result.data[0].results.productType[i].productTypeName,
                    }
                    assetObj.push(obj);  
                }
            
                this.createGenreForm.controls['mapProductType'].setValue(assetObj);
            }
            
            assetObj=[];
            if( result.data[0].results.geoZone.length>0){

                for(let i = 0 ; i < result.data[0].results.geoZone.length;i++){
                    let obj={
                    'id':result.data[0].results.geoZone[i].geoZoneId,
                    'itemName':result.data[0].results.geoZone[i].geoZoneName,
                    }
                    assetObj.push(obj); 
                    this.productTypeMappingFilter.geoZoneId.push(obj.id);
                }
                this.createGenreForm.controls['mapGeoZone'].setValue(assetObj);
                this.getProductTypeInfo(this.productTypeMappingFilter);
            }

            if(result.data[0].results.productDeviceTypeConfigure.length>0){

                    this.img = [];

                    this.multipleDeviceForm = this.formBuilder.group({
                        devices: this.formBuilder.array([])
                    });

                   //device type validation code start
                   if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                      this.deviceTypeFull = true;
                     }
                  //device type validation code end

                    this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                    
                    for(let i = 0;i < result.data[0].results.productDeviceTypeConfigure.length;i++){

                            this.img.push({
                                horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                                verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                            })

                            let objH = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "horizontalFileName": "",
                                "horizontalFilePath": "",
                                "horizontalFileType": "",
                                'horizontalFile':false
                            }
                
                            let objV = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "verticalFileName":"",
                                "verticalFilePath": "",
                                "verticalFileType":"",
                                'verticalFile':false,
                                
                            }
                
                            this.uploadImgFilePathHorizontal.push(objH);
                            this.uploadImgFilePathVertical.push(objV);
                            
                            this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                    }
            }

        });

    }

    selectFileVertical(e ,i) {

        this.img.push({
            'verticalURL':'',
            'horizontalURL':''
        })

        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 
            // for(let i = 0;i<this.uploadImgFilePath.length;i++){

            //     if(obj.index == this.uploadImgFilePath[i].index && obj.imgtype ==='vertical'){
            //         this.uploadImgFilePath.splice(i, 1);
            //     }
            // }

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].verticalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }
        
            e.target.value = '';    

       
    }

    selectFileHorizontal(e ,i) {

                
        this.img.push({
            'verticalURL':'',
            'horizontalURL':''
        })

        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathHorizontal[i].index = i;
        this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
        this.uploadImgFilePathHorizontal[i].horizontalFile = true; 
        // for(let i = 0;i<this.uploadImgFilePath.length;i++){

        //     if(obj.index == this.uploadImgFilePath[i].index && obj.imgtype ==='horizontal'){
        //         this.uploadImgFilePath.splice(i, 1);
        //     }
        // }

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }
        
       e.target.value = '';
  
    }

    addItem(): void {
        
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
        }

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){
        this.isDeleteAction=false;

        // console.log('event Mayuri',event)
        // if(event=='Cancel'){
        //     this.isDeleteAction=false;
        // }
        
        // if(event=='Yes'){
        //     this.isDeleteAction=false;
        //     this.delInput(this.deleteDeviceTypeIndex);
        // }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event);

        if(event=='Cancel'){

            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteHorizontalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";

            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = ''
        }

    }

    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = ''
        }

    }

    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }

    resetForm(){

    
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {
            arrayControl.removeAt(0);
        }

        console.log("this.formTabs >>>>",this.formTabs);

        this.onGenreTabClick();

        this.createGenreForm = this.formBuilder.group({
            isActive : ["1"],
            langId :["1"],
            companyId : [""],
            title : ["",Validators.required],
            sameForAllZone : [""],
            sameForAllProductType :[""],
            exclusionText : [""],
            description : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            mapGeoZone:[[],Validators.required],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[],Validators.required],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });
        

        this.update = false;

        //////////////////////

        this.img = [];
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  
        
        this.updateSend.emit();

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });


        this.uploadImgFilePathVertical = [];
        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePath = [];

        this.showCreateNewButton('show');
    
    }


    //New Code for right side for from
    onContentReadyToReview(){

        if(!this.createGenreForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createGenreFormSubmitted = true;
            return;
        }
        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createGenreFormSubmitted = true;
            return;
        }

        // this.loader = false;
        this.commonService.changeloader(false);

        // this.contentReadyToReview = true;
        // this.shareMassage = 'review';

    }
    //is Content Ready To Review Received  


     // is Content Ready To Review Received  

     isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createGenreForm.controls['contentState'].setValue(['Completed']);
            // this.postCallGenres();
            console.log("================================================ edit popup")
            this.onSaveGenres();
           
        }
        if(event == 'Cancel'){

            this.createGenreForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCallGenres();
            this.onSaveGenres();

        }
       
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
