import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeoZoneService {
  
  
    constructor(private http: HttpClient) { }

    updateGeoZone(postObj:any) : Observable<any> {
        postObj.langId = undefined;

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateGeoZoneUrl, postObj,httpOptions);
    }
          
    insertGeoZone(postObj:any) : Observable<any> {

        postObj.langId = undefined;
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertGeoZoneUrl, postObj,httpOptions);
    }    
    
    getGeoZone( id: any) : Observable<any> {
        
        if(id){
  
        }
        else {
            id = 1;
        }
  
        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getGeoZoneUrl+"/" + id);
    }
  
    getAllGeoZone(filterObj,start,limit){

        filterObj.languageId = undefined;

        let urlGetAllGeoZone = environment.apiURL + environment.adminApiUrl + environment.getGeoZoneUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllGeoZone)
    }
  
    deleteGeoZone(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteGeoZoneUrl, postObj,httpOptions);
    }
    
}
