import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
// import { ClassificationService } from '../../../../../services/classification/classification.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Loginshared } from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { errorMessages } from '../../../../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import {ProfessionService} from '../../../../../services/profession/profession.service';
import { CommonService } from '../../../../../services/common_Service/common.service';
import { NewsService } from '../../../../../services/news/news.service';


@Component({
  selector: 'app-addProfession',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentProfession implements OnInit {

  isUserLoggedIn: boolean = false;
  createProfessionForm:FormGroup;
  errorMessages = errorMessages;
  professionSubmitted:boolean = false;

  newsCategoryList = [];
  dropdownList = [];
  selectedItems = [];
  
  start:number=0;
  limit:number=12;

  id:any;
  update=false;

  dropdownSettings={};

  loader:boolean=false;

  contentReadyToReview:boolean = false;
  shareMassage:any;

  isCreateNewShow:boolean = true;

  //@Input() contentId:any;
  @Output() updateSend = new EventEmitter<any>();

 //New Code start
  @Input() set contentId(value: any) {

      if(value == 'reset'){

          this.resetForm();

      }else {
        //start code for hide create new button
        if(value != 'null'){
            this.showCreateNewButton('hide');
        }
        //end code for hide create new button
          this.onLoad(value);
      }
      
  }
  //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

  constructor( private professionService:ProfessionService,private commonService:CommonService, private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private newsService: NewsService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  applyBlackTheme = localStorage.getItem('boolThemeFlag');


  roleAsses:any={};
  roleConfiguration:any=[];
  ngOnInit() {

        this.roleAsses.professionEdit = false;
        this.roleAsses.profession = false;
        this.roleAsses.professionCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {
          console.log('element',element);

              if((element.isView == 1 && element.pageTitle == 'Profession Type') ){
                  
                  this.roleAsses.profession = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Profession Type') ){
                  
                  this.roleAsses.professionEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Profession Type')){

                  this.roleAsses.professionCreate = true;
              
              }
        })
      
        // theme update code start
        this.commonService.currentThemelight.subscribe((result:any)=>{
                
          this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          
      })
      // theme update code start
  }

  onLoad(id) {

      let uid = localStorage.getItem('ud');
      if (uid !== undefined && uid != null) {

      }   else {
          this.router.navigate(['/login']);
      }

    let headerTitle = {
        title:'Master',
        subTitle:'Profession'
    }
    this.commonService.changeHeaderTitle(headerTitle);

      this.createProfessionForm = this.formBuilder.group({
        
        name: ['', Validators.compose([Validators.required])],
        // description:[''],
         id:[''],
        // // isActive:['1'],
        // contentState:['In-Progress'],
        // publisherState:['Un-published'],
        // reviewerState:['Un-approved'],
        // parentTerm:[[]],
        // weight:['', Validators.compose([Validators.required])],
        created_by:[localStorage.getItem('ud')]


      });

      // this.activatedRoute.params.subscribe(params => {
        // this.id = +params['id']; // (+) converts string 'id' to a number
        this.id = id;

        if(this.id != 'null'){
        
          console.log('this.id===>>>',this.id);
        
            this.update=true;
            this.getProfession(this.id)
        }else{
          this.update=false;        
        }

    // });

    this.dropdownSettings={
        singleSelection: false, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
    }

    this.getBlogCategoryInfo();
  }


  get l() { return this.createProfessionForm.controls; }

  onItemSelect(item:any, mappingName){
      console.log("onItemSelect = ",mappingName);

      if(mappingName == 'mapNews'){
      }
  }
   
  getProfession(id){
        
    // this.loader=true;
    this.commonService.changeloader(true);

    this.professionService.getProfession(id)
    .subscribe((result:any)=>{

          // this.loader=false;
          this.commonService.changeloader(false);

          console.log("result==========>",result);
          
          this.createProfessionForm.controls['name'].setValue(result.data[0].name);
    
    });
  }

  getBlogCategoryInfo(){

    this.commonService.getglobalNews()
    .subscribe((result:any)=>{
                
        this.dropdownList = [];
        this.newsCategoryList = [];

        if(result.data.data.length > 0){
                
            this.dropdownList = result.data.data;

            for (let newsCategoryObj of this.dropdownList) {

                this.newsCategoryList.push(
                {
                    'id': newsCategoryObj.newsId,
                    'itemName': newsCategoryObj.newsName
                });

            }
        }

        console.log('this.newsCategoryList',this.newsCategoryList);

        
    }) 
  }

  savecreateProfession(){

      console.log('update',this.id);
      console.log("Record received ",this.createProfessionForm.value);
      this.createProfessionForm.controls['id'].setValue(this.id);

      // this.loader=true;
      this.commonService.changeloader(true);

      this.professionSubmitted = true;

      if(!this.createProfessionForm.valid){
        this.loader=false;
        this.commonService.changeloader(false);
        return;
      }
    
      if(!this.update){

        delete this.createProfessionForm.value.id;
        this.professionService.insertProfession(this.createProfessionForm.value)
        .subscribe((result:any)=>{

            //this.loader=false;
            this.commonService.changeloader(false);
            //New Code for right side for from
            this.update = false;
            //this.updateSend.emit();
            this.resetForm();
            this.professionSubmitted = false;
            this.toastrService.success('Profession Created Successfully.');

        });
      }

      if(this.update){

        this.createProfessionForm.controls['id'].setValue(this.id);
        this.professionService.updateProfessions(this.createProfessionForm.value)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);
            //New Code for right side for from
            this.update = false;
            //this.updateSend.emit();
            this.resetForm();
            this.professionSubmitted = false;
            this.toastrService.success('Profession Updated Successfully.');      

        });
      }


  }

  //New Code for right side for from
  onContentReadyToReview(){
    
      this.loader =false;
    //code for global loader start
    this.commonService.changeloader(false);
    //code for global loader end

    if(this.createProfessionForm.valid){
      this.contentReadyToReview = true;
      this.shareMassage = 'newsCategory';
      return;
    }
    else{
      // this.blogCategorySubmitted = true;
      this.savecreateProfession()
    }

  }

  isContentReadyToReviewReceived(event){

      console.log("we are in",event);
      this.contentReadyToReview = false;

     //New Code start
      if(event == 'No'){

          this.contentReadyToReview = false;
         
      }
      //New Code end

      if(event == 'Yes'){

          // this.createGeoZoneForm.controls['contentState'].setValue(['Completed']);
          this.savecreateProfession();
         
      }
      if(event == 'Cancel'){

          // this.createGeoZoneForm.controls['contentState'].setValue(['In-Progress']);
       //   this.savecreateNewBlogCategory();
          
      }
     
  }

  resetForm(){

    //code for global loader start
    this.commonService.changeloader(false);

    this.updateSend.emit();
    
    //code for global loader end
    // this.img = [];
    // this.spinnerService.hide();
    // this.uploadImgFilePath=[];
    this.update = false;
    this.professionSubmitted = false;

    // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
    // while (arrayControl.length !== 0) {

    //     arrayControl.removeAt(0);
    // }

    this.createProfessionForm = this.formBuilder.group({
        
      name: ['', Validators.compose([Validators.required])],

      id:[''],
    
    });
    //reset multiselect list
    // this.selectedGeoZone=[];
    // this.selectedProductlist=[];

    //reset device type image
    // let obj = {
    //     'index':'',
    //     'deviceType':'',
    //     'selectedFiles':'',
    //     'fileName': '',
    //     'filePath': "",
    //     'fileType':''
    // }


    //     this.img.push({
    //         imgUrl:''
    //     })

    this.update = false;

    // this.multipleDeviceForm = this.formBuilder.group({

    //     devices: this.formBuilder.array([this.createItem()])

    // });
    
    this.showCreateNewButton('show');

  }

  showCreateNewButton(isShow){
    if(isShow == 'show'){
        this.isCreateNewShow = true;
    }
    else{
        this.isCreateNewShow = false;
    }

  }

}
