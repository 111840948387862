import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import {contentType ,assetGroupType ,sorting ,displayType,orderby, displayIndexingPattern} from '../../../../../configurations/configuration';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import {awsPath, deviceType} from '../../../../../configurations/configuration';
import {AssetService} from '../../../../../services/asset/asset.service';
import {Observable}  from 'rxjs/Observable';
import {SessionHelper} from '../../../../../services/localstorage';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {CarouselService } from '../../../../../services/carousel/carousel.service';
import {PageCategoryService} from '../../../../../services/page_category/page_category.service';
import { CasteAndCrewService } from '../../../../../services/caste_and_crew/caste_and_crew.service';

@Component({
  selector: 'app-addPageCategory',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentPageCategory implements OnInit {
    
    isCreateNewShow:boolean = true;

    isUserLoggedIn: boolean = false;
    createPageCategoryForm:FormGroup;
    geoZoneList = [];
    orderby = orderby;
    selectedItems = [];
    mapCastlist = [];
    mapGenreList =[];
    professionList = [];
    casteAndCrewList = [];
    selectedcastCrewAssetMapping = [];
    errorMessages = errorMessages;
    displayType = displayType;
    createPageCategorySubmitted:boolean = false;
    
    
    uploadImgFilePathVertical:any=[];
    uploadImgFilePathHorizontal:any=[];
    confAsset:any=[];
    update=false;
    loader = false;
    deviceType = deviceType;
    deviceTypeFull:boolean = false;
    deviceTypeDublicate:boolean = false;
    
    createCarouselSubmitted:boolean = false;
    dropdownList = [];
    mapLanguageList = [];
    multipleDeviceForm:FormGroup;
    mapAssetDynamicForm:FormGroup;

    primaryNavigationList=[];
    primaryNavigationListMap=[];
    ProducTypeDropdownList = [];
    ProducTypeDropdownListMap = [];
    producTypeList=[];
    producTypeListMap = [];
    assetGroupList=[];
    assetGroupListMap = [];
    menuCategoryList=[];
    menuCategoryListMap=[];
    assetCategoryList=[];
    assetCategoryListMap=[];
    selectedProductlist=[];
    selectedAssetCategory=[];
    mapClassificationList=[];
   

    selectedprimaryNavigationList=[];
    selectedcertificationList=[];
    selectedTrailerVideoList=[];
    selectedmenuCategoryList=[];
    selectedAssetGroupList=[];
    selectedprofessionTypeList = [];
    certificationList=[];
    trailerVideoList=[];
    primaryNavigationSettings={};
    menuCategorySettings={};
    dropdownSettings={};
    dropdownSingleSettings={};

    devices: FormArray;
    mapAssets: FormArray;
    start:number=0;
    limit:number=12;
    id:any;
    dTypeDublicateError:any;
    All:boolean=false;
    Web:boolean=false;
    Android:boolean=false;
    iOS:boolean=false;

    all:boolean = false;;
    web:boolean;
    android:boolean;
    roku:boolean;


    uploadImgFilePath:any=[];
    img:any=[];


    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;
    isDeleteAssetMapAction: boolean=false;
    deleteAssetMapIndex:any;
    
    langId:any;

    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    menuCategoryMappingFilter:any={};
    assetGroupMappingFilter:any={};
    assetCategoryMappingFilter:any={};
    //end mapping filter


    isDeleteHorizontalImageAction:boolean=false;
    isDeleteVerticalImageAction:boolean=false;

    formTabs:any = {

        pageInfo:{

         page:true,
         mapGeoZone:true,
         display:false,
         displayInfo:false,
       

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
            horizontal:false,
            vertical:false

        },
  
        metadata:{

            metadata:false,
            metadataHierarchy:false,
          

        }
    }
    
    @Output() updateSend = new EventEmitter<any>();
    
  
    //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.resetForm();

        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end
        
        
    shareMassage :any;    
    contentReadyToReview:boolean= false;

    applyBlackTheme = localStorage.getItem('boolThemeFlag');

    
    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
        
    constructor( private assetService:AssetService,private casteAndCrewService: CasteAndCrewService, private toastrService:ToastrService,private pageCategoryService:PageCategoryService,private activatedRoute:ActivatedRoute,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private formBuilder: FormBuilder ) {
        
        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );    
    }
    
    roleAsses:any={};
    roleConfiguration:any=[];
    ngOnInit() {

        this.commonService.currentLoader.subscribe(()=>{
             this.loader = false;
        })

        this.getAllCasteAndCrew();
        // theme update code start

        //below code start for role managemet.

        this.roleAsses.pageCategoryEdit = false;
        this.roleAsses.pageCategory = false;
        this.roleAsses.pageCategoryCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Page Category') ){
                  
                  this.roleAsses.pageCategory = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Page Category') ){
                  
                  this.roleAsses.pageCategoryEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Page Category')){

                  this.roleAsses.pageCategoryCreate = true;
              
              }

        })
                        
        // this.commonService.currentThemelight.subscribe((result:any)=>{
                        
        //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
        // })


        this.commonService.currentThemelight.subscribe((result:any)=>{

            if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='pageCategory'){

                let item = JSON.parse(localStorage.getItem('langItem'));

                if(item.id!=undefined)
                   this.id = item.id;
                   
                this.createPageCategoryForm.controls['id'].setValue(this.id);

                if(item.id != null && this.roleAsses.pageCategoryEdit == true){
                    this.editCreate = true;
                    this.update = true;
                }else{
                    this.editCreate = false;
                }

                this.createPageCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                this.isCreateNewShow = false;
                this.getPageCategoryInfo(item.id);
                // localStorage.removeItem('langItem');

            }
               this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          })
    

        // theme update code start

    }
    

    
    onCreateNew(){

        if(this.roleAsses.pageCategoryCreate == true){
               this.editCreate = true;
        }
        this.isCreateNewShow=false

    }

    editCreate:boolean= false;

    dropdown: any = {};
    dropdownTrailer:any={};

    onLoad(id) {


        this.id = id;
        
        this.mapAssetCategoryArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp=[];
        this.mapProductTypeArrayTemp=[];
        this.mapAssetGroupArrayTemp = [];
        this.mapMenuCategoryArrayTemp= [];

        this.setupSearchDebouncer();

        if(this.id != 'null' && this.roleAsses.pageCategoryEdit == true){
            this.editCreate = true;
            this.isCreateNewShow = false;

        }else{
            this.editCreate = false;
        }

        this.onPageCategoryTabClick();

        this.getGlobalProfession();
        
        this.langId = "1";
        
        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        let headerTitle = {
            title:'Page Management',
            subTitle:'Page Category'

        }
 
        this.commonService.changeHeaderTitle(headerTitle);
        
      

        this.mapAssetDynamicForm = this.formBuilder.group({
            mapAssets: this.formBuilder.array([this.createAsset()])
        });

        this.primaryNavigationSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }

        this.menuCategorySettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }

        this.dropdownSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }

        this.dropdownTrailer={
            singleSelection: true, 
            text:"Select text",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['itemName'], 
            maxHeight:130,
            lazyLoading: true   
        }

            this.dropdown = {

                singleSelection: false, 
                text:"Select text",
                selectAllText:'Select All',
                unSelectAllText:'UnSelect All',
                enableSearchFilter: true,
                classes:"myclass custom-class",
                searchBy: ['itemName'], 
                maxHeight:130,
                lazyLoading: true
    
            }

        this.dropdownSingleSettings={
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }




        this.productTypeMappingFilter.geoZoneId = [];
        this.primaryNavigationMappingFilter.productTypeId = [];
        this.menuCategoryMappingFilter.primaryNavigationId = [];
        this.assetGroupMappingFilter.menuCategoryId = [];
        this.assetCategoryMappingFilter.assetGroupId = [];

        this.getGeoZoneInfo();
        this.getGenreInfo();
        this.getGlobalCast();
        this.getLanguageInfo();
        this.getCertificationInfo();
     
        this.getNavigationInfoMap(this.primaryNavigationMappingFilter);
        this.getGlobalMenuCategoryMap(this.menuCategoryMappingFilter);
        this.getGlobalAssetGroupMap(this.assetGroupMappingFilter);
        this.getGlobalAssetCategoryMap(this.assetCategoryMappingFilter);
        this.getProductTypeInfoMap(this.productTypeMappingFilter);

        this.getGlobaltrailervideo();
        this.getGlobalclassification();

        //this.activatedRoute.params.subscribe(params => {

             this.id = id; // (+) converts string 'id' to a number

            //this.id = +params['id']; // (+) converts string 'id' to a number
            
            //this.langId = +params['langId']? +params['langId'] :"1";

            this.langId = '1';
            
          //New Code start
        if(this.id!='null'){
            
                console.log('this.id===>>>',this.id);

                this.update=true;

                this.getPageCategoryInfo(this.id);
               
            }else{


                this.img = [];
                
                this.update=false;
                                 
                this.img.push({
                    horizontalURL:'',
                    verticalURL:''
                }) 

                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }
    
                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }
    
                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
          
            }
  
        // In a real app: dispatch action to load the details here.
        //});   
        
        this.createPageCategoryForm = this.formBuilder.group({
        
            isActive : ["1"],
            langId: [this.langId],
            companyId :[""],
            title : ["",Validators.required],
            sameForAllZone : [''],
            sameForAllProductType : [''],
            displayIndexing :['',Validators.pattern(displayIndexingPattern)],
            displayType :[''],
            orderBy : [""],
            isPrimaryNavigationVisible : [""],
            isMenuCategoryVisible : [""],
            isAssetGroupVisible : [""],
            isAssetCategoryVisible : [""],
            isAssetVisible : [""],
            exclusionText :[""],
            description : [""],
            isVisible : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            sorting : [""],
            mapGeoZone:[[],Validators.required],
            mapCast:[[]],
            productDeviceTypeConfigure:[[]],
            // mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapAssetCategory:[[]],
            mapAsset:[[]],
            professionType:[[]],
            castCrewAssetMapping:[[]],
            mapCastAndCrew:[[]],
            mapLanguage:[[]],
            displayProductType:[[],Validators.required],
            displayPrimaryNavigation:[[]],
            displayMenuCategory:[[]],
            displayAssetGroup:[[]],
            displayAssetCategory:[[]],
            displayAsset:[[]],
            mapClassification:[[]],
            mapGenre:[[]],
            mapCmsUserWithWatchlist:[''],
		    mapCmsUserPlaylist: [''],
			mapCmsUserWithFavorite:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });

            //pravin start code
            this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });
    }

    onMappingTabClick(){
        this.formTabs = {

            pageInfo:{
    
             page:false,
             mapGeoZone:false,
             display:false,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
      
            mapping:true
        }
    }

    onDeviceTypeTabClick(){

        this.formTabs = {

            pageInfo:{
    
             page:false,
             mapGeoZone:false,
             display:false,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:true,
                horizontal:false,
                vertical:false
    
            },
      
            mapping:false
        }
    }

    onPageCategoryTabClick(){

        this.formTabs = {

            pageInfo:{
    
             page:true,
             mapGeoZone:true,
             display:false,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
      
            mapping:false
        }
    }

    onDisplayTabClick(){

        this.formTabs = {

            pageInfo:{
    
             page:true,
             mapGeoZone:false,
             display:false,
             displayInfo:true,
           
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
      
            mapping:false
        }

    }

    onHorizontalTabClick(){
        this.formTabs = {

            pageInfo:{
    
             page:false,
             mapGeoZone:false,
             information:false,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:true,
                vertical:false
    
            },
      
            mapping:false
        }
    }

    onVerticalTabClick(){

        this.formTabs = {

            pageInfo:{
    
             page:false,
             mapGeoZone:false,
             information:false,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:false,
                vertical:true
    
            },
      
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
              
    
            }
        }
    }

    onInformationTabClick(){

        this.formTabs = {

            pageInfo:{
    
             page:true,
             mapGeoZone:false,
             information:true,
             displayInfo:false,
           
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
      
            mapping:false
        }
    }

   

    get l() { return this.createPageCategoryForm.controls; }
    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }
    get ms(): FormArray {
        return this.mapAssetDynamicForm.get('mapAssets') as FormArray;
    }

    //dri multiselect code
    onItemSelect(item:any, mappingName){
        console.log("select from = ",mappingName);

        if(mappingName == 'mapGeoZone'){
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.getProductTypeInfo(this.productTypeMappingFilter);
        }
        else if(mappingName == 'displayProductType'){
            this.primaryNavigationMappingFilter.productTypeId.push(item.id);
            this.getNavigationInfo(this.primaryNavigationMappingFilter);
        }
        else if(mappingName == 'displayPrimaryNavigation'){
            this.menuCategoryMappingFilter.primaryNavigationId.push(item.id);
            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
        }
        else if(mappingName == 'displayMenuCategory'){
            this.assetGroupMappingFilter.menuCategoryId.push(item.id);
            this.getGlobalAssetGroup(this.assetGroupMappingFilter);
        }
        else if(mappingName == 'displayAssetGroup'){
            this.assetCategoryMappingFilter.assetGroupId.push(item.id);
            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
        }
    }

    getAllCasteAndCrew(){

        this.loader=true;
        
        this.casteAndCrewService.getAllCast({},0,10000)
        .subscribe((result:any)=>{
            
            this.loader = false;

            this.casteAndCrewList = [];
            
            console.log('result===>',result.data.data);
            
            if(result.data.data.length > 0){

                for (let casteAndCrewList of result.data.data) {
   
                    if(casteAndCrewList.castFirstName!=''){
                    
                        this.casteAndCrewList.push(
                        {
                            'id': casteAndCrewList.castId,
                            'itemName': casteAndCrewList.castFirstName
                        });
                    } 
                }
            }

        }) 
        
    }
    
    OnItemDeSelect(item:any, mappingName){
        
        if(mappingName == 'mapGeoZone'){
            for (let index=0; index< this.productTypeMappingFilter.geoZoneId.length;index++){

                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                    console.log("mapGeoZone deleted = ",item.id);

                    if(this.productTypeMappingFilter.geoZoneId.length == 0){
                        this.producTypeList = [];
                        this.producTypeList =this.producTypeListMap;
                        this.createPageCategoryForm.controls['displayProductType'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getProductTypeInfo(this.productTypeMappingFilter);
                    }

                    
                    return;
                }
            }
        }
        else if(mappingName == 'displayProductType'){
            for (let index=0; index< this.primaryNavigationMappingFilter.productTypeId.length;index++){
                if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){
                    this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);
                    console.log("displayProductType deleted = ",item.id);

                    if(this.primaryNavigationMappingFilter.productTypeId.length == 0){
                        this.primaryNavigationList = [];
                        this.primaryNavigationList = this.primaryNavigationListMap;
                        this.createPageCategoryForm.controls['displayPrimaryNavigation'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                    }

                    return;
                }
            }
        }
        else if(mappingName == 'displayPrimaryNavigation'){
            for (let index=0; index< this.menuCategoryMappingFilter.primaryNavigationId.length;index++){
                if(this.menuCategoryMappingFilter.primaryNavigationId[index] == item.id){
                    this.menuCategoryMappingFilter.primaryNavigationId.splice(index, 1);
                    console.log("displayPrimaryNavigation deleted = ",item.id);

                    if(this.menuCategoryMappingFilter.primaryNavigationId.length == 0){
                        this.menuCategoryList = [];
                        this.menuCategoryList = this.menuCategoryListMap;
                        this.createPageCategoryForm.controls['displayMenuCategory'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    }

                    return;
                }
            }
        }
        else if(mappingName == 'displayMenuCategory'){
            for (let index=0; index< this.assetGroupMappingFilter.menuCategoryId.length;index++){
                if(this.assetGroupMappingFilter.menuCategoryId[index] == item.id){
                    this.assetGroupMappingFilter.menuCategoryId.splice(index, 1);
                    console.log("displayMenuCategory deleted = ",item.id);

                    if(this.assetGroupMappingFilter.menuCategoryId.length == 0){
                        this.assetGroupList = [];
                        this.assetGroupList =  this.assetGroupListMap;
                        this.createPageCategoryForm.controls['displayAssetGroup'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                    }

                    return;
                }
            }
        }
        else if(mappingName == 'displayAssetGroup'){
            for (let index=0; index< this.assetCategoryMappingFilter.assetGroupId.length;index++){
                if(this.assetCategoryMappingFilter.assetGroupId[index] == item.id){
                    this.assetCategoryMappingFilter.assetGroupId.splice(index, 1);
                    console.log("displayAssetGroup deleted = ",item.id);

                    if(this.assetCategoryMappingFilter.assetGroupId.length == 0){
                        this.assetCategoryList = [];
                        this.assetCategoryList = this.assetCategoryListMap;
                        this.createPageCategoryForm.controls['displayAssetCategory'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
                    }
                    
                    return;
                }
            }
        }
    }
    
    onSelectAll(item: any , mappingName){

        console.log("select All from = ",mappingName);

        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter = filterObj;
            this.getProductTypeInfo(filterObj);
        }
        else if(mappingName == 'displayProductType'){
            let filterObj ={ productTypeId :[] };
            item.forEach(function (value) {
                filterObj.productTypeId.push(value.id);
            });
            this.primaryNavigationMappingFilter = filterObj;
            this.getNavigationInfo(filterObj);
        }
        else if(mappingName == 'displayPrimaryNavigation'){
            let filterObj ={ primaryNavigationId :[] };
            item.forEach(function (value) {
                filterObj.primaryNavigationId.push(value.id);
            });
            this.menuCategoryMappingFilter = filterObj;
            this.getGlobalMenuCategory(filterObj);
        }
        else if(mappingName == 'displayMenuCategory'){
            let filterObj ={ menuCategoryId :[] };
            item.forEach(function (value) {
                filterObj.menuCategoryId.push(value.id);
            });
            this.assetGroupMappingFilter = filterObj;
            this.getGlobalAssetGroup(filterObj);
        }
        else if(mappingName == 'displayAssetGroup'){
            let filterObj ={ assetGroupId :[] };
            item.forEach(function (value) {
                filterObj.assetGroupId.push(value.id);
            });
            this.assetCategoryMappingFilter = filterObj;
            this.getGlobalAssetCategory(filterObj);
        }
    }

    onDeSelectAll(event, info){

        if(info =='mapGeoZone'){
            this.createPageCategoryForm.controls['mapGeoZone'].setValue([]);
            this.producTypeList = [];
            
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'displayProductType'){
            this.createPageCategoryForm.controls['displayProductType'].setValue([]);
            this.primaryNavigationList = [];
            this.mapProductTypeArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'displayPrimaryNavigation'){
            this.createPageCategoryForm.controls['displayPrimaryNavigation'].setValue([]);
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='displayMenuCategory'){
            this.createPageCategoryForm.controls['displayMenuCategory'].setValue([]);
            this.assetGroupList = [];
            this.mapMenuCategoryArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='displayAssetGroup'){
            this.createPageCategoryForm.controls['displayAssetGroup'].setValue([]);
            this.assetCategoryList = [];
            this.mapAssetGroupArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='displayAssetCategory'){
            this.createPageCategoryForm.controls['displayAssetCategory'].setValue([]);
            this.mapAssetCategoryArrayTemp = [];
        }
        else if(info =='displayAsset'){
            this.createPageCategoryForm.controls['displayAsset'].setValue([]);
            
        }
        else if(info =='mapPrimaryNavigation'){
            this.createPageCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
        }
        else if(info =='mapMenuCategory'){
            this.createPageCategoryForm.controls['mapMenuCategory'].setValue([]);
        }
        else if(info =='mapAssetGroup'){
            this.createPageCategoryForm.controls['mapAssetGroup'].setValue([]);
        }
        else if(info =='mapAssetCategory'){
            this.createPageCategoryForm.controls['mapAssetCategory'].setValue([]);
        }
        else if(info =='mapAsset'){
            this.createPageCategoryForm.controls['mapAsset'].setValue([]);
        }
        else if(info =='mapGenre'){
            this.createPageCategoryForm.controls['mapGenre'].setValue([]);
        }
        else if(info =='mapClassification'){
            this.createPageCategoryForm.controls['mapClassification'].setValue([]);
        }

        else if(info =='mapLanguage'){
            this.createPageCategoryForm.controls['mapLanguage'].setValue([]);
        }
        
        else if(info =='mapCast'){
            this.createPageCategoryForm.controls['mapCast'].setValue([]);
        }
        else if('castCrewAssetMapping'){
            
            this.createPageCategoryForm.controls['castCrewAssetMapping'].setValue([]);

        }
    }

    savecreatePageCategory(){

        // this.loader = false;
        this.commonService.changeloader(false);

        this.createPageCategorySubmitted = true;
        
        let varialbeArray=[];
        let array=[];


        let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath=[];

        //remove empty file upload img obj from array.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.deviceTypeDublicate){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(!this.createPageCategoryForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(!this.update){
            
            if(this.mapAssetDynamicForm.value.mapAssets.length>0){

                    for(let i=0;i< this.mapAssetDynamicForm.value.mapAssets.length;i++){ 

                        if(this.mapAssetDynamicForm.value.mapAssets[i].mapAsset.length>0){
    
                            console.log("this.mapAssetDynamicForm.value.mapAssets",this.mapAssetDynamicForm.value.mapAssets);
                            
                                array.push(
                                    {
                                        "id": this.mapAssetDynamicForm.value.mapAssets[i].mapAsset[0].id,
                                        "itemName":this.mapAssetDynamicForm.value.mapAssets[i].mapAsset[0].itemName,
                                        "displayIndexing":this.mapAssetDynamicForm.value.mapAssets[i].displayIndexing,
                                    
                                    });   
                                    this.createPageCategoryForm.controls['mapAsset'].setValue(array);
                            }
                        }

                    }

        }else{

            if(this.mapAssetDynamicForm.value.mapAssets.length>0){

                for(let i=0;i< this.mapAssetDynamicForm.value.mapAssets.length;i++){ 
        
                    console.log("update",this.mapAssetDynamicForm.value.mapAssets);

                        if(this.mapAssetDynamicForm.value.mapAssets[i].mapAsset.length > 0){
                            array.push(
                                {
                                    "id": this.mapAssetDynamicForm.value.mapAssets[i].mapAsset[0].id,
                                    "itemName":this.mapAssetDynamicForm.value.mapAssets[i].mapAsset[0].itemName,
                                    "displayIndexing":this.mapAssetDynamicForm.value.mapAssets[i].displayIndexing,
                                
                                });  
            
                                this.createPageCategoryForm.controls['mapAsset'].setValue(array);  
                        }
                    
                }

            }
        }

        if(!this.mapAssetDynamicForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
        
        let async = this.uploadImgFilePath.length;

        if(!this.update){

            if(this.roleAsses.pageCategoryCreate== false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

            // uploadImgFilePosterImage upload code finish pravin  'tcs-assets/'
            

            console.log("uploadImgFilePath>>",this.uploadImgFilePath)
            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles,awsPath.asset).then(res => {

                        if (res && res.Location) {
                                async--;
                                if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                                if (async == 0) {

                                    for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                                
                                                varialbeArray.push(
                                                {
                                                    "label": this.multipleDeviceForm.value.devices[i].label,
                                                    "description":this.multipleDeviceForm.value.devices[i].description,
                                                    "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                                                    "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                    "verticalFileName": "",
                                                    "verticalFilePath": "",
                                                    "verticalFileType": "",  
                                                    "horizontalFileName": "",
                                                    "horizontalFilePath": "",
                                                    "horizontalFileType": ""
                                                
                                                });   

                                    }

                                    let asyncvariables = varialbeArray.length;
                                    for(let i=0;i< varialbeArray.length;i++){
                                            asyncvariables--;
                                            for(let j = 0 ;j< this.uploadImgFilePath.length; j++){
        
                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
                                                    varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                    varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                    varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
        
                                                }

                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
        
                                                    varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                    varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                    varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
        
                                                }
        
                                            }
                                    }

                                    if(asyncvariables == 0){

                                        this.createPageCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                        console.log("createNewProductForm123",this.createPageCategoryForm);
                                        
                                        if(!this.multipleDeviceForm.valid){
                                            // this.loader = false;
                                            this.commonService.changeloader(false);
                                            return;
                                        }
                                
                                        if(!this.createPageCategoryForm.valid){
                                            // this.loader = false;
                                            this.commonService.changeloader(false);
                                            return;
                                        }
                                        
                                        this.createCarouselSubmitted=true;
                                        
                                        this.postCallPageCategory();
                                        
                                        //New Code for right side for from
                                        //this.onContentReadyToReview();
                                    }

                                }      

                        }

                    });
                }
            }

            if(this.uploadImgFilePath.length==0){
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                    
                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
                
                this.createCarouselSubmitted = true;
                this.createPageCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }
        
                if(!this.createPageCategoryForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                
                console.log("createNewProductForm",this.createPageCategoryForm);
                this.postCallPageCategory();
                
                //New Code for right side for from
                //this.onContentReadyToReview();
            }

        }
    
        if(this.update){

            if(this.roleAsses.pageCategoryEdit == false){

                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }

            let asyncvariables = this.multipleDeviceForm.value.devices.length;
            if(this.uploadImgFilePath.length == 0){

                this.createPageCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                console.log("createPageCategoryForm",this.createPageCategoryForm);
                console.log("multipleDeviceForm",this.multipleDeviceForm);
                this.createCarouselSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                if(!this.createPageCategoryForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }
            
                console.log("createNewProductForm",this.createPageCategoryForm);
                
                this.postCallPageCategory();
                //New Code for right side for from
                //this.onContentReadyToReview();

            }

            // if(this.uploadImgFilePath.length > 0 && this.createNewAssetForm.value.vodClosedCaptionFilePath!=''){
            // }
            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                            if(this.uploadImgFilePath[i].verticalFile == true)
                            this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j< this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){


                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }
                                }
                            }

                            if(asyncvariables == 0){
 
                                this.createCarouselSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return;
                                }

                                if(!this.createPageCategoryForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return;
                                }

                                this.createPageCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("createNewProductForm",this.createPageCategoryForm);
                                this.postCallPageCategory();
                                
                                
                                //New Code for right side for from
                                //this.onContentReadyToReview();
                                
                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    })
                }
            }

        }

    }

    postCallPageCategory(){

        
        console.log("createPageCategoryForm.value.displayProductType1234567")


        this.createPageCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'))

        if( this.update == false ) {

            delete this.createPageCategoryForm.value.id;
            // this.createPageCategoryForm.value.displayAsset = undefined;
            // this.createPageCategoryForm.value.displayAssetCategory = undefined;
            // this.createPageCategoryForm.value.displayAssetGroup = undefined;
            // this.createPageCategoryForm.value.displayMenuCategory = undefined;
            // this.createPageCategoryForm.value.displayPrimaryNavigation = undefined;
            // this.createPageCategoryForm.value.displayProductType = undefined;

            // this.loader=true;
            this.commonService.changeloader(true);

            this.pageCategoryService.insertPageCategory(this.createPageCategoryForm.value)
            .subscribe((result:any)=>{
                // this.loader=false;
                this.commonService.changeloader(false);
                
/*              this.router.navigate(['page/page_category/list']);*/
                this.toastrService.success('Page Category Created Successfully.');


                    //New Code for right side for from
                    this.update = false;
                    this.id= null;
                    this.updateSend.emit();

                    this.onPageCategoryTabClick();
                    this.resetForm();
                    this.createPageCategorySubmitted = false;
            });

        }

        if(this.update){

            this.createPageCategoryForm.controls['id'].setValue(this.id);
            // this.createPageCategoryForm.value.displayAsset = undefined;
            // this.createPageCategoryForm.value.displayAssetCategory = undefined;
            // this.createPageCategoryForm.value.displayAssetGroup = undefined;
            // this.createPageCategoryForm.value.displayMenuCategory = undefined;
            // this.createPageCategoryForm.value.displayPrimaryNavigation = undefined;
            // this.createPageCategoryForm.value.displayProductType = undefined;

            // this.loader=true;
            this.commonService.changeloader(true);
            this.pageCategoryService.updatePageCategory(this.createPageCategoryForm.value)
            .subscribe((result:any)=>{
                // this.loader=false;
                this.commonService.changeloader(false);

                this.toastrService.success('Page Category Updated Successfully.');
/*                this.router.navigate(['page/page_category/list']);*/

                console.log('<====result result result============>',result);
                this.id= null;
                    //New Code for right side for from
                    this.update = false;
                    this.updateSend.emit();
                    this.resetForm();
                    this.createPageCategorySubmitted = false;
                
            });
        }
    }

    selectFileVertical(e ,i) {

        //set obj for file upload of vertical section
        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 
         

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].verticalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }


            e.target.value = '';

       
    }

    selectFileHorizontal(e ,i) {
           //set obj for file upload of horizontal section
           if(e.target.files[0].name==undefined)
           return;
   
           this.uploadImgFilePathHorizontal[i].index = i;
           this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
           this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
           this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
           this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
           this.uploadImgFilePathHorizontal[i].horizontalFile = true; 
        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }


            e.target.value = '';

       
    }

    //pravin code start
    getGeoZoneInfo(){
                
        this.productConfigurationService.getAllGeoZone(this.geoZoneObj,this.start,this.limit)
        .subscribe((result:any)=>{
            
            this.dropdownList = [];
            
            if(result.data.data.length > 0){

                if(this.isSearch == true){

                    this.geoZoneList = [];
                    this.isSearch = false;

                }
                    
                this.dropdownList = result.data.data;
    
                for (let geoZoneObj of this.dropdownList) {
                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });

                    this.geoZoneList = this.removeDuplicates(this.geoZoneList , "id");
    
                }
            }
            
        }) 
    }

    fetchMoreGetGeoZone(event){

        if ( ((event.endIndex >= this.geoZoneList.length - 2)) && (this.geoZoneList.length >=8)) {

                this.start = this.start + 10;

                this.getGeoZoneInfo();  
   
        }
    }
       
    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }

    mapMenuCategoryArrayTemp:any= [];

    getGlobalMenuCategory(filterObj){

        console.log("filterObj= ",filterObj);

        this.commonService.getGlobalMenuCategory(this.menuCategoryMappingFilter,this.startCategory,10).subscribe((result:any)=>{
            
           // let mapMenuCategoryArrayTemp = [];
            let mapMenuCategoryArray = this.createPageCategoryForm.value.displayMenuCategory;

            if(result.data.data.length > 0){  
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.menuCategoryList = [];

                }
                for (let menuCategoryList of result.data.data) {
    
                    mapMenuCategoryArray.forEach(element => {

                        if(element.id == menuCategoryList.menuCategoryId) {
                            
                            this.mapMenuCategoryArrayTemp.push(element);
                            this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayMenuCategory'].setValue(this.mapMenuCategoryArrayTemp);  
                                          
                        }

                     
                    });

                    if(menuCategoryList.menuCategoryName!=''){
                    
                        this.menuCategoryList.push(
                        {
                            'id': menuCategoryList.menuCategoryId,
                            'itemName': menuCategoryList.menuCategoryName
                        });

                        this.menuCategoryList = this.removeDuplicates(this.menuCategoryList,'id')


                    } 
                }
            } 

        });
    }

    fetchMoreGetCategory(event){

        if ( ((event.endIndex >= this.menuCategoryList.length - 2)) && (this.menuCategoryList.length >=8)) {

                this.startCategory = this.startCategory + 10;

                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);  
   
        }
    }

    mapAssetGroupArrayTemp:any = [];
    getGlobalAssetGroup(filterObj){

            this.commonService.getGlobalAssetGroup(this.assetGroupMappingFilter,this.startGroup,10).subscribe((result:any)=>{

                // this.assetGroupList = [];

                // let mapAssetGroupArrayTemp = [];
                let mapAssetGroupArray = this.createPageCategoryForm.value.displayAssetGroup;
                
                if(result.data.data.length > 0){

                    for (let assetGroupList of result.data.data) {

                        if(this.isSearch == true){

                            this.isSearch = false;
                            this.assetGroupList = [];

                        }

                        mapAssetGroupArray.forEach(element => {

                            if(element.id == assetGroupList.assetGroupId) {

                                this.mapAssetGroupArrayTemp.push(element); 
                                this.mapAssetGroupArrayTemp = this.removeDuplicates(this.mapAssetGroupArrayTemp,'id')
                                this.createPageCategoryForm.controls['displayAssetGroup'].setValue(this.mapAssetGroupArrayTemp);                   
                           
                            }
                           
                        });

                        if(assetGroupList.assetGroupName!=null){
                        
                            this.assetGroupList.push(
                            {
                                'id': assetGroupList.assetGroupId,
                                'itemName': assetGroupList.assetGroupName
                            });

                            this.assetGroupList = this.removeDuplicates(this.assetGroupList,'id');
                            this.assetGroupList = this.removeDuplicates(this.assetGroupList,'id');


                            if(filterObj == {}){
                                this.assetGroupListMap = this.assetGroupList;
                            }
                        } 

                    }

                } 

            });
    }

    fetchMoreGetAssetGroup(event){

        if ( ((event.endIndex >= this.assetGroupList.length - 2)) && (this.assetGroupList.length >=8)) {

                this.startGroup = this.startGroup + 10;

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);  
   
        }
    }

    mapProductTypeArrayTemp:any=[];
    getProductTypeInfo(filterObj){

        this.commonService.getGlobalProductType(this.productTypeMappingFilter,this.startProduct,10).subscribe((result:any)=>{
            this.ProducTypeDropdownList = [];
            // this.producTypeList = [];
           // let mapProductTypeArrayTemp = [];
            let mapProductTypeArray = this.createPageCategoryForm.value.displayProductType;

            if(result.data.data.length > 0){       

                this.ProducTypeDropdownList = result.data.data;

                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                    if(this.isSearch == true){
                        this.producTypeList = [];
                        this.isSearch = false;
                    }

                    mapProductTypeArray.forEach(element => {

                        if(element.id == ProducTypeLists.productTypeId) {

                            this.mapProductTypeArrayTemp.push(element);   
                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayProductType'].setValue(this.mapProductTypeArrayTemp);
                        }

                            
                    });

                    if(ProducTypeLists.productTypeName!=''){
                            
                        this.producTypeList.push(
                            {
                                'id': ProducTypeLists.productTypeId,
                                'itemName': ProducTypeLists.productTypeName
                            });

                            this.producTypeList = this.removeDuplicates(this.producTypeList,'id')

                    }   
                    
                } 
            } 
        });
        
    }

    fetchMoreGetProductType(event){

        if ( ((event.endIndex >= this.producTypeList.length - 2)) && (this.producTypeList.length >=8)) {

                this.startProduct = this.startProduct + 10;

                this.getProductTypeInfo(this.productTypeMappingFilter);  
   
        }
    }


    mapPrimaryNavigationArrayTemp:any=[];
    getNavigationInfo(filterObj){

        console.log("filterObj= ",filterObj);
        
        this.commonService.getGlobalPrimaryNavigation(this.primaryNavigationMappingFilter,this.startPrimary,10).subscribe((result:any)=>{
            
            console.log("getNavigationInfo result = ",result.data.data);

            // this.primaryNavigationList = [];

            // let mapPrimaryNavigationArrayTemp = [];
            let mapPrimaryNavigationArray = this.createPageCategoryForm.value.displayPrimaryNavigation;

            if(result.data.data.length > 0){       
                for (let primaryNavigationLists of result.data.data) {

                    mapPrimaryNavigationArray.forEach(element => {

                        if(element.id == primaryNavigationLists.primaryNavigationId) {

                            this.mapPrimaryNavigationArrayTemp.push(element);  
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp,'id');                 
                            this.createPageCategoryForm.controls['displayPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);                 
                      
                        }
                    });

                    if(primaryNavigationLists.primanyNavigationName!=''){

                        this.primaryNavigationList.push(
                            {
                                'id': primaryNavigationLists.primaryNavigationId,
                                'itemName': primaryNavigationLists.primanyNavigationName
                            });

                            if(filterObj =={}){

                                this.primaryNavigationListMap = this.primaryNavigationList;

                            }
                        
                        // this.createPageCategoryForm.controls['displayProductType'].setValue(mapProductTypeArrayTemp);     
                        this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList,'id')

                    } 
                }
            } 
        })
    }

    fetchMoreGetPrimary(event){

        if ( ((event.endIndex >= this.primaryNavigationList.length - 2)) && (this.primaryNavigationList.length >=8)) {

                this.startPrimary = this.startPrimary + 10;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);  
   
        }
    }

    getGlobaltrailervideo(){
        
        this.commonService.getGlobalTrailerVideo(this.filterMapAsset,this.startMapAsset,10).subscribe((result:any)=>{
            
            // this.trailerVideoList = [];
            this.loader = false;
            
            if(result.data.data.length > 0){  
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.trailerVideoList = [];

                }

                for (let trailerVideoLists of result.data.data) {
    
                    if(trailerVideoLists.assetName != '' && trailerVideoLists.assetName != null){
                    
                        this.trailerVideoList.push(
                        {
                            'id': trailerVideoLists.assetId,
                            'itemName': trailerVideoLists.assetName
                        });
                    } 

                }
                
                this.trailerVideoList = this.removeDuplicates(this.trailerVideoList,'id')

            } 

                this.loader = false;



        });
        
    }

    assetList = [];

    getAllAsset(){
 
        this.loader = false;
        
        this.assetService.getAllAsset(this.filterMapAsset,this.startMapAsset,10)
        .subscribe((result:any)=>{
            
            if(result.data.data.length > 0){  
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.assetList = [];

                }

                for (let trailerVideoLists of result.data.data) {
    
                    if(trailerVideoLists.title != '' && trailerVideoLists.title != null){
                    
                        this.assetList.push(
                        {
                            'id': trailerVideoLists.id,
                            'itemName': trailerVideoLists.title
                        });

                    this.assetList = this.removeDuplicates(this.assetList,'id');

                    } 

                }

                
                    // this.assetList = this.removeDuplicates(this.assetList,'id');
                    this.loader = false;

    
            } 
        }) 
        
    }


    startMapAsset = 0;
    filterMapAsset:any = {};
    fetchMoreGetMapAsset(event){
  
        if ( ((event.endIndex >= this.assetList.length - 2)) && (this.assetList.length >=7)) {

            this.startMapAsset = this.startMapAsset + 10;

            this.getAllAsset();  

        }
    }

    mapAssetCategoryArrayTemp:any = [];
    startAssetCategory = 0;
    getGlobalAssetCategory(filterObj){

        this.commonService.getGlobalAssetCategory(this.assetCategoryMappingFilter,this.startAssetCategory,10).subscribe((result:any)=>{

            let mapAssetCategoryArray = this.createPageCategoryForm.value.displayAssetCategory;
            
            if(result.data.data.length > 0){       

                for (let assetCategoryList of result.data.data) {

                    if(this.isSearch == true){

                        this.isSearch = false;
                        this.assetCategoryList = [];

                    }

                    mapAssetCategoryArray.forEach(element => {

                        if(element.id == assetCategoryList.assetCategoryId) {

                            this.mapAssetCategoryArrayTemp.push(element); 
                            this.mapAssetCategoryArrayTemp = this.removeDuplicates(this.mapAssetCategoryArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayAssetCategory'].setValue(this.mapAssetCategoryArrayTemp); 

                        }
                    });

                    if(assetCategoryList.assetCategoryName != null){
                    
                        this.assetCategoryList.push(
                        {
                            'id': assetCategoryList.assetCategoryId,
                            'itemName': assetCategoryList.assetCategoryName
                        });

                        if(filterObj == {}){

                            this.assetCategoryListMap = this.assetCategoryList;

                        }

                    }
                    
                    this.assetCategoryList = this.removeDuplicates(this.assetCategoryList,'id')

                }
            } 
        });
    }

    fetchMoreGetAssetCategory(event){
        
        if ( ((event.endIndex >= this.assetCategoryList.length - 2)) && (this.assetCategoryList.length >=7)) {

            this.startAssetCategory = this.startAssetCategory + 10;

            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);  

        }
    }

    

    ////////////////////////

    getNavigationInfoMap(filterObj){

        console.log("filterObj= ",filterObj);
        
        this.commonService.getGlobalPrimaryNavigation(this.filterPrimaryMap,this.startPrimaryMap,10).subscribe((result:any)=>{
            
            console.log("getNavigationInfo result = ",result.data.data);

            let mapPrimaryNavigationArrayTemp = [];
            let mapPrimaryNavigationArray = this.createPageCategoryForm.value.displayPrimaryNavigation;

            if(result.data.data.length > 0){       
                for (let primaryNavigationLists of result.data.data) {

                    mapPrimaryNavigationArray.forEach(element => {

                        if(element.id == primaryNavigationLists.primaryNavigationId) {
                            mapPrimaryNavigationArrayTemp.push(element); 
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayPrimaryNavigation'].setValue(mapPrimaryNavigationArrayTemp);  
                  
                        }
                    });

                    if(primaryNavigationLists.primanyNavigationName!='' && primaryNavigationLists.primanyNavigationName!=null){

                       this.primaryNavigationListMap.push(
                        {
                            'id': primaryNavigationLists.primaryNavigationId,
                            'itemName': primaryNavigationLists.primanyNavigationName
                        });

                        this.primaryNavigationListMap = this.removeDuplicates(this.primaryNavigationListMap,'id')
                    } 
                }
            } 
        })
    }

    
    startPrimaryMap = 0;
    filterPrimaryMap:any = {};
    fetchMoreMapPrimaryNavigation(event){
  
        if ( ((event.endIndex >= this.primaryNavigationListMap.length - 2)) && (this.primaryNavigationListMap.length >=7)) {

            this.startPrimaryMap = this.startPrimaryMap + 10;

            this.getNavigationInfoMap(this.filterPrimaryMap);  

        }
    }

    getGlobalAssetCategoryMap(filterObj){

        this.commonService.getGlobalAssetCategory(this.filterAssetCategoryMap,this.startAssetCategoryMap,10).subscribe((result:any)=>{

            let mapAssetCategoryArrayTemp = [];
            let mapAssetCategoryArray = this.createPageCategoryForm.value.displayAssetCategory;
            
            if(result.data.data.length > 0){       

                for (let assetCategoryList of result.data.data) {

                    if(this.isSearch == true){

                        this.isSearch = false;
                        this.assetCategoryListMap = [];

                    }

                    mapAssetCategoryArray.forEach(element => {

                        if(element.id == assetCategoryList.assetCategoryId) {

                            mapAssetCategoryArrayTemp.push(element);
                            this.mapAssetCategoryArrayTemp = this.removeDuplicates(this.mapAssetCategoryArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayAssetCategory'].setValue(mapAssetCategoryArrayTemp); 
                    
                        }
                    });

                    if(assetCategoryList.assetCategoryName!=''){
                    
                        this.assetCategoryListMap.push(
                        {
                            'id': assetCategoryList.assetCategoryId,
                            'itemName': assetCategoryList.assetCategoryName
                        });

                        this.assetCategoryListMap = this.removeDuplicates(this.assetCategoryListMap,'id')
                    } 
                }
            } 
        });
    }

    startAssetCategoryMap = 0;
    filterAssetCategoryMap:any= { };

    fetchMoreMapAssetCategory(event){
        
        if ( ((event.endIndex >= this.assetCategoryListMap.length - 2)) && (this.assetCategoryListMap.length >=7)) {

            this.startAssetCategoryMap = this.startAssetCategoryMap + 10;

            this.getGlobalAssetCategoryMap(this.filterAssetCategoryMap);  

        }
    }

    getGlobalAssetGroupMap(filterObj){

        console.log("filterObj= ",filterObj);

        this.commonService.getGlobalAssetGroup(this.filterAssetGroupMap,this.startAssetGroupMap,10).subscribe((result:any)=>{

            // this.assetGroupListMap= [];

            let mapAssetGroupArrayTemp = [];
            let mapAssetGroupArray = this.createPageCategoryForm.value.displayAssetGroup;
            
            if(result.data.data.length > 0){    

                for (let assetGroupList of result.data.data) {

                    mapAssetGroupArray.forEach(element => {

                        if(element.id == assetGroupList.assetGroupId) {

                            this.mapAssetGroupArrayTemp.push(element);  
                            this.mapAssetGroupArrayTemp = this.removeDuplicates(this.mapAssetGroupArrayTemp,'id')
                            this.createPageCategoryForm.controls['displayAssetGroup'].setValue(mapAssetGroupArrayTemp); 
                  
                        }
                    });

                    if(assetGroupList.assetGroupName!=''){
                    
                        this.assetGroupListMap.push(
                        {
                            'id': assetGroupList.assetGroupId,
                            'itemName': assetGroupList.assetGroupName
                        });

                        if(filterObj == {}){
                            this.assetGroupListMap = this.assetGroupList;
                        }

                        this.assetGroupListMap = this.removeDuplicates(this.assetGroupListMap,'id')
                    } 
                }
            } 

        });
    }

    startAssetGroupMap = 0;
    filterAssetGroupMap:any= {};
    fetchMoreMapAssetGroup(event){

        if ( ((event.endIndex >= this.assetGroupListMap.length - 2)) && (this.assetGroupListMap.length >=7)) {

            this.startAssetGroupMap = this.startAssetGroupMap + 10;

            this.getGlobalAssetGroupMap(this.filterAssetGroupMap);  

        }
    }
    
    getGlobalMenuCategoryMap(filterObj){

        this.commonService.getGlobalMenuCategory(this.filterMenuCategoryMap,this.startMenuCategoryMap,10).subscribe((result:any)=>{

         //   this.menuCategoryListMap = [];
            
            let mapMenuCategoryArrayTemp = [];
            let mapMenuCategoryArray = this.createPageCategoryForm.value.displayMenuCategory;

            if(result.data.data.length > 0){     

                for (let menuCategoryList of result.data.data) {
    
                    mapMenuCategoryArray.forEach(element => {

                        if(element.id == menuCategoryList.menuCategoryId) {

                            mapMenuCategoryArrayTemp.push(element);
                            this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp,'id') 
                            this.createPageCategoryForm.controls['displayMenuCategory'].setValue(mapMenuCategoryArrayTemp);  
                  
                        }
                    });

                    if(menuCategoryList.menuCategoryName!=''){
                    
                        this.menuCategoryListMap.push(
                        {
                            'id': menuCategoryList.menuCategoryId,
                            'itemName': menuCategoryList.menuCategoryName
                        });

                    this.menuCategoryListMap = this.removeDuplicates(this.menuCategoryListMap,'id')

                    } 
                }
            } 

        });
    }

    startMenuCategoryMap = 0;
    filterMenuCategoryMap:any= {};

    fetchMoreMapMenuCategory(event){
        
        if ( ((event.endIndex >= this.menuCategoryListMap.length - 2)) && (this.menuCategoryListMap.length >=7)) {

            this.startMenuCategoryMap = this.startMenuCategoryMap + 10;

            this.getGlobalMenuCategoryMap(this.filterMenuCategoryMap);  

        }
    }

    getProductTypeInfoMap(filterObj){

        this.commonService.getGlobalProductType(filterObj,0,10000).subscribe((result:any)=>{

            this.ProducTypeDropdownList = [];
            this.producTypeListMap= [];

            let mapProductTypeArrayTemp = [];
            let mapProductTypeArray = this.createPageCategoryForm.value.displayProductType;

            if(result.data.data.length > 0){       

                this.ProducTypeDropdownList = result.data.data;
                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                    mapProductTypeArray.forEach(element => {

                        if(element.id == ProducTypeLists.productTypeId) {

                            mapProductTypeArrayTemp.push(element);
                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp,'id') 
                            this.createPageCategoryForm.controls['displayProductType'].setValue(mapProductTypeArrayTemp);     
                
                        }
                    });

                    if(ProducTypeLists.productTypeName!=''){
                    this.producTypeListMap.push(
                        {
                            'id': ProducTypeLists.productTypeId,
                            'itemName': ProducTypeLists.productTypeName
                        });

                     this.producTypeListMap = this.removeDuplicates(this.producTypeListMap,'id')

                    }    
                }

                
            } 
        });
        
    }
    ///////////

    filterMapGenre:any = {};
    startMapGenre:any = 0;

    getGenreInfo(){
        // this.loader=true;
        this.commonService.changeloader(true);

        this.commonService.getGlobalGenre(this.filterMapGenre,this.startMapGenre,10).subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);
            
            // this.mapGenreList = [];
            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.mapGenreList = [];

                }

                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.genreName!=''){
                    this.mapGenreList.push(
                        {
                            'id': ProducTypeLists.genreId,
                            'itemName': ProducTypeLists.genreName
                        });

                        this.mapGenreList = this.removeDuplicates(this.mapGenreList,'id');
                    }    
                }
        
            } 
        });
    }

    fetchMoreMapGenre(event){
        
        if ( ((event.endIndex >= this.mapGenreList.length - 2)) && (this.mapGenreList.length >=7)) {

            this.startMapGenre = this.startMapGenre + 10;

            this.getGenreInfo();  

        }
    }

    getCertificationInfo(){
        
        this.commonService.getGlobalCertification({},0,10000).subscribe((result:any)=>{
            
            this.certificationList = [];
            if(result.data.data.length > 0){       
                for (let certificationLists of result.data.data) {

                    if(certificationLists.productTypeName!=''){
                    this.certificationList.push(
                        {
                            'id': certificationLists.certificationId,
                            'itemName': certificationLists.certificationName
                        });
                    } 
                }
            } 

        });
        
    }

    getGlobalCast(){

        console.log("filterCast>>>",this.filterCast);
    
        this.commonService.getGlobalCast(this.filterCast,this.startCast,10).subscribe((result:any)=>{
            
            // this.mapCastlist=[];
          
            if(result.data.data.length > 0){
                   
                for (let objLiveEvent of result.data.data) {
   
                    if(objLiveEvent.castFirstName!=''){
                    
                        this.mapCastlist.push(
                        {
                            'id': objLiveEvent.castId,
                            'itemName': objLiveEvent.castFirstName
                        });
                    } 

                    this.mapCastlist = this.removeDuplicates(this.mapCastlist,'id')
                }
            } 

        });
        
    }

    startCast = 0;
    filterCast = {};
    
    fetchMoreMapCast(event){
        
        if ( ((event.endIndex >= this.mapCastlist.length - 2)) && (this.mapCastlist.length >=7)) {

            this.startCast = this.startCast + 10;

            this.getGlobalCast();  

        }
    }

    getGlobalProfession(){
    
        this.commonService.getGlobalProfession({},0,10000).subscribe((result:any)=>{
            
            this.professionList = [];
            
            if(result.data.data.length > 0){
                   
                for (let professionList of result.data.data) {
   
                    if(professionList.professionId!=''){
                    
                        this.professionList.push(
                        {
                            'id': professionList.professionId,
                            'itemName': professionList.professionName
                        });
                    } 
                }
            } 

        });
        
    }

    filterLanguage:any ={}
    getLanguageInfo(){

        this.commonService.getGlobalLanguage(this.languageFilter,this.startLanguage,10).subscribe((result:any)=>{
            
            // this.mapLanguageList = [];
            
            if(result.data.data.length > 0){     

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.languageName!=''){
                    
                        this.mapLanguageList.push(
                        {
                            'id': menuCategoryList.languageId,
                            'itemName': menuCategoryList.languageName
                        });
                    } 

                    this.mapLanguageList = this.removeDuplicates(this.mapLanguageList,'id')

                }
            } 

        });
    }

    fetchMoreMapLanguage(event){

        if ( ((event.endIndex >= this.mapLanguageList.length - 2)) && (this.mapLanguageList.length >=7)) {

            this.startLanguage = this.startLanguage + 10;

            this.getLanguageInfo();  

        }
    }
    
    //Pravin code mulitple from
    createItem(): FormGroup {

        return this.formBuilder.group({        
            description:[''],
            isVisible:[''],
            dtypeId:['',Validators.required],
            horizontalFileName:[''],
            horizontalFilePath:[''],
            horizontalFileType:[''],
            label:['',Validators.required],
            verticalFileName:[''],
            verticalFilePath:[''],
            verticalFileType:['']  
        });
    }

    createAsset(): FormGroup {
        
        return this.formBuilder.group({   

            mapAsset:[[]],
            displayIndexing:['',Validators.pattern(displayIndexingPattern)]
            
        });
    }

    updateAsset(obj, i): FormGroup { 
        

        console.log("obj.assetId,",obj)

        let arrayObj = {
            'id': obj.assetId,
            'itemName':obj.assetName,
        }

        return this.formBuilder.group({

            mapAsset:[[arrayObj]],
            displayIndexing:obj.displayIndexing

        }); 

    }

    addAsset(): void {

        this.mapAssets = this.mapAssetDynamicForm.get('mapAssets') as FormArray;
        this.mapAssets.push(this.createAsset());

    }
    
    delAssetInput(index){

        const arrayControl = this.mapAssetDynamicForm.get('mapAssets') as FormArray;
        arrayControl.removeAt(index);

    }

    //pravin code start
    updateItem(objProductDeviceTypeConfigure, i): FormGroup { 

        if(objProductDeviceTypeConfigure.dtypeId === 4){
            this.all = true;
        }

        return this.formBuilder.group({
            'description':objProductDeviceTypeConfigure.description,
            'isVisible':objProductDeviceTypeConfigure.isVisible,
            'dtypeId':objProductDeviceTypeConfigure.dtypeId,
            'horizontalFileName':objProductDeviceTypeConfigure.horizontalFileName,
            'horizontalFilePath':objProductDeviceTypeConfigure.horizontalFilePath,
            'horizontalFileType':objProductDeviceTypeConfigure.horizontalFileType,
            'label':objProductDeviceTypeConfigure.label,
            'verticalFileName':objProductDeviceTypeConfigure.verticalFileName,
            'verticalFilePath':objProductDeviceTypeConfigure.verticalFilePath,
            'verticalFileType':objProductDeviceTypeConfigure.verticalFileType,  
        }); 
    }

    onDtypeId(){
        this.All=false;
        // this.Web=false;
        // this.Android=false;
        // this.iOS=false;
    }
    
    getPageCategoryInfo(id){

        // this.loader=true;
        this.commonService.changeloader(true);
            this.pageCategoryService.getPageCategory(id, this.langId)
            .subscribe((result:any)=>{


                this.commonService.changeloader(false);

                console.log("page Category info Service>>",result.data[0].results.productDeviceTypeConfigure);

                if(result.data[0].results.productDeviceTypeConfigure.length>0){
                    this.img=[];
                    this.multipleDeviceForm = this.formBuilder.group({
                        devices: this.formBuilder.array([])
                    });

                    //device type validation code start
                    if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                            this.deviceTypeFull = true;
                    }
                    //device type validation code end


                    this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                    for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){
        
                        this.img.push({
                            horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                            verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                        }) 
                        let objH = {
                            'index':"",
                            'deviceType':"",
                            'selectedFiles':"",
                            "horizontalFileName": "",
                            "horizontalFilePath": "",
                            "horizontalFileType": "",
                            'horizontalFile':false
                        }

                        let objV = {
                            'index':"",
                            'deviceType':"",
                            'selectedFiles':"",
                            "verticalFileName":"",
                            "verticalFilePath": "",
                            "verticalFileType":"",
                            'verticalFile':false,
                            
                        }

                        this.uploadImgFilePathHorizontal.push(objH);
                        this.uploadImgFilePathVertical.push(objV);

                        
                        this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i],i));
                        
                        
                        this.createPageCategoryForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
                        // this.createPageCategoryForm.controls['title'].setValue(result.data[0].title);
                        // this.createPageCategoryForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
                        // this.createPageCategoryForm.controls['orderBy'].setValue(result.data[0].orderBy);
                        this.createPageCategoryForm.controls['sameForAllProductType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].sameForAllProductType);      
                        this.createPageCategoryForm.controls['isAssetGroupVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isAssetGroupVisible);      
                        this.createPageCategoryForm.controls['isMenuCategoryVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isMenuCategoryVisible);
                        this.createPageCategoryForm.controls['isAssetVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isAssetVisible);
                        this.createPageCategoryForm.controls['isAssetCategoryVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isAssetCategoryVisible);
                        this.createPageCategoryForm.controls['isPrimaryNavigationVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isPrimaryNavigationVisible);
                        this.createPageCategoryForm.controls['displayType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].displayType);
                        this.createPageCategoryForm.controls['mapCmsUserPlaylist'].setValue(result.data[0].results.productDeviceTypeConfigure[i].mapCmsUserPlaylist);
                        this.createPageCategoryForm.controls['mapCmsUserWithFavorite'].setValue(result.data[0].results.productDeviceTypeConfigure[i].mapCmsUserWithFavorite);
                        this.createPageCategoryForm.controls['mapCmsUserWithWatchlist'].setValue(result.data[0].results.productDeviceTypeConfigure[i].mapCmsUserWithWatchlist);
                
                            
                
                    }

                    this.createPageCategoryForm.controls['isActive'].setValue(result.data[0].isActive); 
                }
                this.createPageCategoryForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
                this.createPageCategoryForm.controls['title'].setValue(result.data[0].title);
                this.createPageCategoryForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
                this.createPageCategoryForm.controls['orderBy'].setValue(result.data[0].orderBy);
                
                this.createPageCategoryForm.controls['publisherState'].setValue(result.data[0].publisherState);
                this.createPageCategoryForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
             

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createPageCategoryForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createPageCategoryForm.controls['contentState'].setValue(result.data[0].contentState);
            }
            
            this.selectedAssetCategory = [];
            if(result.data[0].results.assetCategory.length>0){
                    
                for(let i = 0 ;i < result.data[0].results.assetCategory.length;i++){
                    let obj={
                        'id':result.data[0].results.assetCategory[i].assetCategoryId,
                        'itemName':result.data[0].results.assetCategory[i].assetCategoryName,
                    }
                    this.selectedAssetCategory.push(obj);
                    this.mapAssetCategoryArrayTemp = this.selectedAssetCategory 
                }
                    
                this.createPageCategoryForm.controls['mapAssetCategory'].setValue(this.selectedAssetCategory);
            }

            this.selectedcastCrewAssetMapping = [];

            if(result.data[0].results.castCrewAssetMapping != undefined){

                result.data[0].results.castCrewAssetMapping.forEach(obj => {

                    let obj1={
                        'id':obj.castId,
                        'itemName':obj.castFirstName,
                    }

                    this.selectedcastCrewAssetMapping.push(obj1);

                    this.createPageCategoryForm.controls['castCrewAssetMapping'].setValue(this.selectedcastCrewAssetMapping);

                });
            }

                    
                // for(let i = 0 ;i < result.data[0].results.castCrewAssetMapping.length;i++){
                //     let obj={
                //         'id':result.data[0].results.castCrewAssetMapping[i].castCrewAssetMappingId,
                //         'itemName':result.data[0].results.castCrewAssetMapping[i].castCrewAssetMappingName,
                //     }
                //     this.selectedcastCrewAssetMapping.push(obj);  
                // }
                    
            
    

            if(result.data[0].results.geoZone.length>0){
                
                this.selectedItems = [];
                
                for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){

                    let obj={
                    'id':result.data[0].results.geoZone[i].geoZoneId,
                    'itemName':result.data[0].results.geoZone[i].geoZoneName,
                    }
                    this.selectedItems.push(obj);  
                    this.productTypeMappingFilter.geoZoneId.push(obj.id);
                                    this.createPageCategoryForm.controls['mapGeoZone'].setValue(this.selectedItems);

                    
                }
                
                
                this.getProductTypeInfo(this.productTypeMappingFilter);

            }
          
            if( result.data[0].results.cast.length>0){

                let mapCast = [];

                for(let i = 0 ;i < result.data[0].results.cast.length;i++){

                    let obj={
                    'id':result.data[0].results.cast[i].castId,
                    'itemName':result.data[0].results.cast[i].castFirstName,
                    }

                    mapCast.push(obj); 
                    this.createPageCategoryForm.controls['mapCast'].setValue(mapCast);
 
                }

        }
                

            if(result.data[0].results.asset.length>0){
    
                this.mapAssetDynamicForm = this.formBuilder.group({

                    mapAssets: this.formBuilder.array([])

                });

                this.mapAssets = this.mapAssetDynamicForm.get('mapAssets') as FormArray;

                for(let i=0;i< result.data[0].results.asset.length;i++){

                    this.mapAssets.push(this.updateAsset(result.data[0].results.asset[i],i));
                }


                // this.createPageCategoryForm.controls['mapAssets'].setValue(result.data[0].isActive); 
            }

            let  arrayObj = [];
            result.data[0].results.displayAssetCategory.forEach((list=>{

                let obj={
                    'id':list.assetCategoryId,
                    'itemName':list.assetCategoryName,
                    }
                    
                    arrayObj.push(obj);
                    this.mapAssetCategoryArrayTemp = arrayObj;
                   
                    this.createPageCategoryForm.controls['displayAssetCategory'].setValue(arrayObj);
            }))
           
            let arrayObj1 = [];
            result.data[0].results.displayAsset.forEach((list=>{

                let obj={
                    'id':list.assetId,
                    'itemName':list.assetName,
                    }
                    
                    arrayObj1.push(obj);

            }))
            this.createPageCategoryForm.controls['displayAsset'].setValue(arrayObj1);
            
            let arrayObj2 = [];
            result.data[0].results.language.forEach((list=>{

                let obj={
                    'id':list.languageId,
                    'itemName':list.languageName,
                    }
                    
                    arrayObj2.push(obj);

            }))
            this.createPageCategoryForm.controls['mapLanguage'].setValue(arrayObj2);

            console.log("createPageCategoryForm12345",this.createPageCategoryForm.value);


            let arrayObj3 = [];
            result.data[0].results.genre.forEach((list=>{

                let obj={
                    'id':list.genreId,
                    'itemName':list.genreName,
                    }
                    
                    arrayObj3.push(obj);

            }))
            this.createPageCategoryForm.controls['mapGenre'].setValue(arrayObj3);

            arrayObj = [];
            result.data[0].results.classification.forEach((list=>{
                let obj={
                    'id':list.classificationId,
                    'itemName':list.classificationName,
                    }
                    arrayObj.push(obj);
            }))
            this.createPageCategoryForm.controls['mapClassification'].setValue(arrayObj);

        
            arrayObj = [];
            if(result.data[0].results.displayProductType.length>0){

                result.data[0].results.displayProductType.forEach((list=>{

                    let obj={
                        'id':list.productTypeId,
                        'itemName':list.productTypeName,
                        }
                        arrayObj.push(obj);
                        this.mapProductTypeArrayTemp = arrayObj;
                        this.primaryNavigationMappingFilter.productTypeId.push(obj.id);
                }))
                this.createPageCategoryForm.controls['displayProductType'].setValue(arrayObj);
                this.getNavigationInfo(this.primaryNavigationMappingFilter);

            }

            arrayObj = [];
            if( result.data[0].results.displayPrimaryNavigation.length > 0){
                result.data[0].results.displayPrimaryNavigation.forEach((list=>{

                    let obj={
                        'id':list.primaryNavigationId,
                        'itemName':list.primaryNavigationName,
                        }
                        
                        arrayObj.push(obj);
                        this.menuCategoryMappingFilter.primaryNavigationId.push(obj.id);

                }))
                this.mapPrimaryNavigationArrayTemp = arrayObj;
                this.createPageCategoryForm.controls['displayPrimaryNavigation'].setValue(arrayObj);
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
            }

            arrayObj=[];
            if(result.data[0].results.displayMenuCategory.length>0){

                let acys = result.data[0].results.displayMenuCategory.length ;
                result.data[0].results.displayMenuCategory.forEach((list=>{
                    acys -- ;
                    let obj={
                        'id':list.menuCategoryId,
                        'itemName':list.menuCategoryName,
                        }
                        
                        arrayObj.push(obj);
                        this.assetGroupMappingFilter.menuCategoryId.push(obj.id);
                        this.mapMenuCategoryArrayTemp = arrayObj;
                        this.createPageCategoryForm.controls['displayMenuCategory'].setValue(arrayObj);

                }))

                if(acys == 0){
                    this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                }
                
            }

            arrayObj = [];
            if(result.data[0].results.displayAssetGroup.length > 0){

                let acys = result.data[0].results.displayAssetGroup.length ;

                result.data[0].results.displayAssetGroup.forEach((list=>{
                    acys -- ;

                    let obj={
                        'id':list.assetGroupId,
                        'itemName':list.assetGroupName,
                        }
                        
                        arrayObj.push(obj);

                        this.mapAssetGroupArrayTemp = arrayObj;
                        this.assetCategoryMappingFilter.assetGroupId.push(obj.id);
                        this.createPageCategoryForm.controls['displayAssetGroup'].setValue(arrayObj);
                        
                }))

                    if(acys == 0){
                    this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
                    }
            }
            
            
            this.selectedAssetGroupList = [];
            if(result.data[0].results.assetGroup.length>0){
                    
                for(let i = 0 ;i < result.data[0].results.assetGroup.length;i++){
                    let obj={
                        'id':result.data[0].results.assetGroup[i].assetGroupId,
                        'itemName':result.data[0].results.assetGroup[i].assetGroupName,
                    }
                    this.selectedAssetGroupList.push(obj);  

                }
                    
                this.createPageCategoryForm.controls['mapAssetGroup'].setValue(this.selectedAssetGroupList);
            }

            this.selectedmenuCategoryList = [];
            
            if(result.data[0].results.menuCategory.length>0){

                for(let i = 0 ;i < result.data[0].results.menuCategory.length;i++){
                    let obj={
                        'id':result.data[0].results.menuCategory[i].menuCategoryId,
                        'itemName':result.data[0].results.menuCategory[i].menuCategoryName,
                    }
                    this.selectedmenuCategoryList.push(obj);  
                }

                this.createPageCategoryForm.controls['mapMenuCategory'].setValue(this.selectedmenuCategoryList);
            }
            
            this.selectedprimaryNavigationList = [];
            
            if( result.data[0].results.primaryNavigation.length>0){
    
                if(result.data[0].results.primaryNavigation.length>0){
    
                    for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                        let obj={
                        'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                        'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                        }
                        this.selectedprimaryNavigationList.push(obj);  
    
                    }
                }
                
                this.createPageCategoryForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
    
            }

            this.selectedprofessionTypeList = [];
            
    
                if(result.data[0].results.professionType.length>0){
    
                    for(let i = 0 ;i < result.data[0].results.professionType.length;i++){
                        
                        let obj={
                        'id':result.data[0].results.professionType[i].professionId,
                        'itemName':result.data[0].results.professionType[i].professionName,
                        }

                        this.selectedprofessionTypeList.push(obj);  
    
                    }

                    this.createPageCategoryForm.controls['professionType'].setValue(this.selectedprofessionTypeList);

                }
             

        })
    }

    getGlobalclassification(){

        this.commonService.getglobalclassification(this.classificationFilter,this.startClassification,10).subscribe((result:any)=>{
                
            // this.mapClassificationList = [];
            
            if(result.data.data.length > 0){   

                for (let menuCategoryList of result.data.data) {
    
                    if(menuCategoryList.classificationId!=''){
                    
                        this.mapClassificationList.push(
                        {
                            'id': menuCategoryList.classificationId,
                            'itemName': menuCategoryList.classificationName
                        });


                    }
                    
                    this.mapClassificationList = this.removeDuplicates(this.mapClassificationList,'id')
                }
            } 
    
        });
    
    }

    fetchMoreMapClassification(event){
        
        if ( ((event.endIndex >= this.mapClassificationList.length - 2)) && (this.mapClassificationList.length >=7)) {

            this.startClassification = this.startClassification + 10;

            this.getGlobalclassification();  

        }
    }


    // functiion related to device ttype
    addItem(): void {


        if(!this.multipleDeviceForm.valid){  

            this.createPageCategorySubmitted = true;
            return
  
          }
          
        if(this.multipleDeviceForm.value.devices.length >= 4){

            this.createPageCategorySubmitted = true;
            return;

        }
        
            this.img.push({
                horizontalURL:'',
                verticalURL:''
            })  

            this.devices = this.multipleDeviceForm.get('devices') as FormArray;
            let devices = this.devices
            var all = 0;
            var Web = 0;
            var android = 0;
            var iOS = 0;
            var roku = 0;
            this.all = false;
            this.roku = false;
            this.web = false;
            this.android = false;
            this.iOS = false;
        
            for(let i = 0;i< devices.value.length;i++){

                if(devices.value[i].dtypeId=='1'){
                    Web++;
                    this.web=true;
                }
                
                if(devices.value[i].dtypeId=='2'){
                    android++;
                    this.android=true;
                }
                
                if(devices.value[i].dtypeId=='3'){
                    iOS++;
                    this.iOS=true;
                }

                if(devices.value[i].dtypeId=='5'){
                    roku++;
                    this.roku=true;
                }
                
                if(devices.value[i].dtypeId=='4'){
                    all++;
                    this.all=true;
                }else{
                    
                }
                
            }
            
            this.deviceTypeDublicate = false;
            this.deviceTypeFull = false;
            
            if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

            this.deviceTypeFull = true ;

            }else if(all == 1 || this.all == true ){
                
                if(all == 1 && devices.value.length>1){

                }

            } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

                this.deviceTypeDublicate = true;

            }else {

                this.devices.push(this.createItem());
                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }
    
                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }
    
                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
            }

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;


        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;


        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){

        console.log('event ',event);
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    isDeleteAssetMap(event){

        console.log('event ',event);
        if(event=='Cancel'){
            this.isDeleteAssetMapAction=false;
        }
        
        if(event=='Yes'){
            console.log("this.deleteAssetMapIndex===========",this.deleteAssetMapIndex)
            this.delAssetInput(this.deleteAssetMapIndex);
            this.isDeleteAssetMapAction=false;
        }

    }
    
    isDeleteAssetMapYes(i){
        this.deleteAssetMapIndex = i;
    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event);
        
        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteHorizontalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";
            console.log("this.deleteDeviceTypeIndex",this.deleteDeviceTypeIndex);
            

             this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
             this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    
    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    //is Content Ready To Review Received  

    //is Content Ready To Review Received  

    isContentReadyToReviewReceived(event){

            console.log("we are in",event);
            this.contentReadyToReview = false;
            debugger;

        //New Code start
            if(event == 'No'){

                this.contentReadyToReview = false;
            
            }
            //New Code end

            if(event == 'Yes'){

                this.createPageCategoryForm.controls['contentState'].setValue(['Completed']);
                // this.postCallPageCategory();
                this.savecreatePageCategory();
                this.onPageCategoryTabClick();
            
            }
            if(event == 'Cancel'){

                this.createPageCategoryForm.controls['contentState'].setValue(['In-Progress']);
                // this.postCallPageCategory();
                this.savecreatePageCategory();
                this.onPageCategoryTabClick();
                
            }
    
    }

    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event);

        if(event=='Cancel'){

            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            console.log("this.deleteDeviceTypeIndex",this.deleteDeviceTypeIndex);

             this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
             this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
           
        }

    }

    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }
    
    //New Code for right side for from
    onContentReadyToReview(){

        // if(this.multipleDeviceForm.value.devices.length >1){
        //     // this.loader = false;
        //     this.commonService.changeloader(false);
        //     this.createPageCategorySubmitted = true;
        //     return;
        // }

        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createPageCategorySubmitted = true;
            return;
        }

        if(!this.createPageCategoryForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createPageCategorySubmitted = true;
            return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    resetForm(){

        this.mapAssetCategoryArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp=[];
        this.mapProductTypeArrayTemp=[];
        this.mapAssetGroupArrayTemp = [];
        this.mapMenuCategoryArrayTemp= [];

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);

        }

        console.log("this.formTabs >>>>",this.formTabs);
        this.createPageCategorySubmitted = false;
        this.createPageCategoryForm = this.formBuilder.group({
        
            isActive : ["1"],
            langId: [this.langId],
            companyId :[""],
            title : ["",Validators.required],
            sameForAllZone : [''],
            sameForAllProductType : [''],
            displayIndexing :['',Validators.pattern(displayIndexingPattern)],
            displayType :[''],
            orderBy : [""],
            isPrimaryNavigationVisible : [""],
            isMenuCategoryVisible : [""],
            isAssetGroupVisible : [""],
            isAssetCategoryVisible : [""],
            isAssetVisible : [""],
            exclusionText :[""],
            description : [""],
            isVisible : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            sorting : [""],
            mapGeoZone:[[],Validators.required],
            mapCast:[[]],
            productDeviceTypeConfigure:[[]],
            // mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapAssetCategory:[[]],
            mapAsset:[[]],
            mapLanguage:[[]],
            displayProductType:[[],Validators.required],
            displayPrimaryNavigation:[[]],
            displayMenuCategory:[[]],
            displayAssetGroup:[[]],
            displayAssetCategory:[[]],
            displayAsset:[[]],
            mapClassification:[[]],
            mapGenre:[[]],
            mapCmsUserWithWatchlist:[''],
		    mapCmsUserPlaylist: [''],
			mapCmsUserWithFavorite:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved']

        });
        
        //reset multiselect list
        this.selectedItems=[];

        //reset device type image
        this.img = [];
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        }) 

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];
    

        this.update = false;

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });

        this.updateSend.emit();
        this.showCreateNewButton('show');
 
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

    eventValue ='gfrf';
    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == ''){

            return;

        }

        this.eventValue=event.target.value;

        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;

        if(info == 'classification'){

            if(event.target.value!=''){

                this.classificationFilter =  obj = { 'classificationName': event.target.value };
                this.mapClassificationList=[];

            }else {

                this.mapClassificationList=[];
                this.classificationFilter =   obj = { };

            }

            this.startClassification = 0;
            this.classificationScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'review'){

            // if(event.target.value!=''){

            // this.reviewFilter = obj = { 'reviewFirstName': event.target.value };
            // // this.mapReviewList = [];
    
            // }else {

            //     // this.mapReviewList = [];
            //     this.reviewFilter = obj = { } 
            // }

            // this.startReview = 0;
            // this.reviewScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }
        
        if(info == 'mapGenre'){
            

            if(event.target.value!=''){

                this.filterMapGenre = obj = { 'genreName': event.target.value };
                // this.mapGenreList= [];

    
            }else {

                this.filterMapGenre = obj = { };
                // this.mapGenreList= [];
            }

            this.startMapGenre = 0;
            // this.reviewScrollCount = 0;
            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'sponsore'){

            // if(event.target.value!=''){

            //     this.sponsoredFiter = obj = { 'sponsorName': event.target.value };
            //     this.mapSponsoreList = [];
    
            // }else {

            //     this.sponsoredFiter =  obj = { } 
            // }

            // this.startSponsored = 0;
            // this.SponsoredScrollCount = 0;

            // this.mapSponsoreList = [];

            this.searchDecouncer$.next(event.target.value);   

        }

        if(info == 'language'){

            if(event.target.value!=''){

                this.languageFilter =  obj = { 'languageName': event.target.value };
                // this.mapLibraryList=[];

            }else {
                // this.mapLibraryList=[];
                this.languageFilter =  obj = { } 
            }

            this.startLanguage = 0;
            // this.SponsoredScrollCount = 0;
            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'library'){

            if(event.target.value!=''){

                 this.libraryFilter =  obj = { 'libraryName': event.target.value };
                 this.mapLibraryList = [];

            }else {

                this.libraryFilter =  obj = { };
                this.mapLibraryList = [];
            }

            this.startClassification = 0;
            // this.SponsoredScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'certification'){

            if(event.target.value!=''){

                obj = { 'certificationName': event.target.value };
                // this.mapCertificatList=[];
                this.certificateFilter = obj;
                this.startCertificate = 0;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                this.certificateFilter = obj = { };
            }

            this.startCertificate = 0;
            this.certificateFilter = obj ;

            // this.mapCertificatList=[];
            this.searchDecouncer$.next(event.target.value);
        }  

        if(info == 'cast'){
            
            if(event.target.value!=''){

                obj = { 'castFirstName': event.target.value };
                this.startCast = 0;
                this.mapCastlist = [];      
                this.filterCast = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.filterCast = obj ;
                this.mapCastlist = [];      
                this.startCast = 0;

                this.searchDecouncer$.next(event.target.value);

            }
        }

        if(info == 'character'){
            

                if(event.target.value!=''){
    
                    obj = { 'characterFirstName': event.target.value };
                    this.startCharacter = 0;
                    this.mapCharacterList = [];      
                    this.characterFilter = {  } ;
                    this.characterFilter = obj;
                    // this.characterFilter = {};


    
                    this.searchDecouncer$.next(event.target.value);
    
                }else {
    
                    obj = { };
                    this.characterFilter = obj ;
                    this.mapCharacterList = [];      
                    this.startCharacter = 0;
    
                    this.searchDecouncer$.next(event.target.value);
    
                }
  
        }  
        
        if(info == 'geoZone'){
            

            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                // this.geoZoneList = [];      
                this.geoZoneObj = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.geoZoneObj = obj ;
                // this.geoZoneList = [];      
                this.start = 0;

                this.searchDecouncer$.next(event.target.value);

            }
  
        }  

        if(info == 'productType'){

            console.log("producttype",event);

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                this.startProduct = 0;
                this.productScrollCount = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                var geoZoneId =  this.productTypeMappingFilter.geoZoneId;
                this.productTypeMappingFilter={};
                this.productTypeMappingFilter.geoZoneId = geoZoneId;
                this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);

                

            }

            
        }

        if(info == 'navigation'){

            if(event.target.value !=''){

                this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

              var  productTypeId =  this.primaryNavigationMappingFilter.productTypeId;
              this.primaryNavigationMappingFilter ={};
              this.primaryNavigationMappingFilter.productTypeId = productTypeId;
              this.primaryNavigationList = [];
              this.startPrimary = 0;
                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
              this.searchDecouncer$.next(event.target.value);

            }

        } 
 
        if(info == 'category'){

            if(event.target.value!=''){

                  this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                   this.menuCategoryList = [];
                   this.startCategory = 0;
                   //this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                   this.searchDecouncer$.next(event.target.value);

            }else {

                var primaryNavigationId = this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter = {};
                this.menuCategoryMappingFilter.primaryNavigationId = primaryNavigationId;
                this.menuCategoryList = [];
                this.startCategory = 0;
                // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetGroup'){

            if(event.target.value!=''){

                   this.assetGroupMappingFilter.assetGroupName = event.target.value;
                   this.assetGroupList = [];
                   this.startGroup = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                var menuCategoryId =  this.assetGroupMappingFilter.menuCategoryId;

                this.assetGroupMappingFilter={};
                this.assetGroupMappingFilter.menuCategoryId = menuCategoryId;
                this.assetGroupList = [];
                this.startGroup = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetCategory'){

            if(event.target.value!=''){

                   this.assetCategoryMappingFilter.assetCategoryName = event.target.value;
                   this.assetCategoryList = [];
                   this.startCategory = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                var assetGroupId =  this.assetCategoryMappingFilter.assetGroupId;
                this.assetCategoryMappingFilter = {};
                this.assetCategoryMappingFilter.assetGroupId =assetGroupId;
                this.assetCategoryList = [];
                this.startCategory = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }
                
        if(info == 'mapAsset'){

            if(event.target.value!=''){

                   this.filterMapAsset.keyword = event.target.value;
                   this.startMapAsset = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterMapAsset = {};
                // this.trailerVideoList = [];
                this.startMapAsset = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }  

        if(info == 'mapWithPrimary'){

            if(event.target.value!=''){

                   this.filterMapAsset.assetName = event.target.value;
                   this.startMapAsset = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterMapAsset = {};
                this.trailerVideoList = [];
                this.startMapAsset = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }  

        
        if(info == 'mapWithMenuCategory'){

            if(event.target.value!=''){

                   this.filterMenuCategoryMap.menuCategoryName = event.target.value;
                   this.startMenuCategoryMap = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterMenuCategoryMap = {};
                
                this.menuCategoryListMap = [];
                this.startMenuCategoryMap = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }  

        
        if(info == 'mapWithAssetGroup'){

            if(event.target.value!=''){

                   this.filterAssetGroupMap.assetGroupName = event.target.value;
                   this.startAssetGroupMap = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterAssetGroupMap = {};
                
                this.assetGroupListMap = [];
                this.startAssetGroupMap = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        } 

        if(info == 'mapAssetCategory'){
           
            if(event.target.value!=''){

                this.filterAssetCategoryMap.assetGroupName = event.target.value;
                this.startAssetCategoryMap = 0;
                this.searchDecouncer$.next(event.target.value);

         }else {

            //  this.assetCategoryListMap = [];
             
             this.filterAssetCategoryMap = {};
             this.startAssetCategoryMap = 0;
             this.searchDecouncer$.next(event.target.value);

         } 
        }

        if(info == 'mapGenre'){

        }


        

            
    }

    startGroup:any=1;
    mapCharacterList=[];
    characterFilter={};
    startCharacter = 0;
    castFilter={};
    genreFilter = {};
    startGenre = 0;
    languageFilter ={};
    libraryFilter = {};
    mapLibraryList = [];
    certificateFilter = {};
    startCertificate = 0;
    startPrimary  =0;



    onOpen(info){

    this.activeSearchBox = info;

    if(info == 'mapAssetCategory')
     {

        this.assetCategoryListMap = [];
        this.startAssetCategoryMap = 0;
        this.filterAssetCategoryMap = { };
        this.getGlobalAssetCategoryMap(this.filterAssetCategoryMap); 
     }

     
    if(info == 'mapGenre')
    {

       this.mapGenreList = [];
       this.startMapGenre = 0;
       this.filterMapGenre = { };
       this.getGenreInfo(); 
    }

    if(info == 'mapWithMenuCategory'){
      
        this.menuCategoryListMap = [];
        this.startMenuCategoryMap = 0;
        this.filterMenuCategoryMap = { };
        this.getGlobalMenuCategoryMap(this.filterMenuCategoryMap);  

    }

    
    if(this.activeSearchBox == 'mapAssetGroup'){

        this.assetGroupList=[];
        this.startAssetGroupMap = 0;
        this.filterAssetGroupMap = {};
        this.getGlobalAssetGroupMap(this.filterAssetGroupMap);

    }

    if(this.activeSearchBox == 'assetGroup'){

        var menuCategoryId =  this.assetGroupMappingFilter.menuCategoryId;
        this.assetGroupMappingFilter={};
        this.assetGroupMappingFilter.menuCategoryId = menuCategoryId;
        this.assetGroupList = [];
        this.startGroup = 0;
        this.assetGroupMappingFilter = {};
        this.getGlobalAssetGroup(this.assetGroupMappingFilter);

    }

            if(this.activeSearchBox == 'character'){

                this.mapCharacterList=[];
                this.characterFilter={};
                this.startCharacter = 0;
                // this.getGlobalCharacter();

            }

            if(this.activeSearchBox == 'cast'){

                this.mapCastlist=[];
                this.filterCast={};
                this.startCast = 0;
                this.getGlobalCast();

            }

            if(this.activeSearchBox == 'classification'){

                    this.classificationFilter={};
                    this.mapClassificationList=[];
                    this.startClassification = 0;
                    this.getGlobalclassification();

            }

            if(this.activeSearchBox == 'review'){

                    // this.reviewFilter={};
                    // this.mapReviewList = [];
                    // this.startReview = 0;
                    // this.getGlobalReviewInfo();

            }

            if(this.activeSearchBox =='genre'){

                    this.genreFilter = {};
                    this.mapGenreList = [];
                    this.startGenre = 0;
                    this.getGenreInfo();    

            }

            if(this.activeSearchBox == 'sponsore'){

                // this.sponsoredFiter = {};
                // this.mapSponsoreList = [];
                // this.startSponsored = 0;
                //     this.getSponsoreInfo();   

            }

            if(this.activeSearchBox == 'language'){

                
                this.languageFilter ={};
                this.mapLanguageList = [];
                this.startLanguage = 0;
                this.getLanguageInfo();

            }

            if(this.activeSearchBox == 'library'){
    
                this.libraryFilter = {};
                this.startLibrary = 0;
                this.mapLibraryList = [];
                    // this.getLibrary();
            }

            if(this.activeSearchBox == 'certification'){

                this.certificationList = [];
                this.certificateFilter = {};
                this.startCertificate = 0;

                this.getCertificationInfo();   
            }  

            if(this.activeSearchBox == 'geoZone'){

                this.geoZoneObj = {};
                this.start =0;
                this.getGeoZoneInfo();       

            }  

            if(this.activeSearchBox == 'mapWithPrimary'){
                this.filterMapAsset = {};
                this.startPrimaryMap = 0;
                this.getNavigationInfoMap(this.filterMapAsset);
                // this.getP(); 
            }

            if(this.activeSearchBox == 'productType'){
                var geoZoneId =  this.productTypeMappingFilter.geoZoneId;
                this.productTypeMappingFilter={};
                this.productTypeMappingFilter.geoZoneId = geoZoneId;
                this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }

            if(this.activeSearchBox == 'navigation'){

              var  productTypeId =  this.primaryNavigationMappingFilter.productTypeId;
              this.primaryNavigationMappingFilter ={};
              this.primaryNavigationMappingFilter.productTypeId = productTypeId;
              this.primaryNavigationList = [];
              this.startPrimary = 0;
              this.getNavigationInfo(this.primaryNavigationMappingFilter);     

            } 

            if(this.activeSearchBox == 'category'){

                var primaryNavigationId = this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter = {};
                this.menuCategoryMappingFilter.primaryNavigationId = primaryNavigationId;
                this.menuCategoryList = [];
                this.startCategory = 0;
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

            }   

            
            if(this.activeSearchBox == 'mapAsset'){

                this.filterMapAsset.mapAsset= undefined;
                this.filterMapAsset ={};
                this.assetList = [];
                this.startMapAsset = 0;
                this.getAllAsset();

            }   

            if(this.activeSearchBox == 'assetCategory'){

              var  assetGroupId= this.assetCategoryMappingFilter.assetGroupId;
              this.assetCategoryMappingFilter={};
              this.assetCategoryMappingFilter.assetGroupId = assetGroupId;
                // this.filterMapAsset ={};
                this.assetCategoryList = [];
                this.startAssetCategory = 0;
                this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
            }

    }

    private setupSearchDebouncer(): void {

        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(2000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            if(this.activeSearchBox == 'mapAssetGroup'){
                this.getGlobalAssetGroupMap(this.filterAssetGroupMap);
            }


            if(this.activeSearchBox == 'assetGroup'){

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);
            }
            
            if(this.activeSearchBox == 'mapWithMenuCategory'){

                this.getGlobalMenuCategoryMap(this.filterMenuCategoryMap);
            }

            if(this.activeSearchBox == 'mapAsset'){

                // this.getGlobaltrailervideo();
                this.getAllAsset();

            }

            if(this.activeSearchBox == 'character'){

                // this.getGlobalCharacter();

            }

            if(this.activeSearchBox == 'cast'){

                this.getGlobalCast();

            }

            if(this.activeSearchBox == 'classification'){
            
                    this.getGlobalclassification();
    
            }
    
            if(this.activeSearchBox == 'review'){
    
                    // this.getGlobalReviewInfo();
   
            }
            
            if(this.activeSearchBox =='genre'){
    
                    this.getGenreInfo();    
    
            }
    
            if(this.activeSearchBox == 'sponsore'){
    
                    // this.getSponsoreInfo();   
    
            }
    
            if(this.activeSearchBox == 'language'){
    
                    this.getLanguageInfo();
    
            }
    
            if(this.activeSearchBox == 'library'){
    
                    // this.getLibrary();
            }
    
            if(this.activeSearchBox == 'certification'){
    
    
                 this.getCertificationInfo();   
            }  
            
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo();       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }
    
            if(this.activeSearchBox == 'navigation'){
    
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     
    
            } 
     
            if(this.activeSearchBox == 'category'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }   
            
            if(this.activeSearchBox == 'assetCategory'){
    
                this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
    
            }   

            

        });
    }

  private searchDecouncer$: Subject<string> = new Subject();

    startProduct:number = 0;
    startCategory:number = 0;
    startLibrary:number = 0;
    startLanguage:number = 0;
    activeSearchBox:any=[];
    isSearch:any='';
    geoZoneObj:any={};
    productScrollCount=0;
    classificationFilter:any={};
    startClassification = 0;
    classificationScrollCount = 0;
    
}
