import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private http: HttpClient) { }

    getAllBlogs(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');
        filterObj.languageId = undefined;


        let urlGetAllNews = environment.apiURL + environment.adminApiUrl + environment.getBlogsUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllNews)
        
    }
      
    updateBlogs(postObj:any) : Observable<any> {

        postObj.langId = undefined;

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateBlogsUrl, postObj,httpOptions);
    }
          
    insertBlogs(postObj:any) : Observable<any> {

        postObj.langId = undefined;

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertBlogsUrl, postObj,httpOptions);
    } 

    getBlogs( id: any) : Observable<any> {
      
        if(id){

        }
        else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getBlogsUrl+"/" + id);
    }
    deleteBlogs(postObj:any) : Observable<any> {
        postObj.langId = undefined;


        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteBlogsUrl, postObj,httpOptions);
    }




    /*========================= 
    Blog Category
    ========================== */


    getAllBlogsCategory(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');
        filterObj.languageId = undefined;

        let urlGetAllNews = environment.apiURL + environment.adminApiUrl + environment.getBlogsCategoryUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllNews)
        
    }
      
    updateBlogsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateBlogsCategoryUrl, postObj,httpOptions);
    }
          
    insertBlogsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertBlogsCategoryUrl, postObj,httpOptions);
    } 

    getBlogsCategory( id: any) : Observable<any> {
      
        if(id){

        }
        else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getBlogsCategoryUrl+"/" + id);
    }

    deleteBlogsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteBlogCategorysUrl, postObj,httpOptions);
    }

}
