import { Injectable } from '@angular/core';
import { Subject , BehaviorSubject}    from 'rxjs';

@Injectable()
export class Loginshared {

  // Observable string sources
  private sendUserLoggedInSource = new Subject<any>();
  private receiveUserLoggedInSource = new Subject<any>();

  // Observable string streams
  sendUserLoggedIn$ = this.sendUserLoggedInSource.asObservable();
  receiveUserLoggedIn$ = this.receiveUserLoggedInSource.asObservable();
 

 
  private loginStatus = new BehaviorSubject(false);
  currentLoginStatus = this.loginStatus.asObservable();

  constructor() { }

  changeLoginStatus(loginStatus: boolean) {
    this.loginStatus.next(loginStatus)
  }


  // Service message commands
  sendUserLoggedIn(message: any) {
    this.sendUserLoggedInSource.next(message);
  }


  receiveUserLoggedIn(message: any) {
    this.receiveUserLoggedInSource.next(message);
  }
}