import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  sendOtp( mobileNo: string, email: string) : Observable<any> {

    if(mobileNo != undefined && mobileNo.length > 0) {
        let sendOtpURL =  environment.apiURL + "send/otp" + "?mobile=" + mobileNo;
      return this.http.get(sendOtpURL);
    }

    else if(email != undefined && email.length > 0) {
        let sendOtpURL =  environment.apiURL + "send/otp" + "?email=" + email;
       return this.http.get(sendOtpURL);
    }
  }
  
    confirmPassword(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.loginUrl, postObj,httpOptions);
    }
                    
  registerUser(email: string, password: string, mobile: any) : Observable<any> {
        let registerUserURL =  environment.apiURL + "user/register";
        return this.http.post(registerUserURL,{
            "email_id":email,
            "password":password,
            "mobile":mobile,
            "device_type":"WEB"
        },httpOptions);
    }     
    
    
    getRoleConfiguration(id){

        let urlRoleConfiguration = environment.apiURL + environment.adminApiUrl + environment.roleConfiguration +'/'+ id;
        return this.http.get<any>(urlRoleConfiguration)
      
    }
    

    verifyOtp(sessionId: string, otp: string, mobileNo: string, email: string, country:string, state:string, name:string) : Observable<any> {
      
      let concatString = '';
      if(country!=undefined && country.length > 0){
          concatString += "&country=" + country;
      }

      if( state!=undefined && state.length > 0 ){
          concatString += "&state=" + state;
      }
      
      if( name!=undefined && name.length > 0 ){
          concatString += "&name=" + name;
      }
          
      if(mobileNo != undefined && mobileNo.length > 0) {
          
        let sendOtpURL =  environment.apiURL + "verify/otp" + "?mobile=" + mobileNo + "&otp=" + otp + "&session_id=" + sessionId + concatString;
        return this.http.get(sendOtpURL);
      }
      else {
          let sendOtpURL = environment.apiURL + "verify/otp" + "?email=" + email + "&otp=" + otp + concatString;
          return this.http.get(sendOtpURL);
      }
    }

    socialLogin(mobile: string, email: string, page_origin:string, country:string, state:string, name:string)  : Observable<any>{
        
      let concatString = '';
      if(country!=undefined && country.length > 0){
          concatString += "&country=" + country;
      }

      if( state!=undefined && state.length > 0 ){
          concatString += "&state=" + state;
      }
      
      if( name!=undefined && name.length > 0 ){
          concatString += "&name=" + name;
      }  
        
      if(mobile != undefined && mobile.length > 0) {
        let socialLoginURL = environment.apiURL + "socialauth" + "?mobile=" + mobile + "&device_type=web&page_origin="+page_origin + concatString;
        return this.http.get(socialLoginURL);
      }
      else {
        let socialLoginURL = environment.apiURL + "socialauth" + "?email=" + email + "&device_type=web&page_origin="+page_origin + concatString;
        return this.http.get(socialLoginURL);
      }
    }
            
    forgotPassword( email: string) : Observable<any> {

        if(email != undefined && email.length > 0) {

            let forgotPasswordURL =  environment.apiURL + environment.adminApiUrl + environment.forgotPasswordUrl;
            return this.http.post(forgotPasswordURL,{
                "emailId":email
            },httpOptions);

        }
    }
  
    
    updatePassword(email: string, password: string, otp: string ) : Observable<any> {
        
        let updatePasswordURL = environment.apiURL + environment.adminApiUrl + environment.updatePasswordUrl;
        
        return this.http.post(updatePasswordURL,{
            "emailId":email,
            "password":password,
            "otp":otp
        },httpOptions);
        
    }
  
    //This function is used for wheather user is present or not in the system
  
    checkUser(email: string, mobile: any) : Observable<any> {
      let checkUserURL = environment.apiURL + "check/user";
      return this.http.post(checkUserURL,{
          "email_id":email,
          "mobile":mobile
      },httpOptions);
    }
    
}
