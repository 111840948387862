import { Injectable,Injector , Output , EventEmitter} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import {CommonService} from './../services/common_Service/common.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {  ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor { 


    constructor(public commonService:CommonService,public toastrService:ToastrService){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
      return next.handle(request)
         .pipe(
           catchError( (error: any) => { 
            this.commonService.onNotify(true);
              let errMsg = '';
              console.log("error",error);
              // Client Side Error   
              if (error.error instanceof ErrorEvent) {
                // toaster.changeErrorGlobal('Something bad happened; please try again later.');       
                errMsg = `Error: ${error.error.message}`;
              } 
               else {  // Server Side Error
                errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
              }
              if(error.error.message){
                
                this.toastrService.error(error.error.message);
              }else{
                this.toastrService.error('Something bad happened; please try again later.'); 
              } 
              return throwError('Something bad happened; please try again later.');
            })
         )
    }
}


//error related interceptor
