import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './component/shared/header/header.component';
import { MenuComponent } from './component/shared/menu/menu.component';
import { SearchComponent } from './component/shared/search/search.component';
import {AuthGuard} from './services/auth-gaurd/auth.guard';
import {ClientComponent} from './component/clientManagement/clientAdd/client/client.component';

const routes: Routes = [

    {
        path:'search',
        component: SearchComponent
    },

    {
        path: 'client',
        loadChildren: './component/clientManagement/client/client.module#ClientModule',
        // canActivate: [AuthGuard]

    },


    {
        path: 'client/:id',
        component:ClientComponent,
        // loadChildren: './component/clientManagement/clientAdd/client.module#ClientModule',
    },
    {
        path: 'clientAdd',
        component:ClientComponent,
        // loadChildren: './component/clientManagement/clientAdd/client.module#ClientModule',
        // canActivate: [AuthGuard]

    },
    

    
    
    {
        path: '',
       loadChildren: './component/dashboard/dashboard.module#DashboardModule', 
       //loadChildren: './component/authentication/login/login.module#LoginModule',
       canActivate: [AuthGuard]
    
    },

    {
        path: 'player/configuration',
       loadChildren: './component/player/player.module#PlayerModule', 
       //loadChildren: './component/authentication/login/login.module#LoginModule',
       canActivate: [AuthGuard]
    
    },

    {
        path: 'login',
        loadChildren: './component/authentication/login/login.module#LoginModule',
    
    },

    {
        path: 'master/news/list',
        loadChildren: './component/master/news/list/list.module#ListModule',
    
    },

    
    {
        path: 'master/blogs/list',
        loadChildren: './component/master/blogs/list/list.module#ListModule',
    
    },

    {
        path: 'master/library/list',
        loadChildren: './component/master/library/list/list.module#ListModule',
    
    },

    {
        path: 'master/clientadserver/list',
        loadChildren: './component/master/clientadserver/list/list.module#ListModule',
    
    },
    {
        path: 'master/clientadserver/add/:id',
        loadChildren: './component/master/clientadserver/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/language/list',
        loadChildren: './component/master/language/list/list.module#ListModule',
    
    },

    

    
    {
        path: 'master/certification/list',
        loadChildren: './component/master/certification/list/list.module#ListModule',
    
    },

    {
        path: 'forgotpassword',
        loadChildren: './component/authentication/forgotpassword/forgotpassword.module#ForgotpasswordModule'
    },

  

    {
        path: 'dashboard',
        loadChildren: './component/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]

    },


    //Start Asset Managenment
    //asset Group
    {
        path: 'asset_management/asset_group/list',
        loadChildren: './component/assetManagement/asset_group/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },

    {
        path: 'asset_management/track/list',
        loadChildren: './component/assetManagement/tracks/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },

    
    {
        path: 'asset_management/asset_group/add',
        loadChildren: './component/assetManagement/asset_group/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    {
        path: 'asset_management/asset/add/:id/:id/:type',
        loadChildren: './component/assetManagement/asset/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    
    {
        path: 'asset_management/asset_group/add/:id',
        loadChildren: './component/assetManagement/asset_group/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_group/add/:id/:langId',
        loadChildren: './component/assetManagement/asset_group/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //asset Category
    {
        path: 'asset_management/asset_category/list',
        loadChildren: './component/assetManagement/asset_category/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_category/add',
        loadChildren: './component/assetManagement/asset_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_category/add/:id',
        loadChildren: './component/assetManagement/asset_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    
    {
        path: 'asset_management/mrss/list',
        loadChildren: './component/assetManagement/mrss/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },

        
    {
        path: 'asset_management/mrss_partner/list',
        loadChildren: './component/assetManagement/mrss_partner/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_category/add/:id/:langId',
        loadChildren: './component/assetManagement/asset_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    
    //asset Reconcillation
    {
        path: 'asset_management/asset_reconcillation/list',
        loadChildren: './component/assetManagement/asset_reconcilliation/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_reconcillation/add',
        loadChildren: './component/assetManagement/asset_reconcilliation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset_reconcillation/add/:id',
        loadChildren: './component/assetManagement/asset_reconcilliation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //asset
    {
        path: 'asset_management/asset/list',
        loadChildren: './component/assetManagement/asset/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset/add',
        loadChildren: './component/assetManagement/asset/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset/add/:id',
        loadChildren: './component/assetManagement/asset/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'asset_management/asset/add/:id/:langId',
        loadChildren: './component/assetManagement/asset/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //End Asset Managenment

    //start Master
    //geo zone
    {
        path: 'master/geo_zone/list',
        loadChildren: './component/master/geo_zone/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/geo_zone/add',
        loadChildren: './component/master/geo_zone/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/geo_zone/add/:id',
        loadChildren: './component/master/geo_zone/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/news/add/:id',
        loadChildren: './component/master/news/add/add.module#AddModule',
    
    },
    {
        path: 'master/news/add',
        loadChildren: './component/master/news/add/add.module#AddModule',
    
    },
    {
        path: 'master/blogs/add/:id',
        loadChildren: './component/master/blogs/add/add.module#AddModule',
    
    },
    {
        path: 'master/blogs/add',
        loadChildren: './component/master/blogs/add/add.module#AddModule',
    
    },
    {
        path: 'master/blogsCategorys/list',
        loadChildren: './component/master/blogcategory/list/list.module#ListModule',
    
    },
    {
        path: 'master/blogscategory/add',
        loadChildren: './component/master/blogcategory/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/blogscategory/add/:id',
        loadChildren: './component/master/blogcategory/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    {
        path: 'master/newsCategory/list',
        loadChildren: './component/master/newscategory/list/list.module#ListModule',
        canActivate: [AuthGuard]
    
    },
    {
        path: 'master/newsCategory/add',
        loadChildren: './component/master/newscategory/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/newsCategory/add/:id',
        loadChildren: './component/master/newscategory/add/add.module#AddModule',
        canActivate: [AuthGuard]
    
    },

    {
        path: 'master/profession/list',
        loadChildren: './component/master/profession/list/list.module#ListModule',
    
    },


    
    // cast and crew
    {
        path: 'master/cast_and_crew/list',
        loadChildren: './component/master/caste_and_crew/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/cast_and_crew/add',
        loadChildren: './component/master/caste_and_crew/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/cast_and_crew/add/:id',
        loadChildren: './component/master/caste_and_crew/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //genres
    {
        path: 'master/genres/list',
        loadChildren: './component/master/genres/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/genres/add',
        loadChildren: './component/master/genres/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/genres/add/:id',
        loadChildren: './component/master/genres/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //reviews
    {
        path: 'master/reviews/list',
        loadChildren: './component/master/reviews/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/reviews/add',
        loadChildren: './component/master/reviews/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/reviews/add/:id',
        loadChildren: './component/master/reviews/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //sponsor
    {
        path: 'master/sponsor/list',
        loadChildren: './component/master/sponsor/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/sponsor/add',
        loadChildren: './component/master/sponsor/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/sponsor/add/:id',
        loadChildren: './component/master/sponsor/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //Classification
    {
        path: 'master/classification/list',
        loadChildren: './component/master/classification/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/classification/add',
        loadChildren: './component/master/classification/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/classification/add/:id',
        loadChildren: './component/master/classification/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //Uplynk Ad Server
    {
        path: 'master/uplynkAdServer/list',
        loadChildren: './component/master/uplynkAdServer/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/uplynkAdServer/add',
        loadChildren: './component/master/uplynkAdServer/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/uplynkAdServer/add/:id',
        loadChildren: './component/master/uplynkAdServer/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //footer link
    {
        path: 'master/footer_links/list',
        loadChildren: './component/master/footer_links/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/footer_links/add',
        loadChildren: './component/master/footer_links/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/footer_links/add/:id',
        loadChildren: './component/master/footer_links/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    //End Master
    
    //  configuration/product_configuration
    {
        path: 'configuration/product_configuration/list',
        loadChildren: './component/configuration/product_configuration/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'configuration',
    //     loadChildren: './component/configuration/configuration/configuration.module#ConfigurationModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'configuration/producttype',
        loadChildren: './component/configuration/product_type/list/list.module#ListModule'
        
    },
    {
        path: 'configuration/productconfiguration',
        loadChildren: './component/configuration/product_configuration/add/add.module#AddModule'
        
    },
  
    {
        path: 'configuration/product_configuration/add',
        loadChildren: './component/configuration/product_configuration/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'configuration/product_configuration/add/:id',
        loadChildren: './component/configuration/product_configuration/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    //  configuration/product_type
    // {
    //     path: 'configuration/product_type/list',
    //     loadChildren: './component/configuration/product_type/list/list.module#ListModule',
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'configuration/product_type/add',
    //     loadChildren: './component/configuration/product_type/add/add.module#AddModule',
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'configuration/product_type/:id',
    //     loadChildren: './component/configuration/product_type/add/add.module#AddModule',
       
    // },
    // {
    //     path: 'configuration/product_type/:id/:langId',
    //     loadChildren: './component/configuration/product_type/add/add.module#AddModule',
      
    // },

    //  navigation/primary_navigation
    {
        path: 'navigation/primary_navigation/list',
        loadChildren: './component/navigation/primary_navigation/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation/primary_navigation/add',
        loadChildren: './component/navigation/primary_navigation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation/primary_navigation/add/:id',
        loadChildren: './component/navigation/primary_navigation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation/primary_navigation/add/:id/:langId',
        loadChildren: './component/navigation/primary_navigation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation/primary_navigation/:id',
        loadChildren: './component/navigation/primary_navigation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'navigation/primary_navigation/:id/:langId',
        loadChildren: './component/navigation/primary_navigation/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    

    //  navigation/menu_category
    // {
    //     path: 'navigation/menu_category/list',
    //     loadChildren: './component/navigation/menu_category/list/list.module#ListModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'navigation/menu_category/list',
        loadChildren: './component/navigation/menu_category/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'navigation/menu_category/add',
    //     loadChildren: './component/navigation/menu_category/add/add.module#AddModule',
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigation/menu_category/add/:id',
    //     loadChildren: './component/navigation/menu_category/add/add.module#AddModule',
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'navigation/menu_category/add/:id/:langId',
    //     loadChildren: './component/navigation/menu_category/add/add.module#AddModule',
    //     canActivate: [AuthGuard]
    // },
    
    //  page/page_category
    {
        path: 'page/page_category/list',
        loadChildren: './component/page/page_category/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/page_category/add',
        loadChildren: './component/page/page_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/page_category/add/:id',
        loadChildren: './component/page/page_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/page_category/add/:id/:langId',
        loadChildren: './component/page/page_category/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    // page/carousel
    {
        path: 'page/carousel/list',
        loadChildren: './component/page/carousel/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/carousel/add',
        loadChildren: './component/page/carousel/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/carousel/add/:id',
        loadChildren: './component/page/carousel/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'page/carousel/add/:id/:langId',
        loadChildren: './component/page/carousel/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    //Subscription
    {
        path: 'subscription/list',
        loadChildren: './component/subscription/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'subscription/add',
        loadChildren: './component/subscription/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'subscription/add/:id',
        loadChildren: './component/subscription/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },

    //Users
    {
        path: 'user_management/users/list',
        loadChildren: './component/user_management/users/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'user_management/users/add',
        loadChildren: './component/user_management/users/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'user_management/users/add/:id',
        loadChildren: './component/user_management/users/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    
    {
        path: 'user_management/role/list',
        loadChildren: './component/user_management/role/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'user_management/role/add',
        loadChildren: './component/user_management/role/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'user_management/role/add/:id',
        loadChildren: './component/user_management/role/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    
    {
        path: 'master/character/list',
        loadChildren: './component/master/character/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/character/add',
        loadChildren: './component/master/character/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/character/add/:id',
        loadChildren: './component/master/character/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    
    {
        path: 'master/bulkupload/list',
        loadChildren: './component/master/bulkupload/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/bulkupload/add',
        loadChildren: './component/master/bulkupload/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'master/bulkupload/add/:id',
        loadChildren: './component/master/bulkupload/add/add.module#AddModule',
        canActivate: [AuthGuard]
    },


    //Orders
    {
        path: 'orders/list',
        loadChildren: './component/order/list/list.module#ListModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'orders/details',
        loadChildren: './component/order/details/details.module#DetailsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'orders/details/:id',
        loadChildren: './component/order/details/details.module#DetailsModule',
        canActivate: [AuthGuard]
    },
    // {
    //     path:'**',
    //     loadChildren: './component/authentication/login/login.module#LoginModule',
    // },

    {
        path: '**',
       loadChildren: './component/dashboard/dashboard.module#DashboardModule',
    },

    // {
    //     path: '**',
    //     component:ClientComponent,
    //     // loadChildren: './component/clientManagement/clientAdd/client.module#ClientModule',
    // },
   

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
