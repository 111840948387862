import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {CommonService} from './../../../component/../services/common_Service/common.service'
import {LoginService} from '../../../services/login/login.service';
import {Loginshared} from '../../../services/sharedservices/loginshared.service'
import { Location } from "@angular/common"
import {environment} from './../../../../environments/environment';
import {SessionHelper} from '../../../services/localstorage'
import { exists } from 'fs';
import { ChangeDetectorRef,AfterContentChecked} from '@angular/core'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    @Input() currentRoutingUrl: string;

    addActiveClass=0;
    // addActiveClass: boolean[20];
    currentURL
    dashbord=false;
    roleAsses:any = {};

    dashbords:any='';

    roleConfiguration:any =[];

    menuClicked:number=1;
    hideSidebar:boolean = false;

    loader:boolean = false;
    applyBlackTheme:any = 'false';

    isUserLoggedIn: boolean = false;

   
    // configurationUrl:any='configuration/productconfiguration';
    configurationUrl:any ='configuration/productconfiguration';
    navigationUrl:any='navigation/primary_navigation/list';
    pageUrl:any='page/page_category/list';
    assetUrl:any='asset_management/asset_group/list';
    masterUrl:any = 'master/genres/list';

    constructor( private cdRef : ChangeDetectorRef , private location:Location, private commonService:CommonService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router ) {

        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );


    }

    
    ngOnInit() {


        if(environment.dashbordSubTitle == 'Dashbord'){

            this.dashbords = 'Dashbord';

        }else {

            this.dashbords = 'SCV';

        }
        

        this.commonService.notify.subscribe((result) => {
            this.loader = false;
        })

        this.commonService.currentThemelight.subscribe((result:any)=>{
          
            this.applyBlackTheme=localStorage.getItem('boolThemeFlag');
            console.log("applyBlackTheme>>",this.applyBlackTheme);
       })
  
        // role management code start.

        this.roleAsses.configuration = false;
     
        this.roleAsses.navigation = false;
        this.roleAsses.primaryNavigation = false;
        

        this.roleAsses.asset_management = false;
        this.roleAsses.asset_Group = false;
        this.roleAsses.player = false;

     

        this.roleAsses.page = false;
        this.roleAsses.page_Category = false;
      

        this.roleAsses.master = false;
        this.roleAsses.genres = false;
    

        this.roleAsses.subscription = false;
        this.roleAsses.orders = false;
        this.roleAsses.user_management = false;
        
        this.loader = true;
        this.cdRef.detectChanges();

        this.loginService.getRoleConfiguration((localStorage.getItem('roleId')))
        .subscribe((result:any)=>{

            this.roleConfiguration = result.data;
            this.loader = false;
            this.cdRef.detectChanges();

            console.log("roleConfiguration>>>",this.roleConfiguration);
            
            // set in local storage start
            SessionHelper.setRole(this.roleConfiguration);
            this.commonService.changeRole(this.roleConfiguration);
          
            this.roleConfiguration.forEach((element , i) => {

                // role management for configuration start

                if((element.isView == 1 && element.pageTitle == 'Product Type') || (element.isView == 1 && element.pageTitle == 'Configuration') ){
                    
                    this.roleAsses.configuration = true;

                    if(element.isView != 1 && element.pageTitle == 'Product Type'){

                        this.configurationUrl = 'configuration/producttype';

                    }else {

                        this.configurationUrl ='configuration/productconfiguration';

                    }
                   
                }

                // role management for configuration end

                
                if((element.isView == 1 && element.pageTitle == 'Player') ){

                    console.log("player>>>",this.roleAsses.player);
                    
                    this.roleAsses.player = true;

                    if(element.isView != 1 && element.pageTitle == 'Player'){

                        this.configurationUrl = 'player/configuration';

                    }else {

                        this.configurationUrl ='player/configuration';

                    }
                   
                }


               // role management for navigation start

                if( (element.isView == 1 && element.pageTitle == 'Primary Navigation') || (element.isView == 1 && element.pageTitle == 'Menu Category') ){
                       
                        this.roleAsses.navigation = true;

                        if(element.isView != 1 && element.pageTitle == 'Primary Navigation'){

                            this.navigationUrl = 'navigation/menu_category/list';

                        } else {
                            this.navigationUrl = '';

                            this.navigationUrl = 'navigation/primary_navigation/list';
                        }

                }

                // role management for navigation end

                if((element.isView == 1 && element.pageTitle == 'Reconcilliation') || (element.isView == 1 && element.pageTitle == 'Asset') || (element.isView == 1 && element.pageTitle == 'Asset Group') || (element.isView == 1 && element.pageTitle == 'Asset Category') ){
                    
                     this.roleAsses.asset_management = true;

                     if((element.isView != 1 && element.pageTitle == 'Asset Group') && (element.isView == 1 && element.pageTitle == 'Asset Category')){
 
                         this.assetUrl = '';

                         this.assetUrl = 'asset_management/asset_category/list';
                      
                     } 

                     if((element.isView != 1 && element.pageTitle == 'Asset Group') && (element.isView 
                        != 1 && element.pageTitle == 'Asset Category') && (element.isView == 1 && element.pageTitle == 'Asset')){
 
                        this.assetUrl = '';

                        this.assetUrl = 'asset_management/asset/list';

                    }

                    if((element.isView != 1 && element.pageTitle == 'Asset Group') && (element.isView 
                        != 1 && element.pageTitle == 'Asset Category') && (element.isView != 1 && element.pageTitle == 'Asset') && (element.isView == 1 && element.pageTitle == 'Reconcilliation')){
 
                        this.assetUrl = '';

                        this.assetUrl = 'asset_management/asset_reconcillation/list';

                    }

                }
    
                if((element.isView == 1 && element.pageTitle == 'Page Category') || (element.isView == 1 && element.pageTitle == '"Carousel"') ){
                
                    this.roleAsses.page = true;

                    if(element.isView == 1 && element.pageTitle == 'Page Category'){

                        this.pageUrl = 'page/page_category/list';

                    } 

                    if(element.isView == 1 && element.pageTitle == 'Carousel'){

                        this.pageUrl = 'page/carousel/list';

                    }

                }
                
    
                  if((element.isView == 1 && element.pageTitle == 'Genres')  || (element.isView == 1 && element.pageTitle == 'Geo Zone')  || (element.isView == 1 && element.pageTitle == 'Cast And Crew') ||(element.isView == 1 && element.pageTitle == 'Sponsor')
                    ||(element.isView == 1 && element.pageTitle == 'Reviews')
                    || (element.isView == 1 && element.pageTitle == 'Profession Type') 
                    ||(element.isView == 1 && element.pageTitle == 'Classification')
                    ||(element.isView == 1 && element.pageTitle == 'Uplynk Ad Server')
                    ||(element.isView == 1 && element.pageTitle == 'Footer Links')
                    ||(element.isView == 1 && element.pageTitle == 'Character')
                    ||(element.isView == 1 && element.pageTitle == 'Blogs')  
                    ||(element.isView == 1 && element.pageTitle == 'Blogs Category')
                    ||(element.isView == 1 && element.pageTitle == 'News')
                    ||(element.isView == 1 && element.pageTitle == 'News Category')
                    ||(element.isView == 1 && element.pageTitle == 'Bulk Import / Export'))
                     {

                        this.roleAsses.master = true;

                        if((element.isView == 1 && element.pageTitle == 'Genres')){

                            this.masterUrl = '';
                            this.masterUrl = 'master/genres/list';

                            console.log("masterUrl>>>",this.masterUrl);
                        }


                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView == 1 && element.pageTitle == 'Geo Zone') ){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/geo_zone/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView == 1 && element.pageTitle == 'Cast And Crew') ){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/cast_and_crew/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView == 1 && element.pageTitle == 'Cast And Crew')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/cast_and_crew/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView == 1 && element.pageTitle == 'Sponsor')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/sponsor/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView == 1 && element.pageTitle == 'Reviews')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/reviews/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView == 1 && element.pageTitle == 'Classification')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/classification/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView == 1 && element.pageTitle == 'uplynkAdServer') && (element.isView == 1 && element.pageTitle == 'Footer Links')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/uplynkAdServer/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView != 1 && element.pageTitle == 'uplynkAdServer') &&  (element.isView != 1 && element.pageTitle == 'Footer Links') && (element.isView == 1 && element.pageTitle == 'Blogs')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/blogs/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView != 1 && element.pageTitle == 'uplynkAdServer') && (element.isView != 1 && element.pageTitle == 'Footer Links') && (element.isView == 1 && element.pageTitle == 'Character')){
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/character/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView != 1 && element.pageTitle == 'uplynkAdServer') && (element.isView != 1 && element.pageTitle == 'Footer Links')&& (element.isView != 1 && element.pageTitle == 'Character') && (element.isView == 1 && element.pageTitle == 'Blogs Category')){
                            
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/blogsCategorys/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView != 1 && element.pageTitle == 'uplynkAdServer') && (element.isView != 1 && element.pageTitle == 'Footer Links')&& (element.isView != 1 && element.pageTitle == 'Character') && (element.isView != 1 && element.pageTitle == 'Blogs Category') && (element.isView != 1 && element.pageTitle == 'News')){
                            
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/news/list';
                        }

                        if((element.isView != 1 && element.pageTitle == 'Genres') && (element.isView != 1 && element.pageTitle == 'Geo Zone') && (element.isView != 1 && element.pageTitle == 'Sponsor') && (element.isView != 1 && element.pageTitle == 'Reviews') && (element.isView != 1 && element.pageTitle == 'Classification') && (element.isView != 1 && element.pageTitle == 'uplynkAdServer') && (element.isView != 1 && element.pageTitle == 'Footer Links')&& (element.isView != 1 && element.pageTitle == 'Character') && (element.isView != 1 && element.pageTitle == 'Blogs Category') && (element.isView != 1 && element.pageTitle == 'News') && (element.isView != 1 && element.pageTitle == 'News Category')){
                            
                            
                            this.masterUrl = '';
                            this.masterUrl = 'master/newsCategory/list';
                        }

                        if((element.isView == 1 && element.pageTitle == 'Genres')){

                            this.masterUrl = '';
                            this.masterUrl = 'master/genres/list';
                        }

                        
                        if((element.isView == 1 && element.pageTitle == 'Profession Type')){

                            this.masterUrl = '';
                            this.masterUrl = 'master/profession/list';
                        }


                      }

                    if((element.isView != 1 && element.pageTitle == 'genres') ){

                        this.roleAsses.genres = false;

                    }

                    if((element.isView == 1 && element.pageTitle == 'Users') || (element.isView == 1 && element.pageTitle == 'Role'))
                    {
                        this.roleAsses.user_management = true;
                    }
        
                    if((element.isView == 1 && element.pageTitle == 'Orders') )
                    {
        
                        this.roleAsses.orders = true;
        
                    }
        
                    if((element.isView == 1 && element.pageTitle == 'Subscription') )
                    {
        
                        this.roleAsses.subscription = true;
        
                    }
               
            })
    
            // set in local storage end 

        })
        
         // role management code end.
        
        this.dashbord=false;
        if(this.router.url=='/')   {

            this.dashbord=true;
        }else{

            this.dashbord=false;
        } 
     
        let uid = localStorage.getItem('ud');

        if (uid !== undefined && uid != null) {

            this.isUserLoggedIn = true;
        }
        else
        {

            this.isUserLoggedIn = false;
            
        }
    }

    goToLink(url: string){

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = '//helpcenter.dcafe.io';
        link.setAttribute('visibility', 'hidden');
        link.click();  

     }

    ngOnChanges(){

        if(this.currentRoutingUrl!=undefined){
            var splitUrl = this.currentRoutingUrl.split('/');
            this.setActiveMenu(splitUrl[1]);
            console.log("this.hideSidebar--------",this.hideSidebar)

        }

    
        // console.log("r-o-u-t-i-n-g-----------------------> ",splitUrl[1]);
        // localStorage.setItem('menuClicked', this.menuClicked.toString());
        
    }


    setActiveMenu(menuName){

        console.log("1234567890>>>",this.roleConfiguration);

        // below code to set role access start

        this.roleConfiguration = SessionHelper.getRole();

          this.roleConfiguration.forEach((element , i) => {

            if((element.isView == 1 && element.pageTitle == 'Product Type') || (element.isView == 1 && element.pageTitle == 'Configuration') ){
                
                this.roleAsses.configuration = true;
               
            }

            if( (element.isView == 1 && element.pageTitle == 'Primary Navigation') || (element.isView == 1 && element.pageTitle == 'Menu Category') ){
                   
                this.roleAsses.navigation = true;
               
            }

            if((element.isView == 1 && element.pageTitle == 'Reconcilliation') || (element.isView == 1 && element.pageTitle == 'Asset') || (element.isView == 1 && element.pageTitle == 'Asset Group') || (element.isView == 1 && element.pageTitle == 'Asset Category') ){
                
                 this.roleAsses.asset_management = true;
               
            }


            if((element.isView == 1 && element.pageTitle == 'Page Category') || (element.isView == 1 && element.pageTitle == '"Carousel"') ){
            
                this.roleAsses.page = true;
        
            }

              if((element.isView == 1 && element.pageTitle == '"Genres"') 
                || (element.isView == 1 && element.pageTitle == '"Geo Zone"')
                || (element.isView == 1 && element.pageTitle == '"Cast And Crew"')
                ||(element.isView == 1 && element.pageTitle == '"Sponsor"')
                ||(element.isView == 1 && element.pageTitle == '""Reviews""')
                ||(element.isView == 1 && element.pageTitle == '"Classification"')
                ||(element.isView == 1 && element.pageTitle == '"Uplynk Ad Server"')
                ||(element.isView == 1 && element.pageTitle == '"Footer Links"')
                ||(element.isView == 1 && element.pageTitle == '"Character"')
                ||(element.isView == 1 && element.pageTitle == '""Blogs""')  
                ||(element.isView == 1 && element.pageTitle == '"Blogs Category"')
                ||(element.isView == 1 && element.pageTitle == '""News""')
                ||(element.isView == 1 && element.pageTitle == '"News Category"')
                ||(element.isView == 1 && element.pageTitle == '"Bulk Import / Export"'))
                 {
                    this.roleAsses.master = true;
                 
                  }

              if((element.isView == 1 && element.pageTitle == '"Users"') )
              {
               
                this.roleAsses.user_management = true;
              }

              if((element.isView == 1 && element.pageTitle == '"Orders"') )
              {

                this.roleAsses.orders = true;

              }

              if((element.isView == 1 && element.pageTitle == '"Subscription"') )
              {

                this.roleAsses.subscription = true;

              }
       
               
            })

    

        // below code to set role access start
            
        if(menuName=='client' || menuName=='clientAdd'){
            this.hideSidebar = true;
        }
        else{
            this.hideSidebar = false;
        }
        if(menuName=='dashboard'){
            this.menuClicked=1;
        }
        else if(menuName=='configuration'){
            this.menuClicked=2;
        }
        else if(menuName=='navigation'){
            this.menuClicked=3;
        }
        else if(menuName=='asset_management'){
            this.menuClicked=4;
        }
        else if(menuName=='page'){
            this.menuClicked=5;
        }
        else if(menuName=='master'){
            this.menuClicked=6;
        }
        else if(menuName=='subscription'){
            this.menuClicked=7;
        }
        else if(menuName=='orders'){
            this.menuClicked=7;
        }
        else if(menuName=='user_management'){            
            this.menuClicked=9;
        }
        else if(menuName=='search'){
            this.menuClicked=0;
        }
        else if(menuName=='player'){
            this.menuClicked=10;
        }
    }

    onCheck() {

    
        // localStorage.setItem('menuClicked', this.menuClicked.toString());
        this.dashbord = false;

        // this.router.events.subscribe((url:any) => {

        //     console.log("123",this.router.url);

        // })
        
        // console.log("---------------------------- ",this.currentRoutingUrl);

        if(this.router.url=='/')   {
            this.dashbord = true;
            this.commonService.changeThemelight('');
        }else{
            this.dashbord = false;
            this.commonService.changeThemelight('');
        }  
    }

}
