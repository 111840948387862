import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {contentType ,assetGroupType ,sorting ,orderby} from '../../../../../configurations/configuration';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import {SessionHelper} from '../../../../../services/localstorage';
import { Subject } from 'rxjs';
import {AssetService} from '../../../../../services/asset/asset.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {awsPath, deviceType, displayIndexingPattern} from '../../../../../configurations/configuration';
import {CarouselService } from '../../../../../services/carousel/carousel.service';
import { CasteAndCrewService } from '../../../../../services/caste_and_crew/caste_and_crew.service';

@Component({
  selector: 'app-addCarousel',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentCarousel implements OnInit {

    isCreateNewShow:boolean = true;

    deviceType = deviceType;
    loader:boolean=false;
    confAsset:any=[];
    casteAndCrewList:any = [];
    update=false;
  
    isUserLoggedIn: boolean = false;
    createCarouselForm:FormGroup;
    assetGroupType = assetGroupType;
    errorMessages = errorMessages;
    createCarouselSubmitted:boolean = false;
    selectedpageCategoryList = [] ; 
    dropdownList = [];
    multipleDeviceForm:FormGroup;
    geoZoneList = [];
    primaryNavigationList=[];
    ProducTypeDropdownList = [];
    producTypeList=[];
    assetGroupList=[];
    pageCategoryList = [];
    menuCategoryList=[];
    assetCategoryList=[];
    selectedProductlist=[];
    selectedAssetCategory=[];
   
    selectedItems = [];
    selectedprimaryNavigationList=[];
    selectedcertificationList=[];
    selectedTrailerVideoList=[];
    selectedmenuCategoryList=[];
    selectedAssetGroupList=[];
    certificationList=[];
    trailerVideoList=[];
    primaryNavigationSettings={};
    menuCategorySettings={};
    dropdownSettings={};
    castAndCrewSettings = {};

    devices: FormArray;
    start:number=0;
    limit:number=12;
    id:any;
    dTypeDublicateError:any;
    All:boolean=false;
    Web:boolean=false;
    Android:boolean=false;
    iOS:boolean=false;

    all:boolean = false;;
    web:boolean;
    android:boolean;
    roku:boolean;
    deviceTypeFull:boolean = false;
    deviceTypeDublicate:boolean = false;
    
    uploadImgFilePath:any=[];
    img:any=[];

    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;
        
    langId:any;

    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    menuCategoryMappingFilter:any={};
    mapTrailerSettings:any={};
    assetGroupMappingFilter:any={};
    assetCategoryMappingFilter:any={};
    //end mapping filter

    dropdownDev:any={};

    isDeleteImageAction:boolean=false;

    applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    formTabs:any = {

        imgInfo:{

        img:true,
        mapGeoZone:true,
         information:false

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
            uploadImg:false

        },
        mapping:false,
      
    }
    
    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
   @Input() set contentId(value: any) {

    if(value == 'reset'){

        this.resetForm();

    }else {

        //start code for hide create new button
        if(value != 'null'){
            this.showCreateNewButton('hide');
        }
        //end code for hide create new button
        this.onLoad(value);
    }
    
}
//New Code end
        
    shareMassage :any;    
    contentReadyToReview:boolean= false;
    
    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
    constructor(private assetService:AssetService, private casteAndCrewService:CasteAndCrewService,private toastrService:ToastrService, private carouselService:CarouselService,private activatedRoute:ActivatedRoute,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private formBuilder: FormBuilder ) {
        
        // loginSharedService.sendUserLoggedIn$.subscribe(
        //     mission => {
        //         this.isUserLoggedIn = mission;
        //         let uid = localStorage.getItem('ud');
        //         if (uid !== undefined && uid != null) {
        //             this.isUserLoggedIn = true;
        //         }
        //     }
        // );
    }
    
    roleAsses:any={};
    roleConfiguration:any=[];
    ngOnInit(){


        this.setupSearchDebouncer();
        this.roleAsses.carouselEdit = false;
        this.roleAsses.carousel = false;
        this.roleAsses.carouselCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Carousel') ){
                  
                  this.roleAsses.carousel = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Carousel') ){
                  
                  this.roleAsses.carouselEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Carousel')){

                  this.roleAsses.carouselCreate = true;
              
              }

        })

        // theme update code start

        this.getAllCasteAndCrew();
                        
        // this.commonService.currentThemelight.subscribe((result:any)=>{
                        
        //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
        // })

        
          this.commonService.currentThemelight.subscribe((result:any)=>{

            if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='carousel'){

                let item = JSON.parse(localStorage.getItem('langItem'));
                
                if(item.id!=undefined)
                   this.id = item.id;


                this.createCarouselForm.controls['id'].setValue(this.id);

                if(item.id != null && this.roleAsses.carouselEdit == true){
                    this.editCreate = true;
                    this.update = true;
                }else{
                    this.editCreate = false;
                }


                this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue([[]]);
                this.createCarouselForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                this.isCreateNewShow = false;
                this.getCarouselInfo(item.id);
                // localStorage.removeItem('langItem');

            }
               this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          })

        // theme update code start
    }

    getAllCasteAndCrew(){

        this.loader=false;
        
        this.casteAndCrewService.getAllCast(this.filterCast,this.startCastAndCrew,10)
        .subscribe((result:any)=>{
            
            this.loader = false;

            // this.casteAndCrewList = [];
            
            console.log('result===>',result.data.data);
            
            if(result.data.data.length > 0){
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.casteAndCrewList = [];
                }

                for (let casteAndCrewList of result.data.data) {
   
                    if(casteAndCrewList.castFirstName!='' && casteAndCrewList.castFirstName!=null){
                    
                        this.casteAndCrewList.push(
                        {
                            'id': casteAndCrewList.castId,
                            'itemName': casteAndCrewList.castFirstName
                        });
                    } 

                    this.casteAndCrewList = this.removeDuplicates(this.casteAndCrewList,'id')
                }
            }

        }) 
        
    }

    startCastAndCrew:any = 0;
    fetchMoreGetCast(event){

        if ( ((event.endIndex >= this.casteAndCrewList.length - 2)) && (this.casteAndCrewList.length >=8)) {

            this.startCastAndCrew = this.startCastAndCrew + 10;

            this.getAllCasteAndCrew();  

        }
    }
    
    editCreate:boolean = false;
    onCreateNew(){


        this.editCreate = true; 
        this.isCreateNewShow=false

    }

    dropdown: any = {};
    //New Code for right side for from
    onLoad(id) {

        this.id = id;

        if(this.id != 'null' && this.roleAsses.carouselEdit == true){
            this.editCreate = true;
        }else{
            this.editCreate = false;
        }
        
        this.langId = "1";
        
        let headerTitle = {
            title:'Page Management',
            subTitle:'Carousel'
 
        }
 
        this.commonService.changeHeaderTitle(headerTitle);
        this.onImgInfoTabClick();
        // let uid = localStorage.getItem('ud');
        // if (uid !== undefined && uid != null) {

        // }   else {
        //     this.router.navigate(['/login']);
        // }

        this.primaryNavigationSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }

        this.menuCategorySettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }

        this.mapTrailerSettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }

        this.dropdownSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }

        
        this.dropdown = {

            singleSelection: false, 
            text:"Select text",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['itemName'], 
            maxHeight:130,
            lazyLoading: true

        }

        
        this.dropdownDev = {

            singleSelection: true, 
            text:"Select text",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['itemName'], 
            maxHeight:130,
            lazyLoading: true

        }

        this.castAndCrewSettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"

        }


        this.createCarouselForm = this.formBuilder.group({
            isActive : ["1"],
            langId: ["1"],
            companyId :[ ""],
            title : ["",Validators.compose([Validators.required])],
            sameForAllZone :[''],
            sameForAllProductType :[''],
            displayIndexing :['',Validators.pattern(displayIndexingPattern)],
            displayType : [''],
            orderBy :[""],
            isPrimaryNavigationVisible:[''],
            isMenuCategoryVisible :[''],
            isAssetGroupVisible :[''],
            isAssetCategoryVisible :[''],
            isPageCategoryVisible : [''],
            exclusionText : [""],
            description : [""],
            isVisible : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            sorting :[""],
            mapGeoZone:[[], Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapAssetCategory:[[]],
            mapPageCategory:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        })
        

        this.productTypeMappingFilter.geoZoneId = [];
        this.primaryNavigationMappingFilter.productTypeId = [];
        this.menuCategoryMappingFilter.primaryNavigationId = [];
        this.assetGroupMappingFilter.menuCategoryId = [];
        this.assetCategoryMappingFilter.assetGroupId = [];

        this.getGeoZoneInfo();
        this.getNavigationInfo({}); // Map Certification
        this.getProductTypeInfo();
        this.getGlobaltrailervideo();
        this.getGlobalMenuCategory({});
        this.getGlobalAssetGroup({});
        this.getglobalPageCategory();
        this.getGlobalAssetCategory({});
        this.getGlobaltrailervideo(); 
        this.getAllAsset();

        //this.activatedRoute.params.subscribe(params => {

            //console.log('Ranjeet===>',params);
            
            this.id = id; // (+) converts string 'id' to a number
            
            //this.id = +params['id']; // (+) converts string 'id' to a number

            //this.langId = +params['langId']? +params['langId'] : "1";

               //New Code start
        if(this.id!='null'){
               
                console.log('this.id===>>>',this.id);
            //    this.loader = true;
            this.commonService.changeloader(true);
               
                this.update=true;
                    setTimeout(()=>{
                        this.getCarouselInfo(this.id);
                    },300)
              

            }else{

                this.update=false;

                  this.img.push({
                      url:'',
                  })

           

                  let obj = {
                    'index':'',
                    'deviceType':'',
                    'selectedFiles':'',
                    'fileName': '',
                    'filePath': "",
                    'fileType':''
                }
    
               this.uploadImgFilePath.push(obj)
                  
                  this.confAsset.push({
                        'selectedconfAssetList':[],
                        'selectedconfAssetCategoryList':[],
                        'selectedconfAssetGroupList':[],
                        'selectedconfMenuCategoryList':[],
                        'selectedconfPrimaryNavigationList':[],
                        'selectedconfTrailerVideoList':[]
                  })  
          
            }
  
        // In a real app: dispatch action to load the details here.
        //});
        

        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });

    }

    get l() { return this.createCarouselForm.controls; }

    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }

    onImgInfoTabClick(){

        this.formTabs = {

            imgInfo:{
    
             img:true,
             mapGeoZone:true,
             information:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                uploadImg:false
    
            },
            mapping:false,
          
        }
    }

    onInformationTabClick(){

        this.formTabs = {
        

            imgInfo:{
    
             img:true,
             mapGeoZone:false,
             information:true
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                uploadImg:false
            },
            mapping:false,
            
        }
    }

    onDeviceTypeTabClick(){

        this.formTabs = {
        

            imgInfo:{
    
             img:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:true,
                uploadImg:false
            
            },
            mapping:false,
            
        }
    }

    onUploadTabClick(){

        this.formTabs = {
        

            imgInfo:{
    
             img:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                uploadImg:true
            
            },
            mapping:false,
            
        }
    }

    onConfigurationTabClick(){

        this.formTabs = {
        

            imgInfo:{
    
             img:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:true,
                uploadImg:false
            
            },
            mapping:false,
        }
    }  
    
    onMappingTabClick(){

        this.formTabs = {
        

            imgInfo:{
    
             img:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                uploadImg:false
            
            },
            mapping:true,
        }
    }
           

    
  
    //dri multiselect code
    onItemSelect(item:any, mappingName){
      
        if(mappingName == 'mapGeoZone'){
            // this.productTypeMappingFilter.geoZoneId=[];
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.getProductTypeInfo();
        }
        else if(mappingName == 'mapProductType'){
            // this.primaryNavigationMappingFilter.productTypeId = [];
            this.primaryNavigationMappingFilter.productTypeId.push(item.id);
            this.getNavigationInfo(this.primaryNavigationMappingFilter);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            // this.menuCategoryMappingFilter.primaryNavigationId = [];
            this.menuCategoryMappingFilter.primaryNavigationId.push(item.id);
            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
        }
        else if(mappingName == 'mapMenuCategory'){
            // this.assetGroupMappingFilter.menuCategoryId = [];
            this.assetGroupMappingFilter.menuCategoryId.push(item.id);
            this.getGlobalAssetGroup(this.assetGroupMappingFilter);
        }
        else if(mappingName == 'mapAssetGroup'){
            // this.assetCategoryMappingFilter.assetGroupId = [];
            this.assetCategoryMappingFilter.assetGroupId.push(item.id);
            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
        }
    }

    OnItemDeSelect(item:any, mappingName){
        
        if(mappingName == 'mapGeoZone'){

            for (let index=0; index< this.productTypeMappingFilter.geoZoneId.length;index++){

                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){

                        this.productTypeMappingFilter.geoZoneId.splice(index, 1);

                        if(this.productTypeMappingFilter.geoZoneId.length == 0){

                            this.producTypeList = [];
                            
                            this.createCarouselForm.controls['mapProductType'].setValue([]);
                            
                        }else{

                            this.getProductTypeInfo();
                        }
                    
                    return;
                }
            }
        }
        

        else if(mappingName == 'mapProductType'){
            for (let index=0; index< this.primaryNavigationMappingFilter.productTypeId.length;index++){
                if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){
                    this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);
                    console.log("mapProductType deleted = ",item.id);

                    if(this.primaryNavigationMappingFilter.productTypeId.length == 0){
                        this.primaryNavigationList = [];
                        this.createCarouselForm.controls['mapPrimaryNavigation'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                    }
                    
                    return;
                }
            }
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            for (let index=0; index< this.menuCategoryMappingFilter.primaryNavigationId.length;index++){
                if(this.menuCategoryMappingFilter.primaryNavigationId[index] == item.id){
                    this.menuCategoryMappingFilter.primaryNavigationId.splice(index, 1);
                    console.log("mapPrimaryNavigation deleted = ",item.id);

                    if(this.menuCategoryMappingFilter.primaryNavigationId.length == 0){
                        this.menuCategoryList = [];
                        this.createCarouselForm.controls['mapMenuCategory'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    }
                    
                    return;
                }
            }
        }

        
        else if(mappingName == 'mapMenuCategory'){
            for (let index=0; index< this.assetGroupMappingFilter.menuCategoryId.length;index++){
                if(this.assetGroupMappingFilter.menuCategoryId[index] == item.id){
                    this.assetGroupMappingFilter.menuCategoryId.splice(index, 1);
                    console.log("mapMenuCategory deleted = ",item.id);

                    if(this.assetGroupMappingFilter.menuCategoryId.length == 0){
                        this.assetGroupList = [];
                        this.createCarouselForm.controls['mapAssetGroup'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                    }
                    
                    return;
                }
            }
        }
        else if(mappingName == 'mapAssetGroup'){
            for (let index=0; index< this.assetCategoryMappingFilter.assetGroupId.length;index++){
                if(this.assetCategoryMappingFilter.assetGroupId[index] == item.id){
                    this.assetCategoryMappingFilter.assetGroupId.splice(index, 1);
                    console.log("mapMenuCategory deleted = ",item.id);

                    if(this.assetCategoryMappingFilter.assetGroupId.length == 0){
                        this.assetCategoryList = [];
                        this.createCarouselForm.controls['confAssetCategory'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
                    }
                    
                    return;
                }
            }
        }
    }

    OnItemDeSelectConf(item:any,mappingName,i){

                if(mappingName == 'confPrimaryNavigation'){
                      this.confAsset[i].confPrimaryNavigation=[];
                }

                if(mappingName == 'confMenuCategory'){
                    this.confAsset[i].confMenuCategory=[];
                }

                if(mappingName == 'confAssetGroup'){
                    this.confAsset[i].confAssetGroup=[];
                }

                if(mappingName == 'confAssetCategory'){
                    this.confAsset[i].confAssetCategory=[];
                }

                if(mappingName == 'confAsset'){
                    this.confAsset[i].confAsset=[];
                }
                if(mappingName == 'cast'){
                    this.confAsset[i].confCastId=[];
                }


    }

    onSelectAll(item: any , mappingName){

        console.log("select All from = ",item);

        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter=filterObj;
            this.getProductTypeInfo();
        }
        else if(mappingName == 'mapProductType'){
            let filterObj ={ productTypeId :[] };
            item.forEach(function (value) {
                filterObj.productTypeId.push(value.id);
            });
            this.primaryNavigationMappingFilter = filterObj;
            this.getNavigationInfo(filterObj);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            let filterObj ={ primaryNavigationId :[] };
            item.forEach(function (value) {
                filterObj.primaryNavigationId.push(value.id);
            });
            this.menuCategoryMappingFilter = filterObj;
            this.getGlobalMenuCategory(filterObj);
        }
        else if(mappingName == 'mapMenuCategory'){
            let filterObj ={ menuCategoryId :[] };
            item.forEach(function (value) {
                filterObj.menuCategoryId.push(value.id);
            });
            this.assetGroupMappingFilter = filterObj;
            this.getGlobalAssetGroup(filterObj);
        }
        else if(mappingName == 'mapAssetGroup'){
            let filterObj ={ assetGroupId :[] };
            console.log("value>>",item);

            item.forEach(function (value) {
                console.log("value.id",value.id);
                filterObj.assetGroupId.push(value.id);
            });
            this.assetCategoryMappingFilter = filterObj;
            this.getGlobalAssetCategory(filterObj);
        }
        else if(mappingName == 'mapAssetCategory'){
            // let filterObj ={ assetGroupId :[] };
            // console.log("value>>",item);

            // item.forEach(function (value) {
            //     console.log("value.id",value.id);
            //     filterObj.assetGroupId.push(value.id);
            // });
            // this.assetCategoryMappingFilter = filterObj;
            // this.getGlobalAssetCategory(filterObj);
        }
    }

    onDeSelectAll(event, info){
        
        if(info =='mapGeoZone'){
            this.createCarouselForm.controls['mapGeoZone'].setValue([]);
            this.producTypeList = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'mapProductType'){
            this.createCarouselForm.controls['mapProductType'].setValue([]);
            this.primaryNavigationList = [];
            this.mapProductTypeArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'mapPrimaryNavigation'){
            this.createCarouselForm.controls['mapPrimaryNavigation'].setValue([]);
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'mapMenuCategory'){
            this.createCarouselForm.controls['mapMenuCategory'].setValue([]);
            this.assetGroupList = [];
            this.mapMenuCategoryArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info == 'mapAssetGroup'){
            this.createCarouselForm.controls['mapAssetGroup'].setValue([]);
            this.assetCategoryList = [];
            this.selectedAssetGroupList = [];
            this.mapAssetGroupArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='confPrimaryNavigation'){

            console.log("c this.multipleDeviceForm",this.multipleDeviceForm.controls)
            // this.multipleDeviceForm.controls['confPrimaryNavigation'].setValue([]);
        }
        else if(info == 'confMenuCategory'){

            console.log("c this.multipleDeviceForm",this.multipleDeviceForm.controls)
            // this.multipleDeviceForm.controls['confMenuCategory'].setValue([]);
            // this.menuCategoryList = [];
            // console.log("DeSelect All from = ",info);
        }
        else if(info == 'confAssetGroup'){

            console.log("c this.multipleDeviceForm",this.multipleDeviceForm.controls)
            // this.multipleDeviceForm.controls['confAssetGroup'].setValue([]);
        }
        else if(info == 'confAssetCategory'){

            // this.multipleDeviceForm.controls['confAssetCategory'].setValue([]);
            console.log("c this.multipleDeviceForm",this.multipleDeviceForm.controls)
        }
         else if(info == 'confAsset'){
        //     this.multipleDeviceForm.controls['confAsset'].setValue([]);
            console.log("c this.multipleDeviceForm",this.multipleDeviceForm)
        }
        else if(info == 'mapAssetCategory'){
            this.createCarouselForm.controls['mapAssetCategory'].setValue([]);
            this.mapAssetCategoryArrayTemp = [];
        }
        else if(info == 'mapPageCategory'){
            this.createCarouselForm.controls['mapPageCategory'].setValue([]);
            
        }
    }


    onDeSelectAllConf(item:any,mappingName,i){

        console.log("mappingName>>>>",mappingName);
        console.log("mappingName>>>>",this.confAsset,i);


        if(mappingName == 'confPrimaryNavigation'){
              this.confAsset[i].selectedconfPrimaryNavigationList=[];
        }

        if(mappingName == 'confMenuCategory'){
            this.confAsset[i].selectedconfMenuCategoryList=[];
        }

        if(mappingName == 'confAssetGroup'){
            this.confAsset[i].selectedconfAssetGroupList=[];
        }

        if(mappingName == 'confAssetCategory'){
            this.confAsset[i].selectedconfAssetCategoryList=[];
        }

        if(mappingName == 'confAsset'){
            this.confAsset[i].selectedconfAssetList=[];
        }

        if(mappingName == 'confcast'){
             this.confAsset[i].selectedconfCastAndCrew = [];
        }
        if(mappingName == 'conftrailer'){
            this.confAsset[i].selectedconfTrailerVideoList = [];
       }

    }    

    saveCarousel(){

        console.log("this.multipleDeviceForm.value.devices",this.multipleDeviceForm.value.devices);

        let  tempUploadImgFilePath = this.uploadImgFilePath;
        
        this.uploadImgFilePath = [];

        tempUploadImgFilePath.forEach((element , i) => {

            if( element.selectedFiles !='') {

             this.uploadImgFilePath.push(element);

            }

       });
       
        // this.loader =false;
        this.commonService.changeloader(false);

        let async = this.uploadImgFilePath.length;
        let varialbeArray=[];
        this.createCarouselSubmitted = true ; 

        if(this.multipleDeviceForm.value.devices.length == 0 ){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.deviceTypeDublicate){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(!this.createCarouselForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
        
        if(!this.update){
            
            if(this.roleAsses.carouselCreate == false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
                
            }

            console.log("inserting records===>",this.update);
            
            console.log("uploadImgFilePath>>",this.uploadImgFilePath);
            
            if(this.uploadImgFilePath.length>0){
                
                console.log("inserting image===>");
                
                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                        
                            async--;                       
                            this.uploadImgFilePath[i].filePath=res.Location;  
                            this.createCarouselSubmitted = true;
                            console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                            let multiAsync = this.multipleDeviceForm.value.devices.length;

                            if (async == 0) {

                                for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                    multiAsync -- ;
                                    console.log("this.multipleDeviceForm.value.devices",this.multipleDeviceForm.value.devices); 
                                            varialbeArray.push(
                                            {
                                                'confTrailerVideo':this.multipleDeviceForm.value.devices[i].confTrailerVideo,
                                                'caption':this.multipleDeviceForm.value.devices[i].caption,
                                                'carouselType':this.multipleDeviceForm.value.devices[i].carouselType,
                                                'clickThrough':this.multipleDeviceForm.value.devices[i].clickThrough,
                                                'confAsset':this.multipleDeviceForm.value.devices[i].confAsset,
                                                'confAssetCategory':this.multipleDeviceForm.value.devices[i].confAssetCategory,
                                                'confAssetGroup':this.multipleDeviceForm.value.devices[i].confAssetGroup,
                                                'confMenuCategory':this.multipleDeviceForm.value.devices[i].confMenuCategory,
                                                'confPrimaryNavigation':this.multipleDeviceForm.value.devices[i].confPrimaryNavigation,
                                                'description':this.multipleDeviceForm.value.devices[i].description,
                                                'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                                                'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                                                'confDisplayIndexing':this.multipleDeviceForm.value.devices[i].confDisplayIndexing,
                                                'fileName':'',
                                                'filePath':'',
                                                'fileType':'',
                                                'isButtonRequired':this.multipleDeviceForm.value.devices[i].isButtonRequired,
                                                'label':this.multipleDeviceForm.value.devices[i].label,
                                            });   

                                }

                                if(multiAsync == 0) {

                                    let asyncvariables = varialbeArray.length;

                                    for(let i=0;i< varialbeArray.length;i++) {

                                        asyncvariables--;

                                        for(let j = 0 ;j< this.uploadImgFilePath.length; j++){
                                            if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId){

                                                varialbeArray[i].fileName=this.uploadImgFilePath[j].fileName;
                                                varialbeArray[i].filePath=this.uploadImgFilePath[j].filePath;
                                                varialbeArray[i].fileType=this.uploadImgFilePath[j].fileType;

                                                console.log("this.>>>",varialbeArray[i]);

                                            }
                                        }
                                    }

                                    if(asyncvariables == 0){

                                        this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                        console.log("this.createCarouselForm.value",this.createCarouselForm.value);

                                        this.postCallCarousel();

                                        //New Code for right side for from
                                        //this.onContentReadyToReview();
                                    }
                                }

                            }      

                        }

                    });
                }
                
            } else if(this.uploadImgFilePath.length==0){
            
                console.log("inserting without image===>");
            
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                  
                    varialbeArray.push(
                    {
                        'caption':this.multipleDeviceForm.value.devices[i].caption,
                        'carouselType':this.multipleDeviceForm.value.devices[i].carouselType,
                        'clickThrough':this.multipleDeviceForm.value.devices[i].clickThrough,
                        // 'confAsset':this.multipleDeviceForm.value.devices[i].confAsset,
                        // 'confAssetCategory':this.multipleDeviceForm.value.devices[i].confAssetCategory,
                        // 'confDisplayIndexing':this.multipleDeviceForm.value.devices[i].confDisplayIndexing,
                        // 'confAssetGroup':this.multipleDeviceForm.value.devices[i].confAssetGroup,
                        // 'confMenuCategory':this.multipleDeviceForm.value.devices[i].confMenuCategory,
                        // 'confPrimaryNavigation':this.multipleDeviceForm.value.devices[i].confPrimaryNavigation,
                        'confAsset':this.confAsset[i].selectedconfAssetList,
                        'confAssetCategory':this.confAsset[i].selectedconfAssetCategoryList,
                        'confTrailerVideo':this.confAsset[i].selectedconfTrailerVideoList,
                        'confAssetGroup':this.confAsset[i].selectedconfAssetGroupList,
                        'confMenuCategory':this.confAsset[i].selectedconfMenuCategoryList,
                        'confCastId':this.confAsset[i].selectedconfCastAndCrew,
                        'confPrimaryNavigation':this.confAsset[i].selectedconfPrimaryNavigationList,
                        'confDisplayIndexing':this.multipleDeviceForm.value.devices[i].confDisplayIndexing,

                        'description':this.multipleDeviceForm.value.devices[i].description,
                        'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                        'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                        'fileName':this.multipleDeviceForm.value.devices[i].fileName,
                        'filePath':this.multipleDeviceForm.value.devices[i].filePath,
                        'fileType':this.multipleDeviceForm.value.devices[i].fileType,
                        'isButtonRequired':this.multipleDeviceForm.value.devices[i].isButtonRequired,
                        'label':this.multipleDeviceForm.value.devices[i].label,
                    });   

                }
               
                this.createCarouselSubmitted = true;
                this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    return
                }
                
        
                if(!this.createCarouselForm.valid){
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    return
                }
             
                console.log("createNewProductForm",this.createCarouselForm);
                this.postCallCarousel();
                //this.onContentReadyToReview();
                
            }

        } else if(this.update){

            if(this.roleAsses.carouselEdit == false){
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }
            
            console.log("updating records===>",this.update);
            
            let asyncvariables = this.multipleDeviceForm.value.devices.length;

            if(this.uploadImgFilePath.length == 0){
                
                console.log("this.multipleDeviceForm.value",this.multipleDeviceForm.value)
                this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createCarouselSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    return
                }
              

                if(!this.createCarouselForm.valid){
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    return
                }
              
            
                console.log("createNewProductForm",this.createCarouselForm);
                
                this.postCallCarousel();
                
                //New Code for right side for from
                //this.onContentReadyToReview();
                

            }

            
            if(this.uploadImgFilePath.length > 0 ){
                
                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                            this.uploadImgFilePath[i].filePath=res.Location; 
                            this.createCarouselSubmitted = true;
                        }

                        if (async == 0) {

                            for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                
                                for(let j = 0 ;j< this.uploadImgFilePath.length; j++){
                    
                                    if (this.multipleDeviceForm.value.devices[i].dtypeId == this.uploadImgFilePath[j].deviceType){

                                        this.multipleDeviceForm.value.devices[i].fileName=this.uploadImgFilePath[j].fileName;
                                        this.multipleDeviceForm.value.devices[i].filePath=this.uploadImgFilePath[j].filePath;
                                        this.multipleDeviceForm.value.devices[i].fileType=this.uploadImgFilePath[j].fileType;
                                        
                                    }
                                }
                            }

                            if(asyncvariables == 0){

                            
                                this.createCarouselSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                //   this.loader  = false ;
                                this.commonService.changeloader(false);
                                  return
                                }
                            

                                if(!this.createCarouselForm.valid){
                                    // this.loader =false;
                                    this.commonService.changeloader(false);
                                    return
                                }
                                
                                this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("createNewProductForm",this.createCarouselForm);
                                this.postCallCarousel();
                                
                                // this.loader = false;
                                this.commonService.changeloader(false);

                                //New Code for right side for from
                                // this.onContentReadyToReview();
                                
                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    });
                }
            }

        }

    }

    getCarouselInfo(id){

        // this.loader=true;
        this.commonService.changeloader(true);
        this.loader = true;

        this.carouselService.getCarousel(id, this.langId)
        .subscribe((result:any)=>{
           // this.id = null;
            this.commonService.changeloader(false);
        
            //(start) set dropdown by default empty
            if(result.data[0].sameForAllProductType == null){
                result.data[0].sameForAllProductType = "";
            }
            if(result.data[0].sameForAllZone == null){
                result.data[0].sameForAllZone = "";
            }
            //(end) set dropdown by default empty
            
            console.log("carouselService>>",result);
            
            if(result.data[0].results.productDeviceTypeConfigure.length>0){

                this.img = [];
                this.multipleDeviceForm = this.formBuilder.group({
                    devices: this.formBuilder.array([])
                });
                //device type validation code start
                if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                    this.deviceTypeFull = true;
             }
              //device type validation code end

                this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                    this.img.push({
                        url:result.data[0].results.productDeviceTypeConfigure[i].filePath,
                    })      

                      //for imgUplaod obj.
                      let obj = {
                        'index':'',
                        'deviceType':'',
                        'selectedFiles':'',
                        'fileName': '',
                        'filePath': "",
                        'fileType':''
                    }
        
                this.uploadImgFilePath.push(obj);

                    this.confAsset.push({
                        'selectedconfAssetList':[],
                        'selectedconfAssetCategoryList':[],
                        'selectedconfAssetGroupList':[],
                        'selectedconfMenuCategoryList':[],
                        'selectedconfPrimaryNavigationList':[],
                        'selectedconfTrailerVideoList':[]
                    }) 
                    this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i],i));
                    this.createCarouselForm.controls['displayIndexing'].setValue(result.data[0].results.productDeviceTypeConfigure[i].displayIndexing);
                    this.createCarouselForm.controls['isAssetCategoryVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isAssetCategoryVisible);
                    this.createCarouselForm.controls['isAssetGroupVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isAssetGroupVisible);      
                    this.createCarouselForm.controls['isMenuCategoryVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isMenuCategoryVisible);
                    this.createCarouselForm.controls['isPageCategoryVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isPageCategoryVisible);
                    this.createCarouselForm.controls['isPrimaryNavigationVisible'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isPrimaryNavigationVisible);
                    this.createCarouselForm.controls['title'].setValue(result.data[0].results.productDeviceTypeConfigure[i].title); 
                    this.createCarouselForm.controls['exclusionText'].setValue(result.data[0].results.productDeviceTypeConfigure[i].exclusionText); 

                    this.createCarouselForm.controls['publisherState'].setValue(result.data[0].publisherState);
                    this.createCarouselForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
                            
                }
                this.createCarouselForm.controls['isActive'].setValue(result.data[0].isActive); 
            }
            
            this.createCarouselForm.controls['title'].setValue(result.data[0].title); 
            this.createCarouselForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone); 
            this.createCarouselForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType); 
            
            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createCarouselForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createCarouselForm.controls['contentState'].setValue(result.data[0].contentState);
            }

        
            this.primaryNavigationMappingFilter.productTypeId =[];

            if(result.data[0].results.productType.length>0){

                this.selectedProductlist = [];
                for(let i = 0 ;i < result.data[0].results.productType.length;i++){


                    let obj={
                    'id':result.data[0].results.productType[i].productTypeId,
                    'itemName':result.data[0].results.productType[i].productTypeName,
                    }

                    this.selectedProductlist.push(obj);  
                    this.primaryNavigationMappingFilter.productTypeId.push(obj.id);


                }
                
                this.createCarouselForm.controls['mapProductType'].setValue(this.selectedProductlist);
            }


            this.selectedItems=[];
          
              setTimeout(() => {

                result.data[0].results.geoZone.forEach(element => {

                this.productTypeMappingFilter.geoZoneId.push(element.id);

                    let obj={
                        'id':element.geoZoneId,
                        'itemName':element.geoZoneName,
                        }
                        this.selectedItems.push(obj);  
                        this.createCarouselForm.controls['mapGeoZone'].setValue(this.selectedItems);
                });

                if(result.data[0].results.assetGroup.length>0){

                    this.selectedAssetGroupList = [];
                    
                    for(let i = 0 ;i < result.data[0].results.assetGroup.length;i++){
                        let obj={
                            'id':result.data[0].results.assetGroup[i].assetGroupId,
                            'itemName':result.data[0].results.assetGroup[i].assetGroupName,
                        }
                        this.selectedAssetGroupList.push(obj);

                        this.assetCategoryMappingFilter.assetGroupId.push(obj.id);

                        this.mapAssetGroupArrayTemp =   this.selectedAssetGroupList;
                        this.createCarouselForm.controls['mapAssetGroup'].setValue(this.selectedAssetGroupList);
                    }
                }

                this.primaryNavigationMappingFilter.assetCategoryId =[];
                if(result.data[0].results.assetCategory.length>0){

                    this.selectedAssetCategory = [];

                    for(let i = 0 ;i < result.data[0].results.assetCategory.length;i++){
                        
                        let obj={

                            'id':result.data[0].results.assetCategory[i].assetCategoryId,
                            'itemName':result.data[0].results.assetCategory[i].assetCategoryName,

                        }

                        this.primaryNavigationMappingFilter.assetCategoryId.push(obj.id)
                        this.selectedAssetCategory.push(obj);  
                        this.mapAssetCategoryArrayTemp = this.selectedAssetCategory;
                    }
                        
                    this.createCarouselForm.controls['mapAssetCategory'].setValue(this.selectedAssetCategory);
                }

                if(result.data[0].results.primaryNavigation.length>0){

                    this.selectedprimaryNavigationList = [];
                    
                    for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                        let obj={
                        'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                        'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                        }

                        this.menuCategoryMappingFilter.primaryNavigationId.push(obj.id);
                        this.selectedprimaryNavigationList.push(obj);
                        
                        this.mapPrimaryNavigationArrayTemp = this.selectedprimaryNavigationList;
                        this.createCarouselForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
    
                    }
                }
                    
    
                this.selectedmenuCategoryList=[]
                result.data[0].results.menuCategory.forEach(element => {
                    
                    let obj={
                        'id':element.menuCategoryId,
                        'itemName':element.menuCategoryName,
                        }
                     
                 this.assetGroupMappingFilter.menuCategoryId.push(obj.id);
    
                        this.selectedmenuCategoryList.push(obj);
                        this.mapMenuCategoryArrayTemp = this.selectedmenuCategoryList;
                });
    
                this.createCarouselForm.controls['mapMenuCategory'].setValue(this.selectedmenuCategoryList);
    
                this.selectedpageCategoryList=[]
                result.data[0].results.pageCategory.forEach(element => {
    
                    let obj={
                        'id':element.pageCategoryId,
                        'itemName':element.pageCategoryName,
                        }
    
                    this.selectedpageCategoryList.push(obj);
                    this.selectedpageCategoryList = this.removeDuplicates(this.selectedpageCategoryList,'id');

                });
    
                this.createCarouselForm.controls['mapPageCategory'].setValue(this.selectedpageCategoryList);

                // this.loader =  false;
                this.commonService.changeloader(false);
                this.loader = false;


              }, 300);
    
        
        })
    }

    postCallCarousel(){

      //  this.createCarouselForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
        // this.loader=true;

        this.createCarouselForm.controls['langId'].setValue(localStorage.getItem('languageId'))

        this.commonService.changeloader(true);

            if( this.update == false ) {

                delete this.createCarouselForm.value.id;
                this.carouselService.insertCarousel(this.createCarouselForm.value)
                .subscribe((result:any)=>{
                    // this.loader=false;
                    this.commonService.changeloader(false);
                   
                    //this.router.navigate(['page/carousel/list']);
                    this.toastrService.success('Carousel Created Successfully.');
                    
                    //New Code for right side for from
                    this.update = false;
                    this.updateSend.emit();
                    // this.selectedItems= [];
                    this.resetForm();
                    this.createCarouselSubmitted = false;
                });
    
            }
    
            if(this.update){


                console.log(">>>>this.id",this.id);
                this.createCarouselForm.controls['id'].setValue(this.id);
                this.carouselService.updateCarousel(this.createCarouselForm.value)
                .subscribe((result:any)=>{
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    
                    this.toastrService.success('Carousel Updated Successfully.');
                    //this.router.navigate(['page/carousel/list']);
                    //console.log('<====result result result============>',result);
                    
                    //New Code for right side for from
                    this.update = false;
                    this.updateSend.emit();
                    // this.selectedItems= [];
                    this.resetForm();
                    this.createCarouselSubmitted = false;
                    
                });
            }

    }

    //pravin code start
    getGeoZoneInfo(){

        let obj={ } 

        this.productConfigurationService.getAllGeoZone(this.geoZoneObj,this.start,this.limit)
        .subscribe((result:any)=>{
            
            this.dropdownList = [];
            // this.geoZoneList = [];
            if(result.data.data.length > 0){

                if(this.isSearch == true){
                    this.isSearch= false;
                    this.geoZoneList = [];
                }
                    
                this.dropdownList = result.data.data;
    
                for (let geoZoneObj of this.dropdownList) {
    
                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });

                    this.geoZoneList = this.removeDuplicates(this.geoZoneList,'id')
    
                }
            }
            
        }) 
    }

    fetchMoreGetGeoZone(event){

        if ( ((event.endIndex >= this.geoZoneList.length - 2)) && (this.geoZoneList.length >=8)) {

            this.start = this.start + 10;

            this.getGeoZoneInfo();  

        }
    }

    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }


    mapMenuCategoryArrayTemp = [];
    getGlobalMenuCategory(filterObj){


        this.commonService.getGlobalMenuCategory(this.menuCategoryMappingFilter,this.startCategory,10).subscribe((result:any)=>{

            // this.menuCategoryList = [];
            
            // let mapMenuCategoryArrayTemp = [];
            let mapMenuCategoryArray = this.createCarouselForm.value.mapMenuCategory;

            if(result.data.data.length > 0){

                this.searchDecouncer$.next(null);

                if(this.isSearch == true){

                    this.isSearch = false;
                    this.menuCategoryList = [];

                }
                       
                for (let menuCategoryList of result.data.data) {
   
                    mapMenuCategoryArray.forEach(element => {

                        if(element.id == menuCategoryList.menuCategoryId) {
                            this.mapMenuCategoryArrayTemp.push(element); 
                            this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp,'id')   
                            this.createCarouselForm.controls['mapMenuCategory'].setValue(this.mapMenuCategoryArrayTemp); 

                        }

                    });

                    if(menuCategoryList.menuCategoryName!=''){
                    
                        this.menuCategoryList.push(
                        {
                            'id': menuCategoryList.menuCategoryId,
                            'itemName': menuCategoryList.menuCategoryName
                        });

                       this.menuCategoryList = this.removeDuplicates(this.menuCategoryList,'id') 
                    } 
                }
            } 

        });
    }

    fetchMoreGetMenuCategory(event){

        if ( ((event.endIndex >= this.menuCategoryList.length - 2)) && (this.menuCategoryList.length >=8)) {

            this.startCategory = this.startCategory + 10;

            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);  

        }
    }
  
    mapAssetGroupArrayTemp:any=[];
    getGlobalAssetGroup(filterObj){

        this.commonService.getGlobalAssetGroup(this.assetGroupMappingFilter,this.startGroup,10).subscribe((result:any)=>{

            // this.assetGroupList = [];
            // let mapAssetGroupArrayTemp = [];
            let mapAssetGroupArray = this.createCarouselForm.value.mapAssetGroup;
            
            if(result.data.data.length > 0){     

                this.searchDecouncer$.next(null);

                if(this.isSearch == true){

                    this.isSearch = false;
                    this.assetGroupList = [];

                }

                for (let assetGroupList of result.data.data) {

                    console.log("assetGroupList>>>",this.assetGroupList);
   
                    mapAssetGroupArray.forEach(element => {

                        if(element.id == assetGroupList.assetGroupId) {

                            this.mapAssetGroupArrayTemp.push(element); 
                            this.mapAssetGroupArrayTemp = this.removeDuplicates(this.mapAssetGroupArrayTemp,'id')
                            this.createCarouselForm.controls['mapAssetGroup'].setValue(this.mapAssetGroupArrayTemp);
                    
                        }
                    });

                    if(assetGroupList.assetGroupName!=''){
                    
                        this.assetGroupList.push(
                        {
                            'id': assetGroupList.assetGroupId,
                            'itemName': assetGroupList.assetGroupName
                        });

                        this.assetGroupList = this.removeDuplicates(this.assetGroupList,'id')
                    } 




                }
            } 

        });
    }

    fetchMoreGetAssetGroup(event){
        
        if ( ((event.endIndex >= this.assetGroupList.length - 2)) && (this.assetGroupList.length >=8)) {

            this.startGroup = this.startGroup + 10;

            this.getGlobalAssetGroup(this.assetGroupMappingFilter);  

        }
    }
  

    mapProductTypeArrayTemp:any =[];
    getProductTypeInfo(){

        this.commonService.getGlobalProductType(this.productTypeMappingFilter,this.startProduct,10).subscribe((result:any)=>{

            this.ProducTypeDropdownList = [];
            // this.producTypeList = [];

            let mapProductTypeArrayTemp = [];
            let mapProductTypeArray = this.createCarouselForm.value.mapProductType;

            if(result.data.data.length > 0){  
                this.searchDecouncer$.next(null);

                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.producTypeList = [];

                }

                this.ProducTypeDropdownList = result.data.data;
                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                      mapProductTypeArray.forEach(element => {

                        if(element.id == ProducTypeLists.productTypeId) {

                            this.mapProductTypeArrayTemp.push(element);
                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp,'id')
                            this.createCarouselForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);  

                        }
                        
                    });

                    if(ProducTypeLists.productTypeName!=''){
                            
                        this.producTypeList.push(
                            {
                                'id': ProducTypeLists.productTypeId,
                                'itemName': ProducTypeLists.productTypeName
                            });
                        } 
                        
                  this.producTypeList = this.removeDuplicates(this.producTypeList,'id')
                }

                
            } 
        });
      
    }

    startProduct: any = 0;
    fetchMoreGetProductType(event){
        
        if ( ((event.endIndex >= this.producTypeList.length - 2)) && (this.producTypeList.length >=8)) {

            this.startProduct = this.startProduct + 10;

            this.getProductTypeInfo();  

        }
    }

    mapPrimaryNavigationArrayTemp:any=[];
    getNavigationInfo(filterObj){

        this.commonService.getGlobalPrimaryNavigation(this.primaryNavigationMappingFilter,this.startNaigation,10).subscribe((result:any)=>{

            // let mapPrimaryNavigationArrayTemp = [];
            let mapPrimaryNavigationArray = this.createCarouselForm.value.mapPrimaryNavigation;

            if(result.data.data.length > 0){ 

                this.searchDecouncer$.next(null);

                if(this.isSearch == true){

                    this.isSearch = false;
                    this.primaryNavigationList = [];

                }

                for (let primaryNavigationLists of result.data.data) {
   
                    mapPrimaryNavigationArray.forEach(element => {

                        if(element.id == primaryNavigationLists.primaryNavigationId) {

                            this.mapPrimaryNavigationArrayTemp.push(element);
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp,'id')  
                            this.createCarouselForm.controls['mapPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);
                   
                        }
                      
                    });

                    if(primaryNavigationLists .primanyNavigationName!= null){

                        this.primaryNavigationList.push(
                            {
                                'id': primaryNavigationLists.primaryNavigationId,
                                'itemName': primaryNavigationLists.primanyNavigationName
                            });

                        this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList,'id')

                    } 
                }

            } 

        })
    }

    startNaigation:any = 0;

    fetchMoreGetNavigation(event){

        if ( ((event.endIndex >= this.primaryNavigationList.length - 2)) && (this.primaryNavigationList.length >=8)) {

            this.startNaigation = this.startNaigation + 10;
            this.getNavigationInfo(this.primaryNavigationMappingFilter);  

        }
    }

    getGlobaltrailervideo(){
        
        this.commonService.getGlobalTrailerVideo(this.filterTrailer,this.startTrailerVideo,10).subscribe((result:any)=>{
            
            // this.trailerVideoList = [];
            
            if(result.data.data.length > 0){   

                this.searchDecouncer$.next(null);

                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.trailerVideoList = [];

                }

                for (let trailerVideoLists of result.data.data) {
   
                    if(trailerVideoLists.assetName!=''){
                    
                        this.trailerVideoList.push(
                        {
                            'id': trailerVideoLists.assetId,
                            'itemName': trailerVideoLists.assetName
                        });
                    
                    this.trailerVideoList = this.removeDuplicates(this.trailerVideoList,'id')
                  }
               } 
           }

        });
        
    }

    

    startTrailerVideo = 0;
    filterTrailer = {};
    fetchMoreGettrailer(event){

        if ( ((event.endIndex >= this.trailerVideoList.length - 3)) && (this.trailerVideoList.length >=7)) {

            this.startTrailerVideo = this.startTrailerVideo + 10;
            this.getGlobaltrailervideo();  

        }
    }
     
    mapAssetCategoryArrayTemp = [];
    getGlobalAssetCategory(filterObj){

        this.commonService.getGlobalAssetCategory(this.assetCategoryMappingFilter,this.startAssetCategory,10).subscribe((result:any)=>{

            // this.assetCategoryList = []; 
            // let mapAssetCategoryArrayTemp = [];
            let mapAssetCategoryArray = this.createCarouselForm.value.mapAssetCategory;
            
            this.searchDecouncer$.next(null);

            if(this.isSearch == true){
                this.isSearch = false;
                this.assetCategoryList = [];
            }


            if(result.data.data.length > 0){    

                for (let assetCategoryList of result.data.data) {
   
                    mapAssetCategoryArray.forEach(element => {

                        if(element.id == assetCategoryList.mapAssetCategory) {

                            this.mapAssetCategoryArrayTemp.push(element);
                            this.assetCategoryList = this.removeDuplicates(this.assetCategoryList,'id')  
                            this.createCarouselForm.controls['mapAssetCategory'].setValue(this.mapAssetCategoryArrayTemp);  
                 
                        }
                        
                    });

                    if(assetCategoryList.assetCategoryName!=''){
                    
                        this.assetCategoryList.push(
                        {
                            'id': assetCategoryList.assetCategoryId,
                            'itemName': assetCategoryList.assetCategoryName
                        });

                    } 
                }
            } 
        });
    }

    startAssetCategory = 0;
    fetchMoreGetAssetCategory(event){

        if ( ((event.endIndex >= this.assetCategoryList.length - 2)) && (this.assetCategoryList.length >=8)) {

            this.startAssetCategory = this.startAssetCategory + 10;
            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);  

        }
    }
  
    filterPage:any={};

    getglobalPageCategory(){

        this.commonService.getglobalPageCategory(this.filterPage,this.startPageCategory,10).subscribe((result:any)=>{

            // this.pageCategoryList = []; 
            
            if(result.data.data.length > 0){
                
                this.searchDecouncer$.next(null);

                for (let pageCategoryObj of result.data.data) {
   
                    if(this.isSearch == true){

                        this.isSearch = false;
                        this.pageCategoryList = [];

                    }

                    if(pageCategoryObj.pageCategoryName!=''){
                    
                        this.pageCategoryList.push(
                        {
                            'id': pageCategoryObj.pageCategoryId,
                            'itemName': pageCategoryObj.pageCategoryName
                        });

                        this.pageCategoryList = this.removeDuplicates(this.pageCategoryList,'id');

                    } 
                }
            } 
        }); 
    }


    startPageCategory:any = 0;
    fetchMoreGetPageCategory(event){
        
        if ( ((event.endIndex >= this.pageCategoryList.length - 2)) && (this.pageCategoryList.length >=8)) {

            this.startPageCategory = this.startPageCategory + 10;
            this.getglobalPageCategory();  

        }
    }

    assetList:any=[];
    getAllAsset(){
 
        
        this.assetService.getAllAsset(this.filterMapAsset,this.startMapAsset,10)
        .subscribe((result:any)=>{

            
            if(result.data.data.length > 0){  
                
                if(this.isSearch == true){

                    this.isSearch = false;
                    this.assetList = [];

                }

                for (let trailerVideoLists of result.data.data) {
    
                    if(trailerVideoLists.title!='')
                        this.assetList.push(
                        {
                            'id': trailerVideoLists.id,
                            'itemName': trailerVideoLists.title
                        });

                    this.assetList = this.removeDuplicates(this.assetList,'id')
                }
            } 
        }) 
        
    }


    startMapAsset = 0;
    filterMapAsset:any = {};
    fetchMoreGetMapAsset(event){
  
        if ( ((event.endIndex >= this.assetList.length - 2)) && (this.assetList.length >=7)) {

            this.startMapAsset = this.startMapAsset + 10;

            this.getAllAsset();  

        }
    }


    //file upload
    selectFile(e , i){
        
        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePath[i].index = i;
        this.uploadImgFilePath[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePath[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePath[i].fileName =  e.target.files[0].name; 
        this.uploadImgFilePath[i].fileType = e.target.files[0].type;

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].url=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';
    
    }

    //Pravin code mulitple from
    createItem(): FormGroup {

        this.confAsset.push({
            
            'selectedconfAssetList':[],
            'selectedconfAssetCategoryList':[],
            'selectedconfAssetGroupList':[],
            'selectedconfMenuCategoryList':[],
            'selectedconfPrimaryNavigationList':[],
            'selectedconfTrailerVideoList':[],
            'selectedconfCastAndCrew':[]

      }) 

        return this.formBuilder.group({        
            'caption':[''],
            'carouselType':[''],
            'clickThrough':[''],
            'confAsset':[[]],
            'confAssetCategory':[[]],
            'confCastId':[[]],
            'confTrailerVideo':[[]],
            'confAssetGroup':[[]],
            'confMenuCategory':[[]],
            'confPrimaryNavigation':[[]],
            'confCastAndCrew':[[]],
            'confDisplayIndexing':['',Validators.pattern(displayIndexingPattern)],
            'description':[''],
            'isVisible':[''],
            'dtypeId':['',Validators.required],
            'fileName':[''],
            'filePath':[''],
            'fileType':[''],
            'isButtonRequired':[''],
            'label':['',Validators.required],
        });
    }

   //pravin code start
    updateItem(objProductDeviceTypeConfigure, i): FormGroup { 
        
        if(objProductDeviceTypeConfigure.dtypeId === 4){
            
            this.all = true;
        }

        let confAsset = objProductDeviceTypeConfigure.confAsset
        let confAssetCategory = objProductDeviceTypeConfigure.confAssetCategory
        let confAssetGroup = objProductDeviceTypeConfigure.confAssetGroup
        let confMenuCategory = objProductDeviceTypeConfigure.confMenuCategory
        let confPrimaryNavigation = objProductDeviceTypeConfigure.confPrimaryNavigation
        let confTrailerVideo= objProductDeviceTypeConfigure.confTrailerVideo
        let confCastId= objProductDeviceTypeConfigure.confCastId
        

        this.confAsset[i].selectedconfAssetList = [];

        if(objProductDeviceTypeConfigure.confAssetName!=null){

                this.confAsset[i].selectedconfAssetList.push({
                    'id':confAsset,
                    'itemName':objProductDeviceTypeConfigure.confAssetName
                });

         }


        this.confAsset[i].selectedconfCastAndCrew = [];
        if(objProductDeviceTypeConfigure.confCastIdName!=null){
            this.confAsset[i].selectedconfCastAndCrew.push({
                'id':confCastId,
                'itemName':objProductDeviceTypeConfigure.confCastIdName
            });
        }
      

        this.confAsset[i].selectedconfAssetCategoryList = [];
        if(objProductDeviceTypeConfigure.confAssetCategoryName!=null){
            this.confAsset[i].selectedconfAssetCategoryList.push({
                'id':confAssetCategory,
                'itemName':objProductDeviceTypeConfigure.confAssetCategoryName
            });
        }
     

        this.confAsset[i].selectedconfAssetGroupList = [];

        if(objProductDeviceTypeConfigure.confAssetGroupName!=null){

            this.confAsset[i].selectedconfAssetGroupList.push({
                'id':confAssetGroup,
                'itemName':objProductDeviceTypeConfigure.confAssetGroupName
            });
        }

        this.confAsset[i].selectedconfMenuCategoryList = [];

        if(objProductDeviceTypeConfigure.confMenuCategoryName!=null){

            this.confAsset[i].selectedconfMenuCategoryList.push({
                'id':confMenuCategory,
                'itemName':objProductDeviceTypeConfigure.confMenuCategoryName
            });
        }

        this.confAsset[i].selectedconfPrimaryNavigationList = [];

        if(objProductDeviceTypeConfigure.confPrimaryNavigationName!=null){

            this.confAsset[i].selectedconfPrimaryNavigationList.push({
                'id':confPrimaryNavigation,
                'itemName':objProductDeviceTypeConfigure.confPrimaryNavigationName
            });
        }

        this.confAsset[i].selectedconfTrailerVideoList = [];

        if(objProductDeviceTypeConfigure.confTrailerVideoName!=null){

            this.confAsset[i].selectedconfTrailerVideoList.push({
                'id':confTrailerVideo,
                'itemName':objProductDeviceTypeConfigure.confTrailerVideoName
            });

         }



      

        // this.assetList.forEach(list =>{

        //     if(list.id == confAsset){
        //         objProductDeviceTypeConfigure.confAsset = list ;
        //         this.confAsset[i].selectedconfAssetList.push(list);
             
        //     }

        // })


        // this.casteAndCrewList.forEach(list =>{

        //     if(list.id == confCastId){

        //         objProductDeviceTypeConfigure.confCastId = list ;
        //         this.confAsset[i].selectedconfCastAndCrew.push(list);
             
        //     }

        // })

        // this.confAsset[i].selectedconfAssetCategoryList = [];

        // this.assetCategoryList.forEach(list =>{

        //     if(list.id == confAssetCategory){
        //         objProductDeviceTypeConfigure.confAssetCategory = list ;
        //         this.confAsset[i].selectedconfAssetCategoryList.push(list);
              
        //     }
            
        // })

        // this.confAsset[i].selectedconfAssetGroupList = [];

        // this.assetGroupList.forEach(list =>{

        //     if(list.id == confAssetGroup){
        //         objProductDeviceTypeConfigure.confAssetGroup = list ;
        //         this.confAsset[i].selectedconfAssetGroupList.push(list);
               
        //     }
            
        // })

        // this.confAsset[i].selectedconfMenuCategoryList = [];

        // this.menuCategoryList.forEach(list =>{

        //     if(list.id == confMenuCategory){
        //         objProductDeviceTypeConfigure.confMenuCategory = list ;
        //         this.confAsset[i].selectedconfMenuCategoryList.push(list);
               
        //     }
            
        // })

        // this.confAsset[i].selectedconfPrimaryNavigationList=[];

        // this.primaryNavigationList.forEach(list =>{

        //     if(list.id == confPrimaryNavigation){

        //         objProductDeviceTypeConfigure.confPrimaryNavigation = list ;
        //         this.confAsset[i].selectedconfPrimaryNavigationList.push(list);
                
        //     }
            
        // })


        setTimeout(()=>{

            console.log("123>>>",this.trailerVideoList);
            console.log("confTrailerVideo>>>",confTrailerVideo);

            // this.confAsset[i].selectedconfTrailerVideoList = [];

            // this.trailerVideoList.forEach(list =>{
    
            //     if(list.id == confTrailerVideo){

            //         console.log("confTrailerVideo>>>",confTrailerVideo);
            //         console.log("id>>>",list.id);

    
            //         objProductDeviceTypeConfigure.confTrailerVideo = list ;
            //         this.confAsset[i].selectedconfTrailerVideoList.push(list);
                   
            //     }
    
            // })

        },1000)


        objProductDeviceTypeConfigure.confAsset

        return this.formBuilder.group({
            'caption':objProductDeviceTypeConfigure.caption,
            'carouselType':objProductDeviceTypeConfigure.carouselType,
            'clickThrough':objProductDeviceTypeConfigure.clickThrough,

            // 'confDisplayIndexing':objProductDeviceTypeConfigure.confDisplayIndexing,
            // 'confAsset':objProductDeviceTypeConfigure.confAsset,
            // 'confAssetCategory':this.confAsset[i].selectedconfAssetCategoryList,
            // 'confTrailerVideo':this.confAsset[i].selectedconfTrailerVideoList,
            // 'confAssetGroup':objProductDeviceTypeConfigure.confAssetGroup,
            // 'confMenuCategory':objProductDeviceTypeConfigure.confMenuCategory,
            // 'confCastId':objProductDeviceTypeConfigure.confCastId,
            // 'confPrimaryNavigation':objProductDeviceTypeConfigure.confPrimaryNavigation,

            
            // 'confAsset' : objProductDeviceTypeConfigure.confAsset,
            // 'confAssetCategory' : objProductDeviceTypeConfigure.confAssetCategory,
            // 'confAssetGroup' : objProductDeviceTypeConfigure.confAssetGroup,
            // 'confMenuCategory': objProductDeviceTypeConfigure.confMenuCategory,
            // 'confPrimaryNavigation': objProductDeviceTypeConfigure.confPrimaryNavigation,
            // 'confTrailerVideo': objProductDeviceTypeConfigure.confTrailerVideo,
            // 'confCastId': objProductDeviceTypeConfigure.confCastId,
            
            'confDisplayIndexing':objProductDeviceTypeConfigure.confDisplayIndexing,
            'confAsset':this.confAsset[i].selectedconfAssetList,
            'confAssetCategory':this.confAsset[i].selectedconfAssetCategoryList,
            'confTrailerVideo':this.confAsset[i].selectedconfTrailerVideoList,
            'confAssetGroup':this.confAsset[i].selectedconfAssetGroupList,
            'confMenuCategory':this.confAsset[i].selectedconfMenuCategoryList,
            'confCastId':this.confAsset[i].selectedconfCastAndCrew,
            'confPrimaryNavigation':this.confAsset[i].selectedconfPrimaryNavigationList,

            'description':objProductDeviceTypeConfigure.description,
            'isVisible':objProductDeviceTypeConfigure.isVisible,
            'dtypeId':[objProductDeviceTypeConfigure.dtypeId,Validators.required],
            'fileName':objProductDeviceTypeConfigure.fileName,
            'filePath':objProductDeviceTypeConfigure.filePath,
            'fileType':objProductDeviceTypeConfigure.fileType,
            'isButtonRequired':objProductDeviceTypeConfigure.isButtonRequired,
            'label':[objProductDeviceTypeConfigure.label,Validators.required],
            
        });
       
    }

    onDtypeId(){
        this.All=false;
        // this.Web=false;
        // this.Android=false;
        // this.iOS=false;
    }

    addItem(): void {

            if(!this.multipleDeviceForm.valid){  

                this.createCarouselSubmitted = true;
                return
    
            }
  
            if(this.multipleDeviceForm.value.devices.length >= 4){
    
                this.createCarouselSubmitted = true;
                return;
    
            }

            this.img.push({
                url:'',
            })

       
        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let obj = {
                'index':'',
                'deviceType':'',
                'selectedFiles':'',
                'fileName': '',
                'filePath': "",
                'fileType':''
            }

           this.uploadImgFilePath.push(obj)
        }

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;
       
        this.uploadImgFilePath[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePath[i].index = 1;
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        this.uploadImgFilePath.splice(index, 1);
        arrayControl.removeAt(index);
        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.confAsset[index].selectedconfAssetList=[],
        this.confAsset[index].selectedconfAssetCategoryList=[];
        this.confAsset[index].selectedconfAssetGroupList=[];
        this.confAsset[index].selectedconfMenuCategoryList=[];
        this.confAsset[index].selectedconfPrimaryNavigationList=[];
        this.confAsset[index].selectedconfTrailerVideoList=[];
      

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){

        console.log('event>>',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){

        this.deleteDeviceTypeIndex = i;
    }

    isDeleteImage(event){

        console.log('isDeleteImageAction event = ',event)
        if(event=='Cancel'){

            this.isDeleteImageAction = false;
        }
        
        if(event=='Yes'){

            this.isDeleteImageAction=false;
            
            delete this.img[this.deleteDeviceTypeIndex].url;
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].deviceType = "";
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].selectedFiles = "";
            
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileName="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].filePath="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileType="";
        }

    }

    isDeleteImageYes(i){

        this.deleteDeviceTypeIndex = i;
    }
     
    //New Code for right side for from
    onContentReadyToReview(){
        // console.log("is valid onContentReadyToReview################################################ ",this.createCarouselForm.valid);
        // console.log("is valid this.multipleDeviceForm################################################ ",this.multipleDeviceForm.valid);
        if(this.createCarouselForm.valid==true && this.multipleDeviceForm.valid==true){
            this.contentReadyToReview = true;
            this.shareMassage = 'review';
            return;
        }
        else{
            this.saveCarousel();
        }
    }
    // is Content Ready To Review Received  


    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

      //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
        
        }
        //New Code end

        if(event == 'Yes'){

            this.createCarouselForm.controls['contentState'].setValue(['Completed']);
            // this.postCallCarousel();
            this.saveCarousel();
            this.onImgInfoTabClick();
        
        }
        if(event == 'Cancel'){

            this.createCarouselForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCallCarousel();
            this.saveCarousel();
            this.onImgInfoTabClick();
            
        }


   
    }

    

    resetForm(){

        this.confAsset = [];

        this.mapAssetCategoryArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp = [];
        this.mapAssetGroupArrayTemp = [];
        this.mapAssetCategoryArrayTemp = [];
        this.mapMenuCategoryArrayTemp = [];

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);

        }
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {

        //     arrayControl.removeAt(0);

        // }

        this.createCarouselForm = this.formBuilder.group({
            isActive : ["1"],
            langId: ["1"],
            companyId :[ ""],
            title : ["",Validators.compose([Validators.required])],
            sameForAllZone :[''],
            sameForAllProductType :[''],
            displayIndexing :['',Validators.pattern(displayIndexingPattern)],
            displayType : [''],
            orderBy :[""],
            isPrimaryNavigationVisible:[''],
            isMenuCategoryVisible :[''],
            isAssetGroupVisible :[''],
            isAssetCategoryVisible :[''],
            isPageCategoryVisible : [''],
            exclusionText : [""],
            description : [""],
            isVisible : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            sorting :[""],
            mapGeoZone:[[], Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapAssetCategory:[[]],
            mapPageCategory:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved']

        })

        this.uploadImgFilePath = [];
        
        //reset multiselect list
        this.selectedItems=[];

        //reset device type image
        this.img = [];

        this.img.push({
            url:'',
        })

        this.update = false;

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });

        this.updateSend.emit();
        this.showCreateNewButton('show');
        
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

    eventValue:any ='dff';
    activeSearchBox ='';
    startMenuCategory:any =[];

    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
            return;

        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;
        this.eventValue=event.target.value;

        if(info == 'mapAsset'){

            if(event.target.value!=''){

                this.filterMapAsset.keyword = event.target.value;
                this.startMapAsset = 0;
                this.searchDecouncer$.next(event.target.value);

         }else {

             this.filterMapAsset = {};
             // this.trailerVideoList = [];
             this.startMapAsset = 0;
             this.searchDecouncer$.next(event.target.value);

         }

        }  

        if(info == 'trailer'){

            this.filterTrailer={};
            
            if(event.target.value!=''){

                this.filterTrailer = obj = { 'assetName': event.target.value };
                // this.mapReviewList = [];
        
                }else {
    
                    // this.mapReviewList = [];
                    this.filterTrailer = obj = { } 
                }
    
                this.startTrailerVideo = 0;
    
                this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'review'){

            if(event.target.value!=''){

            this.reviewFilter = obj = { 'reviewFirstName': event.target.value };
            // this.mapReviewList = [];
    
            }else {

                // this.mapReviewList = [];
                this.reviewFilter = obj = { } 
            }

            this.startReview = 0;
            this.reviewScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'menuCategory'){

            if(event.target.value!=''){

                this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                // this.primaryNavigationList = [];
                this.startMenuCategory = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

                 this.menuCategoryMappingFilter = {};
                // this.primaryNavigationList = [];
                this.startMenuCategory = 0;
                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }
        }
        
        if(info == 'genre'){

            if(event.target.value!=''){

                this.genreFilter = obj = { 'genreName': event.target.value };
                // this.mapGenreList= [];

    
            }else {

                this.genreFilter = obj = { };
                // this.mapGenreList= [];
            }

            this.startGenre = 0;
            this.reviewScrollCount = 0;
            this.searchDecouncer$.next(event.target.value);

        }


        if(info == 'certification'){

            if(event.target.value!=''){

                obj = { 'certificationName': event.target.value };
                this.mapCertificatList=[];
                this.certificateFilter = obj;
                this.startCertificate = 0;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                this.certificateFilter = obj = { };
            }

            this.startCertificate = 0;
            this.certificateFilter = obj ;

            this.mapCertificatList=[];
            this.searchDecouncer$.next(event.target.value);
        }  

        if(info == 'geoZone'){
            

            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                // this.geoZoneList = [];      
                this.geoZoneObj = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.geoZoneObj = obj ;
                // this.geoZoneList = [];      
                this.start = 0;

                this.searchDecouncer$.next(event.target.value);

            }
  
        }  

        if(info == 'productType'){

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                // this.producTypeList = [];
                this.startProduct = 0;
                this.productScrollCount = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                this.productTypeMappingFilter.productTypeName;
                // this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);    

            }

            
        }

        if(info == 'navigation'){

            if(event.target.value!=''){

                this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                // this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {
 
              var productTypeId = this.primaryNavigationMappingFilter.productTypeId;
              this.primaryNavigationMappingFilter = {};
              this.primaryNavigationMappingFilter.productTypeId = productTypeId;
              this.primaryNavigationList = [];
              this.startPrimary = 0;

                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }

        } 
 
        if(info == 'category'){

            if(event.target.value!=''){

                   this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                //    this.menuCategoryList = [];
                   this.startCategory = 0;
                   //this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                   this.searchDecouncer$.next(event.target.value);

            }else {

              var primaryNavigationId =  this.menuCategoryMappingFilter.primaryNavigationId;

              this.menuCategoryMappingFilter  = {};
              this.menuCategoryMappingFilter.primaryNavigationId = primaryNavigationId;

                // this.menuCategoryList = [];
                this.startCategory = 0;
                // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetGroup'){

            if(event.target.value!=''){

                   this.assetGroupMappingFilter.assetGroupName = event.target.value;
                //    this.assetGroupList = [];
                //    this.startGroup = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

              var menuCategoryId =  this.assetGroupMappingFilter.menuCategoryId
              this.assetGroupMappingFilter ={};
              this.assetGroupMappingFilter.menuCategoryId = menuCategoryId;
              this.startGroup = 0;
              this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetCategory'){

            if(event.target.value!=''){

                   this.assetCategoryMappingFilter.assetCategoryName = event.target.value;
                //    this.assetCategoryList = [];
                   this.startCategory = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

              var assetGroupId = this.assetCategoryMappingFilter.assetGroupId;
              this.assetCategoryMappingFilter = {};
              this.assetCategoryMappingFilter.assetGroupId = assetGroupId;
            //   this.assetCategoryList = [];
              this.startCategory = 0;
              this.searchDecouncer$.next(event.target.value);

            }

        }  
        
        if(info =='pageCategory'){

            if(event.target.value!=''){

                this.filterPage.pageCategoryName = event.target.value;
                // this.pageCategoryList = [];
                this.startPageCategory = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterPage = {};
                //  this.pageCategoryList = [];
                this.startPageCategory = 0;
                this.searchDecouncer$.next(event.target.value);

            }
        }

        
        if(info =='cast'){

            if(event.target.value!=''){

                // this.filterPage.pageCategoryName = event.target.value;
                this.filterCast.castFirstName = event.target.value;
                // this.pageCategoryList = [];
                this.startCastAndCrew = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

                this.filterCast = {};
                //  this.pageCategoryList = [];
                this.startCastAndCrew = 0;
                this.searchDecouncer$.next(event.target.value);

            }
        }

      
            
    }

    

    startGroup:any = 0;
    filterCast:any={};
    onOpen(info){

           this.activeSearchBox = info;

           if(this.activeSearchBox == 'mapAsset'){

                    this.filterMapAsset.mapAsset= undefined;
                    this.filterMapAsset ={};
                    this.assetList = [];
                    // this.trailerVideoList = [];
                    this.startMapAsset = 0;
                    this.getAllAsset();

        }  
           
           if(this.activeSearchBox == 'assetCategory'){

                    var assetGroupId = this.assetCategoryMappingFilter.assetGroupId;
                    this.assetCategoryMappingFilter = {};

                    if(assetGroupId!= undefined){

                        if(assetGroupId.length>0){

                            this.assetCategoryMappingFilter.assetGroupId = assetGroupId;
                        }
    
                    }
                 
                    this.assetCategoryList = [];
                    this.startCategory = 0;
                    this.startAssetCategory = 0;
                    this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
                    
            }

                
            if(info == 'trailer'){

                this.filterTrailer = {};
                this.startTrailerVideo = 0;
                this.filterCast = {};
                this.trailerVideoList = [];

                this.getGlobaltrailervideo();
        
            }

            
           if(this.activeSearchBox == 'cast'){

                this.startCastAndCrew = 0;
                this.filterCast = {};
                this.casteAndCrewList = [];

               this.getAllCasteAndCrew();
            
            }
           
           if(this.activeSearchBox == 'pageCategory'){

            this.startPageCategory = 0;
            this.pageCategoryList = [];
            this.filterPage = {};
                
            this.getglobalPageCategory();       
  
          }  
          
          if(this.activeSearchBox == 'menuCategory'){

            this.startMenuCategory = 0;
            this.startCategory = 0;
            this.menuCategoryList = [];
            this.menuCategoryMappingFilter = {};        
            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);       
  
          }  

            if(this.activeSearchBox == 'assetGroup'){

                // this.assetGroupList=[];
                this.startGroup = 0;
                this.assetGroupMappingFilter = {};

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);

            }

            if(this.activeSearchBox == 'geoZone'){

                this.geoZoneObj = {};
                this.geoZoneList = [];
                this.start =0;
                this.getGeoZoneInfo();       

            }  

            if(this.activeSearchBox == 'productType'){

                this.productTypeMappingFilter.productTypeName= undefined;
                this.producTypeList = [];
                this.productTypeMappingFilter = {};
                this.startProduct = 0;
                this.getProductTypeInfo();
                
            }

            if(this.activeSearchBox == 'navigation'){

                var productTypeId = this.primaryNavigationMappingFilter.productTypeId;
                this.primaryNavigationMappingFilter = {};
                if(productTypeId.length>0){

                    this.primaryNavigationMappingFilter.productTypeId = productTypeId;

                }
                this.primaryNavigationMappingFilter = {};

                this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.startNaigation = 0;
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     

            } 

            if(this.activeSearchBox == 'category'){

                var primaryNavigationId =  this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter  = {};

                if(primaryNavigationId.length>0){

                    this.menuCategoryMappingFilter.primaryNavigationId = primaryNavigationId;

                }
                this.menuCategoryMappingFilter  = {};
                 this.startCategory = 0;
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

            }   


    }

    

    private setupSearchDebouncer(): void {

        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            
            if(this.activeSearchBox == 'mapAsset'){

                this.getAllAsset();

            }

            if(this.activeSearchBox == 'assetGroup'){

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);

            }
            
           if(this.activeSearchBox == 'cast'){

                this.startCastAndCrew = 0;        

               this.getAllCasteAndCrew();
        
           }

            
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo();       
      

            }
            

            
            if(this.activeSearchBox == 'pageCategory'){
                
                this.getglobalPageCategory();       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo();
                
            }
    
            if(this.activeSearchBox == 'navigation'){
    
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     
    
            } 
     
            if(this.activeSearchBox == 'category'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }   
            
            if(this.activeSearchBox == 'menuCategory'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }   

            if(this.activeSearchBox == 'assetCategory'){

                this.getGlobalAssetCategory(this.assetCategoryMappingFilter);

            }
            if(this.activeSearchBox == 'trailer'){

                this.getGlobaltrailervideo();

            }

        });
    }

  private searchDecouncer$: Subject<string> = new Subject();

    startCategory:number = 0;
    startLibrary:number = 0;
    startLanguage:number = 0;
    isSearch:any='';
    geoZoneObj:any={};
    productScrollCount=0;
    classificationFilter:any={};
    startClassification = 0;
    classificationScrollCount = 0;
    reviewFilter:any={};
    startPrimary = 0;
    mapCertificatList=[];
    certificateFilter:any={};
    startCertificate = 0;
    libraryFilter:any={};
    SponsoredScrollCount = 0;
    languageFilter:any={};
    sponsoredFiter:any={};
    
    startGenre = 0;
    genreFilter:any = {};
    startReview = 0;
    startSponsored = 0;
    reviewScrollCount = 0;


}
