import { CommonModule } from '@angular/common';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoaderComponent} from './component/shared/loader/loader.component';
import { ReactiveFormsModule } from '@angular/forms';   
import { AppDisableAfterClickDirective } from './customeDirective/app-disable-after-click.directive';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ConfirmationBoxComponent } from './component/shared/confirmation-box/confirmation-box.component';
import { DatatabelComponent } from './component/shared/datatabel/datatabel.component';
import { AddComponentProductType } from './component/configuration/product_type/add/add/add.component';
import { AddComponentMenuCategory } from './component/navigation/menu_category/add/add.component';
import { AddComponentPrimaryNavigation } from './component/navigation/primary_navigation/add/add/add.component';
import { AddComponentPageCategory } from './component/page/page_category/add/add/add.component';
import { AddComponentCarousel } from './component/page/carousel/add/add/add.component';
import { AddComponentAssetCategory } from './component/assetManagement/asset_category/add/add/add.component';
import { AddComponentAssetGroup } from './component/assetManagement/asset_group/add/add/add.component';
import { AddComponentGenres } from './component/master/genres/add/add/add.component';
import { AddComponentUplynkadserver } from './component/master/uplynkAdServer/add/add/add.component';
import { AddComponentFooterlinks } from './component/master/footer_links/add/add/add.component';
import { AddComponentGeoZone } from './component/master/geo_zone/add/add/add.component';
import { AddComponentCastAndCrew } from './component/master/caste_and_crew/add/add/add.component';
import { AddComponentSponsor } from './component/master/sponsor/add/add/add.component';
import { AddComponentReviews } from './component/master/reviews/add/add/add.component';
import { AddComponentCharacter } from './component/master/character/add/add/add.component';
import { AddComponentNewsCategory } from './component/master/newscategory/add/add/add.component';
import { AddComponentClassification } from './component/master/classification/add/add/add.component';
import { AddComponentL } from './component/master/library/add/add/add.component';
import { AddComponentCertification } from './component/master/certification/add/add/add.component';
import {AddComponentLan} from './component/master/language/add/add/add.component';
import { AddComponentNews } from './component/master/news/add/add/add.component';
import {LanguageselectionBoxComponent} from './component/shared/languageselection-box/languageselection-box.component';
import { AddSubscription } from './component/subscription/add/add/add.component';
import { AddComponentBlogs } from './component/master/blogs/add/add/add.component';
import { AddComponentBlogCategory } from './component/master/blogcategory/add/add/add.component';
import { AddUserComponent } from './component/user_management/users/add/add/add.component';
import { AddComponentRole } from './component/user_management/role/add/add/add.component';
import {AddMrssComponent} from './component/assetManagement/mrss/add/add/add.component';
import {AddMrssPartnerComponent} from './component/assetManagement/mrss_partner/add/add/add.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RouterModule }  from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';
import {AddAssetComponent} from './component/assetManagement/asset/add/add/add.component';
import {AddComponentProfession} from './component/master/profession/add/add/add.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PrivacypolicyComponent } from './component/shared/privacypolicy/privacypolicy.component';
import { PaginationlistComponent } from './component/shared/paginationlist/paginationlist.component';
import { MastermenuComponent } from './component/master/mastermenu/mastermenu.component';

import {ThemepageComponent}from './component/shared/themepage/themepage.component';
import { CKEditorModule } from 'ckeditor4-angular';

import { AddComponentCSAI } from './component/master/clientadserver/add/add/add.component';



@NgModule({

 imports:[RouterModule ,CKEditorModule, NgxEditorModule ,NgxPaginationModule ,ReactiveFormsModule, AngularMultiSelectModule,NgxDatatableModule,CommonModule , FormsModule],

 declarations: [AddAssetComponent ,AddComponentLan,AddComponentCertification,AddComponentL,AddComponentCSAI,MastermenuComponent,AddComponentProfession,ThemepageComponent ,AddMrssPartnerComponent, AddMrssComponent, LanguageselectionBoxComponent ,PrivacypolicyComponent,AddUserComponent, AddComponentRole,  AddComponentNews, AddComponentNewsCategory, AddComponentBlogCategory, AddComponentCharacter, AddComponentReviews, AddComponentSponsor, AddComponentCastAndCrew, AddComponentBlogs, AddComponentFooterlinks, AddSubscription,AddComponentGeoZone, AddComponentGenres, AddComponentAssetGroup ,AddComponentAssetCategory, AddComponentPrimaryNavigation,PaginationlistComponent ,AddComponentProductType ,AddComponentMenuCategory , AddComponentPageCategory, AddComponentCarousel, DatatabelComponent,LoaderComponent,ConfirmationBoxComponent,DatatabelComponent,AppDisableAfterClickDirective, AddComponentUplynkadserver, AddComponentClassification],

 exports: [AddAssetComponent ,AddComponentLan,AddComponentCertification,AddComponentL,AddComponentCSAI,CKEditorModule , MastermenuComponent,AddMrssPartnerComponent, AddComponentProfession ,ThemepageComponent, AddMrssComponent,LanguageselectionBoxComponent,PrivacypolicyComponent, AddUserComponent, AddComponentRole, AddComponentNews, AddComponentNewsCategory, AddComponentBlogCategory, AddComponentCharacter, AddComponentReviews, AddComponentSponsor, AddComponentCastAndCrew, AddComponentBlogs, AddComponentFooterlinks, AddSubscription ,NgxPaginationModule, AddComponentGeoZone, AddComponentGenres, AddComponentAssetGroup, AddComponentAssetCategory, AddComponentPrimaryNavigation,NgxEditorModule ,AddComponentProductType  ,AddComponentMenuCategory , AddComponentPageCategory, AddComponentCarousel, PaginationlistComponent ,RouterModule ,AngularMultiSelectModule ,DatatabelComponent,LoaderComponent,ConfirmationBoxComponent,ReactiveFormsModule,AppDisableAfterClickDirective,NgxDatatableModule,FormsModule,CommonModule,DatatabelComponent, AddComponentUplynkadserver, AddComponentClassification],
 
 schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class SharedModule { }

// AddComponentProductConfiguration
// AddComponentProductConfiguration