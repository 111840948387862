import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CasteAndCrewService {

  
  constructor(private http: HttpClient) { }

    updateCast(postObj:any) : Observable<any> {

      postObj.langId = localStorage.getItem('languageId');
      postObj.langId = undefined;

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateCastUrl, postObj,httpOptions);
    }
          
    insertCast(postObj:any) : Observable<any> {
    
      postObj.langId = localStorage.getItem('languageId');
      postObj.langId = undefined;

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertCastUrl, postObj,httpOptions);
    }    
    
    getCast( id: any) : Observable<any> {
        
        if(id){

        }
        else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getCastUrl+"/" + id);
    }

    getAllCast(filterObj,start,limit){

      filterObj.languageId = localStorage.getItem('languageId');
      filterObj.languageId = undefined;
  
        let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getCastUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllCast)
    }

  deleteCast(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteCastUrl, postObj,httpOptions);
  }
  
}
