import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
// import { ClassificationService } from '../../../../../services/classification/classification.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Loginshared } from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import { CommonService } from '../../../../../services/common_Service/common.service';
import { BlogsService } from '../../../../../services/blogs/blogs.service';

@Component({
  selector: 'app-addBlogCategory',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentBlogCategory implements OnInit {

 isCreateNewShow:boolean = true;

  isUserLoggedIn: boolean = false;
  createBlogCategoryForm:FormGroup;
  errorMessages = errorMessages;
  blogCategorySubmitted:boolean = false;

  blogCategoryList = [];
  dropdownList = [];
  selectedItems = [];
  
  start:number=0;
  limit:number=12;

  roleAsses:any={};

  id:any;
  update=false;

  dropdownSettings={};

  loader:boolean=false;

    contentReadyToReview:boolean = false;
    shareMassage:any;

    applyBlackTheme = localStorage.getItem('boolThemeFlag');


    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.resetForm();

        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
    constructor(private commonService:CommonService, private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private blogsService: BlogsService,private formBuilder:FormBuilder) {
        loginSharedService.sendUserLoggedIn$.subscribe(
        mission => {
            this.isUserLoggedIn = mission;
            let uid = localStorage.getItem('ud');
            if (uid !== undefined && uid != null) {
                this.isUserLoggedIn = true;
            }
        }
        );
    }

    roleConfiguration:any=[];

    ngOnInit() {
                // theme update code start
        
                this.commonService.currentThemelight.subscribe((result:any)=>{
          
                    this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
                    
               })

               this.roleAsses.blogsCEdit = false;
               this.roleAsses.blogsC = false;
               this.roleAsses.blogsCCreate = false;
           
               this.roleConfiguration = SessionHelper.getRole();
           
               this.roleConfiguration.forEach((element , i) => {
           
                     if((element.isView == 1 && element.pageTitle == 'Blogs Category') ){
                         
                         this.roleAsses.blogsC = true;
                     }
                 
                     if((element.isEdit == 1 && element.pageTitle == 'Blogs Category') ){
                         
                         this.roleAsses.blogsCEdit = true;
                     }
                     
                     if((element.isCreate == 1 && element.pageTitle == 'Blogs Category')){
           
                         this.roleAsses.blogsCCreate = true;
                     
                     }
                   })
        
               // theme update code start
    }

    onLoad(id) {

        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        let headerTitle = {
            title:'Master',
            subTitle:'Blog'
        }
        this.commonService.changeHeaderTitle(headerTitle);

        this.createBlogCategoryForm = this.formBuilder.group({
        
            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            // contentState:['In-Progress'],
            // publisherState:['Un-published'],
            // reviewerState:['Un-approved'],
            mapBlog:[[]],
            created_by:[localStorage.getItem('ud')]

        });

        // this.activatedRoute.params.subscribe(params => {
            // this.id = +params['id']; // (+) converts string 'id' to a number

            this.id = id;

            if(this.id != 'null'){
            
                console.log('this.id===>>>',this.id);
            
                this.update=true;
                this.getClassificationInfo(this.id)
            }else{
                this.update=false;        
            }

        // });

        this.dropdownSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
        }

        this.getBlogCategoryInfo();
    }


    get l() { return this.createBlogCategoryForm.controls; }

    onItemSelect(item:any, mappingName){
        console.log("onItemSelect = ",mappingName);

        if(mappingName == 'mapBlog'){
        }
    }
   




  getClassificationInfo(id){
        
    // this.loader=true;
    this.commonService.changeloader(true);

    this.blogsService.getBlogsCategory(id)
    .subscribe((result:any)=>{

        //   this.loader=false;
        this.commonService.changeloader(false);

          console.log("result==========>",result);
          
          this.createBlogCategoryForm.controls['title'].setValue(result.data[0].title);
          this.createBlogCategoryForm.controls['description'].setValue(result.data[0].description);
          this.createBlogCategoryForm.controls['isActive'].setValue(result.data[0].isActive.toString());

        //   this.createBlogCategoryForm.controls['publisherState'].setValue(result.data[0].publisherState);
        //   this.createBlogCategoryForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

        //   if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
        //       this.createBlogCategoryForm.controls['contentState'].setValue(['In-Progress']);
        //   }
        //   else{
        //       this.createBlogCategoryForm.controls['contentState'].setValue(result.data[0].contentState);
        //   }
          // console.log("result==========>",result.data[0].isActive.toString());


          if(result.data[0].results.blog.length>0){

            console.log("result.data[0].results.blog------",result.data[0].results.blog);
            for(let i = 0 ;i < result.data[0].results.blog.length;i++){

                let obj={
                'id':result.data[0].results.blog[i].id,
                'itemName':result.data[0].results.blog[i].title,
                }
                this.selectedItems.push(obj);  
            }
            console.log("this.selectedItems ",this.selectedItems);
            this.createBlogCategoryForm.controls['mapBlog'].setValue(this.selectedItems);

        }
    
    });
  }

  getBlogCategoryInfo(){

    let obj={
    } 
    this.commonService.getglobalBlog()
    .subscribe((result:any)=>{
                
        this.dropdownList = [];
        this.blogCategoryList = [];
        if(result.data.data.length > 0){
                
            this.dropdownList = result.data.data;

            for (let blogCategoryObj of this.dropdownList) {

                this.blogCategoryList.push(
                {
                    'id': blogCategoryObj.blogId,
                    'itemName': blogCategoryObj.blogName
                });

            }
        }

        console.log('this.blogCategoryList',this.blogCategoryList);

        
    }) 
  }

  
   

  savecreateNewBlogCategory(){

    //   this.loader=true;
    this.commonService.changeloader(true);

      this.blogCategorySubmitted = true;

      if(!this.createBlogCategoryForm.valid){
        // this.loader=false;
        this.commonService.changeloader(false);
        return;
      }
    
      if(!this.update){

        if(this.roleAsses.blogsCCreate == false){
            this.toastrService.success('no access to create.');
            this.commonService.changeloader(false);
            return;
        }


        delete this.createBlogCategoryForm.value.id;
        this.blogsService.insertBlogsCategory(this.createBlogCategoryForm.value)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);
            // this.spinnerService.hide();
            // this.router.navigate(['master/blogsCategorys/list']);

            //New Code for right side for from
            this.update = false;
          //  this.updateSend.emit();
            this.resetForm();
            this.blogCategorySubmitted = false;

            this.toastrService.success('Blog Created Successfully.');
        });
      }

      if(this.update){

        if(this.roleAsses.blogsCEdit == false){
            this.toastrService.success('no access to edit.');
            this.commonService.changeloader(false);
            return;
        }


        this.createBlogCategoryForm.controls['id'].setValue(this.id);
        this.blogsService.updateBlogsCategory(this.createBlogCategoryForm.value)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);
            // this.router.navigate(['master/blogsCategorys/list']);

            //New Code for right side for from
            this.update = false;
          //  this.updateSend.emit();
            this.resetForm();
            this.blogCategorySubmitted = false;
            
            this.toastrService.success('Blog Updated Successfully.');          
        });
      }


  }


    //New Code for right side for from
    onContentReadyToReview(){

        this.blogCategorySubmitted = true;

        if(!this.createBlogCategoryForm.valid){
          // this.loader=false;
          this.commonService.changeloader(false);
          this.blogCategorySubmitted = true;
          return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'blogCategory';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            // this.createGeoZoneForm.controls['contentState'].setValue(['Completed']);
            this.savecreateNewBlogCategory();
           
        }
        if(event == 'Cancel'){

            // this.createGeoZoneForm.controls['contentState'].setValue(['In-Progress']);
           // this.savecreateNewBlogCategory();
            
        }
       
    }

  
    resetForm(){

        this.updateSend.emit();

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
        // this.img = [];
        // this.spinnerService.hide();
        // this.uploadImgFilePath=[];
        this.update = false;
        this.blogCategorySubmitted = false;

        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {

        //     arrayControl.removeAt(0);
        // }

        this.createBlogCategoryForm = this.formBuilder.group({
            
            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            // contentState:['In-Progress'],
            // publisherState:['Un-published'],
            // reviewerState:['Un-approved'],
            mapBlog:[[]],
            created_by:[localStorage.getItem('ud')]


        });
        
        //reset multiselect list
        // this.selectedGeoZone=[];
        // this.selectedProductlist=[];

        //reset device type image
        // let obj = {
        //     'index':'',
        //     'deviceType':'',
        //     'selectedFiles':'',
        //     'fileName': '',
        //     'filePath': "",
        //     'fileType':''
        // }

        // this.uploadImgFilePath.push(obj)

        //     this.img.push({
        //         imgUrl:''
        //     })

        this.update = false;

        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });
        
        this.showCreateNewButton('show');

    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
