import { Injectable } from '@angular/core';
import { httpOptions } from '../../configurations/configuration';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UplynkAdServerService {

  constructor(private http: HttpClient) { }

  updateUplynkadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateUplynkadserverUrl, postObj,httpOptions);
  }
        
  insertUplynkadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertUplynkadserverUrl, postObj,httpOptions);
  } 

  getUplynkadserver( id: any) : Observable<any> {
    
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getUplynkadserverUrl+"/" + id);
  }

  getAllUplynkadserver(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllUplynkadserver = environment.apiURL + environment.adminApiUrl + environment.getUplynkadserverUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllUplynkadserver)
  }

  deleteUplynkadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteUplynkadserverUrl, postObj,httpOptions);
  }

}
