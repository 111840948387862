import { Component, OnInit ,Input , Output, EventEmitter} from '@angular/core';
import { ProductConfigurationService } from '../../../../../services/product_configuration/product_configuration.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Loginshared } from '../../../../../services/sharedservices/loginshared.service'
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import {contentType ,assetGroupType ,sorting ,orderby} from '../../../../../configurations/configuration';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ProductTypeService } from '../../../../../services/product_type/product_type.service';
import { errorMessages } from '../../../../../configurations/errorMessages';
import { deviceType } from '../../../../../configurations/configuration';
import {environment} from '../../../../../../environments/environment';
import { CommonService } from '../../../../../services/common_Service/common.service';
import { awsPath } from '../../../../../configurations/configuration';
import { SlicePipe } from '@angular/common';
import {SessionHelper} from '../../../../../services/localstorage'


@Component({
  selector: 'app-addproducttype',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentProductType implements OnInit {

    isCreateNewShow:boolean = true;
    contentReadyToReview:boolean= false;
    shareMassage :any;
    contantIdFlag:boolean = false;
    deviceType = deviceType;

    deviceValue:any={};
    isDeleteActionActive:boolean = false;
    allDevice:boolean= false;
    deviceTypeBackUp:any = [];
    loader:boolean=false;
    // activeDeviceIndex:number = 0;
    isUserLoggedIn: boolean = false;
    createNewProductForm:FormGroup;
    errorMessages = errorMessages;
    createNewProductSubmitted = false;
    dropdownSettings = {};
    deviceTypeSettings = {};
    devicesReqError:any;
    contentType = contentType;
    selectedItems = [];
    geoZoneList = [];
    dropdownList = [];
    start:number=0;
    limit:number=100000;
    toggelId=0;
    All:boolean=false;
    Web:boolean=false;
    Android:boolean=false;
    iOS:boolean=false;
    update=false;
    deviceTypeDublicate = false;
    deviceTypeFull = false;

    deleteDeviceTypeIndexActive:boolean = false;
    
    dTypeDublicateError:any;
    id:any;
    uploadImgFilePath:any=[];
    uploadImgFilePathVertical:any=[];
    uploadImgFilePathHorizontal:any=[];

    uploadImgFilePathHorizontalBackUp:any = [];
    uploadImgFilePathVerticalBackUp:any = [];
    imgActive:any=[];
    img:any=[];


    activeDevice :any ={};
    all:boolean = false;
    android:boolean = false;
    roku:boolean = false;

    //pravin code start
    deviceActiveFrom:FormGroup;
    multipleDeviceForm:FormGroup;
 
    devices: FormArray;
    deviceActive: FormArray;
    //pravin code end 

    refresh:any=false;

    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;

    isDeleteHorizontalImageAction:boolean=false;
    isDeleteVerticalImageAction:boolean=false;

    boolDeviceTypeList:boolean=false;
   
    // list out device.
    deviceList:any = [];
 
    selectedOption:any = '';
     
    // list out device.
    errorMessageDeviceList = ''
     
    applyBlackTheme = localStorage.getItem('boolThemeFlag');
    //  @Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();
    
    editorConfig = {

        "toolbar": [

            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
           
        ]
    }
    
    langId:any;

    //taber
    formTabs:any = {

        productInfo:{

         product:true,
         mapGeoZone:true,
         information:false

        },
        deviceType:{
            configurationType:false,
            configuration:false,
            horizontal:false,
            vertical:false

        }
    }
  
    
    constructor(private commonService:CommonService,private activatedRoute:ActivatedRoute,private productTypeService:ProductTypeService,private toastrService:ToastrService,private spinnerService:Ng4LoadingSpinnerService ,private productConfigurationService:ProductConfigurationService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private formBuilder: FormBuilder ) {
        
        this.commonService.notify.subscribe((result) => {
          this.loader = false;
        })

        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );
        
    }

    @Input() set contentId(value: any) {

        this.toggelId = 0;
     

        this.commonService.currentThemelight.subscribe((result:any)=>{
          
            this.applyBlackTheme=localStorage.getItem('boolThemeFlag');
            console.log("applyBlackTheme>>",this.applyBlackTheme);
       })
        if(value == 'reset'){

            this.resetForm();

        }else {

            this.deviceActiveFrom = this.formBuilder.group({

                deviceActive: this.formBuilder.array([this.createItem()])
    
            });

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
 
    }

    roleAsses:any={};
    roleConfiguration:any =[];


    editCreate:boolean = false;

    ngOnInit() {

        
        this.roleAsses.productType = false;
        this.roleAsses.productTypeEdit = false;
        this.roleAsses.productTypeCreate = false;


        this.roleAsses.configuration = false;
        this.roleAsses.configurationEdit = false;
        this.roleAsses.configurationCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        console.log("roleConfiguration>>>",this.roleConfiguration);

        this.roleConfiguration.forEach((element , i) => {

            if((element.isView == 1 && element.pageTitle == 'Product Type')){

              
                this.roleAsses.productType = true;
               
            }

            
            if((element.isEdit == 1 && element.pageTitle == 'Product Type')){

              
                this.roleAsses.productTypeEdit = true;
               
            }

            
            if((element.isCreate == 1 && element.pageTitle == 'Product Type')){

                this.roleAsses.productTypeCreate = true;
               
            }

            
            if((element.isView == 1 && element.pageTitle == 'Product Type')){

                this.roleAsses.configurations = true;
               
            }

            if((element.isEdit == 1 && element.pageTitle == 'Configuration') ){
                
                this.roleAsses.configurationEdit = true;
            }
            
            if((element.isCreate == 1 && element.pageTitle == 'Product Type')){

                this.roleAsses.configurationCreate = true;
               
            }

        })
        // theme update code start

    //     this.commonService.currentThemelight.subscribe((result:any)=>{
  
    //         this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
    //    })

       this.commonService.currentThemelight.subscribe((result:any)=>{

        if(localStorage.getItem('langItem') && localStorage.getItem('activePage')=='productType'){

            let item = JSON.parse(localStorage.getItem('langItem'));
            this.id = item.id;
            this.createNewProductForm.controls['id'].setValue(this.id);

            if(item.id != null && this.roleAsses.carouselEdit == true){
                this.editCreate = true;
                this.update = true;
            }else{
                this.editCreate = false;
            }

            this.createNewProductForm.controls['langId'].setValue(localStorage.getItem('languageId'));
            this.isCreateNewShow = false;
            this.getProductInfo(item.id);
            localStorage.removeItem('langItem');

        }
           this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      })

       // theme update code start
}


    onLoad(id) {

        this.id = id;

        this.allDevice = false;

        this.img = [];
        this.img.push({

        })

        deviceType.forEach((device,i) => {

            device.active = false;

            console.log("device",device);
            
        });

      this.deviceList = [];
      this.contantIdFlag = false ; 

        let headerTitle = {
            title:environment.configurationPageTitle,
            subTitle:environment.conPagePTSubTitle
 
        }

        this.commonService.changeHeaderTitle(headerTitle);

          //start code added by mayuri
          

          if(this.refresh == true){
       
            this.onDeviceTabClick();

          }else {

            this.onProductInfoTabClick();

          }
          //end code added by mayuri
        

        // code for error loaderstop
        this.commonService.notify.subscribe((result) => {
            this.loader = false;
        })
            
        this.deviceActiveFrom = this.formBuilder.group({

            deviceActive: this.formBuilder.array([this.createItem()])

        });    
        
    
                this.id = id;

                this.langId = "1";

                //below if for first time load condition.
                if(this.id!='null'){

                    this.contantIdFlag = false;
                    this.update = true;

                    this.getProductInfo(this.id);


               //below else for with id 
                }else{

                    this.update = false;

                        this.img.push({
                            horizontalURL:'',
                            verticalURL:''
                        })   
                       
                        let objH = {
                            'index':"",
                            'deviceType':"",
                            'selectedFiles':"",
                            "horizontalFileName": "",
                            "horizontalFilePath": "",
                            "horizontalFileType": "",
                            'horizontalFile':false
                        }

                        let objV = {
                            'index':"",
                            'deviceType':"",
                            'selectedFiles':"",

                            "verticalFileName":"",
                            "verticalFilePath": "",
                            "verticalFileType":"",
                            'verticalFile':false,
                            
                        }

                        this.uploadImgFilePathHorizontal.push(objH);
                        this.uploadImgFilePathVertical.push(objV);
                      
                }
         

            this.createNewProductForm = this.formBuilder.group({
                // baseUrl: ['',Validators.compose([Validators.pattern('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),Validators.required])],
                mapGeoZone: ['', Validators.compose([Validators.required])],
                exclusionText: [''],
                sameForAllZone:[''],
                isActive:['1'],
                title:['',Validators.compose([Validators.required])],
                isDefault:[''],
                productDeviceTypeConfigure:[''],
                // productTitle: ['', Validators.compose([Validators.required])],
                sameConfigurationDeviceType: [''],
                // genreSameConfigurationDeviceType: [''],
                languageSameConfigurationDeviceType: [''],
                pageAdsSameConfigurationDeviceType: [''],
                sameConfigurationOrientation: [''],
                companyId:[''],
                langId: [this.langId],
                id:[''],
                contentState:['In-Progress'],
                publisherState:['Un-published'],
                reviewerState:['Un-approved'],
                created_by:[localStorage.getItem('ud')]

            });

            //pravin code start
            this.dropdownSettings = { 
                singleSelection: false, 
                text:"Select Geo Zone",
                selectAllText:'Select All',
                unSelectAllText:'UnSelect All',
                enableSearchFilter: true,
                classes:"myclass custom-class"
            }; 

            //pravin code start
            this.deviceTypeSettings = { 
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: false,
            classes:"myclass custom-class"
        }; 
        this.getGeoZoneInfo();

        this.selectedItems = [];

    }

    onCreateNew(){


        // this.editCreate = true; 
        this.isCreateNewShow=false

    }

    onLoadRefresh(id){

        this.refresh = true;
        if(this.update == false){

            this.resetForm();

        }else {

            this.onLoad(id);

        }
        

    }

    onProductInfoTabClick(){
        
        this.formTabs = {

            productInfo:{
    
             product:true,
             mapGeoZone:true,
             information:false
    
            },
            deviceType:{
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            }
        }
    }

    onHorizontalTabClick(){

        this.formTabs = {

            productInfo:{
    
             product:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{

                configurationType:true,
                configuration:false,
                horizontal:true,
                vertical:false
    
            }
        }
    }

    onVerticalTabClick(){
        
        this.formTabs = {

            productInfo:{
    
             product:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{

                configurationType:true,
                configuration:false,
                horizontal:false,
                vertical:true
    
            }
        }
    }

    onDeviceTabClick(){

        this.refresh = false;

        this.formTabs = {

            productInfo:{
    
             product:false,
             mapGeoZone:false,
             information:false
    
            },
            deviceType:{
                configurationType:true,
                configuration:true,
                horizontal:false,
                vertical:false
    
            }
        }
            //  if(this.update == true){
            //      if(this.deviceList[0].length>0){
            //         this.ondeviceDetails(this.deviceList[0])
            //      }
            //  }
              
    }

    onInformationTabClick(){

        this.formTabs = {

            productInfo:{
    
             product:true,
             mapGeoZone:false,
             information:true
    
            },
            deviceType:{
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            }
        }
    }

    selectFileVertical(e ,i) {

        //set obj for file upload of vertical section
        if(e.target.files[0].name == undefined)
        return;

        let objVs = {

            'index':"",
            'toggelId':this.deviceActiveFrom.value.deviceActive[0].toggelId,
            'deviceType':this.deviceActiveFrom.value.deviceActive[0].dtypeId,
            'selectedFiles': e.target.files.item(0),
            "verticalFileName":e.target.files[0].name,
            "verticalFilePath": "",
            "verticalFileType":e.target.files[0].type,
            'verticalFile':true,
            
        }

        this.uploadImgFilePathVertical.push(objVs);

        // this.uploadImgFilePathVertical[i].deviceType = this.deviceActiveFrom.value.deviceActive[0].dtypeId;
        // this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        // this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        // this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        // this.uploadImgFilePathVertical[i].verticalFile = true; 


        let objH = {
            'index':"",
            'toggelId':"",
            'deviceType':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'toggelId':"",
            'deviceType':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

       

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {

            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                
                this.img[0].verticalURL=reader.result;  
                this.deviceActiveFrom = this.formBuilder.group({
                    deviceActive: this.formBuilder.array([this.updateImgItem(this.deviceActiveFrom.value.deviceActive[0])])
                }); 
                
            //    this.deviceActiveFrom.value.deviceActive[0].verticalFilePath = reader.result;
               console.log('render>>',this.deviceActiveFrom.value.deviceActive[0].verticalFilePath);
               e.target.value = '';
            
            }


          

    }

    selectFileHorizontal(e ,i) {


        //set obj for file upload of horizontal section
        if(e.target.files[0].name == undefined)
        return;

        // this.uploadImgFilePathHorizontal[i].deviceType = this.deviceActiveFrom.value.deviceActive[i].dtypeId;
        // this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
        // this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
        // this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
        // this.uploadImgFilePathHorizontal[i].horizontalFile = true; 

        let objVs = {

            'index':"",
            'toggelId':this.deviceActiveFrom.value.deviceActive[0].toggelId,
            'deviceType':this.deviceActiveFrom.value.deviceActive[0].dtypeId,
            'selectedFiles': e.target.files.item(0),
            "horizontalFileName":e.target.files[0].name,
            "horizontalFileType":e.target.files[0].type,
            'horizontalFile':true,
            
        }

        this.uploadImgFilePathHorizontal.push(objVs);

        let objH = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            'toggelId':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'deviceType':"",
            'toggelId':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);


        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
            
                this.img[0].horizontalURL=reader.result;

                this.deviceActiveFrom = this.formBuilder.group({
                    deviceActive: this.formBuilder.array([this.updateImgItem(this.deviceActiveFrom.value.deviceActive[0])])
                }); 

              //  this.deviceActiveFrom.value.deviceActive[0].horizontalFilePath =reader.result;
                console.log('render>>',this.deviceActiveFrom.value.deviceActive[0].verticalFilePath)
                e.target.value = '';
            }

         

    }

    getProductInfo(id){

        this.img = [];

        // this.loader = true;

       //code for global loader start
        this.commonService.changeloader(true);
       //code for global loader end

        // this.changeloader
        this.productTypeService.getProductType(id, this.langId)
        .subscribe((result:any)=>{
            
            // this.loader = false;

            //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end

            this.createNewProductForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
            this.createNewProductForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
            this.createNewProductForm.controls['title'].setValue(result.data[0].title);
            this.createNewProductForm.controls['isActive'].setValue(result.data[0].isActive);
            this.createNewProductForm.controls['isDefault'].setValue(result.data[0].isDefault);
            this.createNewProductForm.controls['sameConfigurationDeviceType'].setValue(result.data[0].sameConfigurationDeviceType);
            this.createNewProductForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createNewProductForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
            
            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){

                this.createNewProductForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{

                this.createNewProductForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            this.selectedItems = [];

            if(result.data[0].geoZone.length>0){

                for(let i = 0 ;i < result.data[0].geoZone.length;i++){
                    let obj={
                    'id':result.data[0].geoZone[i].geoZoneId,
                    'itemName':result.data[0].geoZone[i].geoZoneName,
                    }
                    this.selectedItems.push(obj)
                }
            }

            this.createNewProductForm.controls['mapGeoZone'].setValue(this.selectedItems);


            if(result.data[0].productDeviceTypeConfigure.length>0){

                this.deviceList = result.data[0].productDeviceTypeConfigure;

                for(let i = 0 ;i<this.deviceList.length ; i ++){
                    this.deviceList[i].toggelId = i+1;

                    for(let j = 0; j <deviceType.length ; j ++){

                        if(deviceType[j].key == (this.deviceList[i].dtypeId).toString()){

                            if(this.deviceList[i].dtypeId == 4){
                                this.allDevice = true;
                            }

                            deviceType[j].active = true;
                        }
                    }

                }

                if(this.deviceList.length>0)
                 this.boolDeviceTypeList = true;
              
              
                //device type validation code start
                if(result.data[0].productDeviceTypeConfigure.length == 4){
                    this.deviceTypeFull = true;
                }
               //device type validation code end

                this.multipleDeviceForm = this.formBuilder.group({

                    devices: this.formBuilder.array([])

                });

                this.deviceActiveFrom = this.formBuilder.group({

                    deviceActive: this.formBuilder.array([])

                });

                this.deviceActive = this.deviceActiveFrom.get('deviceActive') as FormArray;

                let objH = {

                    'index':"",
                    'deviceType':"",
                    'toggelId':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }

                let objV = {

                    'index':"",
                    'deviceType':"",
                    'toggelId':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }
                this.img =[];


                 this.uploadImgFilePathHorizontal.push(objH);
                 this.uploadImgFilePathVertical.push(objV);
               
                this.deviceActiveFrom = this.formBuilder.group({

                    deviceActive: this.formBuilder.array([this.createItem()])
        
                });


                for(let i=0;i < result.data[0].productDeviceTypeConfigure.length;i++){

                    this.img.push({

                        // horizontalURL:result.data[0].productDeviceTypeConfigure[i].horizontalFilePath,
                        // verticalURL:result.data[0].productDeviceTypeConfigure[i].verticalFilePath

                        horizontalURL:'',
                        verticalURL:''

                    })

                    console.log("result.img",this.img);

                    let objH = {

                        'index':"",
                        'deviceType':"",
                        'toggelId':"",
                        'selectedFiles':"",
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": "",
                        'horizontalFile':false
                    }

                    let objV = {

                        'index':"",
                        'deviceType':"",
                        'toggelId':"",
                        'selectedFiles':"",
                        "verticalFileName":"",
                        "verticalFilePath": "",
                        "verticalFileType":"",
                        'verticalFile':false,
                        
                    }

                    this.uploadImgFilePathHorizontal.push(objH);
                    this.uploadImgFilePathVertical.push(objV);
                    

                }
            }

        });

    }

    //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup {

        return this.formBuilder.group({
            toggelId:objProductDeviceTypeConfigure.toggelId,
            label: [objProductDeviceTypeConfigure.label,Validators.required],
            description: objProductDeviceTypeConfigure.description,
            uploadVerticalImage: '',
            isVisible:objProductDeviceTypeConfigure.isVisible,
            uploadHorizontalImage:'',
            dtypeId:[objProductDeviceTypeConfigure.dtypeId],
            verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
            verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
            verticalFileType: objProductDeviceTypeConfigure.verticalFileType,  
            horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
            horizontalFilePath: objProductDeviceTypeConfigure.horizontalFilePath,
            horizontalFileType: objProductDeviceTypeConfigure.horizontalFileType

        });
      
    }

    //pravin code start
    updateImgItem(objProductDeviceTypeConfigure): FormGroup {

        return this.formBuilder.group({
            toggelId:objProductDeviceTypeConfigure.toggelId,
            label: [objProductDeviceTypeConfigure.label,Validators.required],
            description: objProductDeviceTypeConfigure.description,
            uploadVerticalImage: '',
            isVisible:objProductDeviceTypeConfigure.isVisible,
            uploadHorizontalImage:'',
            dtypeId:[objProductDeviceTypeConfigure.dtypeId],
            verticalFileName: '',
            verticalFilePath: this.img[0].verticalURL,
            verticalFileType:'',  
            horizontalFileName: '',
            horizontalFilePath: this.img[0].horizontalURL,
            horizontalFileType: ''

        });
      
    }
        
    //pravin code start
    updateItemAdd(objProductDeviceTypeConfigure): FormGroup {

        return this.formBuilder.group({
            toggelId:this.toggelId++,
            label: ['',Validators.required],
            description: [],
            uploadVerticalImage: '',
            isVisible:[],
            uploadHorizontalImage:'',
            dtypeId:[parseInt(objProductDeviceTypeConfigure.key)],
            verticalFileName:'',
            verticalFilePath: '',
            verticalFileType: '',  
            horizontalFileName: '',
            horizontalFilePath: '',
            horizontalFileType: ''

        });
      
    }

    createItem(): FormGroup {

        this.toggelId=this.toggelId+1;

        return this.formBuilder.group({
            toggelId:this.toggelId,
            label: ['',Validators.required],
            description: '',
            uploadVerticalImage: '',
            uploadHorizontalImage: '',
            dtypeId:[''],
            isVisible:[''],
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""
        });
    }

    onDtypeId(){
        this.All=false;
    }

    toggleDeviceList(){

        this.boolDeviceTypeList = !this.boolDeviceTypeList;
        
    }


    //click of delete device type
    delInput(index){
         
        this.img = [];

        this.img.push({
            
            horizontalURL:'',
            verticalURL:''
        })

      const arrayControl1 = this.deviceActiveFrom.get('deviceActive') as FormArray;
      while (arrayControl1.length !== 0) {

          arrayControl1.removeAt(0);

      }

        this.deviceActiveFrom = this.formBuilder.group({

            deviceActive: this.formBuilder.array([this.createItem()])

        });

        this.uploadImgFilePathHorizontal.splice(index , 1);
        this.uploadImgFilePathVertical.splice(index , 1);
             
        this.img.splice(0 , 1);
        
        let flag = false;

        this.deviceList.splice(index , 1);
        
        deviceType.forEach((device,i)=>{

            deviceType[i].active = false;

            this.deviceList.forEach((element,j) => {

                    if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                        deviceType[i].active = true;
                    }
  
            });

        })

        if(this.deviceList.length ==0){

            this.allDevice = false;
            deviceType.forEach((device, i)=>{
                 device.active = false;
            },)
        }

    }
    

    addInList(){
        
            console.log(" device.toggelId", this.deviceActiveFrom.value.deviceActive[0]);
            this.loader = true;
            this.createNewProductSubmitted = false;
          
            if(!this.deviceActiveFrom.valid){
                this.loader = false;
                this.createNewProductSubmitted = true;
                return;
            }

            let dlenght = this.deviceList.length;

            this.createNewProductSubmitted = false;

            if(!this.createNewProductForm.valid){
                
                this.loader = false;
                this.createNewProductSubmitted = true;

                // deviceType.forEach((device,i)=>{

                //     deviceType[i].active = false;
        
                //     this.deviceList.forEach((element,j) => {
        
                //             if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                //                 deviceType[i].active = true;
                //             }
                        
                //     });
        
                // })
                return;
            }

            if(this.deviceActiveFrom.value.deviceActive[0].dtypeId !='' && (this.deviceActiveFrom.value.deviceActive[0].label=='')){
                this.loader = false;
                this.createNewProductSubmitted = true;

                deviceType.forEach((device,i)=>{

                    deviceType[i].active = false;
        
                    this.deviceList.forEach((element,j) => {
        
                            if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                                deviceType[i].active = true;
                            }

                    });
        
                })

                return;
            }

            var count = 0 ; 
            let dlength = this.deviceList.length;

            this.deviceList.forEach((device , i) => {
               
                dlength--;

                if(this.deviceActiveFrom.value.deviceActive[0].dtypeId == device.dtypeId){
                    console.log(" device.toggelId", device.toggelId);

                    count++ ;
                    this.deviceList[i].description = this.deviceActiveFrom.value.deviceActive[0].description
                    this.deviceList[i].dtypeId  = this.deviceActiveFrom.value.deviceActive[0].dtypeId
                    this.deviceList[i].horizontalFileName  = this.deviceActiveFrom.value.deviceActive[0].horizontalFileName
                    this.deviceList[i].horizontalFilePath  = this.deviceActiveFrom.value.deviceActive[0].horizontalFilePath
                    this.deviceList[i].horizontalFileType  = this.deviceActiveFrom.value.deviceActive[0].horizontalFileType
                    this.deviceList[i].isVisible  = this.deviceActiveFrom.value.deviceActive[0].isVisible
                    this.deviceList[i].label  = this.deviceActiveFrom.value.deviceActive[0].label
                    this.deviceList[i].uploadHorizontalImage  = this.deviceActiveFrom.value.deviceActive[0].uploadHorizontalImage
                    this.deviceList[i].uploadVerticalImage  = this.deviceActiveFrom.value.deviceActive[0].uploadVerticalImage
                    this.deviceList[i].verticalFileName  = this.deviceActiveFrom.value.deviceActive[0].verticalFileName
                    this.deviceList[i].verticalFilePath  = this.deviceActiveFrom.value.deviceActive[0].verticalFilePath
                    this.deviceList[i].verticalFileType  = this.deviceActiveFrom.value.deviceActive[0].verticalFileType
                    if(this.deviceList[0].dtypeId == 4 && this.deviceList.length ==1){

                        this.allDevice = true;
                    }
                }
                
            });

            //after checking of d type update excucute below code start
            if(dlength == 0){

                if(count == 0){

                    this.loader = false;
    
                   if(this.deviceList.length == 1){
    
                    if(this.deviceActiveFrom.value.deviceActive[0].dtypeId!='' && this.deviceList[0].dtypeId!=4)
                        this.deviceList.push(this.deviceActiveFrom.value.deviceActive[0]);
    
                   }else {
    
                    this.deviceList.push(this.deviceActiveFrom.value.deviceActive[0]);
                   }
    
                    if(this.deviceList[0].dtypeId == 4){
    
                        this.allDevice = true;
                    }
    
                }  
    
                  console.log("this.deviceList",this.deviceList);
                 //remove currect aactive from for reset and add new empty from code start
                  const arrayControl1 = this.deviceActiveFrom.get('deviceActive') as FormArray;
                  while (arrayControl1.length !== 0) {
          
                      arrayControl1.removeAt(0);
          
                  }
    
                  this.deviceActiveFrom = this.formBuilder.group({
    
                    deviceActive: this.formBuilder.array([this.createItem()])
        
                  });
                //remove currect aactive from for reset and add new empty from code start
    
                 this.loader = false;
            }
            //after checking of d type update excucute above code end

           this.img = [];

           this.img.push({
               horizontalURL:'',
               verticalURL:''
           })
        
    }

    // after click of device type button code end
    addItemCreates(activeDevice){ 

        this.activeDevice = activeDevice;
        //active item active code start
        deviceType.forEach((device,i)=>{

           deviceType[i].active = false;

            if(device.key == activeDevice.key){

                deviceType[i].active = true;

                if(activeDevice.key == '4' && this.deviceList.length!=0){

                    deviceType[i].active = true;

                    this.allDevice = true;
                    console.log("this.allDevice",this.allDevice);
                    console.log("deviceType[i].active",deviceType[i].active);
                    return;

                }     

            }
        })
        //active item active code end

        let dlistlength = this.deviceList.length;
        deviceType.forEach((device,i)=>{
           // deviceType[i].active = false;
            this.deviceList.forEach((element,j) => {
                dlistlength --;
                    if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                        deviceType[i].active = true;
                    }   
            });

        })

        this.img = [];

        this.img.push({
            verticalURL:'',
            horizontalURL:''
        })
        
        const arrayControl1 = this.deviceActiveFrom.get('deviceActive') as FormArray;
         arrayControl1.removeAt(0);
        
        this.deviceActiveFrom = this.formBuilder.group({
            deviceActive: this.formBuilder.array([this.updateItemAdd(activeDevice)])
        });

    }
    // after click of device type button code end

    ondeviceDetails(devices){

        //chekning actual active device code start
        deviceType.forEach((device,i)=>{

            deviceType[i].active = false;

            this.deviceList.forEach((element,j) => {
                    if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                        deviceType[i].active = true;
                    }   
            });

        })
        //chekning actual active device code end

        
        this.boolDeviceTypeList = !this.boolDeviceTypeList;

        this.img = [];

        //reomve previous form
        let device = this.deviceActiveFrom.controls.deviceActive as FormArray;
        device.removeAt(0);

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        this.deviceActiveFrom = this.formBuilder.group({

            deviceActive: this.formBuilder.array([])

        });

        this.deviceActive = this.deviceActiveFrom.get('deviceActive') as FormArray;

        let objH = {

            'index':"",
            'deviceType':"",
            'toggelId':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {

            'index':"",
            'deviceType':"",
            'toggelId':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.img.push({

            horizontalURL:devices.horizontalFilePath,
            verticalURL:devices.verticalFilePath

        })

         this.uploadImgFilePathHorizontal.push(objH);
         this.uploadImgFilePathVertical.push(objV);
         //added previous form
         this.deviceActive.push(this.updateItem(devices));

    }

    //pravin code start
    getGeoZoneInfo(){

        this.spinnerService.show();

        let obj={
        }

        this.productConfigurationService.getAllGeoZone(obj,this.start,this.limit)
        .subscribe((result:any)=>{

            console.log('result===>',result);

            this.dropdownList = [];
            this.geoZoneList = [];
            if(result.data.data.length > 0){

                this.dropdownList = result.data.data;

                for (let geoZoneObj of this.dropdownList) {

                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });

                }
            }

            console.log('this.geoZoneList',this.geoZoneList);
        }) 
    }
    //pravin code end

    onItemSelect(item:any){
        console.log(item);
        console.log(this.selectedItems);
    }

    OnItemDeSelect(item:any){
        console.log(item);
        console.log(this.selectedItems);
    }

    onSelectAll(items: any){

        console.log("items",items);
        
    }

    onDeSelectAll(event, info){
        // console.log(items);
        if(info =='mapGeoZone'){
            this.createNewProductForm.controls['mapGeoZone'].setValue([]);
        }
    }

    get l() { return this.createNewProductForm.controls; }

    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }

    get dA(): FormArray {
        return this.deviceActiveFrom.get('deviceActive') as FormArray;
    }

    savecreateNewProduct(){

         this.loader = true;
         //code for global loader start
         this.commonService.changeloader(true);
         //code for global loader end
      
        let tempUploadImgFilePath= this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath = [];

        this.loader = false;
        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
       
        //remove empty file upload img obj from array code start.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.deviceType !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });
        //remove empty file upload img obj from array code end.

        this.createNewProductSubmitted = true;
        let async = this.uploadImgFilePath.length;
       
        this.devicesReqError = '';

        let varialbeArray = [];

        console.log("uploadImgFilePath",this.uploadImgFilePath);
        if(!this.update){

             //code for global loader start
            this.commonService.changeloader(true);
            //code for global loader end

            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.productType).then(res => {

                        if (res && res.Location) {
                            
                                async--;
                                if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                if (async == 0) {

                                    for(let i=0;i< this.deviceList.length;i++){

                                                varialbeArray.push(
                                                {
                                                    "label": this.deviceList[i].label,
                                                    "description":this.deviceList[i].description,
                                                    "offlineDownload": "1",
                                                    "toggelId":this.deviceList[i].toggelId,
                                                    "mapTrailerVideo":"12345",
                                                    "uploadVerticalImage": "",
                                                    "uploadHorizontalImage": "",
                                                    "dtypeId":this.deviceList[i].dtypeId,
                                                    "isVisible":this.deviceList[i].isVisible,
                                                    "verticalFileName": "",
                                                    "verticalFilePath": "",
                                                    "verticalFileType": "",  
                                                    "horizontalFileName": "",
                                                    "horizontalFilePath": "",
                                                    "horizontalFileType": ""
                                              
                                                });   

                                    }

                                    let asyncvariables = varialbeArray.length;
                                    for(let i=0;i< varialbeArray.length;i++){

                                            asyncvariables--;
                                            for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
        
                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
        
                                                    varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                    varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                    varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
        
                                                }

                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
        
                                                    varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                    varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                    varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
        
                                                }
        
                                            }
                                    }

                                    if(asyncvariables == 0){

                                        this.createNewProductSubmitted=true;
                                  
                                        console.log("this.createNewProductForm>>",this.createNewProductForm);
                                        if(!this.createNewProductForm.valid){
                                   
                                            //code for global loader start

                                            this.loader = false;
                                            this.commonService.changeloader(false);
                                            //code for global loader end
                                            return
                                       }

                                        this.createNewProductForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                     
                                        // this.onContentReadyToReview();

                                         this.postCAllProduct();

                                    }

                                }      

                        }

                    });
                }
            }


            if(this.uploadImgFilePath.length == 0){
               

                    for(let i=0;i< this.deviceList.length;i++){ 

                        varialbeArray.push(
                        {
                            "label": this.deviceList[i].label,
                            "description":this.deviceList[i].description,
                            "uploadVerticalImage": "",
                            "uploadHorizontalImage": "",
                            "toggelId":this.deviceList[i].toggelId,
                            "dtypeId":this.deviceList[i].dtypeId,
                            "isVisible":this.deviceList[i].isVisible,
                            "verticalFileName": "",
                            "verticalFilePath": "",
                            "verticalFileType": "",  
                            "horizontalFileName": "",
                            "horizontalFilePath": "",
                            "horizontalFileType": ""
                        });   

                    }

                    this.createNewProductSubmitted = true;

                    this.createNewProductForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                    console.log("createNewProductForm",this.createNewProductForm);
                      this.postCAllProduct();
                   //  this.onContentReadyToReview();

            }
    

        }

        if(this.update){
            console.log("deviceList>>>>",this.deviceList);
           
            let asyncvariables = this.deviceList.length;
           
                        if(this.uploadImgFilePath.length == 0){

                        this.createNewProductForm.controls['productDeviceTypeConfigure'].setValue(this.deviceList);
                        this.createNewProductSubmitted = true;
                                
                                        if(!this.createNewProductForm.valid){
            
                                            //code for global loader start
                                            this.commonService.changeloader(false);
                                            //code for global loader end
            
                                            return
                                        }
                                        this.createNewProductForm.controls['productDeviceTypeConfigure'].setValue(this.deviceList)
                                     
                                       //  this.onContentReadyToReview();
                                         this.postCAllProduct();
            
                        }

                        if(this.uploadImgFilePath.length > 0){

                            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                        
                                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.productType).then(res => {
            
                                    if (res && res.Location) {
                                        async--;
                                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                            this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                            
                                            if(this.uploadImgFilePath[i].verticalFile == true)
                                            this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                                    }
            
                                    if (async == 0) {
            
                                        for(let i=0;i<this.deviceList.length;i++){
            
                                            asyncvariables--;
                                            for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
            
                                                if(this.uploadImgFilePath[j].deviceType == this.deviceList[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){
                    
                                                    this.deviceList[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                    this.deviceList[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                    this.deviceList[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
            
                                                }
            
                                                if(this.uploadImgFilePath[j].deviceType == this.deviceList[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
            
                                                    this.deviceList[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                    this.deviceList[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                    this.deviceList[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
            
                                                }
            
                            
                                            }
                                        }
            
                                        if(asyncvariables == 0){
            
                                            this.createNewProductSubmitted = true;
            
                                            console.log("this.createNewProductForm>>",this.createNewProductForm);
                                            // if(!this.createNewProductForm.valid){
            
                                            //      //code for global loader start
                                            //      this.commonService.changeloader(false);
                                            //      //code for global loader end
                                            //     return
                                            // }
            
                                            this.createNewProductForm.controls['productDeviceTypeConfigure'].setValue(this.deviceList)
                                         
                                           // this.onContentReadyToReview();

                                            this.postCAllProduct();
            
                                            
                                        }
                                    }
                                })
                            }
                        }  

        }

    }

    onContentReadyToReview(){ 

        this.loader = true;
        this.createNewProductSubmitted = false;  
        console.log("createNewProductForm",this.deviceActiveFrom.value.deviceActive[0]);
       //basic form valid check code start
        if(!this.createNewProductForm.valid){
            this.loader = false;
            this.createNewProductSubmitted = true;
            return;
        }
        //basic form valid check code end

        //actvive from deviceId present but lable missing check code start
        if(this.deviceActiveFrom.value.deviceActive[0].dtypeId !='' && this.deviceActiveFrom.value.deviceActive[0].label ==''){
            this.loader = false;
            this.createNewProductSubmitted = true;    
            return;
       }
       //actvive from deviceId present but lable missing check code end

       //below check all devie type condition code start
        else if(this.deviceList.length==0 && this.allDevice == true && this.deviceList[0].dtypeId==''){
            this.loader = false;
            this.createNewProductSubmitted = true;
            return;

        }else if(this.allDevice == true && this.deviceList[0].dtypeId!=4){
            this.loader = false;
            this.createNewProductSubmitted = true;
            return;

        }
        //below check all devie type condition code start
        else {

            this.createNewProductSubmitted = true;
       //active form if valid then add or update below code start
        if(this.deviceActiveFrom.value.deviceActive.length > 0){

            let count = this.deviceList.length; 
            let flag = false;

            this.deviceList.forEach((device , i) => {

                count--;
                if(this.deviceActiveFrom.value.deviceActive[0].dtypeId == device.dtypeId){

                    flag =  true;

                    this.deviceList[i].description = this.deviceActiveFrom.value.deviceActive[0].description
                    this.deviceList[i].dtypeId  = this.deviceActiveFrom.value.deviceActive[0].dtypeId
                    this.deviceList[i].horizontalFileName  = this.deviceActiveFrom.value.deviceActive[0].horizontalFileName
                    this.deviceList[i].horizontalFilePath  = this.deviceActiveFrom.value.deviceActive[0].horizontalFilePath
                    this.deviceList[i].horizontalFileType  = this.deviceActiveFrom.value.deviceActive[0].horizontalFileType
                    this.deviceList[i].isVisible  = this.deviceActiveFrom.value.deviceActive[0].isVisible
                    this.deviceList[i].label  = this.deviceActiveFrom.value.deviceActive[0].label
                    this.deviceList[i].uploadHorizontalImage  = this.deviceActiveFrom.value.deviceActive[0].uploadHorizontalImage
                    this.deviceList[i].uploadVerticalImage  = this.deviceActiveFrom.value.deviceActive[0].uploadVerticalImage
                    this.deviceList[i].verticalFileName  = this.deviceActiveFrom.value.deviceActive[0].verticalFileName
                    this.deviceList[i].verticalFilePath  = this.deviceActiveFrom.value.deviceActive[0].verticalFilePath
                    this.deviceList[i].verticalFileType  = this.deviceActiveFrom.value.deviceActive[0].verticalFileType

                }
                
            });

            
            if(count == 0){

                if(flag == false){

                    if(this.deviceActiveFrom.value.deviceActive[0].dtypeId!=''&&  this.deviceActiveFrom.value.deviceActive[0].label!=null && this.deviceActiveFrom.value.deviceActive[0].label!='')
                        this.deviceList.push(this.deviceActiveFrom.value.deviceActive[0]);

                        
                    this.errorMessageDeviceList = '';
                    this.deviceList.forEach((element, i) => {
            
                        if(element.dtypeId == ''){
            
                            this.deviceList.slice(i,1)
            
                        }
                        
                    });

                    if(this.deviceList.length == 0){

                        this.loader = false;
                        
                        return;
        
                    }else {

                        this.loader = false;
                        //code for global loader start
                        this.commonService.changeloader(false);
                        //code for global loader end
                
                        this.contentReadyToReview = true;
                        this.shareMassage = 'review';
                    }
    
                    }else {

                        this.errorMessageDeviceList = '';
                        this.deviceList.forEach((element, i) => {
                
                            if(element.dtypeId == ''){
                
                                this.deviceList.slice(i,1)
                
                            }
                            
                        });
    
                        if(this.deviceList.length == 0){
    
                            this.loader = false;
                            
                            return;
            
                        }else {

                            this.loader = false;
                            //code for global loader start
                            this.commonService.changeloader(false);
                            //code for global loader end
                            this.contentReadyToReview = true;
                            this.shareMassage = 'review';
                        }
                    }
            }

        } 
       //active form if valid then add or update below code end     
            
        }

    }

    postCAllProduct(){

        this.createNewProductForm.value.productDeviceTypeConfigure.forEach((element ,i) => {

            if(element.dtypeId == ''){

                this.createNewProductForm.value.productDeviceTypeConfigure.splice(i, 1);
                
            }
            
        });

        this.createNewProductForm.value.genreSameConfigurationDeviceType;
        delete this.createNewProductForm.value.pageAdsSameConfigurationDeviceType;
        delete this.createNewProductForm.value.languageSameConfigurationDeviceType;
    
        // this.loader = true;

        //code for global loader start
        this.commonService.changeloader(true);
        //code for global loader end

        if( this.update == false ) {

            //role access code.. start
            if(this.roleAsses.productTypeCreate == false){

                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);

                return;

            }
            //role access code.. end

            
            delete this.createNewProductForm.value.id;
            this.productTypeService.insertProductType(this.createNewProductForm.value)
            .subscribe((result:any)=>{

                this.formTabs = {

                    productInfo:{
        
                    product:true,
                    mapGeoZone:true,
                    information:false
        
                    },
                    deviceType:{
                        configurationType:false,
                        configuration:false,
                        horizontal:false,
                        vertical:false
        
                    }
                }
        
                this.spinnerService.hide();
                // this.loader = false;

                //code for global loader start
                this.commonService.changeloader(false);
                //code for global loader end

                this.toastrService.success('Product Type Created Successfully.');
                this.deviceList = [];
                this.img = [];
                this.resetForm();

                this.loader = false;
                this.boolDeviceTypeList = false;
                this.createNewProductSubmitted= false;
             
            });

        }

        if(this.update){
       
            //role access code.. start
            if(this.roleAsses.productTypeEdit == false){

                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);

                return;

            }
            //role access code.. end

            this.createNewProductForm.controls['id'].setValue(this.id);
            this.productTypeService.updateProductType(this.createNewProductForm.value)
            .subscribe((result:any)=>{
                
                this.formTabs = {

                    productInfo:{
        
                    product:true,
                    mapGeoZone:true,
                    information:false
        
                    },
                    deviceType:{
                        configurationType:false,
                        configuration:false,
                        horizontal:false,
                        vertical:false
        
                    }
                }
        
                this.deviceType.forEach((device)=>{

                    device.active = false;

                })
                // this.loader = false;

                //code for global loader start
                this.commonService.changeloader(false);
                //code for global loader end

                this.spinnerService.hide();

                this.loader = false;

                console.log("id>>",this.id);
                this.toastrService.success('Product Type Updated Successfully.');
                
                this.resetForm();
                this.boolDeviceTypeList = false;
                this.deviceList = [];
                this.img = [];
                this.createNewProductSubmitted= false;
                
            });
        }

    }

    resetForm(){


        this.deviceList = [];
        this.img = [];

        this.allDevice = false;
        this.selectedItems = []

        deviceType.forEach((device,i) => {

            device.active = false;
            
        });

        const arrayControl1 = this.deviceActiveFrom.get('deviceActive') as FormArray;
        while (arrayControl1.length !== 0) {

            arrayControl1.removeAt(0);

        }

        this.deviceActiveFrom = this.formBuilder.group({

            deviceActive: this.formBuilder.array([this.createItem()])

        });    

        this.uploadImgFilePath =[];

        this.onProductInfoTabClick();

        this.createNewProductForm = this.formBuilder.group({
            // baseUrl: ['',Validators.compose([Validators.pattern('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),Validators.required])],
            mapGeoZone: ['', Validators.compose([Validators.required])],
            exclusionText: [''],
            sameForAllZone:[''],
            isActive:['1'],
            title:['',Validators.compose([Validators.required])],
            isDefault:[''],
            productDeviceTypeConfigure:[''],
            // productTitle: ['', Validators.compose([Validators.required])],
            sameConfigurationDeviceType: [''],
            // genreSameConfigurationDeviceType: [''],
            languageSameConfigurationDeviceType: [''],
            pageAdsSameConfigurationDeviceType: [''],
            sameConfigurationOrientation: [''],
            companyId:[''],
            langId: [this.langId],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved']
        });
        

        this.update = false;

        //////////////////////
         this.img = []

         this.img.push({
            horizontalURL:'',
            verticalURL:''
        })   
       
        let objH = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);
           
        if(this.refresh == false){

            this.updateSend.emit();
        }   
        
        this.createNewProductSubmitted = false;

        this.selectedItems = [];

        this.showCreateNewButton('show');
        
    }

     delInputActive(index){

        this.uploadImgFilePathHorizontal.splice(0 , 1);
        this.uploadImgFilePathVertical.splice(0 , 1);
             
        this.img.splice(0 , 1);
        
        if(this.deviceActiveFrom.value.deviceActive[0].dtypeId == 4){
            this.allDevice = false;
        }

        let dlenght = this.deviceList.length;

        this.deviceList.forEach((element,j) => {
            dlenght --;
                if(this.deviceList[j].dtypeId == this.deviceActiveFrom.value.deviceActive[0].dtypeId){

                    this.deviceList.splice(1,j);

                }

        });
        
        //chekning actual active device code start

        if(dlenght==0){

            
            let device = this.deviceActiveFrom.controls.deviceActive as FormArray;
           device.removeAt(0);

        
        this.deviceActiveFrom = this.formBuilder.group({

            deviceActive: this.formBuilder.array([this.createItem()])

        });

            deviceType.forEach((device,i)=>{

                deviceType[i].active = false;
    
                this.deviceList.forEach((element,j) => {
    
                        if(this.deviceList[j].dtypeId == parseInt(deviceType[i].key)){
                            deviceType[i].active = true;
                        }
    
                    
                });
    
            })
        }
        //chekning actual active device code end

     }

    isDeleteDeviceType(event){

        console.log('event = ',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
             this.delInput(this.deleteDeviceTypeIndex);
          
        }

    }

    isDeleteDeviceTypeActive(event){

        console.log('event = ',event)
        if(event=='Cancel'){
        
            this.isDeleteActionActive=false;
        }
        
        if(event=='Yes'){
            this.isDeleteActionActive=false;
             this.delInputActive(this.deleteDeviceTypeIndex);
          
        }

    }

    isDeleteDeviceTypeYesActive(i){
        this.deleteDeviceTypeIndex = i;
    }

    isDeleteDeviceTypeYes(i){

        this.deleteDeviceTypeIndex = i;
    }

    isDeleteHorizontalImage(event){

        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction = false;
        }
        
        if(event=='Yes'){
            this.isDeleteHorizontalImageAction = false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.deviceActiveFrom.value.deviceActive[this.deleteDeviceTypeIndex].horizontalFilePath="";

            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    deleteHorizontalImageYes(i){

        this.deleteDeviceTypeIndex = i;
    }

    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.deviceActiveFrom.value.deviceActive[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    deleteVerticalImageYes(i){
        
        this.deleteDeviceTypeIndex = i;
    }

    // is Content Ready To Review Received  

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);


        this.contentReadyToReview = false;

        if(event == 'Yes'){

            this.createNewProductForm.controls['contentState'].setValue(['Completed']);
            //this.postCAllProduct();
            this.savecreateNewProduct();
           
        }
        if(event == 'Cancel'){

            this.createNewProductForm.controls['contentState'].setValue(['In-Progress']);
          //  this.postCAllProduct();
            this.savecreateNewProduct();
           
        }
        
       
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
