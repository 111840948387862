import { Component, OnInit,Input,EventEmitter,Output} from '@angular/core';
import {CommonService} from '../../../services/common_Service/common.service';

@Component({
  selector: 'app-themepage',
  templateUrl: './themepage.component.html',
  styleUrls: ['./themepage.component.scss']
})

export class ThemepageComponent implements OnInit {

    @Output() isThemePageSend = new EventEmitter<any>();

    isPrivacyPolicy:boolean = false;

    boolTheme = localStorage.getItem('boolThemeFlag');
    boolThemeFlag:boolean=false;

      constructor(private commonService:CommonService,) { }

      ngOnInit() {
            
          this.boolTheme = localStorage.getItem('boolThemeFlag');
            
          if(this.boolTheme == 'true'){
      
            this.boolThemeFlag = true;
      
          }else{
      
              this.boolThemeFlag = false;
      
          }
      }

      onCheckTheme(){

        this.boolThemeFlag = !this.boolThemeFlag;
  
        if(this.boolThemeFlag == true){
  
            localStorage.setItem('boolThemeFlag','true');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
  
        }else{
  
            localStorage.setItem('boolThemeFlag','false');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
        }
        
        this.commonService.changeThemelight(this.boolThemeFlag);
      
    }

      onAction(event) {

          this.isThemePageSend.emit(event);  

      }

      onThemeSelection(event){
        
        this.isThemePageSend.emit(event);
      }
}
