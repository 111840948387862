import { Component, OnInit } from '@angular/core';
import {SessionHelper} from '../../../services/localstorage';
import {Router} from '@angular/router';
import {Input,EventEmitter,Output, ɵConsole} from '@angular/core';


@Component({
  selector: 'app-mastermenu',
  templateUrl: './mastermenu.component.html',
  styleUrls: ['./mastermenu.component.scss']
})
export class MastermenuComponent implements OnInit {

  
  roleAsses:any={};
  roleConfiguration:any =[];
  @Input() contentId:any;
  @Input() pageName:any;
  @Input() infoObj:any = {};

  

  @Output() checkEditSend = new EventEmitter<any>();


  constructor(private router: Router) { }

  ngOnInit() {

    
              //below code start for role managemet.

              this.roleAsses.genres = false;
              this.roleAsses.geoZone = false;
              this.roleAsses.castAndCrew = false;
              this.roleAsses.sponsor = false;

                this.roleAsses.genresApprove = false;
                this.roleAsses.genresDelete = false;
                this.roleAsses.genresUnApprove = false;
                this.roleAsses.genresActivate = false;
                this.roleAsses.genresDeactivate = false;
    
              this.roleAsses.reviews = false;
              this.roleAsses.classification = false;
              this.roleAsses.uplynkAdServer = false;
              this.roleAsses.footerLinks = false;
    
              this.roleAsses.character = false;
              this.roleAsses.blogs = false;
              this.roleAsses.blogsCategory = false;
              this.roleAsses.News = false;
    
              this.roleAsses.newsCategory = false;
              this.roleAsses.bulkImportExport = false;
     
              this.roleConfiguration = SessionHelper.getRole();
     
              this.roleConfiguration.forEach((element , i) => {
     
                  if((element.isView == 1 && element.pageTitle == 'Geo Zone')){
     
                    
                     this.roleAsses.geoZone  = true;
                    
                 }

                 if((element.isView == 1 && element.news == 'newsCategory')){
     
                    this.roleAsses.newsCategory  = true;
                   
                }


                 if((element.isView == 1 && element.pageTitle == 'Uplynk Ad Server') ){
                 
              
                    this.roleAsses.uplynkAdServer = true;
                }
     
                 if((element.isView == 1 && element.pageTitle == 'Cast And Crew')){
     
                    
                     this.roleAsses.castAndCrew = true;
                    
                 }

                 if((element.isView == 1 && element.pageTitle == 'Profession Type') ){
                    
                    this.roleAsses.profession = true;
                }
     
                  if((element.isView == 1 && element.pageTitle == 'Sponsor') ){
                      
                   
                      this.roleAsses.sponsor = true;
                  }
    
                  if((element.isView == 1 && element.pageTitle == 'Genres')){
     
                    
                    this.roleAsses.genres = true;
                   
                }

                    
                if((element.isDelete == 1 && element.pageTitle == 'Genres')){

                    this.roleAsses.genresDelete = true;
                
                }

                if((element.isApprove == 1 && element.pageTitle == 'Genres')){

                    this.roleAsses.genresApprove = true;
                
                }
                if((element.isUnapprove == 1 && element.pageTitle == 'Genres')){

                    this.roleAsses.genresUnApprove = true;
                
                }
                if((element.isActivate == 1 && element.pageTitle == 'Genres')){

                    this.roleAsses.genresActivate = true;
                
                }

                if((element.isDeactive == 1 && element.pageTitle == 'Genres')){

                    this.roleAsses.genresDeactivate = true;
                
                }

                if((element.isView == 1 && element.pageTitle == 'Blogs Category') ){
                 
                    this.roleAsses.blogsCategory = true;
                    
                   }
    
                if((element.isView == 1 && element.pageTitle == 'Reviews')){
    
                  
                   this.roleAsses.reviews  = true;
                  
               }
    
               if((element.isView == 1 && element.pageTitle == 'Classification')){
    
                  
                   this.roleAsses.classification = true;
                  
               }
    
                if((element.isView == 1 && element.pageTitle == 'Uplynk Ad Server') ){
                    
                 
                    this.roleAsses.uplynkAdServer = true;
                }
                
                if((element.isView == 1 && element.pageTitle == 'Footer Links') ){
                    
                 
                    this.roleAsses.footerLinks = true;
                }
                
                if((element.isView == 1 && element.pageTitle == 'Character') ){
                    
                 
                    this.roleAsses.character = true;
                }
                if((element.isView == 1 && element.pageTitle == 'Blogs') ){
                    
                 
                    this.roleAsses.blogs = true;
                }
    
                if((element.isView == 1 && element.pageTitle == 'News') ){
                    
                 
                    this.roleAsses.news = true;
                }
    
                if((element.isView == 1 && element.pageTitle == 'News Category') ){
                    
                    this.roleAsses.newsCategory = true;
                }
    
                if((element.isView == 1 && element.pageTitle == 'Bulk Import / Export') ){
                    
                    this.roleAsses.bulkImportExport = true;
                }
    
              })
  }

  
  onRedirect(info){
    if(this.contentId != 'null'){

        return;

      }else {

         if(info == 'reviews'){
            
            this.router.navigate(['/master/reviews/list']);

        }else if(info == "genres"){
            this.router.navigate(['/master/genres/list']);
            

        }else if(info == "geo_zone"){
            
            this.router.navigate(['/master/geo_zone/list']);
            
        }
        if(info == 'cast_and_crew'){
        
            this.router.navigate(['/master/cast_and_crew/list']);

        }else if(info == 'Group'){

            this.router.navigate(['/asset_management/asset_group/list']);

            
        }else if(info == 'sponsor'){

            this.router.navigate(['/master/sponsor/list']);
            
        }else if(info == 'Asset'){

            this.router.navigate(['/master/reviews/list']);
        }else if(info == 'classification'){
            
            this.router.navigate(['/master/classification/list']);
        }else if(info == 'uplynkAdServer'){

            this.router.navigate(['/master/uplynkAdServer/list']);
        }else if(info == 'footer_links'){
            
            this.router.navigate(['/master/footer_links/list']);
        }else if(info == 'character'){

            this.router.navigate(['/master/character/list']);
        }else if(info == 'blogs'){
            
            this.router.navigate(['/master/blogs/list']);
        }else if(info == 'blogsCategorys'){

            this.router.navigate(['/master/blogsCategorys/list']);
        }else if(info == 'news'){
            
            this.router.navigate(['/master/news/list']);
        }else if(info == 'newsCategory'){
            
            this.router.navigate(['/master/newsCategory/list']);
        }else if(info == 'bulkupload'){
            
            this.router.navigate(['/master/bulkupload/add']);
        }

    }
  }

  onCheckEditMode(){

      this.checkEditSend.emit();
  }

}
