import { Component, OnInit,Input,EventEmitter,Output} from '@angular/core';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

    @Output() isDeleteActionSend = new EventEmitter<any>();
    @Output() isStateActionSend = new EventEmitter<any>();
    @Output() isContinueEditingSend = new EventEmitter<any>();
    @Output() isContentReadyToReviewSend = new EventEmitter<any>();
    

    @Input() shareMassage;

    constructor() { }

      ngOnInit() {
      }

      onAction(event) {

          this.isDeleteActionSend.emit(event);  

      }

      onStatusAction(event){

        this.isStateActionSend.emit(event);  

      }

      onReviewAction(event){
        
        this.isContentReadyToReviewSend.emit(event);  

      }

      onContinueEditingAction(event){

        console.log("event",event);

      this.isContinueEditingSend.emit(event);

      }

  

}
