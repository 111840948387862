import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router ,ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../../services/login/login.service';
import {Loginshared} from '../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from '../../../../configurations/errorMessages';
import {ProductConfigurationService} from '../../../../services/product_configuration/product_configuration.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {MenuCategoryService} from '../../../../services/menu_category/menu-category.service';
import {ProductTypeService} from '../../../../services/product_type/product_type.service';
import {PrimaryNavigationService} from '../../../../services/primary_navigation/primary_navigation.service';
import { ToastrService } from 'ngx-toastr';
import { splitClasses } from '@angular/compiler';
import {awsPath, menuCategoryType, deviceType, displayIndexingPattern} from '../../../../configurations/configuration';
import {CommonService} from '../../../../services/common_Service/common.service';
import {Observable}  from 'rxjs/Observable';
import {SessionHelper} from '../../../../services/localstorage';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { exit } from 'process';

@Component({
  selector: 'app-addMenuCategory',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentMenuCategory implements OnInit {

    isCreateNewShow:boolean = true;

    deviceType = deviceType;
    isUserLoggedIn: boolean = false;
    loader:boolean=false;
    createMenuCategoryForm:FormGroup;
    errorMessages = errorMessages;
    menuCategoryType = menuCategoryType;
    createMenuCategorySubmitted=false;
    devicesReqError:any;
    shareMassage :any;

    selectedprimaryNavigationList:any=[];
    primaryNavigationList:any=[];
    img:any=[];
    //pravin code 

    startProductType:number  = 0 ;
    geoZoneObj = {};
    geoKeyword:any='ram'
    isSearch:boolean = false;
    startNavigation = 0;

    addExtra:boolean=false;
    dropdownSettings = {};
    selectedItems = [];
    selectedProductlist=[];
    dropdownList=[];
    geoZoneList=[];
    start:number=0;
    limit:number=10;      
    ProducTypeDropdownList = [];
    ProducTypeList = [];
    ProducTypeSettings={};
    primaryNavigationSettings = {};
     //pravin code start
     multipleDeviceForm:FormGroup;
     devices: FormArray;
     id:any;
     update = false;
     dTypeDublicateError:any;
     //pravin code end 
     All:boolean;
     Web:boolean;
     Android:boolean;
     iOS:boolean;
     selectedFiles: any=[];
     uploadImgFilePath:any=[];

     all:boolean = false;
     android:boolean = false;
     roku:boolean = false;

     productTypeScrollCount:number = 0;
 

     isDeleteImageAction:boolean=false;
     
     deviceTypeDublicate = false;
     deviceTypeFull = false;
    
     isDeleteAction: boolean=false;
     deleteDeviceTypeIndex:any;

    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    //end mapping filter

    applyBlackTheme = localStorage.getItem('boolThemeFlag');


     langId:any;

     formTabs:any = {

        MenuCategoryInfo:{

         MenuCategory:true,
         mapGeoZone:true,
         information:false

        },
        DeviceType:{
            
            configurationType:false,
            configuration:false,
            horizontal:false,
            vertical:false

        }
    }

    //New Code for right side for from
    contentReadyToReview:boolean = false;
    @Output() updateSend = new EventEmitter<any>();

    @Input() set contentId(value: any) {
 
        if(value == 'reset'){

            this.resetForm();

        }else {

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
         
    }
    

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    
     constructor(private commonService:CommonService,private menuCategoryService:MenuCategoryService,private primaryNavigationService:PrimaryNavigationService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private productTypeService:ProductTypeService,private productConfigurationService:ProductConfigurationService,private spinnerService:Ng4LoadingSpinnerService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private formBuilder: FormBuilder) {
        
        this.commonService.notify.subscribe((result) => {
            // this.loader = false;
                this.commonService.changeloader(false);
        })

        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');

                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }

            }
        );
        
    }

    roleAsses:any={};
    roleConfiguration:any=[];
    ngOnInit() {

        this.setupSearchDebouncer();

          //below code start for role managemet.

          this.roleAsses.menuCategoryEdit = false;
          this.roleAsses.menuCategory = false;
          this.roleAsses.menuCategoryCreate = false;
          this.roleConfiguration = SessionHelper.getRole();

          this.roleConfiguration.forEach((element , i) => {

                if((element.isView == 1 && element.pageTitle == 'Menu Category') ){
                    
                    this.roleAsses.menuCategory = true;
                }
            
                if((element.isEdit == 1 && element.pageTitle == 'Menu Category') ){
                    
                    this.roleAsses.menuCategoryEdit = true;
                }
                
                if((element.isCreate == 1 && element.pageTitle == 'Menu Category')){

                    this.roleAsses.menuCategoryCreate = true;
                
                }

          })
        
        // theme update code start

        this.commonService.currentThemelight.subscribe((result:any)=>{

            if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='menuCategory'){

                let item = JSON.parse(localStorage.getItem('langItem'));

                if(item.id!=undefined)
                   this.id = item.id;
                  
                this.createMenuCategoryForm.controls['id'].setValue(this.id);

                if(item.id != null && this.roleAsses.menuCategoryEdit == true){
                    this.editCreate = true;
                    this.update = true;
                }else{
                    this.editCreate = false;
                }

                this.createMenuCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                this.isCreateNewShow = false;
                this.getMenuCategory(item.id);
                // localStorage.removeItem('langItem');

            }
               this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
          })
    
    //     this.commonService.currentThemelight.subscribe((result:any)=>{

    //         if(result.id!=undefined && result.id!=null){

    //             this.id = result.id;
    //             this.createMenuCategoryForm.controls['id'].setValue(result.id);
    //             this.update = true;  
    //             this.getMenuCategory(result.id);
    //             this.isCreateNewShow = false;

    //         }
              
    //        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
    //    })
                        
        // this.commonService.currentThemelight.subscribe((result:any)=>{

        //     if(result.id == this.id){

        //         this.update = true;

        //         this.createMenuCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'))
                
        //         this.postMenuCategory();

        //     }
                  
        //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
        //   })

     }

     editCreate:boolean = false;

    onCreateNew(){

        if(this.roleAsses.menuCategoryCreate == true){
               this.editCreate = true;
        }
        this.isCreateNewShow=false

    }

    onLoad(id) {

        this.id = id;


        if(this.id != 'null' && this.roleAsses.menuCategoryEdit == true){
            this.editCreate = true;
            this.isCreateNewShow = false;

        }else{
            this.editCreate = false;
        }

        let headerTitle = {
            title:'Navigation Management',
            subTitle:'Menu'
        }

       // subTitle:'Menu Category'
 
        this.commonService.changeHeaderTitle(headerTitle);

            //start code added by mayuri
            this.onProductInfoTabClick();
            //end code added by mayuri
        

        this.dropdownSettings = { 
            singleSelection: false, 
            text:"Select Geo Zone",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['itemName'],
            maxHeight:140,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class"
        }; 

        this.primaryNavigationSettings = {
            singleSelection: false, 
            text:"Select Primary  Navigation",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'],
            maxHeight:100,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class"
        }

        this.ProducTypeSettings = {
            singleSelection: false, 
            text:"Select Product type",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'],
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class"
        }

        // let uid = localStorage.getItem('ud');
        // if (uid !== undefined && uid != null) {

        // }   else {
        //     this.router.navigate(['/login']);
        // }

            //pravin start code
            this.multipleDeviceForm = this.formBuilder.group({
                devices: this.formBuilder.array([this.createItem()])
            });

            this.productTypeMappingFilter.geoZoneId = [];
            this.primaryNavigationMappingFilter.productTypeId = [];

            this.getGeoZoneInfo({});
            // this.getProductTypeInfo(this.productTypeMappingFilter);
            // this.getNavigationInfo(this.primaryNavigationMappingFilter);

            // this.activatedRoute.params.subscribe(params => {
                this.id = id; // (+) converts string 'id' to a number
                
                // this.langId = +params['langId']? +params['langId'] :"1";
                this.langId = "1";
                
                if(this.id!='null'){
                    this.update = true;
                }else{

                    let obj = {
                        'index':'',
                        'deviceType':'',
                        'selectedFiles':'',
                        'fileName': '',
                        'filePath': "",
                        'fileType':''
                    }
        
                   this.uploadImgFilePath.push(obj)

                    this.img.push({
                        imgUrl:''
                    })
                }  
                
                if(this.update){
                    this.getMenuCategory(this.id);
                 }
            // });

            
       

        this.createMenuCategoryForm = this.formBuilder.group({
            // baseUrl: ['',Validators.compose([Validators.pattern('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),Validators.required])],
            // mapPrimaryNavigation: [[],Validators.compose([Validators.required])],
            // dtypeId: ['', Validators.compose([Validators.required])],
            // deviceOrientation: ['', Validators.compose([Validators.required])],
            // label: ['', Validators.compose([Validators.required])],
            mapPrimaryNavigation: [[],Validators.compose([Validators.required])],
            displayIndexing:['',Validators.pattern(displayIndexingPattern)],
            isActive:['1'],
            mapGeoZone: [[], Validators.compose([Validators.required])],
            title: ['', Validators.compose([Validators.required])],
            mapProductType:[[],Validators.compose([Validators.required])],
            exclusionText: [''],
            sameForAllZone:[''],
            // sameForAllDeviceType:[''],
            sameForAllProductType:[''],
            productDeviceTypeConfigure:[''],
            sameConfigurationDeviceType: [''],
            seoTitle:[''],
            seoKeyword:[''],
            seoDescription:[''],
            companyId:[''],
            langId: [this.langId],
            menuCategoryType:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

        });

    }




        get l() { return this.createMenuCategoryForm.controls; }
    
        get m(): FormArray {
            return this.multipleDeviceForm.get('devices') as FormArray;
        }


        savecreateMenuCategory(){

            this.langId = localStorage.getItem('langId');
            this.createMenuCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'))

            //remove empty file upload img obj from array.

          let  tempUploadImgFilePath = this.uploadImgFilePath;
          this.uploadImgFilePath = [];

          tempUploadImgFilePath.forEach((element , i) => {

                if( element.selectedFiles !='') {
    
                this.uploadImgFilePath.push(element);
            
                }
                
            });

            console.log("this.uploadImgFilePath>>",this.uploadImgFilePath);
            this.createMenuCategorySubmitted = true;
            // this.loader = true;
            this.commonService.changeloader(true);

            if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
                // this.loader = false;
                this.commonService.changeloader(false);
                // console.log(" this.loader>>1", this.loader);
                return;
    
            }

            if(this.deviceTypeDublicate){
                // this.loader = false;
                this.commonService.changeloader(false);
                // console.log(" this.loader>>2", this.loader);
                return;
            }

            // this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
        

           if(this.createMenuCategoryForm.invalid || this.multipleDeviceForm.invalid)
           {
            // this.loader = false;
                this.commonService.changeloader(false);
                console.log(" this.loader>>3", this.createMenuCategoryForm);
                console.log(" this.loader>>3", this.multipleDeviceForm.invalid);
          
            return;
           }
          
           console.log("this.12345>>",this.multipleDeviceForm.value);
           if(this.multipleDeviceForm.value.devices.length == 0){
                // this.loader = false;
                this.commonService.changeloader(false);
                // console.log(" this.loader>>4", this.loader);
                return;
           }
        
           if(!this.multipleDeviceForm.valid){
            // this.loader = false;
                this.commonService.changeloader(false);
            return;
          }
            this.devicesReqError = '';
        
            let count = 0;
            let varialbeArray = [];
            let async = this.uploadImgFilePath.length;

            console.log("this.12345>>",this.createMenuCategoryForm.value);     
            if(this.update){

                if(this.roleAsses.menuCategoryEdit== false){

                    this.toastrService.success('no access to edit.');

                    this.commonService.changeloader(false);
                    return;
                }
                
                let asyncvariables = this.multipleDeviceForm.value.devices.length;

                if(this.uploadImgFilePath.length == 0){

                this.createMenuCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createMenuCategorySubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }
                                
                                if(!this.createMenuCategoryForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }
                                
                                this.createMenuCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("me1 alo>>>1",)
                                   this.postMenuCategory()
                                   //New Code for right side for from
                                   //this.onContentReadyToReview();
                
                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);

                }

                if(this.uploadImgFilePath.length > 0){

                    for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.menuCategory).then(res => {

                            if (res && res.Location) {
                                async--;
                                this.uploadImgFilePath[i].filePath=res.Location;   
                            }

                            if (async == 0) {

                                for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                    asyncvariables--;
                                    for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
                    
                                        if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId){
                    
                                            this.multipleDeviceForm.value.devices[i].fileName=this.uploadImgFilePath[j].fileName;
                                            this.multipleDeviceForm.value.devices[i].filePath=this.uploadImgFilePath[j].filePath;
                                            this.multipleDeviceForm.value.devices[i].fileType=this.uploadImgFilePath[j].fileType;
                    
                                        }
                    
                                    }
                                }

                                if(asyncvariables == 0){

                                    this.createMenuCategorySubmitted = true;
                                    console.log("this.multipleDeviceForm.value>>",this.multipleDeviceForm.value);
                                    console.log("this.createMenuCategoryForm.value>>",this.createMenuCategoryForm.value);

                                    if(!this.multipleDeviceForm.valid){
                                        // this.loader = false;
                                    this.commonService.changeloader(false);
                                        return
                                    }
                                    
                            
                                    if(!this.createMenuCategoryForm.valid){
                                        // this.loader = false;
                                     this.commonService.changeloader(false);
                                        return
                                    }
                                    
                                    this.createMenuCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                    console.log("me alo2>>>",)
                                    this.postMenuCategory();
                                    //New Code for right side for from
                                   // this.onContentReadyToReview();
                                }
                            }
                        })
                    }
                }

            }
            
            if(!this.update){
                
                if(this.roleAsses.menuCategoryCreate== false){

                    this.toastrService.success('no access to create.');

                    this.commonService.changeloader(false);
                    return;
                }

                if(this.uploadImgFilePath.length>0){

                    for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.menuCategory).then(res => {

                            if (res && res.Location) {
                                    async--;
                                    this.uploadImgFilePath[i].filePath=res.Location;   
                                    console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i].filePath); 
                                    if (async == 0) {

                                        for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){

                                                    varialbeArray.push(
                                                    {
                                                        'label':this.multipleDeviceForm.value.devices[i].label,
                                                        'description':this.multipleDeviceForm.value.devices[i].description,
                                                        'uploadImage':'',
                                                        // 'mapWithHeaderOrBurgerMenu':this.multipleDeviceForm.value.devices[i].mapWithHeaderOrBurgerMenu,
                                                        'isCarouselApplicable':this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                                                        'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                                                        'caption':this.multipleDeviceForm.value.devices[i].caption,
                                                        'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                                                        'fileName':this.uploadImgFilePath[i].fileName,
                                                        'filePath': this.uploadImgFilePath[i].filePath,
                                                        'fileType': this.uploadImgFilePath[i].fileType
                                                    });   

                                        }

                                        let asyncvariables = varialbeArray.length;
                                        console.log("varialbeArray",varialbeArray);
                                        for(let i=0;i< varialbeArray.length;i++){

                                            asyncvariables--;
                                            for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId){

                                                    varialbeArray[i].fileName=this.uploadImgFilePath[j].fileName;
                                                    varialbeArray[i].filePath=this.uploadImgFilePath[j].filePath;
                                                    varialbeArray[i].fileType=this.uploadImgFilePath[j].fileType;

                                                }

                                            }
                                        }

                                        if(asyncvariables == 0){

                                            this.createMenuCategorySubmitted = true;
                                            console.log("multipleDeviceForm",this.multipleDeviceForm);
                                            console.log("createMenuCategoryForm",this.createMenuCategoryForm);
                                            if(!this.multipleDeviceForm.valid){
                                                // this.loader = false;
                                                this.commonService.changeloader(false);
                                                return
                                            }
                                            
                                    
                                            if(!this.createMenuCategoryForm.valid){
                                                // this.loader = false;
                                                this.commonService.changeloader(false);
                                                return
                                            }
                                        
                                            this.createMenuCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                            console.log("me alo3>>>",)
                                            this.postMenuCategory();
                                             //New Code for right side for from
                                           // this.onContentReadyToReview();
                                        
                                        }

                                    }      
                            }

                        });
                    }
                }

                if(this.uploadImgFilePath.length==0){
                    
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 

                    varialbeArray.push(
                    {
                        'label':this.multipleDeviceForm.value.devices[i].label,
                        'description':this.multipleDeviceForm.value.devices[i].description,
                        'uploadImage':'',
                        // 'mapWithHeaderOrBurgerMenu':this.multipleDeviceForm.value.devices[i].mapWithHeaderOrBurgerMenu,
                        'isCarouselApplicable':this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                        'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                        'caption':this.multipleDeviceForm.value.devices[i].caption,
                        'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                        'fileName':'',
                        'filePath': '',
                        'fileType': '',
                    });   

                }

                this.createMenuCategorySubmitted = true;

                console.log("his.multipleDeviceForm",this.multipleDeviceForm.value);
                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                this.commonService.changeloader(false);
                    return
                }
                
                console.log("his.createMenuCategoryForm",this.createMenuCategoryForm.value);
                if(!this.createMenuCategoryForm.valid){
                    // this.loader = false;
                this.commonService.changeloader(false);
                    return
                }
                this.createMenuCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                console.log("me alo4>>>",)

                 this.postMenuCategory()
                //New Code for right side for from
              ///  this.onContentReadyToReview();

                }
            }
            
        }

        postMenuCategory(){

            console.log("me alo>>>",)

            // this.loader=true;
                this.commonService.changeloader(true);

            if( this.update == false ) {

                delete this.createMenuCategoryForm.value.id;
                console.log("this.createMenuCategoryForm.value>>",this.createMenuCategoryForm)

                this.menuCategoryService.insertMenuCategory(this.createMenuCategoryForm.value)
                .subscribe((result:any)=>{
                
                    // this.loader=false;
                    this.commonService.changeloader(false);
                    this.uploadImgFilePath=[];
                    this.spinnerService.hide();
                    //New Code for right side for from
                   this.update = false;
                   this.updateSend.emit();
                   this.resetForm();
                   this.createMenuCategorySubmitted = false;
                //    this.onProductInfoTabClick();
                    // this.router.navigate(['navigation/menu_category/list']);
                     this.toastrService.success('Menu Category Created Successfully.');
                });
                
            }
                
            if(this.update) {   
                
                this.createMenuCategoryForm.controls['id'].setValue(this.id);
                this.menuCategoryService.updateMenuCategory(this.createMenuCategoryForm.value)
                .subscribe((result:any)=>{

                    
                    // this.loader=false;
                   this.commonService.changeloader(false);
                    this.uploadImgFilePath=[];
                    this.spinnerService.hide();
                    //New Code for right side for from
                   this.update = false;
                   this.updateSend.emit();
                   this.resetForm();
                   this.createMenuCategorySubmitted = false;
                //    this.onProductInfoTabClick();

                    // this.router.navigate(['navigation/menu_category/list']);
                     this.toastrService.success('Menu Category Updated Successfully.');
                });
            }

            console.log("createPrimaryNavigationForm>>>",this.createMenuCategoryForm.value)
        }

        //pravin code start
        getGeoZoneInfo(obj){
        
            // this.loader=true;
                this.commonService.changeloader(true);
            
            this.productConfigurationService.getAllGeoZone(obj,this.start,10)
            .subscribe((result:any)=>{

                // this.loader=false;
                this.commonService.changeloader(false);
                
                if(result.data.data.length > 0){

                    if(this.isSearch == true){
                        this.geoZoneList = [];
                        this.isSearch = false;
                    }
                        
                    this.dropdownList = result.data.data;

                    for (let geoZoneObj of this.dropdownList) {

                        this.geoZoneList.push(
                        {
                            'id': geoZoneObj.geoZoneId,
                            'itemName': geoZoneObj.geoZoneName
                        });

                        this.geoZoneList = this.removeDuplicates(this.geoZoneList, "id");

                    }
                    // this.getglobalproducttype();
                    
                }

            }) 
        }

            
        fetchMoreGetGeoZone(event){

            if ((event.endIndex === this.geoZoneList.length - 1) && (this.geoZoneList.length>=9)) {

            this.start = this.start + 10;

            this.getGeoZoneInfo(this.geoZoneObj);
                
            }

        }


        private setupSearchDebouncer(): void {
    
            // Subscribe to `searchDecouncer$` values,
            // but pipe through `debounceTime` and `distinctUntilChanged`
            this.searchDecouncer$.pipe(
              debounceTime(1000),
              distinctUntilChanged(),
            ).subscribe((term: string) => {
    
             
                if(this.activeSearchBox == 'geoZone'){
                    
                    this.getGeoZoneInfo(this.geoZoneObj);       
          
                }  
        
                if(this.activeSearchBox == 'productType'){
        
    
                    this.getProductTypeInfo(this.productTypeMappingFilter);
                    
                }
        
                if(this.activeSearchBox == 'navigation'){
        
                    this.getNavigationInfo(this.primaryNavigationMappingFilter);     
        
                } 
         
    
            });
        }
        
        private searchDecouncer$: Subject<string> = new Subject();
        activeSearchBox:any='';
        eventValue:any='dse';

        onSearch(event, info){

            if(this.eventValue == '' && event.target.value == '')
                return;

            this.activeSearchBox = info;
            this.eventValue = event.target.value;
            var obj = {};
            this.isSearch = true;
    
            
            if(info == 'geoZone'){
    
                if(event.target.value!=''){
    
                    obj = { 'keyword': event.target.value };
                    this.start = 0;
                    this.geoZoneList = [];      
                    this.geoZoneObj = obj ;
                    // setTimeout(()=>{
    
                    //    this.getGeoZoneInfo(this.geoZoneObj); 
    
                    // },200)
                    this.searchDecouncer$.next(event.target.value);
    
            
                }else {
    
                    obj = { };
                    this.geoZoneObj = obj ;
                    this.geoZoneList = [];      
                    this.start = 0;
                    // setTimeout(()=>{
    
                    //    this.getGeoZoneInfo(this.geoZoneObj); 
    
                    // },200)
                    this.searchDecouncer$.next(event.target.value);
    
    
    
                }
      
            }  
    
            if(info == 'productType'){
    
                console.log("producttype",event);
    
                if(event.target.value!=''){
    
                    this.productTypeMappingFilter.productTypeName = event.target.value;
                    this.ProducTypeList = [];
                    this.startProductType = 0;
                   // this.subject.next(event.target.value);
                   this.searchDecouncer$.next(event.target.value);
                    //this.getProductTypeInfo(this.productTypeMappingFilter);
    
        
                }else {
    
                     this.productTypeMappingFilter.productTypeName = undefined;
                    this.ProducTypeList = [];      
                    this.startProductType = 0;
                    // this.subject.next(event.target.value);
                    this.searchDecouncer$.next(event.target.value);
                    //  this.getProductTypeInfo(this.productTypeMappingFilter);
    
                }
    
                
            }
    
            if(info == 'navigation'){

                console.log("navigation>>");
    
                if(event.target.value!=''){
    
                    this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                    this.primaryNavigationList = [];
                    this.startNavigation = 0;
                    this.searchDecouncer$.next(event.target.value);
    
                }else {
    
                     this.primaryNavigationMappingFilter.primanyNavigationName = undefined;
                    this.primaryNavigationList = [];
                    this.startNavigation = 0;
                    // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                    this.searchDecouncer$.next(event.target.value);
    
    
                }
    
            } 
        }

        onOpen(info){

            this.activeSearchBox = info;
          
                 if(this.activeSearchBox == 'geoZone'){
         
                     this.geoZoneObj = {};
                     this.start = 0;
                     this.geoZoneList = [];
                     this.getGeoZoneInfo(this.geoZoneObj);       
         
                 }  
         
                 if(this.activeSearchBox == 'productType'){

                   var geoZoneId= this.productTypeMappingFilter.geoZoneId
         
                     this.productTypeMappingFilter = {};
                     this.startProductType = 0;
                     this.productTypeMappingFilter.geoZoneId = geoZoneId;


                     this.ProducTypeList = [];
                     this.getProductTypeInfo(this.productTypeMappingFilter);
                     
                 }
         
                 if(this.activeSearchBox == 'navigation'){
         
                     this.primaryNavigationMappingFilter.primanyNavigationName= undefined;
                     var productTypeId = this.primaryNavigationMappingFilter.productTypeId;
                     this.primaryNavigationMappingFilter = {};
                     this.startNavigation = 0;
                     this.primaryNavigationMappingFilter.productTypeId = productTypeId;

                       this.primaryNavigationList=[];
                       this.getNavigationInfo(this.primaryNavigationMappingFilter);     
        
                 } 

         }


       //geoZone dropdown code end
        mapProductTypeArrayTemp = [];
        getProductTypeInfo(filterObj){
            
            this.loader = true;

            this.commonService.getGlobalProductType(this.productTypeMappingFilter,this.startProductType,10).subscribe((result:any)=>{

                this.ProducTypeDropdownList = [];
   
                let mapProductTypeArray = this.createMenuCategoryForm.value.mapProductType;
                this.loader = false;
                let asyc = result.data.data.length;

                if(result.data.data.length > 0){  
                    
                    if(this.isSearch == true){
                        this.ProducTypeList = [];
                        this.isSearch = false;
                    }

                    this.ProducTypeDropdownList = result.data.data;
                    for (let ProducTypeLists of this.ProducTypeDropdownList) {

                        asyc--;

                        mapProductTypeArray.forEach(element => {

                            if(element.id == ProducTypeLists.productTypeId) {
                                this.mapProductTypeArrayTemp.push(element);
                            }

                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp , "itemName");
                    
                        });

                 

                        if(ProducTypeLists.productTypeName!=''){

                            this.ProducTypeList.push(
                                {
                                    'id': ProducTypeLists.productTypeId,
                                    'itemName': ProducTypeLists.productTypeName
                                });

                            this.ProducTypeList = this.removeDuplicates(this.ProducTypeList , "id");
                                
                        }
                        
                    }

                    if(asyc == 0){

                        this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp, 'id');

                    setTimeout(()=>{

                        this.loader = false;
                        this.createMenuCategoryForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);

                    },1000)

                        if(this.mapProductTypeArrayTemp.length == 0){
     
                         this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                         this.primaryNavigationList = [];
                                                  
                        }else {
     
                         this.getNavigationInfo(this.primaryNavigationMappingFilter);
     
                        }
                 
                    }

                    console.log('ProducTypeList===>',this.ProducTypeList);
                 }else{
                //     this.createMenuCategoryForm.controls['mapProductType'].setValue([]);
                //     this.ProducTypeList = [];
    
                //     this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                //     this.primaryNavigationList = [];
                } 

                
            }) 
        }

        //pravin code end

        removeDuplicates(originalArray, prop) {

            var newArray = [];
            var lookupObject  = {};
        
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
        
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
                return newArray;
        }

        fetchMoreGetProductType(event){

            if ((event.endIndex === this.ProducTypeList.length - 1) && (this.ProducTypeList.length>=9)) {

                this.startProductType = this.startProductType + this.limit;

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }
        }

        getMenuCategory(id){

            // this.loader=true;
                this.commonService.changeloader(true);
            this.menuCategoryService.getMenuCategory(id, this.langId)
            .subscribe((result:any)=>{

                this.searchDecouncer$.next(null);

                // this.loader=false;
                this.commonService.changeloader(false);
                console.log("result123,",result.data[0]);
                this.createMenuCategoryForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
                this.createMenuCategoryForm.controls['title'].setValue(result.data[0].title);
                this.createMenuCategoryForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
                this.createMenuCategoryForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
                this.createMenuCategoryForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
                this.createMenuCategoryForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);
                this.createMenuCategoryForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
                this.createMenuCategoryForm.controls['menuCategoryType'].setValue(result.data[0].menuCategoryType);
                this.createMenuCategoryForm.controls['isActive'].setValue(result.data[0].isActive.toString());


                this.createMenuCategoryForm.controls['publisherState'].setValue(result.data[0].publisherState);
                this.createMenuCategoryForm.controls['reviewerState'].setValue(result.data[0].reviewerState);


                if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                    this.createMenuCategoryForm.controls['contentState'].setValue(['In-Progress']);
                }
                else{
                    this.createMenuCategoryForm.controls['contentState'].setValue(result.data[0].contentState);
                }
                
                this.selectedItems=[];
                this.selectedProductlist=[];

                if(result.data[0].results.productType.length>0){

                    for(let i = 0 ;i < result.data[0].results.productType.length;i++){

                        let obj={

                            'id':result.data[0].results.productType[i].productTypeId,
                            'itemName':result.data[0].results.productType[i].productTypeName,
                        }

                        this.selectedProductlist.push(obj);
                        this.mapProductTypeArrayTemp = this.selectedProductlist;
                        this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp , "itemName");
                        this.primaryNavigationMappingFilter.productTypeId.push(obj.id);
                    }
                    setTimeout(()=>{

                        this.createMenuCategoryForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);

                    },2000)

                    // console.log(" this.createMenuCategoryForm", this.createMenuCategoryForm.value.mapProductType);

                }

                this.selectedprimaryNavigationList = [];

                if(result.data[0].results.primaryNavigation.length>0){

                    result.data[0].results.primaryNavigation.forEach(element => {
                        
                        let objPrimaryNavigation = {

                            'id':element.primaryNavigationId,
                            'itemName':element.primaryNavigationName,

                        }

                        this.selectedprimaryNavigationList.push(objPrimaryNavigation);
                        this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
                        this.mapPrimaryNavigationArrayTemp = this.selectedprimaryNavigationList;

                    });

                }

                if(result.data[0].results.geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){
                        let obj={
                        'id':result.data[0].results.geoZone[i].geoZoneId,
                        'itemName':result.data[0].results.geoZone[i].geoZoneName,
                        }
                        this.selectedItems.push(obj);
                        this.productTypeMappingFilter.geoZoneId.push(obj.id);                    
                    }
                    this.getProductTypeInfo(this.productTypeMappingFilter);
                }
                        
                if(result.data[0].results.productDeviceTypeConfigure.length>0){

                        this.img=[];


                        this.multipleDeviceForm = this.formBuilder.group({
                            devices: this.formBuilder.array([])
                        });

                        //device type validation code start
                    if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                        this.deviceTypeFull = true;
                    }
                  //device type validation code end
                   console.log("123",result.data[0].results.productDeviceTypeConfigure);
                            
                        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                        for(let i=0;i<result.data[0].results.productDeviceTypeConfigure.length;i++){

                            this.img.push({
                                'imgURL':result.data[0].results.productDeviceTypeConfigure[i].filePath
                            })

                            //for imgUplaod obj.
                            let obj = {
                                'index':'',
                                'deviceType':'',
                                'selectedFiles':'',
                                'fileName': '',
                                'filePath': "",
                                'fileType':''
                            }
                
                        this.uploadImgFilePath.push(obj);

                            this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                        
                        }
                }
            
                this.createMenuCategoryForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
            });
        }

        mapPrimaryNavigationArrayTemp = [];
        getNavigationInfo(filterObj){

            //    this.loader = true;
                console.log("filterObj = ",filterObj);
            
                this.commonService.getGlobalPrimaryNavigation(filterObj,this.startNavigation,this.limit).subscribe((result:any)=>{
        
                let mapPrimaryNavigationArray = this.createMenuCategoryForm.value.mapPrimaryNavigation;

                this.searchDecouncer$.next(null);

                if(result.data.data.length > 0){  
                    
                    if(this.isSearch == true){

                        this.primaryNavigationList =[];
                        this.isSearch = false;

                    }
                    for (let primaryNavigationLists of result.data.data) {
        
                        mapPrimaryNavigationArray.forEach(element => {

                            if(element.id == primaryNavigationLists.primaryNavigationId) {

                                this.mapPrimaryNavigationArrayTemp.push(element);
                                this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp , "id");

                                this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);
                         
                            }
                          

                        });

                        if(primaryNavigationLists .primaryNavigationId != undefined && primaryNavigationLists .primanyNavigationName != undefined){

                        this.primaryNavigationList.push(
                            {
                                'id': primaryNavigationLists.primaryNavigationId,
                                'itemName': primaryNavigationLists.primanyNavigationName
                            });
                        }
                        
                        this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList , "id");
                    
                    }
                } 

            })
        }

        fetchMoreGetPrimaryNaviagation(event){

            if ((event.endIndex === this.primaryNavigationList.length - 1) && (this.primaryNavigationList.length>=9)) {

                this.startNavigation = this.startNavigation + this.limit;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);
                
            }

        }

        //Pravin code mulitple from
        createItem(): FormGroup {

            return this.formBuilder.group({        
                label: ['',Validators.required],
                description: '',
                uploadImage: '',
                fileName: "",
                filePath: "",
                fileType: "",
                isVisible:"",
                caption:"",
                // mapWithHeaderOrBurgerMenu :'',
                isCarouselApplicable:'',
                dtypeId:['',Validators.required],
            });
        }
    
        onDtypeId(){
            this.All=false;
            // this.Web=false;
            // this.Android=false;
            // this.iOS=false;
        }

        onDeviceType(i){

            this.devices = this.multipleDeviceForm.get('devices') as FormArray;
            let devices = this.devices;

            this.uploadImgFilePath[i].deviceType = devices.value[i].dtypeId;
            this.uploadImgFilePath[i].index = 1;
    
            //device type validation code start
            var all = 0;
            var Web = 0;
            var android = 0;
            var iOS = 0;
            var roku = 0 ;
            this.all = false;
            this.Web = false;
            this.android = false;
            this.iOS = false;
            this.roku = false;
        
            for(let i = 0;i< devices.value.length;i++){
    
                if(devices.value[i].dtypeId=='1'){
                    Web++;
                    this.Web=true;
                }
                
                if(devices.value[i].dtypeId=='2'){
                    android++;
                    this.android=true;
                }
                if(devices.value[i].dtypeId=='5'){
                    roku++;
                    this.roku=true;
                }
                
                if(devices.value[i].dtypeId=='3'){
                    iOS++;
                    this.iOS=true;
                }
                
                if(devices.value[i].dtypeId=='4'){
                    all++;
                    this.all=true;
                }else{
                    
                }
                
            }
            
            this.deviceTypeDublicate = false;
            this.deviceTypeFull = false;
            
            if(Web == 1 && android == 1 && iOS == 1 && roku == 1){
    
            this.deviceTypeFull = true ;
    
            }else if(all == 1 || this.all == true ){
                
                if(all == 1 && devices.value.length>1){
    
                }
    
            } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){
    
                this.deviceTypeDublicate = true;
    
            }else {
    
            }
            //device type validation code end
    
        }
    
        delInput(index){
          
            this.img.splice(index , 1);
            const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
            arrayControl.removeAt(index);
    
            this.devices = this.multipleDeviceForm.get('devices') as FormArray;
            this.uploadImgFilePath.splice(index, 1);
            let devices = this.devices;
    
           //device type validation code start
            var all = 0;
            var Web = 0;
            var android = 0;
            var iOS = 0;
            var roku = 0;
            this.all = false;
            this.roku = false;
            this.Web = false;
            this.android = false;
            this.iOS = false;
        
            for(let i = 0;i< devices.value.length;i++){
    
                if(devices.value[i].dtypeId=='1'){
                    Web++;
                    this.Web=true;
                }
                
                if(devices.value[i].dtypeId=='2'){
                    android++;
                    this.android=true;
                }
                
                if(devices.value[i].dtypeId=='3'){
                    iOS++;
                    this.iOS=true;
                }
    
                if(devices.value[i].dtypeId=='5'){
                    roku++;
                    this.roku=true;
                }
                
                if(devices.value[i].dtypeId=='4'){
                    all++;
                    this.all=true;
                }else{
                    
                }
                
            }
            
            this.deviceTypeDublicate = false;
            this.deviceTypeFull = false;
            
            if(Web == 1 && android == 1 && iOS == 1 && roku == 1){
    
            this.deviceTypeFull = true ;
    
            }else if(all == 1 || this.all == true ){
                
                if(all == 1 && devices.value.length>1){
    
                }
    
            } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){
    
                this.deviceTypeDublicate = true;
    
            }
    
            //device type validation code end
    
        }
        
        addItem(): void {

            this.img.push({
                imgURL:''
            })

            this.devices = this.multipleDeviceForm.get('devices') as FormArray;
            let devices = this.devices;
          
            //device type validation code start
    
            var all = 0;
            var Web = 0;
            var android = 0;
            var iOS = 0;
            var roku = 0;
            this.all = false;
            this.roku = false;
            this.Web = false;
            this.android = false;
            this.iOS = false;
        
            for(let i = 0;i< devices.value.length;i++){
    
                if(devices.value[i].dtypeId=='1'){
                    Web++;
                    this.Web=true;
                }
                
                if(devices.value[i].dtypeId=='2'){
                    android++;
                    this.android=true;
                }
                
                if(devices.value[i].dtypeId=='3'){
                    iOS++;
                    this.iOS=true;
                }
    
                if(devices.value[i].dtypeId=='5'){
                    roku++;
                    this.roku=true;
                }
                
                if(devices.value[i].dtypeId=='4'){
                    all++;
                    this.all=true;
                }else{
                    
                }
                
            }
            
            this.deviceTypeDublicate = false;
            this.deviceTypeFull = false;
            
            if(Web == 1 && android == 1 && iOS == 1 && roku == 1){
    
            this.deviceTypeFull = true ;
    
            }else if(all == 1 || this.all == true ){
                
                if(all == 1 && devices.value.length>1){
    
                }
    
            } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){
    
                this.deviceTypeDublicate = true;
    
            }else {
    
                this.devices.push(this.createItem());
                
                let obj = {
                    'index':'',
                    'deviceType':'',
                    'selectedFiles':'',
                    'fileName': '',
                    'filePath': "",
                    'fileType':''
                }
    
               this.uploadImgFilePath.push(obj)
            }
    
             //device type validation code end
    
        }
    
       //pravin code start
        updateItem(objProductDeviceTypeConfigure): FormGroup {

        if(objProductDeviceTypeConfigure.dtypeId == '4'){
            this.all = true;
            console.log("all",this.all);
        }

        return this.formBuilder.group({
            label: objProductDeviceTypeConfigure.label,
            description: objProductDeviceTypeConfigure.description,
            uploadImage:objProductDeviceTypeConfigure.uploadImage,
            // mapWithHeaderOrBurgerMenu :objProductDeviceTypeConfigure.mapWithHeaderOrBurgerMenu,
            isCarouselApplicable:objProductDeviceTypeConfigure.isCarouselApplicable,
            isVisible:objProductDeviceTypeConfigure.isVisible,
            caption:objProductDeviceTypeConfigure.caption,
            dtypeId:objProductDeviceTypeConfigure.dtypeId,
            filePath:objProductDeviceTypeConfigure.filePath,
            fileName:objProductDeviceTypeConfigure.fileName,
            fileType:objProductDeviceTypeConfigure.fileType
        });
        // uploadVerticalImage: objProductDeviceTypeConfigure.uploadVerticalImage,
        //     uploadHorizontalImage:objProductDeviceTypeConfigure.uploadHorizontalImage,
        }

        selectFile(e ,i) {

          
                if(e.target.files[0].name==undefined)
                return;

                this.uploadImgFilePath[i].index = i;
                this.uploadImgFilePath[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
                this.uploadImgFilePath[i].selectedFiles = e.target.files.item(0);
                this.uploadImgFilePath[i].fileName =  e.target.files[0].name; 
                this.uploadImgFilePath[i].fileType = e.target.files[0].type;

                var mimeType = e.target.files[0].type;
                if (mimeType.match(/image\/*/) == null) {
                    console.log("Only images are supported.");
                    return;
                }

                var reader = new FileReader();
                
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = (_event) => {
                    // this.imgURL = reader.result;
                    this.img[i].imgURL = reader.result;
                    console.log("this.img[i].",this.img[i]);
                }

                e.target.value = '';

        }

        // multiselect code 
        onItemSelect(item:any, mappingName){
    
            if(mappingName == 'mapGeoZone'){

                this.productTypeMappingFilter.geoZoneId.push(item.id);

                this.startProductType = 0;

                this.getProductTypeInfo(this.productTypeMappingFilter);

            }else if(mappingName == 'mapProductType'){

                this.primaryNavigationMappingFilter.productTypeId.push(item.id);

                this.startNavigation = 0 ;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);

            }
    
        }

        OnItemDeSelect(item:any, mappingName){
            console.log('item',item.id);

            if(mappingName == 'mapGeoZone'){
                for (let index=0; index<this.productTypeMappingFilter.geoZoneId.length;index++){
                    if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                        this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                        console.log("mapGeoZone deleted = ",item.id);

                        if(this.productTypeMappingFilter.geoZoneId.length == 0){
                            this.ProducTypeList = [];

                            this.mapProductTypeArrayTemp = [];
                            this.createMenuCategoryForm.controls['mapProductType'].setValue([]);
                            console.log("empty list= ",mappingName);

                            this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                            this.primaryNavigationList = [];
                            return;
                        }
                        else{

                            this.startProductType = 0;

                            this.getProductTypeInfo(this.productTypeMappingFilter);
                        }

                        
                    }
                }
            }else if(mappingName == 'mapProductType'){

                console.log("this.primaryNavigationMappingFilter.>>",this.primaryNavigationMappingFilter.productTypeId);

                for (let index=0; index<this.primaryNavigationMappingFilter.productTypeId.length;index++){
                    if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){
                        this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);
                        console.log("mapProductType deleted = ",item.id);

                        if(this.primaryNavigationMappingFilter.productTypeId.length == 0){
                            this.primaryNavigationList = [];
                            this.mapProductTypeArrayTemp = [];
                            this.mapPrimaryNavigationArrayTemp = [];
                            this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                            console.log("empty list= ",mappingName);
                            return;
                        }
                        else{

                            this.startNavigation = 0;
                            this.getNavigationInfo(this.primaryNavigationMappingFilter);
                        }

                       
                    }
                }
            }
        }

        onSelectAll(item:any, mappingName){
            console.log("select All from = ",mappingName);

            if(mappingName == 'mapGeoZone'){
                let filterObj ={ geoZoneId :[] };
                item.forEach(function (value) {
                    filterObj.geoZoneId.push(value.id);
                });
                this.productTypeMappingFilter = filterObj;

                this.startProductType = 0;

                this.getProductTypeInfo(filterObj);
            }
            else if(mappingName == 'mapProductType'){
                let filterObj ={ productTypeId :[] };
                item.forEach(function (value) {
                    filterObj.productTypeId.push(value.id);
                });
                this.primaryNavigationMappingFilter = filterObj;

                this.startNavigation = 0;


                this.getNavigationInfo(filterObj);
            }
        }

        onDeSelectAll(event, info){

            if(info =='mapGeoZone'){
                this.createMenuCategoryForm.controls['mapGeoZone'].setValue([]);
                this.ProducTypeList = [];
                this.selectedProductlist = [];
                this.mapProductTypeArrayTemp = [];
                
                this.createMenuCategoryForm.controls['mapProductType'].setValue([]);
                console.log("DeSelect All from = ",info);
            }
            else if(info =='mapProductType'){
                this.createMenuCategoryForm.controls['mapProductType'].setValue([]);
                this.primaryNavigationList=[];
                this.mapProductTypeArrayTemp = [];
                this.mapPrimaryNavigationArrayTemp = [];
                this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                console.log("DeSelect All from = ",info);
            }
            else if(info =='mapPrimaryNavigation'){
                
                this.mapPrimaryNavigationArrayTemp = [];
                this.createMenuCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
            }
        }

        isDeleteDeviceType(event){

            if(event=='Cancel'){
                this.isDeleteAction=false;
            }
            
            if(event=='Yes'){
                this.isDeleteAction=false;
                this.delInput(this.deleteDeviceTypeIndex);
            }

        }

        isDeleteDeviceTypeYes(i){
            this.deleteDeviceTypeIndex = i;
        }


        onMapGeoZoneTabClick(){
            this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = false ;
            this.formTabs.MenuCategoryInfo.seo = false;

            this.formTabs.DeviceType.configuration = false ;
            this.formTabs.DeviceType.horizontal = false ;
            this.formTabs.DeviceType.configurationType = false ;
            this.formTabs.DeviceType.vertical = false ;
           
            this.formTabs.MenuCategoryInfo.mapGeoZone = true ;
            this.formTabs.MenuCategoryInfo.MenuCategory = true ;
    
        }
    
        oninformationTabClick(){ 
            
            // this.createMenuCategoryForm.controls['mapProductType'].setValue([]);
            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp,'id');
            this.loader = true;
            setTimeout(()=>{
                this.loader = false;
                this.createMenuCategoryForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);
    
            },100)
            
            this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.product = false ;
            this.formTabs.DeviceType.configuration = false ;
            this.formTabs.DeviceType.horizontal = false ;
            this.formTabs.DeviceType.configurationType = false ;
            this.formTabs.DeviceType.vertical = false ;
    
            this.formTabs.MenuCategoryInfo.information = true ;
            this.formTabs.MenuCategoryInfo.MenuCategory = true ;
            this.formTabs.MenuCategoryInfo.seo = false;
    
        }
    
        onProductInfoTabClick(){
             this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = false ;
            this.formTabs.DeviceType.configuration = false ;
            this.formTabs.DeviceType.horizontal = false ;
            this.formTabs.DeviceType.configurationType = false ;
            this.formTabs.DeviceType.vertical = false ;
    
            this.formTabs.MenuCategoryInfo.mapGeoZone = true ;
            this.formTabs.MenuCategoryInfo.MenuCategory = true ;
            this.formTabs.MenuCategoryInfo.seo = false;
    
    
        }
    
        onHorizontalTabClick(){
             this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = false ;
            this.formTabs.DeviceType.configuration = false ;
            this.formTabs.DeviceType.vertical = false ;
    
            this.formTabs.DeviceType.horizontal = true ;
            this.formTabs.DeviceType.configurationType = true ;
            this.formTabs.MenuCategoryInfo.seo = false;
    
    
        }
    
        onVerticalTabClick(){
            this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = false ;
            this.formTabs.DeviceType.configuration = false ;
            this.formTabs.DeviceType.horizontal = false ;
        
            this.formTabs.DeviceType.vertical = true ;
            this.formTabs.DeviceType.configurationType = true ;
            this.formTabs.MenuCategoryInfo.seo = false;
    
        }
    
        onDeviceTypeTabClick(){
            this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = false ;
            this.formTabs.DeviceType.horizontal = false ;
            this.formTabs.DeviceType.vertical = false ;
          
            this.formTabs.DeviceType.configuration = true;
            this.formTabs.DeviceType.configurationType = true ;
            this.formTabs.MenuCategoryInfo.seo = false;
    
    
        }

        onSeoTabClick(){
            this.formTabs.MenuCategoryInfo.mapGeoZone = false ;
            this.formTabs.MenuCategoryInfo.information = false ;
            this.formTabs.MenuCategoryInfo.MenuCategory = true ;
            this.formTabs.DeviceType.horizontal = false ;
            this.formTabs.DeviceType.vertical = false ;
          
            this.formTabs.DeviceType.configuration = false;
            this.formTabs.DeviceType.configurationType = false ;
            this.formTabs.MenuCategoryInfo.seo = true;

        }



    isDeleteImage(event){
        console.log("event = ", event);
        if(event=='Cancel'){
            this.isDeleteImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteImageAction=false;

            delete this.img[this.deleteDeviceTypeIndex].imgURL;

            this.uploadImgFilePath[this.deleteDeviceTypeIndex].deviceType = '';
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].selectedFiles = '';
            
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileName="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].filePath="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileType="";

        }

    }
    
    deleteImageYes(i){
        this.deleteDeviceTypeIndex = i;
    }


    //New Code for right side for from
    onContentReadyToReview(){

       
        if(this.createMenuCategoryForm.invalid || this.multipleDeviceForm.invalid)
        {
         // this.loader = false;
         this.createMenuCategorySubmitted = true;
         this.commonService.changeloader(false);
       
         return;
        }


        this.contentReadyToReview = true;
        this.commonService.changeloader(false);
        this.shareMassage = 'review';

    }
    // is Content Ready To Review Received  


    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

        //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
        
        }
        //New Code end

        if(event == 'Yes'){

            this.createMenuCategoryForm.controls['contentState'].setValue(['Completed']);
            // this.postMenuCategory();
            this.savecreateMenuCategory();
        
        }
        if(event == 'Cancel'){

            this.createMenuCategoryForm.controls['contentState'].setValue(['In-Progress']);
            // this.postMenuCategory();
            this.savecreateMenuCategory();
        
        }
   
    }


    resetForm(){

        this.mapPrimaryNavigationArrayTemp = [];
        this.mapProductTypeArrayTemp = [];

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);

        }

        this.createMenuCategoryForm = this.formBuilder.group({
            mapPrimaryNavigation: [[],Validators.compose([Validators.required])],
            displayIndexing:['',Validators.pattern(displayIndexingPattern)],
            isActive:['1'],
            mapGeoZone: [[], Validators.compose([Validators.required])],
            title: ['', Validators.compose([Validators.required])],
            mapProductType:[[],Validators.compose([Validators.required])],
            exclusionText: [''],
            sameForAllZone:[''],
            // sameForAllDeviceType:[''],
            sameForAllProductType:[''],
            productDeviceTypeConfigure:[''],
            sameConfigurationDeviceType: [''],
            seoTitle:[''],
            seoKeyword:[''],
            seoDescription:[''],
            companyId:[''],
            langId: [this.langId],
            menuCategoryType:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]
        });
        
        //reset multiselect list
        this.selectedItems=[];
        this.selectedProductlist=[];
        this.selectedprimaryNavigationList=[];

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });

        this.uploadImgFilePath =[];

        //reset device type image
        this.img = [];

        this.img.push({
            imgURL:''
        })

        this.update = false;

        this.commonService.changeloader(false);
        this.uploadImgFilePath = [];
        
        let obj = {
            'index':'',
            'deviceType':'',
            'selectedFiles':'',
            'fileName': '',
            'filePath': "",
            'fileType':''
        }

       this.uploadImgFilePath.push(obj)

        this.spinnerService.hide();
        //New Code for right side for from
        this.update = false;
        this.updateSend.emit();

        //////////////////////

        this.showCreateNewButton('show');

    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
    
}
