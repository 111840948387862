import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FooterLinkService {

  constructor(private http: HttpClient) { }

  updateFooterlink(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateFooterlinkUrl, postObj,httpOptions);
  }
        
  insertFooterlink(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertFooterlinkUrl, postObj,httpOptions);
  } 

  getFooterlink( id: any) : Observable<any> {
    
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getFooterlinkUrl+"/" + id);
  }

  getAllFooterlink(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllFooterlink = environment.apiURL + environment.adminApiUrl + environment.getFooterlinkUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllFooterlink)
  }

  deleteFooterlink(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteFooterlinkUrl, postObj,httpOptions);
  }
}
