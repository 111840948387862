import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CharacterService {

  
  constructor(private http: HttpClient) { }

  updateCharacter(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateCharacterUrl, postObj,httpOptions);

  }
        
  insertCharacter(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertCharacterUrl, postObj,httpOptions);

  }    
  
  getCharacter( id: any) : Observable<any> {
      
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getCharacterUrl+"/" + id);
  }

  getAllCharacter(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getCharacterUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllCast)

  }

  deleteCharacter(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteCharacterUrl, postObj,httpOptions);
      
  }
  
}
