import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {primaryNavigationType} from '../../../../../configurations/configuration';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import {ProductTypeService} from '../../../../../services/product_type/product_type.service';
import {PrimaryNavigationService} from '../../../../../services/primary_navigation/primary_navigation.service';
import { CommonService } from './../../../../../services/common_Service/common.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {awsPath, deviceType, displayIndexingPattern} from '../../../../../configurations/configuration';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import { splitClasses } from '@angular/compiler';

import {Observable}  from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-addPrimaryNavigation',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentPrimaryNavigation implements OnInit {

    isCreateNewShow:boolean = true;

    contentReadyToReview:boolean= false;
  
    deviceType = deviceType;
    img:any=[];

    reset:boolean = false;

    isSearch:boolean =false;
    
    shareMassage :any;
    loader:boolean=false;

    geoKeyword:any='ram';

    productTypeScrollCount:number = 0;
    isUserLoggedIn: boolean = false;
    primaryNavigationType = primaryNavigationType
    createPrimaryNavigationForm:FormGroup;
    errorMessages = errorMessages;
    createPrimaryNavigationSubmitted = false;
    addExtra:boolean=false;
    dropdownSettings = {};
    selectedGeoZone = [];
    selectedProductlist=[];
    dropdownList=[];
    geoZoneList=[];
    start:number=0;
    startProductType:number = 0;
    limit:number=10;            
    ProducTypeDropdownList = [];
    ProducTypeList = [];
    ProducTypeSettings={};
     //pravin code start
     multipleDeviceForm:FormGroup;
     devices: FormArray;
     id:any;
     update=false;
     dTypeDublicateError:any;
     //pravin code end 
     All:boolean;

     infoObj:any ={};
     Web:boolean;
     android:boolean;
     iOS:boolean;
     selectedFiles: any=[];
     uploadImgFilePath:any=[];

     deviceTypeFull:boolean = false;
     deviceTypeDublicate:boolean = false;

     all:boolean = false;
     roku:boolean = false;

     filterObjProductType = {};
    
     isDeleteAction: boolean=false;
     deleteDeviceTypeIndex:any;
     isDeleteImageAction: boolean=false;

    //start mapping filter
    productTypeMappingFilter:any={};
    //end mapping filter

    applyBlackTheme = localStorage.getItem('boolThemeFlag');

     
    formTabs:any = {

        primaryNavigationInfo:{

         primaryNavigation:true,
         mapGeoZone:true,
         information:false,
         seo:false
        },
        DeviceType:{
            configurationType:false,
            configuration:false,
            uploadImage:false,
        },
        // seo:false
    }
    
    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
    @Input() set contentId(value: any) {

        this.start = 0 ;

        this.productTypeScrollCount = 0;

        this.startProductType = 0;

        if(value == 'reset'){

            this.reset = true;
     
            this.resetForm();

        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end
     
    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }


    langId:any;

    constructor(private commonService:CommonService, private primaryNavigationService:PrimaryNavigationService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private productTypeService:ProductTypeService,private productConfigurationService:ProductConfigurationService,private spinnerService:Ng4LoadingSpinnerService,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private formBuilder: FormBuilder) {
        
        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );
        
        
        this.activatedRoute.params.subscribe(params => {
                this.id = +params['id']; // (+) converts string 'id' to a number
                this.langId = +params['langId']? +params['langId'] :"1";
                //new code start

                console.log("this.id",this.id);
                if(this.id!='null'){

                    this.update = true;

                }else{

                    this.update = false; 
               //new code start end
            let obj = {
                'index':'',
                'deviceType':'',
                'selectedFiles':'',
                'fileName': '',
                'filePath': "",
                'fileType':''
            }

           this.uploadImgFilePath.push(obj)

                    this.img.push({
                        imgUrl:''
                    })
                }
            // In a real app: dispatch action to load the details here.
        });
        //end pravin   
        
    }

 
    roleAsses:any={};
    roleConfiguration:any=[];
    editCreate:boolean = false;

    onCreateNew(){

        if(this.roleAsses.primaryNavigationCreate == true){
               this.editCreate = true;
        }
        this.isCreateNewShow=false

    }

    ngOnInit(){

          this.roleAsses.primaryNavigationEdit = false;
          this.roleAsses.primaryNavigation = false;
          this.roleAsses.primaryNavigationCreate = false;


          this.roleConfiguration = SessionHelper.getRole();

          this.roleConfiguration.forEach((element , i) => {

                if((element.isView == 1 && element.pageTitle == 'Primary Navigation') ){
                    
                    this.roleAsses.primaryNavigation = true;
                }
            
                if((element.isEdit == 1 && element.pageTitle == 'Primary Navigation') ){
                    
                    this.roleAsses.primaryNavigationEdit = true;
                }
                
                if((element.isCreate == 1 && element.pageTitle == 'Primary Navigation')){

                    this.roleAsses.primaryNavigationCreate = true;
                
                }

          })

        // theme update code start

        this.setupSearchDebouncer();

                
         this.commonService.currentThemelight.subscribe((result:any)=>{

            if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='primaryNavigation'){

                let item = JSON.parse(localStorage.getItem('langItem'));
                
                if(item.id!=undefined)
                  this.id = item.id;
                  
                this.createPrimaryNavigationForm.controls['id'].setValue(this.id);

                if(item.id != null && this.roleAsses.primaryNavigationEdit == true){

                    this.editCreate = true;
                    this.update = true;
                    this.createPrimaryNavigationForm.controls['primaryNavigationType'].setValue([]);

                }else{

                    this.editCreate = false;
                }

                this.createPrimaryNavigationForm.controls['langId'].setValue(localStorage.getItem('languageId'));

                this.isCreateNewShow = false;
                this.getPrimaryNavigation(item.id);
                // localStorage.removeItem('langItem');

            }
               this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
          })
  
          // theme update code start

    }

    

  private searchDecouncer$: Subject<string> = new Subject();
  activeSearchBox:any='';


  //New Code for right side for from
    onLoad(id) {

        console.log("id>>>",id);

        this.id = id;

        if(this.id != 'null' && this.roleAsses.primaryNavigationEdit == true){
            
            this.editCreate = true;
            this.isCreateNewShow = false;


        }else{
            this.editCreate = false;
        }

        this.productTypeScrollCount = 0;

        this.langId = localStorage.getItem('LanguagId');

        this.loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );
        
        this.id = id; // (+) converts string 'id' to a number

       //New Code start
       console.log("id123",this.id);

        if(this.id!='null'){

            this.update = true;
            this.getPrimaryNavigation(this.id);


        }else{

            this.update = false;
        //New Code start
        let obj = {
            'index':'',
            'deviceType':'',
            'selectedFiles':'',
            'fileName': '',
            'filePath': "",
            'fileType':''
        }

        this.uploadImgFilePath.push(obj)

            this.img.push({
                imgUrl:''
            })
        }
    // In a real app: dispatch action to load the details here.

        let headerTitle = {
            title:'Navigation Management',
            subTitle:'Primary'
 
        }
 
        this.commonService.changeHeaderTitle(headerTitle);
        
        this.onPrimaryNavigationInfoTabClick();
           //pravin code start
           this.dropdownSettings = { 
            singleSelection: false, 
            text:"Select Geo Zone",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'],
            lazyLoading: true,
            maxHeight:130,
            minHeight:30,
            classes:"myclass custom-class"
        }; 

        this.ProducTypeSettings = {
            singleSelection: false, 
            text:"Select Product type",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'],
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class"
        }
        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

              //pravin start code
              this.multipleDeviceForm = this.formBuilder.group({
                devices: this.formBuilder.array([this.createItem()])
            });

            this.productTypeMappingFilter.geoZoneId = [];
            this.getGeoZoneInfo({});

        this.createPrimaryNavigationForm = this.formBuilder.group({
           
            isActive:['1'],
            mapGeoZone: [[], Validators.compose([Validators.required])],
            title: ['', Validators.compose([Validators.required])],
            mapProductType:[[],Validators.compose([Validators.required])],
            exclusionText: [''],
            sameForAllZone:[''],
            // sameForAllDeviceType:[''],
            sameForAllProductType:[''],
            productDeviceTypeConfigure:[''],
            // sameConfigurationDeviceType: [''],
            seoTitle:[''],
            seoKeyword:[''],
            seoDescription:[''],
            companyId:[''],
            langId: ['1'],
            randomCarousel:[''],
            randomPagecategory:[''],
            displayIndexing:['',Validators.pattern(displayIndexingPattern)],
            primaryNavigationType:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });

     
    }

    //tab changes code start

    onMapGeoZoneTabClick(){

        this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = false ;
        this.formTabs.primaryNavigationInfo.seo = false;
        this.formTabs.DeviceType.configuration = false ;
        this.formTabs.DeviceType.configurationType = false ;
        this.formTabs.DeviceType.uploadImage = false ;
        this.formTabs.primaryNavigationInfo.mapGeoZone = true ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = true ;
    }

    oninformationTabClick(){ 

        // this.createPrimaryNavigationForm.controls['mapProductType'].setValue([]);
        this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp,'id');
        this.loader = true;

        setTimeout(()=>{
            this.loader = false;

            this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);

        },100)

        this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = false ;
        this.formTabs.primaryNavigationInfo.seo = false;
        this.formTabs.DeviceType.configuration = false ;
        this.formTabs.DeviceType.configurationType = false ;
        this.formTabs.DeviceType.uploadImage = false ;
        this.formTabs.primaryNavigationInfo.information = true ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = true ;
    }

    onPrimaryNavigationInfoTabClick(){

         this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = false ;
        this.formTabs.primaryNavigationInfo.seo = false;
        this.formTabs.DeviceType.configuration = false ;
        this.formTabs.DeviceType.configurationType = false ;
        this.formTabs.DeviceType.uploadImage = false ;
        this.formTabs.primaryNavigationInfo.mapGeoZone = true ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = true ;

    }
    
    onUploadImageTabClick(){

         this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = false ;
        this.formTabs.primaryNavigationInfo.seo = false;
        this.formTabs.DeviceType.configuration = false ;
        this.formTabs.DeviceType.uploadImage = false ;
        this.formTabs.DeviceType.uploadImage = true ;
        this.formTabs.DeviceType.configurationType = true ;

    }

    onDeviceTypeTabClick(){

        this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = false ;
        this.formTabs.primaryNavigationInfo.seo = false;
        this.formTabs.DeviceType.uploadImage = false ;
        this.formTabs.DeviceType.configuration = true;
        this.formTabs.DeviceType.configurationType = true ;


    }

    onSeoTabClick(){

        this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        this.formTabs.primaryNavigationInfo.information = false ;
        this.formTabs.primaryNavigationInfo.primaryNavigation = true ;
        this.formTabs.primaryNavigationInfo.seo = true;

        this.formTabs.DeviceType.configuration = false ;
        this.formTabs.DeviceType.configurationType = false ;
        this.formTabs.DeviceType.uploadImage = false ;

        // this.formTabs.primaryNavigationInfo.mapGeoZone = false ;
        // this.formTabs.primaryNavigationInfo.primaryNavigation = true ;
    }

    //tab changes code end


    //pravin code start
    //geoZone dropdown code start
    getGeoZoneInfo(obj){

//        this.loader = true;

        this.spinnerService.show();
        
        this.productConfigurationService.getAllGeoZone(obj,this.start,10)
        .subscribe((result:any)=>{

         //   this.geoZoneList = [];
         this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){

                if(this.isSearch == true){

                    this.geoZoneList = [];
                    this.isSearch = false;

                }

                this.dropdownList = result.data.data;
                
                for (let geoZoneObj of this.dropdownList) {

                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });
                    
                        this.geoZoneList = this.removeDuplicates(this.geoZoneList, "id");
                  
                }
                

                if(this.update){

                  //  if(this.id!='null')
                  //   this.getPrimaryNavigation(this.id);

                }else{

                    this.loader = false;
                    this.update = false;
                }
            }
         
        }) 
    }

    geoZoneObj:any={};

    fetchMoreGetGeoZone(event){

        if ((event.endIndex === this.geoZoneList.length - 1) && (this.geoZoneList.length>=8)) {

           this.start = this.start + 10;

           this.getGeoZoneInfo(this.geoZoneObj);
            
        }

    }

    eventValue:any='ddw';
    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
            return;

        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;

        this.eventValue = event.target.value;

        if(info == 'geoZone'){
            
            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                this.geoZoneList = [];      
                this.geoZoneObj = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.geoZoneObj = obj ;
                this.geoZoneList = [];      
                this.start = 0;

                this.searchDecouncer$.next(event.target.value);

            }
  
        }  

        if(info == 'productType'){

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                this.ProducTypeList = [];
                this.startProductType = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                delete this.productTypeMappingFilter.productTypeName;
                this.ProducTypeList = [];      
                this.startProductType = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);
            }
            
        }
    }

    
    onOpen(info){

            this.activeSearchBox = info;
      
             if(this.activeSearchBox == 'geoZone'){
     
                 this.geoZoneObj = {};
                 this.geoZoneList = [];
                 this.start = 0;
                 this.getGeoZoneInfo(this.geoZoneObj);       
     
             }  
     
             if(this.activeSearchBox == 'productType'){

               var geoZoneId= this.productTypeMappingFilter.geoZoneId
     
                 this.productTypeMappingFilter = {};
                 this.productTypeMappingFilter.geoZoneId = geoZoneId;
                 this.ProducTypeList = [];
                 this.startProductType = 0;
                 this.getProductTypeInfo(this.productTypeMappingFilter);
                 
             }
     
     }


    private setupSearchDebouncer(): void {

        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

          
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo(this.geoZoneObj);       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }

        });
    }

    //geoZone dropdown code end

    mapProductTypeArrayTemp = [];
    //product type dropdown code start
    getProductTypeInfo(filterObj){

        this.filterObjProductType = filterObj;

        this.commonService.getGlobalProductType(filterObj,this.startProductType,10).subscribe((result:any)=>{

            //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end    
          
            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){  


                this.infoObj.pageCount =  result.data.pageCount ;
                // this.mapProductTypeArrayTemp = [];
                let mapProductTypeArray = this.createPrimaryNavigationForm.value.mapProductType;   
                this.ProducTypeDropdownList = result.data.data;

               //console.log("result.data.data>>>",result.data.data);
                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                  //  mapping base ongeoZone

                    mapProductTypeArray.forEach(element => {

                        if(element.id == ProducTypeLists.productTypeId) {

                            this.mapProductTypeArrayTemp.push(element);
                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp ,"itemName");

                    
                        }  

                    });
                    this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp ,"itemName");

                    setTimeout(()=>{

                        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);

                    },700)

                  //  mapping base ongeoZone

                    if(ProducTypeLists.productTypeId!=undefined){

                        this.ProducTypeList.push(
                            {
                                'id': ProducTypeLists.productTypeId,
                                'itemName': ProducTypeLists.productTypeName
                            });

                    }

                    this.ProducTypeList = this.removeDuplicates(this.ProducTypeList, "id");         


                    //code for global loader start
                    this.commonService.changeloader(false);
                    //code for global loader end                    
                }

            } else{
                // this.ProducTypeList = [];
            }
 
        }) 
    }

    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }

    fetchMoreGetProductType(event){

        if ((event.endIndex == this.ProducTypeList.length - 1)&& (this.ProducTypeList.length >= 8)) {

                this.startProductType = this.startProductType + 10;
                this.getProductTypeInfo(this.productTypeMappingFilter);
   
        }

    }
   //product type dropdown code end


    //pravin code end

    getPrimaryNavigation(id){

        // this.loader=true;

         //code for global loader start

         this.langId = localStorage.getItem('languageId');

         if(this.langId == null){
             
            this.langId= '1';

         }
      

          this.commonService.changeloader(true);
         //code for global loader end
         this.createPrimaryNavigationForm.controls['id'].setValue(id);

        this.primaryNavigationService.getPrimaryNavigation(id, this.langId)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.searchDecouncer$.next(null);

            //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end

            console.log("result,",result.data[0]);

            // this.createPrimaryNavigationForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
            this.createPrimaryNavigationForm.controls['title'].setValue(result.data[0].title);
            this.createPrimaryNavigationForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
            // sameConfigurationDeviceType
            this.createPrimaryNavigationForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
            this.createPrimaryNavigationForm.controls['contentState'].setValue(result.data[0].contentState);
            this.createPrimaryNavigationForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
            this.createPrimaryNavigationForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);

            if(result.data[0].randomCarousel!=undefined){

                this.createPrimaryNavigationForm.controls['randomCarousel'].setValue(result.data[0].randomCarousel);
            }

            if(result.data[0].randomPagecategory!=undefined){

                this.createPrimaryNavigationForm.controls['randomPagecategory'].setValue(result.data[0].randomPagecategory);
            }


            this.createPrimaryNavigationForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
            this.createPrimaryNavigationForm.controls['primaryNavigationType'].setValue(result.data[0].primaryNavigationType);
            this.createPrimaryNavigationForm.controls['isActive'].setValue(result.data[0].isActive.toString());
            this.createPrimaryNavigationForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createPrimaryNavigationForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
        

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createPrimaryNavigationForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createPrimaryNavigationForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            this.selectedGeoZone=[];
            this.selectedProductlist=[];

                if(result.data[0].productType.length>0){

                    for(let i = 0 ;i < result.data[0].productType.length;i++){

                        let obj={
                        'id':result.data[0].productType[i].productTypeId,
                        'itemName':result.data[0].productType[i].productTypeName,
                        }

                        this.selectedProductlist.push(obj);  
                        this.mapProductTypeArrayTemp = this.selectedProductlist;
                        this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp , "id");

                        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.selectedProductlist);

                    }
                }

                if(result.data[0].geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].geoZone.length;i++){
                        let obj={
                        'id':result.data[0].geoZone[i].geoZoneId,
                        'itemName':result.data[0].geoZone[i].geoZoneName,
                        }
                        this.selectedGeoZone.push(obj);  
                        this.productTypeMappingFilter.geoZoneId.push(obj.id);
                    }
                    this.createPrimaryNavigationForm.controls['mapGeoZone'].setValue(this.selectedGeoZone);
                   
                    this.getProductTypeInfo(this.productTypeMappingFilter);

                }
            
            //device type validation code start
            this.deviceTypeFull = false;
            this.all = false;
            //device type validation code end

        
            if(result.data[0].productDeviceTypeConfigure.length>0){

                    this.img=[];
                    this.multipleDeviceForm = this.formBuilder.group({
                        devices: this.formBuilder.array([])
                    });
                    
            //device type validation code start
            if(result.data[0].productDeviceTypeConfigure.length == 4){
                this.deviceTypeFull = true;
            }
            //device type validation code end

            console.log("123",result.data[0].productDeviceTypeConfigure.length);


                    this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                    for(let i=0;i< result.data[0].productDeviceTypeConfigure.length;i++){

                        this.img.push({
                            'imgURL':result.data[0].productDeviceTypeConfigure[i].filePath
                        })

                        //for imgUplaod obj.
                        let obj = {
                            'index':'',
                            'deviceType':'',
                            'selectedFiles':'',
                            'fileName': '',
                            'filePath': "",
                            'fileType':''
                        }
            
                       this.uploadImgFilePath.push(obj)

                        this.devices.push(this.updateItem(result.data[0].productDeviceTypeConfigure[i]));
                    
                    }
            }
        
            this.createPrimaryNavigationForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
        });
    }

    // multiselect code base on selection
    
    onItemSelect(item:any, mappingName){

        this.startProductType = 0;

        console.log("this.createGenreForm.controls['mapProductType'",this.createPrimaryNavigationForm.value.mapProductType);

        if(mappingName == 'mapGeoZone'){
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            delete  this.productTypeMappingFilter.keyword ;


            this.getProductTypeInfo(this.productTypeMappingFilter);
        }

    }

    OnItemDeSelect(item:any, mappingName){
     
        if(mappingName == 'mapGeoZone'){

            for (let index=0; index<this.productTypeMappingFilter.geoZoneId.length;index++){
                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                    console.log("mapGeoZone deleted = ",item.id);

                    if(this.productTypeMappingFilter.geoZoneId.length == 0){
                        this.ProducTypeList = [];
                        this.selectedProductlist = [];
                        this.createPrimaryNavigationForm.controls['mapProductType'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }
                    else{
                       this.getProductTypeInfo(this.productTypeMappingFilter);
                    }

                    return;
                }
            }
        }
    }

    onSelectAll(item:any, mappingName){
        console.log("select All from = ",mappingName);

        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter = filterObj;
            this.getProductTypeInfo(filterObj);
        }
    }

    onDeSelectAll(event, info){
        // console.log(items);
        if(info =='mapGeoZone'){
            this.createPrimaryNavigationForm.controls['mapGeoZone'].setValue([]);
            this.ProducTypeList = [];
            
            this.mapProductTypeArrayTemp = [];


            this.createPrimaryNavigationForm.controls['mapProductType'].setValue([]);
            this.selectedProductlist =[];
            
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapProductType'){
            this.createPrimaryNavigationForm.controls['mapProductType'].setValue([]);
            // this.ProducTypeList = [];
            this.mapProductTypeArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
    }

    // multiselect code base on selection

    get l() { return this.createPrimaryNavigationForm.controls; }
    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

    onContentReadyToReview(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.selectedProductlist);
        this.contentReadyToReview = false;

        if((!this.multipleDeviceForm.valid) || (!this.createPrimaryNavigationForm.valid)){

            this.createPrimaryNavigationSubmitted = true;
            // this.loader = false;
             //code for global loader start
             this.commonService.changeloader(false);
             //code for global loader end
            console.log("multipleDeviceForm>>",this.multipleDeviceForm.valid);
            return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    isContentReadyToReviewReceived(event){

        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.selectedProductlist);
        this.contentReadyToReview = false;
        
    //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
        
        }
        //New Code end

        if(event == 'Yes'){

            this.createPrimaryNavigationForm.controls['contentState'].setValue(['Completed']);
            // this.postCAllNavigation();
            this.savecreatePrimaryNavigation();
        
        }
        if(event == 'Cancel'){

            this.createPrimaryNavigationForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCAllNavigation();
            this.savecreatePrimaryNavigation();
        

        }
   
    }

    savecreatePrimaryNavigation(){


          //remove empty file upload img obj from array.
        // this.createPrimaryNavigationForm.controls['langId'].setValue(localStorage.getItem('languageId'))
        let  tempUploadImgFilePath = this.uploadImgFilePath;
        this.uploadImgFilePath = [];
        
        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.selectedProductlist);
        this.createPrimaryNavigationForm.controls['mapProductType'].setValue(this.selectedProductlist);

    
        tempUploadImgFilePath.forEach((element , i) => {

            if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
        
            }
            
        });

    //    this.loader = true;
      //code for global loader start
      this.commonService.changeloader(true);
      //code for global loader end

       
        let count = 0;
        let varialbeArray = [];
        this.createPrimaryNavigationSubmitted = true;

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader = false;

              //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end
            return;
        }

        if(this.deviceTypeDublicate){

            // this.loader = false;
             //code for global loader start
             this.commonService.changeloader(false);
             //code for global loader end

            console.log("deviceTypeDublicate>>",this.deviceTypeDublicate);
           return;
        }

        if(this.multipleDeviceForm.value.devices.length == 0){
            // this.loader = false;
             //code for global loader start
             this.commonService.changeloader(false);
             //code for global loader end
            console.log("multipleDeviceForm>>000",this.multipleDeviceForm.valid);
            return;
        }

        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
             //code for global loader start
             this.commonService.changeloader(false);
             //code for global loader end
            console.log("multipleDeviceForm>>",this.multipleDeviceForm.valid);
            return;
        }

        if(!this.createPrimaryNavigationForm.valid){
            // this.loader=false;
             //code for global loader start
             this.commonService.changeloader(false);
             //code for global loader end
            console.log("createPrimaryNavigationForm>>",this.createPrimaryNavigationForm.valid);
            return;
        }

        let async = this.uploadImgFilePath.length;
       
        if(this.update){

            if(this.roleAsses.primaryNavigationEdit == false){
                 
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }
           
            let asyncvariables = this.multipleDeviceForm.value.devices.length;

            if(this.uploadImgFilePath.length == 0){

            this.createPrimaryNavigationForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
            this.createPrimaryNavigationSubmitted = true;

                            if(!this.multipleDeviceForm.valid){
                                console.log("1",);
                                // this.loader = false;
                                 //code for global loader start
                                this.commonService.changeloader(false);
                                //code for global loader end
                                return
                            }
                          
                    
                            if(!this.createPrimaryNavigationForm.valid){
                                console.log("2",);
                                // this.loader = false;
                                  //code for global loader start
                                  this.commonService.changeloader(false);
                                  //code for global loader end
                                return
                            }
                            this.createPrimaryNavigationForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                             
                                //  this.loader = false;

                                  //code for global loader start
                                  this.commonService.changeloader(false);
                                  //code for global loader end
                                  this.postCAllNavigation();
                                 //New Code for right side for from
                                 //this.onContentReadyToReview();
            
            }

            if(this.uploadImgFilePath.length > 0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.primaryNavigation).then(res => {

                        if (res && res.Location) {
                            async--;
                            this.uploadImgFilePath[i].filePath=res.Location;   
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
                
                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId){
              
                                        this.multipleDeviceForm.value.devices[i].fileName=this.uploadImgFilePath[j].fileName;
                                        this.multipleDeviceForm.value.devices[i].filePath=this.uploadImgFilePath[j].filePath;
                                        this.multipleDeviceForm.value.devices[i].fileType=this.uploadImgFilePath[j].fileType;
                
                                    }
                
                                }
                            }

                            if(asyncvariables == 0){
                                this.createPrimaryNavigationSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    console.log("3",);
                                    // this.loader = false;

                                      //code for global loader start
                                    this.commonService.changeloader(false);
                                    //code for global loader end

                                    return
                                }
                        
                                if(!this.createPrimaryNavigationForm.valid){
                                    console.log("4",);
                                    // this.loader = false;
                                     //code for global loader start
                                     this.commonService.changeloader(false);
                                     //code for global loader end
                                    return
                                }
                                this.createPrimaryNavigationForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                //  this.loader = false;
                                 //code for global loader start
                                 this.commonService.changeloader(false);
                                 //code for global loader end
                                 this.postCAllNavigation();
                                  //New Code for right side for from
                                // this.onContentReadyToReview();
                
                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    })
                }
            }

        }

        
       if(!this.update){

            if(this.roleAsses.primaryNavigationCreate == false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

          if(this.uploadImgFilePath.length>0){
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles,  awsPath.primaryNavigation).then(res => {

                    if (res && res.Location) {
                            async--;
                            this.uploadImgFilePath[i].filePath=res.Location;   
                            console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i].Location); 
                            if (async == 0) {

                                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){

                                            varialbeArray.push(
                                            {
                                                'label':this.multipleDeviceForm.value.devices[i].label,
                                                'description':this.multipleDeviceForm.value.devices[i].description,
                                                'uploadImage':'',
                                                'mapWithHeaderOrBurgerMenu':this.multipleDeviceForm.value.devices[i].mapWithHeaderOrBurgerMenu,
                                                'isCarouselApplicable':this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                                                'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                                                'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                                                'randomCarousel':this.multipleDeviceForm.value.devices[i].randomCarousel,
                                                'randomPagecategory':this.multipleDeviceForm.value.devices[i].randomPagecategory,
                                                'fileName':'',
                                                'filePath': '',
                                                'fileType': '',
                                            });   

                                }

                                let asyncvariables = varialbeArray.length;
                                for(let i=0;i< varialbeArray.length;i++){

                                    asyncvariables--;
                                    for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                        if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId){

                                            varialbeArray[i].fileName=this.uploadImgFilePath[j].fileName;
                                            varialbeArray[i].filePath=this.uploadImgFilePath[j].filePath;
                                            varialbeArray[i].fileType=this.uploadImgFilePath[j].fileType;

                                        }

                                    }
                                }

                                if(asyncvariables == 0){

                                    this.createPrimaryNavigationSubmitted = true;

                                    if(!this.multipleDeviceForm.valid){
                                        console.log("5",);
                                        // this.loader = false;
                                         //code for global loader start
                                        this.commonService.changeloader(false);
                                        //code for global loader end
                                        return
                                    }
                            
                                    if(!this.createPrimaryNavigationForm.valid){
                                        console.log("6",);
                                        // this.loader = false;
                                        //code for global loader start
                                        this.commonService.changeloader(false);
                                        //code for global loader end
                                        return
                                    }
                                    this.createPrimaryNavigationForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray)
                                     this.postCAllNavigation();
                                    // this.loader = false;
                                       //code for global loader start
                                       this.commonService.changeloader(false);
                                       //code for global loader end

                                     //New Code for right side for from
                                   // this.onContentReadyToReview();
                                }

                            }      

                    }

                });
            }
          }

          if(this.uploadImgFilePath.length==0){
              
            for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 

                varialbeArray.push(
                {
                    'label':this.multipleDeviceForm.value.devices[i].label,
                    'description':this.multipleDeviceForm.value.devices[i].description,
                    'uploadImage':'',
                    'randomCarousel':this.multipleDeviceForm.value.devices[i].randomCarousel,
                    'randomPagecategory':this.multipleDeviceForm.value.devices[i].randomPagecategory,
                    'mapWithHeaderOrBurgerMenu':this.multipleDeviceForm.value.devices[i].mapWithHeaderOrBurgerMenu,
                    'isCarouselApplicable':this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                    'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                    'isVisible':this.multipleDeviceForm.value.devices[i].isVisible,
                    'fileName':'',
                    'filePath': '',
                    'fileType': '',
                });   

            }

            this.createPrimaryNavigationSubmitted = true;

            if(!this.multipleDeviceForm.valid){
                console.log("7",);
                // this.loader = false;
                //code for global loader start
                this.commonService.changeloader(false);
                //code for global loader end
                return
            }
    
            if(!this.createPrimaryNavigationForm.valid){
                console.log("8",);
                // this.loader = false;
                //code for global loader start
                this.commonService.changeloader(false);
                //code for global loader end
                return
            }
            this.createPrimaryNavigationForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray)
            // this.postCAllNavigation();
               this.postCAllNavigation();
            //   this.loader = false;
            //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end
               //New Code for right side for from
             // this.onContentReadyToReview();

          }
       }
          

    }

    postCAllNavigation(){

        if(!this.createPrimaryNavigationForm.valid){
            // this.loader = false;
            //code for global loader start
            this.commonService.changeloader(false);
            //code for global loader end
            return;
        }

        // this.loader=true;
        //code for global loader start
        this.commonService.changeloader(true);
        //code for global loader end

        console.log("123>>",this.createPrimaryNavigationForm.value);
        
     
        if( this.update == false ) {

            delete this.createPrimaryNavigationForm.value.id;

            this.primaryNavigationService.insertPrimaryNavigation(this.createPrimaryNavigationForm.value)
            .subscribe((result:any)=>{

                this.loader=false;

                 //code for global loader start
                this.commonService.changeloader(false);
                //code for global loader end

                this.spinnerService.hide();
                this.uploadImgFilePath=[];
                 //New Code for right side for from
                this.update = false;
                this.id = 'null';
                
                this.resetForm();
                
                this.createPrimaryNavigationSubmitted = false;
                // this.router.navigate(['navigation/primary_navigation/list']);
                this.toastrService.success('Primary Navigation Created Successfully.');
            });
            
        }
            
        if(this.update){ 

            this.createPrimaryNavigationForm.controls['id'].setValue(this.id);
            this.primaryNavigationService.updatePrimaryNavigation(this.createPrimaryNavigationForm.value)
            .subscribe((result:any)=>{
                // this.loader=false;

                 //code for global loader start
                 this.commonService.changeloader(false);
                 //code for global loader end

                this.spinnerService.hide();
                this.uploadImgFilePath=[];
                 //New Code for right side for from
                this.update = false;
                this.id = 'null';
                this.updateSend.emit();
                this.resetForm();
                this.createPrimaryNavigationSubmitted = false;
                // this.router.navigate(['navigation/primary_navigation/list']);
                this.toastrService.success('Primary Navigation Updated Successfully.');
            });
        }

        console.log("createPrimaryNavigationForm>>>",this.createPrimaryNavigationForm.value)
    }

    //Pravin code mulitple from
    createItem(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.required],
            description: '',
            uploadImage: '',
            isVisible:[''],
            fileName: "",
            randomCarousel:[''],
            randomPagecategory:[''],
            filePath: "",
            fileType: "",
            mapWithHeaderOrBurgerMenu :['',Validators.required],
            isCarouselApplicable:'',
            dtypeId:['',Validators.required],
        });
    }

    //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup {

        //device type validation code start
            if(objProductDeviceTypeConfigure.dtypeId === 4){
            this.all = true;
        }
        //device type validation code end
        
        return this.formBuilder.group({
            label: objProductDeviceTypeConfigure.label,
            randomCarousel: objProductDeviceTypeConfigure.randomCarousel,
            randomPagecategory: objProductDeviceTypeConfigure.randomPagecategory,
            description: objProductDeviceTypeConfigure.description,
            uploadImage:objProductDeviceTypeConfigure.uploadImage,
            mapWithHeaderOrBurgerMenu :objProductDeviceTypeConfigure.mapWithHeaderOrBurgerMenu,
            isCarouselApplicable:objProductDeviceTypeConfigure.isCarouselApplicable,
            dtypeId:objProductDeviceTypeConfigure.dtypeId,
            isVisible:objProductDeviceTypeConfigure.isVisible,
            filePath:objProductDeviceTypeConfigure.filePath,
            fileName:objProductDeviceTypeConfigure.fileName,
            fileType:objProductDeviceTypeConfigure.fileType
        });
        // uploadVerticalImage: objProductDeviceTypeConfigure.uploadVerticalImage,
        //     uploadHorizontalImage:objProductDeviceTypeConfigure.uploadHorizontalImage,
    }

    onDtypeId(){
        this.All=false;
        // this.Web=false;
        // this.Android=false;
        // this.iOS=false;
    }

    addItem(): void {

        this.img.push({
            imgUrl:''
        })

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;

       let devices = this.devices
       var All=0
       var Web=0
       var Android=0
       var iOS=0
       this.All=false;
       this.Web=false;
       this.android=false;
       this.iOS=false;
 
        for(let i = 0;i<devices.value.length;i++){

            console.log("this.dtypeId>>>",devices.value[i].dtypeId);

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            if(devices.value[i].dtypeId=='2'){
                Android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            if(devices.value[i].dtypeId=='4'){
                All++;
                this.All=true;
            }else{
               
            }

        }
        if(All==1 || this.All== true ){

        }else if(Web>1 || Android>1 || iOS>1 ){

         this.dTypeDublicateError='Please select single time one device type.';

        }else{

            this.dTypeDublicateError='';
            this.devices.push(this.createItem());

            let obj = {
                'index':'',
                'deviceType':'',
                'selectedFiles':'',
                'fileName': '',
                'filePath': "",
                'fileType':''
            }

           this.uploadImgFilePath.push(obj)
        }
        
    } 

    //New Code for right side for from
    
    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.uploadImgFilePath[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePath[i].index = 1;
     
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        this.uploadImgFilePath.splice(index, 1);
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }
   

    isDeleteDeviceType(event){

        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    //img realted code start

    isDeleteImage(event){
        console.log("event = ", event);
        if(event=='Cancel'){
            this.isDeleteImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteImageAction=false;

            delete this.img[this.deleteDeviceTypeIndex].imgURL;
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].deviceType = '';
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].selectedFiles = '';
            
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileName="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].filePath="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileType="";

        }

    }

    deleteImageYes(i){
        this.deleteDeviceTypeIndex = i;
    }
    
    selectFile(e ,i) {

        console.log("img",i);
        
        this.img.push({
            imgUrl:''
        })

        if(e.target.files[0].name==undefined)
        return;


        this.uploadImgFilePath[i].index = i;
        this.uploadImgFilePath[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePath[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePath[i].fileName =  e.target.files[0].name; 
        this.uploadImgFilePath[i].fileType = e.target.files[0].type;
       
            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                // this.imgURL = reader.result;
                this.img[i].imgURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

        // this.uploadImgFilePath.push(obj);  
    }

    //img realted code end

    resetForm(){

        this.update = false;

        this.id = undefined;
        
        //code for global loader start
        this.mapProductTypeArrayTemp =[];
        this.mapProductTypeArrayTemp = [];
        this.commonService.changeloader(false);
        //code for global loader end

        this.productTypeScrollCount = 0;

        this.selectedGeoZone = [];
        this.selectedProductlist = [];
        this.dropdownList = [];
        // this.geoZoneList = [];   
        this.start = 0;
        this.startProductType = 0;      
        this.ProducTypeDropdownList = [];
        this.ProducTypeList = [];
        this.selectedFiles = [];
        this.uploadImgFilePath = [];


        this.img = [];
        this.spinnerService.hide();
        this.update = false;

        this.createPrimaryNavigationSubmitted = false;
    
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);
        }

        this.createPrimaryNavigationForm = this.formBuilder.group({
           
            isActive:['1'],
            mapGeoZone: [[], Validators.compose([Validators.required])],
            title: ['', Validators.compose([Validators.required])],
            mapProductType:[[],Validators.compose([Validators.required])],
            exclusionText: [''],
            sameForAllZone:[''],
            // sameForAllDeviceType:[''],
            sameForAllProductType:[''],
            productDeviceTypeConfigure:[''],
            // sameConfigurationDeviceType: [''],
            seoTitle:[''],
            seoKeyword:[''],
            seoDescription:[''],
            companyId:[''],
            langId: ['1'],
            displayIndexing:['',Validators.pattern(displayIndexingPattern)],
            primaryNavigationType:[''],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]
        });

        //reset device type image
        let obj = {
            'index':'',
            'deviceType':'',
            'selectedFiles':'',
            'fileName': '',
            'filePath': "",
            'fileType':''
        }

        this.uploadImgFilePath.push(obj)

            this.img.push({
                imgUrl:''
            })

        this.update = false;

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });
        this.id == null;
        this.updateSend.emit();
        this.onMapGeoZoneTabClick();
        this.showCreateNewButton('show');
        
    }

}

