import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SearchService } from './../../../services/search/search.service';
import { ToastrService } from 'ngx-toastr';
import {CommonService} from '../../../services/common_Service/common.service';
import {StatusService} from '../../../services/status_management/status.service';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {


  isDeleteAction:boolean=false;
  isDeleteActionId:any;

  searchCount:number=0;
  searchResults:any;
  loader:boolean=false;
  noRecordFound:boolean=false;
  keyword:any;
  
  searchQuery:any={};
  searchResultObj={};
  searchResultList:any=[[]];

  rows:any = [];
  temp:any = [];

  infoObj:any = {};

      
  start:number = 0;
  limit:number = 10;
  



  columns = [
      { prop: 'thumbnail', name: 'Thumbnail'},  
      { prop: 'srNo', name: 'Sr. No. '},
      { prop: 'contentType', name: 'Content Type'},
      { prop: 'title', name: 'Title'},
      { prop: 'isActive', name: 'Status'},
      { prop: 'modified', name: 'Updated'},
      { prop: 'edit', name: 'Edit'},
      { prop: 'delete', name: 'Delete'},
    
  ]
    //content type;

  constructor( private statusService:StatusService, private commonService:CommonService,private toastrService:ToastrService, private router:Router, private datePipe:DatePipe, private searchService: SearchService) { }

  ngOnInit() {

    this.commonService.notify.subscribe((result) => {

      this.loader = false;
    })

    let headerTitle = {

      title:'Search',
      subTitle:'Search'

     }

  this.commonService.changeHeaderTitle(headerTitle);

    this.searchService.currentApprovalStageMessage.subscribe((result:any)=>{

      this.searchQuery.keyword=result;
      this.searchQuery.languageId=2;
      this.fireSearch(this.searchQuery);

    })
   
  }

  isDeleteActionReceive(event){

    if(event=='Cancel'){
        this.isDeleteAction=false;
    }
    
    if(event=='Yes'){

        let obj={
            'id': this.isDeleteActionId
        }

        this.searchService.deleteSearchRecord(obj)
        .subscribe((result:any)=>{

        console.log("result delete",result);
        this.isDeleteAction=false;
        this.toastrService.success('Record Deleted Successfully.');
        this.fireSearch(this.searchQuery);

        })
    }

  }



  actionObjReceive(event){

    this.isDeleteActionId = event.id;

    console.log("event>>>",event.action);

    if(event.action =='edit'){
      // this.router.navigate(['asset_management/asset/list',event.id]);

      this.router.navigate(['asset_management/asset/list'])
      localStorage.setItem('activeAsset',event.id);

        // if(event.languageId){
        //     // this.router.navigate(['asset_management/asset/add',event.id, event.languageId]);
        //     this.router.navigate(['asset_management/asset/add',event.id]);
        // } else {
        //     this.router.navigate(['asset_management/asset/add',event.id]);
        // }
        
    } else if(event.action =='delete'){

        this.isDeleteAction = true;

    }

  }


  actionApiReceive(e){

      if(e[0].contentType != undefined){

          this.loader = true;
          //below code is for status management
          this.statusService.updateStatus(e)
          .subscribe((result:any)=>{

              this.toastrService.success('Asset status updated Successfully.');
              this.fireSearch(this.searchQuery);
              console.log("result>>",result);

          })
      }
  }


  fireSearch(searchQuery){
    this.loader=true;
    this.searchService.getAllSearchResult(searchQuery).subscribe((result:any)=>{

        this.loader=false;
        console.log("Result ====>>> ",result);
        this.searchResultList = [];


        // this.searchResultList=result.data.data;
        if(result.data.data.length > 0){

          this.noRecordFound=false;
          
          let index = 0;
          for (let searchResultObj of result.data.data) {
              index++;
              
              let editButton = "<a class='tableFaIconButtton'><img src='./../../../../../assets/images/edit.svg'></a>";
              let deleteButton = "<a class='tableFaIconButtton'><img src='./../../../../../assets/images/delete.svg'></a>";
              let isActiveStatus;

              this.infoObj.pageCount =  result.data.pageCount;
              this.infoObj.totalCount = result.data.totalCount;
              this.infoObj.data = result.data.data;
              this.infoObj.page = 'search';

              if(searchResultObj.isActive == 1){
                searchResultObj.isActive='Active';
                  isActiveStatus = "<span class='activeStatus'>Active</span>";
              }else{
                searchResultObj.isActive='Not Active';
                  isActiveStatus = "<span class='notactiveStatus'>Not Active</span>";
              }
             

              if(searchResultObj.sameForAllZone == 1){
                searchResultObj.sameForAllZone='Yes';
              }else{
                searchResultObj.sameForAllZone='Not';
              }
              
              let modified_date = this.datePipe.transform(searchResultObj.modified,"EEE, MM/d/y - hh:mm");
              
              let modifiedTime = moment(searchResultObj.modified).format('HH.mm:ss');
              searchResultObj.modified = moment(searchResultObj.modified).format('YYYY-MM-DD'); 

                let posterImage = "";
                if(searchResultObj.filePath != null){
                    let front = "<img class='listingThumbnail' src='";
                    let back = "' alt='Poster Image'>";
                    posterImage = searchResultObj.filePath;
                }
                      
              this.searchResultList.push(
              {
                  'srNo': index,
                  'id': searchResultObj.id,
                  'thumbnail': posterImage,
                  'contentType': searchResultObj.contentType,
                  'title': searchResultObj.title,
                  'isActive': isActiveStatus,
                  'modified': searchResultObj.modified,
                  'modifyTime': modifiedTime,
                  'edit': editButton,
                  'delete': deleteButton,
                  'statusmanagement':{  
                    "contentType":'asset',
                    "contentId": searchResultObj.id,
                    "publisherState":searchResultObj.publisherState,
                    "reviewerState":searchResultObj.reviewerState,
                },
              });
          }
          // cache our list
          this.temp = this.searchResultList; 
          
          // push our inital complete list
          this.rows = this.searchResultList;
      }
      else{
        // this.router.navigate(['dashboard']);
        // this.dashboard
        this.temp = [];
        this.rows = [];
        this.noRecordFound=true;

      }

    
    });
  } 

}
