export const errorMessages = {
    errorMassageValidEmail:'Please enter valid email address',
    errorMassageDeviceTypeReq:'Please add at least one device type',
    errorMassageImgDeviceTypeReq:'Please select device type to upload img',
    errorMassageEmailReq:'Email address is required',
    errorMassagePassword:'Password is required',
    errorMassageLabel:'Label is required',
    errorMassageVarible:' No Global Variable present',
    errorMassageDigitOnly:'Please enter digit only',
    errorMassageNumberOnly:'Please enter number only',
    
    errorUserAlreadyPresent:'The username ranjeet.g@dctinc.com is already taken',
    errorMassagePostalCodeValid:'Enter only valid Postal Code',
    errorMassageVariableValueReq:'Variable Value required',
    errorMassageVariableKeyReq:'Variable Key required',
    errorMassagedTypeDublicateError:'Please select single time one device type',
    errorMassageDeviceTypeAll:'Please remove other device type as you have selected all as device type',
    errorMassageDevicesReqError :'Please add at least one device type',
    usernameReq:'Username is required',
    firstnameReq:'First name is required',
    lastnameReq:'Last name is required',
    addressReq:'Address is required',
    cityReq:'City is required',
    stateReq:'State is required',
    
    errorMassageValidUsername:'Enter valid Username',
    errorMassageCharacterOnly:'Enter character only',
    errorMassagePhoneValid:'Please check phone number format',
    errorMassageCPassword:'Confirm password is required',
    errorMassageCpasswordCheck:'The password and Confirm password values must be same',
    errorMassagePhone:'Phone number is required',
    passwordReq:'Password is required',
    userIdReq:'User ID required',
    secretKeyReq:'Secret Key required',
    baseUrlReq:'Base Url required',
    errorMassagePasswordPatter:'A minimum 8 characters password contains a combination of special character,uppercase and lowercase letter and number are required.',
    validBaseUrl:'Enter Valid Url',
    validLatitudeValue:'Enter Valid latitude value',
    validLongitudeValue:'Enter Valid longitude value',
    mapGeoZoneReq:'Map geo zone is required',
    geoZoneReq:'Geo zone is required',
    dateOfBirthReq:'Date of birth required',
    productTitleReq:'Title is required',
    sameConfigurationDeviceTypeReq:"Same configuration for all Device type is required",
    // sameConfigurationOrientationReq:"Same orientation configuration for all Device type is required.",
    titleReq: "Title is required",
    mapProductTypeReq: "Map product type is required",
    mapPrimaryNavigationReq: "Map primary navigation is required",
    sameForAllDeviceTypeReq:"Same for device type is required",
    deviceTypeReq:"Device type is required",
    deviceOrientationReq:"Device orientation is required",
    labelReq:"Label is required",
    assetMappingReq:"Asset mapping is required",
    assetTypeReq:"Asset Type is required",
    mapWithMenuReq:"Map menu is required",

    HitsReq:"Hits is required",
    productTypeReq: "Product pype is required",
    carouselImageReq: "Carousel image is required",
    firstNameReq: "First Name is required",

    keyReq: "Key is Required",
    valReq: "Value is Required",


  };