import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {awsConfiguration} from '../../configurations/configuration';

import * as S3 from 'aws-sdk/clients/s3';

@Injectable()
export class ProductConfigurationService {

    constructor(private http: HttpClient) { }

    updateProductConfiguration(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateProductConfigurationUrl, postObj,httpOptions);
    }
        
    insertProductConfiguration(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertProductConfigurationUrl, postObj,httpOptions);
    }  

    

    
    getProductConfiguration( id: any) : Observable<any> {
        
        if(id){
            
        } else {
            id = 1;
        }
        
         return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getProductConfigurationUrl+"/" + id);
    }
   
    getAllGeoZone(filterObj,start,limit){

      // filterObj.languageId = localStorage.getItem('languageId');

        let urlGetAllGoeZone = environment.apiURL + environment.adminApiUrl + environment.getGeoZoneUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllGoeZone)
    }
            
    /*uploadFile(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.createFileUrl, postObj,httpOptionsImage);
    }*/
        
    uploadFile(file, path) {

      var parts = file.name.split('.');
      let fileNameWithoutExtension = parts[0];
      let fileExtention = (parts.length > 1) ? parts.pop() : '';
      
      let fullFileName = '';
      if(fileExtention){
          fullFileName = path+fileNameWithoutExtension+'-'+new Date().getTime()+"."+fileExtention;
      } else {
          fullFileName = path+fileNameWithoutExtension+'-'+new Date().getTime();
      }
      



        const bucket = new S3(
          {
            // accessKeyId: 'AKIASYXB26NJKYQM2ZLR',
            // secretAccessKey: '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',
            // region: 'us-west-1'
            accessKeyId: environment.awsConfiguration.accessKeyId,
            secretAccessKey: environment.awsConfiguration.secretAccessKey,
            region: environment.awsConfiguration.region,
          }
        );

        const params = {
          Bucket: environment.awsConfiguration.bucketName,
          // Key: path + file.name,
          Key: fullFileName,
          Body: file
        };

        return bucket.upload(params, function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
          }

          console.log('Successfully uploaded file.', data);
          return true;
        }).promise();
    }   
                
            
               
}
