import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ClassificationService } from '../../../../../services/classification/classification.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import {CommonService} from '../../../../../services/common_Service/common.service';

@Component({
  selector: 'app-addClassification',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentClassification implements OnInit {

  isUserLoggedIn: boolean = false;
  createClassificationForm:FormGroup;
  errorMessages = errorMessages;
  classificationSubmitted:boolean = false;

  isCreateNewShow:boolean = true;
  

  id:any;
  update=false;

  loader:boolean=false;
  
    //New Code for right side for from
    @Output() updateSend = new EventEmitter<any>();
    @Input() set contentId(value: any) {
        // this.onLoad(value);
        if(value == 'reset'){
            this.resetForm();
        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
    }
        
    contentReadyToReview:boolean = false;    
    shareMassage:any;


    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');

    
  constructor(private commonService:CommonService, private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private classificationService: ClassificationService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  roleAsses:any={};
  roleConfiguration:any=[];
    ngOnInit(){


        this.roleAsses.classificationEdit = false;
        this.roleAsses.classification = false;
        this.roleAsses.classificationCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Classification') ){
                  
                  this.roleAsses.classification = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Classification') ){
                  
                  this.roleAsses.classificationEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Classification')){

                  this.roleAsses.classificationCreate = true;
              
              }

        })
          // theme update code start
          this.commonService.currentThemelight.subscribe((result:any)=>{
          
            this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
         })
        // theme update code start
    }
    
    onLoad(id) {

        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        let headerTitle = {
            title:'Master',
            subTitle:'Custom'
        }
        
        this.commonService.changeHeaderTitle(headerTitle);

        this.createClassificationForm = this.formBuilder.group({

            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });

        this.id = id; // (+) converts string 'id' to a number

        if(this.id!= 'null' ){

          console.log('this.id===>>>',this.id);

            this.update=true;
            this.getClassificationInfo(this.id)
        }else{
          this.update=false;        
        }

        
    }


  get l() { return this.createClassificationForm.controls; }

  getClassificationInfo(id){
        
    //this.loader=true;
    this.commonService.changeloader(true);
    
    this.classificationService.getClassification(id)
    .subscribe((result:any)=>{

          //this.loader=false;
          
          this.commonService.changeloader(false);
          
          console.log("result==========>",result);
          
          this.createClassificationForm.controls['title'].setValue(result.data[0].title);
          this.createClassificationForm.controls['description'].setValue(result.data[0].description);
          this.createClassificationForm.controls['isActive'].setValue(result.data[0].isActive.toString());

          this.createClassificationForm.controls['publisherState'].setValue(result.data[0].publisherState);
          this.createClassificationForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

          if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
              this.createClassificationForm.controls['contentState'].setValue(['In-Progress']);
          }
          else{
              this.createClassificationForm.controls['contentState'].setValue(result.data[0].contentState);
          }
          // console.log("result==========>",result.data[0].isActive.toString());
    
    });
  }

  savecreateNewClassification(){

      console.log('update',this.id);
      console.log("Record received",this.createClassificationForm.value);

      //this.loader=true;
      this.commonService.changeloader(true);
      
      this.classificationSubmitted = true;

      if(!this.createClassificationForm.valid){
        //this.loader=false;
        
        this.commonService.changeloader(false);
        
        return;
      }
    
      if(!this.update){

        if(this.roleAsses.classificationCreate == false){
          this.toastrService.success('no access to create.');
          this.commonService.changeloader(false);
          return;
      }

        delete this.createClassificationForm.value.id;
        this.classificationService.insertClassification(this.createClassificationForm.value)
        .subscribe((result:any)=>{

            //this.loader=false;
            this.commonService.changeloader(false);
            // this.spinnerService.hide();
            
            this.update = false;
           // this.updateSend.emit();
            this.resetForm();
            this.classificationSubmitted = false;
            
            this.toastrService.success('Classification Created Successfully.');
        });
      }

      if(this.update){

        if(this.roleAsses.classificationEdit == false){
          this.toastrService.success('no access to edit.');
          this.commonService.changeloader(false);
          return;
      }
        this.createClassificationForm.controls['id'].setValue(this.id);
        this.classificationService.updateClassification(this.createClassificationForm.value)
        .subscribe((result:any)=>{

            //this.loader=false;
            this.commonService.changeloader(false);
            
            //New Code for right side for from
            this.update = false;
          //  this.updateSend.emit();
            this.resetForm();
            this.classificationSubmitted = false;
            
            this.toastrService.success('Classification Updated Successfully.');          
        });
      }


  }
  
    //New Code for right side for from
    onContentReadyToReview(){

        if(!this.createClassificationForm.valid){
            //this.loader=false;
            
            this.commonService.changeloader(false);
            this.classificationSubmitted  = true;
            
            return;
          }

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createClassificationForm.controls['contentState'].setValue(['Completed']);
            this.savecreateNewClassification();
           
        }
        if(event == 'Cancel'){

            this.createClassificationForm.controls['contentState'].setValue(['In-Progress']);
            this.savecreateNewClassification();
            
        }
       
    }

    
    resetForm(){

    
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {
        //     arrayControl.removeAt(0);
        // }

        // console.log("this.formTabs >>>>",this.formTabs);

        this.updateSend.emit();
        
        this.createClassificationForm = this.formBuilder.group({

            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });
        

        this.update = false;

        //////////////////////

        this.showCreateNewButton('show');

    
    }
  

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
  

}
