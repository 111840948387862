import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/shared/header/header.component';
import { MenuComponent } from './component/shared/menu/menu.component';
//services
import { LoginService } from './services/login/login.service';
import { ProductConfigurationService } from './services/product_configuration/product_configuration.service';
import { ProductTypeService } from './services/product_type/product_type.service';
import { PrimaryNavigationService } from './services/primary_navigation/primary_navigation.service';
import { HttpErrorInterceptor }from './services/intercepter';
import {SharedModule} from '../app/sharedModule';
import { SearchComponent } from './component/shared/search/search.component';
import {Loginshared} from './services/sharedservices/loginshared.service';
import {UserRegistrationService} from "./services/cognito/user-registration.service";
import {UserParametersService} from "./services/cognito/user-parameters.service";
import {UserLoginService} from "./services/cognito/user-login.service";
import {CognitoUtil} from "./services/cognito/cognito.service";
import {AwsUtil} from "./services/cognito/aws.service";
import {DynamoDBService} from "./services/cognito/ddb.service";
//Added By Ismail for Charts Dashboard
import {NgApexchartsModule} from 'ng-apexcharts';
import {ClientComponent} from './component/clientManagement/clientAdd/client/client.component';

@NgModule({ 
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    SearchComponent,
    ClientComponent,
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
        positionClass: 'toast-bottom-center',
        preventDuplicates: true,
    }),
    SharedModule,
    Ng4LoadingSpinnerModule,
    AngularMultiSelectModule,
    NgApexchartsModule,
    
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],


  providers: [LoginService,ProductConfigurationService,ProductTypeService,PrimaryNavigationService,DatePipe,
    {
      provide:HTTP_INTERCEPTORS, 
      useClass: HttpErrorInterceptor, 
      multi: true
    },
    
    UserRegistrationService,
    UserParametersService,
    UserLoginService,
    CognitoUtil,
    AwsUtil,
    Loginshared,
    DynamoDBService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
