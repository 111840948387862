import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router ,ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {FooterLinkService} from '../../../../../services/footer_link/footer-link.service';
import { ProductConfigurationService } from './../../../../../services/product_configuration/product_configuration.service';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import { splitClasses } from '@angular/compiler';
import {awsPath, deviceType, urlPattern} from '../../../../../configurations/configuration';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-addFooterlinks',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentFooterlinks implements OnInit {

  isUserLoggedIn:boolean=false;
  createFooterlinkForm:FormGroup;
  errorMessages = errorMessages;
  footerlinkSubmitted:boolean = false;

  isCreateNewShow:boolean = true;
  geoZoneList = [];
  uploadImgFilePath:any=[];
  uploadImageFileUrl:any;
  geoZoneDropdownList = [];
  mapGeoZoneSettings= {};

  deviceType = deviceType;
  all:boolean = false;
  Web:boolean = false;
  android:boolean = false;
  iOS:boolean = false;
  roku:boolean = false;

  index = 0;
  infoObj:any = {};

  deviceTypeFull:boolean = false;
  deviceTypeDublicate:boolean = false;

  devices: FormArray;
  multipleDeviceForm:FormGroup;

  isDeleteAction: boolean=false;
  deleteDeviceTypeIndex:any;

  id:any;
  update=false;
  img:any=[];
 
  isDeleteImageAction:boolean=false;

  loader:boolean=false;

    formTabs:any = {

        footerInfo:{

        footer:true,
        mapGeoZone:true,
        information:false,

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
        
        }
    }
    
    //New Code for right side for from
    @Output() updateSend = new EventEmitter<any>();
    @Input() set contentId(value: any) {
        // this.onLoad(value);
        if(value == 'reset'){
            this.resetForm();
        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
    }
    
    
    contentReadyToReview:boolean= false;
    shareMassage:any;    
        
    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');


  constructor(private commonService:CommonService, private toastrService:ToastrService, private productConfigurationService:ProductConfigurationService, private activatedRoute:ActivatedRoute, private footerLinkService:FooterLinkService, private formBuilder:FormBuilder, private router:Router, private loginSharedService:Loginshared) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }
    
  roleAsses:any={};
  roleConfiguration:any=[];

    
    editCreate:boolean = false;
    onCreateNew(){

            if(this.roleAsses.geoZoneCreate == true){

                this.editCreate = true;
            }
            
            this.isCreateNewShow=false

    }
    ngOnInit(){

        
        
        this.roleAsses.footerLinksEdit = false;
        this.roleAsses.footerLinks = false;
        this.roleAsses.footerLinksCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Footer Links') ){
                  
                  this.roleAsses.footerLinks = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Footer Links') ){
                  
                  this.roleAsses.footerLinksEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Footer Links')){

                  this.roleAsses.footerLinksCreate = true;
              
              }
            })

    // theme update code start
    this.commonService.currentThemelight.subscribe((result:any)=>{
          
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
     })
    // theme update code start
    }

    
    
   onLoad(id) {

        
        if(this.id != 'null' && this.roleAsses.footerLinksEdit == true){
            this.editCreate = true;
        }else{
            this.editCreate = false;
        }

        this.commonService.notify.subscribe((result) => {
            //this.loader = false;
            this.commonService.changeloader(false);
        })
    
        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        let headerTitle = {
            title:'Master',
            subTitle:'Footer links'
        }
        this.commonService.changeHeaderTitle(headerTitle);

        this.onFooterLinkTabClick();

        this.createFooterlinkForm = this.formBuilder.group({
          isActive:['1'],
          id:[''],
          langId: ["1"],
          mapGeoZone:[[],Validators.compose([Validators.required])],
          exclusionText:[''],
          productDeviceTypeConfigure:[[]],
          sameForAllZone:[''],
          title: ['', Validators.compose([Validators.required])],
          description:[''],
          airStartDate:[''],
          airEndDate:[''],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          created_by:[localStorage.getItem('ud')]


        });

        this.id = id; // (+) converts string 'id' to a number
        
        if(this.id!='null' ){
        
            console.log('this.id===>>>',this.id);
        
            this.update=true;
            this.getFooterlinkInfo(this.id);

        }else{

            this.img.push({
              url:'',
            })  

            this.update=false;     

            let obj = {
              'index':'',
              'deviceType':'',
              'selectedFiles':'',
              'fileName': '',
              'filePath': "",
              'fileType':''
            }

            this.uploadImgFilePath.push(obj)

            this.img.push({
                imgUrl:''
            })
        }
        
      this.getGeoZoneInfo();

      this.mapGeoZoneSettings = {
        singleSelection: false, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      }

      //pravin start code
      this.multipleDeviceForm = this.formBuilder.group({
          devices: this.formBuilder.array([this.createDevice()])
      });

  }

  onFooterLinkTabClick(){
    this.formTabs = {

        footerInfo:{

        footer:true,
        mapGeoZone:true,
        information:false,

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
        
        }
    }
  }

  onInformationTabClick(){
    this.formTabs = {

        footerInfo:{

        footer:true,
        mapGeoZone:false,
        information:true,

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
        
        }
    }
  }

  onDeviceTabClick(){

    this.formTabs = {

        footerInfo:{

        footer:false,
        mapGeoZone:false,
        information:false,

        },
        deviceType:{
            
            configurationType:true,
            configuration:true,
        
        }
    }
  }


    get l() { return this.createFooterlinkForm.controls; }

    get m(): FormArray {
      return this.multipleDeviceForm.get('devices') as FormArray;
    }

    createDevice(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.compose([Validators.required])],
            confDescription: [''],
            urlMapping: ['',Validators.compose([Validators.pattern(urlPattern)])],
            dtypeId:['',Validators.required],
            fileName: '',
            filePath: '',
            fileType: '',
        });
    }
 
    updateDevice(objProductDeviceTypeConfigure): FormGroup { 

      if(objProductDeviceTypeConfigure.dtypeId == '4'){
          this.all = true;
      }

      return this.formBuilder.group({

          label: [objProductDeviceTypeConfigure.label,Validators.compose([Validators.required])],
          confDescription: objProductDeviceTypeConfigure.confDescription,
          urlMapping: objProductDeviceTypeConfigure.urlMapping,
          fileName: objProductDeviceTypeConfigure.fileName,
          filePath: objProductDeviceTypeConfigure.filePath,
          fileType: objProductDeviceTypeConfigure.fileType,
          dtypeId:[objProductDeviceTypeConfigure.dtypeId,Validators.required], 

      });
    
    }

    addDevice(): void {

        this.img.push({

            'url':''

        })

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createDevice());

            let obj = {
                'index':'',
                'deviceType':'',
                'selectedFiles':'',
                'fileName': '',
                'filePath': "",
                'fileType':''
            }

           this.uploadImgFilePath.push(obj)
        }

    }

    onDeviceType(i){


          this.devices = this.multipleDeviceForm.get('devices') as FormArray;
          let devices = this.devices;

          this.uploadImgFilePath[i].deviceType = devices.value[i].dtypeId;
          this.uploadImgFilePath[i].index = 1;

          var all = 0;
          var Web = 0;
          var android = 0;
          var iOS = 0;
          var roku = 0 ;
          this.all = false;
          this.Web = false;
          this.android = false;
          this.iOS = false;
          this.roku = false;
      
          for(let i = 0;i< devices.value.length;i++){

              if(devices.value[i].dtypeId=='1'){
                  Web++;
                  this.Web=true;
              }
              
              if(devices.value[i].dtypeId=='2'){
                  android++;
                  this.android=true;
              }
              if(devices.value[i].dtypeId=='5'){
                  roku++;
                  this.roku=true;
              }
              
              if(devices.value[i].dtypeId=='3'){
                  iOS++;
                  this.iOS=true;
              }
              
              if(devices.value[i].dtypeId=='4'){
                  all++;
                  this.all=true;
              }else{
                  
              }
              
          }
          
          this.deviceTypeDublicate = false;
          this.deviceTypeFull = false;
          
          if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

          this.deviceTypeFull = true ;

          }else if(all == 1 || this.all == true ){
              
              if(all == 1 && devices.value.length>1){

              }

          } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

              this.deviceTypeDublicate = true;

          }else {

          }

    }

    delDevice(index){

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;
        this.uploadImgFilePath.splice(index, 1);

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    getGeoZoneInfo(){

        let obj={
        } 
        this.productConfigurationService.getAllGeoZone(obj,0,1000000)
        .subscribe((result:any)=>{

            this.geoZoneDropdownList = [];
            this.geoZoneList = [];

            if(result.data.data.length > 0){
                    
                this.geoZoneDropdownList = result.data.data;

                for (let geoZoneObj of this.geoZoneDropdownList) {

                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });

                }
            }

        }) 
    }

    getFooterlinkInfo(id){
          
      //this.loader=true;
      this.commonService.changeloader(true);
      
      this.footerLinkService.getFooterlink(id)
      .subscribe((result:any)=>{

            //this.loader=false;
            this.commonService.changeloader(false);

            console.log("result==========>",result);
            // this.createFooterlinkForm.controls['mapGeoZone'].setValue(result.data[0].mapGeoZone);
            this.createFooterlinkForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
            this.createFooterlinkForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
            this.createFooterlinkForm.controls['title'].setValue(result.data[0].title);
            this.createFooterlinkForm.controls['description'].setValue(result.data[0].description);
            this.createFooterlinkForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createFooterlinkForm.controls['reviewerState'].setValue(result.data[0].reviewerState);


            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createFooterlinkForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createFooterlinkForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            if(result.data[0].airStartDate!= null){

              var  airStartDate = result.data[0].airStartDate.replace('T00:00:00.000Z', ""); 
              this.createFooterlinkForm.controls['airStartDate'].setValue(airStartDate);

            }
         
            if(result.data[0].airEndDate!= null){

                var airEndDate = result.data[0].airEndDate.replace('T00:00:00.000Z', ""); 
                this.createFooterlinkForm.controls['airEndDate'].setValue(airEndDate);

            }
         
            let geoZoneObj=[];
            if(result.data[0].results.geoZone.length > 0){

                for(let i = 0 ; i < result.data[0].results.geoZone.length;i++){
                    let obj={
                    'id':result.data[0].results.geoZone[i].geoZoneId,
                    'itemName':result.data[0].results.geoZone[i].geoZoneName,
                    }
                    geoZoneObj.push(obj); 
                }
                
            }  
            this.createFooterlinkForm.controls['mapGeoZone'].setValue(geoZoneObj);
          
            this.deviceTypeFull = false;
            this.all = false;
            
            if(result.data[0].results.productDeviceTypeConfigure.length>0){

              if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                  this.deviceTypeFull = true;
              }

              this.multipleDeviceForm = this.formBuilder.group({
                  devices: this.formBuilder.array([])
              });

              this.devices = this.multipleDeviceForm.get('devices') as FormArray;
              this.img = [];
              for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                
                this.img.push({
                  url:result.data[0].results.productDeviceTypeConfigure[i].filePath,
                 })  

                 console.log("result.data[0]>>>",this.img);

                 let obj = {
                    'index':'',
                    'deviceType':'',
                    'selectedFiles':'',
                    'fileName': '',
                    'filePath': "",
                    'fileType':''
                }
        
               this.uploadImgFilePath.push(obj)
 

                  this.devices.push(this.updateDevice(result.data[0].results.productDeviceTypeConfigure[i]));
                
              }

          }

      });
    }

    savecreateFooterlink(){

         //remove empty file upload img obj from array.

         let  tempUploadImgFilePath = this.uploadImgFilePath;
         this.uploadImgFilePath = [];

         tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });

        //this.loader=true;
        
        this.commonService.changeloader(true);
        
        this.footerlinkSubmitted = true;
        let async = this.uploadImgFilePath.length;
        let varialbeArray=[];
        
        this.createFooterlinkForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            //this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.deviceTypeDublicate){
            //this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.multipleDeviceForm.value.devices.length == 0){
            //this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

        if(!this.multipleDeviceForm.valid){
            //this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

        if(!this.createFooterlinkForm.valid){
            //this.loader=false;
            this.commonService.changeloader(false);
            return;
        }

        console.log("uploadImgFilePath>>",this.uploadImgFilePath);

        if(!this.update){

            if(this.roleAsses.footerLinksCreate == false){

                this.commonService.changeloader(false);
                return;
            }

            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                                async--;                       
                                this.uploadImgFilePath[i].filePath=res.Location;  
                                this.footerlinkSubmitted = true;
                                console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                                let multiAsync = this.multipleDeviceForm.value.devices.length;
                                if (async == 0) {

                                    for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){

                                        multiAsync -- ;
                                        console.log("this.multipleDeviceForm.value.devices",this.multipleDeviceForm.value.devices); 
                                                varialbeArray.push(
                                                {
                                                    'confDescription':this.multipleDeviceForm.value.devices[i].confDescription,
                                                    'urlMapping': this.multipleDeviceForm.value.devices[i].urlMapping,
                                                    'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                                                    'fileName':'',
                                                    'filePath':'',
                                                    'fileType':'',
                                                    'label':this.multipleDeviceForm.value.devices[i].label,
                                                });   

                                    }
                                        
                                    if(multiAsync == 0){

                                        let asyncvariables = varialbeArray.length;

                                        for(let i= 0;i< varialbeArray.length;i++){

                                                asyncvariables--;
                                                
                                                for(let j = 0 ;j< this.uploadImgFilePath.length; j++){

                                                    if(varialbeArray[i].dtypeId == this.uploadImgFilePath[j].deviceType){

                                                        varialbeArray[i].fileName=this.uploadImgFilePath[j].fileName;
                                                        varialbeArray[i].filePath=this.uploadImgFilePath[j].filePath;
                                                        varialbeArray[i].fileType=this.uploadImgFilePath[j].fileType;

                                                        console.log("this.>>>",varialbeArray[i]);

                                                    }

                                                }
                                        }

                                        if(asyncvariables == 0){

                                            this.createFooterlinkForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                            console.log("this.createCarouselForm.value",this.createFooterlinkForm.value);
                                
                                            if(!this.multipleDeviceForm.valid){
                                                //this.loader=false;
                                                this.commonService.changeloader(false);
                                                return;
                                            }
                                            
                                    
                                            if(!this.createFooterlinkForm.valid){
                                                //this.loader=false;
                                                this.commonService.changeloader(false);
                                                return;
                                            }
                                                
                                            //this.postCallfooterLink();
                                            
                                            //New Code for right side for from
                                            this.onContentReadyToReview();
                                            
                                        }
                                    }
                                
                                }      
                        }

                    });
                }
            }

            if(this.uploadImgFilePath.length==0){

                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                    
                    varialbeArray.push(
                        {
                            'confDescription':this.multipleDeviceForm.value.devices[i].confDescription,
                            'urlMapping': this.multipleDeviceForm.value.devices[i].urlMapping,
                            'dtypeId':this.multipleDeviceForm.value.devices[i].dtypeId,
                            'fileName':this.multipleDeviceForm.value.devices[i].fileName,
                            'filePath':this.multipleDeviceForm.value.devices[i].filePath,
                            'fileType':this.multipleDeviceForm.value.devices[i].fileType,
                            'label':this.multipleDeviceForm.value.devices[i].label,
                        });   

                }
                
                this.footerlinkSubmitted = true;
                this.createFooterlinkForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    //this.loader=false;
                    this.commonService.changeloader(false);
                    return;
                }
                
        
                if(!this.createFooterlinkForm.valid){
                    //this.loader=false;
                    this.commonService.changeloader(false);
                    return;
                }

                
                console.log("createNewProductForm",this.createFooterlinkForm);
                //this.postCallfooterLink();
                
                //New Code for right side for from
                this.onContentReadyToReview();
                
            }

        }

        if(this.update){

            if(this.roleAsses.footerLinksEdit == false){
                
                this.commonService.changeloader(false);
                return;
            }

            let asyncvariables = this.multipleDeviceForm.value.devices.length;

            if(this.uploadImgFilePath.length == 0){

            console.log("this.multipleDeviceForm.value",this.multipleDeviceForm.value)
                this.createFooterlinkForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.footerlinkSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    //this.loader=false;
                    this.commonService.changeloader(false);
                    return;
                }

                if(!this.createFooterlinkForm.valid){
                    //this.loader=false;
                    this.commonService.changeloader(false);
                    return;
                }
            
                console.log("createNewProductForm",this.createFooterlinkForm);
                
                //this.postCallfooterLink();
                
                //New Code for right side for from
                this.onContentReadyToReview();
                
            }

            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                            this.uploadImgFilePath[i].filePath=res.Location; 
                            this.footerlinkSubmitted = true;
                        }

                        if (async == 0) {

                            for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j< this.uploadImgFilePath.length; j++){
                    
                                    if (this.multipleDeviceForm.value.devices[i].dtypeId == this.uploadImgFilePath[j].deviceType){

                                        this.multipleDeviceForm.value.devices[i].fileName=this.uploadImgFilePath[j].fileName;
                                        this.multipleDeviceForm.value.devices[i].filePath=this.uploadImgFilePath[j].filePath;
                                        this.multipleDeviceForm.value.devices[i].fileType=this.uploadImgFilePath[j].fileType;
                                        
                                    }

                                }
                            }

                            if(asyncvariables == 0){

                            
                                this.footerlinkSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    //this.loader=false;
                                    this.commonService.changeloader(false);
                                    return;
                                }
                                

                                if(!this.createFooterlinkForm.valid){
                                    //this.loader=false;
                                    this.commonService.changeloader(false);
                                    return;
                                }
                                
                                this.createFooterlinkForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("createNewProductForm",this.createFooterlinkForm);
                                //this.postCallfooterLink();

                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                                //New Code for right side for from
                                this.onContentReadyToReview();
                                
                            }
                        }
                    })
                }
            }

        }

    }

    selectUploadImageFile(e , i) {

        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePath[i].index = i;
        this.uploadImgFilePath[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePath[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePath[i].fileName =  e.target.files[0].name; 
        this.uploadImgFilePath[i].fileType = e.target.files[0].type;
    
        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {

            console.log("Only images are supported.");
            return;
        }
    
        var reader = new FileReader();
    
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {

          this.img[i].url=reader.result;
          
        }
        //this.loader= false;
        e.target.value = '';

        this.commonService.changeloader(false);
     
    }

    postCallfooterLink(){

        if(!this.update){

            if(this.roleAsses.footerLinksCreate ==false ){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

          delete this.createFooterlinkForm.value.id;
        
          this.footerLinkService.insertFooterlink(this.createFooterlinkForm.value)
          .subscribe((result:any)=>{

            //this.loader = false;
            this.commonService.changeloader(false);
            
            //New Code for right side for from
            this.update = false;
        //    this.updateSend.emit();
            this.resetForm();
            this.footerlinkSubmitted = false;
            this.toastrService.success('Footer Link Created Successfully.');
            
            //this.router.navigate(['master/footer_links/list']);

          })
        }

        if(this.update){

            if(this.roleAsses.footerLinksEdit ==false ){
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }

            this.createFooterlinkForm.controls['id'].setValue(this.id);
            this.footerLinkService.updateFooterlink(this.createFooterlinkForm.value)
            .subscribe((result:any)=>{
                
                //this.loader = false;
                this.commonService.changeloader(false);
                
                //New Code for right side for from
                this.update = false;
                //this.updateSend.emit();
                this.resetForm();
                this.footerlinkSubmitted = false;
                
                this.toastrService.success('Footer Link Updated Successfully.');
                this.router.navigate(['master/footer_links/list']);
              
            })
        }

    }

    isDeleteDeviceType(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delDevice(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    isDeleteImage(event){

        console.log('isDeleteImageAction event = ',event)

        if(event=='Cancel'){

            this.isDeleteImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteImageAction=false;
            
            delete this.img[this.deleteDeviceTypeIndex].url;
            
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileName="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].filePath="";
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].fileType="";

            this.uploadImgFilePath[this.deleteDeviceTypeIndex].deviceType = '';
            this.uploadImgFilePath[this.deleteDeviceTypeIndex].selectedFiles = '';
          

        }

    }

    deleteImageYes(i){
        this.deleteDeviceTypeIndex = i;
    }
    


    //New Code for right side for from
    onContentReadyToReview(){

        // this.loader = false;
        this.commonService.changeloader(false);

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }
    //is Content Ready To Review Received  

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);

        this.contentReadyToReview = false;

        if(event == 'No'){
            this.contentReadyToReview = false;
        }
        else if(event == 'Yes'){
            this.createFooterlinkForm.controls['contentState'].setValue(['Completed']);
            this.postCallfooterLink();
            this.onFooterLinkTabClick();
        }
        else if(event == 'Cancel'){
            this.createFooterlinkForm.controls['contentState'].setValue(['In-Progress']);
            this.postCallfooterLink();
            this.onFooterLinkTabClick();
        }
       
    }


    resetForm(){
    
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {
            arrayControl.removeAt(0);
        }

        this.updateSend.emit();

        console.log("this.formTabs >>>>",this.formTabs);

        this.createFooterlinkForm = this.formBuilder.group({
          isActive:['1'],
          id:[''],
          langId: ["1"],
          mapGeoZone:[[],Validators.compose([Validators.required])],
          exclusionText:[''],
          productDeviceTypeConfigure:[[]],
          sameForAllZone:[''],
          title: ['', Validators.compose([Validators.required])],
          description:[''],
          airStartDate:[''],
          airEndDate:[''],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          created_by:[localStorage.getItem('ud')]


        });
        
        
        this.update = false;

        //////////////////////

        this.img = [];
       

        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createDevice()])
        });

       
        this.showCreateNewButton('show');
       
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}