import { Injectable } from '@angular/core';
import { Subject , BehaviorSubject}    from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {awsConfiguration} from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient) { }

  updateStatus(postObj:any) : Observable<any> {

    return this.http.post(environment.apiURL + environment.adminApiUrl + environment.statusUpdateUrl, postObj,httpOptions);

  }

}
