import { Component } from '@angular/core';
import { Loginshared} from './services/sharedservices/loginshared.service'
import {Router} from '@angular/router';
import {environment} from '../environments/environment'
import {LoginService} from './services/login/login.service';
import {CommonService} from './services/common_Service/common.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
       './app.component.scss'
    ],
    providers: [ Loginshared]
})

export class AppComponent {

    isLoggedIn: boolean = false;
    applyBlackTheme:any;
    currentUrl:String;
    roleConfiguration:any ={};
    faviconImg =environment.faviconImg;
    
    constructor( private loginService:LoginService,  private commonService:CommonService,private loginSharedService: Loginshared, private router: Router ) {
        
        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                
                this.isLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid) {
                    this.isLoggedIn = true;
                }
            }
        );

        loginSharedService.currentLoginStatus.subscribe((result:any)=>{

            let uid = localStorage.getItem('ud');

            if (uid) {

                    setTimeout(()=>{

                        if(this.router.url == '/login') {

                            this.loginService.getRoleConfiguration(14)
                            .subscribe((result:any)=>{

                                this.roleConfiguration = result.data;
                                this.commonService.changeRole(this.roleConfiguration);

                                    this.router.navigate(['/dashboard']);
                                } )
                        
                        }

                })

                }
       })
    }
    

    ngOnInit() {

        // window.onpopstate = function (e) { window.history.forward(1); }



        this.router.events.subscribe((url:any) => {

            // console.log("123 ------------> ",this.router.url);
            this.currentUrl= this.router.url;
        });
        // console.log("123 ------------> ",this.router.url);
        // this.currentUrl= this.router.url;


        this.commonService.currentThemelight.subscribe((result:any)=>{
          
            this.applyBlackTheme=localStorage.getItem('boolThemeFlag');
            console.log("applyBlackTheme>>",this.applyBlackTheme);
       })
        let uid = localStorage.getItem('ud');  
        if (uid) {

            this.isLoggedIn = true;
            console.log("window.location",this.router.url);
    
            } 
            
        else
            this.isLoggedIn = false;            
    }
    

}
