import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable()
export class ProductTypeService {

    constructor(private http: HttpClient) { }

    updateProductType(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateProductTypeUrl, postObj,httpOptions);
    }
        
    insertProductType(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertProductTypeUrl, postObj,httpOptions);
    }    
    
    getProductType( id: any, langId:any) : Observable<any> {
        
        langId = localStorage.getItem('languageId');

        if(langId == null){
            localStorage.setItem('languageId','1');
            langId  = '1'; 
        }
        
        
        if(id){
         return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getProductTypeUrl+"/" + id +"/" + langId);   
        } 
        
    }
   
    getAllProductType(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');

        let urlGetAllProductType = environment.apiURL + environment.adminApiUrl + environment.getProductTypeUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllProductType)
    }

    getGlobalProductType(filterObj,start,limit){
        let urlGetAllProductType = environment.apiURL + environment.adminApiUrl + environment.getGlobalProductTypeUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllProductType)
    }
              
    deleteProductType(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteProductTypeUrl, postObj,httpOptions);
    }
}
