import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import {CommonService} from '../../../../../services/common_Service/common.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {UserService} from '../../../../../services/user/user.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {awsPath} from '../../../../../configurations/configuration';
import {status } from '../../../../../configurations/configuration';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {AbstractControl} from '@angular/forms';
import { errorMessages } from './../../../../../configurations/errorMessages';
import {SessionHelper} from '../../../../../services/localstorage';
import { DynamoDBService } from "../../../../../services/cognito/ddb.service";
//import { UserLoginService } from "../../../../../services/cognito/user-login.service";
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../../../../../services/cognito/cognito.service";
import {UserRegistrationService} from "../../../../../services/cognito/user-registration.service";

@Component({
  selector: 'app-addUser',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddUserComponent implements CognitoCallback, OnInit {
  
  isCreateNewShow:boolean = true;
  createUserSubmitted=false;

  contentReadyToReview:boolean= false;
  gender=false;
  errorImage:any='';
  status = status;
  presentUser:boolean = false;
  globalStateList:any=[];
  globalCountryList:any=[];
  globalCityList:any=[];
  globalRoleList:any=[];
  globalCitySettings:any=[];
  staticDeviceType:any=[];
  staticloginSource:any=[];
  staticRoleId:any=[];
  FileClosedCaptionURL:any;
  errorMessages = errorMessages;
  id:any;
  globalDeviceTypeList:any=[];
  globalLoginSourceList:any=[];
  update=false;
  loader = false;
  
  isContinueEditing:any;
  shareMassage:any;
  uploadImgFileclosedCaption:any=[];
  currentEmailId:any;
  createUserForm:FormGroup;
  createUserFormSubmit:boolean = false;
  
  isDeleteImageAction:boolean=false;
  errorMessage: string;

  applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    
    registerUser= {
        name: '',
        email: '',
        phone_number: '',
        password: ''
    }
    
  
    constructor(private userRegistration:UserRegistrationService, public ddb: DynamoDBService, private activatedRoute:ActivatedRoute, private toastrService:ToastrService,private userService:UserService,private productConfigurationService:ProductConfigurationService , private router: Router,private commonService:CommonService,private formBuilder:FormBuilder) {
    
    }

    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

    //New Code start
      @Input() set contentId(value: any) {
  
          if(value == 'reset'){
  
              this.resetForm();
  
          }else {
  
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
              this.onLoad(value);
          }
          
      }
      //New Code end

      roleAsses:any={};
      roleConfiguration:any=[];
      ngOnInit(){
    // theme update code start

    
    this.roleAsses.usersEdit = false;
    this.roleAsses.users = false;
    this.roleAsses.usersCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {

          if((element.isView == 1 && element.pageTitle == 'Users') ){
              
              this.roleAsses.users = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Users') ){
              
              this.roleAsses.usersEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Users')){

              this.roleAsses.usersCreate = true;
          
          }

    })

                            
    this.commonService.currentThemelight.subscribe((result:any)=>{
                            
      this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      
    })

    // theme update code start
         }
         editCreate:boolean = false;    
   
      onCreateNew(){

          if(this.roleAsses.userCreate == true){

                  this.editCreate = true;
          }else {
              // this.editCreate = false;

          }
          
          this.isCreateNewShow=false
  
      }

    onLoad(id) {

      this.id = id;

      if(this.id != 'null' && this.roleAsses.userEdit == true){

        this.editCreate = true;

      }else{


      }

      // console.log("phone_number",phone_number)

        let headerTitle = {
            title:'User Management',
            subTitle:'User'
        }

        this.commonService.changeHeaderTitle(headerTitle);

      this.staticRoleId = [{

        'id':'1',
        'itemName':'Authenticated User'
      }]

        this.createUserForm = this.formBuilder.group({

            "id":[''],
            "emailId": ['', Validators.compose(
              [Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'), Validators.required])],
            "firstName": ['', Validators.compose(
              [Validators.pattern('[A-Za-z ]+'), Validators.required])],
            "middleName": [""],
            "lastName":  ['', Validators.compose(
              [Validators.pattern('[A-Za-z ]+')])],
            "address1": [''],
            "address2": [''],
            "country":[[]],
            "city": [[]],
            "state": [[]],
            "zip": ['',Validators.compose([Validators.pattern('[0-9]+')])],
            "dateOfBirth": [''],
            "sex": ["Male"],
            // "mobile":   ['',Validators.compose([Validators.pattern('[0-9]+')])],
            "mobile":   ['',Validators.compose([Validators.pattern('[0-9]+')]) ],
            "status": ["Active"],
            "deviceTypeId": [[]],
            "loginSourceId": [[]],
            "fileName": [""],
            "filePath": [""],
            "fileType": [""],
            "roleId": [[]],
            "password":['', Validators.compose(
              [Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20})'), Validators.required])],
            "cpassword":['',[Validators.required]],
            cognitoId:[''],
            created_by:[localStorage.getItem('ud')]

        },
        {
          validator:AddUserComponent.MatchPassword
        })

        this.globalCitySettings = {
          singleSelection: true, 
          text:"Select",
          // filterSelectAllText:'',
          // filterUnSelectAllText:'',
          // selectAllText:'Select All',
          // unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          // classes:"myclass custom-class"
        }

      // this.activatedRoute.params.subscribe(params => {
        this.id = id; // (+) converts string 'id' to a number
        if(this.id!='null'){
        
          console.log('this.id===>>>',this.id);
          this.createUserForm.controls['id'].setValue(this.id); 
            this.update=true;
            this.getUser(this.id);
            this.createUserForm.get('password').clearValidators();
            this.createUserForm.get('cpassword').clearValidators();

        }else{

          this.update=false;
          this.getGlobalStateList({});
          this.getGlobalCountryList({});
          this.getGlobalCityList({});
          
        }

    // In a real app: dispatch action to load the details here.
      // });

    
        this.getGlobalLoginSourceList();
        this.getGlobalDeviceTypeList();
        this.getGlobalrole();
    }


    resetForm(){


        this.commonService.changeloader(false);
        //code for global loader end

        this.update = false;
        this.createUserFormSubmit = false;

        this.staticRoleId = [{

          'id':'1',
          'itemName':'Authenticated User'
        }]

        this.createUserForm = this.formBuilder.group({

            "emailId": ['', Validators.compose(
              [Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'), Validators.required])],
            "firstName": ['', Validators.compose(
              [Validators.pattern('[A-Za-z ]+'), Validators.required])],
            "middleName": [""],
            "lastName":  ['', Validators.compose(
              [Validators.pattern('[A-Za-z ]+')])],
            "address1": [''],
            "address2": [''],
            "country":[[]],
            "city": [[]],
            "state": [[]],
            "zip": ['',Validators.compose([Validators.pattern('[0-9]+')])],
            "dateOfBirth": [''],
            "sex": ["Male"],
            // "mobile":   ['',Validators.compose([Validators.pattern('[0-9]+')])],
            "mobile":   ['',Validators.compose([Validators.pattern('[0-9]+')]) ],
            "status": ["Active"],
            "deviceTypeId": [[]],
            "loginSourceId": [[]],
            "fileName": [""],
            "filePath": [""],
            "fileType": [""],
            "roleId": [[]],
            "password":['', Validators.compose(
              [Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20})'), Validators.required])],
            "cpassword":['',[Validators.required]],
            "cognitoId":[''],
            "created_by":[localStorage.getItem('ud')]

        },
        {
          validator:AddUserComponent.MatchPassword
        })

        this.globalCitySettings = {

          singleSelection: true, 
          text:"Select",
          enableSearchFilter: true,
      
        }

        this.FileClosedCaptionURL = '';

        this.showCreateNewButton('show');
        
    }


    static MatchPassword(AC: AbstractControl) {
      let password = AC.get('password').value; // to get value in input tag
      let confirmPassword = AC.get('cpassword').value; // to get value in input tag
      if(password != confirmPassword) {
          AC.get('cpassword').setErrors( {MatchPassword: true} )
      } else {
          return null
      }
    }


    onDeSelectAll(event, info){
        // console.log(items);
        if(info =='country'){
            this.createUserForm.controls['country'].setValue([]);
        }
        else if(info =='state'){
            this.createUserForm.controls['state'].setValue([]);
        }
        else if(info =='city'){
            this.createUserForm.controls['city'].setValue([]);
        }
        else if(info =='deviceTypeId'){
            this.createUserForm.controls['deviceTypeId'].setValue([]);
        }
        else if(info =='loginSourceId'){
            this.createUserForm.controls['loginSourceId'].setValue([]);
        }
        else if(info =='roleId'){
            this.createUserForm.controls['roleId'].setValue([]);
        }
    }

  get l() { return this.createUserForm.controls; }

  openCalendar(){
   
  }

      //New Code for right side for from
      onContentReadyToReview(){

        if(!this.createUserForm.valid) {
           console.log("2")
           this.loader=false;
           this.createUserFormSubmit = true;
           return
        }

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'users';

      }

        // is Content Ready To Review Received  

      isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            // this.createUserForm.controls['contentState'].setValue(['Completed']);
            this.onSaveUser();
           
        }
        if(event == 'Cancel'){

            // this.createUserForm.controls['contentState'].setValue(['In-Progress']);
         //   this.onSaveUser();
           

        }
       
      }

    getGlobalCountryList(stateFilter){

        this.loader = true;

        this.commonService.getGlobalCountryList(stateFilter).subscribe((result:any)=>{
              
          this.loader = false;

          this.globalCountryList = [];
          
          if(result.data.data.length > 0){   

              for (let objStateList of result.data.data) {

                  if(objStateList.countryId!=1){
                  
                      this.globalCountryList.push(
                      {
                          'id': objStateList.countryId,
                          'itemName': objStateList.countryName
                      });
                  } 
              }
  
          } 
        });
    }

    getGlobalStateList(stateFilter){

        this.commonService.getGlobalStateList(stateFilter).subscribe((result:any)=>{
            
            this.globalStateList = [];

              this.loader = false;

            if(result.data.data.length > 0){       
                for (let globalStateList of result.data.data) {

                    if(globalStateList.stateId != 1){
                    
                        this.globalStateList.push(
                        {
                            'id': globalStateList.stateId,
                            'itemName': globalStateList.stateName
                        });
                    } 
                }
            } 

        });
    }

    getGlobalCityList(cityFilter){

        this.commonService.getGlobalCityList(cityFilter).subscribe((result:any)=>{
            
            this.globalCityList = [];

            this.loader = false;

            if(result.data.data.length > 0){  

                for (let globalCityList of result.data.data) {

                    if(globalCityList.cityId!=1){
                    
                        this.globalCityList.push(
                        {
                            'id': globalCityList.cityId,
                            'itemName': globalCityList.cityName
                        });
                    } 
                }
            } 

        });
    }

    getGlobalrole(){

        this.commonService.getGlobalRole().subscribe((result:any)=>{
        
            this.globalRoleList = [];
            result.data.data.forEach(element => {

              this.globalRoleList.push(
                {
                    'id': element.roleId,
                    'itemName': element.roleName
                });
                
            });

        });

    }

    getGlobalDeviceTypeList(){

        this.commonService.getGlobalDeviceTypeList().subscribe((result:any)=>{
              
          this.globalDeviceTypeList = [];
          
          if(result.data.data.length > 0){       
              for (let globalCityList of result.data.data) {

                  if(globalCityList.deviceTypeId!=''){
                  
                      this.globalDeviceTypeList.push(
                      {
                          'id': globalCityList.deviceTypeId,
                          'itemName': globalCityList.deviceTypeName
                      });
                  } 
              }
          } 

      });

    }

    getGlobalLoginSourceList(){

        this.commonService.getGlobalLoginSourceList().subscribe((result:any)=>{
              
          this.globalLoginSourceList = [];
          
          if(result.data.data.length > 0){       
              for (let globalCityList of result.data.data) {

                  if(globalCityList.loginSourceId!=''){
                  
                      this.globalLoginSourceList.push(
                      {
                          'id': globalCityList.loginSourceId,
                          'itemName': globalCityList.loginSourceName
                      });
                  } 
              }
          } 

      });

    }

    selectFileClosedCaption(e){
      
      this.loader = true;

      this.uploadImgFileclosedCaption=[];

      let obj ={
        
          'selectedFiles':e.target.files.item(0),
          "fileName": e.target.files[0].name,
          "filePath": "",
          "fileType": e.target.files[0].type, 
      }

      var mimeType = e.target.files[0].type;

      if (mimeType.match(/image\/*/) == null) {

          console.log("Only images are supported.");
          this.errorImage = 'Only images are supported.';
          this.FileClosedCaptionURL = '';
        
          return;
      }

     this.errorImage='';

      var reader = new FileReader();

          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (_event) => {
              this.FileClosedCaptionURL=reader.result;
            
          }

      this.uploadImgFileclosedCaption.push(obj);  

      if(this.uploadImgFileclosedCaption.length>0){

          for (let i = 0; i < this.uploadImgFileclosedCaption.length; i++) {
      
              this.productConfigurationService.uploadFile(this.uploadImgFileclosedCaption[i].selectedFiles, awsPath.asset).then(res => {

                  if (res && res.Location) {
                    this.loader = false;
                      this.uploadImgFileclosedCaption[i].FilePath=res.Location;  
                      this.createUserForm.controls['fileName'].setValue(this.uploadImgFileclosedCaption[i].fileName);
                      this.createUserForm.controls['filePath'].setValue(res.Location);
                      this.createUserForm.controls['fileType'].setValue(this.uploadImgFileclosedCaption[i].fileType);
                      console.log("createNewProductForm",this.createUserForm);
                  }          
              })
          }

      }
      e.target.value = '';
    }

    onCountrySelect(item){

      this.loader = true;
      this.createUserForm.controls['state'].setValue([]); 
      this.createUserForm.controls['city'].setValue([]); 
      this.getGlobalStateList({'countryId':item.id});

    }

    onCountryDeSelect(e){

      this.createUserForm.controls['state'].setValue([]); 
      this.createUserForm.controls['city'].setValue([]); 
      this.getGlobalStateList({});
      this.getGlobalCityList({});

    }
  
  onStateSelect(item){

    this.loader = true;
    this.createUserForm.controls['city'].setValue([]); 
    this.getGlobalCityList({'stateId':item.id})
  }

  onStateDeSelect(e){

    this.loader = true;
    this.getGlobalStateList({});
    this.getGlobalCityList({})

  }

  

    onSaveUser(){

        this.loader=true;
        this.presentUser=false;
        this.createUserFormSubmit = true;

        if(this.errorImage != ''){
          console.log("1")
          this.loader = false;
        
          return
        }

        let filterObj = {

          "emailId": this.createUserForm.value.emailId

        }

          if(!this.createUserForm.valid) {
              console.log("2")
              this.loader=false;
              return
          }

          if(this.update == true) {
            
            if(this.roleAsses.usersEdit == false){
                this.loader=false;
                this.toastrService.success('no access to edit.');
                return;
            }
              
              console.log("update========================================");
              console.log("createUserForm",this.createUserForm.value);

              this.userService.updateUser(this.createUserForm.value)
              .subscribe((result:any)=>{

                this.toastrService.success('User Updated Successfully.');
                this.loader = false;

                this.update = false;
                this.resetForm();
                this.updateSend.emit();
                this.createUserSubmitted = false;
                // this.router.navigate(['users/list']);
              })

          } else{

              if(this.roleAsses.usersCreate == false){

                  this.loader=false;
                  this.toastrService.success('no access to create.');
                  return;

              }

              this.userService.getUserCheck(filterObj)
              .subscribe((result:any)=>{

                if(result.data.length == 0){

                    delete  this.createUserForm.value.id 
                      
                      console.log("user Creation in db===>",this.createUserForm.value);
                      
                      //Congnito user registration
                      this.registerUser.name = this.createUserForm.value.firstName;
                      this.registerUser.email = this.createUserForm.value.emailId;
                      this.registerUser.phone_number = this.createUserForm.value.mobile;
                      this.registerUser.password = this.createUserForm.value.password;
                      
                      console.log("user Creation in db==xx=>",this.registerUser);
                                  
                      //let cognitoRegisterUserResponse = this.userRegistration.register(this.registerUser, this);
                      
                      //console.log("cognitoRegisterUserResponse",cognitoRegisterUserResponse);
                      
                      this.userService.insertUser(this.createUserForm.value)
                      .subscribe((result:any)=>{
                        this.loader = false;

                        this.update = false;
                        this.resetForm();
                        this.updateSend.emit();
                        this.createUserSubmitted = false;
                          console.log("save========================================",result);
                          this.toastrService.success('User Created Successfully.');
                          // this.router.navigate(['users/list']);

                      });

                }else{
                        this.presentUser = true ; 
                        this.currentEmailId = this.createUserForm.value.emailId
                        console.log("presentUser========================================",this.presentUser);
                        this.loader=false;
                }

              })
          }
          
    }

    errorClear(){
      this.presentUser = false ; 
    }

    getUser(id){
      this.userService.getUser(id)
      .subscribe((result:any)=>{

        if(result.data[0].emailId!=null){
          this.createUserForm.controls['emailId'].setValue(result.data[0].emailId);
        }
        if(result.data[0].firstName!=null){
          this.createUserForm.controls['firstName'].setValue(result.data[0].firstName);
        }
        if(result.data[0].middleName!=null){
          this.createUserForm.controls['middleName'].setValue(result.data[0].middleName);
        }
        
        if(result.data[0].address1!=null){
          this.createUserForm.controls['address1'].setValue(result.data[0].address1);
        }
        if(result.data[0].address2!=null){
          this.createUserForm.controls['address2'].setValue(result.data[0].address2);
        }

        if(result.data[0].lastName!=null){
          this.createUserForm.controls['lastName'].setValue(result.data[0].lastName); 
        }

        if(result.data[0].sex!=null){
          this.createUserForm.controls['sex'].setValue(result.data[0].sex); 
        }
      
              
              let deviceType;

              if(result.data[0].deviceTypeName!=null){

                deviceType = [{

                'id': result.data[0].deviceTypeId,
                'itemName': result.data[0].deviceTypeName

                }]

              }else{

                deviceType = [];

              }

              var country;

              if(result.data[0].countryName!=null){

                country =[{
                  'id': result.data[0].countryId,
                  'itemName': result.data[0].countryName
                }]

              }else{

                country = [];

              }


              let loginSource;

              if(result.data[0].loginSourceId!=null){

                loginSource =[{
                  'id': result.data[0].loginSourceId,
                  'itemName': result.data[0].loginSourceName
                }]

              }else{

                loginSource = [];

              }

              // let state =[{
              //   'id':result.data[0].stateId,
              //   'itemName':result.data[0].stateName
              // }]

              let state;
              let city;

              if(result.data[0].cityId!=null){

                city =[{
                  'id': result.data[0].cityId,
                  'itemName': result.data[0].cityName
                }]

              }else{

                city = [];

              }


              if(result.data[0].stateId!=null){

                state = [{
                'id': result.data[0].stateId,
                'itemName': result.data[0].stateName
                }]

                }else{

                  state = [];
                  
                }


            if(result.data[0].cityId!=null){

                city =[{
                  'id': result.data[0].cityId,
                  'itemName': result.data[0].cityName
                }]

              }else{

                city = [];
                
              }


          if(result.data[0].cityId!=null){

              city =[{
              'id': result.data[0].cityId,
              'itemName': result.data[0].cityName
              }]

          }else{

            city = [];
          
          }
            

              this.getGlobalCountryList({});
              this.getGlobalStateList({'countryId':result.data[0].countryId});
              this.getGlobalCityList({'stateId':result.data[0].stateId,});

              let role;

              if(result.data[0].roleId!=null && result.data[0].roleId !=''){

                role =[{
                'id': result.data[0].roleId,
                'itemName': result.data[0].roleName
              }]

              this.createUserForm.controls['roleId'].setValue(role);

            }else{

              role = [];
              this.createUserForm.controls['roleId'].setValue(role);

            }


              // if(result.data[0].sex == 'Male'){

              //   this.gender = false;
              //   this.createUserForm.controls['sex'].setValue('Male'); 
              // }else{
          
              //   this.gender = true;
              //   this.createUserForm.controls['sex'].setValue('Female'); 
              // }

              if(result.data[0].emailId!=null){
                
                this.createUserForm.controls['emailId'].setValue(result.data[0].emailId);
              }else{
                this.createUserForm.controls['emailId'].setValue('');
              }

              if(result.data[0].firstName!=null){
                this.createUserForm.controls['firstName'].setValue(result.data[0].firstName);
              }else{
                this.createUserForm.controls['firstName'].setValue('');
              }


              if(result.data[0].middleName!=null){
                this.createUserForm.controls['middleName'].setValue(result.data[0].middleName);
              }else{
                this.createUserForm.controls['middleName'].setValue('');
              }

              // if(result.data[0].title!=null){
              //   this.createUserForm.controls['title'].setValue(result.data[0].title);
              // }else{
              //   this.createUserForm.controls['title'].setValue('');
              // }

              if(result.data[0].address1!=null){
                this.createUserForm.controls['address1'].setValue(result.data[0].address1);
              }else{
                this.createUserForm.controls['address1'].setValue('');
              }

              if(result.data[0].address1!=null){
                this.createUserForm.controls['address1'].setValue(result.data[0].address1);
              }else{
                this.createUserForm.controls['address1'].setValue('');
              }

              if(result.data[0].address2!=null){
                this.createUserForm.controls['address2'].setValue(result.data[0].address2);
              }
        
              if(result.data[0].lastName!=null){
                this.createUserForm.controls['lastName'].setValue(result.data[0].lastName); 
              }
        
              if(result.data[0].sex!=null){
                this.createUserForm.controls['sex'].setValue(result.data[0].sex); 
              }
      
                        
              this.createUserForm.controls['city'].setValue(city);
              this.createUserForm.controls['country'].setValue(country);
            
              this.createUserForm.controls['deviceTypeId'].setValue(deviceType);
              this.createUserForm.controls['loginSourceId'].setValue(loginSource);
              this.createUserForm.controls['state'].setValue(state); 
              this.createUserForm.controls['zip'].setValue(result.data[0].zip);
              this.createUserForm.controls['sex'].setValue(result.data[0].sex);
              this.createUserForm.controls['mobile'].setValue(result.data[0].mobile);
              this.createUserForm.controls['status'].setValue(result.data[0].status);
              this.createUserForm.controls['fileName'].setValue(result.data[0].fileName);
              this.createUserForm.controls['filePath'].setValue(result.data[0].filePath);
              this.createUserForm.controls['fileType'].setValue(result.data[0].fileType);
              this.FileClosedCaptionURL = result.data[0].filePath;

              var dateOfBirth;
                dateOfBirth = result.data[0].dateOfBirth.replace('T00:00:00.000Z', ""); 

                this.createUserForm.controls['dateOfBirth'].setValue(dateOfBirth);

          })

    }

    onGender(gender){

      if(gender == true){

        this.gender = false;
        this.createUserForm.controls['sex'].setValue('Male'); 
      }else{

        this.gender = true;
        this.createUserForm.controls['sex'].setValue('Female'); 
      }

    }
    
    cognitoCallback(message: string, result: any) {
        
        if (message != null) { //error
            this.loader = false;
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { //success
            this.ddb.writeLogEntry("login");
            //this.router.navigate(['/securehome']);
             console.log("Cognito Callback===>",result);
        }
    }

    isDeleteImage(event){

        console.log('isDeleteImageAction event = ',event)
        if(event=='Cancel'){

            this.isDeleteImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteImageAction=false;
            
            // delete this.uploadImgFilePath;
            this.FileClosedCaptionURL ='';
            
            this.createUserForm.controls['fileName'].setValue(['']);
            this.createUserForm.controls['filePath'].setValue(['']);
            this.createUserForm.controls['fileType'].setValue(['']);
        }

    }
        
    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
}   
