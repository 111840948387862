import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {CommonService} from '../../../services/common_Service/common.service';
import { LoginService } from '../../../services/login/login.service';
import { Loginshared } from '../../../services/sharedservices/loginshared.service';
import { SearchService } from './../../../services/search/search.service';
import {SessionHelper} from '../../../services/localstorage';
import { ChangeDetectorRef,AfterContentChecked} from '@angular/core'
declare var $: any;
import{environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    
    isUserLoggedIn: boolean = false;
    logOutButtonClicked = false;
    
    activeEmail:any ='';

    environment = environment;
    
    showUserMenu: boolean;
    roleAsses:any = {};

    roleConfiguration:any =[];
    
    searchMessage:string="";
    searchName:string="";

    boolThemeFlag:boolean=false;

    boolThemeCustomFlag:boolean=false;

    isLangugePopUp:boolean = false;

    boolTheme:any = 'false';

    isThemepage:boolean = false;

    languageList:any = [];

    loader:boolean = false;

    headerTitle = {
        'subTitle':'',
        'title':''
    }
  
    constructor( private cdRef:ChangeDetectorRef,private commonService:CommonService,private searchService:SearchService, private loginSharedService: Loginshared, private loginService: LoginService, private router: Router ) {
        
        this.showUserMenu = false;
        
        loginSharedService.sendUserLoggedIn$.subscribe(
            mission => {
                this.isUserLoggedIn = mission;
                let uid = localStorage.getItem('ud');
                if (uid !== undefined && uid != null) {
                    this.isUserLoggedIn = true;
                }
            }
        );
    }

    selectedFlag:Boolean = false;
    languageName:any='';


    ngOnInit() {

        this.languageName='Language';

        localStorage.setItem('languageId','1');
        if(localStorage.getItem('languageName')!=null){

            this.languageName = localStorage.getItem('languageName');
    
        } 

        this.commonService.currentThemeLanguage.subscribe((result:any)=>{

            this.languageName = localStorage.getItem('languageName');

            if(localStorage.getItem('languageName') == null){
                
                this.languageName='Language';
  
            }

        })


        this.commonService.notify.subscribe((result) => {

            this.loader = false;
            
        })



        
    

        this.activeEmail = localStorage.getItem('email');
        // role managemet code start

        this.loginService.getRoleConfiguration(localStorage.getItem('roleId'))
        .subscribe((result:any)=>{

            this.roleConfiguration = result.data;
            this.commonService.changeRole(this.roleConfiguration);

            console.log("roleConfiguration>>>",this.roleConfiguration);

            SessionHelper.setRole(this.roleConfiguration);
            this.roleConfiguration.forEach((element , i) => {

                if((element.isView == 1 && element.pageTitle == 'Product Type') || (element.isView == 1 && element.pageTitle == 'Configuration') ){
                    
                    this.roleAsses.configuration = true;
                   
                }
    
                if( (element.isView == 1 && element.pageTitle == 'Primary Navigation') || (element.isView == 1 && element.pageTitle == 'Menu Category') ){
                       
                    this.roleAsses.navigation = true;
                   
                }
    
                if((element.isView == 1 && element.pageTitle == 'Reconcilliation') || (element.isView == 1 && element.pageTitle == 'Asset') || (element.isView == 1 && element.pageTitle == 'Asset Group') || (element.isView == 1 && element.pageTitle == 'Asset Category') ){
                    
                     this.roleAsses.asset_management = true;
                   
                }
    
    
                if((element.isView == 1 && element.pageTitle == 'Page Category') || (element.isView == 1 && element.pageTitle == '"Carousel"') ){
                
                    this.roleAsses.page = true;
            
                }
    
                  if((element.isView == 1 && element.pageTitle == '"Genres"') 
                    || (element.isView == 1 && element.pageTitle == '"Geo Zone"')
                    || (element.isView == 1 && element.pageTitle == '"Cast And Crew"')
                    ||(element.isView == 1 && element.pageTitle == '"Sponsor"')
                    ||(element.isView == 1 && element.pageTitle == '""Reviews""')
                    ||(element.isView == 1 && element.pageTitle == '"Classification"')
                    ||(element.isView == 1 && element.pageTitle == '"Uplynk Ad Server"')
                    ||(element.isView == 1 && element.pageTitle == '"Footer Links"')
                    ||(element.isView == 1 && element.pageTitle == '"Character"')
                    ||(element.isView == 1 && element.pageTitle == '""Blogs""')  
                    ||(element.isView == 1 && element.pageTitle == '"Blogs Category"')
                    ||(element.isView == 1 && element.pageTitle == '""News""')
                    ||(element.isView == 1 && element.pageTitle == '"News Category"')
                    ||(element.isView == 1 && element.pageTitle == '"Bulk Import / Export"'))
                     {
                        this.roleAsses.master = true;
                     
                      }
    
                  if((element.isView == 1 && element.pageTitle == '"Users"') )
                  {
                   
                    this.roleAsses.user_management = true;
                  }
    
                  if((element.isView == 1 && element.pageTitle == '"Orders"') )
                  {
    
                    this.roleAsses.orders = true;
    
                  }
    
                  if((element.isView == 1 && element.pageTitle == '"Subscription"') )
                  {
    
                    this.roleAsses.subscription = true;
    
                  }
              
                   
            })
    
        })
       
        // role managemet code end

        //global loader
        this.commonService.currentLoader.subscribe((result:any)=>{

            this.loader = result;

            this.cdRef.detectChanges();

        })


        this.commonService.currentheaderTitle.subscribe((result:any)=>{

            this.headerTitle = result;

        })

        this.boolTheme = localStorage.getItem('boolThemeFlag');
        
        if(this.boolTheme == 'true'){

         this.boolThemeFlag = true;
         this.cdRef.detectChanges();


        }else{

            this.boolThemeFlag = false;
            this.cdRef.detectChanges();


        }
    
        let uid = localStorage.getItem('ud');  
        if (uid !== undefined && uid != null) {
            this.isUserLoggedIn = true;
            this.cdRef.detectChanges();

        }
        else
        {
            this.isUserLoggedIn = false;
            this.cdRef.detectChanges();

        }
        
        this.searchService.currentApprovalStageMessage.subscribe(msg => this.searchMessage = msg);
    }

    
    onCheckCustom(){
        
        this.boolThemeCustomFlag = !this.boolThemeCustomFlag;

        if(this.boolThemeCustomFlag == true){
          
            this.boolThemeFlag = false;
            localStorage.setItem('boolThemeFlag','trueCustom');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
            this.commonService.changeThemelight(this.boolTheme);

        }else{
            
            this.boolThemeFlag = false;
            localStorage.setItem('boolThemeFlag','false');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
            this.commonService.changeThemelight(this.boolTheme);
     
        }
         
    }

    onCheckTheme(){

    //    this.isThemepage = true;

        console.log("this.isThemepage = true;",this.isThemepage );

        this.boolThemeCustomFlag = false;

        this.boolThemeFlag = !this.boolThemeFlag;

        if(this.boolThemeFlag == true){

            localStorage.setItem('boolThemeFlag','true');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
            this.commonService.changeThemelight('');

        }else{

            localStorage.setItem('boolThemeFlag','false');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
            this.commonService.changeThemelight('');
        }
        
        
      
    }

    onCheckThemeHeader(){

        this.isThemepage = true;
        this.cdRef.detectChanges();


        // console.log("this.isThemepage = true;",this.isThemepage );

        // this.boolThemeCustomFlag = false;

        // this.boolThemeFlag = !this.boolThemeFlag;

        // if(this.boolThemeFlag == true){

        //     localStorage.setItem('boolThemeFlag','true');
        //     this.boolTheme = localStorage.getItem('boolThemeFlag');
        //     this.commonService.changeThemelight(this.boolThemeFlag);

        // }else{

        //     localStorage.setItem('boolThemeFlag','false');
        //     this.boolTheme = localStorage.getItem('boolThemeFlag');
        //     this.commonService.changeThemelight(this.boolThemeFlag);
        // }
        
        
      
    }

    isThemePageReceive(event){

        this.boolThemeFlag = false;
       
        this.showUserMenu = false;

        if(event == 'custom'){

            localStorage.setItem('boolThemeFlag','trueCustom');
            this.boolTheme = localStorage.getItem('boolThemeFlag');

            console.log("boolTheme",this.boolTheme);
            this.commonService.changeThemelight('');
            this.isThemepage = false;
        }else if(event == 'dcafe'){

            localStorage.setItem('boolThemeFlag','false');
            this.boolTheme = localStorage.getItem('boolThemeFlag');
            this.commonService.changeThemelight('');
            this.isThemepage = false;

        }else {
            this.isThemepage = false;
        }

    }
    
    logOutClicked() {
      this.logOutButtonClicked = true;
    }

    cancelLogout() {
      this.logOutButtonClicked = false;
    }

    cancelOverlayLogout(event) {
        var target = event.target;
        var idAttr = target.attributes.id;
        var value = '';
        if(idAttr){
            value = idAttr.nodeValue;
        }
        
        if( value == "js_close_logout_popup"){
            this.logOutButtonClicked = false;
        }
    }  

    confirmLogout() {
      this.logOutButtonClicked = false;
      this.signOut();
    }

    signOut() {
        localStorage.clear();
        this.isUserLoggedIn = false;
        this.loginSharedService.sendUserLoggedIn(this.isUserLoggedIn);
        window.location.href= '/login';
    }

    signOutWithoutReload(){
        localStorage.clear();
        this.isUserLoggedIn = false;
        this.loginSharedService.sendUserLoggedIn(this.isUserLoggedIn);
    }
    
    toggleUserMenu() {
        this.showUserMenu = !this.showUserMenu;
    }
    
    onKeydown(event) {

        // console.log("this.router.url === '/login'",);
        this.searchService.updateSearchMessage(this.searchName);
        
        if(this.router.url !== '/search'){
            console.log("this.router.url === '/search'");
            this.router.navigate(['/search']);
        }

        // this.searchService.changeSearchInfo(this.searchtext);

        // if (event.key !== "Enter") {
        //   console.log("Key pressed",this.searchtext);
        // }
        // else{
        //     console.log("Enter Key pressed :",this.searchtext);
        // }
    }

    onLangugePopUp(){

        this.isLangugePopUp = true;

    }

    showModal(){

        this.isLangugePopUp = true;

    }

    isLanguageActionReceive(){

        this.isLangugePopUp = false;

    }



}
