import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MrssService {

  
  constructor(private http: HttpClient) { }

  updateMrssPartner(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateMrssPartnerUrl, postObj,httpOptions);
  }
        
  insertMrssPartner(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertMrssPartnerUrl, postObj,httpOptions);
  }    
  
  getMrssPartner( id: any) : Observable<any> {
      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getMrssPartnerbyIdUrl+"/" + id);
  }

  getAllMrssPartner(filterObj,start,limit){
      let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getMrssPartnerUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllCast)
  }

//   deleteMrssPartner(id:any) : Observable<any> {
//       return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteMrssPartnerUrl +'/' +id);
//   }
  
  deleteMrssPartner(id){
    let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.deleteMrssPartnerUrl +'/'+id;
    return this.http.get<any>(urlGetAllCast)
}



  ////////////////////////////////////

  
  insertMrssQueue(postObj:any) : Observable<any> {
    return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertMrssQueueUrl, postObj,httpOptions);
  } 

  getAllMrssQueueList(filterObj,start,limit){
    let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getMrssQueueUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
    return this.http.get<any>(urlGetAllCast)
}

getAllMrssQueue(){
    let urlGetAllCast = environment.apiURL + environment.adminApiUrl + '/'+ environment.getMrssQueueUrlList
    return this.http.get<any>(urlGetAllCast)
}


getMrssQueue( id: any) : Observable<any> {
      
    if(id){

    }
    else {
        id = 1;
    }

    return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getMrssQueueUrl+"/" + id);
}
     

  
}
