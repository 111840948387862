import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {contentType ,assetGroupType ,sorting ,orderby} from '../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import {AssetService} from '../../../../../services/asset/asset.service';
import {AssetCategoryService} from '../../../../../services/asset_category/asset-category.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {awsPath, deviceType} from '../../../../../configurations/configuration';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import {SessionHelper} from '../../../../../services/localstorage';

import {Observable}  from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-addAssetCategory',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentAssetCategory implements OnInit {

    isCreateNewShow:boolean = true;
    private searchDecouncer$: Subject<string> = new Subject();


    // startProduct:number = 0;
    // startCategory:number = 0;
    startLanguage:number = 0;

    reset:boolean = false;
    primaryObj:any = {};
    deviceType = deviceType;
    createAssetCategoryForm:FormGroup;
    contentType = contentType;
    assetGroupType = assetGroupType;
    Orderby = orderby;
    sorting = sorting;
    loader:boolean=false;
    createAssetCategorySubmitted=false;
    multipleDeviceForm:FormGroup;
    errorMessages=errorMessages;
    mapCharacterList:any=[];


    geoZoneList:any=[];
    dropdownList:any=[];
    selectedItems:any=[];
    menuCategoryList=[];
    mapClassificationList:any=[];
    mapReviewList:any=[];
    mapCertificatList:any=[];
    mapLibraryList:any=[];
    mapGenreList=[];
    mapCastList:any=[];
    assetGroupList:any=[];
    selectedAssetGroupList:any=[];


    deviceTypeFull:boolean = false;
    deviceTypeDublicate:boolean = false;
  
    producTypeList=[];
    ProducTypeDropdownList=[];
    selectedProductlist=[];
    selectedprimaryNavigationList=[];
    selectedmenuCategoryList=[];
    primaryNavigationList=[];
    mapLanguageList:any=[];
    mapSponsoreList:any=[];

    ProducTypeSettings={};
    primaryNavigationSettings={};
    menuCategorySettings = {};
    devices: FormArray;
    update=false;
    id:any;
    img:any=[];
    //pravin code end 


    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    menuCategoryMappingFilter:any={};
    assetGroupMappingFilter:any={};
    //end mapping filter

    uploadImgFilePath:any=[];
    uploadImgFilePathVertical:any=[];
    uploadImgFilePathHorizontal:any=[];

    All:boolean;
    Web:boolean;
    Android:boolean;
    all:boolean = false;;
    web:boolean;
    android:boolean;
    iOS:boolean;
    roku:boolean;

    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;
  
    dTypeDublicateError:any;
    langId:any;

    isDeleteHorizontalImageAction:boolean=false;
    isDeleteVerticalImageAction:boolean=false;
    contentReadyToReview:boolean= false;
    shareMassage :any;

    start:number = 0
    
    startProduct = 0;
    startPrimary = 0;
    startCategory = 0;
    startCharacter = 0;

    startReview = 0;
    reviewScrollCount = 0;
    isSearch = false;
    SponsoredScrollCount = 0;
    characterScrollCount = 0;
    productInfoObj:any={};
    geoZoneObj= {};
    primaryScrollCount = 0;

    
    menuCategoryScrollCount = 0;
    menuCategoryObj:any={};
    startMenuCateogry = 0;

    productScrollCount:number = 0;

    applyBlackTheme = localStorage.getItem('boolThemeFlag');

    formTabs:any = {

        assetCategoryInfo:{

         assetCategory:true,
         mapGeoZone:true,
         information:false,
        //  mapping:false,
         hierarchy:false,
         seo:false

        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
            horizontal:false,
            vertical:false

        },
        metadata:{
            metadata:false,
            information:false,
            castAndCrew:false,
            ordering:false
        },
        seo:false,
        // mapping:false
    }
    

   //New Code for right side for from
    @Output() updateSend = new EventEmitter<any>();
    //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.reset = true;

            this.resetForm();

        }else {

            this.reset = false;

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end


    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
    constructor(private router:Router,private assetCategoryService:AssetCategoryService,private activatedRoute:ActivatedRoute,private assetService:AssetService,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private spinnerService:Ng4LoadingSpinnerService,private toastrService:ToastrService,private formBuilder:FormBuilder) { 
        this.commonService.notify.subscribe((result) => {
            // this.loader = false;
            this.commonService.changeloader(false);
        })
    }

    roleAsses:any={};
    roleConfiguration:any=[];

    ngOnInit() {

        localStorage.removeItem('langItem');

          this.roleAsses.assetCategoryEdit = false;
          this.roleAsses.assetCategory = false;
          this.roleAsses.assetCategoryCreate = false;

          this.roleConfiguration = SessionHelper.getRole();

          this.roleConfiguration.forEach((element , i) => {

                if((element.isView == 1 && element.pageTitle == 'Asset Category') ){
                    
                    this.roleAsses.assetCategory = true;
                }
            
                if((element.isEdit == 1 && element.pageTitle == 'Asset Category') ){
                    
                    this.roleAsses.assetCategoryEdit = true;
                }
                
                if((element.isCreate == 1 && element.pageTitle == 'Asset Category')){

                    this.roleAsses.assetCategoryCreate = true;
                
                }

          })

        this.setupSearchDebouncer();

                // theme update code start
        
            //     this.commonService.currentThemelight.subscribe((result:any)=>{
          
            //         this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
                    
            //    })

                        
    //    this.commonService.currentThemelight.subscribe((result:any)=>{

    //             if(result.id == this.id){

    //                     this.update = true;

    //                     this.createAssetCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'))
                        
    //                     this.postCallAssetCategory();

    //             }
              
    //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
    //   })

      this.commonService.currentThemelight.subscribe((result:any)=>{

        if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='assetCategory'){

                let item = JSON.parse(localStorage.getItem('langItem'));

                if(item.id!=undefined)
                this.id = item.id;
                
                if(this.id != 'null' && this.roleAsses.assetCategoryEdit == true){

                    this.editCreate = true;
                    this.isCreateNewShow = false;
        
                }else{
        
                    // this.editCreate = false;
        
                }
                this.createAssetCategoryForm.controls['id'].setValue(this.id);

                this.createAssetCategoryForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                 this.isCreateNewShow = false;
                this.update = true;
                // this.postCAllAssetGroup();
                this.getAssetCategoryInfo(this.id);
               // this.postCallAssetCategory();

                // localStorage.removeItem('langItem');

        }
            this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      })
        
               // theme update code start
    }

    editCreate:boolean = false;

    onCreateNew(){

        if(this.roleAsses.assetCategoryCreate == true){

               this.editCreate = true;

        }

        this.isCreateNewShow = false;

    }

    onLoad(id) {

        this.id = id;

        if(this.id != 'null' && this.roleAsses.assetCategoryEdit == true){

            this.editCreate = true;
            this.isCreateNewShow = false;


        }else{

            this.editCreate = false;

        }


            
        // this.activatedRoute.params.subscribe(params => {
            // this.id = +params['id']; // (+) converts string 'id' to a number
            // this.langId = +params['langId']? +params['langId'] :"1";

            this.id = id;

            // if(localStorage.getItem("languageId")!= null){

            //     this.langId =localStorage.getItem("languageId");

            // }else {

            //     this.langId = "1";

            // }

            this.langId = localStorage.getItem('LanguageId');

            console.log("this.langId>>",this.langId);
         

            this.getGlobalCharacter();

            let headerTitle = {
                title:'Asset Management',
                subTitle:'Asset Category'
    
            }
    
            this.commonService.changeHeaderTitle(headerTitle);
            this.onAssetCategoryTabClick();

            if(this.id!='null'){
            
            console.log('this.id===>>>',this.id);
            
                this.update=true;
                this.getAssetCategoryInfo(this.id);
            
            }else{
            
                this.img.push({
                    horizontalURL:'',
                    verticalURL:''
                })   

                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }
    
                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }
    
                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
        
            }

        // In a real app: dispatch action to load the details here.
        // });
        
        this.createAssetCategoryForm = this.formBuilder.group({
            contentType : [""],
            isActive : ["1"],
            langId: [this.langId],
            companyId : [""],
            title :[ "",Validators.required],
            displayIndexing: [''],
            sameForAllZone : [''],
            sameForAllProductType :[''],
            // airStartDate : ["",Validators.required],
            // airStartTime:['',Validators.required],
            // airEndTime:['',Validators.required],
            // airEndDate : ["",Validators.required],
            airStartDate : [""],
            airStartTime:[''],
            airEndTime:[''],
            airEndDate : [""],
            exclusionText : [""],
            description : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            orderBy :[""],
            sorting : [""],
            mapGeoZone:[[],Validators.required],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapCertification:[[]],
            mapCharacter:[[]],
            mapCast:[[]],
            mapGenre:[[]],
            id:[''],
            // mapAssetLibrary:[[]],
            mapLibrary:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            mapClassification:[[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

        })
            

        this.productTypeMappingFilter.geoZoneId = [];
        this.primaryNavigationMappingFilter.productTypeId = [];
        this.menuCategoryMappingFilter.primaryNavigationId = [];
        this.assetGroupMappingFilter.menuCategoryId = [];

        this.getGeoZoneInfo({});
        // this.getProductTypeInfo(this.productTypeMappingFilter);
        // this.getNavigationInfo(this.primaryNavigationMappingFilter);
        // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
        // this.getGlobalAssetGroup(this.assetGroupMappingFilter);
        this.getGenreInfo();
        this.getCastInfo();
        this.getLibrary();
        this.getCertificate({});
        this.getLanguageInfo();
        this.getSponsoreInfo({});
        this.getGlobalReviewInfo({});
        this.getGlobalclassification({});

            this.menuCategorySettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'], 
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class",
            }


            this.ProducTypeSettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true, 
            searchBy: ['name'], 
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class",
            }

            this.primaryNavigationSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['name'], 
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class",
        }

        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });

    }

    get l() { return this.createAssetCategoryForm.controls; }

    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }

    onItemSelect(item:any, mappingName){

        console.log("select from = ",mappingName);
        console.log("item = ",item);

        if(mappingName == 'mapGeoZone'){
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.getProductTypeInfo(this.productTypeMappingFilter);
        }
        else if(mappingName == 'mapProductType'){
            this.primaryNavigationMappingFilter.productTypeId.push(item.id);
            this.getNavigationInfo(this.primaryNavigationMappingFilter);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            this.menuCategoryMappingFilter.primaryNavigationId.push(item.id);
            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
        }
        else if(mappingName == 'mapMenuCategory'){
            this.assetGroupMappingFilter.menuCategoryId.push(item.id);
            this.getGlobalAssetGroup(this.assetGroupMappingFilter);
        }
    }

    OnItemDeSelect(item:any, mappingName){
        
        if(mappingName == 'mapGeoZone'){
            for (let index=0; index<this.productTypeMappingFilter.geoZoneId.length;index++){

                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                    console.log("mapGeoZone deleted = ",item.id);

                    if(this.productTypeMappingFilter.geoZoneId.length == 0){
                        this.producTypeList = [];
                        this.createAssetCategoryForm.controls['mapProductType'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getProductTypeInfo(this.productTypeMappingFilter);
                    }

                    return;
                }
            }
        }
        else if(mappingName == 'mapProductType'){

            console.log("primaryNavigationMappingFilter>>",this.primaryNavigationMappingFilter);

            for (let index=0; index<this.primaryNavigationMappingFilter.productTypeId.length;index++){

                if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){

                    this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);
                    console.log("mapProductType deleted = ",item.id);

                    if(this.primaryNavigationMappingFilter.productTypeId.length == 0){
                        this.primaryNavigationList = [];
                        this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                        console.log("empty list= ",mappingName);
                        return;
                    }else{

                        console.log("empty list=123 ");
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                    }

                   
                }

            }
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            for (let index=0; index<this.menuCategoryMappingFilter.primaryNavigationId.length;index++){
                if(this.menuCategoryMappingFilter.primaryNavigationId[index] == item.id){
                    this.menuCategoryMappingFilter.primaryNavigationId.splice(index, 1);
                    console.log("mapPrimaryNavigation deleted = ",item.id);

                    if(this.menuCategoryMappingFilter.primaryNavigationId.length == 0){
                        this.menuCategoryList = [];
                        this.createAssetCategoryForm.controls['mapMenuCategory'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    }

                    return;
                }
            }
        }
        else if(mappingName == 'mapMenuCategory'){
            for (let index=0; index<this.assetGroupMappingFilter.menuCategoryId.length;index++){
                if(this.assetGroupMappingFilter.menuCategoryId[index] == item.id){
                    this.assetGroupMappingFilter.menuCategoryId.splice(index, 1);
                    console.log("mapMenuCategory deleted = ",item.id);

                    if(this.assetGroupMappingFilter.menuCategoryId.length == 0){
                        // this.assetGroupList = [];
                        // this.createAssetCategoryForm.controls['mapAssetGroup'].setValue([]);
                        console.log("empty list= ",mappingName);
                    }else{
                        this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                    }

                    return;
                }
            }
        }
    }

    onSelectAll(item:any, mappingName){
        console.log("select All from = ",mappingName);
       
        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter = filterObj;

            this.startProduct = 0;
            this.productScrollCount = 0;
            this.getProductTypeInfo(filterObj);
        }
        else if(mappingName == 'mapProductType'){
            let filterObj ={ productTypeId :[] };
            item.forEach(function (value) {
                filterObj.productTypeId.push(value.id);
            });
            this.primaryNavigationMappingFilter = filterObj;
            this.getNavigationInfo(filterObj);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            let filterObj ={ primaryNavigationId :[] };
            item.forEach(function (value) {
                filterObj.primaryNavigationId.push(value.id);
            });
            this.menuCategoryMappingFilter = filterObj;
            this.getGlobalMenuCategory(filterObj);
        }
        else if(mappingName == 'mapMenuCategory'){
            let filterObj ={ menuCategoryId :[] };
            item.forEach(function (value) {
                filterObj.menuCategoryId.push(value.id);
            });
            this.assetGroupMappingFilter = filterObj;
            this.getGlobalAssetGroup(filterObj);
        }
    }

    onDeSelectAll(event, info){

        if(info =='mapGeoZone'){
            this.createAssetCategoryForm.controls['mapGeoZone'].setValue([]);
            this.producTypeList = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapProductType'){
            this.createAssetCategoryForm.controls['mapProductType'].setValue([]);
            this.primaryNavigationList = [];
            this.mapProductTypeArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapPrimaryNavigation'){
            this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapMenuCategory'){
            this.createAssetCategoryForm.controls['mapMenuCategory'].setValue([]);
            this.assetGroupList = [];
            this.mapMenuCategoryArrayTemp = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapAssetGroup'){
            this.createAssetCategoryForm.controls['mapAssetGroup'].setValue([]);
        }
        else if(info =='mapClassification'){
            this.createAssetCategoryForm.controls['mapClassification'].setValue([]);
        }
        else if(info =='mapCertification'){
            this.createAssetCategoryForm.controls['mapCertification'].setValue([]);
        }
        else if(info =='mapLibrary'){
            this.createAssetCategoryForm.controls['mapLibrary'].setValue([]);
        }
        else if(info =='mapGenre'){
            this.createAssetCategoryForm.controls['mapGenre'].setValue([]);
        }
        else if(info =='mapLanguage'){
            this.createAssetCategoryForm.controls['mapLanguage'].setValue([]);
        }
        else if(info =='mapSponsor'){
            this.createAssetCategoryForm.controls['mapSponsor'].setValue([]);
        }
        else if(info =='mapReview'){
            this.createAssetCategoryForm.controls['mapReview'].setValue([]);
        }
        else if(info =='mapCast'){
            this.createAssetCategoryForm.controls['mapCast'].setValue([]);
        }
    }

    getAssetCategoryInfo(id){

        // this.loader=true;
        this.commonService.changeloader(true);
        this.assetCategoryService.getAssetCategory(id, this.langId)
        .subscribe((result:any)=>{

            this.commonService.changeloader(false);

             if(result.data.length == 0)
                return;

            this.createAssetCategoryForm.controls['description'].setValue(result.data[0].description);
      
                  //(end) set dropdown by default empty
              //(end) set dropdown by default empty
              if(result.data[0].airStartDate!= null){

                let startTime = result.data[0].airStartDate.split('T').pop();
                let date = result.data[0].airStartDate.replace(startTime, '');
                 date = date.replace('T', '');
             
                startTime = startTime.slice(0, -5);
                console.log("date",date);

                if(startTime =='0000-00-00 00' || startTime =='' || startTime =='00:00:00'){

                    startTime = '';
                    
                }


                if(date ==''){

                    date = '';
                    
                }

                this.createAssetCategoryForm.controls['airStartDate'].setValue(date);
                this.createAssetCategoryForm.controls['airStartTime'].setValue(startTime);
            }
        
            if(result.data[0].airEndDate!= null){

                let endTime = result.data[0].airEndDate.split('T').pop();
                let date = result.data[0].airEndDate.replace(endTime, '');
                 date = date.replace('T', '');
             
                endTime = endTime.slice(0, -5);

                if(endTime =='0000-00-00 00' || endTime =='00:00:00' || endTime =='' ){

                    endTime = null;

                }

                if(date ==''){

                    date = null;
                    
                }
           
                this.createAssetCategoryForm.controls['airEndDate'].setValue(date);
                this.createAssetCategoryForm.controls['airEndTime'].setValue(endTime);
            }

                //(start) set dropdown by default empty
                if(result.data[0].sameForAllZone == null){
                    result.data[0].sameForAllZone = "";
                }
                if(result.data[0].sameForAllProductType == null){
                    result.data[0].sameForAllProductType = "";
                }
                //(end) set dropdown by default empty
                
                this.createAssetCategoryForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
                this.createAssetCategoryForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
                this.createAssetCategoryForm.controls['title'].setValue(result.data[0].title);
                this.createAssetCategoryForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
                this.createAssetCategoryForm.controls['isActive'].setValue(result.data[0].isActive.toString());
                this.createAssetCategoryForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
                this.createAssetCategoryForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
                this.createAssetCategoryForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
                this.createAssetCategoryForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);
                this.createAssetCategoryForm.controls['publisherState'].setValue(result.data[0].publisherState);
                this.createAssetCategoryForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
                    
                this.selectedItems=[];
                this.selectedProductlist=[];


                if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                    this.createAssetCategoryForm.controls['contentState'].setValue(['In-Progress']);
                }
                else{
                    this.createAssetCategoryForm.controls['contentState'].setValue(result.data[0].contentState);
                }

                this.productTypeMappingFilter.geoZoneId = [];
                if(result.data[0].results.geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){
                        let obj={
                        'id':result.data[0].results.geoZone[i].geoZoneId,
                        'itemName':result.data[0].results.geoZone[i].geoZoneName,
                        }
                        this.selectedItems.push(obj);  
                        this.productTypeMappingFilter.geoZoneId.push(obj.id);
                    }
                    this.createAssetCategoryForm.controls['mapGeoZone'].setValue(this.selectedItems);
                    this.startProduct = 0;
                    this.productScrollCount = 0 ;
                    this.getProductTypeInfo(this.productTypeMappingFilter);
                }

                let assetObj1=[];
                if( result.data[0].results.character.length>0){

                    for(let i = 0 ;i < result.data[0].results.character.length;i++){
                        let obj={
                        'id':result.data[0].results.character[i].characterId,
                        'itemName':result.data[0].results.character[i].characterFirstName,
                        }
                        assetObj1.push(obj);  
                        this.createAssetCategoryForm.controls['mapCharacter'].setValue(assetObj1);
                    }

               
               }

                let certificationArray=[];
                if( result.data[0].results.certification.length>0){

                    if(result.data[0].results.certification.length>0){

                        for(let i = 0 ;i < result.data[0].results.certification.length;i++){
                            let obj={
                            'id':result.data[0].results.certification[i].certificationId,
                            'itemName':result.data[0].results.certification[i].certificationName,
                            }
                            certificationArray.push(obj);  
                        }
                    }
                    this.createAssetCategoryForm.controls['mapCertification'].setValue(certificationArray);
                }

                this.menuCategoryMappingFilter.primaryNavigationId = [];
                if( result.data[0].results.primaryNavigation.length>0){

                    this.selectedprimaryNavigationList = [];

                    for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                        let obj={
                        'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                        'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                        }
                        this.selectedprimaryNavigationList.push(obj);
                        this.menuCategoryMappingFilter.primaryNavigationId.push(obj.id);
                        
                    this.mapProductTypeArrayTemp = this.selectedprimaryNavigationList;
                    }


                    this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
                    this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

                }

                if( result.data[0].results.menuCategory.length>0){

                    this.selectedmenuCategoryList = [];

                    if(result.data[0].results.menuCategory.length>0){

                        for(let i = 0 ;i < result.data[0].results.menuCategory.length;i++){
                            let obj={
                            'id':result.data[0].results.menuCategory[i].menuCategoryId,
                            'itemName':result.data[0].results.menuCategory[i].menuCategoryName,
                            }
                            this.selectedmenuCategoryList.push(obj); 
                            this.assetGroupMappingFilter.menuCategoryId.push(obj.id);
                        }
                    }
                    this.createAssetCategoryForm.controls['mapMenuCategory'].setValue(this.selectedmenuCategoryList);
                    this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                }
               
                let assetObj=[];

                
                 assetObj=[];
                if( result.data[0].results.character.length>0){

                    for(let i = 0 ;i < result.data[0].results.character.length;i++){
                        let obj={
                        'id':result.data[0].results.character[i].characterId,
                        'itemName':result.data[0].results.character[i].characterFirstName,
                        }
                        assetObj.push(obj);  
                        this.createAssetCategoryForm.controls['mapCharacter'].setValue(assetObj);
                    }

               
               }

                assetObj=[];
                if( result.data[0].results.library.length>0){

                    for(let i = 0 ;i < result.data[0].results.library.length;i++){

                        let obj={
                        'id':result.data[0].results.library[i].libraryId,
                        'itemName':result.data[0].results.library[i].libraryName,
                        }

                        assetObj.push(obj);  
                    }

                this.createAssetCategoryForm.controls['mapLibrary'].setValue(assetObj);
             }

                assetObj=[];
                if( result.data[0].results.sponsor.length>0){

                    for(let i = 0 ;i < result.data[0].results.sponsor.length;i++){
                        let obj={
                        'id':result.data[0].results.sponsor[i].sponsorId,
                        'itemName':result.data[0].results.sponsor[i].sponsorName,
                        }
                        assetObj.push(obj);  
                    }

                this.createAssetCategoryForm.controls['mapSponsor'].setValue(assetObj);
             }
               
                assetObj=[];
                if( result.data[0].results.language.length>0){

                        for(let i = 0 ;i < result.data[0].results.language.length;i++){
                            
                            let obj={
                            'id':result.data[0].results.language[i].languageId,
                            'itemName':result.data[0].results.language[i].languageName,
                            }

                            assetObj.push(obj);  

                        }
                    
                    this.createAssetCategoryForm.controls['mapLanguage'].setValue(assetObj);
                }
                
                assetObj=[];

                setTimeout (() => {

                    if( result.data[0].results.productType.length>0){

                        let assetObjS = []

                        for(let i = 0 ;i < result.data[0].results.productType.length;i++){
                            
                            let obj={
                            'id':result.data[0].results.productType[i].productTypeId,
                            'itemName':result.data[0].results.productType[i].productTypeName,
                            }
                            assetObjS.push(obj);
                            this.selectedProductlist =  assetObjS;
                            this.mapProductTypeArrayTemp = this.selectedProductlist;
                            this.primaryNavigationMappingFilter.productTypeId.push(obj.id);
                            this.createAssetCategoryForm.controls['mapProductType'].setValue(assetObjS);
    
                            console.log("mapProductType>>",this.createAssetCategoryForm.value.mapProductType);
    
                        }
                        
                      
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                    }

                    

                    if( result.data[0].results.assetGroup.length>0){

                        let assetObj1 = [];
                        this.selectedAssetGroupList = [];

                        for(let i = 0 ;i < result.data[0].results.assetGroup.length;i++){
                            
                            let obj={
                            'id':result.data[0].results.assetGroup[i].assetGroupId,
                            'itemName':result.data[0].results.assetGroup[i].assetGroupName,
                            }

                            assetObj1.push(obj); 
                            this.selectedAssetGroupList =  assetObj1;
                            this.createAssetCategoryForm.controls['mapAssetGroup'].setValue(assetObj1);

                        }
                    
                }
                // this.loader=false;
                this.commonService.changeloader(false);
                },300)
               

                assetObj=[];
                if(result.data[0].results.classification.length>0){

                    for(let i = 0 ;i < result.data[0].results.classification.length;i++){

                        let obj={
                        'id':result.data[0].results.classification[i].classificationId,
                        'itemName':result.data[0].results.classification[i].classificationName,
                        }
                        assetObj.push(obj);  
                    }
                    
                    this.createAssetCategoryForm.controls['mapClassification'].setValue(assetObj);
                }

                let arrayObj=[]
                if(result.data[0].results.genre.length>0){

                    for(let i = 0 ;i < result.data[0].results.genre.length;i++){

                        let obj={
                        'id':result.data[0].results.genre[i].genreId,
                        'itemName':result.data[0].results.genre[i].genreName,
                        }
                        arrayObj.push(obj);  

                    }
                    
                    this.createAssetCategoryForm.controls['mapGenre'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.review.length>0){

                    for(let i = 0 ;i < result.data[0].results.review.length;i++){

                        let obj={
                        'id':result.data[0].results.review[i].reviewId,
                        'itemName':result.data[0].results.review[i].reviewFirstName,
                        }
                        arrayObj.push(obj);  

                    }
                    
                    this.createAssetCategoryForm.controls['mapReview'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.cast.length>0){

                    for(let i = 0 ;i < result.data[0].results.cast.length;i++){

                        let obj={

                        'id':result.data[0].results.cast[i].castId,
                        'itemName':result.data[0].results.cast[i].castFirstName,
                        
                        }
                        arrayObj.push(obj);  
                    }
                    
                    this.createAssetCategoryForm.controls['mapCast'].setValue(arrayObj);
                }

                this.img=[];

                if(result.data[0].results.productDeviceTypeConfigure.length>0){

                        this.multipleDeviceForm = this.formBuilder.group({
                            devices: this.formBuilder.array([])
                        });


                //device type validation code start
                    if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                        this.deviceTypeFull = true;
                    }
                  //device type validation code end
        
                        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                        
                        for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                            this.img.push({
                                horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                                verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                            })

                            let objH = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "horizontalFileName": "",
                                "horizontalFilePath": "",
                                "horizontalFileType": "",
                                'horizontalFile':false
                            }
        
                            let objV = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "verticalFileName":"",
                                "verticalFilePath": "",
                                "verticalFileType":"",
                                'verticalFile':false,
                                
                            }
        
                            this.uploadImgFilePathHorizontal.push(objH);
                            this.uploadImgFilePathVertical.push(objV);


                            console.log("img>>",this.img);

                            this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                            this.createAssetCategoryForm.controls['contentType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].contentType);
                            this.createAssetCategoryForm.controls['sorting'].setValue(result.data[0].results.productDeviceTypeConfigure[i].sorting);
                            this.createAssetCategoryForm.controls['orderBy'].setValue(result.data[0].results.productDeviceTypeConfigure[i].orderBy);
                        
            
                        }
                }
                
        });
    }

    getGlobalclassification(obj){
  
        this.commonService.getglobalclassification(obj,0,1000).subscribe((result:any)=>{
                
            this.mapClassificationList = [];
            
            if(result.data.data.length > 0){       
                for (let menuCategoryList of result.data.data) {
    
                    if(menuCategoryList.classificationId!=''){
                    
                        this.mapClassificationList.push(
                        {
                            'id': menuCategoryList.classificationId,
                            'itemName': menuCategoryList.classificationName
                        });
                    } 
                }
            } 
    
        });
    
    }

    getGeoZoneInfo(obj){

        // if(this.isSearch)
        //  this.commonService.changeloader(true);

            this.productConfigurationService.getAllGeoZone(obj,this.start,10)
            .subscribe((result:any)=>{

            //    this.dropdownList = [];
            this.searchDecouncer$.next(null);

                this.commonService.changeloader(false);

                if(result.data.data.length > 0){
                        
                    this.dropdownList = result.data.data;

                    for (let geoZoneObj of this.dropdownList) {

                        this.geoZoneList.push(
                        {
                            'id': geoZoneObj.geoZoneId,
                            'itemName': geoZoneObj.geoZoneName
                        });

                    }

                    this.geoZoneList = this.removeDuplicates(this.geoZoneList, "id");

                }
        
            }) 
    }

    fetchMoreGetClassification(event){

        if ((event.endIndex >= this.mapClassificationList.length - 3)&& (this.mapClassificationList.length >= 8)) {


                this.startClassification = this.startClassification + 10;

                this.getGlobalclassification(this.classificationFilter);
            
   
        }

    }

    fetchMoreGetAssetGroup(event){

        if ( ((event.endIndex >= this.assetGroupList.length - 2)) && (this.assetGroupList.length >= 9)) {

                this.startGroup = this.startGroup + 10;

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);  
   
        }
    }

    fetchMoreGetGeoZone(event){

    if ((event.endIndex >= this.geoZoneList.length - 1) && (this.geoZoneList.length>=9)) {

         this.start = this.start + 10;

         this.getGeoZoneInfo(this.geoZoneObj);
            
        }

    }
    //////////////////

    mapProductTypeArrayTemp:any =[];

    getProductTypeInfo(filterObj){

            this.commonService.getGlobalProductType(filterObj,this.startProduct,10)
            .subscribe((result:any)=>{

                this.ProducTypeDropdownList = [];
                this.commonService.changeloader(false);
                // let mapProductTypeArrayTemp = [];
               // this.mapProductTypeArrayTemp = [];
                // this.producTypeList = [];
                this.searchDecouncer$.next(null);

                let mapProductTypeArray = this.createAssetCategoryForm.value.mapProductType;
    
                if(result.data.data.length > 0){
    
                    
                    this.productInfoObj.pageCount = result.data.data.pageCount;
    
                    if(this.isSearch == true){
    
                        this.isSearch = false;
                        this.producTypeList = [];
    
                    }
    
                    this.ProducTypeDropdownList = result.data.data;
                    let asyc = result.data.data.length;
    
                    for (let ProducTypeLists of this.ProducTypeDropdownList) {
    
                        asyc--;
    
                        mapProductTypeArray.forEach(element => {
    
                            if(element.id == ProducTypeLists.productTypeId) {
    
                                this.mapProductTypeArrayTemp.push(element);
                                this.primaryNavigationMappingFilter.productTypeId.push(element.id);
                                this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp, "id");
    
                            }
                        });
    
    
                        if(ProducTypeLists.productTypeName!=''){
    
                            this.producTypeList.push(
                                {
                                    'id': ProducTypeLists.productTypeId,
                                    'itemName': ProducTypeLists.productTypeName
                                });
    
                        }
    
    
                        
                        this.producTypeList = this.removeDuplicates(this.producTypeList, "id");
    
    
                    }
    
                   if(asyc == 0){
    
    
                        // for (let ProducTypeLists of this.producTypeList) {
    
                        //     mapProductTypeArray.forEach(element => {
    
                        //         if(element.id == ProducTypeLists.id) {
                        //             mapProductTypeArrayTemp.push(element);
                        //             this.primaryNavigationMappingFilter.productTypeId.push(element.id);
                        //         }
                        //         this.createAssetGroupForm.controls['mapProductType'].setValue(mapProductTypeArrayTemp);
                        
                        //     });
                        // }
    
                        this.createAssetCategoryForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);
    
                    
                       if(this.mapProductTypeArrayTemp.length == 0){
    
                        this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.primaryNavigationList = [];
                        
                        this.createAssetCategoryForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                       }else {
    
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
    
                       }
                
                   }
                    
                }else{
    
                    if( this.isSearch == false){
    
                            //    this.createAssetCategoryForm.controls['mapProductType'].setValue([]);
                            //    this.producTypeList = [];
    
                            //     this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue([]);
                            //     this.primaryNavigationList = [];
                                
                            //     this.createAssetCategoryForm.controls['mapMenuCategory'].setValue([]);
                            //     this.menuCategoryList = [];
                    }
                    
                }
            });
        

    }

    fetchMoreGetProductType(event){

        if(this.productInfoObj.pageCount == undefined){

            this.productInfoObj.pageCount = 0;

        }

        if ((event.endIndex == this.producTypeList.length - 1) && (this.producTypeList.length>=8)) {

                this.startProduct = this.startProduct + 10;
                console.log("in");
                
                this.getProductTypeInfo(this.productTypeMappingFilter);     
           
         }

    }
    ///////////////

    mapPrimaryNavigationArrayTemp=[];

    getNavigationInfo(filterObj){


        // if(this.isSearch == true)
        //    this.commonService.changeloader(true);

        this.commonService.getGlobalPrimaryNavigation(filterObj,this.startPrimary,10).subscribe((result:any)=>{
            
            let mapPrimaryNavigationArray = this.createAssetCategoryForm.value.mapPrimaryNavigation;
            let asyc = result.data.data.length;
            this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){  

                if(this.isSearch == true){

                    this.loader =false;
    
                    this.isSearch = false;
                    
                    this.primaryNavigationList = [];
                }

              //  this.menuCategoryMappingFilter.primaryNavigationId = [];

                for (let primaryNavigationLists of result.data.data) {

                    asyc --;
                    mapPrimaryNavigationArray.forEach(element => {
                        if(element.id == primaryNavigationLists.primaryNavigationId) {

                            this.mapPrimaryNavigationArrayTemp.push(element);
                            this.menuCategoryMappingFilter.primaryNavigationId.push(element.id);
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp, "id");


                        }
                    });

                    if(primaryNavigationLists.primanyNavigationName!=''){

                       this.primaryNavigationList.push(
                        {
                            'id': primaryNavigationLists.primaryNavigationId,
                            'itemName': primaryNavigationLists.primanyNavigationName
                        });
                        
                    } 

                    this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList, "id");
                                    
                }


                 if(asyc == 0){ 

                    // this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);
                    
                    if(this.mapPrimaryNavigationArrayTemp.length == 0){

                        this.createAssetCategoryForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                       }else {
    
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    
                    }
                   
                }
               
            }else {
            //    this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
            //    this.primaryNavigationList= [];
                
            //     this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            //     this.menuCategoryList = [];
            } 
        })
    }

    fetchMoreGetPrimary(event){

        console.log("event=list-count",event.endIndex , this.primaryNavigationList.length ,this.SponsoredScrollCount)

        if ( ((event.endIndex >= this.primaryNavigationList.length - 2)) && (this.primaryNavigationList.length >=10)) {

                this.startPrimary = this.startPrimary + 10;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);  
   
        }

    }
    //////////////

    mapMenuCategoryArrayTemp:any=[];

    getGlobalMenuCategory(filterObj){

      //  this.commonService.changeloader(true);
        this.commonService.getGlobalMenuCategory(filterObj,this.startCategory,10).subscribe((result:any)=>{
        
           // let mapMenuCategoryArrayTemp = [];
          //  this.menuCategoryList = [];
            this.commonService.changeloader(false);

            let mapMenuCategoryArray = this.createAssetCategoryForm.value.mapMenuCategory;
            
            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){  
                
                    if(this.isSearch == true){

                        this.startCategory = 0;
                        this.menuCategoryList = [];
                        this.isSearch = false;

                    }

                    for (let menuCategoryList of result.data.data) {

                        mapMenuCategoryArray.forEach(element => {

                            if(element.id == menuCategoryList.menuCategoryId) {
                                this.mapMenuCategoryArrayTemp.push(element);
                                this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp, "id");
                    
                            }
                        });

                        if(menuCategoryList.menuCategoryName!=''){
                        
                            this.menuCategoryList.push(
                            {
                                'id': menuCategoryList.menuCategoryId,
                                'itemName': menuCategoryList.menuCategoryName
                            });
                        } 

                        this.menuCategoryList = this.removeDuplicates(this.menuCategoryList, "id");
    
                    }
        
                this.createAssetCategoryForm.controls['mapMenuCategory'].setValue(this.mapMenuCategoryArrayTemp);

            } 

        });
    }

    fetchMoreGetCategory(event){

        if ( ((event.endIndex >= this.menuCategoryList.length - 2)) && (this.menuCategoryList.length >= 9)) {

                this.startCategory = this.startCategory + 10;

                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);  
   
        }
    }
    /////////////
    languageFilter:any={};

    getLanguageInfo(){

        this.commonService.getGlobalLanguage(this.languageFilter, this.startLanguage,10).subscribe((result:any)=>{
            
            // this.mapLanguageList = [];
            this.searchDecouncer$.next(null);

            
            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapLanguageList = [];

                }

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.languageId!=''){
                    
                        this.mapLanguageList.push(
                        {
                            'id': menuCategoryList.languageId,
                            'itemName': menuCategoryList.languageName
                        });
                    } 
                }

                this.mapLanguageList = this.removeDuplicates(this.mapLanguageList, "id");

            } 

        });
    }
    
    fetchMoreGetLanguage(event){
        
        if ((event.endIndex == this.mapLanguageList.length - 1)&& (this.mapLanguageList.length >= 8)) {

            //  this.characterScrollCount ++;  

            // if(this.characterScrollCount <= this.charcterInfoObj.pageCount)   {

                this.startLanguage = this.startLanguage + 10;
                this.getLanguageInfo();
            // }       
   
        }
    }
    /////////////
    sponsoredFiter={};
    startSponsored = 0;
    getSponsoreInfo(obj){

        // this.sponsoredFiter = obj;

        this.commonService.getGlobalSponsore(this.sponsoredFiter,this.startSponsored,10).subscribe((result:any)=>{
            
         //   this.mapSponsoreList = [];
         this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){  

                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapSponsoreList = [];
                }

                // this.SponsoredInfoObj.pageCount = result.data.data.pageCount ;
                    
                for (let mapSponsoreList of result.data.data) { 

                        this.mapSponsoreList.push(
                        {
                            'id': mapSponsoreList.sponsorId,
                            'itemName': mapSponsoreList.sponsorName
                        });
                }

                this.mapSponsoreList = this.removeDuplicates(this.mapSponsoreList, "id");

            } 

        });

    }
     
    fetchMoreGetSponsore(event){
        
        if ( ((event.endIndex == this.mapSponsoreList.length - 1)) && (this.mapSponsoreList.length >= 7)) {


                this.startSponsored = this.startSponsored + 10;
                this.getSponsoreInfo(this.sponsoredFiter);
   
        }
    }
    ////////////////

    genreFilter:any={};
    startGenre = 0;

    getGenreInfo(){

        this.commonService.getGlobalGenre(this.genreFilter,this.startGenre,10).subscribe((result:any)=>{  

        //    this.mapGenreList = [];

        this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){   
                      
                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapGenreList = [];

                }
 
                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.productTypeName!=''){
                    this.mapGenreList.push(
                        {
                            'id': ProducTypeLists.genreId,
                            'itemName': ProducTypeLists.genreName
                        });
                    }    
                }

                this.mapGenreList = this.removeDuplicates(this.mapGenreList, "id");

            } 

        });
    }

    fetchMoreGetGenre(event){
        
        if ( ((event.endIndex == this.mapGenreList.length - 1)) && (this.mapGenreList.length >= 7)) {

                this.startGenre = this.startGenre + 10;

                this.getGenreInfo();
            // }       
   
        }
    }
    ////////////

    certificateFilter:any={};
    startCertificate = 0;

    getCertificate(obj){

        this.commonService.getGlobalCertification(this.certificateFilter,this.startCertificate,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){      
                
                    if(this.isSearch == true){
                        
                        this.isSearch = false;
                        this.mapCertificatList = [];

                    }
                    for (let ProducTypeLists of result.data.data) {

                        if(ProducTypeLists.productTypeName!=''){
                        this.mapCertificatList.push(
                            {
                                'id': ProducTypeLists.certificationId,
                                'itemName': ProducTypeLists.certificationName
                            });
                        }    
                    }
                    this.mapCertificatList = this.removeDuplicates(this.mapCertificatList, "id");

            } 

        });
    }

    fetchMoreGetCertificate(event){
    
        if ( ((event.endIndex == this.mapClassificationList.length - 1)) && (this.mapClassificationList.length >= 7)) {

                this.startClassification = this.startClassification + 10;

                this.getCertificate({});      
   
        }

    }
    /////////////
    libraryFilter:any={};
    startLibrary:any=0;

    getLibrary(){
        
        this.commonService.getglobalLibrary(this.libraryFilter,this.startLibrary,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){
                        
                    this.isSearch = false;
                    this.mapLibraryList = [];

                }

                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.libraryId!=''){
                    this.mapLibraryList.push(
                        {
                            'id': ProducTypeLists.libraryId,
                            'itemName': ProducTypeLists.libraryName
                        });
                    }    
                }

                this.mapLibraryList = this.removeDuplicates(this.mapLibraryList, "id");

            } 
        });

    }

    fetchMoreGetlibrary(event){

        if ( ((event.endIndex == this.mapLibraryList.length - 1)) && (this.mapLibraryList.length >= 9)) {

                // this.reviewScrollCount++;  

                this.startLibrary = this.startLibrary + 10;

                this.getLibrary();
   
        }

    }
    ////////////////

    // startReview = 0;

    getGlobalReviewInfo(obj){
        
        this.commonService.getGlobalReview(this.reviewFilter,this.startReview,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapReviewList = [];

                }
               
                for (let ProducTypeLists of result.data.data) {

                    this.mapReviewList.push(
                        {
                            'id': ProducTypeLists.reviewId,
                            'itemName': ProducTypeLists.reviewFirstName
                        });
                       
                }  
                
                this.mapReviewList = this.removeDuplicates(this.mapReviewList, "id");

            } 
        });
    }

    fetchMoreGetReviews(event){

        if ( ((event.endIndex == this.mapReviewList.length - 1)) && (this.mapReviewList.length >= 7)) {


                this.startReview = this.startReview + 10;

                this.getGlobalReviewInfo({});
   
        }

    }
    /////////////////

    
    getGlobalCharacter(){

        this.commonService.getGlobalCharacter(this.characterFilter,this.startCharacter,10).subscribe((result:any)=>{

           // this.mapCharacterList = [];
           this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){   

                if(this.isSearch == true){

                    this.isSearch = false;
                    this.mapCharacterList = [];
                }
                
               
                for (let CharacterLists of result.data.data) {

                    // if(CharacterLists.characterFirstName!=''){
                    this.mapCharacterList.push(
                        {
                            'id': CharacterLists.characterId,
                            'itemName': CharacterLists.characterFirstName
                        });
                    // }    
                }  
                
                this.mapCharacterList = this.removeDuplicates(this.mapCharacterList, "id");

            } 
        });
    }

    fetchMoreGetCharacter(event){

        if ( ((event.endIndex == this.mapCharacterList.length - 1)) && (this.mapCharacterList.length >= 7)) {

             this.startCharacter = this.startCharacter + 10;

             this.getGlobalCharacter();

      }
    }

    classificationFilter:any={};
    activeSearchBox:any='';
    SponsoredInfoObj:any={};
    reviewFilter:any={};
    characterFilter:any={};
    startCast: any = 0;


    eventValue:any='fr';

    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
        return;

        this.activeSearchBox = info;
        this.eventValue = event.target.value;

        var obj = {};
        this.isSearch = true;
        
        
        if(info == 'cast'){
            

            if(event.target.value!=''){

                obj = { 'castFirstName': event.target.value };
                this.startCast = 0;
                this.mapCastList = [];      
                this.castFilter = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.castFilter = obj ;
                this.mapCastList = [];      
                this.startCast = 0;

                this.searchDecouncer$.next(event.target.value);

            }
        }
        
        if(info == 'character'){
            

            if(event.target.value!=''){

                obj = { 'characterFirstName': event.target.value };
                this.startCharacter = 0;
                this.mapCharacterList = [];      
                this.characterFilter = {  } ;
                this.characterFilter = obj;
                // this.characterFilter = {};
                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.characterFilter = obj ;
                this.mapCharacterList = [];      
                this.startCharacter = 0;
                this.searchDecouncer$.next(event.target.value);

            }
  
        }  
    

        if(info == 'classification'){
            

            if(event.target.value!=''){

                this.classificationFilter =  obj = { 'classificationName': event.target.value };
                // this.classificationFilter = obj = { };
                this.mapClassificationList=[];

    
            }else {
                this.mapClassificationList=[];
                this.classificationFilter =   obj = { } 
            }

            this.startClassification = 0;

            this.searchDecouncer$.next(event.target.value);

            // setTimeout(()=>{

            //     this.getGlobalclassification(obj);

            //  },200)
            

        }

        if(info == 'review'){

            if(event.target.value!=''){

               this.reviewFilter = obj = { 'reviewFirstName': event.target.value };
            //    this.reviewFilter = obj = { } 
            this.mapReviewList = [];

    
            }else {

                this.mapReviewList = [];
                this.reviewFilter = obj = { } 
            }

            this.startReview = 0;
            this.reviewScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

            // setTimeout(()=>{

            //     this.getGlobalReviewInfo(obj);

            //  },200)
            

        }
        
        if(info == 'genre'){

            if(event.target.value!=''){

               this.genreFilter = obj = { 'genreName': event.target.value };
            //    this.genreFilter = obj = { } 

            this.mapGenreList= [];

    
            }else {

                this.genreFilter = obj = { };
                this.mapGenreList= [];
            }

            this.startGenre = 0;
            this.reviewScrollCount = 0;
            
            // setTimeout(()=>{

            //     this.getGenreInfo();

            //  },200)
            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'sponsore'){


            if(event.target.value!=''){

                this.sponsoredFiter = obj = { 'sponsorName': event.target.value };
                this.mapSponsoreList = [];
    
            }else {

                this.sponsoredFiter =  obj = { } 
            }

            this.startSponsored = 0;
            this.SponsoredScrollCount = 0;

            this.mapSponsoreList = [];


            // setTimeout(()=>{

            //     this.getSponsoreInfo(obj);

            //  },200)

            this.searchDecouncer$.next(event.target.value);

            

        }

        if(info == 'language'){

            if(event.target.value!=''){

                this.languageFilter =  obj = { 'languageName': event.target.value };
                this
                this.mapLibraryList=[];
                // this.languageFilter =  obj = { } 


            }else {
                this.mapLibraryList=[];
                this.languageFilter =  obj = { } 
            }

            this.startLanguage = 0;
            this.SponsoredScrollCount = 0;
            // setTimeout(()=>{

            //     this.getLanguageInfo();

            //  },200)

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'library'){

            if(event.target.value!=''){

                 this.libraryFilter =  obj = { 'libraryName': event.target.value };
                 this.mapLibraryList = [];
                // this.libraryFilter =  obj = { } 

    
            }else {

                this.libraryFilter =  obj = { };
                this.mapLibraryList = [];
            }

            this.startClassification = 0;
            this.SponsoredScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'certification'){

            if(event.target.value!=''){

                obj = { 'certificationName': event.target.value };
                this.mapCertificatList=[];
                this.certificateFilter = obj;
                this.startCertificate = 0;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                this.certificateFilter = obj = { };
            }

            this.startCertificate = 0;
            this.certificateFilter = obj ;

            this.mapCertificatList=[];
            // setTimeout(()=>{

            //     this.getCertificate(obj);   

            //  },200)

            this.searchDecouncer$.next(event.target.value);
        }  
        
        if(info == 'geoZone'){
            

            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                this.geoZoneList = [];      
                this.geoZoneObj = obj ;
                // setTimeout(()=>{

                //    this.getGeoZoneInfo(this.geoZoneObj); 

                // },200)
                this.searchDecouncer$.next(event.target.value);

        
            }else {

                obj = { };
                this.geoZoneObj = obj ;
                this.geoZoneList = [];      
                this.start = 0;
                // setTimeout(()=>{

                //    this.getGeoZoneInfo(this.geoZoneObj); 

                // },200)
                this.searchDecouncer$.next(event.target.value);



            }
  
        }  

        if(info == 'productType'){

            console.log("producttype",event);

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                this.producTypeList = [];
                this.startProduct = 0;
                this.productScrollCount = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                delete this.productTypeMappingFilter.productTypeName;
                this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);

            }

            
        }

        if(info == 'navigation'){

            if(event.target.value!=''){

                this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

                delete this.primaryNavigationMappingFilter.primanyNavigationName;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                this.searchDecouncer$.next(event.target.value);


            }

        } 
 
        if(info == 'category'){

            if(event.target.value!=''){

                   this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                   this.menuCategoryList = [];
                   this.startCategory = 0;
                   //this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                   this.searchDecouncer$.next(event.target.value);

            }else {

                delete this.menuCategoryMappingFilter.menuCategoryName;
                this.menuCategoryList = [];
                this.startCategory = 0;
                // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                this.searchDecouncer$.next(event.target.value);


            }

        }
        
        if(info == 'assetGroup'){

            if(event.target.value!=''){

                   this.assetGroupMappingFilter.assetGroupName = event.target.value;
                   this.assetGroupList = [];
                   this.startGroup = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.assetGroupMappingFilter.assetGroupName ='';
                this.assetGroupList = [];
                this.startGroup = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }      

    }

    onOpen(info){

        this.activeSearchBox = info;
        
     
             if(this.activeSearchBox == 'assetGroup'){
     
                 this.assetGroupList = [];
                 this.startGroup = 0;
                  this.assetGroupMappingFilter.assetCategoryName = undefined;
     
                 this.getGlobalAssetGroup(this.assetGroupMappingFilter);
     
             }
     
             if(this.activeSearchBox == 'character'){
     
                 this.mapCharacterList=[];
                 this.startCharacter = 0;
                 this.characterFilter={};
                 this.getGlobalCharacter();
     
             }
     
             if(this.activeSearchBox == 'cast'){
     
                 this.mapCastList=[];
                 this.startCast = 0;
                 this.castFilter={};
                 this.getCastInfo();
     
             }
     
             if(this.activeSearchBox == 'classification'){
     
                     this.classificationFilter={};
                     this.mapClassificationList=[];
                     this.startClassification = 0;
                     this.getGlobalclassification(this.classificationFilter);
     
             }
     
             if(this.activeSearchBox == 'review'){
     
                     this.reviewFilter={};
                     this.mapReviewList = [];
                     this.startReview = 0;
                     this.getGlobalReviewInfo(this.reviewFilter);
     
             }
     
             if(this.activeSearchBox =='genre'){
     
                     this.genreFilter = {};
                     this.startGenre = 0;
                     this.mapGenreList = [];
                     this.getGenreInfo();    
     
             }
     
             if(this.activeSearchBox == 'sponsore'){
     
                    this.sponsoredFiter = {};
                    this.mapSponsoreList = [];
                    this.startSponsored = 0;
                     this.getSponsoreInfo(this.sponsoredFiter);   
     
             }
     
             if(this.activeSearchBox == 'language'){
     
                 
                 this.languageFilter ={};
                 this.mapLanguageList = [];
                 this.startLanguage = 0;
                 this.getLanguageInfo();

             }
     
             if(this.activeSearchBox == 'library'){
      
                    this.libraryFilter = {};
                    this.mapLibraryList = [];
                    this.startLibrary = 0;
                     this.getLibrary();
             }
     
             if(this.activeSearchBox == 'certification'){
     
                 this.mapCertificatList = [];
                 this.certificateFilter = {};
                 this.startCertificate = 0;
     
                 this.getCertificate(this.certificateFilter);   
             }  
     
             if(this.activeSearchBox == 'geoZone'){
     
                 this.geoZoneObj = {};
                 this.geoZoneList = [];
                 this.start = 0;
                 this.getGeoZoneInfo(this.geoZoneObj);       
     
             }  
     
             if(this.activeSearchBox == 'productType'){
     
                   this.productTypeMappingFilter.productTypeName= undefined;
                 this.producTypeList = [];
                 this.startProduct = 0;
                 this.getProductTypeInfo(this.productTypeMappingFilter);
                 
             }
     
             if(this.activeSearchBox == 'navigation'){
     
                   this.primaryNavigationMappingFilter.primaryNavigationName= undefined;
                   this.primaryNavigationList=[];
                   this.startPrimary = 0;
                   this.getNavigationInfo(this.primaryNavigationMappingFilter);     
     
             } 
     
             if(this.activeSearchBox == 'category'){
     
                 this.menuCategoryMappingFilter.menuCategoryName= undefined;
                 this.menuCategoryList = [];
                 this.startCategory = 0;

                 this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
     
             }   
     
     
     }

    startGroup = 0;

    private setupSearchDebouncer(): void {
        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            
            if(this.activeSearchBox == 'character'){

                this.getGlobalCharacter();

            }

            if(this.activeSearchBox == 'cast'){

                this.getCastInfo();

            }

            if(this.activeSearchBox == 'classification'){
            
                    this.getGlobalclassification(this.classificationFilter);
    
            }
    
            if(this.activeSearchBox == 'review'){
    
                    this.getGlobalReviewInfo(this.reviewFilter);
   
            }
            
            if(this.activeSearchBox =='genre'){
    
                    this.getGenreInfo();    
    
            }
    
            if(this.activeSearchBox == 'sponsore'){
    
                    this.getSponsoreInfo(this.SponsoredInfoObj);   
    
            }
    
            if(this.activeSearchBox == 'language'){
    
                    this.getLanguageInfo();
    
            }
    
            if(this.activeSearchBox == 'library'){
    
                    this.getLibrary();
            }
    
            if(this.activeSearchBox == 'certification'){
    
    
                 this.getCertificate(this.certificateFilter);   
            }  
            
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo(this.geoZoneObj);       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }
    
            if(this.activeSearchBox == 'navigation'){
    
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     
    
            } 
     
            if(this.activeSearchBox == 'category'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }  
            
            if(this.activeSearchBox == 'assetGroup'){
    
                this.getGlobalAssetGroup(this.assetGroupMappingFilter);
    
            }   

        });
    }
    
    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }

    fetchMoreGetLibrary(event){
        
        if ((event.endIndex == this.mapLibraryList.length - 1)&& (this.mapLibraryList.length >= 8)) {

            //  this.characterScrollCount ++;  

            // if(this.characterScrollCount <= this.charcterInfoObj.pageCount)   {

                this.startLibrary = this.startLibrary + 10;
                this.getLibrary();
            // }       
   
        }
    }

    //pravin code start
    // getGeoZoneInfo(obj){
    
    //     this.commonService.changeloader(true);

    //     this.productConfigurationService.getAllGeoZone(obj,this.start,100000)
    //     .subscribe((result:any)=>{

    //         this.commonService.changeloader(false);

    //         this.dropdownList = [];
    //         this.geoZoneList = [];


    //         // if(this.isSearch == true){

    //         //     this.isSearch = false;

    //         // }

    //         if(result.data.data.length > 0){
                    
    //             this.dropdownList = result.data.data;

    //             for (let geoZoneObj of this.dropdownList) {

    //                 this.geoZoneList.push(
    //                 {
    //                     'id': geoZoneObj.geoZoneId,
    //                     'itemName': geoZoneObj.geoZoneName
    //                 });

    //             }
    //         }
    
    //     }) 
    // }
    //pravin code end
    // fetchMoreGetGeoZone(event){

    //     if ((event.endIndex >= this.geoZoneList.length - 1) && (this.geoZoneList.length>=9)) {

    //         this.start = this.start + 10;

    //         this.getGeoZoneInfo(this.geoZoneObj);
            
    //     }

    // }

//    onSearch(event, info){
      
//     var obj = {};

//     this.isSearch = true;

//     console.log("info",info);

//     if(info == 'character'){

//         if(event.target.value!=''){

//             obj = { 'keyword': event.target.value };

//         }else {

//                 obj = { } 
//         }

//         this.startCharacter = 0;

//         this.characterScrollCount = 0;
        
//         this.getGlobalCharacter();

//     }  
             
//     if(info == 'classification'){

//         if(event.target.value!=''){

//             obj = { 'keyword': event.target.value };

//         }else {

//                 obj = { } 
//         }

//         this.startClassification = 0;
        
//         this.getGlobalclassification(obj);

//     }

//     if(info == 'review'){

//         if(event.target.value!=''){

//             obj = { 'keyword': event.target.value };

//         }else {

//                 obj = { } 
//         }

//         this.startReview = 0;
//         this.reviewScrollCount = 0;
//         this.startClassification = 0;
//         this.isSearch = true;
//         this.SponsoredScrollCount = 0;
//         this.startClassification = 0;
//         this.characterScrollCount = 0;


        
//         this.getGlobalReviewInfo(obj);

//     }

//     if(info == 'sponsored'){

//         if(event.target.value!=''){

//             obj = { 'keyword': event.target.value };

//         }else {

//                 obj = { } 
//         }

//         this.startClassification = 0;
//         this.SponsoredScrollCount = 0;
        
//         this.getSponsoreInfo(obj);

//     }
// //////////////////////////////////////////////
    
//     if(info == 'geoZone'){

//         if(event.target.value!=''){

//             obj = { 'keyword': event.target.value };
    
//         }else {

//                 obj = { };
//         }

//         this.start = 0;
//         this.geoZoneObj = obj ;
//         this.getGeoZoneInfo(this.geoZoneObj);   
//     }  

//     if(info == 'productType'){

//         if(event.target.value!=''){

//             this.productTypeMappingFilter.keyword = event.target.value;

//         }else {

//             delete this.productTypeMappingFilter.keyword;
//         //  this.ProducTypeList = [];

//         }

        
//         this.startProduct = 0;
//         this.productScrollCount = 0;

//         this.getProductTypeInfo(this.productTypeMappingFilter);
        
//     } 

//     if(info == 'navigation'){

//         if(event.target.value!=''){

//                this.primaryNavigationMappingFilter.keyword = event.target.value;

//         }else {

//             delete this.primaryNavigationMappingFilter.keyword;
//         //  this.ProducTypeList = [];

//         }

//         this.startPrimary = 0;
//         this.primaryScrollCount = 0;

//         this.getNavigationInfo(this.primaryNavigationMappingFilter);

//     } 

//     if(info == 'category'){

//         if(event.target.value!=''){

//                this.menuCategoryMappingFilter.keyword = event.target.value;

//         }else {

//             delete this.menuCategoryMappingFilter.keyword;
//         //  this.ProducTypeList = [];

//         }

//         this.startCategory = 0;

//         this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

//     }   

//    }


    // getProductTypeInfo(filterObj){

    //     console.log("filterObj= ",filterObj);

    //     this.commonService.changeloader(true);

    //     this.commonService.getGlobalProductType(filterObj,this.startProduct,100000).subscribe((result:any)=>{

    //         console.log("getProductTypeInfo Result= ",result.data.data);

    //         this.commonService.changeloader(false);

    //         this.ProducTypeDropdownList = [];
    //         this.producTypeList = [];

    //         let mapProductTypeArrayTemp = [];
    //         let mapProductTypeArray = this.createAssetCategoryForm.value.mapProductType;

    //         console.log("mapProductTypeArray = ",mapProductTypeArray);

    //         if(result.data.data.length > 0){ 

    //             // if(this.isSearch == true){

    //             //     this.producTypeList = [];
    //             //     this.productScrollCount = 0;
    //             //     this.isSearch = false;
    
    //             // }
                
    //             this.productInfoObj.pageCount = result.data.data.pageCount;

    //             this.ProducTypeDropdownList = result.data.data;
    //             for (let ProducTypeLists of this.ProducTypeDropdownList) {

    //                 mapProductTypeArray.forEach(element => {

    //                     if(element.id == ProducTypeLists.productTypeId) {
    //                         mapProductTypeArrayTemp.push(element);
    //                     }
    //                     this.createAssetCategoryForm.controls['mapProductType'].setValue(mapProductTypeArrayTemp);

    //                 });


    //                 if(ProducTypeLists.productTypeName!=''){

    //                         this.producTypeList.push(
    //                             {
    //                                 'id': ProducTypeLists.productTypeId,
    //                                 'itemName': ProducTypeLists.productTypeName
    //                             });
    //                         }

    //                 this.producTypeList = this.removeDuplicates(this.producTypeList, "id");
    
    //             }

                
    //         } 
    //     });

    // }

    // fetchMoreGetProductType(event){

    //     if ((event.endIndex == this.producTypeList.length - 1) && (this.producTypeList.length>=9)) {

    //         this.productScrollCount++;  

    //         if(this.productScrollCount<= this.productInfoObj.pageCount){

    //             this.startProduct = this.startProduct + 10;
    //             this.getProductTypeInfo(this.productTypeMappingFilter);
                 
    //         }
           
    //     }

    // }

    // getNavigationInfo(filterObj){
            
    //     this.commonService.changeloader(true);

    //     this.commonService.getGlobalPrimaryNavigation(filterObj,this.startPrimary,100000).subscribe((result:any)=>{
            
    //         this.commonService.changeloader(false);
          
    //         this.primaryNavigationList = [];   
    //         this.ProducTypeDropdownList = [];
    //         let mapPrimaryNavigationArrayTemp = [];
    //         let mapPrimaryNavigationArray = this.createAssetCategoryForm.value.mapPrimaryNavigation;

    //         if(result.data.data.length > 0){   

    //             this.primaryObj.pageCount = result.data.data.pageCount;
                
    //             // if(this.isSearch == true){

    //             //     this.primaryNavigationList = [];
    //             //     this.primaryScrollCount = 0;
    //             //     this.isSearch = false;
    
    //             // }

    //             for (let primaryNavigationLists of result.data.data) {

    //                 mapPrimaryNavigationArray.forEach(element => {

    //                     if(element.id == primaryNavigationLists.primaryNavigationId) {
    //                         mapPrimaryNavigationArrayTemp.push(element); 
    //                     }
    //                     this.createAssetCategoryForm.controls['mapPrimaryNavigation'].setValue(mapPrimaryNavigationArrayTemp);

    //                 });

    //                 if(primaryNavigationLists .primanyNavigationName!=''){
    //                 this.primaryNavigationList.push(
    //                     {
    //                         'id': primaryNavigationLists.primaryNavigationId,
    //                         'itemName': primaryNavigationLists.primanyNavigationName
    //                     });
    //                 } 
            
    //                 this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList, "id");

    //             }
    //         } 
    //     })
    // }

    
    // fetchMoreGetPrimary(event){

    //     if ((event.endIndex == this.primaryNavigationList.length - 1) && (this.primaryNavigationList.length>=9)) {

    //         this.primaryScrollCount++;  

    //         if(this.primaryScrollCount<= this.primaryObj.pageCount){

    //             this.startPrimary = this.startPrimary + 10;
    //             this.getNavigationInfo(this.primaryNavigationMappingFilter);
                 
    //         }
           
    //     }

    // }

    // getGlobalMenuCategory(filterObj){

    //     console.log("filterObj= ",filterObj);

    //     this.commonService.getGlobalMenuCategory(filterObj,this.startCategory,100000).subscribe((result:any)=>{
            
    //         console.log("getGlobalMenuCategory Result= ",result.data.data); 

    //         let mapMenuCategoryArrayTemp = [];
    //         this.menuCategoryList = [];
    //         let mapMenuCategoryArray = this.createAssetCategoryForm.value.mapMenuCategory;
            
    //         if(result.data.data.length > 0){ 

    //             // if(this.isSearch == true){
    //             //     this.menuCategoryList = [];
    //             //     this.isSearch = false;
    //             //     this.menuCategoryScrollCount = 0;
    //             // }
                
    //             this.menuCategoryObj.pageCount == result.data.data.pageCount;

    //             for (let menuCategoryList of result.data.data) {

    //                 mapMenuCategoryArray.forEach(element => {

    //                     if(element.id == menuCategoryList.menuCategoryId) {
    //                         mapMenuCategoryArrayTemp.push(element);
    //                     }
    //                     this.createAssetCategoryForm.controls['mapMenuCategory'].setValue(mapMenuCategoryArrayTemp);
    //                 });

    //                 if(menuCategoryList.menuCategoryName!=''){
                    
    //                     this.menuCategoryList.push(
    //                     {
    //                         'id': menuCategoryList.menuCategoryId,
    //                         'itemName': menuCategoryList.menuCategoryName
    //                     });
    //                 } 
    //             }
    //         } 

    //     });
    // }

    
    mapAssetGroupArrayTemp = [];

    getGlobalAssetGroup(filterObj){

        console.log("filterObj= ",filterObj);

        this.commonService.getGlobalAssetGroup(filterObj,this.startGroup,10).subscribe((result:any)=>{

            console.log("getGlobalAssetGroup Result= ",result.data.data);

            //this.assetGroupList = [];

            this.mapAssetGroupArrayTemp = [];
            let mapAssetGroupArray = this.createAssetCategoryForm.value.mapAssetGroup;
            
            if(result.data.data.length > 0){    
                
                if(this.isSearch){
                    this.assetGroupList = [];
                    this.isSearch = false;
                }

                for (let assetGroupList of result.data.data) {

                    mapAssetGroupArray.forEach(element => {

                        if(element.id == assetGroupList.assetGroupId) {
                        
                            this.mapAssetGroupArrayTemp.push(element);
                            this.createAssetCategoryForm.controls['mapAssetGroup'].setValue(this.mapAssetGroupArrayTemp);

                        }

                    });

                    if(assetGroupList.assetGroupName!=''){
                    
                        this.assetGroupList.push(
                        {
                            'id': assetGroupList.assetGroupId,
                            'itemName': assetGroupList.assetGroupName
                        });
                        
                        this.assetGroupList = this.removeDuplicates(this.assetGroupList, "id");

                    } 
                }
            } 

        });
    }

    // removeDuplicates(originalArray, prop) {

    //     var newArray = [];
    //     var lookupObject  = {};
    
    //     for(var i in originalArray) {
    //         lookupObject[originalArray[i][prop]] = originalArray[i];
    //     }
    
    //     for(i in lookupObject) {
    //         newArray.push(lookupObject[i]);
    //     }
    //         return newArray;
    // }

    // getGenreInfo(){

    //     this.commonService.getGlobalGenre({},0,1000).subscribe((result:any)=>{

    //         this.mapGenreList = [];

    //         if(result.data.data.length > 0){         
    //             for (let genreLists of result.data.data) {

    //                 if(genreLists.genreName!=''){
    //                 this.mapGenreList.push(
    //                     {
    //                         'id': genreLists.genreId,
    //                         'itemName': genreLists.genreName
    //                     });
    //                 }    
    //             } 
    //         } 
    //     });
    // }

    // getCertificate(){

    //     this.commonService.getGlobalCertification({},0,10000000).subscribe((result:any)=>{
    //         this.mapCertificatList = [];
    //         if(result.data.data.length > 0){       

    //             for (let certificationLists of result.data.data) {

    //                 if(certificationLists.certificationName!=''){
    //                 this.mapCertificatList.push(
    //                     {
    //                         'id': certificationLists.certificationId,
    //                         'itemName': certificationLists.certificationName
    //                     });
    //                 }    
    //             }

    //         } 
    //     });
    // }

    // getLibrary(){
    //     this.commonService.getglobalLibrary({},0,1000000).subscribe((result:any)=>{
    //         this.mapLibraryList = [];
    //         if(result.data.data.length > 0){       

    //             for (let libraryLists of result.data.data) {

    //                 if(libraryLists.libraryId!= undefined){
    //                 this.mapLibraryList.push(
    //                     {
    //                         'id': libraryLists.libraryId,
    //                         'itemName': libraryLists.libraryName
    //                     });
    //                 }    
    //             }

    //         } 
    //     });
    // }:any={}

    castFilter: any = {};
    startClassification = 0;

    getCastInfo(){
        this.commonService.getGlobalCast(this.castFilter,this.startCast,10).subscribe((result:any)=>{
         
          //  this.mapCastList = [];
         
            if(result.data.data.length > 0){   
                
                if(this.isSearch == true){

                    this.mapCastList = [];
                    this.isSearch = false;
                
                }

                for (let castLists of result.data.data) {

                    if(castLists.castFirstName!=''){
                    this.mapCastList.push(
                        {
                            'id': castLists.castId,
                            'itemName': castLists.castFirstName
                        });
                    }
                    
                    this.mapCastList = this.removeDuplicates(this.mapCastList, "id");

                }
                
                console.log("this.mapCastList>>>",this.mapCastList);

            } 
        });
       
    }

    fetchMoreGetCast(event){
        
        if ((event.endIndex == this.mapCastList.length - 1) && (this.mapCastList.length>=9)) {

                this.startCast = this.startCast + 10;
                this.getCastInfo(); 
           
        }
    }

    // getLanguageInfo(){

    //     this.commonService.getGlobalLanguage({},0,1000).subscribe((result:any)=>{
            
    //         this.mapLanguageList = [];
            
    //         if(result.data.data.length > 0){       
    //             for (let languageList of result.data.data) {

    //                 if(languageList.languageName!=''){
                    
    //                     this.mapLanguageList.push(
    //                     {
    //                         'id': languageList.languageId,
    //                         'itemName': languageList.languageName
    //                     });
    //                 } 
    //             }

    //             console.log("this.mapLanguageList",this.mapLanguageList);
    //         } 

    //     });
    // }

    // getSponsoreInfo(obj){

    //     this.commonService.getGlobalSponsore(obj,0,1000).subscribe((result:any)=>{
            
    //         this.mapSponsoreList = [];
            
    //         if(result.data.data.length > 0){       
    //             for (let sponsorList of result.data.data) {

    //                 if(sponsorList.sponsorName!=''){
                    
    //                     this.mapSponsoreList.push(
    //                     {
    //                         'id': sponsorList.sponsorId,
    //                         'itemName': sponsorList.sponsorName
    //                     });
    //                 } 
    //             }
    //         } 

    //     });

    // }

      //file upload related code

    selectFileVertical(e ,i) {

        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

    var reader = new FileReader();
    
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {

            this.img[i].verticalURL=reader.result;
            console.log("this.img[i].",this.img[i]);
        }

        e.target.value = '';

    
    }

    selectFileHorizontal(e ,i) {

           //set obj for file upload of horizontal section
           if(e.target.files[0].name==undefined)
           return;
   
           this.uploadImgFilePathHorizontal[i].index = i;
           this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
           this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
           this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
           this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
           this.uploadImgFilePathHorizontal[i].horizontalFile = true; 

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

    }

    saveCreateAssetCategory(){

        // if(localStorage.getItem("languageId")!= null){

        //     this.langId =localStorage.getItem("languageId");
        //     this.createAssetCategoryForm.controls['langId'].setValue('1')

        // }else {

        //     this.langId = "1";
        //     this.createAssetCategoryForm.controls['langId'].setValue('1')

        // }
            
        const airStartTime = this.createAssetCategoryForm.get('airStartTime');
        const airEndTime = this.createAssetCategoryForm.get('airEndTime');
        const airStartDate = this.createAssetCategoryForm.get('airStartDate');
        const airEndDate = this.createAssetCategoryForm.get('airEndDate');

        if(this.createAssetCategoryForm.value.airStartDate == ''){
            this.createAssetCategoryForm.controls['airStartTime'].setValue(['']);
        }

        if(this.createAssetCategoryForm.value.airEndDate == ''){
            this.createAssetCategoryForm.controls['airEndTime'].setValue(['']);
        }


        if(this.createAssetCategoryForm.value.airStartDate=='' && this.createAssetCategoryForm.value.airStartTime!=''){

            // airStartDate.setValidators([Validators.required]);
            // airStartDate.updateValueAndValidity();

        }else if(this.createAssetCategoryForm.value.airStartDate!='' && this.createAssetCategoryForm.value.airStartTime==''){

            // airStartTime.setValidators([Validators.required]);
            // airStartTime.updateValueAndValidity();
            console.log('update',this.createAssetCategoryForm);

        }else {
            airStartTime.setValidators(null);
            airStartDate.setValidators(null);
            airStartTime.updateValueAndValidity();
            airStartDate.updateValueAndValidity();
        }

        if(this.createAssetCategoryForm.value.airEndDate=='' && this.createAssetCategoryForm.value.airEndTime!=''){

            // airEndDate.setValidators([Validators.required]);
            // airEndDate.updateValueAndValidity();

        }else if(this.createAssetCategoryForm.value.airEndDate!='' && this.createAssetCategoryForm.value.airEndTime==''){

            // airEndTime.setValidators([Validators.required]);
            // airEndTime.updateValueAndValidity();
       

        }else{
            airEndTime.setValidators(null);
            airEndDate.setValidators(null);
            airEndTime.updateValueAndValidity();
            airEndDate.updateValueAndValidity();
        }

        // this.loader = true;
        this.commonService.changeloader(true);
        this.createAssetCategorySubmitted=true;
       
        let varialbeArray=[];

        let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath=[];

        //remove empty file upload img obj from array.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });
        
        if(!this.createAssetCategoryForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return
        }
        
        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.deviceTypeDublicate){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.multipleDeviceForm.value.devices.length == 0){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }
        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        let async = this.uploadImgFilePath.length;
        if(!this.update){

            if(this.roleAsses.assetCategoryCreate == false){

                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;

            }

            // uploadImgFilePosterImage upload code finish pravin

            console.log("uploadImgFilePath>>",this.uploadImgFilePath);

            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.assetGroup).then(res => {

                        if (res && res.Location) {
                                async--;
                                 if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                                if (async == 0) {

                                    for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){

                                                varialbeArray.push(
                                                {
                                                    "label": this.multipleDeviceForm.value.devices[i].label,
                                                    "description":this.multipleDeviceForm.value.devices[i].description,
                                                    "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                    "isCarouselApplicable":this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                                                    "isVisible":this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                                                    "verticalFileName": "",
                                                    "verticalFilePath": "",
                                                    "verticalFileType": "",  
                                                    "horizontalFileName": "",
                                                    "horizontalFilePath": "",
                                                    "horizontalFileType": ""
                                                
                                                });   

                                    }

                                    let asyncvariables = varialbeArray.length;
                                    for(let i=0;i< varialbeArray.length;i++){
                                            asyncvariables--;
                                            for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
        
                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
        
                                                    varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                    varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                    varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
        
                                                }

                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
        
                                                    varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                    varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                    varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
        
                                                }
        
                                            }
                                    }

                                    if(asyncvariables == 0){

                                        this.createAssetCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                        console.log("createNewProductForm123",this.createAssetCategoryForm);
                                        
                                            if(!this.createAssetCategoryForm.valid){
                                                // this.loader = false;
                                                this.commonService.changeloader(false);
                                                return;
                                            }
                                        this.createAssetCategorySubmitted=true;
                                         this.postCallAssetCategory();
                                        //New Code for right side for from
                                        //this.onContentReadyToReview();

                                    }

                                }      

                        }

                    });
                }
            }

            if(this.uploadImgFilePath.length==0){
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                    
                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "isCarouselApplicable":this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
                
                this.createAssetCategorySubmitted = true;
                this.createAssetCategoryForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }
        
                if(!this.createAssetCategoryForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                
                console.log("createNewProductForm",this.createAssetCategoryForm);
                 this.postCallAssetCategory();
                //New Code for right side for from
                //this.onContentReadyToReview();
                
            }

        }

        if(this.update){

            if(this.roleAsses.assetCategoryEdit == false){

                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;

            }

            let asyncvariables = this.multipleDeviceForm.value.devices.length;
            if(this.uploadImgFilePath.length == 0){

                this.createAssetCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createAssetCategorySubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }

                if(!this.createAssetCategoryForm.valid){
                    // this.loader = false;
                    this.commonService.changeloader(false);
                    return;
                }
            
                console.log("createNewProductForm",this.createAssetCategoryForm);
                
                 this.postCallAssetCategory();
                //New Code for right side for from
                //this.onContentReadyToReview();

            }

            // if(this.uploadImgFilePath.length > 0 && this.createNewAssetForm.value.vodClosedCaptionFilePath!=''){
            // }
            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.assetGroup).then(res => {

                        if (res && res.Location) {
                            async--;
                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){

                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }


                                }
                            }

                            if(asyncvariables == 0){

                                this.createAssetCategorySubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }
                               

                                if(!this.createAssetCategoryForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }

                                this.createAssetCategoryForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("createNewProductForm",this.createAssetCategoryForm);
                                 this.postCallAssetCategory();
                                //New Code for right side for from
                                //this.onContentReadyToReview();

                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    })
                }
            }

        }
    }

    postCallAssetCategory(){

        this.createAssetCategoryForm.controls['langId'].setValue('languageId');

        // this.loader=true;
        this.commonService.changeloader(true);
        // this.createAssetCategoryForm.controls['airStartDate'].setValue(this.createAssetCategoryForm.value.airStartDate +' '+ this.createAssetCategoryForm.value.airStartTime);
        // this.createAssetCategoryForm.controls['airEndDate'].setValue(this.createAssetCategoryForm.value.airEndDate +' '+  this.createAssetCategoryForm.value.airEndTime);
  
        if(this.createAssetCategoryForm.value.airStartDate!='')
        this.createAssetCategoryForm.controls['airStartDate'].setValue(this.createAssetCategoryForm.value.airStartDate +' '+ this.createAssetCategoryForm.value.airStartTime);

        if(this.createAssetCategoryForm.value.airStartTime =='')
        this.createAssetCategoryForm.controls['airStartDate'].setValue(this.createAssetCategoryForm.value.airStartDate)

        if(this.createAssetCategoryForm.value.airEndTime =='')
        this.createAssetCategoryForm.controls['airEndDate'].setValue(this.createAssetCategoryForm.value.airEndDate)

        if(this.createAssetCategoryForm.value.airEndDate!='')
        this.createAssetCategoryForm.controls['airEndDate'].setValue(this.createAssetCategoryForm.value.airEndDate +' '+  this.createAssetCategoryForm.value.airEndTime);
            if( this.update == false ) {

                delete this.createAssetCategoryForm.value.id;
                this.assetCategoryService.insertAssetCategory(this.createAssetCategoryForm.value)
                .subscribe((result:any)=>{

                    // this.loader=false;
                    this.commonService.changeloader(false);
                    this.spinnerService.hide();
                    this.toastrService.success('Asset Category Created Successfully.');
                    // this.router.navigate(['asset_management/asset_category/list']);

                    //New Code for right side for from
                    this.update = false;
                 //    this.updateSend.emit();
                    this.resetForm();
                    this.createAssetCategorySubmitted = false;
                });
    
            }
    
            if(this.update) {

                this.createAssetCategoryForm.controls['id'].setValue(this.id);
                this.assetCategoryService.updateAssetCategory(this.createAssetCategoryForm.value)
                .subscribe((result:any)=>{

                    // this.loader=false;
                    this.commonService.changeloader(false);
                    this.toastrService.success('Asset Category Updated Successfully.');
                    console.log('<====result result result============>',result);
                    this.spinnerService.hide();
                    // this.router.navigate(['asset_management/asset_category/list']);
                    //New Code for right side for from
                    this.update = false;
                  //  this.updateSend.emit();
                    this.resetForm();
                    this.createAssetCategorySubmitted = false;
                   
                });

            }
        
    }
    
    //Pravin code mulitple from
    createItem(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.required],
            description: [''],
            uploadImage: [''],
            dtypeId:['',Validators.required],
            isVisible:[''],
            isCarouselApplicable:'',
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""
        });
    }
   //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup { 

        if(objProductDeviceTypeConfigure.dtypeId === 4){
            this.all = true;
        }

        return this.formBuilder.group({
            label: [objProductDeviceTypeConfigure.label,Validators.required],
            description: objProductDeviceTypeConfigure.description,
            isCarouselApplicable:objProductDeviceTypeConfigure.isCarouselApplicable,
            isVisible:objProductDeviceTypeConfigure.isCarouselApplicable,
            dtypeId:[objProductDeviceTypeConfigure.dtypeId,Validators.required],
            verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
            verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
            verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
            horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
            horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
            horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType

        });
       
    }
    
    onDtypeId(){
        this.All=false;
        // this.Web=false;
        // this.Android=false;
        // this.iOS=false;
    }
    
    addItem(): void {
        
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  

        if(!this.multipleDeviceForm.valid){  

            this.createAssetCategorySubmitted = true;
            return
  
          }
          
        if(this.multipleDeviceForm.value.devices.length >= 4){

            this.createAssetCategorySubmitted = true;
            return;

        }

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
        }

    }

    //New Code for right side for from
    onContentReadyToReview(){
        // this.loader = false;
                
        if(!this.createAssetCategoryForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createAssetCategorySubmitted = true;
            return
        }
        
        if(!this.multipleDeviceForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.createAssetCategorySubmitted = true;
            return;
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;


        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }
    
    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    onAssetCategoryTabClick(){
        
        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:true,
             mapGeoZone:true,
             information:false,
             //  mapping:false,
              hierarchy:false,
             seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }


    onOrderingTabClick(){
        
        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:false,
             mapGeoZone:false,
             information:false,
            //  mapping:false,
             hierarchy:false,
             seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:true,
                information:false,
                castAndCrew:false,
                ordering:true
            },
            seo:false,
            // mapping:false
        }
    }

    onCastAndCrewTabClick(){
        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:false,
             mapGeoZone:false,
             information:false,
             //  mapping:false,
              hierarchy:false,
             seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:true,
                information:false,
                castAndCrew:true,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    onDeviceTypeTabClick(){
 
        this.formTabs = {

            assetCategoryInfo:{
    
                assetCategory:false,
                mapGeoZone:false,
                information:false,
                //  mapping:false,
                hierarchy:false,
                seo:false
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:true,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    onMappingTabClick(){

        this.formTabs = {

            assetCategoryInfo:{
    
                assetCategory:false,
                mapGeoZone:false,
                information:false,
                hierarchy:false,
                seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:true,
                information:true,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:true
        }

    }
  
    onSeoTabClick(){
        this.formTabs = {

            assetCategoryInfo:{
    
                assetCategory:true,
                mapGeoZone:false,
                information:false,
                //  mapping:false,
                hierarchy:false,
                seo:true
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    onHorizontalTabClick(){
        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:false,
             mapGeoZone:false,
             information:false,
             //  mapping:false,
              hierarchy:false,
             seo:false
    
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:true,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    onVerticalTabClick(){
        
        this.formTabs = {

            assetCategoryInfo:{

                assetCategory:false,
                mapGeoZone:false,
                information:false,
                //  mapping:false,
                hierarchy:false,
                seo:false

            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:false,
                vertical:true

            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    oninformationTabClick(){

        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:true,
             mapGeoZone:false,
             information:true,
             //  mapping:false,
              hierarchy:false,
             seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    onHierarchyTabClick(){

        this.formTabs = {

            assetCategoryInfo:{
    
             assetCategory:true,
             mapGeoZone:false,
             information:false,
             //  mapping:false,
              hierarchy:true,
             seo:false
    
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            metadata:{
                metadata:false,
                information:false,
                castAndCrew:false,
                ordering:false
            },
            seo:false,
            // mapping:false
        }
    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event)
        if(event=='Cancel'){

            this.isDeleteHorizontalImageAction=false;

        }
        
        if(event=='Yes'){

            this.isDeleteHorizontalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";

            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    
    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){

            this.isDeleteVerticalImageAction = false;

        }
        
        if(event=='Yes'){

            this.isDeleteVerticalImageAction = false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }
    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    //is Content Ready To Review Received  
    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createAssetCategoryForm.controls['contentState'].setValue(['Completed']);
           // this.postCallAssetCategory();
           this.saveCreateAssetCategory();
           
        }
        if(event == 'Cancel'){

            this.createAssetCategoryForm.controls['contentState'].setValue(['In-Progress']);
           // this.postCallAssetCategory();
           this.saveCreateAssetCategory();
           

        }
       
    }


    resetForm(){

        
    this.productTypeMappingFilter = { };
    this.primaryNavigationMappingFilter = {  };
    this.menuCategoryMappingFilter = {};
    this.assetGroupMappingFilter = {};
 

        this.onAssetCategoryTabClick();

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);

        }

        console.log("this.formTabs >>>>",this.formTabs);

        
        this.createAssetCategoryForm = this.formBuilder.group({
            contentType : [""],
            isActive : ["1"],
            langId: [this.langId],
            companyId : [""],
            title :[ "",Validators.required],
            displayIndexing: [''],
            sameForAllZone : [''],
            sameForAllProductType :[''],
            // airStartDate : ["",Validators.required],
            // airStartTime:['',Validators.required],
            // airEndTime:['',Validators.required],
            // airEndDate : ["",Validators.required],
            airStartDate : [""],
            airStartTime:[''],
            airEndTime:[''],
            airEndDate : [""],
            exclusionText : [""],
            description : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            orderBy :[""],
            sorting : [""],
            mapGeoZone:[[],Validators.required],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapCertification:[[]],
            mapCharacter:[[]],
            mapCast:[[]],
            mapGenre:[[]],
            id:[''],
            // mapAssetLibrary:[[]],
            mapLibrary:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            mapClassification:[[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        })

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];


        // update list on reset code start    
          if(this.reset == true){

            this.updateSend.emit();
            this.reset = false;
            
         }else{
             
            this.updateSend.emit();
         }

     
         this.id = null;

         // update list on reset code end

        this.update = false;

        //////////////////////

        // this.img.push({
        //     horizontalURL:'',
        //     verticalURL:''
        // });
        //reset device type image
        this.img = [];
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        }) 

        //reset multiselect selected list
        this.selectedItems = [];
        this.selectedprimaryNavigationList = [];
        this.selectedmenuCategoryList = [];

        this.update = false;

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });

       
        this.showCreateNewButton('show');

    }


    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
