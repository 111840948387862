
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router ,ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from '../../../../../configurations/errorMessages';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
// import {FooterLinkService} from '../../../../../services/footer_link/footer-link.service';
import { BlogsService} from '../../../../../services/blogs/blogs.service';
import { ProductConfigurationService } from '../../../../../services/product_configuration/product_configuration.service';
import { ToastrService } from 'ngx-toastr';
import { splitClasses } from '@angular/compiler';
import {SessionHelper} from '../../../../../services/localstorage';
import {awsPath, deviceType, urlPattern} from '../../../../../configurations/configuration';
import {CommonService} from '../../../../../services/common_Service/common.service';

import { from } from 'rxjs';

@Component({
  selector: 'app-addBlogs',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentBlogs implements OnInit {

  isCreateNewShow:boolean = true;

  isUserLoggedIn:boolean=false;
  createFooterlinkForm:FormGroup;
  errorMessages = errorMessages;
  blogsSubmitted:boolean = false;


  contentReadyToReview:boolean= false;
  shareMassage:any;


  geoZoneList = [];
  uploadImgFilePath:any=[];
  uploadImageFileUrl:any;
  geoZoneDropdownList = [];
  mapGeoZoneSettings= {};

  deviceType = deviceType;
  all:boolean = false;
  Web:boolean = false;
  android:boolean = false;
  iOS:boolean = false;
  roku:boolean = false;

  index = 0;
  infoObj:any = {};

  deviceTypeFull:boolean = false;
  deviceTypeDublicate:boolean = false;

  devices: FormArray;
  multipleDeviceForm:FormGroup;

  isDeleteAction: boolean=false;
  deleteDeviceTypeIndex:any;

  createBlogFormSubmitted:boolean=false;
  createBlogForm:FormGroup;


  uploadImgFilePathVertical:any=[];
  uploadImgFilePathHorizontal:any=[];


  isDeleteHorizontalImageAction:boolean=false;
  isDeleteVerticalImageAction:boolean=false;

  id:any;
  update=false;
  img:any=[];

  ProducTypeDropdownList = [];
  producTypeList = [];
  dropdownList = [];

  loader:boolean=false;
  poster:boolean=false;

  menuCategorySettings = {};
  ProducTypeSettings = {};


   //New Code for right side for from
   @Output() updateSend = new EventEmitter<any>();
   @Input() set contentId(value: any) {
       // this.onLoad(value);

       console.log("value>>>",value);
       if(value == 'reset'){

           this.resetForm();
       }else {

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
           this.onLoad(value);
       }
   }


 
  isDeleteImageAction:boolean=false;


    formTabs:any = {

        blogsInfo:{
            blogs:true,
            information:true,
            seo:false
        },
        deviceType:{
            configurationType:false,
            configuration:false,
        }
    }

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');


  constructor( private commonService:CommonService, private toastrService:ToastrService, private productConfigurationService:ProductConfigurationService, private activatedRoute:ActivatedRoute, private blogsService:BlogsService, private formBuilder:FormBuilder, private router:Router, private loginSharedService:Loginshared) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }




  get l() { return this.createBlogForm.controls; }

  get m(): FormArray {

    return this.multipleDeviceForm.get('devices') as FormArray;

   }
   
   roleAsses:any={};
   roleConfiguration:any=[];
   ngOnInit(){

                // theme update code start
        
                this.commonService.currentThemelight.subscribe((result:any)=>{
          
                    this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
                    
               })

               this.roleAsses.blogcEdit = false;
               this.roleAsses.blogc = false;
               this.roleAsses.blogcCreate = false;
       
               this.roleConfiguration = SessionHelper.getRole();
       
               this.roleConfiguration.forEach((element , i) => {
       
                     if((element.isView == 1 && element.pageTitle == 'Blogs Category') ){
                         
                         this.roleAsses.blogc = true;
                     }
                 
                     if((element.isEdit == 1 && element.pageTitle == 'Blogs Category') ){
                         
                         this.roleAsses.blogcEdit = true;
                     }
                     
                     if((element.isCreate == 1 && element.pageTitle == 'Blogs Category')){
       
                         this.roleAsses.blogcCreate = true;
                     
                     }
                    })
       
        
               // theme update code start
                }

   onLoad(id) {


        let headerTitle = {
            title:'Master',
            subTitle:'Blogs Category'
        }

        this.commonService.changeHeaderTitle(headerTitle);

        this.createBlogForm = this.formBuilder.group({
        
            isActive : ["1"],
            id:[''],
            langId: [],
            title: ['',Validators.required],
            description: ['',Validators.required],
            seoTitle: [],
            seoKeyword: [],
            seoDescription:[],
            productDeviceTypeConfigure: [[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

        })

        // this.activatedRoute.params.subscribe(params => {

           // this.id = +params['id']; // (+) converts string 'id' to a number

           this.id = id;

            if(this.id!='null'){
            
            console.log('this.id===>>>',this.id);
            
                this.update = true;
                this.getBlogInfo(this.id);

            }else{


            //pravin start code
            this.multipleDeviceForm = this.formBuilder.group({

                devices: this.formBuilder.array([this.createItem()])

            });

                this.update = false;
                this.img.push({
                    horizontalURL:'',
                    verticalURL:''
                })
                
                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }

                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }

                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
        
            }

        // });


    }

    editCreate:boolean = false;

    onCreateNew(){

        if(this.roleAsses.geoZoneCreate == true){

            this.editCreate = true;
        }
        
        this.isCreateNewShow=false

}

    createItem(): FormGroup {

        return this.formBuilder.group({  
                
            label: ['',Validators.required],
            description: [''], 
            dtypeId:['',Validators.required], 
            isVisible:'', 
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""

        });
    }     //Pravin code mulitple from



    getBlogInfo(id){

        this.loader=true;

        this.blogsService.getBlogs(id)
        .subscribe((result:any)=>{

            this.loader=false;

            this.createBlogForm.controls['description'].setValue(result.data[0].description);
            this.createBlogForm.controls['title'].setValue(result.data[0].title);
            this.createBlogForm.controls['isActive'].setValue(result.data[0].isActive);
         
            this.createBlogForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
            this.createBlogForm.controls['seoDescription'].setValue(result.data[0].results.productDeviceTypeConfigure[0].seoDescription);
            this.createBlogForm.controls['seoKeyword'].setValue(result.data[0].results.productDeviceTypeConfigure[0].seoKeyword);
            this.createBlogForm.controls['seoTitle'].setValue(result.data[0].results.productDeviceTypeConfigure[0].seoTitle);

            this.createBlogForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createBlogForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createBlogForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createBlogForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            let assetObj=[];

      
            
            assetObj=[];


            if(result.data[0].results.productDeviceTypeConfigure.length>0){

                    this.multipleDeviceForm = this.formBuilder.group({
                        devices: this.formBuilder.array([])
                    });


                   //device type validation code start
                   if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                      this.deviceTypeFull = true;
                     }
                  //device type validation code end

                   this.img = [];

                    this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                    
                    for(let i = 0;i < result.data[0].results.productDeviceTypeConfigure.length;i++){

                            this.img.push({
                                horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                                verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                            })

                            let objH = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "horizontalFileName": "",
                                "horizontalFilePath": "",
                                "horizontalFileType": "",
                                'horizontalFile':false
                            }
                
                            let objV = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "verticalFileName":"",
                                "verticalFilePath": "",
                                "verticalFileType":"",
                                'verticalFile':false,
                                
                            }
                
                            this.uploadImgFilePathHorizontal.push(objH);
                            this.uploadImgFilePathVertical.push(objV);
                            
                            this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                    }
            }

        });

    }

    onSaveBlogs(){

        console.log("123 createBlogForm 123>>",this.createBlogForm);

        console.log("createGenreForm",this.createBlogForm.value);
        console.log("multipleDeviceForm",this.multipleDeviceForm.value);


        let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath=[];

        //remove empty file upload img obj from array.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });

        this.loader = true;
        this.createBlogFormSubmitted = true;
        let async = this.uploadImgFilePath.length;
        let uploadEmptyCount = 1;
        let varialbeArray=[];

        if(!this.createBlogForm.valid){
            this.loader = false;
            return;
        }
        if(!this.multipleDeviceForm.valid){
            this.loader = false;
            return;
        }
  
        if(this.multipleDeviceForm.value.devices == 0){
            this.loader = false;
            return;
        }

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            this.loader = false;
            return;
        }

        if(this.deviceTypeDublicate){
            this.loader = false;
            return;
        }
        

        if(!this.update){

            // uploadImgFilePosterImage upload code finish pravin  'tcs-assets/'
            

            console.log("uploadImgFilePath>>",this.uploadImgFilePath)
            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    if(this.uploadImgFilePath[i].selectedFiles!=''){
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles,awsPath.asset).then(res => {

                            if (res && res.Location) {
                                    async--;
                                      if(this.uploadImgFilePath[i].horizontalFile == true)
                                    this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                    
                                    if(this.uploadImgFilePath[i].verticalFile == true)
                                    this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                    console.log("this.uploadImgFilePath[i].Location>>",this.uploadImgFilePath[i]); 
                                    if (async == 0) {

                                        let asycn = this.multipleDeviceForm.value.devices.length;
                                        for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                            asycn -- ;
                                                    varialbeArray.push(
                                                    {
                                                        "label": this.multipleDeviceForm.value.devices[i].label,
                                                        "description":this.multipleDeviceForm.value.devices[i].description,
                                                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                                                        "verticalFileName": "",
                                                        "verticalFilePath": "",
                                                        "verticalFileType": "",  
                                                        "horizontalFileName": "",
                                                        "horizontalFilePath": "",
                                                        "horizontalFileType": ""
                                                
                                                    });   

                                        }

                                        if(asycn == 0){
                                            
                                            let asyncvariables = varialbeArray.length;
                                            for(let i=0;i< varialbeArray.length;i++){
                                                    asyncvariables--;
                                                    for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
                
                                                        if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
                
                                                            varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                            varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                            varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
                
                                                        }
    
                                                        if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
                
                                                            varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                            varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                            varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
                
                                                        }
                
                                                    }
                                            }
    
                                            if(asyncvariables == 0){
    
                                                this.createBlogForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                                console.log("createNewProductForm123",this.createBlogForm);
                                                
                                                if(!this.createBlogForm.valid){
                                                    this.loader = false;
                                                    return;
                                                }

                                                if(!this.multipleDeviceForm.valid){
                                                    this.loader = false;
                                                    return;
                                                }

                                                this.createBlogFormSubmitted=true;

                                              //  this.onContentReadyToReview()
                                                 this.postCallBlogs();
                                            }
   
                                    }     
                                }
                            }

                        });
                    }else{

                        async--;
                        uploadEmptyCount ++;
                        if(uploadEmptyCount == this.uploadImgFilePath.length){
                            
                            this.uploadImgFilePath.length=[];

                        }
                        
                    }
                   
                }
            }

            if(this.uploadImgFilePath.length==0){
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                    
                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
                
                console.log("varialbeArray",varialbeArray);
                this.createBlogFormSubmitted = true;
                this.createBlogForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    this.loader = false;
                    return;
                }
        
                if(!this.createBlogForm.valid){
                    this.loader = false;
                    return;
                }

                
                console.log("createNewProductForm",this.createBlogForm);
                this.postCallBlogs();
              
            }

        }
    
        if(this.update){

            let asyncvariables = this.multipleDeviceForm.value.devices.length;
            if(this.uploadImgFilePath.length == 0){

                this.createBlogForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createBlogFormSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    this.loader = false;
                    return;
                }

                if(!this.createBlogForm.valid){
                    this.loader = false;
                    return
                }
            
                console.log("createNewProductForm",this.createBlogForm);
                
                 this.postCallBlogs();
              
            }

            // if(this.uploadImgFilePath.length > 0 && this.createNewAssetForm.value.vodClosedCaptionFilePath!=''){
            // }
            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){

                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }


                                }
                            }

                            if(asyncvariables == 0){
                            
                                this.createBlogFormSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    this.loader = false;
                                    return;
                                }

                                if(!this.createBlogForm.valid){
                                    this.loader = false;
                                    return;
                                }
                                this.createBlogForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                                
                               
                               this.postCallBlogs();
                             

                            }
                        }
                    })
                }
            }

        }


    }

  //New Code for right side for from
  onContentReadyToReview(){


    if(!this.createBlogForm.valid){
        this.loader = false;
        this.createBlogFormSubmitted = true;
        return;
    }

    if(!this.multipleDeviceForm.valid){
        this.loader = false;
        this.createBlogFormSubmitted = true;
        return;
    }

    // this.loader = false;
    this.commonService.changeloader(false);

    this.contentReadyToReview = true;
    this.shareMassage = 'review';

  }
//is Content Ready To Review Received  



isContentReadyToReviewReceived(event){

    console.log("we are in",event);
    this.contentReadyToReview = false;

   //New Code start
    if(event == 'No'){

        this.contentReadyToReview = false;
       
    }
    //New Code end

    if(event == 'Yes'){

        this.createBlogForm.controls['contentState'].setValue(['Completed']);
        this.onSaveBlogs();
        this.onBlogTabClick();
               
    }
    if(event == 'Cancel'){

        this.createBlogForm.controls['contentState'].setValue(['In-Progress']);
        this.onSaveBlogs();
        this.onBlogTabClick();       

    }
   
}

    postCallBlogs(){


        this.loader = false;
       
        if(!this.createBlogForm.valid || !this.multipleDeviceForm.valid)
        {
            this.loader = false;
            return;
        }

            this.loader=true;
            if( this.update == false ) {

                delete this.createBlogForm.value.id;
                this.blogsService.insertBlogs(this.createBlogForm.value)
                .subscribe((result:any)=>{
                    this.loader=false;

                    this.uploadImgFilePath = [];
                    //New Code for right side for from
                    this.update = false;
                 //   this.updateSend.emit();
                    this.resetForm();
                    this.createBlogFormSubmitted = false;

                    // this.router.navigate(['master/blogs/list']);
                    this.toastrService.success('Blog Category Created Successfully.');
                });

            }

            if(this.update){

                this.createBlogForm.controls['id'].setValue(this.id);
                this.blogsService.updateBlogs(this.createBlogForm.value)
                .subscribe((result:any)=>{
                    this.loader=false;

                    this.uploadImgFilePath = [];
                    this.toastrService.success('Blog Category Updated Successfully.');
                    //New Code for right side for from
                    this.update = false;
                 //   this.updateSend.emit();
                    this.resetForm();
                    this.createBlogFormSubmitted = false;
                    this.router.navigate(['master/blogs/list']);
                    console.log('<====result result result============>',result);
                ;
                
                });
            }
    }

    //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup {
    
    //device type validation code start
    if(objProductDeviceTypeConfigure.dtypeId === 4){
        this.all = true;
    }
    //device type validation code end
        return this.formBuilder.group({
            label: objProductDeviceTypeConfigure.label,
            description: objProductDeviceTypeConfigure.description,
            dtypeId:objProductDeviceTypeConfigure.dtypeId,
            isVisible:objProductDeviceTypeConfigure.isVisible,
            verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
            verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
            verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
            horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
            horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
            horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType

        });
        
    }

    resetForm(){

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {
            arrayControl.removeAt(0);
        }

        this.updateSend.emit();


        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });


        this.createBlogForm = this.formBuilder.group({
        
            isActive : ["1"],
            id:[''],
            langId: [],
            title: ['',Validators.required],
            description: ['',Validators.required],
            seoTitle: [],
            seoKeyword: [],
            seoDescription:[],
            productDeviceTypeConfigure: [[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

        })

        this.update = false;
        this.img = [];

        this.img.push({
            'verticalURL':'',
            'horizontalURL':''
        })

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        this.createBlogFormSubmitted = false;


        this.showCreateNewButton('show');
    }

    onDeviceTabClick(){

        this.formTabs = {

            blogsInfo:{
                blogs:false,
                information:false,
            },
            deviceType:{
                configurationType:true,
                configuration:true,
            }
        }

    }

    onBlogTabClick(){

        this.formTabs = {

            blogsInfo:{

                blogs:true,
                information:true,
                seo:false
            },
            
            deviceType:{

                configurationType:false,
                configuration:false,
            }
        }

    }

    onSeoTabClick(){

        this.formTabs = {

            blogsInfo:{
                blogs:true,
                information:false,
                seo:true,
            },
            deviceType:{
                configurationType:false,
                configuration:false,
            }
        }

    }

    selectFileVertical(e ,i) {

        this.img.push({
            'verticalURL':'',
            'horizontalURL':''
        })

        if(e.target.files[0].name==undefined){

            return;
        }
       

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 
            // for(let i = 0;i<this.uploadImgFilePath.length;i++){

            //     if(obj.index == this.uploadImgFilePath[i].index && obj.imgtype ==='vertical'){
            //         this.uploadImgFilePath.splice(i, 1);
            //     }
            // }

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].verticalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

       
    }

    selectFileHorizontal(e ,i) {
                
        this.img.push({
            'verticalURL':'',
            'horizontalURL':''
        })

        if(e.target.files[0].name==undefined){

            return;
        }
    
        this.uploadImgFilePathHorizontal[i].index = i;
        this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
        this.uploadImgFilePathHorizontal[i].horizontalFile = true; 
        // for(let i = 0;i<this.uploadImgFilePath.length;i++){

        //     if(obj.index == this.uploadImgFilePath[i].index && obj.imgtype ==='horizontal'){
        //         this.uploadImgFilePath.splice(i, 1);
        //     }
        // }

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';
  
    }


    addItem(): void {
        
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
        }

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteHorizontalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";

            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event);

        if(event == 'Cancel'){

            this.isDeleteVerticalImageAction = false;

        }
        
        if(event == 'Yes'){

            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}