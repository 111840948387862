import { Injectable } from '@angular/core';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable ,Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetCategoryService {

  subject = new Subject()

  constructor(private http: HttpClient) { }

  updateAssetCategory(postObj:any) : Observable<any> {

    postObj.langId = localStorage.getItem('languageId'); 
    if(postObj.langId== null){

      postObj.langId = '1';

    }
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateAssetCategoryUrl, postObj,httpOptions);
  }
        
  insertAssetCategory(postObj:any) : Observable<any> {

    postObj.langId = localStorage.getItem('languageId');

    if(postObj.langId== null){

         postObj.langId = '1';

     }

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertAssetCategoryUrl, postObj,httpOptions);
  }    
  
  getAssetCategory( id: any, langId:any ) : Observable<any> {

    langId = localStorage.getItem('languageId');
      
      if(langId == null){       
          langId = '1';
      }
      
      if(id){
        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getAssetCategoryUrl+"/" + id +"/" + langId);
      }
      
  }

  getAllAssetCategory(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

        
      if(filterObj.languageId == null){       
        filterObj.languageId = '1';  
    }

      let urlGetAllAsset = environment.apiURL + environment.adminApiUrl + environment.getAssetCategoryUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllAsset)
  }

  deleteAssetCategory(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteAssetCategoryUrl, postObj,httpOptions);
  }
}
