import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CasteAndCrewService } from '../../../../../services/caste_and_crew/caste_and_crew.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from '../../../../../configurations/errorMessages';
import {awsPath, usernamePattern} from '../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {CharacterService } from '../../../../../services/character/character.service';

import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-addCharacter',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentCharacter implements OnInit {

  isCreateNewShow:boolean = true;

  isUserLoggedIn: boolean = false;
  createCastNCrewForm:FormGroup;
  errorMessages = errorMessages;
  createCastNCrewSubmitted:boolean = false;
  assetGroupList=[];

  verticalPosterImageUrl:any;
  horizontalPosterImageUrl:any;

  img:any=[];
  professionList:any=[];

  id:any;
  update=false;

  loader:boolean=false;
  mapCastSettings:any={};
  mapCastlist:any=[];
  liveEventList:any=[];

  uploadImgFilePath:any=[];
  uploadImgFilePathVertical:any=[];
  uploadImgFilePathHorizontal:any=[];

  shareMassage:any;

  isDeleteHorizontalImageAction:boolean=false;
  isDeleteVerticalImageAction:boolean=false;


  contentReadyToReview:boolean = false;

    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.resetForm();

        }else {

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
  constructor( private characterService:CharacterService,private commonService:CommonService,private toastrService: ToastrService, private productConfigurationService: ProductConfigurationService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private casteAndCrewService: CasteAndCrewService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  roleAsses:any={};
  roleConfiguration:any=[];
  ngOnInit() {

    
    this.roleAsses.characterEdit = false;
    this.roleAsses.character = false;
    this.roleAsses.characterCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {

          if((element.isView == 1 && element.pageTitle == 'Character') ){
              
              this.roleAsses.character = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Character') ){
              
              this.roleAsses.characterEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Character')){

              this.roleAsses.characterCreate = true;
          
          }
        })
        

      // theme update code start
      this.commonService.currentThemelight.subscribe((result:any)=>{
          
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
     })
    // theme update code start
  }

  
  onLoad(id) {

    this.getGlobalCast();
    this.getGlobalProfession();

    let headerTitle = {
        title:'Master',
        subTitle:'Character'
  
    }
  
    this.commonService.changeHeaderTitle(headerTitle);

    this.mapCastSettings = {

        singleSelection: true, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"

    }

    let uid = localStorage.getItem('ud');

        if (uid !== undefined && uid != null) {

        }   else {

            this.router.navigate(['/login']);
        }

        this.createCastNCrewForm = this.formBuilder.group({

            //firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
            //lastName:['', Validators.pattern(usernamePattern)],
            
            firstName: ['', Validators.compose([Validators.required])],
            lastName:[''],
            
            shortDescription:[''],
            description:[''],
            isActive:['1'],
            verticalFileName:[''],
            verticalFilePath:[''],
            verticalFileType:[''],
            horizontalFileName:[''],
            horizontalFilePath:[''],
            horizontalFileType:[''],
            quotes:[""],
            professionType:[[]],
            mapCast: [[]],
            id:[''],
            created_by:[localStorage.getItem('ud')]


        });

        // this.createCastNCrewForm = this.formBuilder.group({

        //     "firstName":[ "Ranjeet1"],
        //     "lastName": ["Gholave"],
        //     "shortDescription":["Short description working"],
        //     "description": ["Desc"],
        //     "isActive": [1],
        //     "verticalFileName":[ "vertical.jpg"],
        //     "verticalFilePath":[ "https://dcafe-content.s3.us-west-1.amazonaws.com/tcs-assets/Janice%20photo.jpg"],
        //     "horizontalFileName":[ "horizontal.jpg"],
        //     "horizontalFilePath": ["https://dcafe-content.s3.us-west-1.amazonaws.com/tcs-assets/Janice%20photo.jpg"],
        //     "quotes":["Sample code"],
        //     "professionType":["Actor"],
        //     "mapCast1": [[]]

        // });


        // this.activatedRoute.params.subscribe(params => {
        //   this.id = +params['id']; // (+) converts string 'id' to a number
          this.id = id;
          
          if(this.id != 'null'){
          
            console.log('this.id===>>>',this.id);
          
              this.update=true;
              this.getCastNCrewInfo(this.id)

          }else{
              
            this.update = false;

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
            
                // this.img.push({
                //     horizontalURL:'',
                //     verticalURL:''
                // })   
        
          }

    //   });
  }
  get l() { return this.createCastNCrewForm.controls; }

    saveCastNCrew(){

        this.createCastNCrewSubmitted = true;
    }


   
    getCastNCrewInfo(id){
            
        // this.loader=true;
        this.commonService.changeloader(true);

        this.characterService.getCharacter(id)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);

            console.log("result==========>",result);
            
            this.createCastNCrewForm.controls['firstName'].setValue(result.data[0].firstName);
            this.createCastNCrewForm.controls['lastName'].setValue(result.data[0].lastName);
            this.createCastNCrewForm.controls['shortDescription'].setValue(result.data[0].shortDescription);
            this.createCastNCrewForm.controls['description'].setValue(result.data[0].description);
            this.createCastNCrewForm.controls['quotes'].setValue(result.data[0].quotes);
            this.createCastNCrewForm.controls['isActive'].setValue(result.data[0].isActive.toString());


            this.createCastNCrewForm.controls['verticalFileName'].setValue(result.data[0].verticalFileName);
            this.createCastNCrewForm.controls['verticalFilePath'].setValue(result.data[0].verticalFilePath);
            this.createCastNCrewForm.controls['verticalFileType'].setValue(result.data[0].verticalFileType);

            this.createCastNCrewForm.controls['horizontalFileName'].setValue(result.data[0].horizontalFileName);
            this.createCastNCrewForm.controls['horizontalFilePath'].setValue(result.data[0].horizontalFilePath);
            this.createCastNCrewForm.controls['horizontalFileType'].setValue(result.data[0].horizontalFileType);


            this.verticalPosterImageUrl=result.data[0].verticalFilePath;
            this.horizontalPosterImageUrl=result.data[0].horizontalFilePath;

            setTimeout(()=>{

                
            let obj = {

                'id':result.data[0].professionTypeId,
                'itemName':result.data[0].professionTypeName

            }

            this.createCastNCrewForm.controls['professionType'].setValue([obj]);
             
            let objS = {

                'id':result.data[0].cast[0].castId,
                'itemName':result.data[0].cast[0].firstName

            }

            this.createCastNCrewForm.controls['mapCast'].setValue([objS]);

            console.log("objS",objS);


                // this.professionList.forEach((element)=>{

                //     console.log("element",element);
                //     console.log("element",element);


                //           if( element.id == result.data[0].professionTypeId){
    
                //          this.createCastNCrewForm.controls['professionType'].setValue([element]);
    
                //           }
                // })

                // this.mapCastlist.forEach((element)=>{

                //     if( element.id == result.data[0].cast[0].castId){

                //    this.createCastNCrewForm.controls['mapCast'].setValue([element]);

                //     }
                // })
            },1000)


            

            console.log("result==========>",result.data[0].isActive.toString());
        
        });
    }

    
    selectFileHorizontal(e ) {

        this.loader = true;

        this.uploadImgFilePath=[];
  
        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }
  
        var mimeType = e.target.files[0].type;
  
        if (mimeType.match(/image\/*/) == null) {
  
            console.log("Only images are supported.");
            //this.errorImage = 'Only images are supported.';
            this.horizontalPosterImageUrl = '';
          
            return;
        }

  
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            
            this.horizontalPosterImageUrl=reader.result;
            
        }

        this.uploadImgFilePath.push(obj);  
  
        if(this.uploadImgFilePath.length>0){
  
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
  
                    if (res && res.Location) {

                        this.loader = false;
                        this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].fileName);
                        this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                        this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].fileType); 

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }
  
        }

        e.target.value = '';
        
    }

    selectFileVertical(e) {

        this.loader = true;

        this.uploadImgFilePath=[];
  
        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }
  
        var mimeType = e.target.files[0].type;
  
        if (mimeType.match(/image\/*/) == null) {
  
            console.log("Only images are supported.");
            //this.errorImage = 'Only images are supported.';
            this.verticalPosterImageUrl = '';
          
            return;
        }

  
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            
            this.verticalPosterImageUrl=reader.result;
            
        }

        this.uploadImgFilePath.push(obj);  
  
        if(this.uploadImgFilePath.length>0){
  
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
  
                    if (res && res.Location) {

                        this.loader = false;
                        this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                        this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                        this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType); 

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }
  
        }

        e.target.value = '';

    }

    saveCharacter(){

        console.log('update',this.id);
        console.log("Record received",this.createCastNCrewForm.value);

        // this.loader = true;
        this.commonService.changeloader(true);

        this.createCastNCrewSubmitted = true;

        if(!this.createCastNCrewForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);

            return;
        }

        if(this.update){

            if(this.roleAsses.characterEdit == false){
                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }

                if(this.uploadImgFilePath.length>0){
                    
                    let async = this.uploadImgFilePath.length;

                    for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                    
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
        
                            if (res && res.Location) {

                                async -- ;

                                console.log("async>>>",async);
            
                                
                                //   this.uploadImgFilePath[i].FilePath=res.Location;  
                                if(this.uploadImgFilePath[i].imgtype == 'horizontal'){
            
                                    this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].horizontalFileName);
                                    this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                                    this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].horizontalFileType);
                                    console.log("update>>>",this.createCastNCrewForm.value);

                                    if(async == 0){

                                        this.postCallCharacter();

                                        //New Code for right side for from
                                    // this.onContentReadyToReview();
                                    }
            
                                }
        
                                if(this.uploadImgFilePath[i].imgtype == 'vertical'){
        
                                    this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                                    this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                                    this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType);
                                    console.log("update>>1",this.createCastNCrewForm.value);

                                    if(async == 0){

                                        this.postCallCharacter();

                                        //New Code for right side for from
                                        //this.onContentReadyToReview();
                                        
                                    }
        
                                }
        
                        }
                        
                        })
                        
                    }
    
                }

                if(this.uploadImgFilePath.length == 0){

                    this.postCallCharacter();

                    //New Code for right side for from
                    // this.onContentReadyToReview();

                }
        }

        if(!this.update){

            if(this.roleAsses.characterCreate == false){
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

              if(this.uploadImgFilePath.length>0){

                let async = this.uploadImgFilePath.length;
        
                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                   
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
                    
                        if (res && res.Location) {
                            async -- ;
                                // this.loader = false;
                                this.commonService.changeloader(false);

                            //   this.uploadImgFilePath[i].FilePath=res.Location;  
                            if(this.uploadImgFilePath[i].imgtype == 'horizontal'){
        
                                this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].horizontalFileName);
                                this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                                this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].horizontalFileType);
                                console.log("createNewProductForm",this.createCastNCrewForm);

                                if(async == 0){

                                     this.postCallCharacter();

                                    //New Code for right side for from
                                   // this.onContentReadyToReview();
                                }
                            }

                            if(this.uploadImgFilePath[i].imgtype == 'vertical'){

                                this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                                this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                                this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType);
                                console.log("createNewProductForm",this.createCastNCrewForm.value);

                                if(async == 0){

                                     this.postCallCharacter();

                                    //New Code for right side for from
                                    // this.onContentReadyToReview();
                                }

                            }
    
                    }
                    
                    })
                }

               
              }

              if(this.uploadImgFilePath.length == 0){

                 this.postCallCharacter();

                //New Code for right side for from
                //this.onContentReadyToReview();
                  
              }
        }


    }

    postCallCharacter(){
       
        if(!this.update){

            delete this.createCastNCrewForm.value.id;
            this.characterService.insertCharacter(this.createCastNCrewForm.value)
            .subscribe((result:any)=>{
               
                // this.spinnerService.hide();
                // this.router.navigate(['master/character/list']);
                 //New Code for right side for from
                 this.commonService.changeloader(false);
                 this.update = false;
             //    this.updateSend.emit();
                 this.resetForm();
                 this.createCastNCrewSubmitted = false;
                this.toastrService.success('Character Created Successfully.');
            });
        }

        if(this.update){

            this.createCastNCrewForm.controls['id'].setValue(this.id);
            this.characterService.updateCharacter(this.createCastNCrewForm.value)
            .subscribe((result:any)=>{
              
                // this.router.navigate(['master/character/list']);
                 //New Code for right side for from
                 this.commonService.changeloader(false);
                 this.update = false;
            //     this.updateSend.emit();
                 this.resetForm();
                 this.createCastNCrewSubmitted = false;
                this.toastrService.success('Character Updated Successfully.');
                // this.spinnerService.hide();
                
            });
        }
    }

    getGlobalCast(){

    
        this.commonService.getGlobalCast({},0,10000).subscribe((result:any)=>{
            
            
            this.liveEventList = [];
            
            if(result.data.data.length > 0){
                   
                for (let objLiveEvent of result.data.data) {
   
                    if(objLiveEvent.castFirstName!=''){
                    
                        this.mapCastlist.push(
                        {
                            'id': objLiveEvent.castId,
                            'itemName': objLiveEvent.castFirstName
                        });
                    } 
                }
            } 

        });
        
    }
    // http://localhost:3200/admin/globalprofession
    getGlobalProfession(){
    
        this.commonService.getGlobalProfession({},0,1000).subscribe((result:any)=>{
            
            this.professionList = [];
            
            if(result.data.data.length > 0){
                   
                for (let professionList of result.data.data) {
   
                    if(professionList.professionId!=''){
                    
                        this.professionList.push(
                        {
                            'id': professionList.professionId,
                            'itemName': professionList.professionName
                        });
                    } 
                }
            } 

        });
        
    }

    onItemSelect(z , y){

    }

    OnItemDeSelect(z , y){

    }
    
    onSelectAll(z , y){

    }

    onDeSelectAll(z , y){

    }

    
    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){
   
            this.isDeleteHorizontalImageAction=false; 
            // delete this.uploadImgFilePath;
            this.horizontalPosterImageUrl ='';
            this.createCastNCrewForm.controls['horizontalFileName'].setValue(['']);
            this.createCastNCrewForm.controls['horizontalFilePath'].setValue(['']);
            this.createCastNCrewForm.controls['horizontalFileType'].setValue(['']);

        }

    }



    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){

            this.verticalPosterImageUrl = '';
            this.isDeleteVerticalImageAction=false;
            this.createCastNCrewForm.controls['verticalFileName'].setValue(['']);
            this.createCastNCrewForm.controls['verticalFilePath'].setValue(['']);
            this.createCastNCrewForm.controls['verticalFileType'].setValue(['']);

        }

    }


    //New Code for right side for from
    onContentReadyToReview(){

        //code for global loader start
        // this.loader = false;
        this.commonService.changeloader(false);
        //code for global loader end

        if(this.createCastNCrewForm.invalid){

             this.createCastNCrewSubmitted = true;
             return;
             
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'character';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

      // New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;

            this.shareMassage = ''; 
           
        }
        //New Code end

        if(event == 'Yes'){

            // this.postCallCharacter();
            this.shareMassage = ''; 
            this.saveCharacter();
           
        }
        if(event == 'Cancel'){

            // this.postCallCharacter();
            this.shareMassage = ''; 
            this.contentReadyToReview = false;
          //  this.saveCharacter();
            
        }
       
    }

    
    resetForm(){

    
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {
        //     arrayControl.removeAt(0);
        // }

        // console.log("this.formTabs >>>>",this.formTabs);

        this.updateSend.emit();

        
        this.uploadImgFilePath =[];
        
        this.createCastNCrewForm = this.formBuilder.group({

            //firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
            //lastName:['', Validators.pattern(usernamePattern)],
            
            firstName: ['', Validators.compose([Validators.required])],
            lastName:[''],
            
            shortDescription:[''],
            description:[''],
            isActive:['1'],
            verticalFileName:[''],
            verticalFilePath:[''],
            verticalFileType:[''],
            horizontalFileName:[''],
            horizontalFilePath:[''],
            horizontalFileType:[''],
            quotes:[""],
            professionType:[[]],
            mapCast: [[]],
            id:[''],
            created_by:[localStorage.getItem('ud')]


        });
        

        this.update = false;

        //////////////////////

        // this.img = [];
        this.horizontalPosterImageUrl = '';
        this.verticalPosterImageUrl = '';

        
        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        let objH = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);
       
        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });

        this.showCreateNewButton('show');
    
    }
    
    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}