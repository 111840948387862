import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private http: HttpClient) { }

    private themelight = new BehaviorSubject({});
    currentThemelight = this.themelight.asObservable();

    changeThemelight(themelight:any) {
      this.themelight.next(themelight)
    }

    private themeLanguage = new BehaviorSubject(false);
    currentThemeLanguage = this.themeLanguage.asObservable();

    changeThemeLanguage(themeLanguage: boolean) {
      this.themeLanguage.next(themeLanguage)
    }

  //user for on off loader
    private loader = new BehaviorSubject(false);
    currentLoader = this.loader.asObservable();

    changeloader(loader: boolean) {

      this.loader.next(loader);

    }


      //user for on off loader start

      private reconcillation = new BehaviorSubject({});
      currentReconcillation = this.reconcillation.asObservable();
  
      changeReconcillation(reconcillation: any) {
  
        this.reconcillation.next(reconcillation);
  
      }

      //user for on off loader stop
    

    //user role on off loader
    private role = new BehaviorSubject({});
    currentrole = this.role.asObservable();

    changeRole(role) {

      this.role.next(role);

    }

    private headerTitle = new BehaviorSubject({ 'subTitle':'',
    'title':''});
    currentheaderTitle = this.headerTitle.asObservable();

    changeHeaderTitle(headerTitle: any) {
      this.headerTitle.next(headerTitle)
    }

    notify: Subject<boolean> = new Subject<boolean>();
    onNotify(event) {
            this.notify.next(true);
    }


    getGlobalProductType(filterObj ,start, limit) : Observable<any> {
      
      let urlGetGlobalProductType = environment.apiURL + environment.adminApiUrl + environment.getGlobalProductTypeUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
    //  let urlGetGlobalProductType = environment.apiURL + environment.adminApiUrl + environment.getGlobalProductTypeUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj));
      return this.http.get<any>(urlGetGlobalProductType)
    }

    getGlobalCharacter(filterObj ,start , limit) : Observable<any> {

      let urlGetGlobalCharacter = environment.apiURL + environment.adminApiUrl + environment.getGlobalCharacterUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;

    //  let urlGetGlobalCharacter = environment.apiURL + environment.adminApiUrl + environment.getGlobalCharacterUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj));
      return this.http.get<any>(urlGetGlobalCharacter)
    }

  
    getGlobalPrimaryNavigation(filterObj ,start , limit) : Observable<any> {

      let urlGetGlobalPrimaryNavigation = environment.apiURL + environment.adminApiUrl + environment.getGlobalPrimaryNavigationurl+'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetGlobalPrimaryNavigation)
    }  

    

    getGlobalProfession(filterObj,start, limit) : Observable<any> {
      let urlgetGlobalProfession = environment.apiURL + environment.adminApiUrl + environment.getGlobalProfessionUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlgetGlobalProfession)
    }  

    

    getGlobalMenuCategory(filterObj,start, limit): Observable<any> {
      let urlGetGlobalMenuCategory = environment.apiURL + environment.adminApiUrl + environment.globalmenucategoryUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetGlobalMenuCategory)
    }

    getGlobalAssetGroup(filterObj,start, limit): Observable<any> {
      let urlGetGlobalAssetGroup = environment.apiURL + environment.adminApiUrl + environment.globalAssetGroupUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetGlobalAssetGroup)
    }

    getGlobalAssetCategory(filterObj,start, limit): Observable<any> {
      let urlGetGlobalAssetCategory = environment.apiURL + environment.adminApiUrl + environment.globalAssetCategoryUrl+'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetGlobalAssetCategory)
    }

    // getGlobalLanguage(): Observable<any> {
    //   let urlgetGlobalLanguage= environment.apiURL + environment.adminApiUrl + environment.globalLanguageUrl;
    //   return this.http.get<any>(urlgetGlobalLanguage)
    // }

    getGlobalLanguage(obj ,start , limit): Observable<any> {
      let urlgetGlobalLanguage= environment.apiURL + environment.adminApiUrl + environment.globalLanguageUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlgetGlobalLanguage)
    }

    getGlobalSponsore(obj ,start , limit): Observable<any> {
      let urlgetGlobalSponsore= environment.apiURL + environment.adminApiUrl + environment.globalsponsorUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlgetGlobalSponsore)
    }

    
    getglobalLibrary(obj ,start , limit):Observable<any> {

        let UrlGetglobalLibrary = environment.apiURL + environment.adminApiUrl + environment.globallibraryUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(UrlGetglobalLibrary)
        
    }

    getGlobalCountry(): Observable<any> {
      let urlGetGlobalCountry = environment.apiURL + environment.adminApiUrl + environment.globalmenucategoryUrl;
      return this.http.get<any>(urlGetGlobalCountry)
    }

  

    // getGlobalCertification() : Observable<any> {
    //   let urlGetGlobalCertification = environment.apiURL + environment.adminApiUrl + environment.getGlobalCertificationUrl;
    //   return this.http.get<any>(urlGetGlobalCertification)
    // }    

    
    getGlobalCertification(obj ,start , limit) : Observable<any> {
      let urlGetGlobalCertification = environment.apiURL + environment.adminApiUrl + environment.getGlobalCertificationUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetGlobalCertification)
    }    

    getGlobalGeozone() : Observable<any> {
      let urlGetGlobalGeozone = environment.apiURL + environment.adminApiUrl + environment.getGlobalGeozoneUrl;
      return this.http.get<any>(urlGetGlobalGeozone)
    }

    // getGlobalTrailerVideo(): Observable<any> {
    //     let UrlGetGlobalTrailerVideo = environment.apiURL + environment.adminApiUrl + environment.globaltrailervideoUrl;
    //     return this.http.get<any>(UrlGetGlobalTrailerVideo)
    // }
    
    getGlobalTrailerVideo(obj,start,limit): Observable<any> {
      let UrlGetGlobalTrailerVideo = environment.apiURL + environment.adminApiUrl + environment.globaltrailervideoUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetGlobalTrailerVideo)
  }  

    getGlobalUplynkAdServer():Observable<any> {
      let UrlGetGlobalUplynkAdServer = environment.apiURL + environment.adminApiUrl + environment.globaluplynkadserverUrl;
      return this.http.get<any>(UrlGetGlobalUplynkAdServer)
    } 

    getGlobalLiveEvent():Observable<any> {
      let UrlGetGlobalLiveEvent = environment.apiURL + environment.adminApiUrl + environment.globalliveeventUrl;
      return this.http.get<any>(UrlGetGlobalLiveEvent)
    }

    getGlobalAssetLibrary():Observable<any> {
      let UrlGetGlobalAssetLibrary = environment.apiURL + environment.adminApiUrl + environment.globalassetlibraryUrl;
      return this.http.get<any>(UrlGetGlobalAssetLibrary)
    }
          
    // getGlobalLiveVirtualChannel():Observable<any> {
    //   let UrlGetGlobalLiveVirtualChannel = environment.apiURL + environment.adminApiUrl + environment.globallivevirtualchannelUrl;
    //   return this.http.get<any>(UrlGetGlobalLiveVirtualChannel)
    // } 
    
       
    getGlobalLiveVirtualChannel(obj ,start , limit):Observable<any> {
      let UrlGetGlobalLiveVirtualChannel = environment.apiURL + environment.adminApiUrl + environment.globallivevirtualchannelUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetGlobalLiveVirtualChannel)
    }  
    
    // getGlobalCast():Observable<any> {
    //   let UrlGetGlobalCast = environment.apiURL + environment.adminApiUrl + environment.globalCastUrl;
    //   return this.http.get<any>(UrlGetGlobalCast)
    // } 

    getGlobalCast(obj ,start , limit):Observable<any> {
      let UrlGetGlobalCast = environment.apiURL + environment.adminApiUrl + environment.globalCastUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetGlobalCast)
    } 

    getGlobalGenre(obj ,start , limit):Observable<any> {
      let UrlGetGlobalGenre = environment.apiURL + environment.adminApiUrl + environment.globalGenreUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetGlobalGenre)
    } 

    
    // getGlobalGenre():Observable<any> {
    //   let UrlGetGlobalGenre = environment.apiURL + environment.adminApiUrl + environment.globalGenreUrl;
    //   return this.http.get<any>(UrlGetGlobalGenre)
    // } 

    
    getGlobalContinentList(countryFilter):Observable<any> {
      // let UrlGetGlobalContinent = environment.apiURL + environment.adminApiUrl + environment.globalContinentListUrl;
      let UrlGetGlobalCountryList = environment.apiURL + environment.adminApiUrl + environment.globalContinentListUrl+'?filter='+encodeURIComponent(JSON.stringify(countryFilter));
      return this.http.get<any>(UrlGetGlobalCountryList)
    } 

    getGlobalCountryList(countryFilter):Observable<any> {
      let UrlGetGlobalCountryList = environment.apiURL + environment.adminApiUrl + environment.globalCountryListUrl+'?filter='+encodeURIComponent(JSON.stringify(countryFilter));
      return this.http.get<any>(UrlGetGlobalCountryList)
    } 

    getGlobalStateList(stateFilter):Observable<any> {
      let UrlGetGlobalStateList = environment.apiURL + environment.adminApiUrl + environment.globalStateListUrl+'?filter='+encodeURIComponent(JSON.stringify(stateFilter));
      return this.http.get<any>(UrlGetGlobalStateList)
    } 

    getGlobalCityList(cityFilter):Observable<any> {
      let UrlGetGlobalCityList = environment.apiURL + environment.adminApiUrl + environment.globalCityListUrl+'?filter='+encodeURIComponent(JSON.stringify(cityFilter));
      return this.http.get<any>(UrlGetGlobalCityList)
    }  
    getGlobalDeviceTypeList():Observable<any> {
      let UrlGetGlobalDeviceTypeList = environment.apiURL + environment.adminApiUrl + environment.globalDeviceTypeListUrl;
      return this.http.get<any>(UrlGetGlobalDeviceTypeList)
    }  
    getGlobalLoginSourceList():Observable<any> {
      let UrlGetGlobalLoginSource = environment.apiURL + environment.adminApiUrl + environment.globalLoginSourceListUrl;
      return this.http.get<any>(UrlGetGlobalLoginSource)
    } 

    getGlobalRole():Observable<any> {
      let UrlGetGlobalRole = environment.apiURL + environment.adminApiUrl + environment.globalRoleUrl;
      return this.http.get<any>(UrlGetGlobalRole)
    } 

    getGlobalReview(obj , start, limit):Observable<any> {
      let UrlGetGlobalReview = environment.apiURL + environment.adminApiUrl + environment.globalReviewUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetGlobalReview)
    }

    getglobalclassification(obj,start , limit):Observable<any> {
      let UrlGetglobalclassification = environment.apiURL + environment.adminApiUrl + environment.globalclassificationUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetglobalclassification)
    }
 
    // getglobalPageCategory():Observable<any> {
    //   let UrlGetglobalPageCategory = environment.apiURL + environment.adminApiUrl + environment.globalPageCategoryUrl;
    //   return this.http.get<any>(UrlGetglobalPageCategory)
    // }

    getglobalPageCategory(obj,start , limit):Observable<any> {
      let UrlGetglobalPageCategory = environment.apiURL + environment.adminApiUrl + environment.globalPageCategoryUrl+'?filter='+encodeURIComponent(JSON.stringify(obj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(UrlGetglobalPageCategory)
    }

    // getglobalLibrary():Observable<any> {
    //   let UrlGetglobalLibrary = environment.apiURL + environment.adminApiUrl + environment.globallibraryUrl;
    //   return this.http.get<any>(UrlGetglobalLibrary)
    // }
    

    getglobalBlog():Observable<any> {
      let UrlGetglobalBlog = environment.apiURL + environment.adminApiUrl + environment.globalBlogUrl;
      return this.http.get<any>(UrlGetglobalBlog)
    }

    getglobalNews():Observable<any> {
      let UrlGetglobalNews = environment.apiURL + environment.adminApiUrl + environment.globalNewsUrl;
      return this.http.get<any>(UrlGetglobalNews)
    }
          
    getGlobalPage():Observable<any> {
      let UrlGetGlobalPage = environment.apiURL + environment.adminApiUrl + environment.globalPageUrl;
      return this.http.get<any>(UrlGetGlobalPage)
    }       
    
}
