import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {


  constructor(private http: HttpClient) { }

  updateCarousel(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateCarouselUrl, postObj,httpOptions);
  }
        
  insertCarousel(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertCarouselUrl, postObj,httpOptions);
  }    
  
  getCarousel( id: any, langId:any ) : Observable<any> {

    if(langId == null){
      langId = '1';
    }
      
      if(id){
        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getCarouselUrl+"/" + id +"/" + langId);
      }
      
  }

  getAllCarousel(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');
    if(filterObj.languageId  == 'null'){
      filterObj.languageId = '1';
    }

    console.log("localStorage.getItem('languageId')>>>",localStorage.getItem('languageId'));

      let urlGetAllAsset = environment.apiURL + environment.adminApiUrl + environment.getCarouselUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllAsset)
  }

  deleteCarousel(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteCarouselUrl, postObj,httpOptions);
  }

}
