import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import {CommonService} from '../../../../services/common_Service/common.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import {ProductConfigurationService} from '../../../../services/product_configuration/product_configuration.service';
import {SubscriptionService } from '../../../../services/subscription/subscription.service';
import { errorMessages } from '../../../../configurations/errorMessages'
import * as moment from 'moment'; 
import {SessionHelper} from '../../../../services/localstorage';
import { deviceType, numberOnlyPattern } from '../../../../configurations/configuration';
import { bool } from 'aws-sdk/clients/signer';
@Component({
  selector: 'app-addSubscription',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddSubscription implements OnInit {

    isCreateNewShow:boolean = true;

      deviceType = deviceType;
      all:boolean = false;
      loader:boolean = false;
      Web:boolean = false;
      android:boolean = false;
      iOS:boolean = false;
      roku:boolean = false;
      shareMassage :any;

      contentReadyToReview:boolean= false;

      deviceTypeFull:boolean = false;
      deviceTypeDublicate:boolean = false;
      createSubscriptionForm:FormGroup;
      createSubscriptionFormSubmit:boolean = false;
      errorMessages=errorMessages;

      geoZoneList = [];//geoZoneList type variable 
      dropdownSettings={};

      geoZoneSettings= {};

      dropdownList = [];

      productTypeList=[]; //product type variable
      ProducTypeSettings={};
      ProducTypeDropdownList=[];

      bitratelist=[];

      primaryNavigationList=[]; //primary Navigation listing
      primaryNavigationSettings={};

      menuCategoryList=[]; //primary Navigation listing
      menuCategorySettings={};

      assetGroupList=[]; //primary Navigation listing
      assetGroupSettings={};

      
      assetCategoryList=[]; // asset Category listing
      assetCategorySettings={};

      assetList=[] // Asset listing
      trailerVideoSettings={};

      devices: FormArray;
      multipleDeviceForm:FormGroup;

      variableForm:FormGroup;
      items: FormArray;

      update:boolean = false;
      id:any;

      start:number=0;
      limit:number=100000;
      
      isDeleteAction: boolean=false;
      deleteDeviceTypeIndex:any;

      isDeleteKeyAction: boolean = false;
      deleteKeyIndex:any;

      langId:any;

      formTabs:any = {

        information:{
            information: true,
            mapGeoZone:true,
            info:false,
            certificationstream:false,
            concurrency:false,
            usage: false,
            promovoucher:false
        },
        deviceType:{
            configuration: false,
            configurationType: false,
            horizontalImage: false,
            verticalImage: false,
        },
        subscriptionMapping:{
            mappingInfo:false,
            mapping: false,
        },
        contentMapping: false
        // mapGeoZone:true,
        // subscriptionInfo:false,
        // contentMapping:false,
        // freeUsage:false,
        // subscriptionMapping:false,
        // deviceType:false

     } 

     applyBlackTheme = localStorage.getItem('boolThemeFlag');


    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

     //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.resetForm();

        }else {

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
      constructor(private router:Router, private toastrService:ToastrService ,private subscriptionService:SubscriptionService,private productConfigurationService:ProductConfigurationService ,private commonService:CommonService, private activatedRoute:ActivatedRoute,private formBuilder:FormBuilder) { }


      roleAsses:any={};
      roleConfiguration:any=[];
    ngOnInit(){
  
          this.roleAsses.subscriptionEdit = false;
          this.roleAsses.subscription = false;
          this.roleAsses.subscriptionCreate = false;
  
          this.roleConfiguration = SessionHelper.getRole();
  
          this.roleConfiguration.forEach((element , i) => {
  
                if((element.isView == 1 && element.pageTitle == 'Subscription') ){
                    
                    this.roleAsses.subscription = true;
                }
            
                if((element.isEdit == 1 && element.pageTitle == 'Subscription') ){
                    
                    this.roleAsses.subscriptionEdit = true;
                }
                
                if((element.isCreate == 1 && element.pageTitle == 'Subscription')){
  
                    this.roleAsses.subscriptionCreate = true;
                
                }
  
          })
  

        // theme update code start
                            
     this.commonService.currentThemelight.subscribe((result:any)=>{
                            
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
      })
  
      // theme update code start

        this.bitratelist=[
            {"id":1080,"itemName":"1080p"},
            {"id":720,"itemName":"720p"},
            {"id":480,"itemName":"480p"},
            {"id":240,"itemName":"240p"},
            {"id":144,"itemName":"144p"}]

      }

      onLoad(id) {

        if(this.roleAsses.subscriptionCreate == true){

            this.editCreate = true;
        }
        
            
        let headerTitle = {
            title:'Monetization',
            subTitle:'Subscription'
        }
        this.commonService.changeHeaderTitle(headerTitle);
              this.onMapGeoZoneInfoTabClick();
            // this.id = +params['id']; 
            // (+) converts string 'id' to a number
            this.id = id;
            
            this.langId = "1";
            
            if(this.id != 'null'){
            
                 console.log('this.id===>>>',this.id);
            
                this.update = true;
                this.getSubscription(this.id);

            }else{

                this.update = false;
            }

        // In a real app: dispatch action to load the details here.
       
     
            this.createSubscriptionForm = this.formBuilder.group({
                isActive : ["1"],
                langId: ["1"],
                companyId : [""],
                title : ["",Validators.compose([Validators.required])],
                totalDeviceSupported : ['',Validators.compose([Validators.pattern(numberOnlyPattern)])],
                concurrentUser:['',Validators.compose([Validators.pattern(numberOnlyPattern)])],
                sameForAllZone : ['1'],
                packageStartDate :[''],
                packageEndDate : [''],
                exclusionText : [""],
                description : [""],
                lifetimeFree :["",Validators.compose([Validators.required])],
                minutes : ["",Validators.pattern('[0-9]*')],
                hits : ["",Validators.pattern('[0-9]*')],
                validityDays : ["",Validators.pattern('[0-9]*')],
                discountCode :[''],
                discountPercentage :[''],
                discountAmount :[''],
                discountUsageLimit :[''],
                viewCountLimit:['',Validators.pattern('[0-9]*')],
                rate : ["",Validators.pattern('[0-9]+(\.[0-9]+)?')],
                mapDeviceTypeRateKeys:[[]],
                mapGeoZone:[[],Validators.compose([Validators.required])],
                productDeviceTypeConfigure:[[]],
                mapProductType:[[]],
                mapPrimaryNavigation:[[]],
                mapMenuCategory:[[]],
                mapAssetGroup:[[]],
                mapAssetCategory:[[]],
                mapAsset:[[]],
                subscriptionType:[''],
                subscriptionFrequency:[''],
                id:[''],
                contentState:['In-Progress'],
                publisherState:['Un-published'],
                reviewerState:['Un-approved'],
                certificationtype:[''],
                minagelimit:[''],
                streams:[''],
                bitrate:[''],
                created_by:[localStorage.getItem('ud')]

            })
            this.createSubscriptionForm.controls['lifetimeFree'].setValue(false);

            this.ProducTypeSettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            this.primaryNavigationSettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            this.menuCategorySettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            this.assetGroupSettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            this.assetCategorySettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            this.trailerVideoSettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }
            
            this.dropdownSettings={
              singleSelection: false, 
              text:"Select",
              selectAllText:'Select All',
              unSelectAllText:'UnSelect All',
              enableSearchFilter: true,
              classes:"myclass custom-class"
            }

            //pravin start code
            this.multipleDeviceForm = this.formBuilder.group({
              devices: this.formBuilder.array([this.createDevice()])
          });

            //pravin start code
            this.variableForm = this.formBuilder.group({
              items: this.formBuilder.array([])
          });
          //end pravin  

        this.getGeoZoneInfo();
        this.getNavigationInfo(); // Map Certification
        this.getProductTypeInfo();
        this.getGlobaltrailervideo();
        this.getGlobalMenuCategory();
        this.getGlobalAssetGroup();
        this.getGlobalAssetCategory();

      }

    resetForm(){

        this.commonService.changeloader(false);
        //code for global loader end
        this.createSubscriptionFormSubmit = false;
    
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);
        }

        this.createSubscriptionForm = this.formBuilder.group({
            isActive : ["1"],
            langId: ["1"],
            companyId : [""],
            title : ["",Validators.compose([Validators.required])],
            totalDeviceSupported : ['',Validators.compose([Validators.pattern(numberOnlyPattern)])],
            concurrentUser:['',Validators.compose([Validators.pattern(numberOnlyPattern)])],
            sameForAllZone : ['1'],
            packageStartDate :[''],
            packageEndDate : [''],
            exclusionText : [""],
            description : [""],
            lifetimeFree :["",Validators.compose([Validators.required])],
            minutes : ["",Validators.pattern('[0-9]*')],
            hits : ["",Validators.pattern('[0-9]*')],
            validityDays : ["",Validators.pattern('[0-9]*')],
            discountCode :[''],
            discountPercentage :[''],
            discountAmount :[''],
            discountUsageLimit :[''],            
            viewCountLimit:['',Validators.pattern('[0-9]*')],
            rate : ["",Validators.pattern('[0-9]+(\.[0-9]+)?')],
            mapDeviceTypeRateKeys:[[]],
            mapGeoZone:[[],Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[]],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapAssetGroup:[[]],
            mapAssetCategory:[[]],
            mapAsset:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            certificationtype:[''],
            minagelimit:[''],
            streams:[''],
            bitrate:[''],
            created_by:[localStorage.getItem('ud')]

        });
        this.createSubscriptionForm.controls['lifetimeFree'].setValue(false);

        this.ProducTypeSettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        this.primaryNavigationSettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        this.menuCategorySettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        this.assetGroupSettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        this.assetCategorySettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        this.trailerVideoSettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }
        
        this.dropdownSettings={
          singleSelection: false, 
          text:"Select",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        }

        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
          devices: this.formBuilder.array([this.createDevice()])
       });

      this.update = false;

        //pravin start code
        this.variableForm = this.formBuilder.group({
          items: this.formBuilder.array([])
      });
      //end pravin  

        this.getGeoZoneInfo();
        this.getNavigationInfo(); // Map Certification
        this.getProductTypeInfo();
        this.getGlobaltrailervideo();
        this.getGlobalMenuCategory();
        this.getGlobalAssetGroup();
        this.getGlobalAssetCategory();


        this.showCreateNewButton('show');

    }

    
      onMapGeoZoneInfoTabClick(){

        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:true,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:true,
            // subscriptionInfo:false,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         } 
      }


      onPromoVoucherTabClick(){

        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:true
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:true,
            // subscriptionInfo:false,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         } 
      }
      

      onDeviceTabClick(){

        this.formTabs = {

            information:{
                information: false,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: true,
                configurationType: true,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:false,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:true
    
         }

      }
      

      onSubscriptionInfoTabClick(){

        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:false,
                info:true,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:true,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         }
      }

      onSubscriptionConcurrencyTabClick(){
        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:true,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:true,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         }
      }

      onSubscriptionCertificationstreamTabClick(){
        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:false,
                info:false,
                certificationstream:true,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:true,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         }
      }

      onContentMappingTabClick(){

        this.formTabs = {

            information:{
                information: false,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
            },
            contentMapping: true
            // mapGeoZone:false,
            // subscriptionInfo:false,
            // contentMapping:true,
            // freeUsage:false,
            // subscriptionMapping:false,
            // deviceType:false
    
         }

      }


      onFreeUsageTabClick(){
 
        this.formTabs = {

            information:{
                information: true,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: true,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:false,
                mapping: false,
                
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:false,
            // contentMapping:false,
            // freeUsage:true,
            // subscriptionMapping:false,
            // deviceType:false
    
         }

         console.log(">>>formTabs");
      }


      onMappingTabClick(){

        this.formTabs = {


            information:{
                information: false,
                mapGeoZone:false,
                info:false,
                certificationstream:false,
                concurrency:false,
                usage: false,
                promovoucher:false
            },
            deviceType:{
                configuration: false,
                configurationType: false,
                horizontalImage: false,
                verticalImage: false,
            },
            subscriptionMapping:{
                mappingInfo:true,
                mapping: true,
                
            },
            contentMapping: false
            // mapGeoZone:false,
            // subscriptionInfo:false,
            // contentMapping:false,
            // freeUsage:false,
            // subscriptionMapping:true,
            // deviceType:false
    
         }

      }


    //   onSubscriptionMappingTabClick(){

    //     this.formTabs = {

    //         mapGeoZone:false,
    //         subscriptionInfo:false,
    //         contentMapping:false,
    //         freeUsage:false,
    //         subscriptionMapping:true,
    //         deviceType:false
    
    //      }

    //   }


      onEndDate(){

          console.log("this. packageStartDate",this.createSubscriptionForm.value.packageStartDate);
          console.log("this. packageEndDate",this.createSubscriptionForm.value.packageEndDate);

          console.log("123",moment(this.createSubscriptionForm.value.packageStartDate).isSameOrAfter(moment(this.createSubscriptionForm.value.packageEndDate)));

      }

      onSaveSubscripton(){

        //   this.loader = true;
        this.commonService.changeloader(true);

          this.createSubscriptionFormSubmit = true;
          this.createSubscriptionForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
          this.createSubscriptionForm.controls['mapDeviceTypeRateKeys'].setValue(this.variableForm.value.items);
          console.log("variableForm>>",this.variableForm);

          if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }

          if(this.deviceTypeDublicate){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }

          if(this.multipleDeviceForm.value.devices.length == 0){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }

          if(!this.multipleDeviceForm.valid){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }



          if(!this.variableForm.valid){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }

          if(!this.createSubscriptionForm.valid){
            // this.loader=false;
            this.commonService.changeloader(false);
            return;
          }
          console.log("1234567>>",this.createSubscriptionForm.value);

         if(!this.update){

            delete this.createSubscriptionForm.value.id;
           
            this.subscriptionService.insertSubscription(this.createSubscriptionForm.value)
            .subscribe((result:any)=>{

                // this.loader = false;
                this.commonService.changeloader(false);

               this.toastrService.success('Subscription Created Successfully.');
            //    this.router.navigate(['subscription/list']);
            this.update = false;
            this.updateSend.emit();
            this.resetForm();
            
            this.createSubscriptionFormSubmit = false;
  
            })
         }

         if(this.update){

            this.createSubscriptionForm.controls['id'].setValue(this.id);
            this.subscriptionService.updateSubscription(this.createSubscriptionForm.value)
            .subscribe((result:any)=>{
                
                // this.loader = false;
                this.commonService.changeloader(false);

                this.toastrService.success('Subscription Updated Successfully.');
                // this.router.navigate(['subscription/list']);
                this.update = false;
                this.updateSend.emit();
                this.resetForm();
                
                this.createSubscriptionFormSubmit = false;
              
            })
         }
  
      }

      getSubscription(id){

        // this.loader = true;
        this.commonService.changeloader(true);

        this.subscriptionService.getSubscription(id)
        .subscribe((result:any)=>{

            // this.loader = false;
            this.commonService.changeloader(false);

            console.log("result>>>",result);
            this.createSubscriptionForm.controls['exclusionText'].setValue(result.data[0].exclusionText);

            if(result.data[0].packageEndDate!= null){
                var  packageEndDate = result.data[0].packageEndDate.replace('T00:00:00.000Z', ""); 
            }
           
            if(result.data[0].packageStartDate!= null){
                var packageStartDate = result.data[0].packageStartDate.replace('T00:00:00.000Z', ""); 
            }
            
            this.createSubscriptionForm.controls['packageEndDate'].setValue(packageEndDate);
            this.createSubscriptionForm.controls['packageStartDate'].setValue(packageStartDate);
            this.createSubscriptionForm.controls['lifetimeFree'].setValue( result.data[0].lifetimeFree);
            this.createSubscriptionForm.controls['minutes'].setValue(result.data[0].minutes);
            this.createSubscriptionForm.controls['hits'].setValue(result.data[0].hits);
            this.createSubscriptionForm.controls['validityDays'].setValue(result.data[0].validityDays);

            this.createSubscriptionForm.controls['discountCode'].setValue(result.data[0].discountCode);
            this.createSubscriptionForm.controls['discountPercentage'].setValue(result.data[0].discountPercentage);
            this.createSubscriptionForm.controls['discountAmount'].setValue(result.data[0].discountAmount);
            this.createSubscriptionForm.controls['discountUsageLimit'].setValue(result.data[0].discountUsageLimit);

            this.createSubscriptionForm.controls['viewCountLimit'].setValue(result.data[0].viewCountLimit);
            this.createSubscriptionForm.controls['rate'].setValue(result.data[0].rate);
            this.createSubscriptionForm.controls['title'].setValue(result.data[0].title);
            this.createSubscriptionForm.controls['totalDeviceSupported'].setValue(result.data[0].totalDeviceSupported);
            this.createSubscriptionForm.controls['concurrentUser'].setValue(result.data[0].concurrentUser);
            if(result.data[0].subscriptionType!=undefined)
            this.createSubscriptionForm.controls['subscriptionType'].setValue(result.data[0].subscriptionType);
            if(result.data[0].subscriptionFrequency!=undefined)
            this.createSubscriptionForm.controls['subscriptionFrequency'].setValue(result.data[0].subscriptionFrequency);

            this.createSubscriptionForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createSubscriptionForm.controls['reviewerState'].setValue(result.data[0].reviewerState);


            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createSubscriptionForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createSubscriptionForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            this.deviceTypeFull = false;
            this.all = false;

            if(result.data[0].results.productDeviceTypeConfigure.length>0){

                if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                    this.deviceTypeFull = true;
                }

                this.multipleDeviceForm = this.formBuilder.group({
                    devices: this.formBuilder.array([])
                });

                this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                
                for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                    this.devices.push(this.updateDevice(result.data[0].results.productDeviceTypeConfigure[i]));
                   
                }
 
            }

            let arrayObj=[];
            if(result.data[0].results.productType.length>0){

                for(let i = 0 ;i < result.data[0].results.productType.length;i++){
                    let obj={
                    'id':result.data[0].results.productType[i].productTypeId,
                    'itemName':result.data[0].results.productType[i].productTypeName,
                    }
                    arrayObj.push(obj);  

                }
             
                this.createSubscriptionForm.controls['mapProductType'].setValue(arrayObj);
            }

            arrayObj=[];
            if( result.data[0].results.geoZone.length>0){

                if(result.data[0].results.geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){
                        let obj={
                        'id':result.data[0].results.geoZone[i].geoZoneId,
                        'itemName':result.data[0].results.geoZone[i].geoZoneName,
                        }
                       arrayObj.push(obj);  

                    }
                }
                
                this.createSubscriptionForm.controls['mapGeoZone'].setValue(arrayObj);

            }

            arrayObj=[];
            
            if( result.data[0].results.primaryNavigation.length>0 ) { 

                    for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                        
                        let obj= {
                            'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                            'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                        }
                        
                        arrayObj.push(obj);  
                    }
                
                
                this.createSubscriptionForm.controls['mapPrimaryNavigation'].setValue(arrayObj);

            }

            arrayObj=[];
            if(result.data[0].results.menuCategory.length>0){
                
                for(let i = 0 ;i < result.data[0].results.menuCategory.length;i++){
                    let obj={
                        'id':result.data[0].results.menuCategory[i].menuCategoryId,
                        'itemName':result.data[0].results.menuCategory[i].menuCategoryName,
                    }
                   arrayObj.push(obj);  
                }
                    
                this.createSubscriptionForm.controls['mapMenuCategory'].setValue(arrayObj);
            };

            arrayObj=[];
            if(result.data[0].results.assetGroup.length>0){
                
                for(let i = 0 ;i < result.data[0].results.assetGroup.length;i++){
                    let obj={
                        'id':result.data[0].results.assetGroup[i].assetGroupId,
                        'itemName':result.data[0].results.assetGroup[i].assetGroupName,
                    }
                    arrayObj.push(obj);  
                }
                    
                this.createSubscriptionForm.controls['mapAssetGroup'].setValue(arrayObj);
            }

            arrayObj=[];
            if(result.data[0].results.asset.length>0){
                
                for(let i = 0 ;i < result.data[0].results.asset.length;i++){
                    let obj={
                        'id':result.data[0].results.asset[i].assetId,
                        'itemName':result.data[0].results.asset[i].assetName,
                    }
                    arrayObj.push(obj);  
                }
                    
                this.createSubscriptionForm.controls['mapAsset'].setValue(arrayObj);
            }

            arrayObj=[];
            if(result.data[0].results.assetCategory.length>0){
                
                for(let i = 0 ;i < result.data[0].results.assetCategory.length;i++){
                    let obj={
                        'id':result.data[0].results.assetCategory[i].assetCategoryId,
                        'itemName':result.data[0].results.assetCategory[i].assetCategoryName,
                    }
                    arrayObj.push(obj);  
                }
                    
                this.createSubscriptionForm.controls['mapAssetCategory'].setValue(arrayObj);
            }

            if(result.data[0].results.deviceTypeRateKeys.length>0){

                this.variableForm = this.formBuilder.group({
                    items: this.formBuilder.array([])
                });

                this.items = this.variableForm.get('items') as FormArray;
                
                for(let i=0;i< result.data[0].results.deviceTypeRateKeys.length;i++){

                    this.items.push(this.updateitem(result.data[0].results.deviceTypeRateKeys[i]));
                   
                }
 
            }

        })

      }

      get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
      }
      get v(): FormArray {
        return this.variableForm.get('items') as FormArray;
      }
      get l() { return this.createSubscriptionForm.controls; }



      //Pravin code mulitple from
      createDevice(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.compose([Validators.required])],
            description: '',
            dtypeId:['',Validators.required], 
        });
      }
      //pravin code start
      updateDevice(objProductDeviceTypeConfigure): FormGroup { 


        if(objProductDeviceTypeConfigure.dtypeId === 4){
            this.all = true;
        }

        return this.formBuilder.group({

            label: [objProductDeviceTypeConfigure.label,Validators.compose([Validators.required])],
            description: objProductDeviceTypeConfigure.description,
            dtypeId:[objProductDeviceTypeConfigure.dtypeId,Validators.required], 

        });
      
      }

      addDevice(): void {

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createDevice());
        }

      }

    
      createItem(): FormGroup {

          return this.formBuilder.group({
            variableKey: ['',Validators.required],
            variableValue:  ['',Validators.required],
          });

      }


      delInputItem(index){
          const arrayControl = this.variableForm.get('items') as FormArray;
          arrayControl.removeAt(index);
          arrayControl.length == 0
      }

      updateitem(objProductDeviceTypeConfigure): FormGroup { 

        return this.formBuilder.group({

            variableKey: [objProductDeviceTypeConfigure.variableKey, Validators.required],
            variableValue: [objProductDeviceTypeConfigure.variableValue,Validators.required],
            
        });
      
      }

    // global listing info dropdown :pravin

      getGlobaltrailervideo(){
            
        this.commonService.getGlobalTrailerVideo({},0,10000000).subscribe((result:any)=>{
            
            this.assetList = [];
            
            if(result.data.data.length > 0){       
                for (let trailerVideoLists of result.data.data) {

                    if(trailerVideoLists.productTypeName!=''){
                    
                        this.assetList.push(
                        {
                            'id': trailerVideoLists.assetId,
                            'itemName': trailerVideoLists.assetName
                        });
                    } 
                }
            } 

        });
        
      }


      
        editCreate:boolean = false;
        onCreateNew(){

                if(this.roleAsses.subscriptionCreate == true){

                    this.editCreate = true;
                }
                
                this.isCreateNewShow=false

        }
    //New Code for right side for from
    onContentReadyToReview(){

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader=false;
            this.commonService.changeloader(false);
            this.createSubscriptionFormSubmit = true;
            return;
          }


          if(this.multipleDeviceForm.value.devices.length == 0){
            // this.loader=false;
            this.commonService.changeloader(false);
            this.createSubscriptionFormSubmit = true;
            return;
          }

          if(!this.multipleDeviceForm.valid){
            // this.loader=false;
            this.commonService.changeloader(false);
            this.createSubscriptionFormSubmit = true;
            return;
          }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

       // is Content Ready To Review Received  

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createSubscriptionForm.controls['contentState'].setValue(['Completed']);
            this.onSaveSubscripton();
            this.onMapGeoZoneInfoTabClick();
           
        }
        if(event == 'Cancel'){

            this.createSubscriptionForm.controls['contentState'].setValue(['In-Progress']);
            this.onSaveSubscripton();
            this.onMapGeoZoneInfoTabClick();

        }
       
    }


      getGlobalMenuCategory(){
          this.commonService.getGlobalMenuCategory({},0,1000).subscribe((result:any)=>{
              
              this.menuCategoryList = [];
              
              if(result.data.data.length > 0){       
                  for (let menuCategoryList of result.data.data) {

                      if(menuCategoryList.menuCategoryName!=''){
                      
                          this.menuCategoryList.push(
                          {
                              'id': menuCategoryList.menuCategoryId,
                              'itemName': menuCategoryList.menuCategoryName
                          });
                      } 
                  }
              } 

          });
      }


      getGlobalAssetGroup(){
          this.commonService.getGlobalAssetGroup({},0,1000).subscribe((result:any)=>{
              
              this.assetGroupList = [];
              
              if(result.data.data.length > 0){       
                  for (let assetGroupList of result.data.data) {

                      if(assetGroupList.assetGroupName!=''){
                      
                          this.assetGroupList.push(
                          {
                              'id': assetGroupList.assetGroupId,
                              'itemName': assetGroupList.assetGroupName
                          });
                      } 
                  }
              } 

          });
      }


      getGeoZoneInfo(){

          let obj={
          }
          
          this.productConfigurationService.getAllGeoZone(obj,this.start,this.limit)
          .subscribe((result:any)=>{
              
              
              this.dropdownList = [];
              this.geoZoneList = [];
              if(result.data.data.length > 0){
                      
                  this.dropdownList = result.data.data;

                  for (let geoZoneObj of this.dropdownList) {

                      this.geoZoneList.push(
                      {
                          'id': geoZoneObj.geoZoneId,
                          'itemName': geoZoneObj.geoZoneName
                      });

                  }
              }
              
              console.log('this.geoZoneList',this.geoZoneList);
          }) 
      }


      getGlobalAssetCategory(){
        this.commonService.getGlobalAssetCategory({},0,1000).subscribe((result:any)=>{

            this.assetCategoryList = []; 
            
            if(result.data.data.length > 0){       
                for (let assetCategoryList of result.data.data) {

                    if(assetCategoryList.assetCategoryName!=''){
                    
                        this.assetCategoryList.push(
                        {
                            'id': assetCategoryList.assetCategoryId,
                            'itemName': assetCategoryList.assetCategoryName
                        });
                    } 
                }
            } 
        });
      }
      
      getNavigationInfo(){
            
        this.commonService.getGlobalPrimaryNavigation({},0,10000).subscribe((result:any)=>{
            
            this.primaryNavigationList = [];
            
            if(result.data.data.length > 0 ){       
            
                for (let objPrimaryNavigation of result.data.data) {
                    
                    if( objPrimaryNavigation.primaryNavigationId != undefined ){
                    
                        this.primaryNavigationList.push(
                        {
                            'id': objPrimaryNavigation.primaryNavigationId,
                            'itemName': objPrimaryNavigation.primanyNavigationName
                        });
                    } 
                }
            } 
        })
      }
      
      getProductTypeInfo(){
        
        this.commonService.getGlobalProductType({},0,10000).subscribe((result:any)=>{

              this.ProducTypeDropdownList = [];
              this.productTypeList = [];
              if(result.data.data.length > 0){       

                  this.ProducTypeDropdownList = result.data.data;
                  for (let ProducTypeLists of this.ProducTypeDropdownList) {

                      if(ProducTypeLists.productTypeName!=''){
                      this.productTypeList.push(
                          {
                              'id': ProducTypeLists.productTypeId,
                              'itemName': ProducTypeLists.productTypeName
                          });
                      }    
                  }

                  
              } 
          });
        
      }

      onLifelong(){

        if(this.createSubscriptionForm.value.lifetimeFree == '1' ){
            this.createSubscriptionForm.controls['minutes'].setValue('');
            this.createSubscriptionForm.controls['hits'].setValue('');
        }

      }

    onDeSelect(event, info){

        if(info =='mapProductType'){

            this.createSubscriptionForm.controls['mapProductType'].setValue([]);
        }else if (info =='mapPrimaryNavigation'){

            this.createSubscriptionForm.controls['mapPrimaryNavigation'].setValue([]);
        }else if (info =='mapMenuCategory'){

            this.createSubscriptionForm.controls['mapMenuCategory'].setValue([]);
        }else if (info =='mapAssetGroup'){

            this.createSubscriptionForm.controls['mapAssetGroup'].setValue([]);
        }else if (info =='mapAssetCategory'){

            this.createSubscriptionForm.controls['mapAssetCategory'].setValue([]);
        }else if (info =='mapAsset'){
            
            this.createSubscriptionForm.controls['mapAsset'].setValue([]);
        }else if (info =='mapGeoZone'){
            
            this.createSubscriptionForm.controls['mapGeoZone'].setValue([]);
        }
    
    }


    addItem(): void {
        
        this.items = this.variableForm.get('items') as FormArray;
        this.items.push(this.createItem());

        // let devices = this.devices
        // var all = 0;
        // var Web = 0;
        // var android = 0;
        // var iOS = 0;
        // var roku = 0;
        // this.all = false;
        // this.roku = false;
        // this.Web = false;
        // this.android = false;
        // this.iOS = false;
    
        // for(let i = 0;i< devices.value.length;i++){

        //     if(devices.value[i].dtypeId=='1'){
        //         Web++;
        //         this.Web=true;
        //     }
            
        //     if(devices.value[i].dtypeId=='2'){
        //         android++;
        //         this.android=true;
        //     }
            
        //     if(devices.value[i].dtypeId=='3'){
        //         iOS++;
        //         this.iOS=true;
        //     }

        //     if(devices.value[i].dtypeId=='5'){
        //         roku++;
        //         this.roku=true;
        //     }
            
        //     if(devices.value[i].dtypeId=='4'){
        //         all++;
        //         this.all=true;
        //     }else{
                
        //     }
            
        // }
        
        // this.deviceTypeDublicate = false;
        // this.deviceTypeFull = false;
        
        // if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        // this.deviceTypeFull = true ;

        // }else if(all == 1 || this.all == true ){
            
        //     if(all == 1 && devices.value.length>1){

        //     }

        // } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

        //     this.deviceTypeDublicate = true;

        // }else {

        //     this.devices.push(this.createItem());
        // }

    }

    onDeviceType(){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);
        

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    isDeleteDeviceType(event){

        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }
    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }

    /* Delete key */
    isDeleteKey(event){

        if(event=='Cancel'){
            this.isDeleteKeyAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteKeyAction=false;
            this.delInputItem(this.deleteKeyIndex);
        }

    }
    isDeleteKeyYes(i){
        this.deleteKeyIndex = i;
    }

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
      
 }
