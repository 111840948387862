import { HttpClient, HttpHeaders} from '@angular/common/http';
// import {Headers} from '@angular/http'
    
export const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
  };

export const httpOptionsImage = {
    headers: new HttpHeaders({
        'Accept': 'application/json'
      })
  };


    // if(value == one) {
                    //     return '#8892D6'
                    //        }
                    //     if(value == two) {
                    //       return '#F06292'
                    //       }
                    //     if(value ==  three) {
                    //       return '#45BBE0'
                    //     }
                    //     if(value == 43) {
                    //        return '#78C350'
                    //       }
                    export const color=[
                      '#8892D6',
                      '#45BBE0',
                      '#F06292',
                      '#78C350',
                      '#5BCEAE',
                      '#7C8AEA',
                      '#C591F8',
                      '#F86CCD',
                      '#F86C7C',
                      '#FD7D5E',
                      '#FDB45E',
                      '#B5D647',
                      '#FDD25E',
                      '#5595EA',
                   
                   ]

                  export const labels=[
                            'Trial',
                            'Daily',
                            'Monthly',
                            'Yearly',
                            'android',
                            'ios',
                            'roko',
                            'website',
                            'firestick',
                            'android_tv',
                            'lg',
                            'samsung', 
                            'sony',
                            'ps4',
                            'xbox',
                            'apple_tv',
                  ]

                  export const pieChatColors =[
                                              
                          '#78C350',
                          '#5BCEAE',
                          '#7C8AEA',
                          '#45BBE0',
                          '#C591F8',
                          '#F86CCD',
                          '#F86C7C',
                          '#FD7D5E',
                          '#FDB45E',
                          '#B5D647',
                          '#FDD25E',
                          '#5595EA',
                          '#8892D6',
                          '#F06292',
                          '#45BBE0',
                  ]

                  export const pieChatClass =[
       
                                        'android',
                                        'ios',
                                        'roko',
                                        'website',
                                        'firestick',
                                        'android_tv',
                                        'lg',
                                        'samsung', 
                                        'sony',
                                        'ps4',
                                        'xbox',
                                        'apple_tv',
                                        'Trial',
                                        'Monthly',
                                        'Daily'
                    
                                      ]
  
  export const emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  export const usernamePattern = '[A-Za-z ]+';
  export const displayIndexingPattern='[0-9]+';
  export const urlPattern = 'https?://.+';
  export const numberOnlyPattern='[0-9]+';
  export const globalVaraibleCount = 10;
  export const googleAnalyticsUrl='https://datastudio.google.com/u/0/reporting/998e014f-cbc4-412a-8336-ae220cd21b0f/page/6zXD';


//dcafe-bucket

  export const awsConfiguration = {

    'accessKeyId':'AKIASYXB26NJKYQM2ZLR',
    'secretAccessKey':'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',
    'region':'us-west-1',
    'bucketName' : 'dcafe-content'
  }

  // vuuzle bucket
  // export const awsConfiguration = {
  //   'accessKeyId':'AKIAVK4C7M623V5MKNEN',
  //   'secretAccessKey':'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',
  //   'region':'',
  //   'bucketName' : 'vuuzleprod-content'
  // }  
  
  
  export const assetGroupType = {
    'Normal':'Normal',
    'Live':'Live'
    
  }

  export const status = {

    'active':'Active', 
    'notActive': 'Not-Active',
    'blocked':'Blocked'

  }

  export const primaryNavigationType = {
    'Normal':'Normal',
    'Live':'Live',
    'Genre':'Genre',
    'Language':'Language'
  }

  export const contentType = {
    'Live':'Live',
    'VOD':'VOD',
  }

  export const menuCategoryType = {
    'Normal':'Normal',
    'Live':'Live',
  }


  export const orderby = {

    'Alphabetically':'Alphabetically',
    'DisplayIndexing':'Display Indexing',
    'Mostpopular':'Most popular',
    'RecentlyAdded':'Recently Added',
    'TopRated':'Top Rated'

  }

  export const sorting = {

    'Ascending':'Ascending',
   'Descending':'Descending'

  }

  export const displayType = {

    'Vertical':'Vertical',
    'Horizontal':'Horizontal',
    'Listing':'Listing',
    'Custom':'Custom'
  }


 
  export const awsPath={

    'assetCategory':'dcafe-nodejs-content/asset-management/asset-category/',
    'assetGroup':'dcafe-nodejs-content/asset-management/asset-group/',
    'asset':'dcafe-nodejs-content/asset-management/asset/',

    'productConfiguration':'dcafe-nodejs-content/configuration/product-configuration/',
    'productType':'dcafe-nodejs-content/configuration/product-type/',

    'cast':'dcafe-nodejs-content/master/cast/',
    'geoZone':'dcafe-nodejs-content/master/geo-zone/',
    'subscription':'dcafe-nodejs-content/master/subscription/',

    'menuCategory':'dcafe-nodejs-content/navigation/subscription/',
    'primaryNavigation':'dcafe-nodejs-content/navigation/primary-navigation/'

  }

  
  // export const deviceType = {

  //   'all':'4',
  //   'web':'1',
  //   'android':'2',
  //   'ios':'3',
  //   'roku':'5'

  // }

  export const deviceType = [
      {
        'key':'4',
        'value':'All',
        "active":false
      },
      {
        'key':'1',
        'value':'Web',
        "active":false
      },
      {
        'key':'2',
        'value':'Android',
        "active":false
      },
      {
        'key':'3',
        'value':'iOS',
        "active":false
      },
      {
        'key':'5',
        'value':'Roku',
        "active":false
      }
  ]
