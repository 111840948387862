import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CasteAndCrewService } from '../../../../../services/caste_and_crew/caste_and_crew.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import {awsPath, usernamePattern} from '../../../../../configurations/configuration';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {UserService} from '../../../../../services/user/user.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-addCastAndCrew',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentCastAndCrew implements OnInit {

  isCreateNewShow:boolean = true;

  isUserLoggedIn: boolean = false;
  createCastNCrewForm:FormGroup;
  errorMessages = errorMessages;
  createCastNCrewSubmitted:boolean = false;
  assetGroupList=[];
  professionList = [];
  shareMassage:any;

  called:boolean =false;

  verticalPosterImageUrl:any;
  horizontalPosterImageUrl:any;

  uploadImgFilePath:any=[];
  img:any=[];

  mapCastSettings:any={};
  mapProfessionSettings:any={};

  id:any;
  update:boolean = false;
  loader:boolean = false;

  userList:any=[];

  isDeleteHorizontalImageAction:boolean=false;
  isDeleteVerticalImageAction:boolean=false;

  contentReadyToReview:boolean = false;

    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code start
    @Input() set contentId(value: any) {

        if(value == 'reset'){

            this.resetForm();

        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end
    
    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    applyBlackTheme = localStorage.getItem('boolThemeFlag');
    
    constructor( private userService:UserService,private commonService:CommonService, private toastrService: ToastrService, private productConfigurationService: ProductConfigurationService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private casteAndCrewService: CasteAndCrewService,private formBuilder:FormBuilder) {
        loginSharedService.sendUserLoggedIn$.subscribe(
        mission => {
            this.isUserLoggedIn = mission;
            let uid = localStorage.getItem('ud');
            if (uid !== undefined && uid != null) {
                this.isUserLoggedIn = true;
            }
        }
        );
    }

    roleAsses:any={};
    roleConfiguration:any=[];

    
  editCreate:boolean = false;
  onCreateNew(){

        if(this.roleAsses.castAndCrewCreate == true){

            this.editCreate = true;
        }
        
        this.isCreateNewShow=false

  }
  ngOnInit(){

        if(this.id != 'null' && this.roleAsses.castAndCrewCreate == true){
            this.editCreate = true;
        }else{
            this.editCreate = false;
        }
    
    this.roleAsses.castAndCrewEdit = false;
    this.roleAsses.castAndCrew = false;
    this.roleAsses.castAndCrewCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {

          if((element.isView == 1 && element.pageTitle == 'Cast And Crew') ){
              
              this.roleAsses.castAndCrew = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Cast And Crew') ){
              
              this.roleAsses.castAndCrewEdit = true;
          }

          
          if((element.isCreate == 1 && element.pageTitle == 'Cast And Crew')){

              this.roleAsses.castAndCrewCreate = true;
          
          }
        })
        

      // theme update code start
      this.commonService.currentThemelight.subscribe((result:any)=>{
          
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
     })
    // theme update code start
  }

  onLoad(id) {

    this.getAllUser({},0,10000000000);


    this.mapCastSettings = {

        singleSelection: false, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"

    }

    this.mapProfessionSettings = {

        singleSelection: true, 
        text:"Select",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"

    }

      this.getGlobalProfession();
    let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

    let headerTitle = {
        title:'Master',
        subTitle:'Cast and Crew'
    }

    this.commonService.changeHeaderTitle(headerTitle);

        this.createCastNCrewForm = this.formBuilder.group({
        
            firstName: ['', Validators.compose([Validators.required])],
            lastName: [''],
            //firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
            //lastName:['', Validators.pattern(usernamePattern)],
            shortDescription:[''],
            description:[''],
            isActive:['1'],
            verticalFileName:[''],
            verticalFilePath:[''],
            verticalFileType:[''],
            horizontalFileName:[''],
            horizontalFilePath:[''],
            horizontalFileType:[''],
            professionType:[[]],
            user:[[]],
            id:[''],
            userId:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')],
        });


        // this.activatedRoute.params.subscribe(params => {
        //   this.id = +params['id']; // (+) converts string 'id' to a number
          this.id = id; // (+) converts string 'id' to a number
          
          
          if(this.id != 'null'){
          
            console.log('this.id===>>>',this.id);
          
              this.update=true;
              this.getCastNCrewInfo(this.id)
          }else{
              
            this.update=false;
          
        
          }

    //   });
  }

  
  getAllUser(obj ,start ,limit){

    //this.spinnerService.show();

    let objs = {
            'roleId':'6'
        };

    this.loader=true;

    this.userService.getAllUser(objs,start,limit)
    .subscribe((result:any)=>{

        this.loader = false;

        this.userList = [];

        console.log('result===>',result.data.data);

        if(result.data.data.length > 0){

            for (let userList of result.data.data) {
   
                if(userList.id!=''){
                
                    this.userList.push(
                    {
                        'id': userList.id,
                        'itemName': userList.firstName+ ' '+userList.lastName
                    });
                } 
            }
        }

    }) 

  }
  get l() { return this.createCastNCrewForm.controls; }

  saveCastNCrew(){
      this.createCastNCrewSubmitted = true;
  }

  onItemSelect(a,b){

  }

  OnItemDeSelect(a,b){

  }

  onSelectAll(a,b){

  }

  onDeSelectAll(a,b){

  }

    getCastNCrewInfo(id){
            
        // this.loader=true;
        this.commonService.changeloader(true);

        this.casteAndCrewService.getCast(id)
        .subscribe((result:any)=>{

            // this.loader=false;
            this.commonService.changeloader(false);

            console.log("result==========>",result);
            
            this.createCastNCrewForm.controls['firstName'].setValue(result.data[0].firstName);
            this.createCastNCrewForm.controls['lastName'].setValue(result.data[0].lastName);
            this.createCastNCrewForm.controls['shortDescription'].setValue(result.data[0].shortDescription);
            this.createCastNCrewForm.controls['description'].setValue(result.data[0].description);
            this.createCastNCrewForm.controls['isActive'].setValue(result.data[0].isActive.toString());


            this.createCastNCrewForm.controls['verticalFileName'].setValue(result.data[0].verticalFileName);
            this.createCastNCrewForm.controls['verticalFilePath'].setValue(result.data[0].verticalFilePath);
            this.createCastNCrewForm.controls['verticalFileType'].setValue(result.data[0].verticalFileType);

            this.createCastNCrewForm.controls['horizontalFileName'].setValue(result.data[0].horizontalFileName);
            this.createCastNCrewForm.controls['horizontalFilePath'].setValue(result.data[0].horizontalFilePath);
            this.createCastNCrewForm.controls['horizontalFileType'].setValue(result.data[0].horizontalFileType);

            this.createCastNCrewForm.controls['publisherState'].setValue(result.data[0].publisherState);
            this.createCastNCrewForm.controls['reviewerState'].setValue(result.data[0].reviewerState);


            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createCastNCrewForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createCastNCrewForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            this.verticalPosterImageUrl=result.data[0].verticalFilePath;
            this.horizontalPosterImageUrl=result.data[0].horizontalFilePath;

            setTimeout(()=>{

                this.professionList.forEach((element)=>{

                          if( element.id == result.data[0].professionTypeId){
    
                         this.createCastNCrewForm.controls['professionType'].setValue([element]);
    
                          }
                })

            },1000)

            
            setTimeout(()=>{

                this.userList.forEach((element)=>{

                    console.log("element.id",element.id);
                    console.log("result.data[0].userId",result.data[0].userId);

                          if( element.id == result.data[0].userId){
    
                              this.createCastNCrewForm.controls['user'].setValue([element]);
    
                          }
                })

            },1000)


            console.log("result==========>",result.data[0].isActive.toString());
        
        });
    }

    selectFileHorizontal(e ){

        this.loader = true;

        this.uploadImgFilePath=[];
  
        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }
  
        var mimeType = e.target.files[0].type;
  
        if (mimeType.match(/image\/*/) == null) {
  
            console.log("Only images are supported.");
            //this.errorImage = 'Only images are supported.';
            this.horizontalPosterImageUrl = '';
          
            return;
        }

  
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            
            this.horizontalPosterImageUrl=reader.result;
            
        }

        this.uploadImgFilePath.push(obj);  
  
        if(this.uploadImgFilePath.length>0){
  
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
  
                    if (res && res.Location) {

                        this.loader = false;
                        this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].fileName);
                        this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                        this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].fileType); 

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }
  
        }

        e.target.value = '';
        
    }

    selectFileVertical(e) {

        this.loader = true;

        this.uploadImgFilePath=[];
  
        let obj ={
          
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }
  
        var mimeType = e.target.files[0].type;
  
        if (mimeType.match(/image\/*/) == null) {
  
            console.log("Only images are supported.");
            //this.errorImage = 'Only images are supported.';
            this.verticalPosterImageUrl = '';
          
            return;
        }

  
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            
            this.verticalPosterImageUrl=reader.result;
            
        }

        this.uploadImgFilePath.push(obj);  
  
        if(this.uploadImgFilePath.length>0){
  
            for (let i = 0; i < this.uploadImgFilePath.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
  
                    if (res && res.Location) {

                        this.loader = false;
                        this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                        this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                        this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType); 

                        this.uploadImgFilePath = [];
                        
                    }          
                })
            }
  
        }

        e.target.value = '';

    }

    savecreateNewCastNCrew(){

        console.log('update',this.id);
        console.log("Record received",this.createCastNCrewForm);

        // this.loader = true;
        this.commonService.changeloader(true);

        this.createCastNCrewSubmitted = true;

        if(!this.createCastNCrewForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            return;
        }

        if(this.update){

            this.createCastNCrewForm.controls['id'].setValue(this.id);

            if(this.roleAsses.castAndCrewEdit == false){

                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                return;
            }

                if(this.uploadImgFilePath.length>0){
                
                let async = this.uploadImgFilePath.length;

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
        
                        if (res && res.Location) {

                            async -- ;

                            console.log("async>>>",async);
            
                            //   this.uploadImgFilePath[i].FilePath=res.Location;  
                            if(this.uploadImgFilePath[i].imgtype == 'horizontal'){
        
                                this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].horizontalFileName);
                                this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                                this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].horizontalFileType);
                                console.log("update>>>",this.createCastNCrewForm.value);
        
                            }

                            if(this.uploadImgFilePath[i].imgtype == 'vertical'){

                                this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                                this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                                this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType);
                                console.log("update>>1",this.createCastNCrewForm.value);

                            }

                            console.log("async>>1",async);

                            if(async == 0){

                    
                                // this.postCallCastAndcrew();

                                //New Code for right side for from
                                this.onContentReadyToReview();
                            }

                           }
                
                    })
                    
                }

                }

                if(this.uploadImgFilePath.length == 0){

                    // this.postCallCastAndcrew();
                    //New Code for right side for from
                    this.onContentReadyToReview();

                }
        }

        if(!this.update){

            if(this.roleAsses.castAndCrewCreate == false){
                
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

                if(this.uploadImgFilePath.length>0){

                let async = this.uploadImgFilePath.length;
        
                    for (let i = 0; i < this.uploadImgFilePath.length; i++) {
                     
                        this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {
                        
                            if (res && res.Location) {
                                async -- ;
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                //   this.uploadImgFilePath[i].FilePath=res.Location;  
                                if(this.uploadImgFilePath[i].imgtype == 'horizontal'){
            
                                    this.createCastNCrewForm.controls['horizontalFileName'].setValue(this.uploadImgFilePath[i].horizontalFileName);
                                    this.createCastNCrewForm.controls['horizontalFilePath'].setValue(res.Location);
                                    this.createCastNCrewForm.controls['horizontalFileType'].setValue(this.uploadImgFilePath[i].horizontalFileType);
                                    
                                }

                                if(this.uploadImgFilePath[i].imgtype == 'vertical'){

                                    this.createCastNCrewForm.controls['verticalFileName'].setValue(this.uploadImgFilePath[i].verticalFileName);
                                    this.createCastNCrewForm.controls['verticalFilePath'].setValue(res.Location);
                                    this.createCastNCrewForm.controls['verticalFileType'].setValue(this.uploadImgFilePath[i].verticalFileType);
                                

                                    // if(async == 0){

                                    //     this.postCallCastAndcrew();
                                    // }

                                }

                                    if(async == 0){

                                        // this.postCallCastAndcrew();

                                        //New Code for right side for from
                                        this.onContentReadyToReview();
                                    }             

                        }
                        
                        })
                    }

                
                }

                if(this.uploadImgFilePath.length == 0){

                    console.log("upload file equal to one");
                    // this.postCallCastAndcrew();

                    //New Code for right side for from
                    this.onContentReadyToReview();
                }
        }


    }

    postCallCastAndcrew(){

        if(this.createCastNCrewForm.value.user[0] && this.createCastNCrewForm.value.user[0].id!=undefined)
        this.createCastNCrewForm.controls['userId'].setValue((this.createCastNCrewForm.value.user[0].id));
        else
        this.createCastNCrewForm.controls['userId'].setValue(null);

        delete this.createCastNCrewForm.value.user;  // or delete person["age"];
        delete this.createCastNCrewForm.value.id;

        let value = this.createCastNCrewForm.value;
        delete value.user;  // or delete person["age"];

        

        if(!this.update){

            // this.createCastNCrewForm.controls['userId'].setValue((this.createCastNCrewForm.value.user[0].id).toString());
            // if(this.createCastNCrewForm.value.user[0])
            // this.createCastNCrewForm.controls['userId'].setValue((this.createCastNCrewForm.value.user[0].id).toString());
            // else
            // this.createCastNCrewForm.controls['userId'].setValue(null);

            // delete this.createCastNCrewForm.value.user;  // or delete person["age"];
            // delete this.createCastNCrewForm.value.id;

            // let value = this.createCastNCrewForm.value;
            // delete value.user;  // or delete person["age"];

            setTimeout(()=>{    //<<<---    using ()=> syntax

            this.casteAndCrewService.insertCast(value)
            .subscribe((result:any)=>{
                // this.spinnerService.hide();
                // this.router.navigate(['master/cast_and_crew/list']);
                 //New Code for right side for from
                 this.update = false;
              //  this.updateSend.emit();
                 this.resetForm();
                 this.createCastNCrewSubmitted = false;
                this.toastrService.success('Cast Created Successfully.');
            });
        })
        }

        if(this.update){

            // console.log("value>>>>",this.createCastNCrewForm.value.user[0]);
            // let value = this.createCastNCrewForm.value;

            // if(this.createCastNCrewForm.value.user[0].id!=undefined){

            //     this.createCastNCrewForm.controls['userId'].setValue((this.createCastNCrewForm.value.user[0].id).toString());
            // }
            // else{

            //     this.createCastNCrewForm.controls['userId'].setValue(undefined);
            //     delete value.userId; 
            // }

            delete this.createCastNCrewForm.value.user;  // or delete person["age"];
            
            this.createCastNCrewForm.controls['id'].setValue(this.id);

       
            delete value.user;  // or delete person["age"];
            value.id = this.id;
            console.log("value>>>>",value);


            // setTimeout(()=>{    //<<<---    using ()=> syntax

                this.casteAndCrewService.updateCast(value)
                .subscribe((result:any)=>{
    
                    console.log("this.createCastNCrewForm.value>>>>",this.createCastNCrewForm.value);
                     this.update = false;
                     //this.updateSend.emit();
                     this.resetForm();
                     this.createCastNCrewSubmitted = false;
    
                    this.toastrService.success('Cast Updated Successfully.');
                    // this.spinnerService.hide();
                    
                });
                    //    }, 3000); 
            
        }
    }

    getGlobalProfession(){
    
        this.commonService.getGlobalProfession({},0,10000).subscribe((result:any)=>{
            
            this.professionList = [];
            
            if(result.data.data.length > 0){
                   
                for (let professionList of result.data.data) {
   
                    if(professionList.professionId!=''){
                    
                        this.professionList.push(
                        {
                            'id': professionList.professionId,
                            'itemName': professionList.professionName
                        });
                    } 
                }
            } 

        });
        
    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteHorizontalImageAction=false;
            
            // delete this.uploadImgFilePath;
            this.horizontalPosterImageUrl ='';
            this.createCastNCrewForm.controls['horizontalFileName'].setValue(['']);
            this.createCastNCrewForm.controls['horizontalFilePath'].setValue(['']);
            this.createCastNCrewForm.controls['horizontalFileType'].setValue(['']);
        }

    }

    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){

            this.verticalPosterImageUrl = '';
            this.isDeleteVerticalImageAction=false;
            this.createCastNCrewForm.controls['verticalFileName'].setValue(['']);
            this.createCastNCrewForm.controls['verticalFilePath'].setValue(['']);
            this.createCastNCrewForm.controls['verticalFileType'].setValue(['']);

            this.uploadImgFilePath

        }

    }

    //New Code for right side for from
    onContentReadyToReview(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createCastNCrewForm.controls['contentState'].setValue(['Completed']);
            this.postCallCastAndcrew();
           
        }
        if(event == 'Cancel'){

            this.createCastNCrewForm.controls['contentState'].setValue(['In-Progress']);
            this.postCallCastAndcrew();
            
        }
       
    }

    
    resetForm(){

        this.updateSend.emit();

        this.uploadImgFilePath = [];
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {
        //     arrayControl.removeAt(0);
        // }

        // console.log("this.formTabs >>>>",this.formTabs);
        
        this.createCastNCrewForm = this.formBuilder.group({
        
            firstName: ['', Validators.compose([Validators.required])],
            lastName: [''],
            //firstName: ['', Validators.compose([Validators.pattern(usernamePattern), Validators.required])],
            //lastName:['', Validators.pattern(usernamePattern)],
            shortDescription:[''],
            description:[''],
            isActive:['1'],
            userId:[''],
            user:[[]],
            verticalFileName:[''],
            verticalFilePath:[''],
            verticalFileType:[''],
            horizontalFileName:[''],
            horizontalFilePath:[''],
            horizontalFileType:[''],
            professionType:[[]],
            id:[''],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved']

        });
        

        this.update = false;

        //////////////////////

        // this.img = [];
        this.horizontalPosterImageUrl = '';
        this.verticalPosterImageUrl = '';
       
        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });

        this.showCreateNewButton('show');
    
    }

   showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}