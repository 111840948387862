import { Component, OnInit,Input,EventEmitter,Output} from '@angular/core';
import {CommonService} from '../../../services/common_Service/common.service';

@Component({
  selector: 'app-language-box',
  templateUrl: './languageselection-box.component.html',
  styleUrls: ['./languageselection-box.component.scss']
})
export class LanguageselectionBoxComponent implements OnInit {

    @Output() isLanguageActionSend = new EventEmitter<any>();
    selectedFlag:boolean = false;
    
    languageList:any = [];

    loader:boolean = false;

    @Input() shareMassage;

    constructor(private commonService:CommonService,) { }

    ngOnInit() {

      // localStorage.removeItem("languageId");
      this.getLanguageInfo();
      
    }

    onAction(event) {

        this.isLanguageActionSend.emit(event);  

    }

    getLanguageInfo(){

      this.loader = true;

           this.commonService.getGlobalLanguage({},0,10000).subscribe((result:any)=>{
            
            this.languageList = [];
            
                if(result.data.data.length > 0){  

                    result.data.data.forEach(element => {

                        element.selected = false;

                        element.languageName = element.languageName.toUpperCase();

                        if((localStorage.getItem("languageId")) != null){
                              
                              if(parseInt(localStorage.getItem("languageId")) == element.languageId){

                                element.selected = true;
                                this.selectedFlag = true;
                                // this.commonService.changeThemeLanguage(true);

                              }
                        }

                        this.languageList.push(element);
                        
                    });

                }

                if(this.languageList.length>0){

                  this.loader = false;

                  if(this.selectedFlag == false){

                    console.log("selectedFlag>>",this.selectedFlag);

                    this.languageList.forEach(element => {

                      element.selected = false;

                            if(element.languageId == 1){

                              element.selected = true;
                              this.selectedFlag = true;

                            }
                      
                    })
                  }
                }

            })
    }
                      
    onSubmit(){

      this.loader = true;

        this.languageList.forEach(element => {

            if(element.selected == true){

                localStorage.setItem("languageId",element.languageId);
                localStorage.setItem("languageName",element.languageName);
                
                if( localStorage.getItem('langItem') == null){

                  this.commonService.changeThemeLanguage(true);
                  this.isLanguageActionSend.emit(event); 


                }else {
                  this.commonService.changeThemelight('');
                  this.commonService.changeThemeLanguage(true);

                  this.isLanguageActionSend.emit(event); 

                }
                

                this.loader = false;


            }
         
       });

    }

    onLanguage(language){

      this.languageList.forEach(element => {

              element.selected = false;

              if(element.languageName == language.languageName){

                  element.selected = true;
               
              }
          
      });
          
    }

  //   setTimeout(()=>{

  //     this.isLanguageActionSend.emit(event); 

  // },800)
}
