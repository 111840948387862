import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {contentType ,assetGroupType ,sorting ,orderby} from '../../../../../configurations/configuration';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import {AssetService} from '../../../../../services/asset/asset.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {awsPath, deviceType} from '../../../../../configurations/configuration';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import {Observable}  from 'rxjs/Observable';
import {SessionHelper} from '../../../../../services/localstorage';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { columnsTotalWidth } from '@swimlane/ngx-datatable/release/utils';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-addAssetGroup',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentAssetGroup implements OnInit {

  results$ = Observable;
  subject = new Subject();

  activeSearchBox ='';
  isCreateNewShow:boolean = true;
  applyBlackTheme = localStorage.getItem('boolThemeFlag');
  deviceType = deviceType;
  createAssetGroupForm:FormGroup;
  contentType=contentType;
  assetGroupType = assetGroupType;
  Orderby = orderby;
  sorting = sorting;
  loader:boolean=false;
  createAssetGroupSubmitted=false;
  errorMessages=errorMessages;
  geoZoneList:any=[];
  mapSponsoreList:any=[];
  dropdownList:any=[];
  primaryNavigationList=[];
  menuCategoryList:any=[];
  mapCertificatList:any=[];
  mapClassificationList:any=[];
  mapReviewList:any=[];
  mapLanguageList:any=[];
  mapCastList:any=[];
  mapLibraryList:any=[];
  selectedmenuCategoryList:any=[];
  multipleDeviceForm:FormGroup;

  sponsoredFiter:any =[];

  mapMenuCategoryArrayTemp:any=[];
  mapPrimaryNavigationArrayTemp:any =[];

  startPrimary:number = 0;
  startLibrary:number = 0;
  startReview:number = 0;
  startNaviagation:number = 0;
  reviewFilter:any ={};

  startGenre:number = 0;
  genreFilter:any ={};
 
  deviceTypeFull:boolean = false;
  deviceTypeDublicate:boolean = false;

  uploadImgFilePathVertical:any=[];
  uploadImgFilePathHorizontal:any=[];
  selectedItems:any=[];
  selectedmapCast:any=[];
  selectedProductlist:any=[];
  selectedprimaryNavigationList=[];
  selectedcertificationList:any=[];
  mapProductTypeArrayTemp:any=[];

  producTypeList:any=[];
  ProducTypeDropdownList:any=[];

  menuCategorySettings = {};

  languageFilter:any={};
  devices: FormArray;

  mapGenreList:any = [];
  mapGenreSettings = {};
  mapCharacterList:any=[];

  start:number = 0;
  
  geoZoneObj:any ={};

  startProduct:number = 0;
  startCategory:number = 0;
  startLanguage:number = 0;

    //pravin code end 
    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    menuCategoryMappingFilter:any={};
    //end mapping filter

    private searchDecouncer$: Subject<string> = new Subject();
  
    libraryFilter:any={};
    certificateFilter:any={};
    classificationFilter:any={};

    uploadImgFilePosterImage:any=[];
    uploadImgFileclosedCaption:any=[];


    all:boolean = false;;
    web:boolean;
    android:boolean;
    iOS:boolean;
    roku:boolean;

    dTypeDublicateError:any;
    uploadImgFilePath:any=[];
    img:any=[];
    id:any;
    update=false;
    
    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;

    langId:any;

    isDeleteHorizontalImageAction:boolean = false;
    isDeleteVerticalImageAction:boolean = false;

    contentReadyToReview:boolean = false;
    shareMassage:any;

    formTabs:any = {

        assetInfo:{

         asset:true,
         mapGeoZone:true,
         information:false,
         HierarchyMapping:false,
         seo:false,
        },
        deviceType:{
            
            configurationType:false,
            configuration:false,
            horizontal:false,
            vertical:false

        },
        assetLiveVod:{
            
            vod:false,
            live:false,
            assetLiveVod:false
        },
        metadata:{

            metadata:false,
            metadataHierarchy:false,
            Information:false,
            castAndCrew:false,
            orderingAndSorting:false

        },
        // ordering:false,
        // otherMapping:false
    }

    //pagination related variable

    startCharacter:number = 0;
    characterScrollCount:number = 0;
    charcterInfoObj:any = {};
    settings:any ={};

    reviewScrollCount:number = 0;
    reviewInfoObj:any = {};

    productScrollCount:number = 0;
    productInfoObj:any = {
        pageCount:-1
    };

    isSearch = false;
    
    onInit:boolean = false;

    classificationScrollCount:number = 0;

    classificationInfoObj:any = {
        pageCount:-1
    };
    startClassification:number = 0;
    startCertificate:number = 0;
    
    SponsoredScrollCount:number = 0;

    SponsoredInfoObj:any = {
        pageCount:-1
    };
    startSponsored:number = 0;
    
    
    //@Input() contentId:any;
    @Output() updateSend = new EventEmitter<any>();

   //New Code for right side for from
    //New Code start
    @Input() set contentId(value: any) {

        this.startSponsored = 0;

        if(value == 'reset'){

            this.resetForm();

        }else   {

            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
        
    }
    //New Code end


    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }


    initProductType:boolean=true;
    initNavigation:boolean=true;
    initMenu:boolean=true;
    initGroup:boolean=true;
    initCategory:boolean=true;

    
    constructor(private router:Router,private activatedRoute:ActivatedRoute,private assetService:AssetService,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private spinnerService:Ng4LoadingSpinnerService,private toastrService:ToastrService,private formBuilder:FormBuilder) { 
        this.commonService.notify.subscribe((result) => {
            // this.loader = false;
            this.commonService.changeloader(false);
        })
    }


    roleAsses:any={};
    roleConfiguration:any=[];
    castSettings:any={};

    ngOnInit() {

        localStorage.removeItem('langItem');

        this.primaryNavigationMappingFilter.productTypeId = [];

        this.roleAsses.assetGroupEdit = false;
        this.roleAsses.assetGroup = false;
        this.roleAsses.assetGroupCreate = false;

        this.roleConfiguration = SessionHelper.getRole();

        this.roleConfiguration.forEach((element , i) => {

              if((element.isView == 1 && element.pageTitle == 'Asset Group') ){
                  
                  this.roleAsses.assetGroup = true;
              }
          
              if((element.isEdit == 1 && element.pageTitle == 'Asset Group') ){
                  
                  this.roleAsses.assetGroupEdit = true;
              }
              
              if((element.isCreate == 1 && element.pageTitle == 'Asset Group')){

                  this.roleAsses.assetGroupCreate = true;
              
              }

        })

        // this.results$ = this.subject.pipe(
        //     debounce(() => Rx.Observable.interval(1000)),
        //     map(searchText => this.httpClient.get("/api/search?q=" + searchText))
        // )

        this.setupSearchDebouncer();

        this.productScrollCount = 0;

        this.onAssetInfoTabClick();
        // theme update code start
        
    //     this.commonService.currentThemelight.subscribe((result:any)=>{
          
    //         this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
    //    })

    this.commonService.currentThemelight.subscribe((result:any)=>{

        if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='assetGroup'){

                    let item = JSON.parse(localStorage.getItem('langItem'));


                    if(item.id!=undefined)
                    this.id = item.id;  
                                      
                    this.createAssetGroupForm.controls['id'].setValue(this.id);

                    if(item.id != null && this.roleAsses.assetGroupEdit == true){
                        this.editCreate = true;
                        this.isCreateNewShow = false;
                        this.update = true;
                    }else{
                       
                    }

                    this.createAssetGroupForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                    this.createAssetGroupForm.controls['productDeviceTypeConfigure'].setValue([[]]);

                    this.isCreateNewShow = false;
                    // this.postCAllAssetGroup();
                    this.getAssetGroupInfo(this.id)
                    // localStorage.removeItem('langItem');

            }
                this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      })

                       
    //    this.commonService.currentThemelight.subscribe((result:any)=>{

    //         if(result.id == this.id){

    //                 this.update = true;

    //                 this.createAssetGroupForm.controls['langId'].setValue(localStorage.getItem('languageId'))
                    
    //                 this.postCAllAssetGroup();

    //         }
        
    //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
    //    })

       // theme update code start
    }


    onCreateNew(){

        if(this.roleAsses.assetGroupCreate == true){

               this.editCreate = true;

        }

        this.isCreateNewShow = false;

    }

    editCreate:boolean = false;
    onLoad(id) {

        this.start = 0;
        this.id = id;
        
        if(this.id != 'null' && this.roleAsses.assetGroupEdit == true){

            this.editCreate = true;
            this.isCreateNewShow = false;

        }else{

            // this.editCreate = false;

        }

        this.mapCertificatList=[];
        this.mapProductTypeArrayTemp = [];
        this.mapMenuCategoryArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp = [];

        this.start = 0;
        this.startProduct = 0;
        this.startCategory = 0;
        this.startCharacter = 0;
        this.startReview = 0;
        this.startClassification = 0;
        this.startSponsored = 0;

        this.productScrollCount = 0;

        this.onAssetInfoTabClick();

        this.classificationScrollCount = 0;

        this.reviewScrollCount =0 
        
        this.startReview = 0;

        this.settings = {

            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            // searchBy: ['name'],
            // lazyLoading: true,
            classes:"myclass custom-class"
        }

        this.characterScrollCount = 0;
        
        this.getGlobalCharacter();

        let headerTitle = {
            title:'Asset Management',
            subTitle:'Asset Group'
 
        }
 
        this.commonService.changeHeaderTitle(headerTitle);

        this.onAssetInfoTabClick();

        // this.activatedRoute.params.subscribe(params => {
            // this.id = +params['id']; // (+) converts string 'id' to a number
            // this.langId = +params['langId']? +params['langId'] :"1";
            
            this.id = id;

            // if(localStorage.getItem("languageId")!= null){

            //     this.langId =localStorage.getItem("languageId");

            // }else {

            //     this.langId = "1";

            // }

            this.langId = "1";
        
            
       //New Code start
            if(this.id!='null'){
            console.log('this.id===>>>',this.id);
            
                this.update=true;
                this.getAssetGroupInfo(this.id)
            }else{ 
                this.img.push({
                    horizontalURL:'',
                    verticalURL:''
                })   

                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }
    
                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }
    
                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
            }
            //New Code end

        // In a real app: dispatch action to load the details here.
        // });
            
        //pravin start code
        this.multipleDeviceForm = this.formBuilder.group({
            devices: this.formBuilder.array([this.createItem()])
        });

        this.createAssetGroupForm = this.formBuilder.group({
            id:[""],
            assetGroupType : [""],
            contentType :[""],
            isActive : ["1"],
            langId: [this.langId],
            companyId : [""],
            isVisible:[''],
            title :["",Validators.required],
            displayIndexing :[""],
            sameForAllZone : [''],
            sameForAllProductType : [''],
            airStartDate : [''],
            airEndDate : [""],
            airStartTime:[''],
            airEndTime:[''],
            exclusionText : [""],
            description : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            orderBy : [""],
            sorting : [""],
            mapGeoZone:[[], Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[], Validators.compose([Validators.required])],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapCertification:[[]],
            mapClassification:[[]],
            mapCast:[[]],
            mapGenre:[[]],
            mapCharacter:[[]],
            // mapAssetLibrary:[[]],
            mapLibrary:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]
        
        })

        this.menuCategorySettings = {

            singleSelection: false, 
            text:"Select text",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:130,
            lazyLoading: true

        }

        this.castSettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: false,
            classes:"myclass custom-class"
        }



        this.settings = {

            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            maxHeight:140,
            enableSearchFilter: true,
            unSelectAllText:'UnSelect All',
            classes:"myclass custom-class",
            searchBy: ['name'],
            lazyLoading: true,
        }

        this.productTypeMappingFilter.geoZoneId = [];
        this.primaryNavigationMappingFilter.productTypeId = [];
        this.menuCategoryMappingFilter.primaryNavigationId = [];

        this.getGeoZoneInfo({});

        // this.getProductTypeInfo(this.productTypeMappingFilter);
        // this.getNavigationInfo(this.primaryNavigationMappingFilter);
        // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

        this.getGenreInfo();
        this.getCertificate({});
        this.getLibrary();
        this.getCastInfo();
        this.getLanguageInfo();
        this.getSponsoreInfo({});
        this.getGlobalReviewInfo({});
        this.getGlobalclassification({});
        

    }

    get l() { return this.createAssetGroupForm.controls; }

    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }

    onItemSelect(item:any, mappingName){
        

        if(mappingName == 'mapGeoZone'){    
             
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.productTypeMappingFilter.geoZoneId.filter((item, index) => this.productTypeMappingFilter.geoZoneId.indexOf(this.productTypeMappingFilter.geoZoneId) === index);

            this.startProduct = 0;
            this.producTypeList = [];
            this.getProductTypeInfo(this.productTypeMappingFilter);

        }

        else if(mappingName == 'mapProductType'){

            this.primaryNavigationMappingFilter.productTypeId.push(item.id);
            this.primaryNavigationMappingFilter.productTypeId.filter((item, index) => this.primaryNavigationMappingFilter.productTypeId.indexOf(this.primaryNavigationMappingFilter.productTypeId) === index);

            this.startPrimary = 0;
            this.primaryNavigationList = [];
            this.getNavigationInfo(this.primaryNavigationMappingFilter);

        }

        else if(mappingName == 'mapPrimaryNavigation'){

            // this.selectedprimaryNavigationList.push(item);
            this.menuCategoryMappingFilter.primaryNavigationId.push(item.id);
            this.menuCategoryMappingFilter.primaryNavigationId.filter((item, index) => this.menuCategoryMappingFilter.primaryNavigationId.indexOf(item) === index);

            this.startCategory = 0;
            this.menuCategoryList = [];

            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

        }
    }

    OnItemDeSelect(item:any, mappingName){


        if(mappingName == 'mapGeoZone'){

            for (let index=0; index<this.productTypeMappingFilter.geoZoneId.length;index++){
                
                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){

                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                   
                    if(this.productTypeMappingFilter.geoZoneId.length == 0){
                        
                        this.mapProductTypeArrayTemp = [];
                        this.mapMenuCategoryArrayTemp = [];
                        this.mapPrimaryNavigationArrayTemp = [];

                        this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.primaryNavigationList = [];

                        this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];

                        this.createAssetGroupForm.controls['mapProductType'].setValue([]);
                        this.producTypeList = [];

                      

                    }else{

                        this.startProduct = 0;

                        this.getProductTypeInfo(this.productTypeMappingFilter);
                       
                    }

                    
                }
            }
        } else if(mappingName == 'mapProductType'){

            console.log("this.primaryNavigationMappingFilter.productTypeId>>>",this.primaryNavigationMappingFilter.productTypeId);

            for (let index=0; index<this.primaryNavigationMappingFilter.productTypeId.length;index++){

                if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){

                    this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);
                 
                    if(this.primaryNavigationMappingFilter.productTypeId.length == 0){

                        // this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
                        // this.primaryNavigationList = [];

                        // this.mapMenuCategoryArrayTemp = [];
                        // this.mapPrimaryNavigationArrayTemp = [];

                        // this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                        // this.menuCategoryList = [];
                      
                        
                    }else{

                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                      

                    }
                    
                    
                }
            }
        }
        else if(mappingName == 'mapPrimaryNavigation'){

            for (let index=0; index<this.menuCategoryMappingFilter.primaryNavigationId.length;index++){

                if(this.menuCategoryMappingFilter.primaryNavigationId[index] == item.id){

                    this.menuCategoryMappingFilter.primaryNavigationId.splice(index, 1);
                   
                    if(this.menuCategoryMappingFilter.primaryNavigationId.length == 0){

                        this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        this.mapMenuCategoryArrayTemp = [];

                    
                       
                    }else{

                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                     

                    }

                }
            }
        }
    }

    onSelectAll(item:any, mappingName){
      

        if(mappingName == 'mapGeoZone'){
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            this.productTypeMappingFilter = filterObj;
            this.getProductTypeInfo(filterObj);
        }
        else if(mappingName == 'mapProductType'){
            let filterObj ={ productTypeId :[] };
            item.forEach(function (value) {
                filterObj.productTypeId.push(value.id);
            });
            this.primaryNavigationMappingFilter = filterObj;
            this.startPrimary = 0;
            this.getNavigationInfo(filterObj);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            let filterObj ={ primaryNavigationId :[] };
            item.forEach(function (value) {
                filterObj.primaryNavigationId.push(value.id);
            });
            this.menuCategoryMappingFilter= filterObj;
            this.getGlobalMenuCategory(filterObj);
        }
    }

    onDeSelectAll(event, info){

        if(info =='mapGeoZone'){
            
            this.createAssetGroupForm.controls['mapGeoZone'].setValue([]);
            this.productTypeMappingFilter.geoZoneId=[];
            this.producTypeList = [];
            this.mapProductTypeArrayTemp=[];

            this.createAssetGroupForm.controls['mapProductType'].setValue([]);
            this.primaryNavigationMappingFilter.primaryNavigationId = [];
            this.primaryNavigationList = [];
            this.mapProductTypeArrayTemp =[];

            this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
            this.menuCategoryMappingFilter.menuCategoryId = [];
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp=[];

            
            this.mapProductTypeArrayTemp = [];
            this.mapMenuCategoryArrayTemp = [];
            this.mapPrimaryNavigationArrayTemp = [];


            this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            this.menuCategoryList = [];

            console.log("DeSelect All from = ",info);

        }
        else if(info =='mapProductType'){

            this.createAssetGroupForm.controls['mapProductType'].setValue([]);
            this.mapMenuCategoryArrayTemp = [];
            // this.producTypeList = [];
            this.mapProductTypeArrayTemp =[];

           
            this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            this.mapMenuCategoryArrayTemp = [];
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp=[];


            this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
            this.mapPrimaryNavigationArrayTemp = [];
            this.primaryNavigationList = [];

        }
        else if(info =='mapPrimaryNavigation'){

            this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
            this.mapPrimaryNavigationArrayTemp = [];
            // this.primaryNavigationList = [];

            this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            this.mapMenuCategoryArrayTemp = [];
            this.menuCategoryList = [];


        }
        else if(info =='mapMenuCategory'){

            this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            this.mapMenuCategoryArrayTemp = [];

        }
        else if(info =='mapClassification'){
            this.createAssetGroupForm.controls['mapClassification'].setValue([]);
        }
        else if(info =='mapCertification'){
            this.createAssetGroupForm.controls['mapCertification'].setValue([]);
        }
        else if(info =='mapLibrary'){
            this.createAssetGroupForm.controls['mapLibrary'].setValue([]);
        }
        else if(info =='mapGenre'){
            this.createAssetGroupForm.controls['mapGenre'].setValue([]);
        }
        else if(info =='mapLanguage'){
            this.createAssetGroupForm.controls['mapLanguage'].setValue([]);
        }
        else if(info =='mapSponsor'){
            this.createAssetGroupForm.controls['mapSponsor'].setValue([]);
        }
        else if(info =='mapReview'){
            this.createAssetGroupForm.controls['mapReview'].setValue([]);
        }
        else if(info =='mapCast'){
            this.createAssetGroupForm.controls['mapCast'].setValue([]);
        }
        else if(info =='mapCharacter'){
            this.createAssetGroupForm.controls['mapCharacter'].setValue([]);
        }
    }

    getGlobalclassification(obj){

    //s    this.commonService.changeloader(true);
       // this.classificationFilter = obj;
    
        this.commonService.getglobalclassification(this.classificationFilter,this.startClassification,10).subscribe((result:any)=>{
                
          //  this.mapClassificationList = [];
        //  this.commonService.changeloader(false);

         // this.mapReviewList = [];
         this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){  

                if(this.isSearch == true){
                    
                    this.isSearch = false;
                   this.mapClassificationList = [];

                }
                
                this.classificationInfoObj.pageCount == result.data.data.pageCount;

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.classificationId!=''){
                    
                        this.mapClassificationList.push(
                        {
                            'id': menuCategoryList.classificationId,
                            'itemName': menuCategoryList.classificationName
                        });

                        this.mapClassificationList = this.removeDuplicates(this.mapClassificationList, "id");

                    } 
                }
            } 

        });

    }

    fetchMoreGetClassification(event){

        if ((event.endIndex >= this.mapClassificationList.length - 3)&& (this.mapClassificationList.length >= 8)) {

            //  this.classificationScrollCount ++;  

            // if(this.classificationScrollCount <= this.classificationInfoObj.pageCount)   {

                this.startClassification = this.startClassification + 10;

                this.getGlobalclassification(this.classificationFilter);
            // }       
   
        }

    }

    //pravin code start
    getGeoZoneInfo(obj){

        // if(this.isSearch)
        //  this.commonService.changeloader(true);

            this.productConfigurationService.getAllGeoZone(obj,this.start,10)
            .subscribe((result:any)=>{
                this.searchDecouncer$.next(null);

            //    this.dropdownList = [];
             
                this.commonService.changeloader(false);

                if(this.isSearch == true){
                    this.start = 0;
                    this.isSearch = false;
                    this.geoZoneList = [];
                }

                if(result.data.data.length > 0){
                        
                    this.dropdownList = result.data.data;

                    for (let geoZoneObj of this.dropdownList) {

                        this.geoZoneList.push(
                        {
                            'id': geoZoneObj.geoZoneId,
                            'itemName': geoZoneObj.geoZoneName
                        });

                    }

                    this.geoZoneList = this.removeDuplicates(this.geoZoneList, "id");

                }
        
            }) 
    }

    fetchMoreGetGeoZone(event){

    if ((event.endIndex >= this.geoZoneList.length - 1) && (this.geoZoneList.length>=9)) {

         this.start = this.start + 10;

         this.getGeoZoneInfo(this.geoZoneObj);
            
        }

    }
    //////////////////


    getProductTypeInfo(filterObj){

            this.commonService.getGlobalProductType(filterObj,this.startProduct,10)
            .subscribe((result:any)=>{

                this.searchDecouncer$.next(null);


                this.ProducTypeDropdownList = [];
                this.commonService.changeloader(false);
                // let mapProductTypeArrayTemp = [];
               // this.mapProductTypeArrayTemp = [];
                // this.producTypeList = [];
                
                let mapProductTypeArray = this.createAssetGroupForm.value.mapProductType;
    
                if(result.data.data.length > 0){
    
                    this.initProductType = false;
                    
                    this.productInfoObj.pageCount = result.data.data.pageCount;
    
                    if(this.isSearch == true){
    
                        this.isSearch = false;
                        this.producTypeList = [];
    
                    }
    
                    // this.primaryNavigationMappingFilter.productTypeId = [];
                    this.ProducTypeDropdownList = result.data.data;
                    let asyc = result.data.data.length;
    
                    for (let ProducTypeLists of this.ProducTypeDropdownList) {
    
                        asyc--;
    
                        mapProductTypeArray.forEach(element => {
    
                            if(element.id == ProducTypeLists.productTypeId) {
    
                                this.mapProductTypeArrayTemp.push(element);
                                this.primaryNavigationMappingFilter.productTypeId.push(element.id);
                                this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp, "id");
    
                            }
                            
                        });
    
    
                        if(ProducTypeLists.productTypeName!=''){
    
                            this.producTypeList.push(
                                {
                                    'id': ProducTypeLists.productTypeId,
                                    'itemName': ProducTypeLists.productTypeName
                                });
    
                        }
                        
                        this.producTypeList = this.removeDuplicates(this.producTypeList, "id");
    
                    }
    
                   if(asyc == 0){
    
    
                        // for (let ProducTypeLists of this.producTypeList) {
    
                        //     mapProductTypeArray.forEach(element => {
    
                        //         if(element.id == ProducTypeLists.id) {
                        //             mapProductTypeArrayTemp.push(element);
                        //             this.primaryNavigationMappingFilter.productTypeId.push(element.id);
                        //         }
                        //         this.createAssetGroupForm.controls['mapProductType'].setValue(mapProductTypeArrayTemp);
                        
                        //     });
                        // }
    
                        this.createAssetGroupForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);
    
                    
                       if(this.mapProductTypeArrayTemp.length == 0){
    
                        this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.primaryNavigationList = [];
                        
                        this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                       }else {
    
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
    
                       }
                
                   }
                    
                }else{
    
                    if(this.initProductType = false && this.isSearch == false){
    
                               this.createAssetGroupForm.controls['mapProductType'].setValue([]);
                               this.producTypeList = [];
    
                                this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
                                this.primaryNavigationList = [];
                                
                                this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                                this.menuCategoryList = [];
                    }
                    
                }
            });
        

    }

    fetchMoreGetProductType(event){

        if(this.productInfoObj.pageCount == undefined){

            this.productInfoObj.pageCount = 0;

        }

        if ((event.endIndex == this.producTypeList.length - 1) && (this.producTypeList.length>=8)) {

                this.startProduct = this.startProduct + 10;
                console.log("in");
                
                this.getProductTypeInfo(this.productTypeMappingFilter);     
           
         }

    }
    ///////////////

    getNavigationInfo(filterObj){


        // if(this.isSearch == true)
        //    this.commonService.changeloader(true);


        this.commonService.getGlobalPrimaryNavigation(filterObj,this.startPrimary,10).subscribe((result:any)=>{
            
         //   this.primaryNavigationList = [];
            // let mapPrimaryNavigationArrayTemp = [];
           // this.mapPrimaryNavigationArrayTemp = [];

           // this.primaryNavigationList = [];

               this.searchDecouncer$.next(null);


            let mapPrimaryNavigationArray = this.createAssetGroupForm.value.mapPrimaryNavigation;
            let asyc = result.data.data.length;
            

            if(result.data.data.length > 0){  

                if(this.isSearch == true){

                    this.loader =false;
    
                    this.isSearch = false;
                    
                    this.primaryNavigationList = [];
                }

              //  this.menuCategoryMappingFilter.primaryNavigationId = [];

                for (let primaryNavigationLists of result.data.data) {

                    asyc --;
                    mapPrimaryNavigationArray.forEach(element => {
                        if(element.id == primaryNavigationLists.primaryNavigationId) {

                            this.mapPrimaryNavigationArrayTemp.push(element);
                            this.menuCategoryMappingFilter.primaryNavigationId.push(element.id);
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp, "id");


                        }
                    });

                    if(primaryNavigationLists.primanyNavigationName!=''){

                       this.primaryNavigationList.push(
                        {
                            'id': primaryNavigationLists.primaryNavigationId,
                            'itemName': primaryNavigationLists.primanyNavigationName
                        });
                        
                    } 

                    this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList, "id");
                                    
                }


                 if(asyc == 0){ 

                    // this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);
                    
                    if(this.mapPrimaryNavigationArrayTemp.length == 0){

                        this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                       }else {
    
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    
                    }
                   
                }
               
            }else {
            //    this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue([]);
            //    this.primaryNavigationList= [];
                
            //     this.createAssetGroupForm.controls['mapMenuCategory'].setValue([]);
            //     this.menuCategoryList = [];
            } 
        })
    }

    fetchMoreGetPrimary(event){

        console.log("event=list-count",event.endIndex , this.primaryNavigationList.length ,this.SponsoredScrollCount)

        if ( ((event.endIndex >= this.primaryNavigationList.length - 2)) && (this.primaryNavigationList.length >=10)) {

                this.startPrimary = this.startPrimary + 10;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);  
   
        }

    }
    //////////////

    getGlobalMenuCategory(filterObj){

      //  this.commonService.changeloader(true);
        this.commonService.getGlobalMenuCategory(filterObj,this.startCategory,10).subscribe((result:any)=>{
        
           // let mapMenuCategoryArrayTemp = [];
          //  this.menuCategoryList = [];
            this.commonService.changeloader(false);
            this.searchDecouncer$.next(null);

            let mapMenuCategoryArray = this.createAssetGroupForm.value.mapMenuCategory;
            
            if(result.data.data.length > 0){  
                
                    if(this.isSearch == true){

                        this.startCategory = 0;
                        this.menuCategoryList = [];
                        this.isSearch = false;

                    }

                    for (let menuCategoryList of result.data.data) {

                        mapMenuCategoryArray.forEach(element => {

                            if(element.id == menuCategoryList.menuCategoryId) {
                                this.mapMenuCategoryArrayTemp.push(element);
                                this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp, "id");
                    
                            }
                        });

                        if(menuCategoryList.menuCategoryName!=''){
                        
                            this.menuCategoryList.push(
                            {
                                'id': menuCategoryList.menuCategoryId,
                                'itemName': menuCategoryList.menuCategoryName
                            });
                        } 

                        this.menuCategoryList = this.removeDuplicates(this.menuCategoryList, "id");
    
                    }
        
                this.createAssetGroupForm.controls['mapMenuCategory'].setValue(this.mapMenuCategoryArrayTemp);

            } 

        });
    }

    fetchMoreGetCategory(event){

        if ( ((event.endIndex >= this.menuCategoryList.length - 2)) && (this.menuCategoryList.length >= 9)) {

                this.startCategory = this.startCategory + 10;

                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);  
   
        }
    }
    /////////////

    getLanguageInfo(){

        this.commonService.getGlobalLanguage(this.languageFilter, this.startLanguage,10).subscribe((result:any)=>{
            
            // this.mapLanguageList = [];
            
            if(result.data.data.length > 0){ 
                
                this.searchDecouncer$.next(null);

                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapLanguageList = [];

                }

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.languageId!=''){
                    
                        this.mapLanguageList.push(
                        {
                            'id': menuCategoryList.languageId,
                            'itemName': menuCategoryList.languageName
                        });
                    } 
                }

                this.mapLanguageList = this.removeDuplicates(this.mapLanguageList, "id");

            } 

        });
    }
    
    fetchMoreGetLanguage(event){
        
        if ((event.endIndex == this.mapLanguageList.length - 1)&& (this.mapLanguageList.length >= 8)) {

            //  this.characterScrollCount ++;  

            // if(this.characterScrollCount <= this.charcterInfoObj.pageCount)   {

                this.startLanguage = this.startLanguage + 10;
                this.getLanguageInfo();
            // }       
   
        }
    }
    /////////////

    getSponsoreInfo(obj){

        // this.sponsoredFiter = obj;

        this.commonService.getGlobalSponsore(this.sponsoredFiter,this.startSponsored,10).subscribe((result:any)=>{
            
            this.searchDecouncer$.next(null);

         //   this.mapSponsoreList = [];
            
            if(result.data.data.length > 0){  

                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapSponsoreList = [];
                }

                this.SponsoredInfoObj.pageCount = result.data.data.pageCount ;
                    
                for (let mapSponsoreList of result.data.data) { 

                        this.mapSponsoreList.push(
                        {
                            'id': mapSponsoreList.sponsorId,
                            'itemName': mapSponsoreList.sponsorName
                        });
                }

                

                this.mapSponsoreList = this.removeDuplicates(this.mapSponsoreList, "id");

            } 

        });

    }
     
    fetchMoreGetSponsore(event){
        
        if ( ((event.endIndex == this.mapSponsoreList.length - 1)) && (this.mapSponsoreList.length >= 7)) {

            //  this.reviewScrollCount++;  

            // if(this.reviewScrollCount <= this.reviewInfoObj.pageCount)   {

                this.startSponsored = this.startSponsored + 10;
                this.getSponsoreInfo(this.sponsoredFiter);
            // }       
   
        }
    }
    ////////////////


    getGenreInfo(){

        this.commonService.getGlobalGenre(this.genreFilter,this.startGenre,10).subscribe((result:any)=>{  

        //    this.mapGenreList = [];
        this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){   
                      
                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapGenreList = [];

                }
 
                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.productTypeName!=''){
                    this.mapGenreList.push(
                        {
                            'id': ProducTypeLists.genreId,
                            'itemName': ProducTypeLists.genreName
                        });
                    }    
                }

                this.mapGenreList = this.removeDuplicates(this.mapGenreList, "id");

            } 

        });
    }

    fetchMoreGetGenre(event){
        
        if ( ((event.endIndex == this.mapGenreList.length - 1)) && (this.mapGenreList.length >= 7)) {

                this.startGenre = this.startGenre + 10;

                this.getGenreInfo();
            // }       
   
        }
    }
    ////////////

    getCertificate(obj){

        this.commonService.getGlobalCertification(this.certificateFilter,this.startCertificate,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){      
                
                    if(this.isSearch == true){
                        
                        this.isSearch = false;
                        this.mapCertificatList = [];

                    }
                    for (let ProducTypeLists of result.data.data) {

                        if(ProducTypeLists.productTypeName!=''){
                        this.mapCertificatList.push(
                            {
                                'id': ProducTypeLists.certificationId,
                                'itemName': ProducTypeLists.certificationName
                            });
                        }    
                    }
                    this.mapCertificatList = this.removeDuplicates(this.mapCertificatList, "id");

            } 

        });
    }

    fetchMoreGetCertificate(event){
    
        if ( ((event.endIndex == this.mapClassificationList.length - 1)) && (this.mapClassificationList.length >= 7)) {

                this.startClassification = this.startClassification + 10;

                this.getCertificate({});
                 
   
        }

    }
    /////////////


    getLibrary(){
        
        this.commonService.getglobalLibrary(this.libraryFilter,this.startLibrary,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){
                        
                    this.isSearch = false;
                    this.mapLibraryList = [];

                }

                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.libraryId!=''){
                    this.mapLibraryList.push(
                        {
                            'id': ProducTypeLists.libraryId,
                            'itemName': ProducTypeLists.libraryName
                        });
                    }    
                }

                this.mapLibraryList = this.removeDuplicates(this.mapLibraryList, "id");

            } 
        });

    }

    fetchMoreGetlibrary(event){

        if ( ((event.endIndex == this.mapLibraryList.length - 1)) && (this.mapLibraryList.length >= 9)) {

                // this.reviewScrollCount++;  

                this.startLibrary = this.startLibrary + 10;

                this.getLibrary();
   
        }

    }
    ////////////////


    getGlobalReviewInfo(obj){
        
        this.commonService.getGlobalReview(this.reviewFilter,this.startReview,10).subscribe((result:any)=>{

            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){ 
                
                if(this.isSearch == true){
                    
                    this.isSearch = false;
                    this.mapReviewList = [];

                }
               
                for (let ProducTypeLists of result.data.data) {

                    this.mapReviewList.push(
                        {
                            'id': ProducTypeLists.reviewId,
                            'itemName': ProducTypeLists.reviewFirstName
                        });
                       
                }  
                
                this.mapReviewList = this.removeDuplicates(this.mapReviewList, "id");

            } 
        });
    }

    fetchMoreGetReviews(event){

        // console.log("event=list-count",event.endIndex , this.mapReviewList.length ,this.reviewScrollCount)

        if ( ((event.endIndex == this.mapReviewList.length - 1)) && (this.mapReviewList.length >= 7)) {

            // if(this.reviewScrollCount <= this.reviewInfoObj.pageCount)   {

                this.startReview = this.startReview + 10;

                this.getGlobalReviewInfo({});
            // }       
   
        }

    }
    /////////////////

    characterFilter:any={};

    getGlobalCharacter(){

        this.commonService.getGlobalCharacter(this.characterFilter,this.startCharacter,10).subscribe((result:any)=>{

           // this.mapCharacterList = [];
           this.searchDecouncer$.next(null);


            if(result.data.data.length > 0){   

                if(this.isSearch == true){

                    this.isSearch = false;
                    this.mapCharacterList = [];
                }
                
                this.charcterInfoObj.pageCount = result.data.data.pageCount;
               
                for (let CharacterLists of result.data.data) {

                    // if(CharacterLists.characterFirstName!=''){
                    this.mapCharacterList.push(
                        {
                            'id': CharacterLists.characterId,
                            'itemName': CharacterLists.characterFirstName
                        });
                    // }    
                }  
                
                this.mapCharacterList = this.removeDuplicates(this.mapCharacterList, "id");

            } 
        });
    }

    fetchMoreGetCharacter(event){

        if ( ((event.endIndex == this.mapCharacterList.length - 1)) && (this.mapCharacterList.length >= 9)) {

            this.startCharacter = this.startCharacter + 10;

            this.getGlobalCharacter();

       }
    }


    eventValue:any='fr';

    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
        return;

        this.eventValue = event.target.value;
        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;

        
        if(info == 'cast'){
            
            if(event.target.value!=''){
                obj = { 'castFirstName': event.target.value };
                this.startCast = 0;
                this.mapCastList = [];      
                this.castFilter = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.castFilter = obj ;
                this.mapCastList = [];      
                this.startCast = 0;

                this.searchDecouncer$.next(event.target.value);

            }
        }
        
        if(info == 'character'){
            
            if(event.target.value!=''){

                obj = { 'characterFirstName': event.target.value };
                this.startCharacter = 0;
                this.mapCharacterList = [];      
                this.characterFilter = {} ;
                this.characterFilter = obj;
                // this.characterFilter = {};
                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.characterFilter = obj ;
                this.mapCharacterList = [];      
                this.startCharacter = 0;
                this.searchDecouncer$.next(event.target.value);

            }
  
        }  

        if(info == 'classification'){
            

            if(event.target.value!=''){

                this.classificationFilter =  obj = { 'classificationName': event.target.value };
                // this.classificationFilter = obj = { };
                this.mapClassificationList=[];

    
            }else {
                this.mapClassificationList=[];
                this.classificationFilter =   obj = { } 
            }

            this.startClassification = 0;
            this.classificationScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'review'){

            if(event.target.value!=''){

               this.reviewFilter = obj = { 'reviewFirstName': event.target.value };
            //    this.reviewFilter = obj = { } 
            this.mapReviewList = [];

    
            }else {

                this.mapReviewList = [];
                this.reviewFilter = obj = { } 
            }

            this.startReview = 0;
            this.reviewScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }
        
        if(info == 'genre'){

            if(event.target.value!=''){

               this.genreFilter = obj = { 'genreName': event.target.value };
            //    this.genreFilter = obj = { } 

            this.mapGenreList= [];

    
            }else {

                this.genreFilter = obj = { };
                this.mapGenreList= [];
            }

            this.startGenre = 0;
            this.reviewScrollCount = 0;
            
            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'sponsore'){


            if(event.target.value!=''){

                this.sponsoredFiter = obj = { 'sponsorName': event.target.value };
                this.mapSponsoreList = [];
    
            }else {

                this.sponsoredFiter =  obj = { } 
            }

            this.startSponsored = 0;
            this.SponsoredScrollCount = 0;

            this.mapSponsoreList = [];


            // setTimeout(()=>{

            //     this.getSponsoreInfo(obj);

            //  },200)

            this.searchDecouncer$.next(event.target.value);

            

        }

        if(info == 'language'){

            if(event.target.value!=''){

                this.languageFilter =  obj = { 'languageName': event.target.value };
                this
                this.mapLibraryList=[];
                // this.languageFilter =  obj = { } 


            }else {
                this.mapLibraryList=[];
                this.languageFilter =  obj = { } 
            }

            this.startLanguage = 0;
            this.SponsoredScrollCount = 0;
            // setTimeout(()=>{

            //     this.getLanguageInfo();

            //  },200)on

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'library'){

            if(event.target.value!=''){

                 this.libraryFilter =  obj = { 'libraryName': event.target.value };
                 this.mapLibraryList = [];
                // this.libraryFilter =  obj = { } 

    
            }else {

                this.libraryFilter =  obj = { };
                this.mapLibraryList = [];
            }

            this.startClassification = 0;
            this.SponsoredScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'certification'){

            if(event.target.value!=''){

                obj = { 'certificationName': event.target.value };
                this.mapCertificatList=[];
                this.certificateFilter = obj;
                this.startCertificate = 0;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                this.certificateFilter = obj = { };
            }

            this.startCertificate = 0;
            this.certificateFilter = obj ;

            this.mapCertificatList=[];
            // setTimeout(()=>{

            //     this.getCertificate(obj);   

            //  },200)

            this.searchDecouncer$.next(event.target.value);
        }  
        
        if(info == 'geoZone'){
            

            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                this.geoZoneList = [];      
                this.geoZoneObj = obj ;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                obj = { };
                this.geoZoneObj = obj ;
                this.geoZoneList = [];      
                this.start = 0;
                
                this.searchDecouncer$.next(event.target.value);
            }
  
        }  

        if(info == 'productType'){

            console.log("producttype",event);

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                this.producTypeList = [];
                this.startProduct = 0;
                this.productScrollCount = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                delete this.productTypeMappingFilter.productTypeName;
                this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);

            }

            
        }

        if(info == 'navigation'){

            if(event.target.value!=''){

                this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

                delete this.primaryNavigationMappingFilter.primanyNavigationName;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                this.searchDecouncer$.next(event.target.value);


            }

        } 
 
        if(info == 'category'){

            if(event.target.value!=''){

                   this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                   this.menuCategoryList = [];
                   this.startCategory = 0;
                   //this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                   this.searchDecouncer$.next(event.target.value);

            }else {

                delete this.menuCategoryMappingFilter.menuCategoryName;
                this.menuCategoryList = [];
                this.startCategory = 0;
                // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                this.searchDecouncer$.next(event.target.value);


            }

        }   

    }
       onOpen(info){

        this.activeSearchBox = info;
 
 
         if(this.activeSearchBox == 'character'){
 
             this.mapCharacterList=[];
             this.characterFilter={};
             this.startCharacter = 0;
             this.getGlobalCharacter();
 
         }
 
         if(this.activeSearchBox == 'cast'){
 
             this.castFilter = {};
             this.mapCastList = [];
             this.startCast = 0;
             this.getCastInfo();
 
         }
 
         if(this.activeSearchBox == 'classification'){
 
                 this.classificationFilter={};
                 this.mapClassificationList=[];
                 this.startClassification = 0;
                 this.getGlobalclassification(this.classificationFilter);
 
         }
 
         if(this.activeSearchBox == 'review'){
 
                 this.reviewFilter={};
                 this.mapReviewList = [];
                 this.startReview = 0;
                 this.getGlobalReviewInfo(this.reviewFilter);
 
         }
 
         if(this.activeSearchBox =='genre'){
 
                 this.genreFilter = {};
                 this.mapGenreList = [];
                 this.startGenre=0;
                 this.getGenreInfo();    
 
         }
 
         if(this.activeSearchBox == 'sponsore'){
 
                this.sponsoredFiter = {};
                this.mapSponsoreList = [];
                this.startSponsored = 0;
                 this.getSponsoreInfo(this.sponsoredFiter);   
 
         }
 
         if(this.activeSearchBox == 'language'){
 
             
             this.languageFilter ={};
             this.mapLanguageList = [];
             this.startLanguage = 0;
             this.getLanguageInfo();
 
         }
 
         if(this.activeSearchBox == 'library'){
  
                this.libraryFilter = {};
                this.mapLibraryList = [];
                this.startLibrary = 0;
                 this.getLibrary();
         }
 
         if(this.activeSearchBox == 'certification'){
 
             this.mapCertificatList = [];
             this.certificateFilter = {};
             this.startCertificate = 0;
 
             this.getCertificate(this.certificateFilter);   
         }  
 
         if(this.activeSearchBox == 'geoZone'){
 
             this.geoZoneObj = {};
             this.geoZoneList = [];
             this.start = 0;
             this.getGeoZoneInfo(this.geoZoneObj);       
 
         }  
 
         if(this.activeSearchBox == 'productType'){
 
            var geoZoneId = this.productTypeMappingFilter.geoZoneId;
            this.productTypeMappingFilter ={};
            this.productTypeMappingFilter.geoZoneId = geoZoneId;
            this.startProduct= 0;
            this.producTypeList = [];
            this.getProductTypeInfo(this.productTypeMappingFilter);
             
         }
 
         if(this.activeSearchBox == 'navigation'){
     
            this.primaryNavigationMappingFilter.productTypeId = [];

            var productTypeId = this.primaryNavigationMappingFilter.productTypeId;

            if(productTypeId.length == 0){

                this.primaryNavigationMappingFilter.productTypeId = [];
  
            }else {
               
                this.primaryNavigationMappingFilter.productTypeId = productTypeId;

            }

            this.startPrimary = 0;
            this.startNaviagation = 0;
            this.primaryNavigationList=[];
            this.getNavigationInfo(this.primaryNavigationMappingFilter);     
 
         } 
 
         if(this.activeSearchBox == 'category'){

            this.menuCategoryMappingFilter.primaryNavigationId =  this.menuCategoryMappingFilter.primaryNavigationId.filter((item, index) => {
                return this.menuCategoryMappingFilter.primaryNavigationId.indexOf(item) === index
              });


                var primaryNavigationId = this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter = {};
                this.menuCategoryMappingFilter.primaryNavigationId = primaryNavigationId;
                this.startCategory = 0;
                this.menuCategoryList = [];
                this.startCategory = 0;

             this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
 
         }   
 
 
      }

    private setupSearchDebouncer(): void {
        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            if(this.activeSearchBox == 'classification'){
            
                    this.getGlobalclassification(this.classificationFilter);
    
            }

            
            if(this.activeSearchBox == 'character'){

                this.getGlobalCharacter();

            }

            if(this.activeSearchBox == 'cast'){

                this.getCastInfo();

            }
    
            if(this.activeSearchBox == 'review'){
    
                    this.getGlobalReviewInfo(this.reviewFilter);
   
            }
            
            if(this.activeSearchBox =='genre'){
    
                    this.getGenreInfo();    
    
            }
    
            if(this.activeSearchBox == 'sponsore'){
    
                    this.getSponsoreInfo(this.SponsoredInfoObj);   
    
            }
    
            if(this.activeSearchBox == 'language'){
    
                    this.getLanguageInfo();
    
            }
    
            if(this.activeSearchBox == 'library'){
    
                    this.getLibrary();
            }
    
            if(this.activeSearchBox == 'certification'){
    
    
                 this.getCertificate(this.certificateFilter);   
            }  
            
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo(this.geoZoneObj);       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }
    
            if(this.activeSearchBox == 'navigation'){
    
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     
    
            } 
     
            if(this.activeSearchBox == 'category'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }   

        });
    }
    
    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }

    fetchMoreGetLibrary(event){
        
        if ((event.endIndex == this.mapLibraryList.length - 1)&& (this.mapLibraryList.length >= 8)) {

            //  this.characterScrollCount ++;  

            // if(this.characterScrollCount <= this.charcterInfoObj.pageCount)   {

                this.startLibrary = this.startLibrary + 10;
                this.getLibrary();
            // }       
   
        }
    }

   //product type dropdown code end

    //Pravin code mulitple from
    createItem(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.required],
            description: '',
            isCarouselApplicable:'',
            dtypeId:['',Validators.required],
            isVisible:[''],
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""
        });
    }

    //pravin code start
    updateItem(objProductDeviceTypeConfigure): FormGroup { 
    // console.log("objProductDeviceTypeConfigure.mapTrailerVideo",objProductDeviceTypeConfigure.mapTrailerVideo);
  
        if(objProductDeviceTypeConfigure.dtypeId === 4){
            this.all = true;
        }
        return this.formBuilder.group({
            label: objProductDeviceTypeConfigure.label,
            isCarouselApplicable:objProductDeviceTypeConfigure.isCarouselApplicable,
            description: objProductDeviceTypeConfigure.description,
            dtypeId:objProductDeviceTypeConfigure.dtypeId,
            isVisible:objProductDeviceTypeConfigure.isVisible,
            verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
            verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
            verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
            horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
            horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
            horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType

        });
    }

    onDtypeId(){
        this.all=false;
    }

    addItem(): void {
        
        if(!this.multipleDeviceForm.valid){  

            this.createAssetGroupSubmitted = true;
            return
  
          }
          
        if(this.multipleDeviceForm.value.devices.length >= 4){

            this.createAssetGroupSubmitted = true;
            return;

        }
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
        }

    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId == '1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId == '2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId == '5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId == '3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId == '4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }

    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId == '1'){
                Web++;
                this.web=true;
            }
            
            if(devices.value[i].dtypeId == '2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId == '3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId == '5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId == '4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }
    
    saveCreateAssetGroup(){

        const airStartTime = this.createAssetGroupForm.get('airStartTime');
        const airEndTime = this.createAssetGroupForm.get('airEndTime');
        const airStartDate = this.createAssetGroupForm.get('airStartDate');
        const airEndDate = this.createAssetGroupForm.get('airEndDate');

        if(this.createAssetGroupForm.value.airStartDate == ''){
            this.createAssetGroupForm.controls['airStartTime'].setValue(['']);
        }

        if(this.createAssetGroupForm.value.airEndDate == ''){
            this.createAssetGroupForm.controls['airEndTime'].setValue(['']);
        }

        if(this.createAssetGroupForm.value.airStartDate=='' && this.createAssetGroupForm.value.airStartTime!=''){

            // airStartDate.setValidators([Validators.required]);
            // airStartDate.updateValueAndValidity();

        }else if(this.createAssetGroupForm.value.airStartDate!='' && this.createAssetGroupForm.value.airStartTime==''){

            // airStartTime.setValidators([Validators.required]);
            // airStartTime.updateValueAndValidity();
            console.log('update',this.createAssetGroupForm);

        }else {
            airStartTime.setValidators(null);
            airStartDate.setValidators(null);
            airStartTime.updateValueAndValidity();
            airStartDate.updateValueAndValidity();
        }

        if(this.createAssetGroupForm.value.airEndDate=='' && this.createAssetGroupForm.value.airEndTime!=''){

            // airEndDate.setValidators([Validators.required]);
            // airEndDate.updateValueAndValidity();

        }else if(this.createAssetGroupForm.value.airEndDate!='' && this.createAssetGroupForm.value.airEndTime==''){

            // airEndTime.setValidators([Validators.required]);
            // airEndTime.updateValueAndValidity();
       

        }else{
            airEndTime.setValidators(null);
            airEndDate.setValidators(null);
            airEndTime.updateValueAndValidity();
            airEndDate.updateValueAndValidity();
        }


      this.createAssetGroupSubmitted=true;
        //this.loader= true;
        this.commonService.changeloader(false);

      let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
      this.uploadImgFilePath=[];

      //remove empty file upload img obj from array.
      tempUploadImgFilePath.forEach((element , i) => {

         if( element.selectedFiles !='') {

          this.uploadImgFilePath.push(element);
       
         }
          
      });


    if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
        // this.loader= false;
        this.commonService.changeloader(false);
        return;

    }

    if(this.deviceTypeDublicate){
        // this.loader= false;
        this.commonService.changeloader(false);
        return;
    }
    
    if(this.createAssetGroupForm.invalid || this.multipleDeviceForm.invalid ){
        // this.loader= false;
        this.commonService.changeloader(false);
        return;
    }
    

    if(this.multipleDeviceForm.value.devices.length == 0 ){
        // this.loader= false;
        this.commonService.changeloader(false);
        return;
    }
  

    let async = this.uploadImgFilePath.length;
    let varialbeArray=[];
    
        if(!this.update){

            if(this.roleAsses.assetGroupCreate == false){
                this.toastrService.success('no access to create.');

                this.commonService.changeloader(false);
                return;
            }

            // uploadImgFilePosterImage upload code finish pravin

            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.assetGroup).then(res => {

                        if (res && res.Location) {
                                async--;
                                if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                if (async == 0) {

                                   let asyncs =  this.multipleDeviceForm.value.devices.length;

                                    for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                               
                                        asyncs --;

                                                varialbeArray.push(
                                                {
                                                    "label": this.multipleDeviceForm.value.devices[i].label,
                                                    "description":this.multipleDeviceForm.value.devices[i].description,
                                                    "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                    "isCarouselApplicable":this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                                                    "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                                                    "verticalFileName": "",
                                                    "verticalFilePath": "",
                                                    "verticalFileType": "",  
                                                    "horizontalFileName": "",
                                                    "horizontalFilePath": "",
                                                    "horizontalFileType": ""
                                             
                                                });   

                                    }
                                    
                                    if(asyncs == 0){

                                        let asyncvariables = varialbeArray.length;

                                        for(let i=0;i< varialbeArray.length;i++){

                                                asyncvariables--;

                                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
            
                                                    if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
            
                                                        varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                        varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                        varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
            
                                                    }
    
                                                    if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
            
                                                        varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                        varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                        varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
            
                                                    }
            
                                                }
                                        }
    
                                        if(asyncvariables == 0){
    
                                            this.createAssetGroupForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                            console.log("createNewProductForm123",this.createAssetGroupForm);

                                            this.createAssetGroupSubmitted=true;

                                            if(!this.multipleDeviceForm.valid){
                                                // this.loader= false;
                                                this.commonService.changeloader(false);
                                                return
                                            }
                                    
                                            if(!this.createAssetGroupForm.valid){
                                                // this.loader= false;
                                                this.commonService.changeloader(false);
                                                return
                                            }
                                           
                                            this.postCAllAssetGroup();

                                            //New Code for right side for from
                                            //this.onContentReadyToReview();
                                        }
                                   }
                                 
                               }      
                        }

                    });
                }
            }

            if(this.uploadImgFilePath.length==0){
                
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 
                  
                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "isCarouselApplicable":this.multipleDeviceForm.value.devices[i].isCarouselApplicable,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
             
                this.createAssetGroupSubmitted = true;
                this.createAssetGroupForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    // this.loader= false;
                    this.commonService.changeloader(false);
                    return
                }
        
                if(!this.createAssetGroupForm.valid){
                    // this.loader= false;
                    this.commonService.changeloader(false);
                    return
                }
                

                this.postCAllAssetGroup();
                //New Code for right side for from
                //this.onContentReadyToReview();
            }

        }

        if(this.update){

            if(this.roleAsses.assetGroupEdit == false){
                
                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                return;
            }

            let asyncvariables = this.multipleDeviceForm.value.devices.length;
            if(this.uploadImgFilePath.length == 0){

                this.createAssetGroupForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createAssetGroupSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    // this.loader= false;
                    this.commonService.changeloader(false);
                    return
                }
               

                if(!this.createAssetGroupForm.valid){
                    // this.loader= false;
                    this.commonService.changeloader(false);
                    return
                }
               
                
                 this.postCAllAssetGroup();
                //New Code for right side for from
                //this.onContentReadyToReview();

            }

            // if(this.uploadImgFilePath.length > 0 && this.createNewAssetForm.value.vodClosedCaptionFilePath!=''){
            // }
            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.assetGroup).then(res => {

                        if (res && res.Location) {
                            async--;
                            if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){

                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }


                                }
                            }

                            if(asyncvariables == 0){

                                this.createAssetGroupSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }

                                if(!this.createAssetGroupForm.valid){
                                    // this.loader = false;
                                    this.commonService.changeloader(false);
                                    return
                                }
                                this.createAssetGroupForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                              
                                 this.postCAllAssetGroup();
                                //New Code for right side for from
                                //this.onContentReadyToReview();

                                
                            }
                        }
                    })
                }
            }

        }
    }


    //New Code for right side for from
    onContentReadyToReview(){

        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            // this.loader= false;
            this.commonService.changeloader(false);
            this.createAssetGroupSubmitted = true;
            return;
    
        }
        
        if(this.createAssetGroupForm.invalid || this.multipleDeviceForm.invalid ){
            // this.loader= false;
            this.commonService.changeloader(false);
            this.createAssetGroupSubmitted = true;
            return;

        }
        
        // this.loader = false;
       

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }


    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createAssetGroupForm.controls['contentState'].setValue(['Completed']);
            // this.postCAllAssetGroup();
            this.saveCreateAssetGroup();
           
        }
        if(event == 'Cancel'){

            this.createAssetGroupForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCAllAssetGroup();
            this.saveCreateAssetGroup();
           

        }
       
    }

    postCAllAssetGroup(){

        console.log("createNewProductForm",this.createAssetGroupForm);
    
        this.createAssetGroupForm.controls['langId'].setValue(localStorage.getItem('languageId'));
        // this.loader=true;
        this.commonService.changeloader(true);
        // this.createAssetGroupForm.controls['airStartDate'].setValue(this.createAssetGroupForm.value.airStartDate +' '+ this.createAssetGroupForm.value.airStartTime);
        // this.createAssetGroupForm.controls['airEndDate'].setValue(this.createAssetGroupForm.value.airEndDate +' '+  this.createAssetGroupForm.value.airEndTime);
         
        if(this.createAssetGroupForm.value.airStartDate!='')
        this.createAssetGroupForm.controls['airStartDate'].setValue(this.createAssetGroupForm.value.airStartDate +' '+ this.createAssetGroupForm.value.airStartTime);

        if(this.createAssetGroupForm.value.airStartTime =='')
        this.createAssetGroupForm.controls['airStartDate'].setValue(this.createAssetGroupForm.value.airStartDate)

        if(this.createAssetGroupForm.value.airEndTime =='')
        this.createAssetGroupForm.controls['airEndDate'].setValue(this.createAssetGroupForm.value.airEndDate)

        if(this.createAssetGroupForm.value.airEndDate!='')
        this.createAssetGroupForm.controls['airEndDate'].setValue(this.createAssetGroupForm.value.airEndDate +' '+  this.createAssetGroupForm.value.airEndTime);
          if( this.update == false ) {

                delete this.createAssetGroupForm.value.id;
                this.assetService.insertAssetGroup(this.createAssetGroupForm.value)
                .subscribe((result:any)=>{

                    // this.loader=false;
                    this.commonService.changeloader(false);

                    this.spinnerService.hide();
                    // this.router.navigate(['asset_management/asset_group/list']);
                    this.toastrService.success('Asset Group Created Successfully.');
                    //New Code for right side for from
                   this.update = false;
                //   this.updateSend.emit();
                   this.resetForm();
                   this.createAssetGroupSubmitted = false;
                });
    
            }
    
            if(this.update) {

                this.createAssetGroupForm.controls['id'].setValue(this.id);
                this.assetService.updateAssetGroup(this.createAssetGroupForm.value)
                .subscribe((result:any)=>{

                    // this.loader=false;
                    this.commonService.changeloader(false);

                    this.toastrService.success('Asset Group Updated Successfully.');
                    console.log('<====result result result============>',result);
                    this.spinnerService.hide();
                    // this.router.navigate(['asset_management/asset_group/list']);
                 //New Code for right side for from
                this.update = false;
              //  this.updateSend.emit();
                this.resetForm();
                this.createAssetGroupSubmitted = false;
                   
                });
                
            }
    }


    selectedProductTypeItems:any=[];

    getAssetGroupInfo(id){

        // this.loader=true;
        this.commonService.changeloader(true);

        this.langId = localStorage.getItem('languageId');

        this.assetService.getAssetGroup(id, this.langId)
        .subscribe((result:any)=>{

                 this.loader=false;
                 this.commonService.changeloader(false);

                //(start) set dropdown by default empty
                //
                if(result.data[0].sameForAllProductType == null){
                    result.data[0].sameForAllProductType = "";
                }
                if(result.data[0].sameForAllZone == null){
                    result.data[0].sameForAllZone = "";
                }
                //(end) set dropdown by default empty

                // if(result.data[0].airStartDate!= null){

                //     let startTime = result.data[0].airStartDate.split('T').pop();
                //     console.log("startTime>>",startTime);
                //     startTime = startTime.slice(0, -5);


                //     this.createAssetGroupForm.controls['airStartDate'].setValue(moment(result.data[0].airStartDate).format("YYYY-MM-DD"));
                //     this.createAssetGroupForm.controls['airStartTime'].setValue(startTime);
                // }
            
                // if(result.data[0].airEndDate!= null){
                    
                //     let endTime = result.data[0].airEndDate.split('T').pop();
                //     console.log("endTime>>",endTime);
                //     endTime = endTime.slice(0, -5);
                
                //     this.createAssetGroupForm.controls['airEndDate'].setValue(moment(result.data[0].airEndDate).format("YYYY-MM-DD"));
                //     this.createAssetGroupForm.controls['airEndTime'].setValue(endTime);
                // }

                // if(result.data[0].airStartDate!= null){

                //     let startTime = result.data[0].airStartDate.split('T').pop();
                //     let date = result.data[0].airStartDate.replace(startTime, '');
                //     date = date.replace('T', '');
                //     startTime = startTime.slice(0, -5);
                
                //     this.createAssetGroupForm.controls['airStartDate'].setValue(date);
                //     this.createAssetGroupForm.controls['airStartTime'].setValue(startTime);
                // }
            
                // if(result.data[0].airEndDate!= null){
                    
                //     let endTime = result.data[0].airEndDate.split('T').pop();
                //     let date = result.data[0].airEndDate.replace(endTime, '');
                //     date = date.replace('T', '');
                //     endTime = endTime.slice(0, -5);
                   
                //     this.createAssetGroupForm.controls['airEndDate'].setValue(date);
                //     this.createAssetGroupForm.controls['airEndTime'].setValue(endTime);
                // }

            
            //(end) set dropdown by default empty
            if(result.data[0].airStartDate!= null){

                let startTime = result.data[0].airStartDate.split('T').pop();
                let date = result.data[0].airStartDate.replace(startTime, '');
                 date = date.replace('T', '');
             
                startTime = startTime.slice(0, -5);
                console.log("date",date);

                if(startTime =='0000-00-00 00' || startTime ==''){

                    startTime = '';
                    
                }


                if(date ==''){

                    date = '';
                    
                }

                this.createAssetGroupForm.controls['airStartDate'].setValue(date);
                this.createAssetGroupForm.controls['airStartTime'].setValue(startTime);
            }
        
            if(result.data[0].airEndDate!= null){

                let endTime = result.data[0].airEndDate.split('T').pop();
                let date = result.data[0].airEndDate.replace(endTime, '');
                 date = date.replace('T', '');
             
                endTime = endTime.slice(0, -5);

                if(endTime =='0000-00-00 00'|| endTime =='' ){

                    endTime = null;

                }

                if(date ==''){

                    date = null;
                    
                }
                console.log("date",date);
                console.log("endTime",endTime);
               
                this.createAssetGroupForm.controls['airEndDate'].setValue(date);
                // this.createNewAssetForm.controls['airEndDate'].setValue(moment(result.data[0].airEndDate).format("YYYY-MM-DD"));
                this.createAssetGroupForm.controls['airEndTime'].setValue(endTime);
            }


                this.createAssetGroupForm.controls['description'].setValue(result.data[0].description);
                this.createAssetGroupForm.controls['exclusionText'].setValue(result.data[0].exclusionText);
                this.createAssetGroupForm.controls['title'].setValue(result.data[0].title);
                this.createAssetGroupForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
                this.createAssetGroupForm.controls['isActive'].setValue(result.data[0].isActive);
                this.createAssetGroupForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
                this.createAssetGroupForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
                this.createAssetGroupForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
                this.createAssetGroupForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
                this.createAssetGroupForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);
                this.createAssetGroupForm.controls['sorting'].setValue(result.data[0].sorting);
                this.createAssetGroupForm.controls['orderBy'].setValue(result.data[0].orderBy);
                this.createAssetGroupForm.controls['assetGroupType'].setValue(result.data[0].assetGroupType);


                this.createAssetGroupForm.controls['publisherState'].setValue(result.data[0].publisherState);
                this.createAssetGroupForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

                console.log("result.data[0]===",result.data[0]);
                if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                    this.createAssetGroupForm.controls['contentState'].setValue(['In-Progress']);
                }
                else{
                    this.createAssetGroupForm.controls['contentState'].setValue(result.data[0].contentState);
                }
            
                this.selectedProductTypeItems=[];
                this.selectedProductlist=[];
                this.productTypeMappingFilter.geoZoneId=[];

                if(result.data[0].results.geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){
                        let obj={
                        'id':result.data[0].results.geoZone[i].geoZoneId,
                        'itemName':result.data[0].results.geoZone[i].geoZoneName,
                        }
                        this.selectedProductTypeItems.push(obj);
                        this.selectedProductTypeItems = this.removeDuplicates(this.selectedProductTypeItems, "id");
            
                        this.mapProductTypeArrayTemp = this.selectedProductTypeItems;
                        this.productTypeMappingFilter.geoZoneId.push(obj.id);
                    }
                    this.createAssetGroupForm.controls['mapGeoZone'].setValue(this.selectedProductTypeItems);
                    this.getProductTypeInfo(this.productTypeMappingFilter);
                    
                }    

                if( result.data[0].results.cast.length>0){

                    this.selectedmapCast=[]
                    
                        for(let i = 0 ;i < result.data[0].results.cast.length;i++){
                            let obj={
                            'id':result.data[0].results.cast[i].castId,
                            'itemName':result.data[0].results.cast[i].castFirstName,
                            }
                            this.selectedmapCast.push(obj);  
                        }

                    this.createAssetGroupForm.controls['mapCast'].setValue(this.selectedmapCast);
                }

                this.selectedmapCast=[];
                if( result.data[0].results.character.length>0){

                    for(let i = 0 ;i < result.data[0].results.character.length;i++){
                        let obj={
                        'id':result.data[0].results.character[i].characterId,
                        'itemName':result.data[0].results.character[i].characterFirstName,
                        }
                        this.selectedmapCast.push(obj);  
                        this.createAssetGroupForm.controls['mapCharacter'].setValue(this.selectedmapCast);
                    }

               
                }
    
                if(result.data[0].results.primaryNavigation.length>0){

                    this.selectedprimaryNavigationList = [];

                    for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                        let obj={
                            'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                            'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                        }
                        this.selectedprimaryNavigationList.push(obj);
                        this.mapPrimaryNavigationArrayTemp = this.selectedprimaryNavigationList;
                        this.menuCategoryMappingFilter.primaryNavigationId.push(obj.id);
                        this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
                    }
                  
                    this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                }
                       
                let certificationArray=[];
                if( result.data[0].results.certification.length>0){

                    if(result.data[0].results.certification.length>0){

                        for(let i = 0 ;i < result.data[0].results.certification.length;i++){
                            let obj={
                            'id':result.data[0].results.certification[i].certificationId,
                            'itemName':result.data[0].results.certification[i].certificationName,
                            }
                            certificationArray.push(obj);  

                            this.createAssetGroupForm.controls['mapCertification'].setValue(certificationArray);

                        }
                    }
                }

                if( result.data[0].results.menuCategory.length>0){

                    if(result.data[0].results.menuCategory.length>0){

                        this.selectedmenuCategoryList = [];

                        for(let i = 0 ;i < result.data[0].results.menuCategory.length;i++){
                            let obj={
                            'id':result.data[0].results.menuCategory[i].menuCategoryId,
                            'itemName':result.data[0].results.menuCategory[i].menuCategoryName,
                            }
                            this.selectedmenuCategoryList.push(obj); 
                            this.mapMenuCategoryArrayTemp = this.selectedmenuCategoryList;
                        }
                    }
                    this.createAssetGroupForm.controls['mapMenuCategory'].setValue(this.selectedmenuCategoryList);
                }

                if(result.data[0].results.productType.length>0){

                    // this.selectedProductlist = [];

                    for(let i = 0 ;i < result.data[0].results.productType.length;i++){

                        let obj={
                        'id':result.data[0].results.productType[i].productTypeId,
                        'itemName':result.data[0].results.productType[i].productTypeName,
                        }
                        this.selectedProductlist.push(obj);
                        this.mapProductTypeArrayTemp=this.selectedProductlist;  
                        this.primaryNavigationMappingFilter.productTypeId.push(obj.id);

                    }

                    this.createAssetGroupForm.controls['mapProductType'].setValue(this.selectedProductlist);
                    this.getNavigationInfo(this.primaryNavigationMappingFilter);
                }

                let arrayObj=[]
                if(result.data[0].results.genre.length>0){

                    for(let i = 0 ;i < result.data[0].results.genre.length;i++){

                        let obj={
                        'id':result.data[0].results.genre[i].genreId,
                        'itemName':result.data[0].results.genre[i].genreName,
                        }
                        arrayObj.push(obj);
                    }
                    this.createAssetGroupForm.controls['mapGenre'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.sponsor.length>0){

                    for(let i = 0 ;i < result.data[0].results.sponsor.length;i++){

                        let obj={
                        'id':result.data[0].results.sponsor[i].sponsorId,
                        'itemName':result.data[0].results.sponsor[i].sponsorName,
                        }
                        arrayObj.push(obj);
                    }
                    this.createAssetGroupForm.controls['mapSponsor'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.language.length>0){

                    for(let i = 0 ;i < result.data[0].results.language.length;i++){

                        let obj={
                        'id':result.data[0].results.language[i].languageId,
                        'itemName':result.data[0].results.language[i].languageName,
                        }
                        arrayObj.push(obj); 
                    }
                    this.createAssetGroupForm.controls['mapLanguage'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.library.length>0){

                    for(let i = 0 ;i < result.data[0].results.library.length;i++){

                        let obj={
                        'id':result.data[0].results.library[i].libraryId,
                        'itemName':result.data[0].results.library[i].libraryName,
                        }
                        arrayObj.push(obj);
                    }
                    this.createAssetGroupForm.controls['mapLibrary'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.review.length>0){

                    for(let i = 0 ;i < result.data[0].results.review.length;i++){

                        let obj={
                        'id':result.data[0].results.review[i].reviewId,
                        'itemName':result.data[0].results.review[i].reviewFirstName,
                        }
                        arrayObj.push(obj);
                    }
                    this.createAssetGroupForm.controls['mapReview'].setValue(arrayObj);
                }

                arrayObj=[]
                if(result.data[0].results.classification.length>0){

                    for(let i = 0 ;i < result.data[0].results.classification.length;i++){

                        let obj={
                        'id':result.data[0].results.classification[i].classificationId,
                        'itemName':result.data[0].results.classification[i].classificationName,
                        }
                        arrayObj.push(obj);  
                    }
                    this.createAssetGroupForm.controls['mapClassification'].setValue(arrayObj);
                }

                this.img=[];
                if(result.data[0].results.productDeviceTypeConfigure.length>0){

                    this.multipleDeviceForm = this.formBuilder.group({
                        devices: this.formBuilder.array([])
                    });

                //device type validation code start
                    if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                        this.deviceTypeFull = true;
                    }
                  //device type validation code end
        
                        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
                        
                        for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                            this.img.push({
                                horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                                verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                            })

                            let objH = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "horizontalFileName": "",
                                "horizontalFilePath": "",
                                "horizontalFileType": "",
                                'horizontalFile':false
                            }
                
                            let objV = {
                                'index':"",
                                'deviceType':"",
                                'selectedFiles':"",
                                "verticalFileName":"",
                                "verticalFilePath": "",
                                "verticalFileType":"",
                                'verticalFile':false,
                                
                            }
                
                            this.uploadImgFilePathHorizontal.push(objH);
                            this.uploadImgFilePathVertical.push(objV);


                            this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                            this.createAssetGroupForm.controls['contentType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].contentType);
                            this.createAssetGroupForm.controls['sorting'].setValue(result.data[0].results.productDeviceTypeConfigure[i].sorting);
                            this.createAssetGroupForm.controls['orderBy'].setValue(result.data[0].results.productDeviceTypeConfigure[i].orderBy);
                            this.createAssetGroupForm.controls['assetGroupType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].assetGroupType);
                          
                       
                            
                        }
                }   
                
        });

    }

    
    castFilter: any = {};

    getCastInfo(){
        
        this.commonService.getGlobalCast(this.castFilter,this.startCast,10).subscribe((result:any)=>{
         
          //  this.mapCastList = [];
          this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){   
                
                if(this.isSearch == true){

                    this.mapCastList = [];
                    this.isSearch = false;
                
                }

                for (let castLists of result.data.data) {

                    if(castLists.castFirstName!=''){
                    this.mapCastList.push(
                        {
                            'id': castLists.castId,
                            'itemName': castLists.castFirstName
                        });
                    }
                    
                    this.mapCastList = this.removeDuplicates(this.mapCastList, "id");

                }
                
            } 

        });
       
    }

    startCast = 0;
    fetchMoreGetCast(event){
        
        if ((event.endIndex == this.mapCastList.length - 1) && (this.mapCastList.length>=8)) {

                this.startCast = this.startCast + 10;
                this.getCastInfo(); 
           
        }
    }

    selectFileVertical(e ,i) {

        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].verticalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

       
    }

    selectFileHorizontal(e ,i) {

      //set obj for file upload of horizontal section
      if(e.target.files[0].name==undefined)
      return;

      this.uploadImgFilePathHorizontal[i].index = i;
      this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
      this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
      this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
      this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
      this.uploadImgFilePathHorizontal[i].horizontalFile = true; 

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })   

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

    }

    isDeleteDeviceType(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
    }


    //need to false assetGroup
    tabFalse(){
        this.formTabs = {

            assetInfo:{
    
             asset:false,
             mapGeoZone:false,
             information:false,
             HierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
    
            }
        }
    }

    // asset group tab

    onAssetInfoTabClick(){

        this.formTabs = {

            assetInfo:{
    
             asset:true,
             mapGeoZone:true,
             information:false,
             hierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }
    }

    onHierarchyTabClick(){

        this.selectedprimaryNavigationList = this.removeDuplicates(this.selectedprimaryNavigationList,'id');

        setTimeout(()=>{

            this.createAssetGroupForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);

        },300)

        this.formTabs = {

            assetInfo:{
    
             asset:true,
             mapGeoZone:false,
             information:false,
             hierarchyMapping:true,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
    
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }
    }

    oninformationTabClick(){

       
        this.formTabs = {

            assetInfo:{
    
             asset:true,
             mapGeoZone:false,
             information:true,
             HierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
    
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }


    }

    oncastAndCrewTabClick(){

        this.formTabs = {

            assetInfo:{
    
             asset:false,
             mapGeoZone:false,
             information:false,
             HierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:true,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:true,
                orderingAndSorting:false
    
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }
    }

    onOrderingTabClick(){

        this.formTabs = {

            assetInfo:{
    
             asset:false,
             mapGeoZone:false,
             information:false,
             HierarchyMapping:false,
             seo:false
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:true,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:true
    
            },
            // ordering:true,
            // otherMapping:false,
            // seo:false
        }

    }
    
    onOtherMappingTabClick(){

        this.formTabs = {

            assetInfo:{
    
             asset:false,
             mapGeoZone:false,
             information:false,
             HierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:false,
                configuration:false,
                horizontal:false,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:true,
                metadataHierarchy:false,
                Information:true,
                castAndCrew:false,
                orderingAndSorting:false
    
            },
            // ordering:false,
            // otherMapping:true,
            // seo:false
        }

    }

    onHorizontalTabClick(){
        this.formTabs = {

            assetInfo:{
    
             asset:false,
             mapGeoZone:false,
             information:false,
             HierarchyMapping:false,
             seo:false,
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:true,
                vertical:false
    
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{
    
                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }
    }

    onVerticalTabClick(){
        
        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            HierarchyMapping:false,
            seo:false,
            },
            deviceType:{
                
                configurationType:true,
                configuration:false,
                horizontal:false,
                vertical:true

            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            metadata:{

                metadata:false,
                metadataHierarchy:false,
                Information:false,
                castAndCrew:false,
                orderingAndSorting:false
            },
            // ordering:false,
            // otherMapping:false,
            // seo:false
        }
    }


    onDeviceTypeTabClick(){
 
       this.formTabs = {

        assetInfo:{

         asset:false,
         mapGeoZone:false,
         information:false,
         HierarchyMapping:false,
         seo:false,
        },
        deviceType:{
            
            configurationType:true,
            configuration:true,
            horizontal:false,
            vertical:false

        },
        assetLiveVod:{
            
            vod:false,
            live:false,
            assetLiveVod:false
        },
        metadata:{

            metadata:false,
            metadataHierarchy:false,
            Information:false,
            castAndCrew:false,
            orderingAndSorting:false
        },
        // ordering:false,
        // otherMapping:false,
        // seo:false
    }
    }

    onSeoTabClick(){

            this.formTabs = {

                assetInfo:{

                asset:true,
                mapGeoZone:false,
                information:false,
                HierarchyMapping:false,
                seo:true,
            
                },
                deviceType:{
                    
                    configurationType:false,
                    configuration:false,
                    horizontal:false,
                    vertical:false

                },
                assetLiveVod:{
                    
                    vod:false,
                    live:false,
                    assetLiveVod:false
                },
                metadata:{

                    metadata:false,
                    metadataHierarchy:false,
                    Information:false,
                    castAndCrew:false,
                    orderingAndSorting:false
                },
                // ordering:false,
                // otherMapping:false,
                // seo:false
            }

    }
        
    isDeleteHorizontalImage(event){

            console.log('isDeleteHorizontalImageAction event = ',event)
            if(event=='Cancel'){
                this.isDeleteHorizontalImageAction=false;
            }
            
            if(event=='Yes'){
                this.isDeleteHorizontalImageAction=false;
                delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
                this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";

                this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
                this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';
            }

    }

    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }

    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
            
        }

    }

    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }
    
    
    resetForm(){

        
    this.productTypeMappingFilter = {};
    this.primaryNavigationMappingFilter = {};
    this.menuCategoryMappingFilter = {};
    this.selectedprimaryNavigationList = [];
    this.mapPrimaryNavigationArrayTemp = [];

        this.mapProductTypeArrayTemp = [];
        this.mapLanguageList = [];
        this.productScrollCount = 0;
        this.mapMenuCategoryArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp = [];

        this.mapSponsoreList = [];

        this.SponsoredScrollCount = 0;
        this.startSponsored = 0;
        this.characterScrollCount = 0;

        this.startReview = 0;

        this.onAssetInfoTabClick();

        this.update = false;
        this.img = [];
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        while (arrayControl.length !== 0) {

            arrayControl.removeAt(0);

        }

        this.id = null;

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        let objH = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);
    
        this.createAssetGroupForm = this.formBuilder.group({
            id:[""],
            assetGroupType : [""],
            contentType :[""],
            isActive : ["1"],
            langId: [this.langId],
            companyId : [""],
            isVisible:[''],
            title :["",Validators.required],
            displayIndexing: [''],
            sameForAllZone : [''],
            sameForAllProductType : [''],
            airStartDate : [''],
            airEndDate : [""],
            airStartTime:[''],
            airEndTime:[''],
            exclusionText : [""],
            description : [""],
            seoTitle : [""],
            seoKeyword : [""],
            seoDescription : [""],
            orderBy : [""],
            sorting : [""],
            mapGeoZone:[[], Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[[]],
            mapProductType:[[], Validators.compose([Validators.required])],
            mapPrimaryNavigation:[[]],
            mapMenuCategory:[[]],
            mapCertification:[[]],
            mapClassification:[[]],
            mapCast:[[]],
            mapGenre:[[]],
            mapCharacter:[[]],
            // mapAssetLibrary:[[]],
            mapLibrary:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]
        
        })
            
        

        this.update = false;

        //////////////////////

        // this.img.push({
        //     horizontalURL:'',
        //     verticalURL:''
        // })  
       
        //reset device type image
        this.img = [];
        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })   
      
        this.updateSend.emit();

        this.multipleDeviceForm = this.formBuilder.group({

            devices: this.formBuilder.array([this.createItem()])

        });

        this.showCreateNewButton('show');

    }
   

    showCreateNewButton(isShow){

        if(isShow == 'show'){

            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

    
}
