import { Injectable } from '@angular/core';

@Injectable()

export class SessionHelper {
    static IS_LOGGED_IN: string = "IsLoggedIn";
    static USER: string = "User";
    static user: string = "User";
    static loginUser:string="loginUser";
    constructor() {
    }

    static isLoggedIn = () => { return JSON.parse(localStorage.getItem(SessionHelper.IS_LOGGED_IN)) || false; }


    static getRole = () => {
       
        return JSON.parse(localStorage.getItem('roleConfiguration')); 
       
    }

    static setRole(data: any) {
        localStorage.setItem('roleConfiguration', JSON.stringify(data));
       
    }


    static logout = () => {
        localStorage.clear();
    }
}