import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  
  constructor(private http: HttpClient) { }

  updateClient(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateClientUrl, postObj,httpOptions);

  }
        
  insertClient(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertClientUrl, postObj,httpOptions);

  }    
  
  getClient( id: any) : Observable<any> {
      

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getClientUrl+"/" + id);

      

  }

  getAllClient(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllCast = environment.apiURL + environment.adminApiUrl + environment.getClientUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllCast)

  }

  deleteClient(postObj:any) : Observable<any> {

      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteClientUrl, postObj,httpOptions);
      

    }
  
}
