import { Component, OnInit,Input,EventEmitter,Output} from '@angular/core';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-datatabel',
  templateUrl: './datatabel.component.html',
  styleUrls: ['./datatabel.component.scss']
})
export class DatatabelComponent implements OnInit {

    page :any = {};
    @Input() rows:any;
    @Input() columns:any;
    @Input() infoObj:any;
    
    @Output() rowsDataSend = new EventEmitter<any>();
    @Output() actionObjSend = new EventEmitter<any>();
    @Output() actionApiSend = new EventEmitter<any>();
    
    @ViewChild('table') table: DatatabelComponent;

    constructor() { }

    ngOnInit() {

      console.log("this.table.offset = 0;",this.table);
      console.log("this.infoObj",this.infoObj);
      
    }
    
    updateFilter(event) {

        this.rowsDataSend.emit(event);

    }

    onActivate(event) {

      if(event.type == 'click') {
        
          console.log(event.row.id);
          console.log(event.column.prop);  
          let infoObj={
            'id':event.row.id,
            'languageId':event.row.langaugeId,
            'action':event.column.prop
           
          }
          this.actionObjSend.emit(infoObj);
      }
    }

    setPage(pageInfo){
      console.log("pageInfo",pageInfo);
      this.actionApiSend.emit(pageInfo)

    }
}
