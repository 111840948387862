import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {Loginshared} from '../../services/sharedservices/loginshared.service'
//import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../cognito/cognito.service";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate  {
    
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;
    isLoggedIns:boolean=false;
   
    constructor( private router: Router ,public loginshared:Loginshared) { }
    
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        let uid = localStorage.getItem('ud');
           if(uid){
            
            console.log("login>>",this.router.url);
           
      
                return true;

            } else{

                console.log("login111>>",this.router);
                this.router.navigate(['/login']);
               
                return false;
	       
            }
        
    }
    
}


