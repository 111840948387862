import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

    constructor(private http: HttpClient) { }

    getAllNews(filterObj,start,limit){

      let urlGetAllNews = environment.apiURL + environment.adminApiUrl + environment.getNewsUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllNews)
      
    }
      
    updateNews(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateNewsUrl, postObj,httpOptions);
    }
          
    insertNews(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertNewsUrl, postObj,httpOptions);
    } 

    getNews( id: any) : Observable<any> {
      
        if(id){

        }
        else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getNewsUrl+"/" + id);
    }

    deleteNews(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteNewsUrl, postObj,httpOptions);
    }




    /*========================= 
    News Category
    ========================== */


    getAllNewsCategory(filterObj,start,limit){

        filterObj.languageId = undefined;

        let urlGetAllNewsCategory = environment.apiURL + environment.adminApiUrl + environment.getNewsCategoryUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllNewsCategory)
        
    }
      
    updateNewsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateNewsCategoryUrl, postObj,httpOptions);
    }
          
    insertNewsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertNewsCategoryUrl, postObj,httpOptions);
    } 

    getNewsCategory( id: any) : Observable<any> {
      
        if(id){

        }
        else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getNewsCategoryUrl+"/" + id);
    }

    deleteNewsCategory(postObj:any) : Observable<any> {

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteNewsCategoryUrl, postObj,httpOptions);
    }

}
