
export const environment = {
  
       production: false,
       projectName:'Dcafe',
    // apiURL: 'https://c7lizeeca5.execute-api.us-east-1.amazonaws.com/local-dev/', //dcafe-development
  //  apiURL: 'https://artistaloud.preprod.dctinc.net/',
     apiURL: 'https://iao2ttozd0.execute-api.us-east-1.amazonaws.com/stage/', //dcafe-development

    //  apiURL: 'https://5jt3oa4k68.execute-api.us-east-1.amazonaws.com/dev', //tcs development

    // apiURL: 'https://2cs12gc8pa.execute-api.us-east-1.amazonaws.com/vuuzle-dev/', //vuuzle development

   //  apiURL: 'https://g74tnm96ic.execute-api.us-east-1.amazonaws.com/dev/', //artistaloud development

    //  apiURL: 'https://hw78twul1h.execute-api.us-east-1.amazonaws.com/oap-dev/', //oap development

    // apiURL: 'https://vuuzleapi.dcafecms.com/', //vuuzle prod development

    // apiURL: 'https://hw78twul1h.execute-api.us-east-1.amazonaws.com/oap-dev/', //vuuzle prod development
    
    

    apiURLEmbed: 'https://vuuzletv.dcafecms.com/', 
      
    // apiURL: 'http://localhost:3200/', //vuuzle prod development:

    faviconImg:'../assets/images/favicon.ico',
    dashbordMisc:'misc/data',
    dashbordAnalytics:'/analytics/subscription',
    dashbordvideoTop:'/analytics/videos/top10',
    dashbordplatform:'/analytics/videos/platform',


    logoPath:'assets/images/dcafeLogo.svg',
    adminApiUrl:'admin',
    authApiUrl:'/auth',
    loginUrl:'/login',
    forgotPasswordUrl:'/forgotpassword',
    updatePasswordUrl:'/updatepassword',
    configurationApiUrl:'/configuration',
    getProductConfigurationUrl:'/productconfiguration',
    updateProductConfigurationUrl:'/productconfiguration/update',
    insertProductConfigurationUrl:'/productconfiguration',

    // getGeoZoneUrl:'/geozone',
    createFileUrl:'/file',
    getProductTypeUrl:'/producttype',
    getGlobalProductTypeUrl:'/globalproducttype',
    updateProductTypeUrl:'/producttype/update',
    deleteProductTypeUrl:'/producttype/delete',
    insertProductTypeUrl:'/producttype',
    getPrimaryNavigationUrl:'/navigation',
    updatePrimaryNavigationUrl:'/navigation/update',
    deletePrimaryNavigationUrl:'/navigation/delete',
    insertPrimaryNavigationUrl:'/navigation',
    getGlobalPrimaryNavigationurl:'/globalprimarynavigation',
    getGlobalCertificationUrl:'/globalcertification',
    getGlobalGeozoneUrl:'/globalgeozone',
    globaltrailervideoUrl:'/globaltrailervideo',
    globalPageCategoryUrl:'/globalpagecategory',
    globalBlogUrl:'/globalblog',
    globalNewsUrl:'/globalnews',
    roleConfiguration:'/roleconfiguration',

    getAssetUrl:'/asset',
    updateAssetUrl:'/asset/update',
    deleteAssetUrl:'/asset/delete',
    insertAssetUrl:'/asset',
    refreshUplynkUrl:'/channel/list',
    reconcillationUrl:'/reconcillation',
    deleteReconcillationUrl:'/reconcillation/delete',
    reconcillationVideoUrl:'/videos',
    
    getAssetGroupUrl:'/assetgroup',
    updateAssetGroupUrl:'/assetgroup/update',
    deleteAssetGroupUrl:'/assetgroup/delete',
    insertAssetGroupUrl:'/assetgroup',
    
    getAssetCategoryUrl:'/assetcategory',
    updateAssetCategoryUrl:'/assetcategory/update',
    deleteAssetCategoryUrl:'/assetcategory/delete',
    insertAssetCategoryUrl:'/assetcategory',

    
    getMrssQueueUrlList:'/mrss/queue/list',
    getMrssQueueUrl:'/mrss/queue',
    deleteMrssQueueUrl:'/mrss/queue/delete',
    insertMrssQueueUrl:'/mrss/queue/add',

    
    getClientUrl:'/clients',
    deleteClientUrl:'/clients/delete',
    insertClientUrl:'/clients',
    updateClientUrl:'/clients/update',

  
    
    getProfessionUrlList:'/profession/list',
    getProfessionUrl:'/profession',
    updateProfessionUrl:'/profession/update',
    deleteProfessionUrl:'/profession/delete',
    insertProfessionUrl:'/profession',


    getMrssPartnerUrl:'/mrss/partner/list',
    getMrssPartnerbyIdUrl:'/mrss/partner',
    updateMrssPartnerUrl:'/mrss/partner/edit',
    deleteMrssPartnerUrl:'/mrss/partner/delete',
    insertMrssPartnerUrl:'/mrss/partner/add',

    globaluplynkadserverUrl:'/globaluplynkadserver',
    globalliveeventUrl:'/globalliveevent',
    globalassetlibraryUrl:'/globalassetlibrary',
    globallibraryUrl:'/globallibrary',
    globalRoleUrl:'/globalrole',
    globallivevirtualchannelUrl:'/globallivevirtualchannel',
    globalCastUrl:'/globalcast',
    globalsponsorUrl:'/globalsponsor',
    getGlobalCharacterUrl:'/globalcharacter',
    getGlobalProfessionUrl:'/globalprofession',
    globalPageUrl:'/globalpage',
    

    getCastUrl:'/cast',
    updateCastUrl:'/cast/update',
    deleteCastUrl:'/cast/delete',
    insertCastUrl:'/cast',
    
    getCharacterUrl:'/character',
    updateCharacterUrl:'/character/update',
    deleteCharacterUrl:'/character/delete',
    insertCharacterUrl:'/character',


    getGeoZoneUrl:'/geozone',
    updateGeoZoneUrl:'/geozone/update',
    deleteGeoZoneUrl:'/geozone/delete',
    insertGeoZoneUrl:'/geozone',


    getCarouselUrl:'/carousel',
    updateCarouselUrl:'/carousel/update',
    deleteCarouselUrl:'/carousel/delete',
    insertCarouselUrl:'/carousel',
   

    getPageCategoryUrl:'/pagecategory',
    updatePageCategoryUrl:'/pagecategory/update',
    deletePageCategoryUrl:'/pagecategory/delete',
    insertPageCategoryUrl:'/pagecategory',

    updateMenuCategoryUrl:'/menucategory/update',
    insertMenuCategoryUrl:'/menucategory',
    getMenuCategoryUrl:'/menucategory',
    deleteMenuCategoryUrl:'/menucategory/delete',
    globalmenucategoryUrl:'/globalmenucategory',
    globalLanguageUrl:'/globallanguage',
    globalAssetGroupUrl:'/globalassetgroup',
    globalAssetCategoryUrl:'/globalassetcategory',
   
    getSubscriptionUrl:'/subscription',
    updateSubscriptionUrl:'/subscription/update',
    deleteSubscriptionUrl:'/subscription/delete',
    insertSubscriptionUrl:'/subscription',

    globalContinentListUrl: '/globalcontinent',
    globalCountryListUrl:'/globalcountry',
    globalCityListUrl:'/globalcity',
    globalStateListUrl:'/globalstate',
    globalDeviceTypeListUrl:'/globaldevicetype',
    globalLoginSourceListUrl:'/globalloginsource',
    globalReviewUrl:'/globalreview',
    globalclassificationUrl:'/globalclassification',

    globalGenreUrl:'/globalgenre',

    getGenreUrl:'/genre',
    updateGenreUrl:'/genre/update',
    deleteGenreUrl:'/genre/delete',
    insertGenreUrl:'/genre',

    insertUserUrl:'/user',
    getUserUrl:'/user',
    updateUserUrl:'/user/update',
    deleteUserUrl:'/user/delete',
    checkUrl:'/check',
    
    insertRoleUrl:'/role',
    getRoleUrl:'/role',
    updateRoleUrl:'/role/update',
    deleteRoleUrl:'/role/delete',
    
    insertRoleConfigurationUrl:'/roleconfiguration',
    getRoleConfigurationUrl:'/roleconfiguration',
    updateRoleConfigurationUrl:'/roleconfiguration/update',
    deleteRoleConfigurationUrl:'/roleconfiguration/delete',

    insertSponsorUrl:'/sponsor',
    getSponsorUrl:'/sponsor',
    updateSponsorUrl:'/sponsor/update',
    deleteSponsorUrl:'/sponsor/delete',

    insertReviewUrl:'/review',
    getReviewUrl:'/review',
    updateReviewUrl:'/review/update',
    deleteReviewUrl:'/review/delete',

    insertClassificationUrl:'/classification',
    getClassificationUrl:'/classification',
    updateClassificationUrl:'/classification/update',
    deleteClassificationUrl:'/classification/delete',

    insertUplynkadserverUrl: '/uplynkadserver',
    getUplynkadserverUrl: '/uplynkadserver',
    updateUplynkadserverUrl: '/uplynkadserver/update',
    deleteUplynkadserverUrl: '/uplynkadserver/delete',


    insertClientadserverUrl: '/clientadserver',
    getClientadserverUrl: '/clientadserver',
    updateClientadserverUrl: '/clientadserver/update',
    deleteClientadserverUrl: '/clientadserver/delete',


    insertFooterlinkUrl:'/footerlink',
    getFooterlinkUrl:'/footerlink',
    updateFooterlinkUrl:'/footerlink/update',
    deleteFooterlinkUrl:'/footerlink/delete',

    insertBlogsUrl:'/blog',
    getBlogsUrl:'/blog',
    updateBlogsUrl:'/blog/update',
    deleteBlogsUrl:'/blog/delete',

    insertBlogsCategoryUrl:'/blogcategory',
    getBlogsCategoryUrl:'/blogcategory',
    updateBlogsCategoryUrl:'/blogcategory/update',
    deleteBlogCategorysUrl:'/blogcategory/delete',

    insertNewsUrl:'/news',
    getNewsUrl:'/news',
    updateNewsUrl:'/news/update',
    deleteNewsUrl:'/news/delete',

    insertNewsCategoryUrl:'/newscategory',
    getNewsCategoryUrl:'/newscategory',
    updateNewsCategoryUrl:'/newscategory/update',
    deleteNewsCategoryUrl:'/newscategory/delete',

    bulkImportUrl:'/bulkupload/import',
    bulkExportUrl:'/bulkupload/export',

    deleteOrderUrl: '/order/delete',
    getOrderUrl: '/order',
    getSearchUrl: '/search',
    statusUpdateUrl :'/statusmanagement/update',
    getTracksUrl:'/track',

    
    /*region: 'us-east-1',
    identityPoolId: 'us-east-1:6eda459e-a901-490f-9dbd-718b00f1f72e',
    userPoolId: 'us-east-1_kg0C0IMlD',
    clientId: '5rlic1tomghnkvgm6h87tubu9m',
    bucketRegion: 'us-east-1',
    */
    region: 'us-east-2',
    identityPoolId: 'us-east-2:a8b553c5-8332-4b6e-a0c8-7e2f24203202',
    userPoolId: 'us-east-2_RBy14rj1C',
    //clientId: 't9drh85karb7mar7pp9ackqi6',
    clientId: '7lkn0ioek80sumuh01b9ftq1a1',
    rekognitionBucket: 'rekognition-pics',
    albumName: "usercontent",
    bucketRegion: 'us-east-2',
    ddbTableName: 'LoginTrail',
    cognito_idp_endpoint: '',
    cognito_identity_endpoint: '',
    sts_endpoint: '',
    dynamodb_endpoint: '',
    s3_endpoint: '',

    //below are

    configurationPageTitle:'configuration',
    conPageSubTitle:'Product Configuration',
    conPagePTSubTitle:'Product Type',

    navigationPageTitle:'Navigation',
    nPageSubTitle:'Primary Navigation',
    nMenuCatPageSubTitle:'Menu',

    assetPageTitle:'Asset Management',
    aGroupSubTitle:'Asset Group',
    acategorySubTitle:'Asset Category',
    aSubTitle:'Asset',
    aReconSubTitle:'Asset Reconcillition',
    aTracksSubTitle:'Tracks',

    mrssIngestionPageTitle:'Mrss Partner',
    mPartnerSubTitle:'Mrss Partner',
    mQueueSubTitle:'Mrss Queue',
    
    dashbordTitle:'SCV',
    dashbordSubTitle:'Single Customer View',

    pagePageTitle:'Page Management',
    pCategoryPageTitle:'Page Category',
    pCarouselPageTitle:'Carousel',

    masterPageTitle:'Master',
    mGenresPageTitle:'Genres',
    mLangPageTitle:'Language',
    mGeoZoneSubTitle:'Geo Zone',
    mCandCSubTitle:'Caste and Crew',
    mSponsorSubTitle:'Sponsor',
    mReviewsSubTitle:'Reviews',
    mCustomSubPage:'Custom',
    mUserverSubPage:'Uplynk Ad Server',

    mFooterSubPage:'Footer links',
    mCharacterSubPage:'Character',
    mBCSubPage:'Blogs Category',
    mBlogSubPage:'Blog',
    mNCSubPage:'News Category',
    mNSubTitle:'News',
    mPSubTitle:'Profession',

    monetizationPageTitle:'Monetization',
    moneSubscriptionSubPage:'Subscription',
    moneOrderSubPage:'Order',

    userPageTitle:'User Management',
    userSubTitle:'User',
    uRoleSubTitle:'Role',


    
    //aws configuration

    awsConfiguration: {

      'accessKeyId':'AKIASYXB26NJKYQM2ZLR',
      'secretAccessKey':'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',
      'region':'us-west-1',
      'bucketName' : 'dcafe-content'
    },


};
