import { Injectable } from '@angular/core';

import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageCategoryService {


  constructor(private http: HttpClient) { }

  updatePageCategory(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updatePageCategoryUrl, postObj,httpOptions);
  }
        
  insertPageCategory(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertPageCategoryUrl, postObj,httpOptions);
  }    
  
  getPageCategory( id: any, langId:any ) : Observable<any> {
      
    langId = localStorage.getItem('languageId');  
    if(langId == null){       
        langId = '1';  
      }

      if(id){
        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getPageCategoryUrl+"/" + id +"/" + langId);
      }
      
  }

  getAllPageCategory(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');
    if(filterObj.languageId == null){       
      filterObj.languageId = '1';  
   }

      let urlGetAllAsset = environment.apiURL + environment.adminApiUrl + environment.getPageCategoryUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllAsset)
  }

  deletePageCategory(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deletePageCategoryUrl, postObj,httpOptions);
  }
}
