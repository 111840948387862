import { Injectable } from '@angular/core';
import { httpOptions } from '../../configurations/configuration';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientAdServerService {

  constructor(private http: HttpClient) { }

  updateClientadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateClientadserverUrl, postObj,httpOptions);
  }
        
  insertClientadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertClientadserverUrl, postObj,httpOptions);
  } 

  getClientadserver( id: any) : Observable<any> {
    
      if(id){

      }
      else {
          id = 1;
      }

      return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getClientadserverUrl+"/" + id);
  }

  getAllClientadserver(filterObj,start,limit){

    filterObj.languageId = localStorage.getItem('languageId');

      let urlGetAllClientadserver = environment.apiURL + environment.adminApiUrl + environment.getClientadserverUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
      return this.http.get<any>(urlGetAllClientadserver)
  }

  deleteClientadserver(postObj:any) : Observable<any> {
      return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteClientadserverUrl, postObj,httpOptions);
  }

}
