import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

    constructor(private http: HttpClient) {
    
    }

    insertRole(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertRoleUrl, postObj,httpOptions);
    }

    getRole( id: any) : Observable<any> {

        if(id){

        } else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getRoleUrl+"/" + id);
    }
        
    getAllRole(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');

        let urlGetAllRole = environment.apiURL + environment.adminApiUrl + environment.getRoleUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllRole)
    }

    deleteRole(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deleteRoleUrl, postObj,httpOptions);
    }

    updateRole(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateRoleUrl, postObj,httpOptions);
    }
        
    //Role configuration 
    insertRoleConfiguration(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertRoleConfigurationUrl, postObj,httpOptions);
    }
        
    updateRoleConfiguration(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updateRoleConfigurationUrl, postObj,httpOptions);
    }
                
    getRoleConfiguration( id: any) : Observable<any> {
        
        if(id){

        } else {
            id = 1;
        }

        return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getRoleConfigurationUrl+"/" + id);
    }
           
}