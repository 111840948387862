import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { RoleService } from '../../../../../services/role/role.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage'
import { CommonService } from '../../../../../services/common_Service/common.service';

@Component({
  selector: 'app-addRole',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentRole implements OnInit {

  isUserLoggedIn: boolean = false;
  createRoleForm:FormGroup;
  errorMessages = errorMessages;
  roleSubmitted:boolean = false;

  isCreateNewShow:boolean = true;

  id:any;
  update=false;

  loader:boolean=false;

  contentReadyToReview:boolean = false;
  shareMassage:any;

  //@Input() contentId:any;
  @Output() updateSend = new EventEmitter<any>();

  //New Code start
  @Input() set contentId(value: any) {

      console.log("------------R-E-S-E-T------- ",value);

      if(value == 'reset'){

          this.resetForm();

      }else {
        //start code for hide create new button
        if(value != 'null'){
            this.showCreateNewButton('hide');
        }
        //end code for hide create new button
          this.onLoad(value);
      }
      
  }
  //New Code end
    
    globalPageList:any=[];
      
    age = [
        { id: 1, value: 'Adults' },
        { id: 2, value: 'Children (6-17)' },
        { id: 3, value: 'Creatures' },
        {
          id: 4,
          value: 'Early Childhood (0-5)'
        },
        { id: 5, value: 'Youth (18-35)' }
    ];  
      
    variableForm:FormGroup;
    items: FormArray;
    applyBlackTheme = localStorage.getItem('boolThemeFlag');
      
    arrPage:any = [];
      
  constructor(private commonService: CommonService,  private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private roleService: RoleService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  roleAsses:any={};
  roleConfiguration:any=[];
  ngOnInit() {

    this.roleAsses.roleEdit = false;
    this.roleAsses.role = false;
    this.roleAsses.roleCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {

        console.log("element>>>",element.pageTitle);
        console.log("element.isView>>>",element);


          if((element.isView == 1 && element.pageTitle == 'Role') ){
              
              this.roleAsses.role = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Role') ){
              
              this.roleAsses.roleEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Role')){

              this.roleAsses.roleCreate = true;
          
          }

    })

      // theme update code start
                        
      this.commonService.currentThemelight.subscribe((result:any)=>{
                        
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
    })

    // theme update code start
  }

  editCreate:boolean = false;

  onCreateNew(){

    if(this.roleAsses.roleCreate == true){

           this.editCreate = true;

    }

    this.isCreateNewShow=false

}

  onLoad(id){

    if(this.id != 'null' && this.roleAsses.roleEdit == true){

        this.editCreate = true;

    }else{

        this.editCreate = false;

    }
    
    let headerTitle = {
        title:'User Management',
        subTitle:'Role'
    }
    this.commonService.changeHeaderTitle(headerTitle);

      let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }
        
        this.variableForm = this.formBuilder.group({
            items: this.formBuilder.array([ this.createItem() ])
        });
        
        this.createRoleForm = this.formBuilder.group({
          roleName: ['', Validators.compose([Validators.required])],
          roleKey:[''],
          id:[''],
          isActive:['1'],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          roleConfigure:[[]],
          created_by:[localStorage.getItem('ud')]

        });
        
        //this.getGlobalPageInfo();
        
        // this.activatedRoute.params.subscribe(params => {
          // this.id = +params['id']; // (+) converts string 'id' to a number
          this.id = id; // (+) converts string 'id' to a number
          if(this.id != 'null' ){
          
            console.log('this.id===>>>',this.id);
          
              this.update=true;
              this.getRoleInfo(this.id)
              
              this.getRoleConfigurationInfo(this.id)
              
          }else{
            this.update=false;
            
                this.getRoleConfigurationInfo('');
                                    
          }

      // });
      
      
      
      
  }
  get l() { return this.createRoleForm.controls; }
    
    createItem(): FormGroup {

        return this.formBuilder.group({
            pageTitle: [''],
            pageId: [''],
            isCreate: [''],
            isEdit: [''],
            isView: [''],
            isDelete: [''],
            isApprove: [''],
            isUnapprove: [''],
            isActivate: [''],
            isDeactive: ['']
        });
    }
    
    updateItem(task): FormGroup {
        console.log("updateItem=======>",task);
        
        console.log("updateItem===PageTitle====>",task.pageTitle);
        console.log("updateItem===PageId====>",task.pageId);
        
        return this.formBuilder.group({
            pageTitle: [task.itemName],
            pageId: [task.pageId],
            isCreate: [task.isCreate],
            isEdit: [task.isEdit],
            isView: [task.isView],
            isDelete: [task.isDelete],
            isApprove: [task.isApprove],
            isUnapprove: [task.isUnapprove],
            isActivate: [task.isActivate],
            isDeactive: [task.isDeactive]
        });
    }
    
    addItem(): void {
        this.items = this.variableForm.get('items') as FormArray;
        this.items.push(this.createItem());
        
    } 
    
        
  getRoleInfo(id){
        
    // this.loader=true;
    this.commonService.changeloader(true);

    this.roleService.getRole(id)
    .subscribe((result:any)=>{

          // this.loader=false;
          this.commonService.changeloader(false);

          console.log("result==========>",result);
          
          this.createRoleForm.controls['roleName'].setValue(result.data[0].roleName);
          this.createRoleForm.controls['roleKey'].setValue(result.data[0].roleKey);
          this.createRoleForm.controls['isActive'].setValue(result.data[0].isActive.toString());
          this.createRoleForm.controls['publisherState'].setValue(result.data[0].publisherState);
          this.createRoleForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

          if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
              this.createRoleForm.controls['contentState'].setValue(['In-Progress']);
          }
          else{
              this.createRoleForm.controls['contentState'].setValue(result.data[0].contentState);
          }

          // console.log("result==========>",result.data[0].isActive.toString());
    
    });
  }
    
    
    getGlobalPageInfo(){
        
        
        //this.variableForm = this.formBuilder.group({
        //   items: this.formBuilder.array([])
        //});
        
        //this.items = this.variableForm.get('items') as FormArray;
        
        this.commonService.getGlobalPage().subscribe((result:any)=>{
      
            this.globalPageList = [];
          
            result.data.data.forEach(element => {
                
                console.log("element=======>",element);
                
                
              this.globalPageList.push(
                {
                    'pageId': element.pageId,
                    'itemName': element.pageTitle
                });
                
                //this.items.push(this.updateItem(element));
                
            });
        });
      
   }
    
    getRoleConfigurationInfo(id){
        
        this.variableForm = this.formBuilder.group({
           items: this.formBuilder.array([])
        });
        
        this.items = this.variableForm.get('items') as FormArray;
        
        console.log("This Items===>",this.items);
        
        this.commonService.getGlobalPage().subscribe((result:any)=>{
      
            this.globalPageList = [];
            
            result.data.data.forEach(element => {
            
                console.log("element=======>",element);
                
                this.globalPageList.push(
                {
                    'pageId': element.pageId,
                    'itemName': element.pageTitle
                });
                
                this.arrPage.push({
                    pageTitle:element.pageTitle
                });
                
                
                //this.items.push(this.updateItem(element));
            });
            
            console.log("this.globalPageList===>",this.globalPageList);
            
            if(id){

                this.commonService.changeloader(true);

                this.roleService.getRoleConfiguration(id)
                .subscribe((resultRoleConfiguration:any)=>{

                    console.log("Role Configuration Response===>",resultRoleConfiguration);  

                    this.commonService.changeloader(false);
                    

                    let newArray = []; 
                    
                    this.globalPageList.forEach(objPage => {
                            
                        this.arrPage.push({
                                pageTitle:objPage.pageTitle
                        });
                            
                        console.log('objPage=======>',objPage);    
                        
                       
                        if(resultRoleConfiguration.data && resultRoleConfiguration.data.length > 0 ){
                            
                            console.log('inside=====>'); 
                            
                            resultRoleConfiguration.data.forEach(element => {
                                
                                if(newArray.indexOf(element.pageId) === -1 ) { 
                                    newArray.push(element.pageId) 
                                    
                                    this.items.push(this.updateItem(element));
                                    
                                } else{ 
                                    console.log("This item already exists");
                                }
                                
                                /*if(objPage.pageId == element.pageId){
                                    this.items.push(this.updateItem(element));
                                } else {
                                    this.items.push(this.updateItem(objPage));
                                }*/
                                //this.items.push(this.updateItem(element));
                            });
                            
                        } else {
                        
                            console.log('outside=====>'); 
                        
                            this.items.push(this.updateItem(objPage));
                        }
                    });
                    
                    
                    /*
                    resultRoleConfiguration.data.forEach(element => {
                        
                        console.log('xxxxxxxxxxx==============>',element);
                        
                        this.arrPage.push({
                            pageTitle:element.pageTitle
                        });
                        
                        //this.items.push(this.updateItem(element));
                    });
                    */
                    
                    
                    
                });

            } else {
                
                result.data.data.forEach(element => {
                 
                    console.log("element==on add page=====>",element);

                    this.items.push(this.updateItem(element));
                    
                });
                
            }
        });
    }
    
    savecreateNewRole(){
        
        console.log('update',this.id);
        console.log("Record received",this.createRoleForm.value);


        console.log(">>>>>>>this.variableForm.value",this.variableForm.value);

        // debugger;

        let arrRoleConfigure = [];

        for(let i=0; i < this.variableForm.value.items.length; i++ ){

            arrRoleConfigure.push(
                {   
                    'pageTitle': this.variableForm.value.items[i].pageTitle,
                    'pageId': this.variableForm.value.items[i].pageId,
                    'isCreate': this.variableForm.value.items[i].isCreate,
                    'isEdit': this.variableForm.value.items[i].isEdit,
                    'isView': this.variableForm.value.items[i].isView,
                    'isDelete': this.variableForm.value.items[i].isDelete,
                    'isApprove': this.variableForm.value.items[i].isApprove,
                    'isUnapprove': this.variableForm.value.items[i].isUnapprove,
                    'isActivate': this.variableForm.value.items[i].isActivate,
                    'isDeactivate': this.variableForm.value.items[i].isDeactive
                }
            );
        }

        if(arrRoleConfigure.length > 0 ){
            this.createRoleForm.controls['roleConfigure'].setValue(arrRoleConfigure);
        }

        if(arrRoleConfigure.length == 0) {

           this.variableForm.reset()
        }

        if( !this.variableForm.valid ){
           this.commonService.changeloader(false); 
           return;
        }

        // this.loader = true;
        this.commonService.changeloader(true);

        this.roleSubmitted = true;

        if(!this.createRoleForm.valid){
          // this.loader = false;
          this.commonService.changeloader(false);
          return;
        }
        
        if(!this.update){

            if(this.roleAsses.roleCreate == false){
                this.toastrService.success('no access to create.')
                this.commonService.changeloader(false);
                return;
            }

          delete this.createRoleForm.value.id;
          this.roleService.insertRole(this.createRoleForm.value)
          .subscribe((result:any)=>{
                
                console.log("Create Role Response===>",result);
                
                let newlyCreatedRoleId = result.data.insertId;
                
                let objRoleConfiguration = {
                    'roleId': newlyCreatedRoleId,
                    'roleConfigure':arrRoleConfigure
                }
                
                console.log("POST data CREATE====>",objRoleConfiguration);
                
                this.roleService.updateRoleConfiguration(objRoleConfiguration)
                .subscribe((result:any)=>{
                    
                    this.commonService.changeloader(false);
                    
                    //New Code for right side for from
                    this.update = false;
                    this.updateSend.emit();
                    this.resetForm();
                    this.roleSubmitted = false;
                    
                    this.toastrService.success('Role & Configuration created Successfully.');
                });
              
                //this.toastrService.success('Role Created Successfully.');
          });
        }

        if(this.update){

            if(this.roleAsses.roleEdit == false){
                this.toastrService.success('no access to edit.')
                this.commonService.changeloader(false);
                return;
            }
            this.createRoleForm.controls['id'].setValue(this.id);
            this.roleService.updateRole(this.createRoleForm.value)
            .subscribe((result:any)=>{
            
                let objRoleConfiguration = {
                    'roleId': this.id,
                    'roleConfigure':arrRoleConfigure
                }
                
                console.log("POST data UPDATE====>",objRoleConfiguration);
                
                this.roleService.updateRoleConfiguration(objRoleConfiguration)
                .subscribe((result:any)=>{
                    
                    this.commonService.changeloader(false);
                    
                    //New Code for right side for from
                    this.update = false;
                    this.updateSend.emit();
                    this.resetForm();
                    this.roleSubmitted = false;
                    
                    this.toastrService.success('Role & Configuration Updated Successfully.');
                });
                
                /*
                this.toastrService.success('Role Updated Successfully.');
                */
            });
        }
        
    }



    //New Code for right side for from
    onContentReadyToReview(){

        if(!this.createRoleForm.valid){
            // this.loader = false;
            this.commonService.changeloader(false);
            this.roleSubmitted = true;
            return;
          }
        
        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        this.contentReadyToReview = true;
        this.shareMassage = 'role';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
            this.shareMassage = ''; 
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createRoleForm.controls['contentState'].setValue(['Completed']);
            this.shareMassage = ''; 
            // this.postCAllNavigation();
            this.savecreateNewRole();
            // this.onContentReadyToReview();
           
        }
        if(event == 'Cancel'){

           this.createRoleForm.controls['contentState'].setValue(['In-Progress']);
            this.shareMassage = ''; 
            // this.postCAllNavigation();
            // this.savecreateGeoZone();
           this.savecreateNewRole();
            
        }
       
    }

  
    resetForm(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
        // this.img = [];
        // this.spinnerService.hide();
        // this.uploadImgFilePath=[];
        this.update = false;
        this.roleSubmitted = false;
    
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {

        //     arrayControl.removeAt(0);
        // }
        
        this.createRoleForm = this.formBuilder.group({
          roleName: ['', Validators.compose([Validators.required])],
          roleKey:[''],
          id:[''],
          isActive:['1'],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          roleConfigure:[[]],
          created_by:[localStorage.getItem('ud')]

        });
        
        //reset multiselect list
        // this.selectedGeoZone=[];
        // this.selectedProductlist=[];

        //reset device type image
        // let obj = {
        //     'index':'',
        //     'deviceType':'',
        //     'selectedFiles':'',
        //     'fileName': '',
        //     'filePath': "",
        //     'fileType':''
        // }

        // this.uploadImgFilePath.push(obj)

        //     this.img.push({
        //         imgUrl:''
        //     })

        this.update = false;

        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });
        

        this.showCreateNewButton('show');
        
    }


    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
    
    
    
    
}
