import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { SponsorService } from '../../../../../services/sponsor/sponsor.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../../../services/common_Service/common.service';
import {SessionHelper} from '../../../../../services/localstorage';

@Component({
  selector: 'app-addSponsor',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentSponsor implements OnInit {

  isUserLoggedIn: boolean = false;
  createSponsorForm:FormGroup;
  errorMessages = errorMessages;
  sponsorSubmitted:boolean = false;

  isCreateNewShow:boolean = true;

  id:any;
  update=false;

  loader:boolean=false;

  contentReadyToReview:boolean = false;
  shareMassage:any;

  //@Input() contentId:any;
  @Output() updateSend = new EventEmitter<any>();

  //New Code start
  @Input() set contentId(value: any) {

      console.log("------------R-E-S-E-T------- ",value);

      if(value == 'reset'){

          this.resetForm();

      }else {
        //start code for hide create new button
        if(value != 'null'){
            this.showCreateNewButton('hide');
        }
        //end code for hide create new button
          this.onLoad(value);
      }
      
  }
  //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

  constructor(private commonService: CommonService,  private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private sponsorService: SponsorService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  applyBlackTheme = localStorage.getItem('boolThemeFlag');
  
  roleAsses:any={};
  roleConfiguration:any=[];
  ngOnInit() {

    
    this.roleAsses.sponsorEdit = false;
    this.roleAsses.sponsor = false;
    this.roleAsses.sponsorCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {
      console.log('element',element);

          if((element.isView == 1 && element.pageTitle == 'Sponsor') ){
              
              this.roleAsses.sponsor = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Sponsor') ){
              
              this.roleAsses.sponsorEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Sponsor')){

              this.roleAsses.sponsorCreate = true;
          
          }
    })

    // theme update code start
    this.commonService.currentThemelight.subscribe((result:any)=>{
                
      this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      
  })
  // theme update code start
  }

  
  editCreate:boolean = false;
  onCreateNew(){

        if(this.roleAsses.sponsorCreate == true){

            this.editCreate = true;
        }
        
        this.isCreateNewShow=false

  }

  onLoad(id){

    
        if(this.id != 'null' && this.roleAsses.sponsorEdit == true){

          this.editCreate = true;

      }else{

          this.editCreate = false;

      }

    let headerTitle = {
        title:'Master',
        subTitle:'Sponsor'
    }
    this.commonService.changeHeaderTitle(headerTitle);

      let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        }   else {
            this.router.navigate(['/login']);
        }

        this.createSponsorForm = this.formBuilder.group({
          title: ['', Validators.compose([Validators.required])],
          description:[''],
          id:[''],
          isActive:['1'],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          created_by:[localStorage.getItem('ud')]


        });

        // this.activatedRoute.params.subscribe(params => {
          // this.id = +params['id']; // (+) converts string 'id' to a number
          this.id = id; // (+) converts string 'id' to a number
          if(this.id != 'null' ){
          
            console.log('this.id===>>>',this.id);
          
              this.update=true;
              this.getSponsorInfo(this.id)
          }else{
            this.update=false;        
          }

      // });
  }

  
  get l() { return this.createSponsorForm.controls; }

  getSponsorInfo(id){
        
    // this.loader=true;
    this.commonService.changeloader(true);

    this.sponsorService.getSponsor(id)
    .subscribe((result:any)=>{

          // this.loader=false;
          this.commonService.changeloader(false);

          console.log("result==========>",result);
          
          this.createSponsorForm.controls['title'].setValue(result.data[0].title);
          this.createSponsorForm.controls['description'].setValue(result.data[0].description);
          this.createSponsorForm.controls['isActive'].setValue(result.data[0].isActive.toString());
          this.createSponsorForm.controls['publisherState'].setValue(result.data[0].publisherState);
          this.createSponsorForm.controls['reviewerState'].setValue(result.data[0].reviewerState);

          if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
              this.createSponsorForm.controls['contentState'].setValue(['In-Progress']);
          }
          else{
              this.createSponsorForm.controls['contentState'].setValue(result.data[0].contentState);
          }

          // console.log("result==========>",result.data[0].isActive.toString());
    
    });
  }

  savecreateNewSponsor(){

    console.log('update',this.id);
    console.log("Record received",this.createSponsorForm.value);

    // this.loader = true;
    this.commonService.changeloader(true);

    this.sponsorSubmitted = true;

    if(!this.createSponsorForm.valid){
      // this.loader = false;

      this.sponsorSubmitted = true;
      this.commonService.changeloader(false);
      return;
    }
    
      if(!this.update){

        if(this.roleAsses.sponsorCreate == false){
          this.toastrService.success('no access to create.');
          this.commonService.changeloader(false);
          return;
      }


        delete this.createSponsorForm.value.id;
        this.sponsorService.insertSponsor(this.createSponsorForm.value)
        .subscribe((result:any)=>{
            // this.loader=false;
            this.commonService.changeloader(false);
            // this.spinnerService.hide();
            // this.router.navigate(['master/sponsor/list']);

            //New Code for right side for from
            this.update = false;
          //  this.updateSend.emit();
            this.resetForm();
        
            this.sponsorSubmitted = false;
            
            this.toastrService.success('Sponsor Created Successfully.');
        });
      }

      if(this.update){

        if(this.roleAsses.sponsorEdit == false){
          this.toastrService.success('no access to edit.');
          this.commonService.changeloader(false);
          return;
      }

        this.createSponsorForm.controls['id'].setValue(this.id);
        this.sponsorService.updateSponsor(this.createSponsorForm.value)
        .subscribe((result:any)=>{
            // this.loader=false;
            this.commonService.changeloader(false);
            // this.router.navigate(['master/sponsor/list']);

            //New Code for right side for from
            this.update = false;
          //  this.updateSend.emit();
            this.resetForm();
            this.sponsorSubmitted = false;

            this.toastrService.success('Sponsor Updated Successfully.');          
        });
      }
  }

    //New Code for right side for from
    onContentReadyToReview(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end

        if(this.createSponsorForm.invalid){

          this.sponsorSubmitted = true;

          return;
          
        }

        this.contentReadyToReview = true;
        this.shareMassage = 'review';

    }

    isContentReadyToReviewReceived(event){

        console.log("we are in",event);
        this.contentReadyToReview = false;

       //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
           
        }
        //New Code end

        if(event == 'Yes'){

            this.createSponsorForm.controls['contentState'].setValue(['Completed']);
            // this.postCAllNavigation();
            this.savecreateNewSponsor();
            // this.onContentReadyToReview();
           
        }
        if(event == 'Cancel'){

            this.createSponsorForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCAllNavigation();
            // this.savecreateGeoZone();
            this.savecreateNewSponsor();
            
        }
       
    }

  
    resetForm(){

        //code for global loader start
        this.commonService.changeloader(false);
        //code for global loader end
        // this.img = [];
        // this.spinnerService.hide();
        // this.uploadImgFilePath=[];
        this.update = false;
        this.sponsorSubmitted = false;

         this.updateSend.emit();

    
        // const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        // while (arrayControl.length !== 0) {

        //     arrayControl.removeAt(0);
        // }

        this.createSponsorForm = this.formBuilder.group({
          title: ['', Validators.compose([Validators.required])],
          description:[''],
          id:[''],
          isActive:['1'],
          contentState:['In-Progress'],
          publisherState:['Un-published'],
          reviewerState:['Un-approved'],
          created_by:[localStorage.getItem('ud')]


        });
        
        //reset multiselect list
        // this.selectedGeoZone=[];
        // this.selectedProductlist=[];

        //reset device type image
        // let obj = {
        //     'index':'',
        //     'deviceType':'',
        //     'selectedFiles':'',
        //     'fileName': '',
        //     'filePath': "",
        //     'fileType':''
        // }

        // this.uploadImgFilePath.push(obj)

        //     this.img.push({
        //         imgUrl:''
        //     })

        this.update = false;

        this.updateSend.emit();

        // this.multipleDeviceForm = this.formBuilder.group({

        //     devices: this.formBuilder.array([this.createItem()])

        // });
        

        this.showCreateNewButton('show');
        
    }


    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
}
