import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UplynkAdServerService } from '../../../../../services/uplynk_adServer/uplynk-ad-service.service';
import {LoginService} from '../../../../../services/login/login.service';
import {Loginshared} from '../../../../../services/sharedservices/loginshared.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { ToastrService } from 'ngx-toastr';
import {SessionHelper} from '../../../../../services/localstorage';
import { CommonService } from '../../../../../services/common_Service/common.service';

@Component({
  selector: 'app-addUplynkadserver',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponentUplynkadserver implements OnInit {

  isUserLoggedIn: boolean = false;
  createUplynkAdServerForm:FormGroup;
  errorMessages = errorMessages;
  uplynkAdServerSubmitted:boolean = false;
  id:any;
  update=false;

  loader:boolean=false;

  isCreateNewShow:boolean = true;
    
    contentReadyToReview:boolean= false;
    shareMassage:any;
    
    //New Code for right side for from
    @Output() updateSend = new EventEmitter<any>();
    @Input() set contentId(value: any) {
        // this.onLoad(value);
        if(value == 'reset'){
            this.resetForm();
        }else {
            //start code for hide create new button
            if(value != 'null'){
                this.showCreateNewButton('hide');
            }
            //end code for hide create new button
            this.onLoad(value);
        }
    }

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }
    
  constructor(private commonService: CommonService, private toastrService: ToastrService, private activatedRoute:ActivatedRoute,private loginSharedService: Loginshared, private loginService: LoginService, private router: Router, private uplynkAdServerService: UplynkAdServerService,private formBuilder:FormBuilder) {
    loginSharedService.sendUserLoggedIn$.subscribe(
      mission => {
          this.isUserLoggedIn = mission;
          let uid = localStorage.getItem('ud');
          if (uid !== undefined && uid != null) {
              this.isUserLoggedIn = true;
          }
      }
    );
  }

  applyBlackTheme = localStorage.getItem('boolThemeFlag');
  roleAsses:any={};
  roleConfiguration:any=[];
    ngOnInit() {  
    
    this.roleAsses.uplynkAdServerEdit = false;
    this.roleAsses.uplynkAdServer = false;
    this.roleAsses.uplynkAdServerCreate = false;

    this.roleConfiguration = SessionHelper.getRole();

    this.roleConfiguration.forEach((element , i) => {
      console.log('element',element);

          if((element.isView == 1 && element.pageTitle == 'Uplynk Ad Server') ){
              
              this.roleAsses.uplynkAdServer = true;
          }
      
          if((element.isEdit == 1 && element.pageTitle == 'Uplynk Ad Server') ){
              
              this.roleAsses.uplynkAdServerEdit = true;
          }
          
          if((element.isCreate == 1 && element.pageTitle == 'Uplynk Ad Server')){

              this.roleAsses.uplynkAdServerCreate = true;
          
          }
    })


        

        // theme update code start
    this.commonService.currentThemelight.subscribe((result:any)=>{
                
        this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        
    })
    // theme update code start

    }
    
    
  editCreate:boolean = false;
  onCreateNew(){

        if(this.roleAsses.uplynkAdServerCreate == true){

            this.editCreate = true;
        }
        
        this.isCreateNewShow=false

  }

    onLoad(id) {

        if(this.id != 'null' && this.roleAsses.uplynkAdServerEdit == true){

            this.editCreate = true;

        }else{

            this.editCreate = false;

        }
        

        let headerTitle = {
          title:'Master',
          subTitle:'Uplynk Ad Server'
        }
        
        this.commonService.changeHeaderTitle(headerTitle);

        let uid = localStorage.getItem('ud');
        if (uid !== undefined && uid != null) {

        } else {
            this.router.navigate(['/login']);
        }

        this.createUplynkAdServerForm = this.formBuilder.group({
            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]

        });

  
        this.id = id; // (+) converts string 'id' to a number
        
        if(this.id!='null'){

          console.log('this.id===>>>',this.id);
            this.update = true;
            this.getUplynkadserverInfo(this.id);

        }else{

          this.update = false;        
        }

        
    }
  
  get l() { return this.createUplynkAdServerForm.controls; }

  getUplynkadserverInfo(id){
        
    //this.loader=true;
    
    this.commonService.changeloader(true);
    
    this.uplynkAdServerService.getUplynkadserver(id)
    .subscribe((result:any)=>{

          //this.loader=false;
          this.commonService.changeloader(false);

          console.log("result==========>",result);
          
          this.createUplynkAdServerForm.controls['title'].setValue(result.data[0].title);
          this.createUplynkAdServerForm.controls['description'].setValue(result.data[0].description);
          this.createUplynkAdServerForm.controls['isActive'].setValue(result.data[0].isActive.toString());
          this.createUplynkAdServerForm.controls['publisherState'].setValue(result.data[0].publisherState);
          this.createUplynkAdServerForm.controls['reviewerState'].setValue(result.data[0].reviewerState);


          if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
              this.createUplynkAdServerForm.controls['contentState'].setValue(['In-Progress']);
          }
          else{
              this.createUplynkAdServerForm.controls['contentState'].setValue(result.data[0].contentState);
          }
          // console.log("result==========>",result.data[0].isActive.toString());
    
    });
  }

  savecreateNewUplynkAdServer(){

    //this.loader = true;
    
    this.commonService.changeloader(true);
    
    this.uplynkAdServerSubmitted = true;

    console.log("createUplynkAdServerForm>>", this.createUplynkAdServerForm);
    console.log("upload>>", this.update);

    if(!this.createUplynkAdServerForm.valid){
        //this.loader = false;

        this.commonService.changeloader(false);

        return;
    }
   
  
    if(!this.update){

        if(this.roleAsses.uplynkAdServerCreate == false){
            this.toastrService.success('no access to edit.');
            this.commonService.changeloader(false);
            return;
        }


        delete this.createUplynkAdServerForm.value.id;
      
        this.uplynkAdServerService.insertUplynkadserver(this.createUplynkAdServerForm.value)
        .subscribe((result:any)=>{
            //this.loader=false;

            this.commonService.changeloader(false);
              
            //New Code for right side for from
            this.update = false;
           // this.updateSend.emit();
            this.resetForm();
            this.uplynkAdServerSubmitted = false;
                
            // this.spinnerService.hide();
            //this.router.navigate(['master/uplynkAdServer/list']);
            this.toastrService.success('Uplynk Ad Server Created Successfully.');
        });
    }

    if(this.update){

        if(this.roleAsses.uplynkAdServerEdit == false){
            this.toastrService.success('no access to edit.');
            this.commonService.changeloader(false);
            return;
        }

        this.createUplynkAdServerForm.controls['id'].setValue(this.id);
        this.uplynkAdServerService.updateUplynkadserver(this.createUplynkAdServerForm.value)
        .subscribe((result:any)=>{
              //this.loader=false;

            this.commonService.changeloader(false);
              
            //New Code for right side for from
            this.update = false;
           // this.updateSend.emit();
            this.resetForm();
            this.uplynkAdServerSubmitted = false;
            //this.router.navigate(['master/uplynkAdServer/list']);
            this.toastrService.success('Uplynk Ad Server Updated Successfully.');          
        });
    }
  }
  

    //New Code for right side for from
    onContentReadyToReview(){

        console.log("createUplynkAdServerForm>>>",this.createUplynkAdServerForm);

        if(!this.createUplynkAdServerForm.valid){
            //this.loader = false;

            this.commonService.changeloader(false);
           
            this.uplynkAdServerSubmitted = true;
            return;
        }

        this.contentReadyToReview = true;

        // this.loader = false;
        this.commonService.changeloader(false);

        
        this.shareMassage = 'review';

    }
    //is Content Ready To Review Received  


    isContentReadyToReviewReceived(event)  {

        console.log("we are in",event);
        this.contentReadyToReview = false;

      //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
          
        }
        //New Code end

        if(event == 'Yes'){

            this.createUplynkAdServerForm.controls['contentState'].setValue(['Completed']);
          
            this.savecreateNewUplynkAdServer();
          
        }
        if(event == 'Cancel'){

            this.createUplynkAdServerForm.controls['contentState'].setValue(['In-Progress']);
          
            this.savecreateNewUplynkAdServer();
          
      }
   
   }
  
    resetForm(){

        this.updateSend.emit();

        this.createUplynkAdServerForm = this.formBuilder.group({
            title: ['', Validators.compose([Validators.required])],
            description:[''],
            id:[''],
            isActive:['1'],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]


        });
        
    
        this.update = false;

        //////////////////////
        this.showCreateNewButton('show');
    
    }

  

    showCreateNewButton(isShow){
        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }
  

}
