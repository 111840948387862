import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {httpOptions} from '../../configurations/configuration';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {httpOptionsImage} from '../../configurations/configuration';

@Injectable()
export class PrimaryNavigationService {

    constructor(private http: HttpClient) { }

    updatePrimaryNavigation(postObj:any) : Observable<any> {
        postObj.langId = localStorage.getItem('languageId');
        if(postObj.langId == null){       
            postObj.langId = '1';  
           }

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.updatePrimaryNavigationUrl, postObj,httpOptions);
    }
        
    insertPrimaryNavigation(postObj:any) : Observable<any> {
       postObj.langId = localStorage.getItem('languageId');
       if(postObj.langId == null){       
        postObj.langId = '1';  
       }

        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.insertPrimaryNavigationUrl, postObj,httpOptions);
    }    
    
    getPrimaryNavigation( id: any, langId:any) : Observable<any> {

        langId = localStorage.getItem('languageId');

        if(langId == null){
            langId = '1';
        }

        
        if(id && langId!=null){
            return this.http.get(environment.apiURL + environment.adminApiUrl + environment.getPrimaryNavigationUrl+"/" + id +"/" + langId);
        }
    }
   
    getAllPrimaryNavigation(filterObj,start,limit){

        filterObj.languageId = localStorage.getItem('languageId');
        if(filterObj.languageId == null){       
            filterObj.languageId = '1';  
         }

        let urlGetAllPrimaryNavigation = environment.apiURL + environment.adminApiUrl + environment.getPrimaryNavigationUrl +'?filter='+encodeURIComponent(JSON.stringify(filterObj))+'&start='+start+'&limit='+limit;
        return this.http.get<any>(urlGetAllPrimaryNavigation)
    }
     
    deletePrimaryNavigation(postObj:any) : Observable<any> {
        return this.http.post(environment.apiURL + environment.adminApiUrl + environment.deletePrimaryNavigationUrl, postObj,httpOptions);
    }
                    
}
