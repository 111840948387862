import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common_Service/common.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

  
applyBlackTheme:any ='false'

  constructor(public commonService:CommonService) { }

  ngOnInit() {

    this.commonService.currentThemelight.subscribe((result:any)=>{
                  
      this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
      
    })
    
  }

}
