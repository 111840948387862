
import { Component, OnInit ,Input , Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import {AssetService} from '../../../../../services/asset/asset.service';
import {ProductConfigurationService} from '../../../../../services/product_configuration/product_configuration.service';
import {CommonService} from '../../../../../services/common_Service/common.service';
import {awsPath, deviceType, urlPattern} from '../../../../../configurations/configuration';
import { errorMessages } from './../../../././././../../configurations/errorMessages';
import { element } from '@angular/core/src/render3';
import { ReturnStatement } from '@angular/compiler';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import {SessionHelper} from '../../../../../services/localstorage';
import {Observable}  from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { exists } from 'fs';
import { environment } from 'src/environments/environment';
import { ClientAdServerService } from '../../../../../services/client_adServer/client-ad-service.service';

@Component({
  selector: 'app-addAsset',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddAssetComponent implements OnInit {
    // editorConfig = {
    //     'showToolbar':false
    //   };

    initProductType:boolean=true;
    initNavigation:boolean=true;
    initMenu:boolean=true;
    initGroup:boolean=true;
    initCategory:boolean=true;

    embedUrl = 'data loading...';

    selectedgeoZone:any=[];

  isCreateNewShow:boolean = true;
  contentReadyToReview:boolean= false;
  shareMassage:any;
  deviceType = deviceType;
  loader:boolean=false;
  poster:boolean=false;
  ClosedCaption:boolean=false;
  multipleDevice:boolean=false;
  createNewAssetForm:FormGroup;
  FilePosterImageURL:any;
  FileClosedCaptionURL:any;
  createNewAssetSubmitted=false;
 
  
  mapGenreList:any=[];
  mapClassificationList:any=[];
  uploadImgFilePosterImage:any=[];
  uploadImgFileclosedCaption:any=[];
  mapLanguageList:any=[];
  mapSponsoreList:any=[];
  mapCharacterList:any=[];

  uploadImgFilePath:any=[];
  uploadImgFilePathVertical:any=[];
  uploadImgFilePathHorizontal:any=[];
  

  deviceTypeFull:boolean = false;
  deviceTypeDublicate:boolean = false;

  ProducTypeSettings={};
  primaryNavigationSettings={};
  trailerVideoSettings={};
  trailerVideoVirtualSettings={};
  certificationListSettings={};
  dropdownSettings={};
  mapCastSettings={};
  start:number=0;
  limit:number=12;

  geoZoneList = [];
  productTypeList=[];
  mapCastlist=[];
  menuCategoryList=[];
  assetCategoryList=[];
  
  assetGroupList=[];
  dropdownList = [];

  

  All:boolean;
  Web:boolean;
  all:boolean = false;
  android:boolean;
  iOS:boolean;
  roku:boolean;

  devices: FormArray;
  mapBreak: FormArray;
  mapMulticam:FormArray;
  mapAssetMetaData: FormArray;
  id:any;
  update=false;
  dTypeDublicateError:any;
  //pravin code end 

  Android:boolean;
  multipleDeviceForm:FormGroup;
  mapBreakForm:FormGroup;
  mapMulticamForm:FormGroup;

  mapVirtualChannelSchedule:FormArray;
  mapAssetMetaDataForm:FormGroup;
  mapVirtualChannelScheduleForm:FormGroup;
  ProducTypeDropdownList = [];
  producTypeList=[];
  
  primaryNavigationList=[];

  selectedItems = [];
  selectedAssetCategory=[];
  selectedmapCast:any=[];
  selectedProductlist=[];
  selectedprimaryNavigationList=[];
  selectedcertificationList=[];
  selectedTrailerVideoList=[];
  selectedmenuCategoryList=[];
  selectedAssetGroupList=[];
  selectedAssetLibraryList = [];
  selectedLiveVirtualChannelList = [];
  selectedLiveEventList = [];
  selectedUplynkAdServerList = [];

  certificationList=[];
  trailerVideoList=[];
  live = true;
  uplynkAdServerList = [];

  selectedClientAdServerList = [];
  clientAdServerList = [];
  
  liveEventList = [];
  mapLibraryList:any=[];
  assetLibraryList = [];
  liveVirtualChannelList = [];
  
  uplynkAdServerSettings = {};
  liveEventSettings = {};
  liveVirtualChannelSettings = {};
  assetLibrarySettings = {};
  menuCategorySettings = {};
  assetMapArray:any = [];

  boolShowLiveContentType = true;
  boolShowVodContentType = true;
  boolShowAudioContentType = true;
  
  boolShowLiveEvent = true;
  boolShowVirtualChannel = true;
  boolShowLiveChannel = true;
  boolShowThirdParty =true;

  vodOrLiveAdServer:any;

  errorMessages = errorMessages;

  img:any=[];
  mapReviewList:any=[];
  langId:any;
  uplynkId:any;

  reconsilitionObj:any = {};


    //start mapping filter
    productTypeMappingFilter:any={};
    primaryNavigationMappingFilter:any={};
    menuCategoryMappingFilter:any={};
    assetGroupMappingFilter:any={};
    assetCategoryMappingFilter:any={};
    //end mapping filter


    isDeleteAction: boolean=false;
    deleteDeviceTypeIndex:any;

    isDeleteMetadataAction: boolean=false;
    deleteMetadataIndex:any;
    
    isDeleteMapBreakAction: boolean=false;
    deleteMapBreakIndex:any;

    isDeleteMapMulticamAction: boolean=false;
    deleteMapMulticamIndex:any;

    isDeleteVirtualChannelAction: boolean=false;
    deleteVirtualChannelIndex:any;

    isDeleteHorizontalImageAction:boolean=false;
    isDeleteVerticalImageAction:boolean=false;

    reset:boolean = false;

    isDeletePosterImageAction:boolean=false;
    isDeleteClosedCaptionImageAction:boolean=false;

    
    applyBlackTheme=localStorage.getItem('boolThemeFlag');

    formTabs:any = {

        assetInfo:{

         asset:true,
         mapGeoZone:true,
         information:false,
         castAndCrew:false,
         seo:false
       
        },
        // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

        deviceType:{
            
            configurationType:false,
            configuration:false,
          
        },
        assetLiveVod:{
            
            vod:false,
            live:false,
            assetLiveVod:false,
            multicam:false
        },
        metadata:{

            metadata:false,
            metadataHierarchy:false,
            Information:false

        }
    }

        //@Input() contentId:any;
        @Output() updateSend = new EventEmitter<any>();

        //New Code start
         @Input() set contentId(value: any) {

            this.applyBlackTheme=localStorage.getItem('boolThemeFlag');

            console.log("value>>>",value);
     
             if(value == 'reset'){

                this.reset = true;
     
                 this.resetForm();
               
     
             }else {

                this.reset = false;
     
                //start code for hide create new button
                console.log("123>>",localStorage.getItem('uplynkId'));
                console.log("123456>>",localStorage.getItem('activeAsset'));
                console.log("123456>>",localStorage.getItem('activeAsset'));

                console.log("value@@@@@@@@@@@@@@@@@@@@@@@ ==>>",value);

                if(value != 'null'){

                     this.showCreateNewButton('hide');

                }else {

                    this.isCreateNewShow = true;
                    
                }

                //end code for hide create new button
                 this.onLoad(value);
             }
             
         }
         //New Code end

    editorConfig = {
        "toolbar": [
            ["bold", "italic", "underline"],
            ["justifyLeft", "justifyCenter", "justifyRight"]
        ]
    }

    editor:any={};


    constructor( private sanitizer: DomSanitizer ,private router: Router ,private activatedRoute:ActivatedRoute,private assetService:AssetService,private commonService:CommonService,private productConfigurationService:ProductConfigurationService,private spinnerService:Ng4LoadingSpinnerService,private toastrService:ToastrService,private formBuilder:FormBuilder,private clientAdServerService: ClientAdServerService) { 
        this.commonService.notify.subscribe((result) => {
            this.loader = false;
        })

        // this.commonService.currentThemelight.subscribe((result:any)=>{

        //     console.log("currentThemelight>>",result);

        //         if(result.id!=undefined && result.id!=null){

        //             this.id = result.id;
        //             this.createNewAssetForm.controls['id'].setValue(result.id);
        //             this.createNewAssetForm.controls['langId'].setValue(localStorage.getItem('LanguageId'));
        //             this.update = true;  
        //             this.getAssetInfo(result.id);
        //             this.isCreateNewShow = false;

        //         }
                
        //     this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
        // })

    }
 

    roleAsses:any={};
    roleConfiguration:any=[];

    ngOnInit(){

        localStorage.removeItem('langItem');

        // this.getAllAsset();
    
          //below code start for role managemet.

          this.roleAsses.assetEdit = false;
          this.roleAsses.asset = false;
          this.roleAsses.assetCreate = false;

          this.roleConfiguration = SessionHelper.getRole();

          this.roleConfiguration.forEach((element , i) => {

                if((element.isView == 1 && element.pageTitle == 'Asset') ){
                    
                    this.roleAsses.asset = true;
                }
            
                if((element.isEdit == 1 && element.pageTitle == 'Asset') ){
                    
                    this.roleAsses.assetEdit = true; 

                }
                
                if((element.isCreate == 1 && element.pageTitle == 'Asset')){

                    this.roleAsses.assetCreate = true;
                
                }

          })
        

        this.setupSearchDebouncer();

        this.commonService.currentThemelight.subscribe((result:any)=>{

        if(localStorage.getItem('langItem')!=null && localStorage.getItem('activePage')=='asset'){
                // if(result!=undefined){

                this.onAssetInfoTabClick();


                    let item = JSON.parse(localStorage.getItem('langItem'));
                    //  item = result;
                    if(item.id!=undefined){
                        this.id = item.id;
                    }else {
                        return;
                    }
                    
                    this.createNewAssetForm.controls['id'].setValue(this.id);

                    if(item.id != null && this.roleAsses.assetEdit == true){
                        this.editCreate = true;
                        this.isCreateNewShow = false;
                        this.update = true;
                    }else{
                        // this.editCreate = false;
                    }

                    this.createNewAssetForm.controls['langId'].setValue(localStorage.getItem('languageId'));
                    this.isCreateNewShow = false;
                    this.getAssetInfo(this.id)
                    // localStorage.removeItem('langItem');
        }
            // }
                this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
        })


        // this.editor.resize( '50%', '350' );


        // theme update code start
        
    //     this.commonService.currentThemelight.subscribe((result:any)=>{
          
    //         this.applyBlackTheme = localStorage.getItem('boolThemeFlag');
            
    //    })

                    
        
       // theme update code start
  
    }
    
    editCreate:boolean = false;
    trailerVideo:any=[];

    onCreateNew(){

        if(this.roleAsses.assetCreate == true){

               this.editCreate = true;

        }

        this.isCreateNewShow=false

    }

    assetMappingSettings:any = { };

    onLoad(id) {

        this.id = id;

        this.productTypeMappingFilter = {};
        this.primaryNavigationMappingFilter = {};
        this.menuCategoryMappingFilter = {};
        this.assetGroupMappingFilter = {};
        this.assetCategoryMappingFilter = {};
    
            this.trailerVideoList = [];
            this.mapProductTypeArrayTemp =[]; 
            this.mapMenuCategoryArrayTemp = [];
            this.mapAssetGroupArrayTemp = [];
            this.mapPrimaryNavigationArrayTemp = [];
            this.selectedItems = [];
            this.selectedAssetCategory=[];
            this.selectedmapCast=[];
            this.selectedProductlist=[];
            this.selectedprimaryNavigationList=[];
            this.selectedcertificationList=[];
            this.selectedTrailerVideoList=[];
            this.selectedmenuCategoryList=[];
            this.selectedAssetGroupList=[];
            this.selectedAssetLibraryList = [];
            this.selectedLiveVirtualChannelList = [];
            this.selectedLiveEventList = [];
            this.selectedUplynkAdServerList = [];
            this.selectedClientAdServerList = [];
    
        this.uploadImgFilePath = [];
        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        this.img = [];

        this.img.push({
                horizontalURL:'',
                verticalURL:''
         })
        


    
        setTimeout((element)=>{
            this.description = true;
        },2000)

        this.mapVirtualArray = [];

            if(this.id != 'null' && this.roleAsses.assetEdit == true){

                this.editCreate = true;
                this.isCreateNewShow = false;


            }else{

                // this.editCreate = false;

            }

        // this.embedUrl=environment.apiURLEmbed+"assets/thirdparty/embed.html?assetId="+this.id;
        // this.embedUrl=this.sanitizer.bypassSecurityTrustResourceUrl(this.embedUrl);


        console.log('embedUrl...',this.embedUrl);

        this.createNewAssetForm = this.formBuilder.group({
            id:[''],  
            assetType:[''],
            ingestionType: [''],
            contentType: [''],
            vodOrLivePosterImage: [''],
            vodAssetTitle:[''],
            vodClosedCaption:[''],
            vodIsDrm:[""],
            lyrics:[""],
            vodAssetDesc:[""],
            vodOrLiveAdServer:[[]],
            vodPreRollAds:[""],
            vodPostRollAds:[""],

            vodClientSideAdServer:[""],
            vodClientSidePreRollAds:[""],
            vodClientSideMidRollAds:[""],
            vodClientSideMidRollDuration:[""],
            vodClientSidePostRollAds:[""],

            liveType: [""],
            permaLink:[''],
            liveVirtualChannelId: [[]],
            liveEventId: [[]],
            mapAssetMetaData:[[]],
            mapAssetCategory:[[]],
            mapAssetGroup:[[]],
            mapMenuCategory:[[]],
            mapBreak: [[]],
            mapMulticam: [[]],
            mapAssetLibrary: [[]],
            mapVirtualChannelSchedule: [[]],
            isActive: ["1"],
            langId: [this.langId],
            companyId:[ ""],
            title: [this.reconsilitionObj.title,Validators.compose([Validators.required])],
            sameForAllZone:[''],
            duration:[this.reconsilitionObj.Duration],
            sameForAllProductType: [''],
            displayIndexing:[''],
            // airStartDate:['',Validators.required],
            // airStartTime:['',Validators.required],
            // airEndTime:['',Validators.required],
            // airEndDate: ['',Validators.required],
            airStartDate:[''],
            airStartTime:[''],
            airStartTimezone:[''],
            airEndTime:[''],
            airEndDate: [''],
            airEndTimezone:[''],
            exclusionText: [''],
            description: [this.reconsilitionObj.title],
            seoTitle: [''],
            seoKeyword: [''],
            seoDescription:[''],
            mapCharacter:[[]],
            mapGeoZone: ['', Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[''],
            mapProductType:['',Validators.compose([Validators.required])],
            mapPrimaryNavigation: [[]],
            mapCertification: [[]],
            mapClassification:[[]],
            epgUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
            hlsUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
            copyrightText:[''],
            expandParameter:[''],
            isMulticamApplicable:[''],
        //   vod_asset_desc:[''],
        //   vod_is_drm:[''],
            vodOrLivePosterImageFileName: [''],
            vodOrLivePosterImageFilePath: [''],
            vodOrLivePosterImageFileType:[''],
            vodClosedCaptionFileName:[''],
            vodClosedCaptionFilePath:[''],
            vodClosedCaptionFileType:[''],
            mapCast:[[]],
            mapGenre:[[]],
            mapLibrary:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            uplynkId:[this.uplynkId],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved'],
            created_by:[localStorage.getItem('ud')]
        
         })

            
        this.initProductType=true;
        this.initNavigation=true;
        this.initMenu=true;
        this.initGroup=true;
        this.initCategory=true;

        this.id = id;

        this.embedUrl = environment.apiURLEmbed+"assets/thirdparty/embed.html?assetId="+this.id;
        // this.embedUrl ='<iframe width=“100%” height =“400px” src=“'+this.embedUrl+'> </iframe>';

        this.embedUrl = "<iframe width='575' height='325' scrolling='no' src='"+this.embedUrl+"' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
        
        
        this.embedUrl = this.embedUrl.trim();

        setTimeout(()=>{
            
        this.embedUrl = environment.apiURLEmbed+"assets/thirdparty/embed.html?assetId="+this.id;
        this.embedUrl = "<iframe width='575' height='325' scrolling='no' src='"+this.embedUrl+"' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
        this.embedUrl = this.embedUrl.trim();

        },1000)

    //    this.getGlobalCharacter();
       
        console.log("this.router.url",this.router.url);

        this.commonService.notify.subscribe((result) => {

            this.loader = false;

        })

        let headerTitle = {
            title:'Asset Management',
            subTitle:'Asset'
 
        }
 
        this.commonService.changeHeaderTitle(headerTitle);
        this.onAssetInfoTabClick();

        // this.activatedRoute.params.subscribe(params => {
            // this.id = params['id'];
            // (+) converts string 'id' to a number
            // this.langId = +params['langId']? +params['langId'] :"1"

            // if(localStorage.getItem("languageId")!= null){

            //     this.langId =localStorage.getItem("languageId");
            //     this.langId = "1";

            // }else {

            //     this.langId = "1";

            // }


            console.log("localStorage.getItem('uplynkId')>>>",localStorage.getItem('uplynkId'));

            this.editCreate = true;
        
                    if(localStorage.getItem('uplynkId')){

                    this.id = localStorage.getItem('uplynkId');
                    this.uplynkId = this.id;
                    this.showCreateNewButton('hide');
                    localStorage.removeItem('uplynkId');
                    this.update = false;

                    this.showCreateNewButton('hide');

                        let obj =JSON.parse(localStorage.getItem('assetObj'));

                        this.reconsilitionObj =obj.obj;

                        console.log("reconsilitionObj",this.reconsilitionObj);

                        this.createNewAssetForm.controls['uplynkId'].setValue(this.uplynkId);
                        this.createNewAssetForm.controls['langId'].setValue('1');
                        this.createNewAssetForm.controls['title'].setValue(this.reconsilitionObj.title,Validators.compose([Validators.required]));
                        this.createNewAssetForm.controls['description'].setValue(this.reconsilitionObj.title);
    
    
                    }
                
                    // this.FilePosterImageURL = this.reconsilitionObj.thumbnail;


                    // this.createNewAssetForm = this.formBuilder.group({
                    //     id:[''],  
                    //     assetType:[''],
                    //     ingestionType: [''],
                    //     contentType: [''],
                    //     vodOrLivePosterImage: [this.reconsilitionObj.thumbnail],
                    //     vodAssetTitle:[''],
                    //     vodClosedCaption:[''],
                    //     vodIsDrm:[""],
                    //     lyrics:[""],
                    //     vodAssetDesc:[""],
                    //     vodOrLiveAdServer:[[]],
                    //     vodPreRollAds:[""],
                    //     vodPostRollAds:[""],
                    //     liveType: [""],
                    //     liveVirtualChannelId: [[]],
                    //     liveEventId: [[]],
                    //     mapAssetMetaData:[[]],
                    //     mapAssetCategory:[[]],
                    //     mapAssetGroup:[[]],
                    //     mapMenuCategory:[[]],
                    //     mapBreak: [[]],
                    //     mapAssetLibrary: [[]],
                    //     mapVirtualChannelSchedule: [[]],
                    //     isActive: ["1"],
                    //     langId: ["1"],
                    //     companyId:[ ""],
                    //     title: [this.reconsilitionObj.title,Validators.compose([Validators.required])],
                    //     sameForAllZone:[''],
                    //     duration:[''],
                    //     sameForAllProductType: [''],
                    //     displayIndexing:[''],
                    //     // airStartDate:['',Validators.required],
                    //     // airStartTime:['',Validators.required],
                    //     // airEndTime:['',Validators.required],
                    //     // airEndDate: ['',Validators.required],
                    //     airStartDate:[''],
                    //     airStartTime:[''],
                    //     airStartTimezone:[''],
                    //     airEndTime:[''],
                    //     airEndDate: [''],
                    //     airEndTimezone:[''],
                    //     exclusionText: [''],
                    //     description: [this.reconsilitionObj.title],
                    //     seoTitle: [''],
                    //     seoKeyword: [''],
                    //     seoDescription:[''],
                    //     mapCharacter:[[]],
                    //     mapGeoZone: ['', Validators.compose([Validators.required])],
                    //     productDeviceTypeConfigure:[''],
                    //     mapProductType:['',Validators.compose([Validators.required])],
                    //     mapPrimaryNavigation: [[]],
                    //     mapCertification: [[]],
                    //     mapClassification:[[]],
                    //     epgUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
                    //     hlsUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
                    //     copyrightText:[''],
                    //     expandParameter:[''],
                    // //   vod_asset_desc:[''],
                    // //   vod_is_drm:[''],
                    //     vodOrLivePosterImageFileName: [''],
                    //     vodOrLivePosterImageFilePath: [''],
                    //     vodOrLivePosterImageFileType:[''],
                    //     vodClosedCaptionFileName:[''],
                    //     vodClosedCaptionFilePath:[''],
                    //     vodClosedCaptionFileType:['',],
                    //     mapCast:[[]],
                    //     mapGenre:[[]],
                    //     mapLibrary:[[]],
                    //     mapLanguage:[[]],
                    //     mapSponsor:[[]],
                    //     mapReview:[[]],
                    //     uplynkId:[this.uplynkId],
                    //     contentState:['In-Progress'],
                    //     publisherState:['Un-published'],
                    //     reviewerState:['Un-approved']
                    
                    //  })

                     this.img = [];
                     
                    //  this.img.push({
                    //     horizontalURL:this.reconsilitionObj.thumbnail,
                    //     verticalURL:''
                    // })
        
               
        
            

            if(this.id == null && localStorage.getItem('activeAsset')){

                this.id = localStorage.getItem('activeAsset');
                localStorage.removeItem('activeAsset');
    
            }else {

                this.id = id;

            }

            //this.uplynkId = '';

            console.log("id>>>",this.id);
            
            if(this.id != 'null'){

                this.update = true;
        
                // if(params.id != undefined && params.type !='reconcilliation'){
                //     this.update = true
                //     this.getAssetInfo(this.id);
                // }
            
                this.getAssetInfo(this.id);

                // if(params.type =='reconcilliation'){

                //     console.log("type",params)

                this.uplynkId = this.id ;

                // this.update=false;

                // this.img.push({
                //     horizontalURL:'',
                //     verticalURL:''
                // })   
    
                // let objH = {
                //     'index':"",
                //     'deviceType':"",
                //     'selectedFiles':"",
                //     "horizonFupdatalFileName": "",
                //     "horizontalFilePath": "",
                //     "horizontalFileType": "",
                //     'horizontalFile':false
                // }
    
                // let objV = {
                //     'index':"",
                //     'deviceType':"",
                //     'selectedFiles':"",
                //     "verticalFileName":"",
                //     "verticalFilePath": "",
                //     "verticalFileType":"",
                //     'verticalFile':false,
                    
                // }
    
                // this.uploadImgFilePathHorizontal.push(objH);
                // this.uploadImgFilePathVertical.push(objV);

                // }



            }else{

                this.update = false;

                this.img.push({
                    horizontalURL:'',
                    verticalURL:''
                })   

                let objH = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "horizontalFileName": "",
                    "horizontalFilePath": "",
                    "horizontalFileType": "",
                    'horizontalFile':false
                }

                let objV = {
                    'index':"",
                    'deviceType':"",
                    'selectedFiles':"",
                    "verticalFileName":"",
                    "verticalFilePath": "",
                    "verticalFileType":"",
                    'verticalFile':false,
                    
                }

                this.uploadImgFilePathHorizontal.push(objH);
                this.uploadImgFilePathVertical.push(objV);
            
            }

        // In a real app: dispatch action to load the details here.
        // });

        this.mapCastSettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
        }

        this.dropdownSettings = { 
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:160,
            minHeight:30,
            lazyLoading: true,
        }; 
        this.trailerVideoSettings = { 
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            maxHeight:160,
            minHeight:30,
            lazyLoading: false,
            }        

        this.trailerVideoVirtualSettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true,
        }

        
        this.assetMappingSettings = {

            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['itemName'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true,

            // singleSelection: false, 
            // text:"Select",
            // selectAllText:'Select All',
            // unSelectAllText:'UnSelect All',
            // enableSearchFilter: true,
            // classes:"myclass custom-class",
            // maxHeight:140,
            // minHeight:30,
        }

        this.menuCategorySettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            searchBy: ['itemName'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true,
            classes:"myclass custom-class"
        }

        this.ProducTypeSettings = {
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true
            }        
        this.primaryNavigationSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:120,
            minHeight:30,
            lazyLoading: true
        }
        this.certificationListSettings={
            singleSelection: false, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true
                    }
            
        this.uplynkAdServerSettings = {
            singleSelection: true, 
            // text:"Select",
            // selectAllText:'Select All',
            // unSelectAllText:'UnSelect All',
            // enableSearchFilter: true,
            // classes:"myclass custom-class",
            // searchBy: ['name'], 
            // maxHeight:110,
            // minHeight:30,
            // lazyLoading: true
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            maxHeight:140,
            minHeight:30,


        };
        
        this.liveEventSettings = {
            singleSelection: true, 
            // text:"Select",
            // selectAllText:'Select All',
            // unSelectAllText:'UnSelect All',
            // enableSearchFilter: true,
            // classes:"myclass custom-class",
            // searchBy: ['name'], 
            // maxHeight:140,
            // minHeight:30,
            // lazyLoading: true

            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            maxHeight:140,
            minHeight:30,

        };
        
        this.liveVirtualChannelSettings = {
            singleSelection: true, 
            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            searchBy: ['name'], 
            maxHeight:140,
            minHeight:30,
            lazyLoading: true
            // text:"Select",
            // selectAllText:'Select All',
            // unSelectAllText:'UnSelect All',
            // enableSearchFilter: true,
            // classes:"myclass custom-class",
            // maxHeight:140,
            // minHeight:30,


        };
        
        this.assetLibrarySettings = {
            singleSelection: false, 
            // text:"Select",
            // selectAllText:'Select All',
            // unSelectAllText:'UnSelect All',
            // enableSearchFilter: true,
            // classes:"myclass custom-class",
            // searchBy: ['name'], 
            // maxHeight:140,
            // minHeight:30,
            // lazyLoading: true

            text:"Select",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class",
            maxHeight:140,
            minHeight:30,

        };


        this.productTypeMappingFilter.geoZoneId = [];
        this.primaryNavigationMappingFilter.productTypeId = [];
        this.menuCategoryMappingFilter.primaryNavigationId = [];
        this.assetGroupMappingFilter.menuCategoryId = [];
        this.assetCategoryMappingFilter.assetGroupId = [];


        if(this.uplynkAdServerList.length == 0){

            this.getGlobalReviewInfo();
            this.getGlobalLiveVirtualChannel();
            this.getGlobalCast();
            this.getGeoZoneInfo();
    
            // this.getProductTypeInfo(this.productTypeMappingFilter);
            // this.getNavigationInfo(this.primaryNavigationMappingFilter);
            // // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
            // this.getGlobalAssetGroup(this.assetGroupMappingFilter);
            //  this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
    
            this.getCertificationInfo();
            this.getGlobaltrailervideo();
            this.getGlobalUplynkAdServer();

            this.getGlobalClientAdServer();

            this.getGlobalLiveEvent();
            this.getGlobalAssetLibrary();
            this.getGenreInfo();
            this.getLibrary();
            this.getLanguageInfo();
            this.getSponsoreInfo();
            this.getGlobalclassification();
            this.getAllAsset();

        }

       setTimeout((result:any)=>{
        if(this.reconsilitionObj.id == undefined){
            this.createNewAssetForm = this.formBuilder.group({
                id:[''],  
                assetType:[''],
                ingestionType: [''],
                contentType: [''],
                vodOrLivePosterImage: [''],
                vodAssetTitle:[''],
                vodClosedCaption:[''],
                vodIsDrm:[""],
                lyrics:[""],
                vodAssetDesc:[""],
                vodOrLiveAdServer:[[]],
                vodPreRollAds:[""],
                vodPostRollAds:[""],

                vodClientSideAdServer:[""],
                vodClientSidePreRollAds:[""],
                vodClientSideMidRollAds:[""],
                vodClientSideMidRollDuration:[""],
                vodClientSidePostRollAds:[""],
    
                liveType: [""],
                liveVirtualChannelId: [[]],
                liveEventId: [[]],
                mapAssetMetaData:[[]],
                mapAssetCategory:[[]],
                mapAssetGroup:[[]],
                mapMenuCategory:[[]],
                mapBreak: [[]],
                mapMulticam: [[]],
                mapAssetLibrary: [[]],
                mapVirtualChannelSchedule: [[]],
                isActive: ["1"],
                langId: [this.langId],
                companyId:[ ""],
                title: ['',Validators.compose([Validators.required])],
                sameForAllZone:[''],
                duration:[this.reconsilitionObj.Duration],
                sameForAllProductType: [''],
                displayIndexing:[''],
                permaLink:[''],
                // airStartDate:['',Validators.required],
                // airStartTime:['',Validators.required],
                // airEndTime:['',Validators.required],
                // airEndDate: ['',Validators.required],
                airStartDate:[''],
                airStartTime:[''],
                airStartTimezone:[''],
                airEndTime:[''],
                airEndDate: [''],
                airEndTimezone:[''],
                exclusionText: [''],
                description: [this.reconsilitionObj.title],
                seoTitle: [''],
                seoKeyword: [''],
                seoDescription:[''],
                mapCharacter:[[]],
                mapGeoZone: ['', Validators.compose([Validators.required])],
                productDeviceTypeConfigure:[''],
                mapProductType:['',Validators.compose([Validators.required])],
                mapPrimaryNavigation: [[]],
                mapCertification: [[]],
                mapClassification:[[]],
                epgUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
                hlsUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
                copyrightText:[''],
                expandParameter:[''],
                isMulticamApplicable:[''],
            //   vod_asset_desc:[''],
            //   vod_is_drm:[''],
                vodOrLivePosterImageFileName: [''],
                vodOrLivePosterImageFilePath: [''],
                vodOrLivePosterImageFileType:[''],
                vodClosedCaptionFileName:[''],
                vodClosedCaptionFilePath:[''],
                vodClosedCaptionFileType:['',],
                mapCast:[[]],
                mapGenre:[[]],
                mapLibrary:[[]],
                mapLanguage:[[]],
                mapSponsor:[[]],
                mapReview:[[]],
                uplynkId:[this.uplynkId],
                contentState:['In-Progress'],
                publisherState:['Un-published'],
                reviewerState:['Un-approved'],
                created_by:[localStorage.getItem('ud')]

            
             })
        }
       },100)

    
      //pravin start code
      this.multipleDeviceForm = this.formBuilder.group({
          devices: this.formBuilder.array([this.createItem()])
      });

      //pravin start code
      this.mapBreakForm = this.formBuilder.group({
          mapBreak: this.formBuilder.array([this.createmapBreak()])
      });

      //added for new req of multicam setup
      this.mapMulticamForm = this.formBuilder.group({
        mapMulticam: this.formBuilder.array([this.createMulticamForm()])
      });

      

      this.mapAssetMetaDataForm = this.formBuilder.group({
          mapAssetMetaData: this.formBuilder.array([this.createMetadata()])
      });

      this.mapVirtualChannelScheduleForm = this.formBuilder.group({
          mapVirtualChannelSchedule: this.formBuilder.array([])
      });

    }

    public onChange(event) {

     console.log( "data>>>",event );
   }

    resetForm(){

        this.img = [ ];

    this.productTypeMappingFilter = {};
    this.primaryNavigationMappingFilter = {};
    this.menuCategoryMappingFilter = {};
    this.assetGroupMappingFilter = {};
    this.assetCategoryMappingFilter = {};

        this.trailerVideoList = [];
        this.mapProductTypeArrayTemp =[]; 
        this.mapMenuCategoryArrayTemp = [];
        this.mapAssetGroupArrayTemp = [];
        this.mapPrimaryNavigationArrayTemp = [];
        this.selectedItems = [];
        this.selectedAssetCategory=[];
        this.selectedmapCast=[];
        this.selectedProductlist=[];
        this.selectedprimaryNavigationList=[];
        this.selectedcertificationList=[];
        this.selectedTrailerVideoList=[];
        this.selectedmenuCategoryList=[];
        this.selectedAssetGroupList=[];
        this.selectedAssetLibraryList = [];
        this.selectedLiveVirtualChannelList = [];
        this.selectedLiveEventList = [];
        this.selectedUplynkAdServerList = [];

        this.selectedClientAdServerList = [];

        this.update = false;

        this.createNewAssetForm = this.formBuilder.group({
            id:[''],  
            assetType:[''],
            ingestionType: [''],
            contentType: [''],
            vodOrLivePosterImage: [''],
            vodAssetTitle:[''],
            vodClosedCaption:[''],
            vodIsDrm:[""],
            lyrics:[""],
            permaLink:[''],
            vodAssetDesc:[""],
            vodOrLiveAdServer:[[]],
            vodPreRollAds:[""],
            vodPostRollAds:[""],

            vodClientSideAdServer:[""],
            vodClientSidePreRollAds:[""],
            vodClientSideMidRollAds:[""],
            vodClientSideMidRollDuration:[""],
            vodClientSidePostRollAds:[""],

            liveType: [""],
            liveVirtualChannelId: [[]],
            liveEventId: [[]],
            mapAssetMetaData:[[]],
            mapAssetCategory:[[]],
            mapAssetGroup:[[]],
            mapMenuCategory:[[]],
            mapBreak: [[]],
            mapMulticam: [[]],
            mapAssetLibrary: [[]],
            mapVirtualChannelSchedule: [[]],
            isActive: ["1"],
            langId: ["1"],
            companyId:[ ""],
            title: ['',Validators.compose([Validators.required])],
            sameForAllZone:[''],
            duration:[''],
            sameForAllProductType: [''],
            displayIndexing:[''],
            // airStartDate:['',Validators.required],
            // airStartTime:['',Validators.required],
            // airEndTime:['',Validators.required],
            // airEndDate: ['',Validators.required],
            airStartDate:[''],
            airStartTime:[''],
            airStartTimezone:[''],
            airEndTime:[''],
            airEndDate: [''],   
            airEndTimezone:[''],
            exclusionText: [''],
            description: [''],
            seoTitle: [''],
            seoKeyword: [''],
            seoDescription:[''],
            mapCharacter:[[]],
            mapGeoZone: ['', Validators.compose([Validators.required])],
            productDeviceTypeConfigure:[''],
            mapProductType:['',Validators.compose([Validators.required])],
            mapPrimaryNavigation: [[]],
            mapCertification: [[]],
            mapClassification:[[]],
            mapLibrary:[[]],
            epgUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
            hlsUrl:['',Validators.compose([Validators.pattern(urlPattern)])],
            copyrightText:[''],
            expandParameter:[''],
            isMulticamApplicable:[''],
        //   vod_asset_desc:[''],
        //   vod_is_drm:[''],
            vodOrLivePosterImageFileName: [''],
            vodOrLivePosterImageFilePath: [''],
            vodOrLivePosterImageFileType:[''],
            vodClosedCaptionFileName:[''],
            vodClosedCaptionFilePath:[''],
            vodClosedCaptionFileType:['',],
            mapCast:[[]],
            mapGenre:[[]],
            mapLanguage:[[]],
            mapSponsor:[[]],
            mapReview:[[]],
            uplynkId:[this.uplynkId],
            contentState:['In-Progress'],
            publisherState:['Un-published'],
            reviewerState:['Un-approved']
            
        
       })
    

    const arrayControl1 = this.mapBreakForm.get('mapBreak') as FormArray;
    while (arrayControl1.length !== 0) {

        arrayControl1.removeAt(0);
    }

    const arrayControlMultiCam = this.mapMulticamForm.get('mapMulticam') as FormArray;
    while (arrayControlMultiCam.length !== 0) {

        arrayControlMultiCam.removeAt(0);
    }


    const arrayControl2 = this.mapAssetMetaDataForm.get('mapAssetMetaData') as FormArray;
    while (arrayControl2.length !== 0) {

        arrayControl2.removeAt(0);
    }

    const arrayControl3= this.mapVirtualChannelScheduleForm.get('mapVirtualChannelSchedule') as FormArray;
    while (arrayControl3.length !== 0) {

        arrayControl3.removeAt(0);
    }

    const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
    while (arrayControl.length !== 0) {

        arrayControl.removeAt(0);
    }

        this.uploadImgFilePathHorizontal = [];
        this.uploadImgFilePathVertical = [];

        if(this.reset == true){

           // this.updateSend.emit();
            this.reset = false;
            
        }

        this.id = null;

        this.updateSend.emit();
      
      //pravin start code
      this.multipleDeviceForm = this.formBuilder.group({
          devices: this.formBuilder.array([this.createItem()])
      });


      //pravin start code
      this.mapBreakForm = this.formBuilder.group({
          mapBreak: this.formBuilder.array([this.createmapBreak()])
      });

      //added for new req of multicam setup
      this.mapMulticamForm = this.formBuilder.group({
        mapMulticam: this.formBuilder.array([this.createMulticamForm()])
      });

      this.mapAssetMetaDataForm = this.formBuilder.group({
          mapAssetMetaData: this.formBuilder.array([this.createMetadata()])
      });

      this.mapVirtualChannelScheduleForm = this.formBuilder.group({
          mapVirtualChannelSchedule: this.formBuilder.array([])
      });

      this.img = []

      this.img.push({
            horizontalURL:'',
            verticalURL:''  
       })

       this.FilePosterImageURL ='';


        let objH = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "horizontalFileName": "",
            "horizontalFilePath": "",
            "horizontalFileType": "",
            'horizontalFile':false
        }

        let objV = {
            'index':"",
            'deviceType':"",
            'selectedFiles':"",
            "verticalFileName":"",
            "verticalFilePath": "",
            "verticalFileType":"",
            'verticalFile':false,
            
        }

        this.uploadImgFilePathHorizontal.push(objH);
        this.uploadImgFilePathVertical.push(objV);

         this.update = false;
         this.showCreateNewButton('show');
         
    }


    filterMapAsset:any={};
    startMapAsset = 0;
    assetLists:any=[];

    getAllAsset(){
 
        this.loader = false;
        
        this.assetService.getAllAsset(this.filterMapAsset,this.startMapAsset,10000)
        .subscribe((result:any)=>{
            
            if(result.data.data.length > 0){  
                
                if(this.isSearch == true){
                    this.assetLists = [];
                    this.isSearch = false;

                }

                for (let assetLists of result.data.data) {
    
                    if(assetLists.title != '' && assetLists.title != null){
                    
                        this.assetLists.push(
                        {
                            'id': assetLists.id,
                            'itemName': assetLists.title
                        });
                    } 

                    this.assetLists = this.removeDuplicates(this.assetLists,'id')
                }

            } 
        }) 
        
    }

    fetchMoreGetAssetMap(event){

        if ( ((event.endIndex >= this.assetLists.length - 2)) && (this.assetLists.length >=10)) {

            this.startMapAsset = this.startMapAsset + 10;

            this.getAllAsset();

    }
    }

    onItemSelectAssetMap(event , i){

        if (event.itemName!=''){

            this.mapVirtualArray[i].push(event);
            console.log("onItemSelectAssetMap",this.mapVirtualArray[i]);
                
       }

    }
    
    OnItemDeSelectAssetMap(event , i){

        if (event.itemName!=''){

                this.mapVirtualArray[i].push(event);
                console.log("OnItemDeSelectAssetMap",this.mapVirtualArray[i]);
        }

    }

    onSelectAllAssetMap(){

        this.mapVirtualArray = this.assetLists;

    }

    onDeSelectAllAssetMap(){
        this.mapVirtualArray = [];
    }

    onDeleteAssetMap(i,j){

        
        if(j==0){

            this.mapVirtualArray[i].shift();

        }else{

            this.mapVirtualArray[i].splice(j,1);

        }

    }



    eventValue:any='df';

    onSearch(event, info){

        if(this.eventValue == '' && event.target.value == '')
            return;

        this.activeSearchBox = info;
        var obj = {};
        this.isSearch = true;
        this.eventValue=event.target.value;

        
        if(info == 'trailer'){
            
            if(event.target.value!=''){

                this.filterTrailer =  obj = { 'assetName': event.target.value };
                // this.trailerVideoList = [];

            }else {
                // this.mapClassificationList=[];
                this.filterTrailer =   obj = { } 
            }

            this.startTrailer = 0;

            this.searchDecouncer$.next(event.target.value);

        }
   
        if(info == 'liveVirtualChannelId'){
            
            if(event.target.value!=''){

                this.filterVchannel =  obj = { 'channelName': event.target.value };
                // this.trailerVideoList = [];

            }else {
                // this.mapClassificationList=[];
                this.filterVchannel =   obj = { } 
            }

            this.startVchannel = 0;

            this.searchDecouncer$.next(event.target.value);

        }
        
        if(info == 'assetMapping'){
            
            if(event.target.value!=''){

                this.filterMapAsset =  obj = { 'keyword': event.target.value };
                // this.trailerVideoList = [];

            }else {
                // this.mapClassificationList=[];
                this.filterMapAsset =   obj = { } 
            }

            this.startMapAsset = 0;

            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'classification'){
            
            if(event.target.value!=''){

                this.classificationFilter =  obj = { 'classificationName': event.target.value };
                this.mapClassificationList=[];

            }else {
                this.mapClassificationList=[];
                this.classificationFilter =   obj = { } 
            }

            this.startClassification = 0;
            this.classificationScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'review'){

            if(event.target.value!=''){

            this.reviewFilter = obj = { 'reviewFirstName': event.target.value };
            // this.mapReviewList = [];
    
            }else {

                // this.mapReviewList = [];
                this.reviewFilter = obj = { } 
            }

            this.startReview = 0;
            this.reviewScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);

        }
        
        if(info == 'genre'){

            if(event.target.value!=''){

                this.genreFilter = obj = { 'genreName': event.target.value };
                this.mapGenreList= [];

    
            }else {

                this.genreFilter = obj = { };
                this.mapGenreList= [];
            }

            this.startGenre = 0;
            this.reviewScrollCount = 0;
            this.searchDecouncer$.next(event.target.value);

        }

        if(info == 'sponsore'){

            if(event.target.value!=''){

                this.sponsoredFiter = obj = { 'sponsorName': event.target.value };
                this.mapSponsoreList = [];
    
            }else {

                this.sponsoredFiter =  obj = { } 
            }

            this.startSponsored = 0;
            this.SponsoredScrollCount = 0;

            this.mapSponsoreList = [];

            this.searchDecouncer$.next(event.target.value);   

        }

        if(info == 'language'){

            if(event.target.value!=''){

                this.languageFilter =  obj = { 'languageName': event.target.value };
                this.mapLibraryList=[];

            }else {
                this.mapLibraryList=[];
                this.languageFilter =  obj = { } 
            }

            this.startLanguage = 0;
            this.SponsoredScrollCount = 0;
            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'library'){

            if(event.target.value!=''){

                 this.libraryFilter =  obj = { 'libraryName': event.target.value };
                 this.mapLibraryList = [];

            }else {

                this.libraryFilter =  obj = { };
                this.mapLibraryList = [];
            }

            this.startClassification = 0;
            this.SponsoredScrollCount = 0;

            this.searchDecouncer$.next(event.target.value);
        }

        if(info == 'certification'){

            if(event.target.value!=''){

                obj = { 'certificationName': event.target.value };
                this.mapCertificatList=[];
                this.certificateFilter = obj;
                this.startCertificate = 0;

                this.searchDecouncer$.next(event.target.value);

        
            }else {

                this.certificateFilter = obj = { };
            }

            this.startCertificate = 0;
            this.certificateFilter = obj ;

            this.mapCertificatList=[];
            this.searchDecouncer$.next(event.target.value);
        }  

        if(info == 'cast'){
            

            if(event.target.value!=''){

                obj = { 'castFirstName': event.target.value };
                this.startCast = 0;
                this.mapCastlist = [];      
                this.castFilter = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.castFilter = obj ;
                this.mapCastlist = [];      
                this.startCast = 0;

                this.searchDecouncer$.next(event.target.value);

            }
        }

        if(info == 'character'){
            

                if(event.target.value!=''){
    
                    obj = { 'characterFirstName': event.target.value };
                    this.startCharacter = 0;
                    this.mapCharacterList = [];      
                    this.characterFilter = {  } ;
                    this.characterFilter = obj;
                    // this.characterFilter = {};


    
                    this.searchDecouncer$.next(event.target.value);
    
                }else {
    
                    obj = { };
                    this.characterFilter = obj ;
                    this.mapCharacterList = [];      
                    this.startCharacter = 0;
    
                    this.searchDecouncer$.next(event.target.value);
    
                }
  
        }  
        
        if(info == 'geoZone'){
            

            if(event.target.value!=''){

                obj = { 'keyword': event.target.value };
                this.start = 0;
                this.geoZoneList = [];      
                this.geoZoneObj = obj ;

                this.searchDecouncer$.next(event.target.value);

            }else {

                obj = { };
                this.geoZoneObj = obj ;
                this.geoZoneList = [];      
                this.start = 0;

                this.searchDecouncer$.next(event.target.value);

            }
  
        }  

        if(info == 'productType'){

            console.log("producttype",event);

            if(event.target.value!=''){

                this.productTypeMappingFilter.productTypeName = event.target.value;
                this.producTypeList = [];
                this.startProduct = 0;
                this.productScrollCount = 0;
               // this.subject.next(event.target.value);
               this.searchDecouncer$.next(event.target.value);
                //this.getProductTypeInfo(this.productTypeMappingFilter);

    
            }else {

                var geoZoneId = this.productTypeMappingFilter.geoZoneId;

                this.productTypeMappingFilter ={};
                this.productTypeMappingFilter.geoZoneId = geoZoneId;

                this.producTypeList = [];      
                this.startProduct = 0;
                this.productScrollCount = 0;
                // this.subject.next(event.target.value);
                this.searchDecouncer$.next(event.target.value);
                //  this.getProductTypeInfo(this.productTypeMappingFilter);

                

            }

            
        }

        if(info == 'navigation'){

            if(event.target.value!=''){

                this.primaryNavigationMappingFilter.primanyNavigationName = event.target.value;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                this.searchDecouncer$.next(event.target.value);

            }else {

               var productTypeId =  this.primaryNavigationMappingFilter.productTypeId;

               this.primaryNavigationMappingFilter = {};
               this.primaryNavigationMappingFilter.productTypeId = productTypeId;
                this.primaryNavigationList = [];
                this.startPrimary = 0;
                // this.getNavigationInfo(this.primaryNavigationMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }

        } 
 
        if(info == 'category'){

            if(event.target.value!=''){

                   this.menuCategoryMappingFilter.menuCategoryName = event.target.value;
                   this.menuCategoryList = [];
                   this.startCategory = 0;
                   //this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                   this.searchDecouncer$.next(event.target.value);

            }else {

                var primaryNavigationId =  this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter = {};
                delete this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryList = [];
                this.startCategory = 0;
                // this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetGroup'){

            if(event.target.value!=''){

                   this.assetGroupMappingFilter.assetGroupName = event.target.value;
                   this.assetGroupList = [];
                   this.startGroup = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                var menuCategoryId =  this.assetGroupMappingFilter.menuCategoryId;
                this.assetGroupMappingFilter ={}
                this.assetGroupMappingFilter.menuCategoryId = menuCategoryId;
                this.assetGroupList = [];
                this.startGroup = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }   
        
        if(info == 'assetCategory'){

            if(event.target.value!=''){

                   this.assetCategoryMappingFilter.assetCategoryName = event.target.value;
                   this.assetCategoryList = [];
                   this.startAssetCategory = 0;
                   this.searchDecouncer$.next(event.target.value);

            }else {

                this.assetCategoryMappingFilter={}
                this.assetCategoryList = [];
                this.startAssetCategory = 0;
                this.searchDecouncer$.next(event.target.value);

            }

        }   

            
    }

    onOpen(info){

    this.activeSearchBox = info;

           if(info == 'liveVirtualChannelId'){

               this.startVchannel=0;
               this.liveVirtualChannelList =[];
               this.filterVchannel = {};
               this.getGlobalLiveVirtualChannel();

           }


            if(this.activeSearchBox == 'assetMapping'){
                 
                this.assetLists=[];
                this.startMapAsset = 0;
                this.filterMapAsset = {};
                this.getAllAsset();

            }

            if(info == 'trailer'){

                this.trailerVideoList=[];
                this.startTrailer = 0;
                this.filterTrailer = {};
                this.getGlobaltrailervideo();

            }
    
          if(info == 'assetCategory'){
            
            this.assetCategoryList=[];
            this.startAssetCategory = 0;
            this.assetCategoryMappingFilter.assetCategoryName = undefined;
            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);

          }   

        if(this.activeSearchBox == 'assetGroup'){

            this.assetGroupList=[];
            this.startGroup = 0;
            
            this.assetGroupMappingFilter.assetCategoryName = undefined;

            this.getGlobalAssetGroup(this.assetGroupMappingFilter);

        }

        if(this.activeSearchBox == 'character'){

            this.mapCharacterList=[];
            this.characterFilter={};
            this.startCharacter = 0;
            this.getGlobalCharacter();

        }

            if(this.activeSearchBox == 'cast'){

                this.mapCastlist=[];
                this.castFilter={};
                this.startCast = 0;
                this.getGlobalCast();

            }

            if(this.activeSearchBox == 'classification'){

                    this.classificationFilter={};
                    this.mapClassificationList=[];
                    this.startClassification = 0;
                    this.getGlobalclassification();

            }

            if(this.activeSearchBox == 'review'){

                    this.reviewFilter={};
                    this.mapReviewList = [];
                    this.startReview = 0;
                    this.getGlobalReviewInfo();

            }

            if(this.activeSearchBox =='genre'){

                    this.genreFilter = {};
                    this.mapGenreList = [];
                    this.startGenre = 0;
                    this.getGenreInfo();    

            }

            if(this.activeSearchBox == 'sponsore'){

                this.sponsoredFiter = {};
                this.mapSponsoreList = [];
                this.startSponsored = 0;
                    this.getSponsoreInfo();   

            }

            if(this.activeSearchBox == 'language'){

                
                this.languageFilter ={};
                this.mapLanguageList = [];
                this.startLanguage = 0;
                this.getLanguageInfo();

            }

            if(this.activeSearchBox == 'library'){
    
                this.libraryFilter = {};
                this.startLibrary = 0;
                this.mapLibraryList = [];
                this.getLibrary();
            }

            if(this.activeSearchBox == 'certification'){

                this.certificationList = [];
                this.certificateFilter = {};
                this.startCertificate = 0;

                this.getCertificationInfo();   
            }  

            if(this.activeSearchBox == 'geoZone'){

                this.geoZoneObj = {};
                this.geoZoneList = [];
                this.start =0;
                this.getGeoZoneInfo();       

            }  

            if(this.activeSearchBox == 'productType'){

                // this.productTypeMappingFilter.geoZoneId=this.productTypeMappingFilter.geoZoneId((el, i, a) => i === a.indexOf(el));

                var geoZoneId = this.productTypeMappingFilter.geoZoneId;
                this.productTypeMappingFilter ={};
                this.productTypeMappingFilter.geoZoneId = geoZoneId;

                this.producTypeList = []; 
                this.startProduct = 0;
                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }

            if(this.activeSearchBox == 'navigation'){


                var productTypeId =  this.primaryNavigationMappingFilter.productTypeId;

                this.primaryNavigationMappingFilter = {};
                this.primaryNavigationMappingFilter.productTypeId = productTypeId;
                //  this.primaryNavigationList = [];
                 this.startPrimary = 0;
                this.startPrimary  =0;
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     

            } 

            if(this.activeSearchBox == 'category'){

                var productTypeId =  this.menuCategoryMappingFilter.primaryNavigationId;
                this.menuCategoryMappingFilter = {};
                delete this.menuCategoryMappingFilter.menuCategoryName;
                this.menuCategoryMappingFilter.primaryNavigationId = productTypeId
                this.menuCategoryList = [];

                this.startCategory = 0;
                setTimeout(()=>{
                    this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

                },100)

            }   


    }

    private setupSearchDebouncer(): void {

        // Subscribe to `searchDecouncer$` values,
        // but pipe through `debounceTime` and `distinctUntilChanged`
        this.searchDecouncer$.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((term: string) => {

            if(this.activeSearchBox == 'liveVirtualChannelId'){

               this.getGlobalLiveVirtualChannel();
    
            }

            if(this.activeSearchBox == 'trailer'){

                this.getGlobaltrailervideo();
            }

            if(this.activeSearchBox == 'assetMapping'){

                this.getAllAsset();
            }

            if(this.activeSearchBox == 'assetGroup'){

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);

            }

            if(this.activeSearchBox == 'character'){

                this.getGlobalCharacter();

            }

            if(this.activeSearchBox == 'cast'){

                this.getGlobalCast();

            }

            if(this.activeSearchBox == 'classification'){
            
                    this.getGlobalclassification();
    
            }
    
            if(this.activeSearchBox == 'review'){
    
                    this.getGlobalReviewInfo();
   
            }
            
            if(this.activeSearchBox =='genre'){
    
                    this.getGenreInfo();    
    
            }
    
            if(this.activeSearchBox == 'sponsore'){
    
                    this.getSponsoreInfo();   
    
            }
    
            if(this.activeSearchBox == 'language'){
    
                    this.getLanguageInfo();
    
            }
    
            if(this.activeSearchBox == 'library'){
    
                    this.getLibrary();
            }
    
            if(this.activeSearchBox == 'certification'){
    
    
                 this.getCertificationInfo();   
            }  
            
            if(this.activeSearchBox == 'geoZone'){
                
                this.getGeoZoneInfo();       
      
            }  
    
            if(this.activeSearchBox == 'productType'){
    

                this.getProductTypeInfo(this.productTypeMappingFilter);
                
            }
    
            if(this.activeSearchBox == 'navigation'){
    
                this.getNavigationInfo(this.primaryNavigationMappingFilter);     
    
            } 
     
            if(this.activeSearchBox == 'category'){
    
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
    
            }   
            
            if(this.activeSearchBox == 'assetCategory'){
    
                this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
    
            }  
            

            if(this.activeSearchBox == 'assetMap'){
    
                this.getAllAsset();
    
            }  
            

        });
    }

  private searchDecouncer$: Subject<string> = new Subject();

    startProduct:number = 0;
    startCategory:number = 0;
    startLibrary:number = 0;
    startLanguage:number = 0;
    activeSearchBox:any=[];
    isSearch:any='';
    geoZoneObj:any={};
    productScrollCount=0;
    classificationFilter:any={};
    startClassification = 0;
    classificationScrollCount = 0;
    reviewFilter:any={};
    startPrimary = 0;
    mapCertificatList=[];
    certificateFilter:any={};
    startCertificate = 0;
    libraryFilter:any={};
    SponsoredScrollCount = 0;
    languageFilter:any={};
    sponsoredFiter;any={};
    
    startGenre = 0;
    genreFilter:any = {};
    startReview = 0;
    startSponsored = 0;
    reviewScrollCount = 0;
    description:boolean = false;


    onAssetInfoTabClick(){

        this.formTabs = {

            assetInfo:{

            asset:true,
            mapGeoZone:true,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }
    }

    oninformationTabClick(){
       
        // this.createNewAssetForm.controls['description'].setValue('');

        this.formTabs = {

            assetInfo:{

            asset:true,
            mapGeoZone:false,
            information:true,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }  
    }

    onDeviceTypeTabClick(){

        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:true,
                configuration:true,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }
    }

    onMappingTabClick(){

        this.selectedProductlist = this.removeDuplicates(this.selectedProductlist,'id');
        setTimeout(()=>{

            this.createNewAssetForm.controls['mapProductType'].setValue(this.selectedProductlist);

        },100)

        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:true,
                contentMapping:true,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }
    }

    onInformationMappingTabClick(){
        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:true,
                contentMapping:false,
                informationMapping:true,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }
    }

    oncastAndCrewTabClick(){

        this.formTabs = {

            assetInfo:{

            asset:true,
            mapGeoZone:false,
            information:false,
            castAndCrew:true,
            seo:false
        
            },
            // castAndCrew:true,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
            // seo:false
        }
    }

    onAssetTypeInfo(){

        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:true,
                live:true,
                assetLiveVod:true
            },
        //    seo:false
        }
    }

    onAssetTypeInfoMultiCam(){

        this.formTabs = {

            assetInfo:{

            asset:false,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:false
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false,
                multicam:true
            }
        //    seo:false
        }
    }

    onSeoTabInfo(){

        this.formTabs = {

            assetInfo:{

            asset:true,
            mapGeoZone:false,
            information:false,
            castAndCrew:false,
            seo:true
        
            },
            // castAndCrew:false,
            mappingInfo:{
                mapping:false,
                contentMapping:false,
                informationMapping:false,
            },

            deviceType:{
                
                configurationType:false,
                configuration:false,
            
            },
            assetLiveVod:{
                
                vod:false,
                live:false,
                assetLiveVod:false
            },
        //    seo:true
        }
    }


    get l() { return this.createNewAssetForm.controls; }
    
    get m(): FormArray {
        return this.multipleDeviceForm.get('devices') as FormArray;
    }
    
    get b(): FormArray {
        return this.mapBreakForm.get('mapBreak') as FormArray;
    }

    get mc(): FormArray {
        return this.mapMulticamForm.get('mapMulticam') as FormArray;
    }
    
    get a(): FormArray {
        return this.mapAssetMetaDataForm.get('mapAssetMetaData') as FormArray;
    }
    
    get vc(): FormArray {
        return this.mapVirtualChannelScheduleForm.get('mapVirtualChannelSchedule') as FormArray;
    }

    onItemSelectDevice(e,info){
        
        this.removeTrailerIndex = null;

    }

    //dri multiselect code
    onItemSelect(item:any, mappingName){

        if(mappingName == 'mapGeoZone'){
            this.productTypeMappingFilter.geoZoneId.push(item.id);
            this.getProductTypeInfo(this.productTypeMappingFilter);
        }
        else if(mappingName == 'mapProductType'){
            this.primaryNavigationMappingFilter.productTypeId.push(item.id);
            this.getNavigationInfo(this.primaryNavigationMappingFilter);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            this.menuCategoryMappingFilter.primaryNavigationId.push(item.id);
            this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
        }
        else if(mappingName == 'mapMenuCategory'){
            this.assetGroupMappingFilter.menuCategoryId.push(item.id);
            this.getGlobalAssetGroup(this.assetGroupMappingFilter);
        }
        else if(mappingName == 'mapAssetGroup'){
            this.assetCategoryMappingFilter.assetGroupId.push(item.id);
            this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
        }
        
    }
    
    OnItemDeSelect(item:any, mappingName){

        
        console.log("mappingName",mappingName);

        if(mappingName == 'mapGeoZone'){

            for (let index=0; index< this.productTypeMappingFilter.geoZoneId.length;index++){

                if(this.productTypeMappingFilter.geoZoneId[index] == item.id){
                    this.productTypeMappingFilter.geoZoneId.splice(index, 1);
                    console.log("mapGeoZone deleted = ",item.id);

                    if(this.productTypeMappingFilter.geoZoneId.length == 0){

                        this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.primaryNavigationList = [];

                        this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];

                        this.createNewAssetForm.controls['mapProductType'].setValue([]);
                        this.producTypeList = [];

                        this.assetCategoryList = [];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);


                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];


                    }else{

                        this.getProductTypeInfo(this.productTypeMappingFilter);
                    }

                   
                }
            }
        }
        else if(mappingName == 'mapProductType'){

            console.log("this.primaryNavigationMappingFilter",this.primaryNavigationMappingFilter);

            for (let index=0; index< this.primaryNavigationMappingFilter.productTypeId.length;index++){
                if(this.primaryNavigationMappingFilter.productTypeId[index] == item.id){

                    this.primaryNavigationMappingFilter.productTypeId.splice(index, 1);

                    if(this.primaryNavigationMappingFilter.productTypeId.length == 0){

                        this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.primaryNavigationList = [];

                        this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                        this.assetCategoryList = [];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);


                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];


                    }else{
                    
                        this.getNavigationInfo(this.primaryNavigationMappingFilter);
                       
                    }

                   
                }
            }
        }
        else if(mappingName == 'mapPrimaryNavigation'){

            console.log("Mayuri");
            for (let index=0; index< this.menuCategoryMappingFilter.primaryNavigationId.length;index++){
                console.log("Mayuri 1");
                if(this.menuCategoryMappingFilter.primaryNavigationId[index] == item.id){
                    console.log("Mayuri 2");
                    this.menuCategoryMappingFilter.primaryNavigationId.splice(index, 1);
                
                    if(this.menuCategoryMappingFilter.primaryNavigationId.length == 0){

                        this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                        this.menuCategoryList = [];
            
                        
                        this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                        this.menuCategoryList = [];
                        
                        this.assetCategoryList = [];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
            
            
                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];

                        this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);

                        this.menuCategoryList = [];

                        this.selectedAssetCategory =[];
                        this.selectedmenuCategoryList = [];
                        this.selectedAssetGroupList = [];

                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];

                        this.assetCategoryList = [];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);

                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];


                    }else{

                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);

                    }

                }
            }
        }
        else if(mappingName == 'mapMenuCategory'){

            for (let index=0; index< this.assetGroupMappingFilter.menuCategoryId.length;index++){

                if(this.assetGroupMappingFilter.menuCategoryId[index] == item.id){

                    this.assetGroupMappingFilter.menuCategoryId.splice(index, 1);
                    console.log("mapMenuCategory deleted = ",item.id);

                    if(this.assetGroupMappingFilter.menuCategoryId.length == 0){
                      
                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];
                        this.selectedAssetGroupList = [];


                        this.assetCategoryList = [];
                        this.selectedAssetCategory =[];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);

                      
                    }else{
                        this.getGlobalAssetGroup(this.assetGroupMappingFilter);
                    }

                   
                }
            }
        }
        else if(mappingName == 'mapAssetGroup'){

            for (let index=0; index< this.assetCategoryMappingFilter.assetGroupId.length;index++){
                if(this.assetCategoryMappingFilter.assetGroupId[index] == item.id){
                    this.assetCategoryMappingFilter.assetGroupId.splice(index, 1);
                    console.log("mapAssetGroup deleted = ",item.id);

                    if(this.assetCategoryMappingFilter.assetGroupId.length == 0){

                        // this.assetCategoryList = [];
                        // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
                      
                    }else{

                        this.getGlobalAssetCategory(this.assetCategoryMappingFilter);

                    }

                }
            }
        }
    }
    
    onSelectAll(item: any , mappingName){

        if(mappingName == 'mapGeoZone'){


            this.createNewAssetForm.value.mapGeoZone
        
            let filterObj ={ geoZoneId :[] };
            item.forEach(function (value) {
                filterObj.geoZoneId.push(value.id);
            });
            // this.productTypeMappingFilter=filterObj;

           this.productTypeMappingFilter.concat(filterObj);
            this.getProductTypeInfo(this.productTypeMappingFilter);
        }
        else if(mappingName == 'mapProductType'){
            let filterObj ={ productTypeId :[] };
            item.forEach(function (value) {
                filterObj.productTypeId.push(value.id);
            });
            this.primaryNavigationMappingFilter = filterObj;
            this.getNavigationInfo(this.primaryNavigationMappingFilter);
        }
        else if(mappingName == 'mapPrimaryNavigation'){
            let filterObj ={ primaryNavigationId :[] };
            item.forEach(function (value) {
                filterObj.primaryNavigationId.push(value.id);
            });
            this.menuCategoryMappingFilter = filterObj;
            this.getGlobalMenuCategory(filterObj);
        }
        else if(mappingName == 'mapMenuCategory'){
            let filterObj ={ menuCategoryId :[] };
            item.forEach(function (value) {
                filterObj.menuCategoryId.push(value.id);
            });
            this.assetGroupMappingFilter = filterObj;
            this.getGlobalAssetGroup(filterObj);
        }
        else if(mappingName == 'mapAssetGroup'){
            let filterObj ={ assetGroupId :[] };
            item.forEach(function (value) {
                filterObj.assetGroupId.push(value.id);
            });
            this.assetCategoryMappingFilter = filterObj;
            this.getGlobalAssetCategory(filterObj);
        }
    }
    // trailerVideoList
    removeTrailerIndex:any='';

    onDeSelectAllDevice($event,info, i){

        this.removeTrailerIndex = i;
        // const arrayControl = this.mapAssetMetaDataForm.get('mapTrailerVideo') as FormArray;
        // arrayControl.removeAt(0)

        // const arrayControl = this.mapVirtualChannelScheduleForm.get('mapTrailerVideo') as FormArray;
        // arrayControl.removeAt(i);

    }

    onDeSelectAll(event, info){

        if(info =='mapGeoZone'){
            this.createNewAssetForm.controls['mapGeoZone'].setValue([]);
            this.producTypeList = [];
            this.mapProductTypeArrayTemp = [];

            
            this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
            this.primaryNavigationList = [];
            this.mapPrimaryNavigationArrayTemp = [];

            this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
            this.menuCategoryList = [];
            this.mapMenuCategoryArrayTemp = [];

            this.createNewAssetForm.controls['mapProductType'].setValue([]);
            this.producTypeList = [];
            this.mapProductTypeArrayTemp = [];


            this.assetCategoryList = [];
            this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
            this.mapAssetCategoryArrayTemp = [];


            this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
            this.assetGroupList = [];
            this.mapAssetGroupArrayTemp = [];

            console.log("DeSelect All from = ",info);
        }
    
        else if(info =='mapProductType'){

            this.createNewAssetForm.controls['mapProductType'].setValue([]);
            this.primaryNavigationList = [];
            this.mapProductTypeArrayTemp = [];

            
            this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
            this.primaryNavigationList = [];
            this.mapPrimaryNavigationArrayTemp = [];


            this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
            this.menuCategoryList = [];
            this.mapMenuCategoryArrayTemp  = [];
            
            this.assetCategoryList = [];
            this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
            this.mapAssetCategoryArrayTemp = [];


            this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
            this.assetGroupList = [];
            
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapPrimaryNavigation'){
            this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
            this.menuCategoryList = [];
            this.mapPrimaryNavigationArrayTemp = [];


            
            this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
            this.menuCategoryList = [];
            this.mapMenuCategoryArrayTemp  = [];

            
            this.assetCategoryList = [];
            this.mapAssetCategoryArrayTemp = [];
            this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);


            this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
            this.assetGroupList = [];

            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapMenuCategory'){
             this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
            this.assetGroupList = [];
            this.mapMenuCategoryArrayTemp = [];


            this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
            this.mapAssetGroupArrayTemp = [];
            // this.assetGroupList = [];
            
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapAssetGroup'){
            this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
            this.mapAssetGroupArrayTemp =[];
            this.assetCategoryList = [];
            console.log("DeSelect All from = ",info);
        }
        else if(info =='mapAssetCategory'){
            this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
            this.mapAssetCategoryArrayTemp = [];
        }
        else if(info =='mapClassification'){

            this.createNewAssetForm.controls['mapClassification'].setValue([]);
        }
        else if(info =='mapCertification'){
            this.createNewAssetForm.controls['mapCertification'].setValue([]);
        }
        else if(info =='mapLibrary'){
            this.createNewAssetForm.controls['mapLibrary'].setValue([]);
        }
        else if(info =='mapSponsor'){
            this.createNewAssetForm.controls['mapSponsor'].setValue([]);
        }
        else if(info =='mapGenre'){
            this.createNewAssetForm.controls['mapGenre'].setValue([]);
        }
        else if(info =='mapLanguage'){
            this.createNewAssetForm.controls['mapLanguage'].setValue([]);
        }
        else if(info =='mapReview'){
            this.createNewAssetForm.controls['mapReview'].setValue([]);
        }
        else if(info =='mapCast'){
            this.createNewAssetForm.controls['mapCast'].setValue([]);
        }
        else if(info == 'mapAssetLibrary'){
            this.createNewAssetForm.controls['mapAssetLibrary'].setValue([]);
        }
        else if(info == 'vodOrLiveAdServer'){
            this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]);
        }
        else if(info == 'liveVirtualChannelId'){
            this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]);
        }
        else if(info == 'liveEventId'){
            this.createNewAssetForm.controls['liveEventId'].setValue([]);
        }
        else if(info == 'assetMapping'){
            this.createNewAssetForm.controls['assetMapping'].setValue([]);
        }   else if(info == 'mapCharacter'){
            this.createNewAssetForm.controls['mapCharacter'].setValue([]);
        }
    }
 
    onContentType(){

        console.log("<<createNewAssetForm>>>>",this.createNewAssetForm.value);

        if(this.createNewAssetForm.value.contentType === 'Live' ){
            //this.live = true;
            this.boolShowLiveContentType = true;
            this.boolShowVodContentType = false;
            this.boolShowAudioContentType = false;
        }
        
        if(this.createNewAssetForm.value.contentType === 'VOD' ) {
            //this.live = false;
            this.boolShowVodContentType = true;
            this.boolShowLiveContentType = false;
            this.boolShowAudioContentType = false;
           
        }

        if(this.createNewAssetForm.value.contentType === 'Audio' ) {
            //this.live = false;
            this.boolShowVodContentType = false;
            this.boolShowAudioContentType = true;
            this.boolShowLiveContentType = false;
           
        }

        if(this.boolShowLiveContentType == true ){

            this.mapBreakForm = this.formBuilder.group({
                mapBreak: this.formBuilder.array([this.createmapBreak()])
            });

            this.mapMulticamForm = this.formBuilder.group({
                mapMulticam: this.formBuilder.array([this.createMulticamForm()])
            });

      
            this.mapAssetMetaDataForm = this.formBuilder.group({
                mapAssetMetaData: this.formBuilder.array([this.createMetadata()])
            });

            this.FileClosedCaptionURL = '';
            this.createNewAssetForm.controls['mapAssetMetaData'].setValue([]); 
            this.createNewAssetForm.controls['mapBreak'].setValue([]); 
            this.createNewAssetForm.controls['mapMulticam'].setValue([]); 
            this.createNewAssetForm.controls['vodAssetDesc'].setValue(''); 
            this.createNewAssetForm.controls['vodPreRollAds'].setValue(''); 
            this.createNewAssetForm.controls['mapAssetLibrary'].setValue([]);  
            this.createNewAssetForm.controls['vodIsDrm'].setValue(''); 
        
        }else if (this.boolShowVodContentType == true){

            this.mapVirtualChannelScheduleForm = this.formBuilder.group({
                mapVirtualChannelSchedule: this.formBuilder.array([])
            });

            this.createNewAssetForm.controls['liveType'].setValue('');    
            this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]); 
            // this.selectedLiveVirtualChannelList=[]; 
            this.createNewAssetForm.controls['liveEventId'].setValue([]); 
            this.createNewAssetForm.controls['epgUrl'].setValue('');
            this.createNewAssetForm.controls['hlsUrl'].setValue('');
            this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]); 
            this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]); 

        }else if(this.boolShowAudioContentType == true){

            this.createNewAssetForm.controls['liveType'].setValue('');    
            this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]); 
            // this.selectedLiveVirtualChannelList=[]; 
            this.createNewAssetForm.controls['liveEventId'].setValue([]); 
            this.createNewAssetForm.controls['epgUrl'].setValue('');
            this.createNewAssetForm.controls['hlsUrl'].setValue('');
            this.FileClosedCaptionURL = '';
            this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]); 
            this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]);

            this.mapBreakForm = this.formBuilder.group({
                mapBreak: this.formBuilder.array([this.createmapBreak()])
            });

            this.mapMulticamForm = this.formBuilder.group({
                mapMulticam: this.formBuilder.array([this.createMulticamForm()])
            });
      
            this.mapAssetMetaDataForm = this.formBuilder.group({
                mapAssetMetaData: this.formBuilder.array([this.createMetadata()])
            });

            this.mapVirtualChannelScheduleForm = this.formBuilder.group({
                mapVirtualChannelSchedule: this.formBuilder.array([])
            });

            this.createNewAssetForm.controls['mapAssetMetaData'].setValue([]); 
            this.createNewAssetForm.controls['mapBreak'].setValue([]); 
            this.createNewAssetForm.controls['mapMulticam'].setValue([]); 
            this.createNewAssetForm.controls['vodAssetDesc'].setValue(''); 
            this.createNewAssetForm.controls['vodPreRollAds'].setValue(''); 
            this.createNewAssetForm.controls['mapAssetLibrary'].setValue([]);  
            this.createNewAssetForm.controls['vodIsDrm'].setValue('');  

        }
        
    }
    
    onLiveType(){

        console.log("this.createNewAssetForm.value.liveType>>>",this.createNewAssetForm.value.liveType);

        if(this.createNewAssetForm.value.liveType === 'Live Event' ){

            this.boolShowLiveEvent = true;
            this.boolShowVirtualChannel = false;
            this.boolShowLiveChannel = false;
            this.boolShowThirdParty = false;
        
        }
        
        if(this.createNewAssetForm.value.liveType === 'Virtual Channel' ){

            this.boolShowLiveEvent = false;
            this.boolShowVirtualChannel = true;
            this.boolShowLiveChannel = false;
            this.boolShowThirdParty = false;
         
        }
        
        if(this.createNewAssetForm.value.liveType === 'Live Channel' ) {

            this.boolShowLiveEvent = false;
            this.boolShowVirtualChannel = false;
            this.boolShowLiveChannel = true;
            this.boolShowThirdParty = false;
         
        }
        if(this.createNewAssetForm.value.liveType === 'Third Party' ) {

            this.boolShowLiveEvent = false;
            this.boolShowVirtualChannel = false;
            this.boolShowLiveChannel = false;
            this.boolShowThirdParty = true;
        }
    }
    
    getAssetInfo(id){

    this.loader=true;
    this.langId = localStorage.getItem('languageId');
    console.log("id>>>",id);
    // this.loader=false;

      this.assetService.getAsset(id, this.langId)
      .subscribe((result:any)=>{

        this.loader=false;

        if(result.data.length == 0){
            return;
        }
        
        this.showCreateNewButton('hide');

            //(start) set dropdown by default empty
            if(result.data[0].sameForAllZone == null){
                result.data[0].sameForAllZone = "";
            }
            if(result.data[0].sameForAllProductType == null){
                result.data[0].sameForAllProductType = "";
            }
            //(end) set dropdown by default empty
            if(result.data[0].airStartDate!= null){

                let startTime = result.data[0].airStartDate.split('T').pop();
                let date = result.data[0].airStartDate.replace(startTime, '');
                 date = date.replace('T', '');
             
                startTime = startTime.slice(0, -5);
                console.log("date",date);

                if(startTime =='0000-00-00 00' || startTime ==''){

                    startTime = '';
                    
                }


                if(date ==''){

                    date = '';
                    
                }

                this.createNewAssetForm.controls['airStartDate'].setValue(date);
                this.createNewAssetForm.controls['description'].setValue(result.data[0].description);

                this.createNewAssetForm.controls['airStartTime'].setValue(startTime);
                this.createNewAssetForm.controls['airStartTimezone'].setValue(result.data[0].airStartTimezone);
                this.createNewAssetForm.controls['duration'].setValue(result.data[0].duration);
            }
        
            if(result.data[0].airEndDate!= null){

                let endTime = result.data[0].airEndDate.split('T').pop();
                let date = result.data[0].airEndDate.replace(endTime, '');
                 date = date.replace('T', '');
             
                endTime = endTime.slice(0, -5);

                if(endTime =='0000-00-00 00'|| endTime =='' ){

                    endTime = null;

                }

                if(date ==''){

                    date = null;
                    
                }
                console.log("date",date);
                console.log("endTime",endTime);
               
                this.createNewAssetForm.controls['airEndDate'].setValue(date);
                // this.createNewAssetForm.controls['airEndDate'].setValue(moment(result.data[0].airEndDate).format("YYYY-MM-DD"));
                this.createNewAssetForm.controls['airEndTime'].setValue(endTime);
                this.createNewAssetForm.controls['airEndTimezone'].setValue(result.data[0].airEndTimezone);
            }
          
            this.createNewAssetForm.controls['title'].setValue(result.data[0].title);
            this.createNewAssetForm.controls['seoTitle'].setValue(result.data[0].seoTitle);
            this.createNewAssetForm.controls['seoDescription'].setValue(result.data[0].seoDescription);
            this.createNewAssetForm.controls['seoKeyword'].setValue(result.data[0].seoKeyword);
            this.createNewAssetForm.controls['displayIndexing'].setValue(result.data[0].displayIndexing);
            this.createNewAssetForm.controls['lyrics'].setValue(result.data[0].lyrics);
            this.createNewAssetForm.controls['sameForAllZone'].setValue(result.data[0].sameForAllZone);
           
            this.createNewAssetForm.controls['sameForAllProductType'].setValue(result.data[0].sameForAllProductType);
           
           console.log("<<<12descriiption12>>>>",result.data[0].description);

            this.createNewAssetForm.controls['description'].setValue(result.data[0].description);
            this.createNewAssetForm.controls['uplynkId'].setValue(result.data[0].uplynkId);

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createNewAssetForm.controls['contentState'].setValue(['In-Progress']);
            }

            if(result.data[0].contentState == null || result.data[0].contentState == '' || result.data[0].contentState == undefined){
                this.createNewAssetForm.controls['contentState'].setValue(['In-Progress']);
            }
            else{
                this.createNewAssetForm.controls['contentState'].setValue(result.data[0].contentState);
            }

            this.selectedItems=[];
            

            if(result.data[0].results.productType.length>0){

                this.selectedProductlist = [];

               let asyn = result.data[0].results.productType.length;
                for(let i = 0 ;i < result.data[0].results.productType.length;i++){
                    
                    asyn -- ;

                    let obj={
                    'id':result.data[0].results.productType[i].productTypeId,
                    'itemName':result.data[0].results.productType[i].productTypeName,
                    }
                    this.selectedProductlist.push(obj);  
                    this.mapProductTypeArrayTemp = this.selectedProductlist;
                    this.primaryNavigationMappingFilter.productTypeId.push(obj.id);
                }

                if(asyn == 0){
                    this.selectedProductlist = this.removeDuplicates(this.selectedProductlist,'id');
                    this.createNewAssetForm.controls['mapProductType'].setValue(this.selectedProductlist);
                    this.getNavigationInfo(this.primaryNavigationMappingFilter);
                }
             
              
            }

            this.selectedgeoZone = [];

            if( result.data[0].results.geoZone.length>0){

                    for(let i = 0 ;i < result.data[0].results.geoZone.length;i++){

                        let obj={
                        'id':result.data[0].results.geoZone[i].geoZoneId,
                        'itemName':result.data[0].results.geoZone[i].geoZoneName,
                        }
                        
                        this.selectedgeoZone.push(obj);
                        this.productTypeMappingFilter.geoZoneId.push(obj.id);
                    }
                
                
                this.createNewAssetForm.controls['mapGeoZone'].setValue(this.selectedgeoZone);
                this.getProductTypeInfo(this.productTypeMappingFilter);

            }

            if( result.data[0].results.cast.length>0){

                this.selectedmapCast = [];

                    for(let i = 0 ;i < result.data[0].results.cast.length;i++){
                        let obj={
                        'id':result.data[0].results.cast[i].castId,
                        'itemName':result.data[0].results.cast[i].castFirstName,
                        }
                        this.selectedmapCast.push(obj); 
                         
                    }
 
                this.createNewAssetForm.controls['mapCast'].setValue(this.selectedmapCast);
            }


            this.selectedAssetCategory = [];
            this.mapAssetGroupArrayTemp = [];

            if(result.data[0].results.assetCategory.length>0){

                this.selectedAssetCategory = [];
                
                for(let i = 0 ;i < result.data[0].results.assetCategory.length;i++){

                    let obj={
                        'id':result.data[0].results.assetCategory[i].assetCategoryId,
                        'itemName':result.data[0].results.assetCategory[i].assetCategoryName,
                    }
                    this.selectedAssetCategory.push(obj);  
                    this.mapAssetGroupArrayTemp = this.selectedAssetCategory;

                }
                console.log("selectedAssetCategory>>>",this.selectedAssetCategory);
                    
                this.createNewAssetForm.controls['mapAssetCategory'].setValue(this.selectedAssetCategory);
            }

            
            this.selectedAssetGroupList = [];
            this.mapAssetGroupArrayTemp = [];

            if(result.data[0].results.assetGroup.length>0){

                let asyc = result.data[0].results.assetGroup.length

                for(let i = 0 ;i < result.data[0].results.assetGroup.length;i++){

                    asyc --;

                    let obj={
                        'id':result.data[0].results.assetGroup[i].assetGroupId,
                        'itemName':result.data[0].results.assetGroup[i].assetGroupName,
                    }

                    this.selectedAssetGroupList.push(obj); 
                    this.mapAssetGroupArrayTemp=this.selectedAssetGroupList;
                    this.assetCategoryMappingFilter.assetGroupId.push(obj.id); 
                    this.createNewAssetForm.controls['mapAssetGroup'].setValue(this.selectedAssetGroupList);

                    
                }

                if(asyc == 0){
                    console.log("selectedAssetGroupList>>",this.selectedAssetGroupList);
                    // this.createNewAssetForm.controls['mapAssetGroup'].setValue(this.selectedAssetGroupList);
                    //  this.getGlobalAssetCategory(this.assetCategoryMappingFilter);
                }
  
            }


            if(result.data[0].results.certification.length>0){

                this.selectedcertificationList = [];

                for(let i = 0 ;i < result.data[0].results.certification.length;i++){

                    let obj={
                    'id':result.data[0].results.certification[i].certificationId,
                    'itemName':result.data[0].results.certification[i].certificationName,
                    }

                    this.selectedcertificationList.push(obj);  

                }

             this.createNewAssetForm.controls['mapCertification'].setValue(this.selectedcertificationList);
            }

            
            if( result.data[0].results.character.length>0){

                this.selectedmapCast=[];

                for(let i = 0 ;i < result.data[0].results.character.length;i++){
                    let obj={
                    'id':result.data[0].results.character[i].characterId,
                    'itemName':result.data[0].results.character[i].characterFirstName,
                    }
                    this.selectedmapCast.push(obj);  
                    this.createNewAssetForm.controls['mapCharacter'].setValue(this.selectedmapCast);
                }

           
           }

            if(result.data[0].results.primaryNavigation.length>0){

                this.selectedprimaryNavigationList = [];
                this.menuCategoryMappingFilter.primaryNavigationId = [];

                for(let i = 0 ;i < result.data[0].results.primaryNavigation.length;i++){
                    let obj={
                    'id':result.data[0].results.primaryNavigation[i].primaryNavigationId,
                    'itemName':result.data[0].results.primaryNavigation[i].primaryNavigationName,
                    }
                    this.selectedprimaryNavigationList.push(obj); 
                    this.mapPrimaryNavigationArrayTemp  = this.selectedmenuCategoryList;
                    this.menuCategoryMappingFilter.primaryNavigationId.push(obj.id);
                    this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue(this.selectedprimaryNavigationList);
                }
              
                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
            }
            
        //     this.img.push({
        //         horizontalURL:'',
        //         verticalURL:''
        //  })

        this.img=[];
            if(result.data[0].results.productDeviceTypeConfigure.length>0){

                this.multipleDeviceForm = this.formBuilder.group({
                    devices: this.formBuilder.array([])
                });

                   //device type validation code start
                    if(result.data[0].results.productDeviceTypeConfigure.length == 4){
                        this.deviceTypeFull = true;
                    }
                  //device type validation code end

                this.devices = this.multipleDeviceForm.get('devices') as FormArray;
             
                for(let i=0;i< result.data[0].results.productDeviceTypeConfigure.length;i++){

                    this.img.push({
                        horizontalURL:result.data[0].results.productDeviceTypeConfigure[i].horizontalFilePath,
                        verticalURL:result.data[0].results.productDeviceTypeConfigure[i].verticalFilePath  
                    })

                    console.log("this.img>>>",this.img);


                    let objH = {
                        'index':"",
                        'deviceType':"",
                        'selectedFiles':"",
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": "",
                        'horizontalFile':false
                    }

                    let objV = {
                        'index':"",
                        'deviceType':"",
                        'selectedFiles':"",
                        "verticalFileName":"",
                        "verticalFilePath": "",
                        "verticalFileType":"",
                        'verticalFile':false,
                        
                    }

                    this.uploadImgFilePathHorizontal.push(objH);
                    this.uploadImgFilePathVertical.push(objV);
                    setTimeout(()=>{

                        this.devices.push(this.updateItem(result.data[0].results.productDeviceTypeConfigure[i]));
                        this.loader=false;

                        
                        this.boolShowLiveContentType = true;
                        this.boolShowVodContentType = false;
                        this.boolShowAudioContentType = false;

                        
                    if(this.createNewAssetForm.value.contentType === 'Live' ){
                        //this.live = true;
                        this.boolShowLiveContentType = true;
                        this.boolShowVodContentType = false;
                        this.boolShowAudioContentType = false;
                    }
                    
                    if(this.createNewAssetForm.value.contentType === 'VOD' ) {
                        //this.live = false;
                        this.boolShowVodContentType = true;
                        this.boolShowLiveContentType = false;
                        this.boolShowAudioContentType = false;
                    
                    }

                    if(this.createNewAssetForm.value.contentType === 'Audio' ) {
                        //this.live = false;
                        this.boolShowVodContentType = false;
                        this.boolShowAudioContentType = true;
                        this.boolShowLiveContentType = false;
                    
                    }

                    if(this.createNewAssetForm.value.liveType === 'Live Event' ){

                        this.boolShowLiveEvent = true;
                        this.boolShowVirtualChannel = false;
                        this.boolShowLiveChannel = false;
                        this.boolShowThirdParty = false;
                    
                    }
                    
                    if(this.createNewAssetForm.value.liveType === 'Virtual Channel' ){
            
                        this.boolShowLiveEvent = false;
                        this.boolShowVirtualChannel = true;
                        this.boolShowLiveChannel = false;
                        this.boolShowThirdParty = false;
                     
                    }
                    
                    if(this.createNewAssetForm.value.liveType === 'Live Channel' ) {
            
                        this.boolShowLiveEvent = false;
                        this.boolShowVirtualChannel = false;
                        this.boolShowLiveChannel = true;
                        this.boolShowThirdParty = false;
                     
                    }
                    if(this.createNewAssetForm.value.liveType === 'Third Party' ) {
            
                        this.boolShowLiveEvent = false;
                        this.boolShowVirtualChannel = false;
                        this.boolShowLiveChannel = false;
                        this.boolShowThirdParty = true;
                    }

                    },2000)
                   

                    this.createNewAssetForm.controls['sameForAllProductType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].sameForAllProductType);
                    this.createNewAssetForm.controls['contentType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].contentType);
                  
                    this.createNewAssetForm.controls['ingestionType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].ingestionType);
                    this.createNewAssetForm.controls['assetType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].assetType);
                    this.createNewAssetForm.controls['vodOrLivePosterImage'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodOrLivePosterImage);
                    this.createNewAssetForm.controls['vodClosedCaption'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodClosedCaption);
                    this.createNewAssetForm.controls['vodIsDrm'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodIsDrm);
                    this.createNewAssetForm.controls['vodAssetDesc'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodAssetDesc);
                    // this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodOrLiveAdServer);
                    this.createNewAssetForm.controls['vodPreRollAds'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodPreRollAds);
                    this.createNewAssetForm.controls['vodPostRollAds'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodPostRollAds);

                    // this.createNewAssetForm.controls['vodClientSideAdServer'].setValue(parseInt(result.data[0].vodClientSideAdServer));
                    this.createNewAssetForm.controls['vodClientSidePreRollAds'].setValue(result.data[0].vodClientSidePreRollAds);
                    this.createNewAssetForm.controls['vodClientSideMidRollAds'].setValue(result.data[0].vodClientSideMidRollAds);
                    this.createNewAssetForm.controls['vodClientSideMidRollDuration'].setValue(result.data[0].vodClientSideMidRollDuration);
                    this.createNewAssetForm.controls['vodClientSidePostRollAds'].setValue(result.data[0].vodClientSidePostRollAds);
        

                    this.createNewAssetForm.controls['vodOrLivePosterImageFileName'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodOrLivePosterImageFileName);
                    this.createNewAssetForm.controls['vodOrLivePosterImageFilePath'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodOrLivePosterImageFilePath);
                    this.createNewAssetForm.controls['vodOrLivePosterImageFileType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodOrLivePosterImageFileType);
                    this.createNewAssetForm.controls['vodClosedCaptionFileName'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodClosedCaptionFileName);
                    this.createNewAssetForm.controls['vodClosedCaptionFilePath'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodClosedCaptionFilePath);
                    this.createNewAssetForm.controls['vodClosedCaptionFileType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].vodClosedCaptionFileType);
                    this.createNewAssetForm.controls['liveType'].setValue(result.data[0].results.productDeviceTypeConfigure[i].liveType);
                  
                    if(result.data[0].permaLink != undefined && result.data[0].permaLink != null)
                    {

                        this.createNewAssetForm.controls['permaLink'].setValue(result.data[0].permaLink);

                    }else {

                        this.createNewAssetForm.controls['permaLink'].setValue('');

                    } 

                    // this.onContentType();
                    // this.onLiveType();

                    this.createNewAssetForm.controls['copyrightText'].setValue(result.data[0].results.productDeviceTypeConfigure[i].copyrightText);
                    this.createNewAssetForm.controls['expandParameter'].setValue(result.data[0].results.productDeviceTypeConfigure[i].expandParameter);
                    this.createNewAssetForm.controls['isMulticamApplicable'].setValue(result.data[0].results.productDeviceTypeConfigure[i].isMulticamApplicable);            
                    this.createNewAssetForm.controls['hlsUrl'].setValue(result.data[0].results.productDeviceTypeConfigure[i].hlsUrl);
                    this.createNewAssetForm.controls['epgUrl'].setValue(result.data[0].results.productDeviceTypeConfigure[i].epgUrl);
                    this.createNewAssetForm.controls['seoTitle'].setValue(result.data[0].seoTitle);

                    this.createNewAssetForm.controls['publisherState'].setValue(result.data[0].publisherState);
                    this.createNewAssetForm.controls['reviewerState'].setValue(result.data[0].reviewerState);
        
                    this.FilePosterImageURL =result.data[0].results.productDeviceTypeConfigure[i].vodOrLivePosterImageFilePath;
                    this.FileClosedCaptionURL=result.data[0].results.productDeviceTypeConfigure[i].vodClosedCaptionFilePath;
                    // liveVirtualChannelList

                }
 
            }

            if(result.data[0].results.productDeviceTypeConfigure.length>0){
                 this.selectedLiveVirtualChannelList = [];
                setTimeout(()=>{

                    let liveVirtualChannelId =result.data[0].results.productDeviceTypeConfigure[0].liveVirtualChannelId;

                    console.log("liveVirtualChannelId",liveVirtualChannelId);
         
                    if(this.liveVirtualChannelList.length>0){
        
                        for(let i=0;i< this.liveVirtualChannelList.length;i++){

                            console.log(">>",this.liveVirtualChannelList[0].id);
        
                            if(liveVirtualChannelId == this.liveVirtualChannelList[i].id){
        
                                this.selectedLiveVirtualChannelList.push(this.liveVirtualChannelList[i])
                                this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([this.liveVirtualChannelList[i]]);
                            }
                        }
                    }

                },2000)

            
                let   adServerId =result.data[0].results.productDeviceTypeConfigure[0].vodOrLiveAdServer;

                console.log("adServerId>>",adServerId);

                setTimeout(()=>{

                    if(this.uplynkAdServerList.length>0){

                        this.uplynkAdServerList.forEach(element => {
                            
                               if(element.id == adServerId){
    
                                this.selectedUplynkAdServerList.push(element);  
                                this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([element]);
    
                                console.log("this.vodOrLiveAdServer",this.createNewAssetForm.value);
    
                               }
                        });
    
                    }


                    if(this.clientAdServerList.length>0){

                        this.clientAdServerList.forEach(element => {
                            console.log(">>>>>>result.data[0].vodOrLiveAdServer",result.data[0].vodClientSideAdServer);

                               if(element.id == parseInt(result.data[0].vodClientSideAdServer)){
    
                                this.selectedClientAdServerList.push(element);  
                                this.createNewAssetForm.controls['vodClientSideAdServer'].setValue([element]);
    
                                console.log("this.vodClientSideAdServer",this.createNewAssetForm.value);
    
                               }
                        });
    
                    }
                    
                    
                    let liveEventId = result.data[0].results.productDeviceTypeConfigure[0].liveEventId;
                    
                    this.selectedLiveEventList = [];
                    
                    if(this.liveEventList.length > 0 ) {
                    
                        for(let i = 0 ;i < this.liveEventList.length;i++){

                            if(this.liveEventList[i].id == liveEventId){

                                this.selectedLiveEventList.push(this.liveEventList[i]);  
                                this.createNewAssetForm.controls['liveEventId'].setValue(this.selectedLiveEventList);
                            }
                        } 
                    }
                    
                },2000)
              
            }

            if(result.data[0].results.break.length>0){

                this.mapBreakForm = this.formBuilder.group({
                    mapBreak: this.formBuilder.array([])
                });

                this.mapBreak = this.mapBreakForm.get('mapBreak') as FormArray;
                
                for(let i=0;i< result.data[0].results.break.length;i++){

                    this.mapBreak.push(this.updatemapBreak(result.data[0].results.break[i]));
                    
                    // this.createNewAssetForm.controls['title'].setValue(result.data[0].results.break[i].title);
                  
                }
                
            }


            if(result.data[0].results.multicam.length>0){

                this.mapMulticamForm = this.formBuilder.group({
                    mapMulticam: this.formBuilder.array([])
                });

                this.mapMulticam = this.mapMulticamForm.get('mapMulticam') as FormArray;
                
                for(let i=0;i< result.data[0].results.multicam.length;i++){

                    this.mapMulticam.push(this.updatemapMulticam(result.data[0].results.multicam[i]));
                    
                    // this.createNewAssetForm.controls['title'].setValue(result.data[0].results.break[i].title);
                  
                }
                
            }

            if(result.data[0].results.assetMetaData.length>0){
                
                this.mapAssetMetaDataForm = this.formBuilder.group({
                    mapAssetMetaData: this.formBuilder.array([])
                });

                this.mapAssetMetaData = this.mapAssetMetaDataForm.get('mapAssetMetaData') as FormArray;
                
                for(let i=0;i< result.data[0].results.assetMetaData.length;i++){

                    this.mapAssetMetaData.push(this.updateMetadata(result.data[0].results.assetMetaData[i]));

                }
                
            }

        //    this.mapVirtualArray = [];
           
    
            if(result.data[0].results.virtualChannelSchedule.length>0){

                this.mapVirtualChannelScheduleForm = this.formBuilder.group({
                    mapVirtualChannelSchedule: this.formBuilder.array([])
                });

                this.mapVirtualChannelSchedule = this.mapVirtualChannelScheduleForm.get('mapVirtualChannelSchedule') as FormArray;
                
                for(let i=0;i< result.data[0].results.virtualChannelSchedule.length;i++){

                    this.mapVirtualChannelSchedule.push(this.updateMapVirtualChannel(result.data[0].results.virtualChannelSchedule[i],i));
                
                  
                }

                console.log("mapVirtualChannelSchedule>>>",this.mapVirtualChannelSchedule)
                
            }

            this.selectedAssetLibraryList = []; 
            if(result.data[0].results.assetLibrary.length>0){
                
                for(let i = 0 ;i < result.data[0].results.assetLibrary.length;i++){
                    let obj={
                        'id':result.data[0].results.assetLibrary[i].assetLibraryId,
                        'itemName':result.data[0].results.assetLibrary[i].libraryName,
                    }
                    this.selectedAssetLibraryList.push(obj);  
                }
                    
                 this.createNewAssetForm.controls['mapAssetLibrary'].setValue(this.selectedAssetLibraryList);
            }


            
            
            let arrayObj=[]
            if(result.data[0].results.genre.length>0){

                for(let i = 0 ;i < result.data[0].results.genre.length;i++){

                    let obj={
                    'id':result.data[0].results.genre[i].genreId,
                    'itemName':result.data[0].results.genre[i].genreName,
                    }
                    arrayObj.push(obj);  
                    arrayObj = this.removeDuplicates(arrayObj, "id");


                }
                
                this.createNewAssetForm.controls['mapGenre'].setValue(arrayObj);
            }

            arrayObj=[]
            if(result.data[0].results.review.length>0){

                for(let i = 0 ;i < result.data[0].results.review.length;i++){

                    let obj={
                    'id':result.data[0].results.review[i].reviewId,
                    'itemName':result.data[0].results.review[i].reviewFirstName,
                    }
                    arrayObj.push(obj);  

                }
                
                this.createNewAssetForm.controls['mapReview'].setValue(arrayObj);
            }

            arrayObj=[]
            if(result.data[0].results.library.length>0){

                for(let i = 0 ;i < result.data[0].results.library.length;i++){

                    let obj={
                    'id':result.data[0].results.library[i].libraryId,
                    'itemName':result.data[0].results.library[i].libraryName,
                    }
                    arrayObj.push(obj);  

                }
                
                this.createNewAssetForm.controls['mapLibrary'].setValue(arrayObj);
            }

            arrayObj=[]
            if(result.data[0].results.sponsor.length>0){

                for(let i = 0 ;i < result.data[0].results.sponsor.length;i++){

                    let obj={
                    'id':result.data[0].results.sponsor[i].sponsorId,
                    'itemName':result.data[0].results.sponsor[i].sponsorName,
                    }
                    arrayObj.push(obj);  

                }
                
                this.createNewAssetForm.controls['mapSponsor'].setValue(arrayObj);
            }

            arrayObj=[]
            if(result.data[0].results.classification.length>0){

                for(let i = 0 ;i < result.data[0].results.classification.length;i++){

                    let obj={
                    'id':result.data[0].results.classification[i].classificationId,
                    'itemName':result.data[0].results.classification[i].classificationName,
                    }
                    arrayObj.push(obj);  
                }
                
                this.createNewAssetForm.controls['mapClassification'].setValue(arrayObj);
            }

            arrayObj=[]
            if(result.data[0].results.language.length>0){

                for(let i = 0 ;i < result.data[0].results.language.length;i++){

                    let obj={
                    'id':result.data[0].results.language[i].languageId,
                    'itemName':result.data[0].results.language[i].languageName,
                    }
                    arrayObj.push(obj);  

                }
                
                this.createNewAssetForm.controls['mapLanguage'].setValue(arrayObj);
            }

            this.createNewAssetForm.controls['exclusionText'].setValue(result.data[0].exclusionText);

            this.selectedmenuCategoryList = [];
            this.mapAssetCategoryArrayTemp = [];

            if(result.data[0].results.menuCategory.length>0){

                this.selectedmenuCategoryList = [];
                
                for(let i = 0 ;i < result.data[0].results.menuCategory.length;i++){

                    let obj={
                        'id':result.data[0].results.menuCategory[i].menuCategoryId,
                        'itemName':result.data[0].results.menuCategory[i].menuCategoryName,
                    }

                    this.selectedmenuCategoryList.push(obj); 
                    
                    this.mapAssetCategoryArrayTemp = this.selectedmenuCategoryList;

                    this.assetGroupMappingFilter.menuCategoryId.push(this.selectedmenuCategoryList[0].id);

                    this.createNewAssetForm.controls['mapMenuCategory'].setValue(this.selectedmenuCategoryList);

                    console.log("createNewAssetForm>>>",this.createNewAssetForm);
                }
                    
              
                this.getGlobalAssetGroup(this.assetGroupMappingFilter);
            }


            
      });
    }

    getGlobalReviewInfo(){

        this.commonService.getGlobalReview(this.reviewFilter,this.startReview,10).subscribe((result:any)=>{
            // this.mapReviewList = [];

            if(result.data.data.length > 0){       
               
                if(this.isSearch){

                    this.isSearch = false;
                    this.mapReviewList = [];
                }

                for (let ProducTypeLists of result.data.data) {

                    if(ProducTypeLists.reviewFirstName!=''){
                    this.mapReviewList.push(
                        {
                            'id': ProducTypeLists.reviewId,
                            'itemName': ProducTypeLists.reviewFirstName
                        });
                    }
                    
                    this.mapReviewList = this.removeDuplicates(this.mapReviewList,'id');
                }   
            } 
        });
    }

    fetchMoreGetReviews(event){

        // console.log("event=list-count",event.endIndex , this.mapReviewList.length ,this.reviewScrollCount)

        if ( ((event.endIndex == this.mapReviewList.length - 1)) && (this.mapReviewList.length >= 7)) {

            // if(this.reviewScrollCount <= this.reviewInfoObj.pageCount)   {

                this.startReview = this.startReview + 10;

                this.getGlobalReviewInfo();
            // }       
   
        }

    }

    characterFilter:any={};
    startCharacter=0;

    getGlobalCharacter(){
        
        this.commonService.getGlobalCharacter(this.characterFilter,this.startCharacter,10).subscribe((result:any)=>{

            // this.mapCharacterList = [];

            if(this.isSearch == true){

                this.isSearch = false;
                this.mapCharacterList = [];

            }
            
            if(result.data.data.length > 0){       
               
                for (let CharacterLists of result.data.data) {

                    if(CharacterLists.characterId!=''){
                    this.mapCharacterList.push(
                        {
                            'id': CharacterLists.characterId,
                            'itemName': CharacterLists.characterFirstName
                        });
                    }    
                }   
            } 
        });
    }

    fetchMoreGetCharacter(event){

        // console.log("event=list-count",event.endIndex , this.mapReviewList.length ,this.reviewScrollCount)

        if ( ((event.endIndex == this.mapCharacterList.length - 1)) && (this.mapCharacterList.length >= 7)) {

            // if(this.reviewScrollCount <= this.reviewInfoObj.pageCount)   {

                this.startCharacter = this.startCharacter + 10;

                this.getGlobalCharacter();
            // }       
   
        }

    }

   //Pravin code mulitple from

    mapVirtualArray:any=[];
    createMapVirtualChannel(): FormGroup {

        this.mapVirtualArray.push([])

        let obj={

            'id':'1',
            'itemName':'Live From Comedy Cellar',

            }


        return this.formBuilder.group({  

            startTime:'',
            assetType:['',Validators.required],
            sTime:'',
            assetMapping:[[]],
            selectedItems:[[]]

        });
    }

    updateMapVirtualChannel(metadataObj,i): FormGroup {

            this.mapVirtualArray[i]=[];

            metadataObj.assetMapping.forEach((assetMapping=>{

                            let obj={
                                
                                'id':assetMapping.assetMappingId,
                                'itemName':assetMapping.assetName
                            }
            
                            this.mapVirtualArray[i].push(obj);
            }))

        
        console.log("mapVirtualArray",this.mapVirtualArray);

        let obj = {

            'id':'',
            'itemName':'',

            }

        let startT = metadataObj.startTime.split('T').pop();
        let sTime = startT.slice(0, -5);
    
        let date = metadataObj.startTime.replace(startT, '');
         date = date.replace('T', '');
    
      
       // setTimeout(()=>{
            
        //     this.trailerVideoList.forEach((element =>{

        //         if(element.id == metadataObj.assetMapping[0].assetMappingId){
    
        //             obj.id = element .id;
        //             obj.itemName = element .itemName;
    
        //         }
    
        //     }))
        // },1000)

        return this.formBuilder.group({

            startTime:date,
            sTime:sTime,
            assetType:[metadataObj.assetType,Validators.required],
            assetMapping:[[]],
            selectedItems:[[]],

        });
        
    }

    addMapVirtualChannel(): void {

        this.mapVirtualChannelSchedule = this.mapVirtualChannelScheduleForm.get('mapVirtualChannelSchedule') as FormArray;

        this.mapVirtualChannelSchedule.push(this.createMapVirtualChannel());

    }

    delMapVirtualChannel(index){

        const arrayControl = this.mapVirtualChannelScheduleForm.get('mapVirtualChannelSchedule') as FormArray;
        arrayControl.removeAt(index);

    } 

    createMetadata(): FormGroup {

        return this.formBuilder.group({        
            metaKey:'',
            metaValue:'',

        });
    }
   
    updateMetadata(metadataObj): FormGroup {

        return this.formBuilder.group({

            metaKey:metadataObj.metaKey,
            metaValue:metadataObj.metaValue,

        });
    }

    addMetadata(): void {

        this.mapAssetMetaData = this.mapAssetMetaDataForm.get('mapAssetMetaData') as FormArray;
            this.mapAssetMetaData.push(this.createMetadata());

    }

    delMetadata(index){

        const arrayControl = this.mapAssetMetaDataForm.get('mapAssetMetaData') as FormArray;
        arrayControl.removeAt(index);

    } 

    //mapAssetMetaData multiple form code end

    createmapBreak(): FormGroup {

        return this.formBuilder.group({        
            title:'',

        });
    }


    createMulticamForm(): FormGroup {

        return this.formBuilder.group({        
            title:'',
            uplynkGuid:''

        });
    }
    
    updatemapBreak(metadataObj): FormGroup {

        return this.formBuilder.group({

            title:metadataObj.title

        });
    }

    updatemapMulticam(metadataObj): FormGroup {

        return this.formBuilder.group({

            title:metadataObj.title,
            uplynkGuid:metadataObj.uplynkGuid

        });
    }
    
    addmapBreak(): void {

        this.mapBreak = this.mapBreakForm.get('mapBreak') as FormArray;

        this.mapBreak.push(this.createmapBreak());
        
    }

    addmapMulticam(): void {

        this.mapMulticam = this.mapMulticamForm.get('mapMulticam') as FormArray;

        this.mapMulticam.push(this.createMulticamForm());
        
    }
    
    delmapBreak(index){
        const arrayControl = this.mapBreakForm.get('mapBreak') as FormArray;
        arrayControl.removeAt(index);
    } 


    delmapMulticam(index){

        const arrayControl = this.mapMulticamForm.get('mapMulticam') as FormArray;
        arrayControl.removeAt(index);
    } 

    createItemTemp(): FormGroup {

        return this.formBuilder.group({        
            label: ['',Validators.required],
            description: '',
            uploadImage: '',
            offlineDownload :'',
            mapTrailerVideo:[],
            isVisible:'',
            uploadVerticalImage:'',
            uploadHorizontalImage:'',
            dtypeId:['1',Validators.required],
            verticalFileName: "",
            verticalFilePath: "",
            verticalFileType: "",  
            horizontalFileName: "",
            horizontalFilePath: "",
            horizontalFileType: ""
        });
    

    }

    //Pravin code mulitple from
    createItem(): FormGroup {

            return this.formBuilder.group({        
                label: ['',Validators.required],
                description: '',
                uploadImage: '',
                offlineDownload :'',
                mapTrailerVideo:[],
                isVisible:'',
                uploadVerticalImage:'',
                uploadHorizontalImage:'',
                dtypeId:['' ,Validators.required],
                verticalFileName: "",
                verticalFilePath: "",
                verticalFileType: "",  
                horizontalFileName: "",
                horizontalFilePath: "",
                horizontalFileType: ""
            });
        
    
    }
    
   //pravin code start
   
    updateItem(objProductDeviceTypeConfigure): FormGroup { 

    // console.log("objProductDeviceTypeConfigure.mapTrailerVideo",objProductDeviceTypeConfigure.mapTrailerVideo);
  
        //device type validation code start
        if(objProductDeviceTypeConfigure.dtypeId == '4'){
            this.all = true;
            console.log("all",this.all);
        }

      
        //device type validation code end
        let count = 0;
        // if(count == 0){
            
        //     objProductDeviceTypeConfigure.mapTrailerVideo = {} ;
        // }
        var obj


            // this.trailerVideoList.forEach((element:any)=>{
          
            //     if(element.id == objProductDeviceTypeConfigure.mapTrailerVideo){
                    
            //         count ++ ;
            //         console.log("mapTrailerVideo>>",objProductDeviceTypeConfigure.mapTrailerVideo);
            //         objProductDeviceTypeConfigure.mapTrailerVideo = element ;
               
            //     }


                if(objProductDeviceTypeConfigure.mapTrailerVideo!=''){

                     obj  = {
                        'id':objProductDeviceTypeConfigure.mapTrailerVideo,
                        'itemName':objProductDeviceTypeConfigure.mapTrailerVideoName
                    }

                }

                
            // })
            if( objProductDeviceTypeConfigure.mapTrailerVideo == '' || objProductDeviceTypeConfigure.mapTrailerVideo == null){
                return this.formBuilder.group({
                    label: [objProductDeviceTypeConfigure.label,Validators.required],
                    description: objProductDeviceTypeConfigure.description,
                    uploadImage:objProductDeviceTypeConfigure.uploadImage,
                    offlineDownload :objProductDeviceTypeConfigure.offlineDownload,
                    mapTrailerVideo:[[]],
                    isVisible:objProductDeviceTypeConfigure.isVisible,
                    uploadVerticalImage:objProductDeviceTypeConfigure.uploadVerticalImage,
                    uploadHorizontalImage:objProductDeviceTypeConfigure.uploadHorizontalImage,
                    dtypeId:[objProductDeviceTypeConfigure.dtypeId ,Validators.required],
                    verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
                    verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
                    verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
                    horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
                    horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
                    horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType
        
                });
            }else{
                return this.formBuilder.group({
                    label: [objProductDeviceTypeConfigure.label,Validators.required],
                    description: objProductDeviceTypeConfigure.description,
                    uploadImage:objProductDeviceTypeConfigure.uploadImage,
                    offlineDownload :objProductDeviceTypeConfigure.offlineDownload,
                    mapTrailerVideo:[[obj]],
                    isVisible:objProductDeviceTypeConfigure.isVisible,
                    uploadVerticalImage:objProductDeviceTypeConfigure.uploadVerticalImage,
                    uploadHorizontalImage:objProductDeviceTypeConfigure.uploadHorizontalImage,
                    dtypeId:[objProductDeviceTypeConfigure.dtypeId ,Validators.required],
                    verticalFileName: objProductDeviceTypeConfigure.verticalFileName,
                    verticalFilePath: objProductDeviceTypeConfigure.verticalFilePath,
                    verticalFileType: objProductDeviceTypeConfigure.verticalFileType, 
                    horizontalFileName: objProductDeviceTypeConfigure.horizontalFileName,
                    horizontalFilePath:objProductDeviceTypeConfigure.horizontalFilePath,
                    horizontalFileType:objProductDeviceTypeConfigure.horizontalFileType
        
                });
            }
         
    }

    onDtypeId(){
        this.All=false;
        // this.Web=false;
        // this.Android=false;
        // this.iOS=false;
    }

    
    addItem(): void {
        
        if(!this.multipleDeviceForm.valid){  

            this.createNewAssetSubmitted = true;
            return
  
          }
          
        if(this.multipleDeviceForm.value.devices.length >= 4){

            this.createNewAssetSubmitted = true;
            return;

        }

        this.img.push({
            horizontalURL:'',
            verticalURL:''
        })  

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices
        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

            this.devices.push(this.createItem());

            let objH = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "horizontalFileName": "",
                "horizontalFilePath": "",
                "horizontalFileType": "",
                'horizontalFile':false
            }

            let objV = {
                'index':"",
                'deviceType':"",
                'selectedFiles':"",
                "verticalFileName":"",
                "verticalFilePath": "",
                "verticalFileType":"",
                'verticalFile':false,
                
            }

            this.uploadImgFilePathHorizontal.push(objH);
            this.uploadImgFilePathVertical.push(objV);
        }

    }

      //New Code for right side for from
    onContentReadyToReview(){

        console.log("<<<createNewAssetForm>>>",this.createNewAssetForm);

        if(!this.createNewAssetForm.valid){

        this.loader = false;
        this.createNewAssetSubmitted = true;
        console.log("2");
        return;
        }

    if(this.multipleDeviceForm.value.devices.length == 0){

        this.loader = false;
        this.createNewAssetSubmitted = true;
        console.log("3");
        return;
    }

    if(!this.mapVirtualChannelScheduleForm.valid){

        this.loader = false;
        this.createNewAssetSubmitted = true;
        console.log("4");
        return;
    }

    //code for global loader start
    this.commonService.changeloader(false);
    
    this.contentReadyToReview = true;
    this.shareMassage = 'review';

    }


    isContentReadyToReviewReceived(event){
        
        this.contentReadyToReview= false;
       console.log("event>>",event);
        
    //New Code start
        if(event == 'No'){

            this.contentReadyToReview = false;
        
        }
        //New Code end

        if(event == 'Yes'){

            this.createNewAssetForm.controls['contentState'].setValue(['Completed']);
            // this.postCAllNavigation();
            this.savecreateNewAsset();
            this.onAssetInfoTabClick();
        
        }
        if(event == 'Cancel'){

            this.createNewAssetForm.controls['contentState'].setValue(['In-Progress']);
            // this.postCAllNavigation();
            this.savecreateNewAsset();
            this.onAssetInfoTabClick();

        }
   
    }

    onDeviceType(i){

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        this.uploadImgFilePathHorizontal[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathHorizontal[i].index = 1;

        this.uploadImgFilePathVertical[i].deviceType = devices.value[i].dtypeId;
        this.uploadImgFilePathVertical[i].index = 1;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0 ;
        this.all = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;
        this.roku = false;
    
        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }else {

        }

    }
    
    delInput(index){

        this.img.splice(index, 1);
        const arrayControl = this.multipleDeviceForm.get('devices') as FormArray;
        arrayControl.removeAt(index);
        this.uploadImgFilePathHorizontal.splice(index, 1);
        this.uploadImgFilePathVertical.splice(index, 1);

        this.devices = this.multipleDeviceForm.get('devices') as FormArray;
        let devices = this.devices;

        var all = 0;
        var Web = 0;
        var android = 0;
        var iOS = 0;
        var roku = 0;
        this.all = false;
        this.roku = false;
        this.Web = false;
        this.android = false;
        this.iOS = false;

        for(let i = 0;i< devices.value.length;i++){

            if(devices.value[i].dtypeId=='1'){
                Web++;
                this.Web=true;
            }
            
            if(devices.value[i].dtypeId=='2'){
                android++;
                this.android=true;
            }
            
            if(devices.value[i].dtypeId=='3'){
                iOS++;
                this.iOS=true;
            }

            if(devices.value[i].dtypeId=='5'){
                roku++;
                this.roku=true;
            }
            
            if(devices.value[i].dtypeId=='4'){
                all++;
                this.all=true;
            }else{
                
            }
            
        }
        
        this.deviceTypeDublicate = false;
        this.deviceTypeFull = false;
        
        if(Web == 1 && android == 1 && iOS == 1 && roku == 1){

        this.deviceTypeFull = true ;

        }else if(all == 1 || this.all == true ){
            
            if(all == 1 && devices.value.length>1){

            }

        } else if(Web > 1 || android > 1 || iOS > 1 || roku>1){

            this.deviceTypeDublicate = true;

        }

    }

    savecreateNewAsset(){

        this.langId = localStorage.getItem('langId');
        this.createNewAssetForm.controls['langId'].setValue(localStorage.getItem('languageId'))

        this.contentReadyToReview = false;
        const airStartTime = this.createNewAssetForm.get('airStartTime');
        const airEndTime = this.createNewAssetForm.get('airEndTime');
        const airStartDate = this.createNewAssetForm.get('airStartDate');
        const airEndDate = this.createNewAssetForm.get('airEndDate');
        
        const airStartTimezone = this.createNewAssetForm.get('airStartTimezone');
        const airEndTimezone = this.createNewAssetForm.get('airEndTimezone');
  
        if(this.createNewAssetForm.value.airStartDate == ''){
            this.createNewAssetForm.controls['airStartTime'].setValue(['']);
        }

        if(this.createNewAssetForm.value.airEndDate == ''){
            this.createNewAssetForm.controls['airEndTime'].setValue(['']);
        }

        console.log("airStartTime>>",airStartTime)
        console.log("airEndTime>>",airEndTime)
        console.log("airStartDate>>",airStartDate)
        console.log("airEndDate>>",airEndDate)
        console.log("uploadImgFilePath>>",this.createNewAssetForm)
        
       
        if(this.createNewAssetForm.value.airStartDate=='' && this.createNewAssetForm.value.airStartTime!=''){

            // airStartDate.setValidators([Validators.required]);
            // airStartDate.updateValueAndValidity();

        }else if(this.createNewAssetForm.value.airStartDate!='' && this.createNewAssetForm.value.airStartTime==''){

            // airStartTime.setValidators([Validators.required]);
            // airStartTime.updateValueAndValidity();
            console.log('update',this.createNewAssetForm);

        }else {
            airStartTime.setValidators(null);
            airStartDate.setValidators(null);
            airStartTime.updateValueAndValidity();
            airStartDate.updateValueAndValidity();
        }

        if(this.createNewAssetForm.value.airEndDate=='' && this.createNewAssetForm.value.airEndTime!=''){

            // airEndDate.setValidators([Validators.required]);
            // airEndDate.updateValueAndValidity();

        }else if(this.createNewAssetForm.value.airEndDate!='' && this.createNewAssetForm.value.airEndTime==''){

            // airEndTime.setValidators([Validators.required]);
            // airEndTime.updateValueAndValidity();
       

        }else{
            airEndTime.setValidators(null);
            airEndDate.setValidators(null);
            airEndTime.updateValueAndValidity();
            airEndDate.updateValueAndValidity();
        }
      
        this.loader= true;
        this.createNewAssetSubmitted = true;

        let tempUploadImgFilePath = this.uploadImgFilePathHorizontal.concat(this.uploadImgFilePathVertical); 
        this.uploadImgFilePath=[];

        //remove empty file upload img obj from array.
        tempUploadImgFilePath.forEach((element , i) => {

           if( element.selectedFiles !='') {

            this.uploadImgFilePath.push(element);
         
           }
            
        });

        let async = this.uploadImgFilePath.length;
        if(this.createNewAssetForm.value.liveType != 'Virtual Channel'){

            this.mapVirtualChannelScheduleForm = this.formBuilder.group({
                mapVirtualChannelSchedule: this.formBuilder.array([])
            });

        }

        

     
        if(this.multipleDeviceForm.value.devices.length >1 && this.all=== true){
            this.loader= false;
            return;

        }

        if(this.deviceTypeDublicate){
            this.loader= false;
            return;
        }

        if(!this.createNewAssetForm.valid){
            this.loader=false;
            return;
        }

        if(this.multipleDeviceForm.value.devices.length == 0){
            this.loader= false;
            return;
        }

        if(!this.mapVirtualChannelScheduleForm.valid){
            this.loader= false;
            return;
        }

        let varialbeArray=[];

        if(!this.update){

            if(this.roleAsses.assetCreate == false){

                this.toastrService.success('no access to create.');
                this.commonService.changeloader(false);
                this.loader = false;
                return;

            }
           
            if(this.uploadImgFilePath.length>0){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {
            
                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles,awsPath.asset).then(res => {

                        if (res && res.Location) {
                                async--;
                                 if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 

                                if (async == 0) {

                                    for(let i = 0;i < this.multipleDeviceForm.value.devices.length;i++){
                                           

                                                varialbeArray.push(
                                                {
                                                    "label": this.multipleDeviceForm.value.devices[i].label,
                                                    "description":this.multipleDeviceForm.value.devices[i].description,
                                                    "offlineDownload": this.multipleDeviceForm.value.devices[i].offlineDownload,
                                                    "mapTrailerVideo": this.multipleDeviceForm.value.devices[i].mapTrailerVideo,
                                                    "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                                                    "uploadVerticalImage": "",
                                                    "uploadHorizontalImage": "",
                                                    "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                                                    "verticalFileName": "",
                                                    "verticalFilePath": "",
                                                    "verticalFileType": "",  
                                                    "horizontalFileName": "",
                                                    "horizontalFilePath": "",
                                                    "horizontalFileType": ""
                                             
                                                });   

                                    }

                                    let asyncvariables = varialbeArray.length;
                                    for(let i=0;i< varialbeArray.length;i++){

                                            asyncvariables--;
                                            for(let j = 0 ;j<this.uploadImgFilePath.length; j++){
        
                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].horizontalFile == true){
        
                                                    varialbeArray[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                                    varialbeArray[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                                    varialbeArray[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;
        
                                                }

                                                if(this.uploadImgFilePath[j].deviceType == varialbeArray[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){
        
                                                    varialbeArray[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                                    varialbeArray[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                                    varialbeArray[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;
        
                                                }
        
                                            }
                                    }

                                    if(asyncvariables == 0){

                                        this.createNewAssetForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);
                                        console.log("createNewProductForm123",this.createNewAssetForm);
                                        this.multipleDevice=true;
                                        this.createNewAssetSubmitted=true;
                                        this.postCAllAsset()
                                    }

                                }      

                        }

                    });
                }
            }

            if(this.uploadImgFilePath.length==0){
                for(let i=0;i< this.multipleDeviceForm.value.devices.length;i++){ 

                    if(this.multipleDeviceForm.value.devices[i].mapTrailerVideo == this.removeTrailerIndex){
                       
                        this.multipleDeviceForm.value.devices[i].mapTrailerVideo=[];
                        this.removeTrailerIndex = null;   

                    }


                    varialbeArray.push(
                    {
                        "label": this.multipleDeviceForm.value.devices[i].label,
                        "description":this.multipleDeviceForm.value.devices[i].description,
                        "offlineDownload": this.multipleDeviceForm.value.devices[i].offlineDownload,
                        "mapTrailerVideo":  this.multipleDeviceForm.value.devices[i].mapTrailerVideo,
                        "isVisible":this.multipleDeviceForm.value.devices[i].isVisible,
                        "uploadVerticalImage": "",
                        "uploadHorizontalImage": "",
                        "dtypeId":this.multipleDeviceForm.value.devices[i].dtypeId,
                        "verticalFileName": "",
                        "verticalFilePath": "",
                        "verticalFileType": "",  
                        "horizontalFileName": "",
                        "horizontalFilePath": "",
                        "horizontalFileType": ""
                    });   

                }
                this.multipleDevice=true;
                this.createNewAssetSubmitted = true;
                this.createNewAssetForm.controls['productDeviceTypeConfigure'].setValue(varialbeArray);

                if(!this.multipleDeviceForm.valid){
                    this.loader=false;
                    return;
                }
              
        
                if(!this.createNewAssetForm.valid){
                    this.loader=false;
                    return;
                }

               
                console.log("createNewProductForm",this.createNewAssetForm);
                this.postCAllAsset();
            }

        }
    
        if(this.update){

            if(this.roleAsses.assetEdit == false){

                this.toastrService.success('no access to edit.');
                this.commonService.changeloader(false);
                this.loader = false;
                return;

            }

            let asyncvariables = this.multipleDeviceForm.value.devices.length;

            if(this.uploadImgFilePath.length == 0){
           
                this.multipleDeviceForm.value.devices.forEach((element,i) => {

                    if(i == this.removeTrailerIndex){

                        this.multipleDeviceForm.value.devices[i].mapTrailerVideo=[];  
                       
                    }   
      
                });
               
                this.createNewAssetForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices);
                this.createNewAssetSubmitted = true;

                if(!this.multipleDeviceForm.valid){
                    this.loader=false;
                    return;
                }

                if(!this.createNewAssetForm.valid){
                    this.loader=false;
                    return;
                }
                
                this.postCAllAsset();

            }

            if(this.uploadImgFilePath.length > 0 ){

                for (let i = 0; i < this.uploadImgFilePath.length; i++) {

                    this.productConfigurationService.uploadFile(this.uploadImgFilePath[i].selectedFiles, awsPath.asset).then(res => {

                        if (res && res.Location) {
                            async--;
                               if(this.uploadImgFilePath[i].horizontalFile == true)
                                this.uploadImgFilePath[i].horizontalFilePath=res.Location;  
                                
                                if(this.uploadImgFilePath[i].verticalFile == true)
                                this.uploadImgFilePath[i].verticalFilePath=res.Location; 
                        }

                        if (async == 0) {

                            for(let i=0;i<this.multipleDeviceForm.value.devices.length;i++){

                                asyncvariables--;
                                for(let j = 0 ;j<this.uploadImgFilePath.length; j++){

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].horizontalFile==true){

                                        this.multipleDeviceForm.value.devices[i].horizontalFileName=this.uploadImgFilePath[j].horizontalFileName;
                                        this.multipleDeviceForm.value.devices[i].horizontalFilePath=this.uploadImgFilePath[j].horizontalFilePath;
                                        this.multipleDeviceForm.value.devices[i].horizontalFileType=this.uploadImgFilePath[j].horizontalFileType;

                                    }

                                    if(this.uploadImgFilePath[j].deviceType == this.multipleDeviceForm.value.devices[i].dtypeId && this.uploadImgFilePath[j].verticalFile== true){ 

                                        this.multipleDeviceForm.value.devices[i].verticalFileName=this.uploadImgFilePath[j].verticalFileName;
                                        this.multipleDeviceForm.value.devices[i].verticalFilePath=this.uploadImgFilePath[j].verticalFilePath;
                                        this.multipleDeviceForm.value.devices[i].verticalFileType=this.uploadImgFilePath[j].verticalFileType;

                                    }


                                }
                            }

                            if(asyncvariables == 0){

                            
                                this.createNewAssetSubmitted = true;

                                if(!this.multipleDeviceForm.valid){
                                    this.loader=false;
                                    return;
                                }
                                if(!this.createNewAssetForm.valid){
                                    this.loader=false;
                                    return;
                                }
                                this.createNewAssetForm.controls['productDeviceTypeConfigure'].setValue(this.multipleDeviceForm.value.devices)
                                console.log("createNewProductForm",this.createNewAssetForm);
                                this.postCAllAsset();

                                console.log("this.multipleDeviceForm.value.devices>>",this.multipleDeviceForm.value.devices);
                                
                            }
                        }
                    })
                }
            }

        }

    }

    postCAllAsset(){

        this.createNewAssetForm.controls['langId'].setValue('languageId');

        let arrMapBreak = [];
        for(let i=0;i< this.mapBreakForm.value.mapBreak.length;i++){
            arrMapBreak.push(
                {
                    'title':this.mapBreakForm.value.mapBreak[i].title,    
                }
            );
        }
        
        if(arrMapBreak.length>0)
        {
            this.createNewAssetForm.controls['mapBreak'].setValue(arrMapBreak);
        }


        let arrMapMulticam = [];
        for(let i=0;i< this.mapMulticamForm.value.mapMulticam.length;i++){
            arrMapMulticam.push(
                {
                    'title':this.mapMulticamForm.value.mapMulticam[i].title, 
                    'uplynkGuid':this.mapMulticamForm.value.mapMulticam[i].uplynkGuid    
                }
            );
        }

        console.log(">>>>arrMapMulticam",arrMapMulticam);
        
        if(arrMapMulticam.length>0)
        {
            this.createNewAssetForm.controls['mapMulticam'].setValue(arrMapMulticam);
        }

        
        let arrMapAssetMeta = [];
 

        if(this.createNewAssetForm.value.contentType == 'VOD'){

            console.log("this.mapAssetMetaData>>>",this.mapAssetMetaDataForm.value.mapAssetMetaData);

            if(this.mapAssetMetaDataForm.value.mapAssetMetaData.length>0){

                for(let i=0;i< this.mapAssetMetaDataForm.value.mapAssetMetaData.length;i++){
                    arrMapAssetMeta.push(
                        {
                            metaKey: this.mapAssetMetaDataForm.value.mapAssetMetaData[i].metaKey,
                            metaValue: this.mapAssetMetaDataForm.value.mapAssetMetaData[i].metaValue
                        }
                    );
                }
            }
        }else{

            if(this.mapAssetMetaDataForm.value.mapAssetMetaData.length>0){

                for(let i=0;i< this.mapAssetMetaDataForm.value.mapAssetMetaData.length;i++){
                    arrMapAssetMeta.push(
                        {
                            metaKey: this.mapAssetMetaDataForm.value.mapAssetMetaData[i].metaKey,
                            metaValue: this.mapAssetMetaDataForm.value.mapAssetMetaData[i].metaValue
                        }
                    );
                }
            }
        }

        if(arrMapAssetMeta.length>0)
        {

            this.createNewAssetForm.controls['mapAssetMetaData'].setValue(arrMapAssetMeta);
        }

        if(this.createNewAssetForm.value.liveType != 'Virtual Channel'){

            this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule=[];

        }
        
        let arrMapVirtualChannelSchedule = [];

        for(let i=0;i< this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule.length;i++){

            // let obj={

            //     'id':this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule[i].assetMapping[0].id,
            //     'itemName':this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule[i].assetMapping[0].itemName,

            //     }

            var  startTime = this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule[i].startTime+ " " + this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule[i].sTime;

            arrMapVirtualChannelSchedule.push(
            {
                'assetMapping':this.mapVirtualArray[i],    
                'startTime':startTime,    
                'assetType':this.mapVirtualChannelScheduleForm.value.mapVirtualChannelSchedule[i].assetType,    
            });

        }
        
        this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]);    
        
        if(arrMapVirtualChannelSchedule.length>0)
        {
        
             this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue(arrMapVirtualChannelSchedule);
                    
        }
        
        console.log('POST===>',this.createNewAssetForm.value);
        
        delete this.createNewAssetForm.value.sameConfigurationOrientation;
        delete this.createNewAssetForm.value.genreSameConfigurationDeviceType;

        console.log('<====Asset Post Data============>',this.createNewAssetForm.value);
      

        if(!this.multipleDeviceForm.valid){
            this.loader= false;
            return
            
        }

        if(!this.createNewAssetForm.valid){
            this.loader= false;
            return
           
        }

        
        if(this.createNewAssetForm.value.liveType === 'Live Event' ){

           
            this.createNewAssetForm.controls['epgUrl'].setValue(''); 
            this.createNewAssetForm.controls['hlsUrl'].setValue('');  
            // this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]); 
            // this.createNewAssetForm.controls['liveEventId'].setValue([]);  
            this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]);
            this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]); 

        }
        
        if(this.createNewAssetForm.value.liveType === 'Virtual Channel' ){
         
            // this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]); 
            // this.createNewAssetForm.controls['liveEventId'].setValue([]); 
            // this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]);  
            this.createNewAssetForm.controls['epgUrl'].setValue(''); 
            this.createNewAssetForm.controls['hlsUrl'].setValue(''); 
            this.createNewAssetForm.controls['liveEventId'].setValue([]); 
          
            
        }
        
        if(this.createNewAssetForm.value.liveType === 'Live Channel' ) {

            // this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]); 
            // this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]);  
            this.createNewAssetForm.controls['epgUrl'].setValue(''); 
            this.createNewAssetForm.controls['hlsUrl'].setValue(''); 
            this.createNewAssetForm.controls['liveEventId'].setValue([]); 
            this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]); 

            
        }

        if(this.createNewAssetForm.value.liveType === 'Third Party' ) {
            
            // this.createNewAssetForm.controls['epgUrl'].setValue(''); 
            // this.createNewAssetForm.controls['hlsUrl'].setValue(''); 
            this.createNewAssetForm.controls['liveVirtualChannelId'].setValue([]);
            this.createNewAssetForm.controls['liveEventId'].setValue([]); 
            // this.createNewAssetForm.controls['vodOrLiveAdServer'].setValue([]);  
            this.createNewAssetForm.controls['mapVirtualChannelSchedule'].setValue([]); 

             
        }
        
       this.createNewAssetForm.controls['airStartDate'].setValue(this.createNewAssetForm.value.airStartDate +' '+ this.createNewAssetForm.value.airStartTime);
       this.createNewAssetForm.controls['airEndDate'].setValue(this.createNewAssetForm.value.airEndDate +' '+  this.createNewAssetForm.value.airEndTime);
      
            
        this.loader = true;

            if( this.update == false ) {
                
                delete this.createNewAssetForm.value.id;
                this.assetService.insertAsset(this.createNewAssetForm.value)
                .subscribe((result:any)=>{

                    this.removeTrailerIndex = null;
                    this.loader=false;
                    this.spinnerService.hide();
                    this.uploadImgFilePath=[];
                    //New Code for right side for from
                   this.update = false;
                   this.uplynkId = '';
                   this.updateSend.emit();
                   this.resetForm();
                   this.createNewAssetSubmitted = false;
                    // this.router.navigate(['asset_management/asset/list']);
                    this.toastrService.success('Asset Created Successfully.');
                });
    
            }
    
            if(this.update == true){
               
                this.createNewAssetForm.controls['id'].setValue(this.id);
                console.log("debugger>>",this.createNewAssetForm.value);

                this.assetService.updateAsset(this.createNewAssetForm.value)
                .subscribe((result:any)=>{

                    this.removeTrailerIndex = null;

                    this.loader=false;
                    this.uploadImgFilePath=[];
                    //New Code for right side for from
                   this.update = false;
                   this.updateSend.emit();
                   this.uplynkId = '';
                   this.resetForm();
                   this.createNewAssetSubmitted = false;
                    this.toastrService.success('Asset Updated Successfully.');
                    // this.router.navigate(['asset_management/asset/list']);
                    console.log('<====result result result============>',result);
                    this.spinnerService.hide();
                   
                });
            }
    }

    //pravin code start
    getGeoZoneInfo(){

        this.spinnerService.show();
        
        this.productConfigurationService.getAllGeoZone(this.geoZoneObj,this.start,10)
        .subscribe((result:any)=>{
            
            this.dropdownList = [];

            this.searchDecouncer$.next(null);

            if(this.isSearch){

                this.geoZoneList = [];
                this.isSearch = false;

            }

            if(result.data.data.length > 0){
                    
                this.dropdownList = result.data.data;
    
                for (let geoZoneObj of this.dropdownList) {
    
                    this.geoZoneList.push(
                    {
                        'id': geoZoneObj.geoZoneId,
                        'itemName': geoZoneObj.geoZoneName
                    });
    
                    this.geoZoneList = this.removeDuplicates(this.geoZoneList , "id");

                }   
            }
            
            console.log('this.geoZoneList',this.geoZoneList);
        }) 
    }

    fetchMoreGetGeoZone(event){

        if ((event.endIndex >= this.geoZoneList.length - 1) && (this.geoZoneList.length>=9)) {
    
             this.start = this.start + 10;
    
             this.getGeoZoneInfo();
                
            }
    }

    mapProductTypeArrayTemp:any=[];

    getProductTypeInfo(filterObj){

        console.log("filterObj= ",filterObj);
    
        this.commonService.getGlobalProductType(this.productTypeMappingFilter,this.startProduct,10).subscribe((result:any)=>{

            console.log("getProductTypeInfo result = ",result.data.data);
            this.searchDecouncer$.next(null);

            this.ProducTypeDropdownList = [];

            if(this.isSearch){

                this.producTypeList = [];
                this.isSearch = false;
                
            }

            // let mapProductTypeArrayTemp = [];
           // this.mapProductTypeArrayTemp = [];

            let mapProductTypeArray = this.createNewAssetForm.value.mapProductType;

            console.log("v",mapProductTypeArray);

            if(result.data.data.length > 0){       

                this.initProductType = false

                this.ProducTypeDropdownList = result.data.data;

                for (let ProducTypeLists of this.ProducTypeDropdownList) {

                    if((mapProductTypeArray !='' && mapProductTypeArray !=[])){
                        mapProductTypeArray.forEach(element => {

                            if(element.id == ProducTypeLists.productTypeId) {

                                this.mapProductTypeArrayTemp.push(element);  
                                this.primaryNavigationMappingFilter.productTypeId.push(element.id);
                                this.createNewAssetForm.controls['mapProductType'].setValue(this.mapProductTypeArrayTemp);                   
                          
                            }

                            this.mapProductTypeArrayTemp = this.removeDuplicates(this.mapProductTypeArrayTemp , "id");

                            if(this.initProductType==false){
                           
                            }
    
                        });
                    }

                    if(ProducTypeLists.productTypeName!=''){

                            this.producTypeList.push(
                                {
                                    'id': ProducTypeLists.productTypeId,
                                    'itemName': ProducTypeLists.productTypeName
                                });
                    }   
                    
                    this.producTypeList = this.removeDuplicates(this.producTypeList , "id");

                }

                if(this.mapProductTypeArrayTemp.length == 0){

                    this.producTypeList = this.removeDuplicates(this.producTypeList, "id");

                    this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                    this.primaryNavigationList = [];
                    
                    this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                    this.menuCategoryList = [];
                    
                      // this.menuCategoryList = [];
                    this.assetCategoryList = [];
                    this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
    
                    this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                    this.assetGroupList = [];
                    
                }else {

                    this.getNavigationInfo(this.primaryNavigationMappingFilter);
                }
                
            } else{

                if(this.initProductType == false ){

                    // this.createNewAssetForm.controls['mapProductType'].setValue([]);
                    // this.producTypeList = [];
    
                    // this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                    // this.primaryNavigationList = [];
                   
                    // this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                    // this.menuCategoryList = [];
    
                    // this.assetCategoryList = [];
                    // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
    
                    // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                    // this.assetGroupList = [];
                }

            

            }
        });
      
    }

    fetchMoreGetProductType(event){

        if ((event.endIndex == this.producTypeList.length - 1) && (this.producTypeList.length>=8)) {

                this.startProduct = this.startProduct + 10;                
                this.getProductTypeInfo(this.productTypeMappingFilter);     
           
         }
    }

    mapPrimaryNavigationArrayTemp:any =[];
    getNavigationInfo(filterObj){

        console.log("filterObj= ",filterObj);

        this.commonService.getGlobalPrimaryNavigation(this.primaryNavigationMappingFilter,this.startPrimary,10).subscribe((result:any)=>{

            console.log("getNavigationInfo Result= ",result.data.data);

            let asyc = result.data.data.length;
            let mapPrimaryNavigationArray = this.createNewAssetForm.value.mapPrimaryNavigation;

            this.searchDecouncer$.next(null);

            if(result.data.data.length > 0){  

                this.initNavigation = false;
                // var mapPrimaryNavigationArrayTemp = [];  
              //  this.mapPrimaryNavigationArrayTemp =[];
             //   this.primaryNavigationList = [];   

                    
                    if(this.isSearch){

                        this.primaryNavigationList = [];   
                        this.isSearch = false;
                            
                    }

                for (let primaryNavigationLists of result.data.data) {

                        asyc --;

                           mapPrimaryNavigationArray.forEach(element => {

                        if(element.id == primaryNavigationLists.primaryNavigationId) {
                            this.mapPrimaryNavigationArrayTemp.push(element);
                            this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp, "id");
  
                            this.menuCategoryMappingFilter.primaryNavigationId.push(element.id);                  
                        }

                        this.mapPrimaryNavigationArrayTemp = this.removeDuplicates(this.mapPrimaryNavigationArrayTemp, "id");
                    });
                    
                    

                    if(primaryNavigationLists .primanyNavigationName!=''){
                    this.primaryNavigationList.push(
                        {
                            'id': primaryNavigationLists.primaryNavigationId,
                            'itemName': primaryNavigationLists.primanyNavigationName
                        });
                    } 
                }

                if(asyc == 0){  

                    this.primaryNavigationList = this.removeDuplicates(this.primaryNavigationList, "id");
                    this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue(this.mapPrimaryNavigationArrayTemp);

                    
                    if(this.mapPrimaryNavigationArrayTemp.length == 0 ){

                        // this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                        // this.menuCategoryList = [];
                        // this.assetCategoryList = [];
                        // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
        
                        // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        // this.assetGroupList = [];
        
                        
                       }else {
    
                        this.getGlobalMenuCategory(this.menuCategoryMappingFilter);
                    
                    }
                   
                }

            }else {

                if(this.initNavigation == false){

                    if(this.mapPrimaryNavigationArrayTemp.length == 0 ){

                        // this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                        // this.menuCategoryList = [];

                        this.assetCategoryList = [];
                        this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
        
                        this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                        this.assetGroupList = [];
        
                        
                       }

                    // this.createNewAssetForm.controls['mapPrimaryNavigation'].setValue([]);
                    // this.primaryNavigationList = [];
                   
                    // // this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                    // // this.menuCategoryList = [];
    
                    // this.assetCategoryList = [];
                    // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);
    
                    // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                    // this.assetGroupList = [];
                }           
            }
        })
    }
    
    fetchMoreGetPrimary(event){

        if ( ((event.endIndex >= this.primaryNavigationList.length - 2)) && (this.primaryNavigationList.length >=10)) {

                this.startPrimary = this.startPrimary + 10;

                this.getNavigationInfo(this.primaryNavigationMappingFilter);  
   
        }

    }

    mapMenuCategoryArrayTemp:any = [];

    getGlobalMenuCategory(filterObj){

        console.log("filterObj= ",filterObj);

        this.commonService.getGlobalMenuCategory(this.menuCategoryMappingFilter,this.startCategory,10).subscribe((result:any)=>{
            
           // this.mapMenuCategoryArrayTemp = [];
           this.searchDecouncer$.next(null);

            let mapMenuCategoryArray = this.createNewAssetForm.value.mapMenuCategory;
            
            if(result.data.data.length > 0){ 

                    
                if(this.isSearch){

                    this.menuCategoryList = [];   
                    this.isSearch = false;
                        
                }

                let asyc = result.data.data.length;
                
                for (let menuCategoryList of result.data.data) {
 
                    asyc -- ; 

                    mapMenuCategoryArray.forEach(element => {

                        if(element.id == menuCategoryList.menuCategoryId) {

                            this.mapMenuCategoryArrayTemp.push(element);  
                            this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp, "id");
                  
                        }

                      
                        });
   
                    if(menuCategoryList.menuCategoryName!=''){
                    
                        this.menuCategoryList.push(
                        {
                            'id': menuCategoryList.menuCategoryId,
                            'itemName': menuCategoryList.menuCategoryName
                        });
                    } 

                    if(asyc == 0){
                        this.initMenu = false;
                        this.menuCategoryList = this.removeDuplicates(this.menuCategoryList, "id");
                        this.mapMenuCategoryArrayTemp = this.removeDuplicates(this.mapMenuCategoryArrayTemp,'id');
                        this.createNewAssetForm.controls['mapMenuCategory'].setValue(this.mapMenuCategoryArrayTemp); 
                
                    }
                }
            } else{
                if(this.initMenu == false){

                // this.createNewAssetForm.controls['mapMenuCategory'].setValue([]);
                // this.menuCategoryList = [];

                // this.assetCategoryList = [];
                // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);

                // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                // this.assetGroupList = [];

                }
            }

        });
    }

    fetchMoreGetCategory(event){

        if ( ((event.endIndex >= this.menuCategoryList.length - 2)) && (this.menuCategoryList.length >= 9)) {

                this.startCategory = this.startCategory + 10;

                this.getGlobalMenuCategory(this.menuCategoryMappingFilter);  
   
        }
    }

    mapAssetGroupArrayTemp = [];
    getGlobalAssetGroup(filterObj){


        this.commonService.getGlobalAssetGroup(filterObj,this.startGroup,10).subscribe((result:any)=>{
            
              if(this.isSearch){
                this.assetGroupList = [];
                this.isSearch = false;      
              }
              this.searchDecouncer$.next(null);

            // let mapAssetGroupArrayTemp = [];
         //   this.mapAssetGroupArrayTemp = [];
            let mapAssetGroupArray = this.createNewAssetForm.value.mapAssetGroup;

            console.log("mapAssetGroupArrayTemp= ",this.mapAssetGroupArrayTemp);
            
            if(result.data.data.length > 0){ 
                
                this.initGroup = false;
                for (let assetGroupList of result.data.data) {

                    mapAssetGroupArray.forEach(element => {

                        if(element.id == assetGroupList.assetGroupId) {

                            this.mapAssetGroupArrayTemp.push(element);   
                            this.mapAssetGroupArrayTemp = this.removeDuplicates(this.mapAssetGroupArrayTemp,'id');
                            this.createNewAssetForm.controls['mapAssetGroup'].setValue(this.mapAssetGroupArrayTemp); 
                 
                        }
                    });

                    if(assetGroupList.assetGroupName!=''){
                    
                        this.assetGroupList.push(
                        {
                            'id': assetGroupList.assetGroupId,
                            'itemName': assetGroupList.assetGroupName
                        });
                    }
                    
                    this.assetGroupList = this.removeDuplicates(this.assetGroupList, "id");

                }
            }else{

                if(this.initGroup == false){

                    // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                    // this.assetGroupList = [];
                    
                }
            }

        });
    }

    startGroup:any = 0;
    fetchMoreGetAssetGroup(event){

        if ( ((event.endIndex >= this.assetGroupList.length - 2)) && (this.assetGroupList.length >= 9)) {

                this.startGroup = this.startGroup + 10;

                this.getGlobalAssetGroup(this.assetGroupMappingFilter);  
   
        }
    }

    mapAssetCategoryArrayTemp = [];
    getGlobalAssetCategory(filterObj){

        console.log("filterObj= ",filterObj);
       if(filterObj == undefined)
          filterObj = {};

        this.commonService.getGlobalAssetCategory(this.assetCategoryMappingFilter,this.startAssetCategory,10).subscribe((result:any)=>{

            // this.assetCategoryList = []; 
          //  this.mapAssetCategoryArrayTemp = [];
            let mapAssetCategoryArray = this.createNewAssetForm.value.mapAssetCategory;
            
            if(result.data.data.length > 0){  
                
                this.searchDecouncer$.next(null);

                if(this.isSearch == true){
                    this.assetCategoryList = [];
                    this.isSearch = false;
                }
                
                this.initCategory = false;

                for (let assetCategoryList of result.data.data) {
   
                    mapAssetCategoryArray.forEach(element => {

                        if(element.id == assetCategoryList.assetCategoryId) {

                           this.mapAssetCategoryArrayTemp.push(element);  
                           this.mapAssetCategoryArrayTemp = this.removeDuplicates(this.mapAssetCategoryArrayTemp, "id");
                            this.createNewAssetForm.controls['mapAssetCategory'].setValue(this.mapAssetCategoryArrayTemp);  

                        }

                    
                    });

                    if(assetCategoryList.assetCategoryName!=''){
                    
                        this.assetCategoryList.push(
                        {
                            'id': assetCategoryList.assetCategoryId,
                            'itemName': assetCategoryList.assetCategoryName
                        });
                    }
                    
                    this.assetCategoryList = this.removeDuplicates(this.assetCategoryList, "id");


                }
            }else{

                if(this.initCategory == false){

                    // this.assetCategoryList = [];
                    // this.createNewAssetForm.controls['mapAssetCategory'].setValue([]);

                    // this.createNewAssetForm.controls['mapAssetGroup'].setValue([]);
                    // this.assetGroupList = [];

                }
            }
        });
    }

    startAssetCategory:any = 0;
    fetchMoreGetAssetCategory(event){

        if ( ((event.endIndex >= this.assetCategoryList.length - 2)) && (this.assetCategoryList.length >= 9)) {

                this.startAssetCategory = this.startAssetCategory + 10;

                this.getGlobalAssetCategory(this.assetCategoryMappingFilter);  
   
        }
    }

     //pravin code start

    //pravin code end

    getGlobalclassification(){

        this.commonService.getglobalclassification(this.classificationFilter,this.startClassification,10).subscribe((result:any)=>{
                
          //  this.mapClassificationList = [];
            
            if(result.data.data.length > 0){  
                
                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.mapClassificationList = [];   
                    this.isSearch = false;
                        
                }     

                for (let menuCategoryList of result.data.data) {
    
                    if(menuCategoryList.classificationName!=''){
                    
                        this.mapClassificationList.push(
                        {
                            'id': menuCategoryList.classificationId,
                            'itemName': menuCategoryList.classificationName
                        });

                    this.assetCategoryList = this.removeDuplicates(this.assetCategoryList, "id");

                    } 
                }
            } 
    
        });
    
    }

    fetchMoreGetClassification(event){
    
        if ( ((event.endIndex == this.mapClassificationList.length - 1)) && (this.mapClassificationList.length >= 7)) {

                this.startClassification = this.startClassification + 10;

                this.getGlobalclassification();
                 
        }

    }

    getCertificationInfo(){
        
        this.commonService.getGlobalCertification(this.certificateFilter,this.startCertificate,10).subscribe((result:any)=>{
            
          //  this.certificationList = [];
            if(result.data.data.length > 0){   

                this.searchDecouncer$.next(null);

                    
                if(this.isSearch){

                    this.certificationList = [];   
                    this.isSearch = false;
                        
                }   

                for (let certificationLists of result.data.data) {
   
                    if(certificationLists.certificationName!=''){
                    this.certificationList.push(
                        {
                            'id': certificationLists.certificationId,
                            'itemName': certificationLists.certificationName
                        });
                    } 

                    this.certificationList = this.removeDuplicates(this.certificationList, "id");

                }

            } 

        });
        
    }

    fetchMoreGetCertificate(event){
    
        if ( ((event.endIndex == this.mapClassificationList.length - 1)) && (this.mapClassificationList.length >= 7)) {

                this.startClassification = this.startClassification + 10;

                this.getCertificationInfo();
                 
   
        }

    }

    getGlobaltrailervideo(){
        
        this.commonService.getGlobalTrailerVideo(this.filterTrailer,this.startTrailer,10).subscribe((result:any)=>{
            
            // this.trailerVideoList = [];
            
            if(result.data.data.length > 0){ 
                
                this.searchDecouncer$.next(null);


                if(this.isSearch){

                    this.trailerVideoList = [];   
                    this.isSearch = false;
                        
                }  

                for (let trailerVideoLists of result.data.data) {
   
                    if(trailerVideoLists.assetName!=null){
                    
                        this.trailerVideoList.push(
                        {
                            'id': trailerVideoLists.assetId,
                            'itemName': trailerVideoLists.assetName
                        });

                    this.trailerVideoList = this.removeDuplicates(this.trailerVideoList, "itemName");

                    } 
                }

                console.log("this.trailerVideoList>>",this.trailerVideoList);
            } 

        });
        
    }

    startTrailer = 0;
    filterTrailer = {};
    fetchMoreGetTrailer(event){
    
        if ( ((event.endIndex == this.trailerVideoList.length - 1)) && (this.trailerVideoList.length >= 7)) {

                this.startTrailer = this.startTrailer + 10;

                this.getGlobaltrailervideo();
                 
   
        }

    }

    getLibrary(){

        this.commonService.getglobalLibrary(this.libraryFilter,this.startLibrary,10).subscribe((result:any)=>{


            if(result.data.data.length > 0){ 

                this.searchDecouncer$.next(null);

                
                if(this.isSearch){

                    this.mapLibraryList = [];   
                    this.isSearch = false;
                        
                }  

                for (let ProducTypeLists of result.data.data) {

                    console.log("ProducTypeLists",ProducTypeLists);

                    if(ProducTypeLists.libraryName!=''){
                    this.mapLibraryList.push(
                        {
                            'id': ProducTypeLists.libraryId,
                            'itemName': ProducTypeLists.libraryName
                        });
                    }    
                }

                this.mapLibraryList = this.removeDuplicates(this.mapLibraryList, "id");

            } 

        });
    }

        
    removeDuplicates(originalArray, prop) {

        var newArray = [];
        var lookupObject  = {};
    
        for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
            return newArray;
    }


    fetchMoreGetlibrary(event){

        if ( ((event.endIndex == this.mapLibraryList.length - 1)) && (this.mapLibraryList.length >= 9)) {

                this.startLibrary = this.startLibrary + 10;

                this.getLibrary();
   
        }

    }

    getGlobalUplynkAdServer(){
        
        this.commonService.getGlobalUplynkAdServer().subscribe((result:any)=>{

            this.uplynkAdServerList = []; 
            
            if(result.data.data.length > 0){       
                for (let objUplynkAdServer of result.data.data) {

                    this.searchDecouncer$.next(null);

   
                    if(objUplynkAdServer.uplynkName!=''){
                    
                        this.uplynkAdServerList.push(
                        {
                            'id': objUplynkAdServer.uplynkId,
                            'itemName': objUplynkAdServer.uplynkName
                        });
                    }

                    this.uplynkAdServerList = this.removeDuplicates(this.uplynkAdServerList, "id");

                     
                }
            }

        });
    }

    getGlobalClientAdServer(){
        

        this.clientAdServerService.getAllClientadserver({},0,200).subscribe((result:any)=>{

            console.log(">>>>>>getGlobalClientAdServer",result)

            this.clientAdServerList = []; 
            
            if(result.data.data.length > 0){       
                for (let objClientAdServer of result.data.data) {

                    this.searchDecouncer$.next(null);

   
                    if(objClientAdServer.uplynkName!=''){
                    
                        this.clientAdServerList.push(
                        {
                            'id': objClientAdServer.clientAdServerId,
                            'itemName': objClientAdServer.clientAdServerTitle
                        });
                    }

                    this.clientAdServerList = this.removeDuplicates(this.clientAdServerList, "id");

                     
                }
            }

        });
    }
    
    


    getGlobalLiveVirtualChannel(){
    
        this.commonService.getGlobalLiveVirtualChannel(this.filterVchannel,this.startVchannel,200).subscribe((result:any)=>{
            
            
            // this.liveVirtualChannelList = [];
            
            if(result.data.data.length > 0){

                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.liveVirtualChannelList = [];   
                    this.isSearch = false;
                        
                }  
                   
                for (let objLiveVirtualChannel of result.data.data) {
   
                    if(objLiveVirtualChannel.channelName!=''){
                    
                        this.liveVirtualChannelList.push(
                        {
                            'id': objLiveVirtualChannel.channelId,
                            'itemName': objLiveVirtualChannel.channelName
                        });

                        this.liveVirtualChannelList = this.removeDuplicates(this.liveVirtualChannelList, "id");

                    } 
                }
            } 

        });
        
    } 


    startVchannel:any = 0;
    filterVchannel ={};
    fetchMoreGetstartVchannel(event){
        
        if ( ((event.endIndex == this.liveVirtualChannelList.length - 1)) && (this.liveVirtualChannelList.length >= 7)) {

                this.startVchannel = this.startVchannel + 10;

                this.getGlobalLiveVirtualChannel();
    
   
        }
    }
    
 
    getGlobalLiveEvent(){
    
        this.commonService.getGlobalLiveEvent().subscribe((result:any)=>{
            
            this.liveEventList = [];
            
            if(result.data.data.length > 0){

                this.searchDecouncer$.next(null);
                
                if(this.isSearch){

                    this.liveEventList = [];   
                    this.isSearch = false;
                        
                }  
                   
                for (let objLiveEvent of result.data.data) {
   
                    if(objLiveEvent.eventName!=''){
                    
                        this.liveEventList.push(
                        {
                            'id': objLiveEvent.liveEventId,
                            'itemName': objLiveEvent.eventName
                        });

                    this.liveEventList = this.removeDuplicates(this.liveEventList, "id");

                    } 
                }
            } 

        });
        
    } 

    castFilter = {};
    startCast = 0;

    getGlobalCast(){
    
        this.commonService.getGlobalCast(this.castFilter,this.startCast,10).subscribe((result:any)=>{
            
            // this.liveEventList = [];
            
            if(result.data.data.length > 0){

                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.mapCastlist = [];   
                    this.isSearch = false;
                        
                }  
                   
                for (let objLiveEvent of result.data.data) {
   
                    if(objLiveEvent.castFirstName!=''){
                    
                        this.mapCastlist.push(
                        {
                            'id': objLiveEvent.castId,
                            'itemName': objLiveEvent.castFirstName
                        });

                        this.mapCastlist = this.removeDuplicates(this.mapCastlist, "id");
                        
                    } 
                }
            } 

        });
        
    }

    fetchMoreGetCast(event){
        
        if ( ((event.endIndex == this.mapCastlist.length - 1)) && (this.mapCastlist.length >= 7)) {

                this.startCast = this.startCast + 10;

                this.getGlobalCast();
    
   
        }
    }


    getGlobalAssetLibrary(){
        
        this.commonService.getGlobalAssetLibrary().subscribe((result:any)=>{
        
            this.assetLibraryList = [];
            
            if(result.data.data.length > 0){

                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.assetLibraryList = [];   
                    this.isSearch = false;
                        
                }  
                
                for (let objAssetLibrary of result.data.data) {
   
                    if(objAssetLibrary.libraryName!=''){
                    
                        this.assetLibraryList.push(
                        {
                            'id': objAssetLibrary.assetLibraryId,
                            'itemName': objAssetLibrary.libraryName
                        });
                    } 
                }

                this.assetLibraryList = this.removeDuplicates(this.assetLibraryList, "id");

            } 

        });
        
    }


    getGenreInfo(){

        this.commonService.getGlobalGenre(this.genreFilter,this.startGenre,10).subscribe((result:any)=>{
       
            //  this.mapGenreList = [];

            if(result.data.data.length > 0){  
                
                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.mapGenreList = [];   
                    this.isSearch = false;
                        
                }  

                for (let ProducTypeLists of result.data.data) {
                    if(ProducTypeLists.genreName!=''){
                    this.mapGenreList.push(
                        {
                            'id': ProducTypeLists.genreId,
                            'itemName': ProducTypeLists.genreName
                        });
                    }    
                } 

                this.mapGenreList = this.removeDuplicates(this.mapGenreList, "id");

            } 
        });
    }

    fetchMoreGetGenre(event){
        
        if ( ((event.endIndex == this.mapGenreList.length - 1)) && (this.mapGenreList.length >= 7)) {

                this.startGenre = this.startGenre + 10;

                this.getGenreInfo();
            // }       
   
        }
    }

    //file upload related code

    selectFileVertical(e ,i) {

       
 //set obj for file upload of vertical section
        if(e.target.files[0].name==undefined)
        return;

        this.uploadImgFilePathVertical[i].index = i;
        this.uploadImgFilePathVertical[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
        this.uploadImgFilePathVertical[i].selectedFiles = e.target.files.item(0);
        this.uploadImgFilePathVertical[i].verticalFileName =  e.target.files[0].name; 
        this.uploadImgFilePathVertical[i].verticalFileType = e.target.files[0].type;
        this.uploadImgFilePathVertical[i].verticalFile = true; 

            var mimeType = e.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            }

            console.log("this.img[i].",i);
           console.log("this.img[i].",this.img);

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.img[i].verticalURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

            e.target.value = '';

       
    }
    
    selectFileHorizontal(e ,i) {

        console.log("abcd,",e);

           //set obj for file upload of horizontal section
           if(e.target.files[0].name==undefined)
           return;
   
           this.uploadImgFilePathHorizontal[i].index = i;
           this.uploadImgFilePathHorizontal[i].deviceType = this.multipleDeviceForm.value.devices[i].dtypeId;
           this.uploadImgFilePathHorizontal[i].selectedFiles = e.target.files.item(0);
           this.uploadImgFilePathHorizontal[i].horizontalFileName =  e.target.files[0].name; 
           this.uploadImgFilePathHorizontal[i].horizontalFileType = e.target.files[0].type;
           this.uploadImgFilePathHorizontal[i].horizontalFile = true; 

           console.log("uploadImgFilePathHorizontal>>>",this.uploadImgFilePathHorizontal);

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        console.log("this.img[i].",i);
        console.log("this.img[i].",this.img);


        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.img[i].horizontalURL=reader.result;
            }

            e.target.value = '';

    }

    selectFilePosterImage(e , i){

        console.log("e.target.files>>>",e);

        // if( e.target.files[0].name == undefined){

        //     this.loader = false;
        //     return
        // }
        
       // e.target.value = '';

       this.loader = true;

       this.uploadImgFilePosterImage=[];

        let obj = {
            'index':i,
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }


        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {

                this.FilePosterImageURL=reader.result;
                console.log("this.img[i].",this.img[i]);

            }

            console.log("this.upload>>>",this.uploadImgFilePosterImage);
        this.uploadImgFilePosterImage.push(obj);  
        if(this.uploadImgFilePosterImage.length>0){

          this.loader = false;

            for (let i = 0; i < this.uploadImgFilePosterImage.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFilePosterImage[i].selectedFiles, awsPath.asset).then(res => {

                    if (res && res.Location) {

                    this.ClosedCaption;
                    this.loader = false;
                    e.target.value = '';
                    this.uploadImgFilePosterImage[i].FilePath=res.Location;  
                    this.createNewAssetForm.controls['vodOrLivePosterImageFileName'].setValue(this.uploadImgFilePosterImage[i].fileName);
                    this.createNewAssetForm.controls['vodOrLivePosterImageFilePath'].setValue(res.Location);
                    this.createNewAssetForm.controls['vodOrLivePosterImageFileType'].setValue(this.uploadImgFilePosterImage[i].fileType);
                    console.log("createNewProductForm",this.createNewAssetForm);

                    }
                   
                })
            }
        }else{
            this.ClosedCaption=true;
        }
        
    }

    selectFileClosedCaption(e,i){


        if(e.target.files[0].name == undefined)
        return;

        e.target.value = '';


        this.loader = true;

        this.uploadImgFileclosedCaption=[];

        let obj = {
            'index':i,
            'selectedFiles':e.target.files.item(0),
            "fileName": e.target.files[0].name,
            "filePath": "",
            "fileType": e.target.files[0].type, 
        }

        // for(let i = 0;i<this.uploadImgFilePath.length;i++){

        //     if(obj.index == this.uploadImgFilePath[i].index && obj.imgtype ==='horizontal'){
        //         this.uploadImgFilePath.splice(i, 1);
        //     }
        // }

        var mimeType = e.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            console.log("Only images are supported.");
            return;
        }

        var reader = new FileReader();
        
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (_event) => {
                this.FileClosedCaptionURL=reader.result;
                console.log("this.img[i].",this.img[i]);
            }

        this.uploadImgFileclosedCaption.push(obj);  

        e.target.value = '';

        if(this.uploadImgFileclosedCaption.length>0){

            for (let i = 0; i < this.uploadImgFileclosedCaption.length; i++) {
        
                this.productConfigurationService.uploadFile(this.uploadImgFileclosedCaption[i].selectedFiles, awsPath.asset).then(res => {

                    if (res && res.Location) {

                        console.log("vodClosedCaptionFilePath",this.createNewAssetForm);
                        console.log("res",res.Location);

                        this.poster=true;
                        setTimeout(()=>{
                            this.loader = false;
                        },2000)
                      
                        this.uploadImgFileclosedCaption[i].FilePath=res.Location;  
                        this.createNewAssetForm.controls['vodClosedCaptionFileName'].setValue(this.uploadImgFileclosedCaption[i].fileName);
                        this.createNewAssetForm.controls['vodClosedCaptionFilePath'].setValue(res.Location);
                        this.createNewAssetForm.controls['vodClosedCaptionFileType'].setValue(this.uploadImgFileclosedCaption[i].fileType);
                        console.log("createNewProductForm",this.createNewAssetForm);
                    }          
                })
            }
        }else{
            this.poster=true;
        }
    }
    
    //file upload

    getLanguageInfo(){

        this.commonService.getGlobalLanguage(this.languageFilter,this.startLanguage,10).subscribe((result:any)=>{
            
        //    this.mapLanguageList = [];
            
            if(result.data.data.length > 0){   

                this.searchDecouncer$.next(null);

                if(this.isSearch){

                    this.mapLanguageList = [];   
                    this.isSearch = false;
                        
                }  

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.languageName!=''){
                    
                        this.mapLanguageList.push(
                        {
                            'id': menuCategoryList.languageId,
                            'itemName': menuCategoryList.languageName
                        });
                    } 
                }

                this.mapLanguageList = this.removeDuplicates(this.mapLanguageList, "id");
            } 

        });
    }

    fetchMoreGetLanguage(event){
        
        if ((event.endIndex == this.mapLanguageList.length - 1)&& (this.mapLanguageList.length >= 8)) {

                this.startLanguage = this.startLanguage + 10;
                this.getLanguageInfo();
   
        }
    }

    getSponsoreInfo(){

         if(this.sponsoredFiter == undefined)
              this.sponsoredFiter ={};       
 
        this.commonService.getGlobalSponsore(this.sponsoredFiter,this.startSponsored,10).subscribe((result:any)=>{
            
           // this.mapSponsoreList = [];
            
            if(result.data.data.length > 0){
                
                this.searchDecouncer$.next(null);

                
                if(this.isSearch){

                    this.mapSponsoreList = [];   
                    this.isSearch = false;
                        
                }  

                for (let menuCategoryList of result.data.data) {

                    if(menuCategoryList.sponsorName!=''){
                    
                        this.mapSponsoreList.push(
                        {
                            'id': menuCategoryList.sponsorId,
                            'itemName': menuCategoryList.sponsorName
                        });

                        this.mapSponsoreList = this.removeDuplicates(this.mapSponsoreList, "id");

                    } 

                }

            } 

        });

    }
    
    fetchMoreGetSponsore(event){
        
        if ( ((event.endIndex == this.mapSponsoreList.length - 1)) && (this.mapSponsoreList.length >= 7)) {

          
                this.startSponsored = this.startSponsored + 10;
                this.getSponsoreInfo();
   
        }
    }

    isDeleteDeviceType(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteAction=false;
            this.delInput(this.deleteDeviceTypeIndex);
        }

    }

    isDeleteDeviceTypeYes(i){
        this.deleteDeviceTypeIndex = i;
        console.log("dddddddd",this.deleteDeviceTypeIndex);
    }

    isDeleteMetadata(event){

        if(event=='Cancel'){
            this.isDeleteMetadataAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteMetadataAction=false;
            this.delMetadata(this.deleteMetadataIndex);
        }

    }

    isDeleteMetadataYes(i){
    this.deleteMetadataIndex = i;
    }

    isDeleteMapBreak(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteMapBreakAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteMapBreakAction=false;
            this.delmapBreak(this.deleteMapBreakIndex);
        }

    }

    isDeleteMapBreakYes(i){
        this.deleteMapBreakIndex = i;
    }


    isDeleteMapMulticam(event){

        console.log('event Mayuri',event)
        if(event=='Cancel'){
            this.isDeleteMapMulticamAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteMapMulticamAction=false;
            this.delmapMulticam(this.deleteMapMulticamIndex);
        }

    }

    isDeleteMapMulticamYes(i){
        this.deleteMapMulticamIndex = i;
    }



    //delete 
    isDeleteVirtualChannel(event){

        if(event=='Cancel'){
            this.isDeleteVirtualChannelAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteVirtualChannelAction=false;
            this.delMapVirtualChannel(this.deleteVirtualChannelIndex);
        }

    }

    isDeleteVirtualChannelYes(i){
        this.deleteVirtualChannelIndex = i;
    }

    onSelectAllVideo(i){

        console.log("arrayControl",i);

        // const arrayControl = this.mapVirtualChannelScheduleForm.get('mapTrailerVideo') as FormArray;
        // arrayControl.removeAt(i);

    }

    isDeleteHorizontalImage(event){

        console.log('isDeleteHorizontalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteHorizontalImageAction=false;
        }
        
        if(event=='Yes'){

            this.isDeleteHorizontalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].horizontalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].horizontalFilePath="";

            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathHorizontal[this.deleteDeviceTypeIndex].deviceType = '';

        }

    }

    deleteHorizontalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }


    isDeleteVerticalImage(event){

        console.log('isDeleteVerticalImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteVerticalImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteVerticalImageAction=false;
            delete this.img[this.deleteDeviceTypeIndex].verticalURL;
            this.multipleDeviceForm.value.devices[this.deleteDeviceTypeIndex].verticalFilePath="";

            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].selectedFiles = '';
            this.uploadImgFilePathVertical[this.deleteDeviceTypeIndex].deviceType = '';
        }

    }

    deleteVerticalImageYes(i){
        this.deleteDeviceTypeIndex=i;
    }

    isDeletePosterImage(event){

        console.log('isDeletePosterImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeletePosterImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeletePosterImageAction=false;
            delete this.FilePosterImageURL;
            
            this.createNewAssetForm.controls['vodOrLivePosterImageFileName'].setValue(['']);
            this.createNewAssetForm.controls['vodOrLivePosterImageFilePath'].setValue(['']);
            this.createNewAssetForm.controls['vodOrLivePosterImageFileType'].setValue(['']);
        }

    }

    isDeleteClosedCaptionImage(event){

        console.log('isDeleteClosedCaptionImageAction event = ',event)
        if(event=='Cancel'){
            this.isDeleteClosedCaptionImageAction=false;
        }
        
        if(event=='Yes'){
            this.isDeleteClosedCaptionImageAction=false;
            delete this.FileClosedCaptionURL;
            
            this.createNewAssetForm.controls['vodClosedCaptionFileName'].setValue(['']);
            this.createNewAssetForm.controls['vodClosedCaptionFilePath'].setValue(['']);
            this.createNewAssetForm.controls['vodClosedCaptionFileType'].setValue(['']);

        }

    }

    showCreateNewButton(isShow){

        if(isShow == 'show'){
            this.isCreateNewShow = true;
        }
        else{
            this.isCreateNewShow = false;
        }
    }

}
